import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import { useAuthSelector } from '../../../redux/selector/auth';
import { deactivateAccount, deleteAccount, updateSettings } from '../../../redux/slices/auth';
import swal from 'sweetalert';

const PrivacySettings = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const authSelector = useAuthSelector();
    const settingData = authSelector?.userSettings;
    const checkAccount = authSelector?.userInfo?.user?.is_deactivate

    const handleSettingChange = (e, key, value) => {
        const params = {
            [key]: value
        }
        dispatch(updateSettings({
            ...params, cb(res) {
                if (res.status) {
                    toast.dismiss()
                }
            }
        }))
    }

    const handleDeleteAccount = () => {
        swal({
            // title: "Are you sure?",
            text: `Are you sure want to delete this account?`,
            // icon: "warning",
            dangerMode: false,
            buttons: true,
        })
            .then(result => {
                if (result) {
                    const params = {

                    }
                    dispatch(deleteAccount({
                        ...params, cb(res) {
                            if (res.status) {
                                navigate("/")
                                toast.dismiss()
                            }
                        }
                    }))

                }
            });
    }


    const handleDeactivateAccount = (val) => {
        swal({
            // title: "Are you sure?",
            text: `Are you sure want to ${val === 0 ? "activate" : val === 1 ?  "deactivate" : ""} this account?`,
            // icon: "warning",
            dangerMode: false,
            buttons: true,
        })
            .then(result => {
                if (result) {
                    const params = {
                        is_deactivate: val
                    }
                    dispatch(deactivateAccount({
                        ...params, cb(res) {
                            if (res.status) {
                                // navigate("/")
                                toast.dismiss()
                            }
                        }
                    }))

                }
            });
    }


    useEffect(() => {
        handleSettingChange()
    }, [])

    return (
        <div className='PrivacySettings privacySetting'>
            <h3 className='headTxtBlueSmall mb-4'>Privacy & Settings</h3>
            <div className='listingMenusContainer'>
                {/* <div className='listingMenusArea'>
                    <h5 className='innerSubtext'><b>Your Activity</b></h5>
                    <ul className='listingMenus'>
                        <li className='listingItems'>
                            <p className='headingTitleLight_'>Who can see the people, pages and lists you follow?</p>
                            <div className="dropdown">

                                <button className="subsmallBtnblack_ p-0" type="button" id="dropdownMenuButtons" data-bs-toggle="dropdown" aria-expanded="false" disabled={checkAccount === 1 ? "disabled" : ""}>
                                    {settingData?.who_can_see == 1 ? 'All Members' : settingData?.who_can_see == 2 ? 'Connected Members Only' : "All Members"}

                                    <i class="fas fa-chevron-down ps-2"></i></button>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButtons">
                                    <li><Link className="dropdown-item" onClick={(e) => { handleSettingChange(e, 'who_can_see', 1) }}>All Members</Link></li>
                                    <li><Link className="dropdown-item" onClick={(e) => { handleSettingChange(e, 'who_can_see', 2) }}>Connected Members Only</Link></li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div> */}
                <hr className='topLightboder' />
                <div className='listingMenusArea'>
                    <h5 className='innerSubtext'><b>Public</b></h5>
                    <ul className='listingMenus'>
                        <li className='listingItems'>
                            <p className='headingTitleLight_'>Who can see your community feed activity?</p>
                            <div className="dropdown">
                                <button className="subsmallBtnblack_ p-0" type="button" id="dropdownMenuButtons" data-bs-toggle="dropdown" aria-expanded="false" disabled={checkAccount === 1 ? "disabled" : ""}> {settingData?.who_can_see_community_feed == 1 ? 'All Members' : settingData?.who_can_see_community_feed == 2 ? 'Connected Members Only' : "All Members"} <i class="fas fa-chevron-down ps-2"></i></button>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButtons">
                                    <li><Link className="dropdown-item" onClick={(e) => { handleSettingChange(e, 'who_can_see_community_feed', 1) }}>All Members</Link></li>
                                    <li><Link className="dropdown-item" onClick={(e) => { handleSettingChange(e, 'who_can_see_community_feed', 2) }}>Connected Members Only</Link></li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
                <hr className='topLightboder' />
                <div className='listingMenusArea'>
                    <h5 className='innerSubtext'><b>Notifications</b></h5>
                    <ul className='listingMenus'>
                        <li className='listingItems'>
                            <p className='headingTitleLight_ mb-0'>Send notice to email for notifications</p>
                            <div className="dropdown">
                                <button className="subsmallBtnblack_ p-0" type="button" id="dropdownMenuButtons" data-bs-toggle="dropdown" aria-expanded="false" disabled={checkAccount === 1 ? "disabled" : ""}>{settingData?.email_notifications == 1 ? 'Yes' : 'No'} <i class="fas fa-chevron-down ps-2"></i></button>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButtons">
                                    <li><Link className="dropdown-item" onClick={() => { handleSettingChange('email_notifications', 1) }}>Yes</Link></li>
                                    <li><Link className="dropdown-item" onClick={() => { handleSettingChange('email_notifications', 2) }}>No</Link></li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className='listingMenusArea'>
                    <h5 className='innerSubtext'><b>Account</b></h5>
                    <ul className='listingMenus'>
                        <li className='listingItems'>
                            <p className='headingTitleLight_'>You can temporarily deactivate your account by clicking here.</p>
                            <div className='deactivateDeleteBtn'>
                                {checkAccount === 1 ?
                                    <>
                                        <button className='secondaryBtnOuter' onClick={() => handleDeactivateAccount(0)}>Activate</button><br />
                                    </>
                                    : 
                                    checkAccount === 0 ?
                                    <>
                                        <button className='secondaryBtnOuter' onClick={() => handleDeactivateAccount(1)}>Deactivate</button><br />
                                    </>
                                    : ""
                                }
                            </div>
                        </li>
                        <li className='listingItems mt-2'>
                            <p className='headingTitleLight_'>Looking to delete your account? We are sorry to see you go.</p>
                            <div className='deactivateDeleteBtn me-1 '>
                                <button className='subsmallBtngreen_ me-2' onClick={() => handleDeleteAccount()}>Delete</button>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default PrivacySettings