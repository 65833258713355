import React, { useEffect } from 'react';
import './paymentFailure.css'; // Import your CSS file for styling
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'; // Import Link from react-router-dom to handle navigation
import { verifySession } from '../../../../redux/slices/dashboard';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

function PaymentFailurePage() {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const queryParams = new URLSearchParams(window.location.search);
  const fromInside = queryParams.get('fromInside')

  useEffect(()=>{
    const params  = {
        status : 3,
    }
    dispatch(verifySession(({
        ...params, cb(res) {
            if(res.status == 200){

              if(fromInside){
                setTimeout(()=>{                  
                  navigate('/settings',{state:{activeTab:'subscription'}})
                },5000)
              }else{
                localStorage.removeItem('authToken');
                localStorage.removeItem('persist:root');
  
                setTimeout(()=>{                  
                  navigate('/login');
                },5000)
              }

             
            }
            else{
                toast.warning('Something went wrong')
            }

        }
    })))
  },[])

  return (
    <div className="payment-failure">
      <h2>Payment Failure</h2>
      <p>We're sorry, but your payment was not successful.</p>
      <p>Please check your payment details and try again.</p>
      {
      fromInside ? 
        <Link to="/settings" state={{activeTab:'subscription'}}>
          <button className="back-to-login-button">Back to Manage Subscription</button>
        </Link>
      :
        <Link to="/choose-roles-for=signin">
          <button className="back-to-login-button">Back to Login</button>
        </Link>
      }
    </div>
  );
}

export default PaymentFailurePage;