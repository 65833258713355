import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import * as Images from "../../../utilities/images";
import AllDocuments from '../allDocuments';
import ArchiveDocument from '../archiveDocuments';
import UploadDocuments from '../uploadDocuments';
import RecentlyDeleteDocument from '../recentlyDeleteDocument';

const DocumentView = () => {
    const location = useLocation();
    const tab = location?.state?.activeTab;
    const [activeTab, setActiveTab] = useState('allDocuments');
    return (
        <>
            <div className='opportunityMain_'>
                <div className='row'>
                    <div className='col-md-3'>
                        <div className='containerBoxleft'>
                            <button onClick={() => setActiveTab("uploadDocuments")} className="subsmallBtnblue_ mb-4">Upload Document</button>
                            <ul className='oppotunityMenu'>
                                <li onClick={() => setActiveTab("allDocuments")} className='settingItems'>
                                    <Link to='' className={`${activeTab === 'allDocuments' ? 'settingLinks active' : ''}settingLinks`}>
                                        <img src={Images.reviewicon} alt='document' />
                                        <span>View Documents</span>
                                    </Link>
                                </li>

                                <hr className='topBorder' />
                                <li onClick={() => setActiveTab("archiveDocument")} className='settingItems'>
                                    <Link to='' className={`${activeTab === 'archiveDocument' ? 'settingLinks active' : ''}settingLinks`}>
                                        <img src={Images.archived} alt='archived' />
                                        <span>Archived</span>
                                    </Link>
                                </li>
                                <hr className='topBorder' />
                                <li onClick={() => setActiveTab("recentlyDelete")} className='settingItems'>
                                    <Link to='' className={`${activeTab === 'recentlyDelete' ? 'settingLinks active' : ''}settingLinks`}>
                                        <img src={Images.deletedicon} alt='recentlyDelete' />
                                        <span>Recently Deleted</span>
                                    </Link>
                                </li>
                                {/* <hr className='topBorder' /> */}
                                {/* <li onClick={() => setActiveTab("reviewapplication")} className='settingItems'>
                                    <Link to='' className={`${activeTab === 'reviewapplication' ? 'settingLinks active' : ''}settingLinks`}>
                                        <img src={Images.reviewicon} alt='reviewapplication' />
                                        <span>Settings</span>
                                    </Link>
                                </li> */}
                            </ul>
                        </div>
                        <div className='cardContainer mt-4'>
                            <div className='cardImg'>
                                <img src={Images.featuremark} className='img-fluid' alt='CardImg' />
                            </div>
                            <div className='cardBodyArea'>
                                <h5>Featured</h5>
                                <p className=''>Lorem ipsum dolor sit amet, consectetur adipiscing.</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-9'>
                        <div className='containerBox'>
                            {activeTab == 'allDocuments' ?
                                <AllDocuments /> :
                                activeTab == "archiveDocument" ?
                                    <ArchiveDocument /> :
                                    activeTab == "recentlyDelete" ? 
                                        <RecentlyDeleteDocument /> :
                                        activeTab == "uploadDocuments" ?
                                            <UploadDocuments setActiveTab={() => setActiveTab("allDocuments")} />
                                            : ""
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DocumentView