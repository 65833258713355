import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { MuiOtpInput } from "mui-one-time-password-input";
import { userOtp, userVerifyOtp } from "../../../redux/slices/auth";
import { useAuthSelector } from "../../../redux/selector/auth";

const Verification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const authSelector = useAuthSelector();
  let user_id = location?.state?.id;
  let membershipID = location?.state?.membershipID;
  let planId = location?.state?.planId;
  const [otp, setOtp] = useState("");
  const [time, setTime] = useState(30)
  //onchange otp
  const handleChangePin = (newValue) => {
    setOtp(newValue);
  };

  //form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!otp) {
      toast.error("Please enter OTP");
      return;
    }
    let params = {
      user_id: user_id,
      otp: parseInt(otp),
      status: 1,
      // otp: otp,
    };
    dispatch(
      userOtp({
        ...params,
        cb(res) {
          if (res.status) {
            navigate("/registration", {
              state: { membershipID: membershipID, planId: planId },
            });
          } else {
          }
        },
      })
    );
  };

  const verifyOtp = () => {
    if(time > 0){
      toast.error(`Wait ${time} seconds before requesting new OTP`)
      return
    }

    let params = {
      user_id: user_id,
    };
    dispatch(
      userVerifyOtp({
        ...params,
        cb(res) {
          if (res.status) {
            setTime(30)
            const timeInterval = setInterval(() => {
              setTime(prev => {
                const newTime = prev - 1;
                if (newTime <= 0) {
                  clearInterval(timeInterval);
                }
                return newTime;
              });
            }, 1000);
          } else {
          }
        },
      })
    );
  };

  const TimeManager = ()=>{
    clearInterval()
  }

  useEffect(() => {
    document.title = "Verification";
  }, []);

  useEffect(() => {
    const timeInterval = setInterval(() => {
      setTime(prev => {
        const newTime = prev - 1;
        if (newTime <= 0) {
          clearInterval(timeInterval);
        }
        return newTime;
      });
    }, 1000);
  
    return () => {
      clearInterval(timeInterval); // Cleanup the interval on component unmount
    };
  }, []);
  
  return (
    <div className="verification">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-10 col-lg-8 text-center">
            <div className="detailsContainer">
              <h3 className="highLightTxt">Verification</h3>
              <p className="innerSubtext">
                Enter the verification code that we just sent you on your email
                address.
              </p>
              <form
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
                className="form_horizontal"
              >
                <div className="input-groups">
                  <MuiOtpInput
                    className="input_digits_"
                    length={4}
                    value={otp}
                    onChange={handleChangePin}
                  />
                  <br></br>
                </div>
                <button className="primaryBtn w-50 mt-5 mb-3" type="submit">
                  {authSelector.loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>Submit</span>
                </button>
              </form>
              <p className="innerTxt">
                Didn't receive the code yet ?{" "}
                <span className="linkTxt" onClick={() => verifyOtp()}>
                  <b>Resend</b>
                </span>
              </p>
              {time > 0 ? <h6 className="counter">{time}</h6>: ""}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verification;
