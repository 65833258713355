import React, { useState, useEffect, useRef } from "react";
import * as Images from "../../../utilities/images";
import {
  activeTab,
  addConnection,
  setActiveTab,
} from "../../../redux/slices/connection";
import {
  getAdvanceSearch,
  diverseSupplierDescription,
  diverseSupplierCertification,
  existingCertification,
  tradeMemberShip,
  saveSearch,
} from "../../../redux/slices/advanceSearch";
import {
  businessOwnershipType,
  getAllCountryList,
  getAllIndustryGroups,
  getAllIndustrySubGroups,
  getAllProvinceList,
  getCompanyProfileInfo,
} from "../../../redux/slices/auth";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAdvanceSearchSelector } from "../../../redux/selector/advanceSearch";
import ReactPaginate from "react-paginate";
import { AdvReadMoreMore } from "read-more-more";
import Multiselect from "multiselect-react-dropdown";
import { useAuthSelector } from "../../../redux/selector/auth";
import CustomModal from "../../components/common/shared/CustomModal";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import SavedSearches from "./SavedSearches";
import SaveSearch from "./SaveSearch";
import { toast } from "react-toastify";
import { useConnectionSelector } from "../../../redux/selector/connection";
import moment from "moment";

const Business = () => {
  const authSelector = useAuthSelector();
  const location = useLocation();
  const { id } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const selectedFilteredValue = queryParams.get("selectedFilters");
  const connectionData = useConnectionSelector();
  const opportuntyTabSearch = location?.state?.opportuntyTabSearch;
  const certificate_tooltip =
    " Say goodbye to tedious paperwork and complex requirements. OPPSWell simplifies and streamlines the entire certification process, empowering you to focus on growing your business. With a user-friendly interface and automated verification processes, completing your application accurately and promptly has never been easier. Gain access to procurement opportunities, strategic partnerships, and invaluable resources. Open doors to new contracts, forge connections, and showcase your commitment to diversity. ";
  const maxLength = 60;
  const ref = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState(Math.random());
  const [currentPage, setCurrentPage] = useState(0);
  const messagesEndRef = useRef(null);
  const advanceSearchSelector = useAdvanceSearchSelector();
  const [businessName, setBusinessName] = useState(1);
  const [businessNameRoles, setBusinessNameRoles] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [advanceSearchinfo, setAdvanceSearchinfo] = useState("");
  const [pageCount, setPageCount] = useState(1);
  const [driversSupplierDesc, setDriversSupplierDesc] = useState([]);
  const [driversSupplierDescriptionIds, setDriversSupplierDescriptionId] =
    useState();
  const [industryGroupsList, setindustryGroupsList] = useState();
  const [industrySubGroupsList, setindustrySubGroupsList] = useState();
  const [industryGroupId, setIndustryGroupId] = useState("");
  const [industrySubGroupId, setIndustrySubGroupId] = useState("");

  const [countryId, setCountryId] = useState("");
  const [provinceId, setProvinceId] = useState("");

  const [selected, setSelected] = useState([]);
  const [selecteTrade, setSelectedTrade] = useState("");
  const [selecteCertificate, setSelectedCertificate] = useState("");
  const [selectedSort, setSelectedSort] = useState("");
  const [changePage, setChangePage] = useState("10");
  const [businessType, setBusinessType] = useState(
    opportuntyTabSearch == 3 ? 1 : ""
  );
  const [businessTypeRole, setBusinessTypeRole] = useState(2);

  const [oppTabName, setOppTabName] = useState("");
  
  const [ownershipType, setOwnershipType] = useState("");
  const [ownershipTypeId, setOwnershipTypeId] = useState("");
  const [localServiceArea, setLocalServiceArea] = useState("");
  const [internationalServiceAreas, setInternationalServiceAreas] =
    useState("");
  const [existingCertificationData, setExistingCertificationData] = useState(
    []
  );
  const [existingCertificationId, setExistingCertificationId] = useState("");
  const [tradeMemberShipData, setTradeMemberShipData] = useState("");
  const [tradeMemberShipId, setTradeMemberShipId] = useState([]);
  const [checkboxValues, setCheckboxValues] = useState([]);
  const [checkboxValuesJV, setCheckboxValuesJV] = useState("");
  const [checkboxValuesSecondTier, setCheckboxValuesSecondTier] = useState("");
  const [key, setKey] = useState(Math.random());

  const currentDate = moment();
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "", 
    flag: "", 
  });

  const [scrollTop, setScrollTop] = useState(false);
  const [badgeInfo, setBadgeInfo] = useState("");
  const selectedFilter = location?.state?.selectedFilter;
  const [getAllcountry, setGetAllcountry] = useState("");
  const [getAllProvince, setAllProvince] = useState("");
  const [allMemberShipPlanCount, setAllMemberShipPlanCount] = useState("");

  const chooseBusinessType = authSelector?.userStep?.user?.business_type ?? authSelector?.userStep?.business_type;

  const choosePlan = authSelector?.userStep?.user?.membership_details?.name ?? authSelector?.userStep?.membership_details?.name;

  const searchCount = allMemberShipPlanCount?.search_count;
 
  //closeModal
  const handleOnCloseModal = () => {
    setScrollTop(true);
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  // this code use for select url according to button tab
  const handleButtonClick = (buttonName) => {
    // Construct the new URL without causing a full page reload
    const newUrl = `/${buttonName.toLowerCase()}`;
    setSelectedSort("")
    // Change the URL using pushState
    window.history.pushState({}, "", newUrl);
  };
  //Company Status
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      // If checkbox is checked, add the value to the array
      setCheckboxValues([...checkboxValues, value]);
    } else {
      // If checkbox is unchecked, remove the value from the array
      setCheckboxValues(checkboxValues.filter((item) => item !== value));
    }
  };

  //Open to JV supplier opportunities
  const handleCheckboxChangeJV = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      // If checkbox is checked, add the value to the array
      // setCheckboxValuesJV([...checkboxValuesJV, value]);
      // If checkbox is checked, update the checkbox values accordingly
      const updatedValues = value;
      setCheckboxValuesJV(updatedValues);
    } else {
      // If checkbox is unchecked, remove the value from the array
      // setCheckboxValuesJV(checkboxValuesJV.filter((item) => item !== value));
      // If checkbox is unchecked, remove the value from the array
      setCheckboxValuesJV("");

    }
  };


  const handleCheckboxChangeSecondTier = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      // If checkbox is checked, add the value to the array
      // setCheckboxValuesSecondTier([...checkboxValuesSecondTier, value]);
      const updatedValues = value;
      setCheckboxValuesSecondTier(updatedValues);
    } else {
      // If checkbox is unchecked, remove the value from the array
      // setCheckboxValuesSecondTier(checkboxValuesSecondTier.filter((item) => item !== value));
      setCheckboxValuesSecondTier("")
    }
  };

  // function for user veiw profile buyer & supplier.........
  const handleSubmitedViewProfile = (id, name) => {
    const formattedName = name.replace(/\s+/g, "-"); // Replace spaces with hyphens
    navigate(`/${formattedName}/${id}`);
  };

  // function for user veiw profile.........
  const handleSubmited = (id) => {
    //navigate(`/view-profile`, { state: { id: id } });
    navigate(`/${"view-profile"}/${id}`);
  };

  // for scroll to Bottom in supplier ,buyer ,opp. listing after pagination
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ block: "end", inline: "nearest" });
  };
 
  // function for business Name.........
  const businessNames = (flag, opp) => {
    setCurrentPage(0);
    setBusinessType(flag);
    setBusinessTypeRole("");
    setSearchInput("");
    setOppTabName(opp)
    AdvanceSearchList(1, 10, flag, opp);
    
    setOwnershipTypeId("");
    setDriversSupplierDescriptionId("");
    setSelected("");
    setIndustryGroupId("");
    setCountryId("");
    setIndustrySubGroupId("");
    setProvinceId("");
    setCheckboxValues("");
    setLocalServiceArea("");
    setExistingCertificationData([]);
    setExistingCertificationId("");
    setTimeout(() => {
      // Code to execute after 1 second
      ExistingCertification();
    }, 50);
    setSelectedCertificate("");
    setCheckboxValuesJV("");
    setCheckboxValuesSecondTier("");
  };


  //for add connection.........
  const handleSubmit = (id, userid) => {
    let params = {
      receiver_id: userid,
      company_id: id,
      message: "hello",
    };
    dispatch(
      addConnection({
        ...params,
        cb(res) {
          if (res.status) {
            AdvanceSearchList();
          }
        },
      })
    );
  };

  //for add drivers Supplier Description........
  const SupplierDescription = (id) => {
    setDriversSupplierDescriptionId(id);
    dispatch(
      diverseSupplierDescription({
        cb(res) {
          if (res.status) {
            setDriversSupplierDesc(res?.data?.payload);
          }
        },
      })
    );
  };

  //for add industry Groups .......
  const industryGroups = (id) => {
    dispatch(
      getAllIndustryGroups({
        cb(res) {
          if (res.status) {
            setindustryGroupsList(res?.data?.payload);
          }
        },
      })
    );
  };

  //for add industry Sub Groups .......
  const industrySubGroups = (id) => {
    if (id) {
      let params = {
        id: id,
      };
      dispatch(
        getAllIndustrySubGroups({
          ...params,
          cb(res) {
            if (res.status) {
              setindustrySubGroupsList(res?.data?.payload);
            }
          },
        })
      );
    }
  };

  //for get all countrys .......
  const allCountry = (id) => {
    dispatch(
      getAllCountryList({
        cb(res) {
          if (res.status) {
            setGetAllcountry(res?.data?.payload);
          }
        },
      })
    );
  };


  //for get all provinceList .......
  const provinceList = (id) => {
    if (id) {
      let params = {
        id: id,
      };
      dispatch(
        getAllProvinceList({
          ...params,
          cb(res) {
            if (res.status) {
              setAllProvince(res?.data?.payload?.data);
            }
          },
        })
      );
    }
  };


  // onchange input
  const handleChanged = (id, flag) => {
    if (flag == "industry") {
      setIndustryGroupId(id);
      industrySubGroups(id);
    } else {
      setIndustrySubGroupId(id);
    }
  };


  // onchange input
  const handleChangedCountry = (id, flag) => {
    if (flag == "country") {
      setCountryId(id);
      // setDiableProvince(id);
      provinceList(id);
    } else {
      setProvinceId(id);
    }
  };

  //on change page pagination
  const handlePageClick = (e) => {
    if (businessTypeRole === 2) {
      AdvanceSearchList(e.selected + 1, 10, 2, "",selectedSort);
    } else if (businessTypeRole === 1) {
      AdvanceSearchList(e.selected + 1, 10, 1, "",selectedSort);
    } else if (!businessTypeRole) {
      AdvanceSearchList(e.selected + 1, 10, "", "opportunity",selectedSort);
    }
    setCurrentPage(e.selected);
    setTimeout(() => {
      scrollToBottom();
    }, 10);
  };
  // role, opp, Sorting
  // driver supplier description
  const handleChanges = (data) => {
    setSelected(data);
    let arr = [];
    for (let i = 0; i < data.length; i++) {
      arr.push(data[i].id);
    }
    setDriversSupplierDescriptionId(arr);
  };

  const handleChangesCertifications = (data) => {
    setSelectedCertificate(data);

    let arr = [];
    for (let i = 0; i < data.length; i++) {
      arr.push(data[i].id);
    }

    setExistingCertificationId(arr);
  };

  const handleChangesTradeMembership = (data) => {
    setSelectedTrade(data);

    let arr = [];
    for (let i = 0; i < data.length; i++) {
      arr.push(data[i].id);
    }

    setTradeMemberShipId(arr);
  };

  // sort listing data
  const handleSort = (Sorting) => {
    const path = window?.location?.pathname.split('-')[0].slice(1,)
    setCurrentPage(0)
    setSelectedSort(Sorting);
    if (Sorting) {
      AdvanceSearchList(1, 10, 1, path, Sorting);
    }
  };

  //change page size
  const hangelPage = (flag) => {
    setChangePage(flag);
    if (flag == 10) {
      AdvanceSearchList(1, 10, 1);
    } else if (flag == 20) {
      AdvanceSearchList(1, 20, 1);
    } else if (flag == 30) {
      AdvanceSearchList(1, 30, 1);
    }
  };
  //  reset all filter after delete the saved filtes
  const ResetFilter = (data) => {
    if (data === "empty") {
      ResetAllFilter();
    }
  };

  // reset all filters
  const ResetAllFilter = () => {
  
    setInternationalServiceAreas("");
    setLocalServiceArea("");
    setSearchInput("");
    setOwnershipTypeId("");
    setDriversSupplierDescriptionId("");
    // SupplierDescription();
    setSelected("");
    setExistingCertificationId("");
    setSelectedCertificate("");
    setTradeMemberShipId("");
    setSelectedTrade("");
    setIndustryGroupId("");
    setCountryId("");
    setIndustrySubGroupId("");
    setProvinceId("");
    setCheckboxValues("");
    setExistingCertificationId("");
    setTradeMemberShipId("");
    // setBusinessTypeRole(2);
    setBusinessType("");
    
    setCheckboxValuesJV("");
    setCheckboxValuesSecondTier("")
    if(businessTypeRole == 1){
      setBusinessTypeRole(1)
      AdvanceSearchList(1, 10, 1);
    }
    else if (businessTypeRole == 2){
      setBusinessTypeRole(2)
      AdvanceSearchList(1, 10,2);
    }
    else{
      setBusinessType(1)
      AdvanceSearchList(1, 10, "", oppTabName)
    }
    window.scrollTo(0, 0);
  };

  // handle change address Address
  const handleSelectLocalServiceArea = async (value) => {
    const result = await geocodeByAddress(value);
    const ll = await getLatLng(result[0]);
    setLocalServiceArea(value);
  };

  // handle change address Internation Service Area
  const handleSelectInternationalServiceAreas = async (value) => {
    const result = await geocodeByAddress(value);
    const ll = await getLatLng(result[0]);
    setInternationalServiceAreas(value);
  };

  // business ownership
  const businessOwnership = (id) => {
    setOwnershipTypeId(id);
    dispatch(
      businessOwnershipType({
        cb(res) {
          if (res.status) {
            setOwnershipType(res?.data?.payload);
          } else {
          }
        },
      })
    );
  };

  // Existing certification
  const ExistingCertification = (id) => {
    setExistingCertificationId(id);
    dispatch(
      existingCertification({
        cb(res) {
          if (res.status) {
            setExistingCertificationData(res.data.payload);
          } else {
          }
        },
      })
    );
  };

  // Trade Membership
  const TradeMembership = () => {
    dispatch(
      tradeMemberShip({
        cb(res) {
          if (res.status) {
            setTradeMemberShipData(res.data.payload);
          } else {
          }
        },
      })
    );
  };

  // handle change certification
  const handleChangeCertification = (id) => {
    setExistingCertificationId(id);
  };

  // handle change certification
  const handleChangeTradeMemberShip = (id) => {
    setTradeMemberShipId(id);
  }; 

  const handleSaveSearch = (name) => {
    // console.log(businessType, "checkTYPRSDFSDF");
    const searchParams = {
      business_ownership_type: ownershipTypeId,
      diverse_supplier_description: driversSupplierDescriptionIds
        ? driversSupplierDescriptionIds.map((num) => parseInt(num)).join(",")
        : "",
      industry_groups: industryGroupId,
      industry_sub_groups: industrySubGroupId,
      company_status: checkboxValues
        ? checkboxValues?.map((num) => parseInt(num)).join(",")
        : "",
      trade_membership_id: tradeMemberShipId
        ? tradeMemberShipId?.map((num) => parseInt(num)).join(",")
        : "",
      existing_certifications_id: existingCertificationId
        ? existingCertificationId?.map((num) => parseInt(num)).join(",")
        : "",
      domestic_service_areas: localServiceArea,
      global_service_areas: internationalServiceAreas,
      // open_to_jv_supplier_opportunities: checkboxValuesJV
      //   ? checkboxValuesJV?.map((num) => parseInt(num)).join(",")
      //   : "",
        open_to_jv_supplier_opportunities: checkboxValuesJV ? checkboxValuesJV : "",
      user_role: businessType ? "" : businessTypeRole ? businessTypeRole : 2,
      business_name: searchInput.trim(),
      type: businessType ? businessType : 2,
    };



    const params = {
      name: name,
      data: searchParams,
    };

    if (searchCount >= 12  && choosePlan === "Bronze" && chooseBusinessType === 2) {
      showToast("Current plan bronze. Please upgrade your membership plan ")
    }
    else if (searchCount >= 24 && choosePlan === "Gold" && chooseBusinessType === 2) {
      showToast("Current plan gold. Please upgrade your membership plan")
    }
    else if(chooseBusinessType === 1 && searchCount >= 12 && choosePlan === "Bronze" ){
      showToast("Current plan bronze. Please upgrade your membership plan ")
    }
    else if (chooseBusinessType === 1 && searchCount >= 24 && choosePlan === "Gold") {
      showToast("Current plan gold. Please upgrade your membership plan")
    }
    else {
      // console.log(params, "paramsALL");
      dispatch(
        saveSearch({
          ...params,
          cb(res) {
            if (res.status == 201) {
              handleOnCloseModal()
              toast.success("Search filters saved successfully");
            }
          },
        })
      );
    }
  };

  const industrySubGroupsPlay = (id, subCatId) => {
    if (id) {
      let params = {
        id: id,
      };
      dispatch(
        getAllIndustrySubGroups({
          ...params,
          cb(res) {
            if (res.status) {
              setindustrySubGroupsList(res?.data?.payload);
              setIndustrySubGroupId(subCatId);
            }
          },
        })
      );
    }
  };

  const handlePlaySearch = (data) => {
    let newData = JSON.parse(data);
    // console.log(newData, "newData");

    setOwnershipTypeId(newData.business_ownership_type);

    const idsToFilterOut = newData.diverse_supplier_description
      .split(",")
      .map((val) => {
        return parseInt(val);
      });
    const filteredArray = driversSupplierDesc.filter((item) =>
      idsToFilterOut.includes(item.id)
    );
    setSelected(filteredArray);
    setDriversSupplierDescriptionId(idsToFilterOut);

    setIndustryGroupId(newData.industry_groups);

    industrySubGroupsPlay(newData.industry_groups, newData.industry_sub_groups);

    setCheckboxValues(newData.company_status.split(","));

    const TradeMemberShipIdToFilterOut = newData.trade_membership_id
      .split(",")
      .map((val) => {
        return parseInt(val);
      });
    const filteredMemberShipArray = tradeMemberShipData.filter((item) =>
      TradeMemberShipIdToFilterOut.includes(item.id)
    );
    setSelectedTrade(filteredMemberShipArray);
    setTradeMemberShipId(TradeMemberShipIdToFilterOut);

    const CertificationIdToFilterOut = newData.existing_certifications_id
      .split(",")
      .map((val) => {
        return parseInt(val);
      });
    const filteredbbCertificateArray = existingCertificationData.filter(
      (item) => CertificationIdToFilterOut.includes(item.id)
    );
    setSelectedCertificate(filteredbbCertificateArray);
    setExistingCertificationId(CertificationIdToFilterOut);

    setLocalServiceArea(newData.domestic_service_areas);

    setInternationalServiceAreas(newData.global_service_areas);

    setCheckboxValuesJV(newData.open_to_jv_supplier_opportunities.split(","));

    setBusinessType(newData.type);

    setBusinessTypeRole(newData.user_role);

     setSearchInput(newData.business_name);

    updateFilterData(1, 10, newData);
  };

  //for add connection.........
  const handleActiveTab = (id) => {
    let params = {};
    if (id === 1) {
      params.id = "business-search";
    } else if (id === 2) {
      params.id = "suppliers-search";
    } else if (id === 3) {
      params.id = "opportunities";
    }
    dispatch(
      activeTab({
        ...params,
        cb(res) { },
      })
    );
  };

  //handle filter function
  const updateFilterData = (page, limit = 10, newParams = null, opp) => {
    //for add Advance search list.........
    let params = {
      page: page,
      limit: limit,
      business_ownership_type: ownershipTypeId,
    
      country_id: countryId ? countryId : "",
      province_id: provinceId ? provinceId : "",
      second_tier_jv_opportunities: checkboxValuesSecondTier ? checkboxValuesSecondTier : "",
      open_to_jv_supplier_opportunities: checkboxValuesJV ? checkboxValuesJV : ""
    };
    if (businessTypeRole != "") {
      params = {
        ...params,
        business_name: searchInput.trim(),
        type: businessType ? businessType : 2,
        industry_groups: industryGroupId,
        industry_sub_groups: industrySubGroupId,
        diverse_supplier_description: driversSupplierDescriptionIds
          ? driversSupplierDescriptionIds.map((num) => parseInt(num)).join(",")
          : "",
        company_status: checkboxValues
          ? checkboxValues?.map((num) => parseInt(num)).join(",") 
          : "",
        trade_membership_id: tradeMemberShipId
          ? tradeMemberShipId?.map((num) => parseInt(num)).join(",")
          : "",
        global_service_areas: internationalServiceAreas,
        existing_certifications_id: existingCertificationId
          ? existingCertificationId?.map((num) => parseInt(num)).join(",")
          : "",
        // open_to_jv_supplier_opportunities: checkboxValuesJV
        //   ? checkboxValuesJV?.map((num) => parseInt(num)).join(",")
        //   : "",
        user_role: businessTypeRole ? businessTypeRole : 2,
        domestic_service_areas: localServiceArea,
      }; 
    }  
    if (businessType === 1) {
      params = {
        ...params,
        page: page,
        limit: limit,
        type: 1,
        // business_name: searchInput.trim(),
        industry_group: industryGroupId,
        industry_sub_group: industrySubGroupId,
        diversity_status: driversSupplierDescriptionIds
          ? driversSupplierDescriptionIds.map((num) => parseInt(num)).join(",")
          : "",
        existing_certificates: existingCertificationId
          ? existingCertificationId?.map((num) => parseInt(num)).join(",")
          : "",
        hq_location: localServiceArea,
      };
    }
    if(opp === "opportunity"){
      params = {
        ...params, 
        posting_title : searchInput.trim()
      }
    }
    

    if (newParams) {
      params = {
        page: page,
        limit: limit,
        ...newParams,
      };
    }

    if (searchCount >= 12  && choosePlan === "Bronze" && chooseBusinessType === 2 ) {
      showToast("Current plan bronze. Please upgrade your membership plan ")
    }
    else if (searchCount >= 24 && choosePlan === "Gold" && chooseBusinessType === 2 ) {
      showToast("Current plan gold. Please upgrade your membership plan")
    }
    else if(chooseBusinessType === 1 && searchCount >= 12 && choosePlan === "Bronze"){
      showToast("Current plan bronze. Please upgrade your membership plan ")
    }
    else if (chooseBusinessType === 1 && searchCount >= 24 && choosePlan === "Gold" ) {
      showToast("Current plan gold. Please upgrade your membership plan")
    }
    else {
        dispatch(
          getAdvanceSearch({
            ...params,
            cb(res) {
              if (res.status) {
                setAllMemberShipPlanCount(res?.data?.payload?.user_data)
                setAdvanceSearchinfo(res?.data?.payload?.data);
                setPageCount(res?.data?.payload?.total_pages);
                window.scrollTo(0, 0);
              }
            },
          })
        );
     
      
    }
  };

 

  // Reset Input Search
  const ResetInputSearch = (page = 1, limit = 10) => {
    let params = {
      page: page,
      limit: limit,
      user_role: businessTypeRole,
    };
    if (businessTypeRole != "") {
      params = {
        ...params,
        type: businessType ? businessType : 2,
      };
    }
    dispatch(
      getAdvanceSearch({
        ...params,
        cb(res) {
          if (res.status) {
            setAdvanceSearchinfo(res?.data?.payload?.data);
            setPageCount(res?.data?.payload?.total_pages);
          }
        },
      })
    );
  };

  const emptyActiveTab = () => {
    let params = {};
    dispatch(
      setActiveTab({
        ...params,
        cb(res) { },
      })
    );
  };


  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };



  useEffect(() => {
    allCountry();
    provinceList();
    SupplierDescription();
    businessOwnership();
    TradeMembership();
    ExistingCertification();
    industryGroups();
    AdvanceSearchList();
    document.title = "Business";
  }, []);


  useEffect(() => {
    if (selectedFilter && selectedFilter === "opportunity") {
      setTimeout(() => {
        businessNames(1, "opportunity");
      }, 50);
    }
  }, [selectedFilter]);

  useEffect(() => {
    if (connectionData?.activeTab?.id === "business-search") {
      setTimeout(() => {
        businessNameRole(1);
      }, 50);
    } else if (connectionData?.activeTab?.id === "suppliers-search") {
      setTimeout(() => {
        businessNameRole(2);
      }, 50);
    } else if (connectionData?.activeTab?.id === "opportunities") {
      setTimeout(() => {
        businessNames(1, "opportunity");
      }, 50);
    }
  }, [connectionData?.activeTab]);

  useEffect(() => {
    if (location.pathname === "/opportunities") {
      setTimeout(() => {
        businessNames(1, "opportunity");
      }, 50);
    }
  }, []);

  useEffect(() => {
    if (window.location.pathname === "/opportunity-search") {
      setTimeout(() => {
        businessNames(1, "opportunity");

        queryParams.delete("selectedFilters");
        const updatedQueryString = queryParams.toString();
        const newURL = `${window.location.pathname}${updatedQueryString ? `?${updatedQueryString}` : ""
          }`;

        navigate(newURL);
      }, 50);
    }
  }, [window.location.pathname === "/opportunity-search"]);

  useEffect(() => {
    const currentPath = window.location.pathname;

    if (currentPath.includes(`/opportunity/${id}/overview`)) {
      const newPath = currentPath.replace(
        `/opportunity/${id}/overview`,
        "/opportunities"
      );
      window.history.replaceState(null, "", newPath);
    }
  }, [window.location]);

  useEffect(() => {
    const currentPath = window.location.pathname;

    if (currentPath.includes(`/opportunity/${id}/apply`)) {
      const newPath = currentPath.replace(
        `/opportunity/${id}/apply`,
        "/opportunities"
      );
      window.history.replaceState(null, "", newPath);
    }
  }, [window.location]);

  useEffect(() => {
    if (searchInput != "") {
      AdvanceSearchList(1, 10, businessTypeRole ? businessTypeRole : 2);
    }
    if (businessType == 1) {
      let params = {
        type: 1,
        page: 1,
        limit: 10,
        posting_title: searchInput.trim(),
      };
      dispatch(
        getAdvanceSearch({
          ...params,
          cb(res) {
            if (res.status) {
              setAdvanceSearchinfo(res?.data?.payload?.data);
              setPageCount(res?.data?.payload?.total_pages);
            }
          },
        })
      );
    }
  }, []);

  const toastId = useRef(null);


  useEffect(() => {
    if (scrollTop) {
      window.scrollTo(0, 0);
    }
  }, [scrollTop]);



  // function for business Name Role.........
  const businessNameRole = (flag) => {
    setCurrentPage(0);
    setBusinessTypeRole(flag);
    setBusinessType("");
    setSearchInput("");
    setOppTabName("")
    setTimeout(() => {
      setSearchInput("");
      // Code to execute after 1 second
      AdvanceSearchList(1, 10, flag);
    }, 100);

    setOwnershipTypeId("");
    setDriversSupplierDescriptionId("");
    setDriversSupplierDesc([]);
    setLocalServiceArea("");
    setCheckboxValuesJV("");
    setInternationalServiceAreas("");
    // Set a timeout for 1 second
    setTimeout(() => {
      // Code to execute after 1 second
      SupplierDescription();
    }, 50);

    setExistingCertificationId("");
    setExistingCertificationData([]);

    // Set a timeout for 1 second
    setTimeout(() => {
      // Code to execute after 1 second
      ExistingCertification();
    }, 50);

    setTradeMemberShipData("");
    setTradeMemberShipId("");

    // Set a timeout for 1 second
    setTimeout(() => {
      // Code to execute after 1 second
      TradeMembership();
    }, 50);

    setSelected("");
    setSelectedTrade("");
    setSelectedCertificate("");

    setIndustryGroupId("");
    setCountryId("");
    setIndustrySubGroupId("");
    setProvinceId("");
    setCheckboxValues("");
    setCheckboxValuesSecondTier("");
  };

   //for add Advance search list........
   const AdvanceSearchList = (page, limit = 10, role, opp, Sorting) => {
    let params = {
      page: page,
      limit: limit,
      user_role: role ? role : 2,
      sort: Sorting ? Sorting : "",
    };
    if (businessTypeRole != ""  || businessType != "") {
      params = {
        ...params,
        // type: businessType ? businessType : 2,
        type: 2,
        sort: Sorting ? Sorting : 1,
        // type: businessType ? businessType : 2,
        sort: Sorting ? Sorting : 1,
        // business_name: searchInput.trim(),
      };
      // if (searchInput != "") {
      //   params = {
      //     ...params,
         

      //   };
      //   console.log(params, "IFCOMDITIONPARAMS");
      // }
    }
     if (opp === "opportunity" || opp === "opportunities") {
      params = {
        type: 1,
        page: page,
        limit: limit,
        sort: Sorting ? Sorting : 1,

      };
      if (searchInput != "") {
        params = {
          ...params,
          type: 1, 
          sort: Sorting ? Sorting : 1,
          // posting_title: searchInput.trim(),

        };
      }

    }
    if(opp == "business"){
      params.user_role = 1;
    }
    if(opp == "suppliers"){
      params.user_role = 2;
    }
    dispatch(
      getAdvanceSearch({
        ...params,
        cb(res) {
          if (res.status) {
            setAdvanceSearchinfo(res?.data?.payload?.data);
            setPageCount(res?.data?.payload?.total_pages);
            setAllMemberShipPlanCount(res?.data?.payload?.user_data)
          }
        },
      }))}; 


  return (
    <>
      <div className="businessSearch">
        <div className="row mb-2">
          <div className="col-md-12 col-lg-6 "></div>
        </div>
        <div className="row">
          <div className="col-lg-5">
            <div className="mb-3">
              {businessTypeRole == 1 ? (
                <>
                  <span className="mainHeading coloredHeading me-1">Buyer</span>
                  <span> Busines Search </span>
                </>
              ) : businessTypeRole == 2 ? (
                <>
                  <span className="mainHeading coloredHeading me-1">
                    Supplier
                  </span>
                  <span> Busines Search </span>
                </>
              ) : businessType == 1 ? (
                <>
                  <span className="mainHeading coloredHeading me-1">
                    Opportunity
                  </span>
                  <span> Overview </span>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-lg-7">
            <div className="selectListbox mb-3 text-end">
              <div className="checkmark_ radioCheck_" ref={messagesEndRef}>
                <button
                  className={`secondaryBtnGrey ${businessTypeRole == "2" ? "subsmallBtnblue_" : ""
                    }`}
                  onClick={() => {
                    emptyActiveTab();
                    businessNameRole(2);
                    handleButtonClick("suppliers-search");
                  }}
                  type="button"
                >
                  Suppliers
                </button>
              </div>
              <div className="checkmark_ radioCheck_">
                <button
                  onClick={() => {
                    emptyActiveTab();
                    businessNameRole(1);
                    handleButtonClick("business-search");
                  }}
                  type="button"
                  className={`secondaryBtnGrey ${businessTypeRole == "1" ? "subsmallBtnblue_" : ""
                    }`}
                >
                  Buyers
                </button>
              </div>
              <div className="checkmark_ radioCheck_">
                <button
                  className={`secondaryBtnGrey ${businessType == "1" ? "subsmallBtnblue_" : ""
                    }`}
                  onClick={() => {
                    emptyActiveTab();
                    businessNames(1, "opportunity");
                    handleButtonClick("opportunities");
                  }}
                  type="button"
                >
                  Opportunities
                </button>
              </div>
            </div>
          </div>

          <div className="col-md-12 col-lg-4">
            <div className="containerBox innerSidebar">
              {!businessNameRoles ? (
                <>
                  {/* <label className="postopportunity_label mb-2" htmlFor="">Keyword Search</label> */}
                  <div className="business_search">
                    <form className="inputSearchForm" onSubmit={(event)=>{event.preventDefault()}}>
                      <div className="input-group flexBox">
                        <input
                          className="custoInput"
                          value={searchInput}
                          onChange={(e) => {
                            setSearchInput(e.target.value);
                            setPagination(Math.random());
                          }}
                          type="text"
                          placeholder="Business keyword Search"
                          aria-label="Search"
                          aria-describedby="search-addon"
                        />
                        {/* {searchInput && (
                          <img
                            src={Images.crossIcon}
                            alt="crossIcon"
                            onClick={() => {
                              setSearchInput("");
                              // ResetInputSearch(1, 10);
                            }}
                            className="img-fluid crossIconSearch"
                          />
                        )} */}
                      </div>
                    </form>
                  </div>
 
                  <div className="location_ mt-3 mb-2 ">
                    <h5 className="postopportunity_label mb-2">
                      {/* Business Industry */}
                      Industry Groups
                    </h5>
                    <select
                      className="cateSelectbox"
                      required=""
                      value={industryGroupId}
                      onChange={(e) => {
                        handleChanged(e.target.value, "industry");
                      }}
                    >
                      <option value="">Select Option</option>
                      {industryGroupsList?.length > 0 ? (
                        <>
                          {industryGroupsList.map((option, id) => (
                            <option value={option?.id}>{option?.name}</option>
                          ))}
                        </>
                      ) : (
                        ""
                      )}
                    </select>
                  </div>

                  {industryGroupId ? (
                    <>
                      <div className="location_ mt-3 mb-2 ">
                        <select
                          className="cateSelectbox"
                          required=""
                          value={industrySubGroupId}
                          onChange={(e) => {
                            handleChanged(e.target.value, "subindustry");
                          }}
                        >
                          <option value="">Select Sub Industry</option>
                          {industrySubGroupsList?.length > 0 ? (
                            <>
                              {industrySubGroupsList.map((option, id) => (
                                <option value={option?.id}>
                                  {option?.name}
                                </option>
                              ))}
                            </>
                          ) : (
                            ""
                          )}
                        </select>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="location_ mt-3 mb-2 ">
                    <h5 className="postopportunity_label mb-2">
                      Country Location
                    </h5>
                    <select
                      className="cateSelectbox"
                      required=""
                      value={countryId}
                      onChange={(e) => {
                        handleChangedCountry(e.target.value, "country");
                      }}
                    >
                      <option value="" selected>Select Busines Location</option>
                      {getAllcountry?.length > 0 ? (
                        <>
                          {getAllcountry.map((option, id) => (
                            <option value={option?.id}>{option?.name}</option>
                          ))}
                        </>
                      ) : (
                        ""
                      )}
                    </select>
                  </div>

                  {countryId ? (
                    <>
                      <div className="location_ mt-3 mb-2 ">
                        {/* <h5 className="postopportunity_label mb-2">
                          Province Location
                        </h5> */}
                        <select
                          className="cateSelectbox"
                          required=""
                          value={provinceId}
                          onChange={(e) => {
                            handleChangedCountry(e.target.value, "province");
                          }}
                        >
                          <option value="">State/Province</option>
                          {getAllProvince?.length > 0 ? (
                            <>
                              {getAllProvince.map((option, id) => (
                                <option value={option?.id}>
                                  {option?.name}
                                </option>
                              ))}
                            </>
                          ) : (
                            ""
                          )}
                        </select>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="tagsType mt-3 mb-3 col-md-12">
                    <label className="postopportunity_label mb-2" htmlFor="">
                      Diverse Status
                    </label>

                    {driversSupplierDesc && (
                      <>
                        <Multiselect
                          options={driversSupplierDesc} // Options to display in the dropdown
                          selectedValues={selected} // Preselected value to persist in dropdown
                          onSelect={handleChanges} // Function will trigger on select event
                          onRemove={handleChanges} // Function will trigger on remove event
                          displayValue="name" // Property name to display in the dropdown options
                        />
                        <i class="las la-plus inputIcon"></i>
                      </>
                    )}
                  </div>

                  <div className="location_ mt-3 mb-2 selectIndex">
                    <h5 className="postopportunity_label mb-2">
                      Diverse Certifications
                    </h5>
                    {/*   */}

                    {existingCertificationData && (
                      <>
                        <div className="position-relative">
                          <Multiselect
                            options={existingCertificationData} // Options to display in the dropdown
                            selectedValues={selecteCertificate} // Preselected value to persist in dropdown
                            onSelect={handleChangesCertifications} // Function will trigger on select event
                            onRemove={handleChangesCertifications} // Function will trigger on remove event
                            displayValue="name" // Property name to display in the dropdown options
                          />
                          <i class="las la-plus inputIcon"></i>
                        </div>
                      </>
                    )}
                  </div>
                  {/* {selectedFilter === "opportunity" ||
                  selectedFilteredValue === "opportunity" ||
                  businessType === 1 ? (
                    ""
                  ) : ( */}
                  <div className="location_ mt-3 mb-2 ">
                    <h5 className="postopportunity_label mb-2">
                      Trade Memberships
                    </h5>
                    {tradeMemberShipData && (
                      <>
                        <div className="position-relative">
                          <Multiselect
                            options={tradeMemberShipData} // Options to display in the dropdown
                            selectedValues={selecteTrade} // Preselected value to persist in dropdown
                            onSelect={handleChangesTradeMembership} // Function will trigger on select event
                            onRemove={handleChangesTradeMembership} // Function will trigger on remove event
                            displayValue="name" // Property name to display in the dropdown options
                            className="selectIndex"
                          />
                          <i class="las la-plus inputIcon"></i>
                        </div>
                      </>
                    )}
                  </div>
                  {/* )} */}
                  <label className="postopportunity_label mt-3" htmlFor="">
                    Business Ownership Type
                  </label>
                  <div className="form-group col-md-12 mb-3">
                    <select
                      className="cateSelectbox"
                      required=""
                      value={ownershipTypeId}
                      onChange={(e) => {
                        businessOwnership(e.target.value);
                      }}
                    >
                      <option value="">Select Option</option>
                      {ownershipType?.length > 0 ? (
                        <>
                          {ownershipType?.map((data) => {
                            return (
                              <option value={data?.id}>{data?.name}</option>
                            );
                          })}
                        </>
                      ) : (
                        ""
                      )}
                    </select>
                  </div>

                  {selectedFilter === "opportunity" ||
                    selectedFilteredValue === "opportunity" ||
                    businessType === 1 ? (
                    ""
                  ) : (
                    // 
                    <></>
                  )}
                  {/* {selectedFilter === "opportunity" ||
                  selectedFilteredValue === "opportunity" ||
                  businessType === 1 ? (
                    ""
                  ) : ( */}
                  <>
                    <div className="form-group col-md-12 mb-3">
                      <label className="postopportunity_label">
                        Open to Joint Venture Opportunities{" "}
                      </label>
                      <div className="form-group col-md-12 mb-3">
                        <div className="d-flex mb-3">
                          <input
                            type="checkbox"
                            id="Yes"
                            value="1"
                            checked={checkboxValuesJV.includes("1")}
                            onChange={handleCheckboxChangeJV}
                            className="checkBox me-2"
                          />
                          <label
                            htmlFor="Yes"
                            className="checkBoxTxt align-items-start align-items-md-center"
                          >
                            <span>Yes</span>
                          </label>
                        </div>
                        <div className="d-flex">
                          <input
                            type="checkbox"
                            id="No"
                            value="2"
                            checked={checkboxValuesJV.includes("2")}
                            onChange={handleCheckboxChangeJV}
                            className="checkBox me-2"
                          />
                          <label
                            htmlFor="No"
                            className="checkBoxTxt align-items-start align-items-md-center"
                          >
                            <span>No</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-md-12 mb-3">
                      <label className="postopportunity_label">
                        Open to Second Tier Opportunities{" "}
                      </label>
                      <div className="form-group col-md-12 mb-3">
                        <div className="d-flex mb-3">
                          <input
                            type="checkbox"
                            id="Yes1"
                            value="1"
                            checked={checkboxValuesSecondTier.includes("1")}
                            onChange={handleCheckboxChangeSecondTier}
                            className="checkBox me-2"
                          />
                          <label
                            htmlFor="Yes1"
                            className="checkBoxTxt align-items-start align-items-md-center"
                          >
                            <span>Yes</span>
                          </label>
                        </div>
                        <div className="d-flex">
                          <input
                            type="checkbox"
                            id="No1"
                            value="2"
                            checked={checkboxValuesSecondTier.includes("2")}
                            onChange={handleCheckboxChangeSecondTier}
                            className="checkBox me-2"
                          />
                          <label
                            htmlFor="No1"
                            className="checkBoxTxt align-items-start align-items-md-center"
                          >
                            <span>No</span>
                          </label>
                        </div>
                      </div>
                    </div></>

                  {/* )} */}



                  {/* <div className="location_ mt-3 mb-2 ">
                    <h5 className="postopportunity_label mb-2">
                      Local Service Area
                    </h5>
                    <PlacesAutocomplete
                      value={localServiceArea}
                      onChange={setLocalServiceArea}
                      onSelect={handleSelectLocalServiceArea}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <input
                            {...getInputProps({
                              placeholder: "Search Places ...",
                              className:
                                " customFormControl location-search-input form-control textArea",
                            })}
                          />
                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? {
                                    backgroundColor: "#fafafa",
                                    cursor: "pointer",
                                  }
                                : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                  };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div> */}

                  {selectedFilter === "opportunity" ||
                    selectedFilteredValue === "opportunity" ||
                    businessType === 1 ? (
                    ""
                  ) : (
                    // <div className="location_ mt-3 mb-2 ">
                    //   <h5 className="postopportunity_label mb-2">
                    //     International Service Areas{" "}
                    //   </h5>
                    //   <PlacesAutocomplete
                    //     value={internationalServiceAreas}
                    //     onChange={setInternationalServiceAreas}
                    //     onSelect={handleSelectInternationalServiceAreas}
                    //   >
                    //     {({
                    //       getInputProps,
                    //       suggestions,
                    //       getSuggestionItemProps,
                    //       loading,
                    //     }) => (
                    //       <div>
                    //         <input
                    //           {...getInputProps({
                    //             placeholder: "Search Places ...",
                    //             className:
                    //               " customFormControl location-search-input form-control textArea",
                    //           })}
                    //         />
                    //         <div className="autocomplete-dropdown-container">
                    //           {loading && <div>Loading...</div>}
                    //           {suggestions.map((suggestion) => {
                    //             const className = suggestion.active
                    //               ? "suggestion-item--active"
                    //               : "suggestion-item";
                    //             // inline style for demonstration purpose
                    //             const style = suggestion.active
                    //               ? {
                    //                   backgroundColor: "#fafafa",
                    //                   cursor: "pointer",
                    //                 }
                    //               : {
                    //                   backgroundColor: "#ffffff",
                    //                   cursor: "pointer",
                    //                 };
                    //             return (
                    //               <div
                    //                 {...getSuggestionItemProps(suggestion, {
                    //                   className,
                    //                   style,
                    //                 })}
                    //               >
                    //                 <span>{suggestion.description}</span>
                    //               </div>
                    //             );
                    //           })}
                    //         </div>
                    //       </div>
                    //     )}
                    //   </PlacesAutocomplete>
                    // </div>
                    <></>
                  )}
                </>
              ) : (
                // <div className="business_search">
                //   <form className="inputSearchForm">
                //     <div className="input-group">
                //       <input
                //         className="custoInput"
                //         value={searchInput}
                //         onChange={(e) => {
                //           setSearchInput(e.target.value);
                //           setPagination(Math.random());
                //         }}
                //         type="search"
                //         placeholder="Company Search"
                //         aria-label="Search"
                //         aria-describedby="search-addon"
                //       />
                //       {searchInput && (
                //         <button
                //           className="inputClose"
                //           type="reset"
                //           onClick={() => {
                //             setSearchInput("");
                //           }}
                //         >
                //           {" "}
                //         </button>
                //       )}
                //     </div>
                //   </form>
                // </div>
                <></>
              )}

              <div className='businessBtn mt-4'>
                <button onClick={() => updateFilterData(1,10,  "", oppTabName)} className="primaryBtnSmall">Update Search</button>
                <button onClick={() => ResetAllFilter()} className="secondaryBtnGrey mt-3">Reset</button>
              </div>
              <hr className='bottomBorder_' />
              <div className='businessBtn mt-4'>
                <p className='text-center mb-3'>Like your Settings? <strong>Save them</strong></p>
                <button className="secondaryBtnOuter mb-3 saveSearch"
                  onClick={() => {
                    setModalDetail({ show: true, flag: "SaveSearch" });
                    setKey(Math.random());
                  }}>
                  Save Search <img src={Images.saveSearch2} alt="" className='ms-2' /></button>
                <button className="secondaryBtnGrey"
                  onClick={() => {
                    setModalDetail({ show: true, flag: "SavedSearches" });
                    setKey(Math.random());
                    setScrollTop(false)
                  }}>
                  View Saved Searches <img src={Images.saveSearch} alt="" className='ms-2' />
                </button>
              </div>
            </div>
            <div className="cardContainer mt-4">
              <div className="cardImg">
                <img
                  src={Images.certifyUser}
                  className="img-fluid"
                  alt="CardImg"
                />
              </div>
              <div className="cardBodyArea">
                <h5>Get Certified</h5>
                <div className="infoiconText">
                  <span className="showinfoText">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </span>
                </div>
                <p className="my-4">
                  OPPSWell Diversity Certifications for inclusive business
                  futures.{" "}
                </p>
                <div className="infoiconText m-0">
                  <Link to="/certificate">
                    <button className="primaryBtn">Learn More</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 col-lg-8">
            <div className="containerBox">
              <div className="topPagefilter">
                <div className="col-12 text-end">
                  <select
                    value={selectedSort}
                    onChange={(e) => handleSort(e.target.value)}
                    className="cateSelectbox recentlyAdded"
                    required=""
                  >
                    {/* <option value="">Select</option> */}
                    <option value="1">Recently Added</option>
                    <option value="2">Top - Bottom</option>
                  </select>
                </div>
              </div>
              {advanceSearchSelector.loading ? (
                <>
                  <div className="row">
                    <div className="col-sm-12 text-center pt-5 pb-5">
                      <div
                        className="spinner-grow text-success"
                        role="status"
                      ></div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {advanceSearchinfo?.length ? (
                    <>
                      {advanceSearchinfo.map((data, index) => {
                        let logo;
                        if (data?.company_logo) {
                          logo = data.company_logo;
                        } else if (
                          data?.user_details?.user_company_information
                            ?.company_logo
                        ) {
                          // if (data?.image_mime_type === "image/png" || data?.image_mime_type === "image/jpg" || data?.image_mime_type === "image/jpeg") {
                          logo =
                            data?.user_details?.user_company_information
                              ?.company_logo;
                          // }
                          // else if (data?.image_mime_type ===
                          // "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || data?.image_mime_type === "application/msword") {
                          // logo = Images.iconDoc
                          // }
                          // else if (data?.image_mime_type === "application/pdf") {
                          // logo = Images.iconPdf
                          // }
                        } else {
                          logo = Images.puzzleProfile;
                        }

                        return (
                          <>
                            <div
                              key={index}
                              className="businessDesincontent_ mt-4"
                            >
                              <div className="row">
                                <div className="col-lg-3 col-md-4">
                                  <div className="designImg_">
                                    <img className="imgproduct_" src={logo} />
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-8">
                                  <div className="designContent_ h-auto">
                                    <div className="designcontentTop_ align-items-start mb-2">
                                      <div className="designLeft_ pe-2">
                                        {businessType == "" ? (
                                          <p className="bussinessSector mb-0">
                                            {
                                              data?.industries_group_details
                                                ?.name
                                            }
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                        <p className="txt23 fw-bold mb-0">
                                          {data?.posting_title}
                                        </p>
                                        {businessType === 1 ? (
                                          <h4 className="companyName_ mb-0">
                                            {
                                              data?.user_details
                                                ?.user_company_information
                                                ?.company_name
                                            }
                                            {/* {businessType === 1   ? <img className="badgeImg" src={Images.badgeImg} /> : ""} */}
                                          </h4>
                                        ) : (
                                          <h4 className="txt23 fw-bold">
                                            {data?.company_name
                                              ? data?.company_name
                                              : data?.posting_title}
                                            {data?.user_details
                                              ?.business_type === 1 ? (
                                              <img
                                                className="badgeImg"
                                                src={Images.badgeImg}
                                              />
                                            ) : (
                                              ""
                                            )}
                                          </h4>
                                        )}
                                        {businessType === 1 ? (
                                          <h5 className="weeksTxt">
                                            {data?.hq_location}
                                          </h5>
                                        ) : (
                                          <h5 className="weeksTxt">
                                            {data?.domestic_service_areas}
                                          </h5>
                                        )}
                                      </div>
                                      <div className="designbtnRight buttonMinWidth">
                                        {businessType === 1 ? (
                                          <button
                                            className="subsmallBtnblue_"
                                            onClick={() => {
                                              handleActiveTab(3);
                                              handleSubmited(
                                                businessName
                                                  ? data?.id
                                                  : data?.id
                                              );
                                            }}
                                          >
                                            View Details
                                          </button>
                                        ) : (
                                          <button
                                            className="subsmallBtnblue_"
                                            onClick={() => {
                                              handleActiveTab(businessTypeRole);
                                              handleSubmitedViewProfile(
                                                businessName
                                                  ? data?.id
                                                  : data?.id,
                                                data?.company_name
                                              );
                                            }}
                                          >
                                            View Profile
                                          </button>
                                        )}

                                        {businessName != "" ? (
                                          ""
                                        ) : !data.company_connection ? (
                                          <button
                                            className="subsmallBtngreen_"
                                            onClick={() => {
                                              handleSubmit(
                                                data?.id,
                                                data?.user_id
                                              );
                                            }}
                                          >
                                            Connect
                                          </button>
                                        ) : data.company_connection?.status ==
                                          1 ? (
                                          <button
                                            className="subsmallBtngreen_"
                                            disabled="true"
                                          >
                                            Pending
                                          </button>
                                        ) : data.company_connection?.status ==
                                          2 ? (
                                          ""
                                        ) : data.company_connection?.status ==
                                          3 ? (
                                          <button
                                            className="subsmallBtngreen_"
                                            onClick={() => {
                                              handleSubmit(
                                                data?.id,
                                                data?.user_id
                                              );
                                            }}
                                          >
                                            Connect
                                          </button>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                    <>
                                      <div className="advanceDetails wordLimit mt-3">
                                        <p className="mb-0">
                                          {data?.company_service_description
                                            ? data?.company_service_description
                                            : data?.description}
                                        </p>
                                      </div>
                                      {businessType !== 1 ? (
                                        <>
                                          <ul className="recentlyDataList">
                                            {data
                                              ?.company_diverse_supplier_description
                                              ?.length > 0 ? (
                                              <>
                                                {data?.company_diverse_supplier_description?.map(
                                                  (data, index) => {
                                                    return (
                                                      <>
                                                        <li>
                                                          <div className="recentlyBtn">
                                                            {
                                                              data
                                                                ?.diverse_supplier_description_details
                                                                ?.name
                                                            }
                                                          </div>
                                                        </li>
                                                      </>
                                                    );
                                                  }
                                                )}
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </ul>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                    {businessType === 1 ? (
                                      <div className="opportunitiesPlans">
                                        <div className="d-flex">
                                          <span className="mb-0 me-1 opportunitesMonth">
                                            <img
                                              src={Images.clockIcon}
                                              className="me-2"
                                            />
                                          </span>
                                          <span className="mb-0 me-3 opportunitesMonth">
                                            {/* {(moment(data?.response_deadline_date)).diff(currentDate, 'days')}  {(moment(data?.response_deadline_date)).diff(currentDate, 'days') > 1 ? "Days" : "Day"} */}
                                            {moment(data?.created_at).fromNow()}
                                          </span>

                                          {data?.contract_length === 0 ? (
                                            <p className="mb-0 opportunitesMonth">
                                              <img
                                                src={Images.dollarIcon}
                                                className="me-2"
                                              />
                                              $
                                              {data?.opportunity_amount?.toLocaleString()}
                                            </p>
                                          ) : (
                                            <p className="mb-0 opportunitesMonth">
                                              <img
                                                src={Images.dollarIcon}
                                                className="me-2"
                                              />
                                              $
                                              {data?.opportunity_amount?.toLocaleString()}{" "}
                                              {data?.contract_length === null ? " " : "/"}{" "}
                                              {data?.contract_length === 1 ? "Yearly" : data?.contract_length === 2 ? "Monthly" :
                                                data?.contract_length === 3 ? "Weekly" : data?.contract_length === 4 ? "Daily" : data?.contract_length === 5 ? "Hourly" : ""}
                                            </p>
                                          )}
                                        </div>
                                        {/* <p className='mb-0 opportunitesPosted'>Posted {moment(data?.created_at).fromNow()}</p> */}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* {
                              businessType == 1 && index === 0 ?
                                <div className='cardContainer mt-4 d-flex diverseCard'>
                                  <div className='cardImg d-flex'>
                                    <div className='cardBodyArea'>
                                      <h5>Diverse Business <b>Certification</b></h5>
                                      <p className='my-3'>OPPSWell streamlines diverse-owned business certifications, offering a vetted stamp of approval. Explore to access Global Supplier Diversity Programs and unlock new revenue opportunities. </p>
                                      <div className="infoiconText m-0">
                                        <button className='primaryBtn' onClick={() => {
                                          navigate("/certificate")
                                        }}>Learn More</button>
                                      </div>
                                    </div>

                                    <img src={Images.diverseBussinessImg} className='img-fluid' alt='CardImg' />

                                  </div>

                                </div>
                                : 
                                businessType == "" && index === 0 ?

                                  <div className='cardContainer mt-4 d-flex diverseCard bridge'>
                                    <div className='cardImg d-flex'>
                                      <div className='cardBodyArea'>
                                        <div className="infoiconText mb-4 ms-0">
                                          <button className='primaryBtn' >Certified</button>
                                        </div>
                                        <h5><b>Bridge the Gap </b></h5>
                                        <p className='my-3'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                        <div className="infoiconText m-0 ms-0">

                                          <Link to="/certificate">
                                            <button className='primaryBtn outerBTN'>Learn More</button>
                                          </Link>
                                        </div>
                                      </div>

                                      <img src={Images.diverseBussinessImg} className='img-fluid' alt='CardImg' />

                                    </div>
                                  </div>
                                  :
                                  ""
                            } */}
                            <hr className="bottomBorder_"></hr>

                            {/* {index == 0 && businessNameRoles != null ?
                              <div className='cardContainer_ mt-4'>
                                <div className='row'>
                                  <div className='col-md-6'>
                                    <div className='cardBodyArea'>
                                      <span className='certified_ mb-4'>Certified</span>
                                      <h5>Bridge the Gap</h5>
                                      <p className=''>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore e dolore.</p>
                                      <Link to="/certificate">
                                        <button className='btnNobg_ mt-4'>Learn More</button>
                                      </Link>
                                    </div>
                                  </div>
                                  <div className='col-md-6'>
                                    <div className='bridgeImg '>
                                      <img src={Images.bridgeImg} className='img-fluid' alt='CardImg' />
                                    </div>
                                  </div>
                                </div>
                              </div> : null
                            } */}
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <b>No Data Found</b>
                  )}
                </>
              )}

              <div className="col-12 ">
                <div className="customPagination mt-4">
                  <div className="pagination">
                    {advanceSearchinfo?.length > 0 ? (
                      <ReactPaginate
                        forcePage={currentPage}
                        // key={pagination}
                        // breakLabel=""
                        // nextLabel=">"
                        nextLabel={<i className="fas fa-caret-right"></i>}
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={pageCount}
                        marginPagesDisplayed={0}
                        pageCount={pageCount}
                        previousLabel={<i className="fas fa-caret-left"></i>}
                        // previousLabel="<"
                        renderOnZeroPageCount={null}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={modalDetail.flag === "SavedSearches" ? "SavedSearches" : ""}
        ids={
          modalDetail.flag === "SavedSearches"
            ? "SavedSearches"
            : modalDetail.flag === "SaveSearch"
              ? "SaveSearch"
              : null
        }
        child={
          modalDetail.flag === "SavedSearches" ? (
            <SavedSearches
              close={() => handleOnCloseModal()}
              playSearch={(data) => handlePlaySearch(data)}
              setScrollTop={setScrollTop}
              ResetFilter={(data) => ResetFilter(data)}
            />
          ) : modalDetail.flag === "SaveSearch" ? (
            <SaveSearch
              close={() => handleOnCloseModal()}
              saveSearch={(name) => handleSaveSearch(name)}
            />
          ) : null
        }
        header={
          <div className="modalHeader_">
            <div className="common_">
              {modalDetail.flag === "SavedSearches" ? (
                <h2 className="addLabels_heading">Saved Searches</h2>
              ) : modalDetail.flag === "SaveSearch" ? (
                <h2 className="addLabels_heading">Add Name</h2>
              ) : (
                ""
              )}
            </div>
          </div>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default Business;
