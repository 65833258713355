import React, { useEffect, useState } from 'react'
import * as Images from "../../../utilities/images";
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getChatHead, myReviewRespondentsOpportunity } from '../../../redux/slices/opportunity';
import { useOpportunitySelector } from '../../../redux/selector/opportunity';
import ReactPaginate from "react-paginate";
import CustomModal from '../../components/common/shared/CustomModal';
import MessageResponseBox from './messageResponseBox';
import { useAuthSelector } from '../../../redux/selector/auth';
import ApplicationModal from './applicationModal';
import ResponseModal from './responseModal';
import { toast } from "react-toastify"

const ReviewRespondents = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authSelector = useAuthSelector();
  const chooseBusinessType = authSelector?.userStep?.user?.business_type ?? authSelector?.userStep?.business_type;

  const choosePlan = authSelector?.userStep?.user?.membership_details?.name ?? authSelector?.userStep?.membership_details?.name;

  const opportunitySelector = useOpportunitySelector();
  const [singleResponseData, setSingleResponseData] = useState()
  const [reviewRespondents, setReviewRespondents] = useState('');
  const [pageCount, setPageCount] = useState(1);
  const [key, setKey] = useState(Math.random());
  const [companyId, setCompanyId] = useState("")
  const [selectedCompanyName, setSelectedCompanyName] = useState("")
  const [selectedCompanyId, setSelectedCompanyId] = useState("")
  const [senderId, setSenderId] = useState("")
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  // API for get Review Responses Opportunity ............
  const ReviewRespondentsOpportunity = (page = 1, limit = 10) => {
    let params = {
      id: props.opportunityId,
      page: page,
      limit: limit,
    }
    dispatch(myReviewRespondentsOpportunity({
      ...params, cb(res) {
        if (res.status) {
          setReviewRespondents(res?.data?.payload?.data)
          setPageCount(res?.data?.payload?.total_pages)
        }
      }
    }))
  }

console.log(choosePlan, "choosePlan");
console.log(chooseBusinessType, "chooseBusinessType");
  const handleChat = (userID) => {
    let params = {
      id: userID,
    }
    dispatch(getChatHead({
      ...params, cb(res) {
        if (res.status) {
          if (choosePlan == "Bronze" && chooseBusinessType === 1 || choosePlan == "Bronze" && chooseBusinessType === 2 && res?.data?.payload?.connectionRequest === false) {
            toast.dismiss();
            toast.error("You need to connect to the member or Upgrade your plan")
            return
          }
          else {
            if (res?.data?.payload?.connectionRequest) {
              if (res?.data?.payload?.connectionRequest?.status === 2) {
                if (res?.data?.payload?.chatHeadDetails) {
                  navigate(`/account-message/${res?.data?.payload?.chatHeadDetails?.id}`)
                } else {
                  setModalDetail({ show: true, flag: "responseModal", })
                  setKey(Math.random())
                }

              } else {
                if (res?.data?.payload?.chatRequest) {
                  if (res?.data?.payload?.chatRequest?.status === 1) {
                    navigate(`/account-message/${res?.data?.payload?.chatHeadDetails?.id}`)
                  } else if (res?.data?.payload?.chatRequest?.status === 0) {
                    toast.warning(" Message request already sent to the user")
                  } else {
                    setModalDetail({ show: true, flag: "responseModal", })
                    setKey(Math.random())
                  }
                } else {
                  setModalDetail({ show: true, flag: "responseModal", })
                  setKey(Math.random())
                }
              }
            } else {
              if (res?.data?.payload?.chatRequest) {
                if (res?.data?.payload?.chatRequest?.status === 1) {
                  navigate(`/account-message/${res?.data?.payload?.chatHeadDetails?.id}`)
                }
                else if (res?.data?.payload?.chatRequest?.status === 0) {
                  toast.warning(" Message request already sent to the user")
                }

              } else {
                if (res?.data?.payload?.chatRequest) {
                  if (res?.data?.payload?.chatRequest?.status === 1) {
                    navigate(`/account-message/${res?.data?.payload?.chatHeadDetails?.id}`)
                  } else if (res?.data?.payload?.chatRequest?.status === 0) {
                    toast.warning(" Message request already sent to the user")
                  } else {
                    setModalDetail({ show: true, flag: "responseModal", })
                    setKey(Math.random())
                  }
                } else {
                  setModalDetail({ show: true, flag: "responseModal", })
                  setKey(Math.random())
                }
              }
            }
          }

        }
      }
    }))
  }

  //on change page pagination
  const handlePageClick = (e) => {
    ReviewRespondentsOpportunity(e.selected + 1);
  };

  const BackListing = (e) => {
    e.preventDefault();
    props.setActiveTab("reviewapplication");
    const newUrl = `/create-opportunities/review-response`;
    window.history.pushState({}, '', newUrl);
    localStorage.setItem("selectedOpportunity", "reviewapplication")
  }

  // function for user veiw profile buyer & supplier.........
  const handleSubmitedViewProfile = (id, name) => {
    const formattedName = name.replace(/\s+/g, "-"); // Replace spaces with hyphens
    navigate(`/${formattedName}/${id}`);
  };


  useEffect(() => {
    ReviewRespondentsOpportunity()
    document.title = "opportunities";
  }, []);

  return (
    <div className='viewRespondents'>
      <div className='row'>
        <div className='col-md-6 col-lg-6 '>
          <h3 className="headingTitle_ mb-2">Review Respondents</h3>
        </div>
        <div className='col-md-6 col-lg-6 text-end'>
          <button className='smallgreyBtn_' onClick={(e) => BackListing(e)}>Back to Listing</button>
        </div>
      </div>



      <h4 className='bluesubHeadd_ mb-3'>{reviewRespondents && reviewRespondents[0]?.post_opportunity_details?.posting_title}
      </h4>
      {opportunitySelector.loading ?
        <>
          <div className="row">
            <div className="col-sm-12 text-center pt-5 pb-5">
              <div className="spinner-grow text-success" role="status"></div>
            </div>
          </div>
        </>
        :
        <>
          {reviewRespondents?.length > 0 ?
            <>
              {reviewRespondents?.map((data, index) => {
                return (
                  <>
                    <div className='businessDesincontent_ reviewRespondent_ mt-3'>
                      <div className='row'>
                        <div className='col-lg-2 col-md-2'>
                          <div className='chatUserImg reviewImage' onClick={() => {
                            handleSubmitedViewProfile(
                              data?.user_details?.user_company_information?.id,
                              data?.user_details?.user_company_information?.company_name
                            );
                          }}>
                            <img className='imgproduct_ ' src={data?.user_details?.user_company_information?.company_logo ? data?.user_details?.user_company_information?.company_logo : Images.puzzleProfile} />
                          </div>
                        </div>
                        <div className='col-lg-10 col-md-10'>
                          <div className='designContent_'>
                            <div className='designcontentTop_ mb-4'>
                              <div className='designLeft_ ' >
                                <h4 className='headingTitleSmall_'>{data?.user_details?.user_profile?.first_name} {data?.user_details?.user_profile?.last_name}</h4>
                                {
                                  data?.user_details === null ? "" :
                                    <h5 className='headingTitleSmall_ '> Company : <span className='weeksTxt'>{data?.user_details?.user_company_information?.company_name}</span></h5>
                                }
                              </div>
                              <div className='designMid_'>
                                <p className='motionLoc'>
                                </p>
                              </div>
                              <div className='designbtnRight'>
                              </div>
                              <div className='reviewResponseBtns '>
                                <button className='applicationBtn_ me-2' onClick={() => {
                                  // handleChat(data?.user_id)
                                  setModalDetail({ show: true, flag: "applicationModal", })
                                  setKey(Math.random())
                                  setSingleResponseData(data)
                                }}><img src={Images.reviewResponseIcon1} alt="" />
                                </button>
                                <button className='contactBtn_' onClick={() => {
                                  handleChat(data?.user_id)
                                  // setModalDetail({ show: true, flag: "responseModal", })
                                  // setKey(Math.random())
                                  setSingleResponseData(data)
                                }}>Contact <img src={Images.reviewResponseIcon3} alt="" className='ms-2' />
                                </button>
                              </div>
                            </div>
                            {
                              data?.description === "" ? " " :
                                <p className='innerSubtextSmall mt-2'>{data?.description}</p>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )
              })
              }
            </>
            : <b>No Review Responses Found</b>
          }
          <div className="col-12 ">
            <div className="customPagination mt-4">
              <div className="pagination">
                {reviewRespondents?.length > 0 ? (
                  <ReactPaginate
                    breakLabel=""
                    // nextLabel=">"
                    nextLabel={<i className='fas fa-caret-right'></i>}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={pageCount}
                    marginPagesDisplayed={0}
                    pageCount={pageCount}
                    previousLabel={<i className='fas fa-caret-left'></i>}
                    // previousLabel="<"
                    renderOnZeroPageCount={null}
                  />
                ) : ""}
              </div>
            </div>
          </div>
        </>
      }
      {/* </div> */}
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={modalDetail.flag === "messageResponse" ? "commonWidth customContent" : ""}
        ids={modalDetail.flag === "messageResponse" ? "messageResponse" : modalDetail.flag === "applicationModal" ? "applicationModal" : modalDetail.flag === "responseModal" ? "responseModal" : "ComposeMessage"}
        child={
          modalDetail.flag === "messageResponse" ? (
            <MessageResponseBox
              close={() => handleOnCloseModal()}
              companyName={selectedCompanyName}
              selectedCompanyId={selectedCompanyId}
              receiverId={senderId}
              chatType={2}
            />
          ) :

            modalDetail.flag === "applicationModal" ? (
              <ApplicationModal
                details={singleResponseData}
                ReviewRespondentsOpportunity={ReviewRespondentsOpportunity}
                close={() => handleOnCloseModal()}
                setSelectedCompanyName={setSelectedCompanyName}
                setSenderId={setSenderId}
                setSelectedCompanyId={setSelectedCompanyId}
                setModalDetail={setModalDetail}
                setCompanyId={setCompanyId}
                modalDetail={modalDetail}
              />
            )
              :
              modalDetail.flag === "responseModal" ? (
                <ResponseModal
                  details={singleResponseData}
                  close={() => handleOnCloseModal()}
                  chatType={2}
                />
              )
                :
                ""
        }
        header={
          <div className='modalHeader_'>
            <div className="common_">
              {modalDetail.flag === "applicationModal" ?
                ""
                : modalDetail.flag === "applicationModal" ?
                  ""
                  : ""
              }
            </div>
          </div>
        }

        onCloseModal={() => handleOnCloseModal()}
      />
    </div>


  )
}

export default ReviewRespondents