import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  chatHead: [],
  messageList: [],
  messageConversation: [],
  chatInfo: [],
  sentMessage: [],
  getAllCompanyInformation: [],
  getlabels: [],
  deletelabels: [],
  assignlabels: [],
  addlabels: [],
  createDraft: [],
  getChatDraft: [],
  chatDelete: [],
  messageDelete: [],
  getTrashChat: [],
  getMessageRequest: [],
  updateRequests: [],
  messageRequests: [],
  trashChatUpdate: [],
  loading: false,
  success:false
}

export const messageSlice = createSlice({

  name: 'messages',
  initialState,
  reducers: {
    testFn: (state) => {
      state.success = false
    },
    getChatHead: (state) => {
      state.loading = true
    },
    setChatHead: (state, action) => {
      state.loading = false
      state.chatHead = action.payload
    },
    chatDelete: (state) => {
      state.loading = true
    },
    setChatDelete: (state, action) => {
      state.loading = false
      state.chatDelete = action.payload
    },
    messageDelete: (state) => {
      state.loading = true
    },
    setMessageDelete: (state, action) => {
      state.loading = false
      state.messageDelete = action.payload
    },
    getTrashChat: (state) => {
      state.loading = true
    },
    setGetTrashChat: (state, action) => {
      state.loading = false
      state.getTrashChat = action.payload
    },
    getMessageRequest: (state) => {
      state.loading = true
    },
    setGetMessageRequest: (state, action) => {
      state.loading = false
      state.getMessageRequest = action.payload
    },
    updateRequest: (state) => {
      state.loading = true
    },
    setUpdateRequest: (state, action) => {
      state.loading = false
      state.updateRequests = action.payload
    },
    messageRequest: (state) => {
      state.loading = true
    },
    setMessageRequest: (state, action) => {
      state.loading = false
      state.messageRequests = action.payload
    },
    trashChatUpdate: (state) => {
      state.loading = true
    },
    setTrashChatUpdate: (state, action) => {
      state.loading = false
      state.trashChatUpdate = action.payload
    },
    getChatInfo: (state) => {
      state.loading = true
    },
    setChatInfo: (state, action) => {
      state.loading = false
      state.chatInfo = action.payload
    },
    getSentMessage: (state) => {
      state.loading = true
    },
    setSentMessage: (state, action) => {
      state.loading = false
      state.sentMessage = action.payload
    },
    createDraft: (state) => {
      state.loading = true
    },
    setCreateDraft: (state, action) => {
      state.loading = false
      state.createDraft = action.payload
      state.success = true
    },
    getChatDraft: (state) => {
      state.loading = true
    },
    setGetChatDraft: (state, action) => {
      state.loading = false
      state.getChatDraft = action.payload
    },
    getAllCompanyInfo: (state) => {
      state.loading = true
    },
    setAllCompanyInfo: (state, action) => {
      state.loading = false
      state.getAllCompanyInformation = action.payload
    },
    getLabels: (state) => {
      state.loading = true
    },
    setLabels: (state, action) => {
      state.loading = false
      state.getlabels = action.payload
    },
    deleteLabels: (state) => {
      state.loading = true
    },
    setDeleteLabels: (state, action) => {
      state.loading = false
      state.deletelabels = action.payload
    },
    assignLabels: (state) => {
      state.loading = true
    },
    setAssignLabels: (state, action) => {
      state.loading = false
      state.assignlabels = action.payload
    },
    addLabels: (state) => {
      state.loading = true
    },
    setAddLabels: (state, action) => {
      state.loading = false
      state.addlabels = action.payload
    },
    getMessageList: (state) => {
      state.loading = true
    },
    setMessageList: (state, action) => {
      state.loading = false
      state.messageList = action.payload
    },
    getMessageConversation: (state) => {
      state.loading = true
    },
    setMessageConversation: (state, action) => {
      state.loading = false
      state.messageConversation = action.payload
    },
    addLabel: (state) => {
      state.loading = true
    },
    onErrorStopLoad: (state) => {
      state.loading = false
    }
  }
})

// Action creators are generated for each case reducer function
export const { assignLabels,testFn, setAssignLabels, getLabels, setLabels, deleteLabels, setDeleteLabels, getMessageList, setMessageList, getMessageConversation, setMessageConversation, addLabel, getChatHead, setChatHead, getChatInfo, setChatInfo, getSentMessage, setSentMessage, getAllCompanyInfo, setAllCompanyInfo, addLabels, setAddLabels, createDraft, setCreateDraft, getChatDraft, setGetChatDraft, getMessageRequest, setGetMessageRequest, updateRequest, setUpdateRequest, messageRequest, setMessageRequest, messageDelete, setMessageDelete, chatDelete, setChatDelete, getTrashChat, setGetTrashChat, trashChatUpdate, setTrashChatUpdate, onErrorStopLoad } = messageSlice.actions

export default messageSlice.reducer