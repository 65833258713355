import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import * as Images from "../../../utilities/images";
import { recentlyDeleteDocuments } from '../../../redux/slices/documents/recentlyDeleteDocument';
import { useRecentlyDeleteDocumentSelector } from "../../../redux/selector/recentlyDeleteDocument";
import ReactPaginate from "react-paginate";

const RecentlyDeleteDocument = () => {
    const dispatch = useDispatch();
    const recentlyDeleteDocumentSelector = useRecentlyDeleteDocumentSelector();
    const [recentlyDeleteDocument, setRecentlyDeleteDocument] = useState('');
    const [pageCount, setPageCount] = useState(1);

    // API for get Recently Delete Documents list........................
    const getRecentlyDeleteDocument = (page = 1,limit = 10) => {
        let params = {
            page: page,
            limit: limit,
        }
        dispatch(recentlyDeleteDocuments({
            ...params, cb(res) {
                if (res.status) {
                    setRecentlyDeleteDocument(res?.data?.payload?.data)
                    setPageCount(res?.data?.payload?.total_pages)
                }
            }
        }))
    }


    //on change page pagination
    const handlePageClick = (e) => {
        getRecentlyDeleteDocument(e.selected + 1);
    };

    useEffect(() => {
        getRecentlyDeleteDocument()
        document.title = "RecentlyDeleteDocument";
    }, []);

    return (
        <>
            <div className='allDocuments'>
                <h3 className="headingTitle_ mb-4">Delete Documents</h3>
                <div className='row'>
                    {recentlyDeleteDocumentSelector.loading ?
                        <>
                            <div className="row">
                                <div className="col-sm-12 text-center pt-5 pb-5">
                                    <div className="spinner-grow text-success" role="status"></div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            {recentlyDeleteDocument?.length ?
                                <>
                                    {recentlyDeleteDocument?.map((val, index) => {
                                        return (
                                            <>
                                                <div key={index} className='col-md-3'>
                                                    <div className='documentCrad mb-3'>
                                                    <img className='document' src={val?.document_format == "application/pdf" ? Images.PDFImage :  val?.document_format == "application/msword" || val?.document_format == "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? Images.DocImage  : val?.document } />
                                                        <h5 className='reportHead_ mt-2 mb-3'>{val?.document_name.length > 25 ? val?.document_name.substring(0,25) + "...." : val?.document_name}</h5>
                                                        {/* <a href={val.document} target="_blank" className=' subsmallBtnblue_ me-3 text-center'>
                                                                <button className="viewBtn ">
                                                                    View
                                                                </button></a> */}

                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })
                                    }
                                </>
                                : <b>No Recent Delete Document Found</b>
                            }
                        </>
                    }
                    
                </div>

                <div className="col-12 ">
                    <div className="customPagination mt-4">
                        <div className="pagination">
                            {recentlyDeleteDocument?.length > 0 ? (
                                <ReactPaginate

                                    breakLabel=""
                                    // nextLabel=">"
                                    nextLabel={<i className='fas fa-caret-right'></i>}
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={pageCount}
                                    marginPagesDisplayed={0}
                                    pageCount={pageCount}
                                    previousLabel={<i className='fas fa-caret-left'></i>}
                                    // previousLabel="<"
                                    renderOnZeroPageCount={null}
                                />
                            ) : ""}
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default RecentlyDeleteDocument