import React, { useState, useEffect } from 'react'
import * as Images from "../../../utilities/images";
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { diversityCertification, diversityOwned, getDiversityCertification } from '../../../redux/slices/certification';
import { useCertificationSelector } from '../../../redux/selector/certification';

const CertificateApp = () => {
    const dispatch = useDispatch()
    const certificationData = useCertificationSelector();
    const flag = certificationData?.diversityCertification?.step;
    const navigate = useNavigate();
    // const [businessOwners, setBusinessOwners] = useState([])
    const [ownerList, setOwnerList] = useState([])

    const handleStart = (e) => {
        e.preventDefault()
        if (ownerList.length === 0) {
            toast.error("Please select diversity business owner")
            return;
        }
        else {
            navigate('/certificate-step', { state: { ownerList } })
        }
    }

    console.log(ownerList, "owner list");

    const getDiversityCertificationData = () => {
        dispatch(getDiversityCertification({
            cb(res) {
                if (res.status) {
                    const diversityOwnedBusiness = res?.data?.payload[0]?.certificates_diversity_owned_business;
                    const businessId = diversityOwnedBusiness.map((id) => id.diversity_owned_business_id)
                    setOwnerList(businessId)
                }
                else {

                }
            }
        }))
    }

    // const getDiversityOwnedData = () => {
    //     dispatch(diversityOwned({
    //         cb(res) {
    //             if (res.status) {
    //                 setBusinessOwners(res.data.payload)
    //             }
    //             else {

    //             }
    //         }
    //     }))
    // }


    const handleOwnership = (e, id) => {
        e.preventDefault();
        if (!ownerList?.includes(id)) {
            setOwnerList([...ownerList, id]);
        }
    }


    const handleSelectedList = (e, id) => {
        e.preventDefault();
        if (ownerList.includes(id)) {
            const updatedList = ownerList.filter(item => item !== id);
            setOwnerList(updatedList);
        }
    }

    useEffect(() => {
        if (flag >= 1) {
            getDiversityCertificationData()
            navigate('/certificate-step')
        }
    }, [])

    // useEffect(() => {
    //     getDiversityOwnedData()
    // }, [])


    return (
        <>
            <section className="certificateApp">
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-md-10'>
                            <div className='certificationCon'>
                                <div className='row '>
                                    <div className='col-lg-7'>
                                        <div className='certificateAppLeft'>
                                            <h1 class="headingGreen30 mb-3 fw-800">OPPSWell  <span class="headingBlue30 fw-800">Diversity <br /></span><span class="headingBlack30">Certifications Application</span></h1>
                                            <p className='txt18 mb-0'>OPPSWell offers certification for the following <br /> diverse owned businesses:</p>
                                            <div className='certificateAppBtnCon'>
                                                <div className='row'>
                                                    {/* {businessOwners.map((owner, id) => (
                                                        <div className='col-md-6 mb-3'>
                                                          
                                                            <div class="infoiconText">
                                                               
                                                                <button onClick={(e) => {
                                                                    handleOwnership(e, id)
                                                                
                                                                }}
                                                                    onDoubleClick={(e) => {
                                                                        handleSelectedList(e, "1")
                                                                    }}
                                                                    className={`certificateAppBtn info ${ownerList?.includes(id) ? "active" : ""}`}> {owner.name}</button>
                                                                <div class="showinfoText">
                                                                    <h2>{owner.title}</h2>
                                                                    <p>{owner.description} </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))} */}
                                                    <div className='col-md-6 mb-3'>
                                                        <div class="infoiconText">
                                                            <button onClick={(e) => {
                                                                handleOwnership(e, "1")
                                                                  // Toggle the "active" class on each click
                                                                // e.currentTarget.classList.toggle("active");

                                                                // // Optionally, you can update the ownerList based on the class presence
                                                                // if (e.currentTarget.classList.contains("active")) {
                                                                //     // Add the item to ownerList if the "active" class is present
                                                                //     ownerList.push("1");
                                                                // } else {
                                                                //     // Remove the item from ownerList if the "active" class is removed
                                                                //     ownerList = ownerList.filter(item => item !== "1");
                                                                // }
                                                            }}
                                                                onDoubleClick={(e) => {
                                                                    handleSelectedList(e, "1")
                                                                }}
                                                                className={`certificateAppBtn info ${ownerList?.includes("1") ? "active" : ""}`}> Disabled</button>
                                                            <div class="showinfoText">
                                                                <h2>Business Owners Who Qualify As Disabled</h2>
                                                                <p>Being unable to engage in any substantial gainful activity (SGA)
                                                                    Having a medically determinable physical or mental impairment
                                                                    Having a physical or mental impairment that substantially limits one or more major life activities
                                                                    Having a history or record of such an impairment
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 mb-3'>
                                                        <div class="infoiconText info">
                                                            <button onClick={(e) => {
                                                                handleOwnership(e, "2")
                                                                // Toggle the "active" class on each click
                                                                // e.currentTarget.classList.toggle("active");

                                                                // // Optionally, you can update the ownerList based on the class presence
                                                                // if (e.currentTarget.classList.contains("active")) {
                                                                //     // Add the item to ownerList if the "active" class is present
                                                                //     ownerList.push("2");
                                                                // } else {
                                                                //     // Remove the item from ownerList if the "active" class is removed
                                                                //     ownerList = ownerList.filter(item => item !== "2");
                                                                // }
                                                            }}
                                                                onDoubleClick={(e) => {
                                                                    handleSelectedList(e, "2")

                                                                }}
                                                                className={`certificateAppBtn info ${ownerList?.includes("2") ? "active" : ""}`}>LGBTQIA+</button>
                                                            <div class="showinfoText">
                                                                <h2>Business Owners Who Qualify As LQBTQIA+</h2>
                                                                <p>Lesbian, Gay, Bisexual, Transgender, Queer/Questioning, Intersex, Asexual Other identities that fall under LGBTQIA+ include: Aromantic, Agender, Non-Binary, Pansexual</p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 mb-3'>
                                                        <div class="infoiconText info">
                                                            <button onClick={(e) => {
                                                                handleOwnership(e, "3")
                                                                  // Toggle the "active" class on each click
                                                                // e.currentTarget.classList.toggle("active");

                                                                // // Optionally, you can update the ownerList based on the class presence
                                                                // if (e.currentTarget.classList.contains("active")) {
                                                                //     // Add the item to ownerList if the "active" class is present
                                                                //     ownerList.push("3");
                                                                // } else {
                                                                //     // Remove the item from ownerList if the "active" class is removed
                                                                //     ownerList = ownerList.filter(item => item !== "3");
                                                                // }
                                                            }}
                                                                onDoubleClick={(e) => {
                                                                    handleSelectedList(e, "3")
                                                                }}
                                                                className={`certificateAppBtn info ${ownerList?.includes("3") ? "active" : ""}`}>Minority</button>

                                                            <div class="showinfoText">
                                                                <h2>Business Owners Who Qualify As Minority</h2>
                                                                <p className='mb-1'>Alaskan Native </p>
                                                                <p className='mb-1'>Asian</p>
                                                                <p className='mb-1'>Black</p>
                                                                <p className='mb-1'>Hispanic</p>
                                                                <p className='mb-1'>Indigenous American</p>
                                                                <p>Native Hawaiian/Pacific Islander</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 mb-3'>
                                                        <div class="infoiconText info">
                                                            <button onClick={(e) => {
                                                                handleOwnership(e, "4")
                                                                  // Toggle the "active" class on each click
                                                                //   e.currentTarget.classList.toggle("active");

                                                                //   // Optionally, you can update the ownerList based on the class presence
                                                                //   if (e.currentTarget.classList.contains("active")) {
                                                                //       // Add the item to ownerList if the "active" class is present
                                                                //       ownerList.push("4");
                                                                //   } else {
                                                                //       // Remove the item from ownerList if the "active" class is removed
                                                                //       ownerList = ownerList.filter(item => item !== "4");
                                                                //   }

                                                            }}
                                                                onDoubleClick={(e) => {
                                                                    handleSelectedList(e, "4")
                                                                }}
                                                                className={`certificateAppBtn info ${ownerList?.includes("4") ? "active" : ""}`}>Veteran</button>
                                                            <div class="showinfoText">
                                                                <h2>Business Owners Who Qualify As Veteran</h2>
                                                                <p>Served In The Active Military, Naval, Or Air Service Was Discharged Or Released Under Conditions Other Than Dishonorable
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 mb-3'>
                                                        <div class="infoiconText info">
                                                            <button onClick={(e) => {
                                                                handleOwnership(e, "5")
                                                                  // Toggle the "active" class on each click
                                                                //   e.currentTarget.classList.toggle("active");

                                                                //   // Optionally, you can update the ownerList based on the class presence
                                                                //   if (e.currentTarget.classList.contains("active")) {
                                                                //       // Add the item to ownerList if the "active" class is present
                                                                //       ownerList.push("5");
                                                                //   } else {
                                                                //       // Remove the item from ownerList if the "active" class is removed
                                                                //       ownerList = ownerList.filter(item => item !== "5");
                                                                //   }
                                                            }}
                                                                onDoubleClick={(e) => {
                                                                    handleSelectedList(e, "5")
                                                                }}
                                                                className={`certificateAppBtn info ${ownerList?.includes("5") ? "active" : ""}`}>Woman</button>
                                                            <div class="showinfoText">
                                                                <h2>Business Owners Who Qualify As Woman</h2>
                                                                <p className='mb-1'>Ciswoman </p>
                                                                <p>Transwoman</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 mb-3'>
                                                        <div class="infoiconText info">
                                                            <button onClick={(e) => {
                                                                handleOwnership(e, "6")
                                                                  // Toggle the "active" class on each click
                                                                //   e.currentTarget.classList.toggle("active");

                                                                //   // Optionally, you can update the ownerList based on the class presence
                                                                //   if (e.currentTarget.classList.contains("active")) {
                                                                //       // Add the item to ownerList if the "active" class is present
                                                                //       ownerList.push("6");
                                                                //   } else {
                                                                //       // Remove the item from ownerList if the "active" class is removed
                                                                //       ownerList = ownerList.filter(item => item !== "6");
                                                                //   }
                                                            }}
                                                                onDoubleClick={(e) => {
                                                                    handleSelectedList(e, "6")
                                                                }}
                                                                className={`certificateAppBtn info ${ownerList?.includes("6") ? "active" : ""}`}>Disabled Veteran</button>
                                                            <div class="showinfoText">
                                                                <h2>Business Owners Who Qualify As Disabled Veteran</h2>
                                                                <p className='mb-1'>Has A Disability That Was Incurred Or Aggravated In Line Of Duty In The Active Military, Naval Or Air Service</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className='txt15 mb-5'><i>If this describes your business and your are ready to move forward press “Get Started”.</i></p>
                                            <Link >
                                                <button className='primaryBtn' onClick={(e) => handleStart(e)}>Get Started</button>
                                            </Link>
                                            <p className='txt16 mb-0'>Back to <Link to="/dashboard" className='dashboardLink'><strong>Dashboard</strong></Link> </p>
                                        </div>
                                    </div>
                                    <div className='col-lg-5'>
                                        <div className='certificateAppRight'>
                                            <p><b>Knowing is not enough, we must apply.  Willing is not enough, we must do. </b></p>
                                            <p className='fw-500'>Johann von Goethe</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CertificateApp