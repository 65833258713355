import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import * as Images from "../../../utilities/images";
import Faq from "react-faq-component";
import { useCertificationSelector } from '../../../redux/selector/certification';


const Certificate = () => {
  const navigate = useNavigate()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const [rows, setRowsOption] = useState(null);
  const data = {

    rows: [
      {
        title:
          "What are diversity certifications?               ",
        content: `Diversity certifications are official designations bestowed upon businesses owned by individuals from historically underrepresented groups such as minorities, women, veterans, people with disabilities and people of the LGBTQIA+ community. Based upon information collected from businesses seeking certifications and thereafter reviewed, receipt of a diverse certification verifies that a particular business is diverse-owned.`,
      },
      {
        title: "Which diversity certifications does OPPSWell offer?",
        content:

          `<p>OPPSWell offers certifications for the following diverse-owned businesses:</p>
          <!--<p>Diverse certifications are important because they can help those businesses with the designations access opportunities, contracts, and partnerships that are designed to promote diversity and inclusion. Many organizations and government agencies have Supplier Diversity Programs as a part of their overall procurement process.  Via these programs, they prioritize working with diverse businesses granting them access to contracts and partnerships they might otherwise not have access to.  As such, these organizations and governments actively seek out diverse owned businesses to ensure diversity in their supplier and vendor networks thereby fostering a more inclusive business environment.</p> -->
          <ul class="resourceFaqList">
            <li>
              <div class="faqInnerList">
               <!-- <p class="mb-0">Disabled Owned</p> <span class="ms-5 d-inline-block">- Minority Owned </span> -->
                <p class="mb-0">Disabled-Owned</p>
              </div>
            </li>
            <li>
              <div class="faqInnerList">
                <p class="mb-0">Disabled Veteran-Owned</p>
              </div>
            </li>
            <li>
              <div class="faqInnerList">
                <p class="mb-0">LGBTQIA+ Owned</p>
              </div>
            </li>
            <li>
              <div class="faqInnerList">
                <p class="mb-0">Minority-Owned</p>
              </div>
            </li>
            <li>
            <div class="faqInnerList">
              <p class="mb-0">Veteran-Owned</p>
            </div>
            </li>
            <li>
            <div class="faqInnerList">
              <p class="mb-0">Woman-Owned</p>
            </div>
            </li>
          </li>
          </ul>
          `,
      },
      {
        title: "Why are diversity certifications important?",
        content: `Diverse certifications are important because they can help those businesses with the designations access opportunities, contracts and partnerships that are designed to promote diversity and inclusion. Many companies and government agencies have Supplier Diversity Programs as a part of their overall procurement process. Via these programs, they prioritize working with diverse businesses granting them access to contracts and partnerships they might otherwise not have access to. These companies and governmental agencies actively seek out diverse-owned businesses to ensure diversity in their supplier and vendor networks thereby fostering a more inclusive business environment.`,
      },
      {
        title: "Is there a market advantage to being certified as a diverse-owned business?",
        content: `Being a certified diverse-owned business can differentiate a company from its competitors and attract new customers and clients who value diversity and social responsibility. 
        
        `,
      },
      {
        title: "Who accepts your diversity certifications?",
        content: `A growing number of Fortune 500 and other companies seeking to grow their Supplier Diversity Programs. 
        
        `,
      },
      {
        title: "How will companies know that my company is certified by OPPSWell?",
        content: `Companies certified by OPPSWell will receive badges on their profile pages along with an electronic certificate once certified. 
        
        `,

      },
      {
        title: "Must my company be a Member of OPPSWell to take advantage of your certification process?",
        content: `As of now, OPPSWell certifications will only be offered to active Members on its platform.`,

      },
      {
        title: "What are requirements of the OPPSWell certification process and timeframe to receive certification?",
        content: ` <p><strong>Application Requirements:</strong></p> 
          <ul class="listDotted2">
          <li>Must be a Member of OPPSWell </li>
          <li>Must submit all required documents and information</li>
          <li>The review process and reward of certification timeframe is typically 7-14 days</li>
          <li>Members are required to renew their certification annually from the date of original certification</li>
          
          </ul>
        
   
        
        `,

      },
    ],
  };

  const certificationData = useCertificationSelector();
  const flag = certificationData?.diversityCertification?.step;

  const supplierdata = {
    rows: [
      {
        title: "Business Information",
        content: `<ul class="listDotted2">
                    <li>Business Name and Address</li>
                    <li>Industry and Products/Services</li>
                    <li>Federal Tax ID/EIN</li>
                    <li>Date of Establishment</li>
                    <li>Geographic Markets</li>
                    <li>Ownership Structure</li>
                    <li>Website (must have a website address)</li>
                    <li>State and Local Licenses</li>
                    <li>Business Genesis</li>
                    <li>Business History</li>
                  </ul> `,
      },
      {
        title: "Owner Information",
        content: `<ul class="listDotted2">
                    <li >All Owners with Percentage of Ownership</li>
                    <li>51% of Owners Must be U.S. Citizens</li>
                    <li>51% of Owners Must be Diverse</li>
                    <li>For Minority Certifications – each owner who is designated as diverse must also genetically be at least 50% minority</li>
                    <li >One document with Proof of Identify (must have picture, date of birth and gender)</li>
                    <li class="listMargin">U.S. State Issued Real ID Driver’s License, U.S. State Issued Real ID Identification Card, Birth Certificate, Naturalization Certificate, Current Passport with Signature, Permanent Legal Resident Alien Status

                   </li>
                    <li>Contact Information</li>
                    <li>Date Ownership Acquired</li>
                    <li>Licenses and Resumes</li>
                  </ul>`,
      },

      {
        title: "Business Structure",
        content: `<ul class="listDotted2">
                  <li>Articles of Incorporation/Operating Agreements</li>
                  <li>Partnership Agreements/Member Lists</li>
                  <li>Affidavit of Sole Proprietorship and attestation of active involvement in day-to-day management and operations (form will be provided)</li>
                </ul>`,
      },
      {
        title: "Management/Governance",
        content: `<ul class="listDotted2">
                    <li>C-Suite Officers</li>
                    <li>Board of Directors</li>
                    <li>People with Signatory Rights</li>
                  </ul>`,
      },
      {
        title: "Financials",
        content: `<ul class="listDotted2">
                    <li>Stock Certificates, Transfer Ledgers, Ledger Books, Register of Owners</li>
                    <li>Shareholder Agreements, Profit Sharing Agreements</li>
                    <li>Capitalization Table (must equal 100%): Initial Capitalization, Proof of Capitalization, Source of Capital</li>
                    <li>Schedule of Advances</li>
                    <li>Corporate Resolutions to Establish Bank Accounts</li>
                    <li>Bank Information: Name and Address, Type of Account, Date Opened, Signature Cards, Bank Documents Establishing Account
                    </li>
                  </ul>`,
      },
      {
        title: "Diversity Documents",
        content: `<h5 class="fw-bold">DISABLED</h5>
                  <h6>Definition</h6>
                  <ul class="listDotted2">
                    <li>Being unable to engage in any substantial gainful activity (SGA)
                   </li>
                    <li> Having a medically determinable physical or mental impairment</li>
                    <li> Having a physical or mental impairment that substantially limits one or more major life activities</li>
                    <li> Having a history or record of such an impairment</li>
                  </ul>
                  
                  <h6 class="fw-bold mt-4 mb-0 faqDocuments">Documents</h6>
                  <ul class="listDotted2">
                    <li>Benefit Verification Letter through a Social Security Account</li>
                  </ul>
                  <p class="faqText mb-0">Mandatory notarized self-attestation of disability status and of active involvement in day-to-day management and operations (form will be provided)</p>
                  <h5 class="fw-bold mt-4">DISABLED VETERAN</h5>
                  <h6>Definition</h6>
                  <ul class="listDotted2">
                    <li>Served in the active military, naval or air service</li>
                    <li>Was discharged or released under conditions other than dishonorable</li>
                    <li>Has a disability that was incurred or aggravated in the line of duty in the active military, naval or air service</li>
                    <li>Branches of Service: Army, Air Force, Navy, Marines, Coast Guard, National Guard, Reserves</li>
                  </ul>
                  <h6 class="fw-bold mt-4 mb-0 faqDocuments">Documents</h6>
                  <ul class="listDotted2">
                  <li>DD Form 214 (must state disability; if form does not state disability, must include a Disability Ratings Letter from the Veterans Administration)
                  </li>
                  
                </ul>
                <p class="faqText mb-0">Mandatory notarized self-attestation of disabled veteran status and of active involvement in day-to-day management and operations (form will be provided)</p>
                <h5 class="fw-bold mt-4">LGBTQIA+</h5>
                  <h6>Definition</h6>
                  <ul class="listDotted2">
                    <li>Lesbian, Gay, Bisexual, Transgender, Queer/Questioning, Intersex, Asexual</li>
                    <li>Also: Aromantic, Agender, Non-Binary, Pansexual</li>
                    <li>Not heterosexual and/or cisgender</li>
                  </ul>
                  <h6 class="fw-bold mt-4 mb-2 faqDocuments">Documents<br> (Provide One)</h6>
                  <ul class="listDotted2">
                  <li>Certified copy of Domestic Partnership Registration</li>
                  <li>Civil Union or Marriage Certification</li>
                  <li>Divorce Documents </li>
                  <li>Proof of domestic partnership health insurance coverage</li>
                  <li>Doctor’s letter confirming transgender status or Proof of Gender Affirmation Surgery or LGBTQIA+ membership</li>
                  <li>Copy of Birth Certificate gender marker amendment</li>
                  <li>Court-order name change with transgender status as reason for name change</li> 
                  <li>Any legal documents demonstrating legal gender marker change</li>
                </ul>
                <h6 class="fw-bold mt-4 mb-0 faqDocuments">If none of the above documents are available: </h6>
               <p class="faqTxt mb-0">At least three documents establishing a history of membership in the LGBTQIA+ community or of being in a same sex partnership or marriage. Examples:</p>
               
                <ul class="listDotted2">
                <li>Publications, awards, articles, news clips/news media or recognitions as a member of the LGBTQIA+ educational, philanthropic or social community</li>
                <li>Marketing, social media, advertising or any other materials from your business confirming LGBTQIA+ status</li>
                <li>Membership in organizations and/or clubs whose members are primarily LGBTQIA+</li>
                
              </ul>
              <p class="faqText mb-0">Mandatory notarized self-attestation of LGBTQIA+ status and of active involvement in day-to-day management and operations (form will be provided)</p>
              <h5 class="fw-bold mt-4 mb-0">MINORITY OWNED</h5>
              <h6 class="fw-bold">Definition</h6>
              <p class="mb-0">Choose one of the minority classifications:</p>
              <ul class="listDotted2">
                <li>Alaskan Native</li>
                <li>Asian</li>
                <li>Black (not of Hispanic origin)</li>
                <li>Hispanic (including persons of Mexican, Puerto Rican, Cuban and Central or South American Region)</li>
                <li>Indigenous American</li>
                <li>Native Hawaiian/Pacific Islander</li>
              </ul>
              <h6 class="fw-bold mt-4 mb-0 faqDocuments">Documents<br> For Indigenous Americans Only</h6>
              <p class="faqText mb-0">Blood Degree Certificate and/or Tribal Registry Letter</p>
              <p class="faqText mb-0">Mandatory notarized self-attestation of minority status and of active involvement in day-to-day management and operations (form will be provided)</p>
              <p class="faqText mb-0">If none of the above documents are available, at least three documents establishing a history of membership in the Indigenous American community. Examples:</p>
              <ul class="listDotted2">
              <li>Publications, awards, articles, news clips/news media or recognitions as a member of the minority educational, philanthropic or social community</li>
              <li>Marketing, advertising or any other materials from your business confirming minority status</li>
              <li>Membership in organizations and/or clubs whose members are primarily minorities</li>
              <li>Graduation from colleges and/or universities whose students are primarily minorities
              </li>
              </ul>
              <p class="faqText mb-0">Mandatory notarized self-attestation of minority status and of active involvement in day-to-day management and operations (form will be provided)
              </p>
              <h6 class="fw-bold mt-4 mb-0 faqDocuments">For All Other Minorities<br> (Provide One)</h6>
              <ul class="listDotted2">
              <li>Military Records or Government issued documents that include race  </li>
              <li>Medical Records</li>
              <li>Birth Certificate </li>
              <li>Any legal documents demonstrating minority status that includes race</li>
              
            </ul>
            <h6 class="fw-bold mt-4 mb-0 faqDocuments">If none of the above documents are available: </h6>
            <p class="faqTxt mb-0">At least three documents establishing a history of membership in the minority community. Examples:</p>
            <ul class="listDotted2">
            <li>Publications, awards, articles, news clips/news media or recognitions as a member of the minority educational, philanthropic or social community</li>
            <li>Marketing, advertising or any other materials from your business confirming minority status</li>
            <li>Membership in organizations and/or clubs whose members are primarily minorities</li>
            <li>Graduation from colleges and/or universities whose students are primarily minorities</li>
            
          </ul>
          <p class="faqText mb-0">Mandatory Notarized Self-Attestation of LGBTQIA+ Status (form will be provided)
          </p>
              <h5 class="fw-bold mt-4 mb-0">VETERAN OWNED</h5>
              <h6 class="fw-bold">Definition</h6>
              <ul class="listDotted2">
                <li>Served in the active military, naval or air service</li>
                <li>Was discharged or released under conditions other than dishonorable</li>
                <li>Branches of Service: Army, Air Force, Navy, Marines, Coast Guard, National Guard, Reserves</li>
              </ul>
              <h6 class="fw-bold mt-4 mb-0 faqDocuments">Documents</h6>
              <ul class="listDotted2">
                <li>DD Form 214</li>
                
              </ul>
              <p class="faqText mb-0">Mandatory notarized self-attestation of veteran status and of active involvement in day-to-day management and operations (form will be provided)</p>
              <h5 class="fw-bold mt-4 mb-0">WOMAN OWNED</h5>
              <h6  class="fw-bold">Definition</h6>
              <ul class="listDotted2">
                <li>Female/Ciswoman</li>
                <li>Transwoman/Transgender</li>
              </ul>
              <h6 class="fw-bold mt-4 faqDocuments">Documents<br> (Provide One)</h6>
              <ul class="listDotted2">
                <li>Passport, State Driver’s License, ID, Birth Certificate with correct gender</li>
                <li>Court order recognizing correct gender</li>
                
              </ul>
              <p class="faqText mb-0">Mandatory notarized self-attestation of gender status and of active involvement in day-to-day management and operations (form will be provided)
              </p>

              <p class="anyQueHeading mb-0">Have Certification Questions?  Let Us Know!! <br>  certquestions@oppswell.com</p>
                  `,
      },
    ],
  };

  const styles = {
    // bgColor: 'white',
    titleTextColor: "dark gray",
    rowTitleColor: "dark gray",
    // rowContentColor: 'grey',
    // arrowColor: "red",
  };

  const config = {
    animate: true,
    arrowIcon: "V",
    openOnload: 0,
    expandIcon: "+",
    collapseIcon: "-",
  };
  const SectionToScroll = (flag) => {
    var section = document.getElementById(flag);
    if (section.id == "whyItMatter") {
      section.scrollIntoView({ behavior: 'smooth' });
    }
    else if (section.id == "requirement") {
      section.scrollIntoView({ behavior: 'smooth' });
    }
    else if (section.id == "pricing") {
      section.scrollIntoView({ behavior: 'smooth' });
    }
    else if (section.id == "faq") {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const handleCertification = (e) => {
    e.preventDefault();
    if (flag >= 1) {
      navigate("/certificate-step")
    } else {
      navigate("/certificate-application")
    }
  }

  return (
    <>
      {/* Start Certificate Banner Section */}
      <section className="certificateBanner" >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="leftSec">
                <h3 className="headingBlue58 mb-4 fw-normal">OPPSWell <strong>Diversity Certifications</strong><br /> for inclusive business futures</h3>
                <p className='txtBlack26 mb-5 fw-500'>Open doors to additional contracts, strengthen existing <br /> relationships and forge new connections. </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Certificate Banner Section */}
      {/* Start Certificate Banner Section */}
      <section className="overviewLinks">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="overviewCon">
                <ul className='overviewList'>
                  <li><Link href="#" onClick={() => SectionToScroll("whyItMatter")}>Why Supplier Diversity Matters</Link></li>
                  <li><Link href="#" onClick={() => SectionToScroll("requirement")}>Requirements</Link></li>
                  <li><Link href="#" onClick={() => SectionToScroll("pricing")}>Pricing</Link></li>
                  <li><Link href="#" onClick={() => SectionToScroll("faq")}>FAQs</Link></li>
                </ul>
                {/* <div className='getCertifiedBtn '>
                  <Link to="/certificate-application">
                    <button className='getBtn'>Get Certified <img src={Images.certificateImg} alt="img" /></button>
                  </Link>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Certificate Section */}
      {/* Start Certificate Section */}
      <section className="diveersitySection">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className="diveersityLeft">
                <h1 className='headingGreen30 mb-3'><span className='oppsbell500'>OPPSWell</span> Diversity Certifications
                  {/* <span className='heading30'><br /> for  inclusive business futures</span> */}
                </h1>
                <p className='txt25 mb-3'>Say goodbye to tedious paperwork and complex requirements. <strong>OPPSWell</strong> simplifies and streamlines the entire certification process empowering you to focus on <strong>growing your business</strong>. </p>

                <p className='Certificationstxt20 mb-3'>OPPSWell’s user-friendly interface and automated verification process, makes completing your application accurately and promptly easier than ever before. And OPPSWell certified companies will receive certification “badges” on their OPPSWell profiles along with electronic certificates of certification.</p>
                <ul>
                  <artical className="listMain">
                    <img src={Images.Mark} alt="mark" className="tickMark me-2" />
                    <li className="innerSubtext">Thorough vetting, yet less onerous and invasive</li>
                  </artical>
                  <artical className="listMain">
                    <img src={Images.Mark} alt="mark" className="tickMark me-2" />
                    <li className="innerSubtext">Certification decisions in 7-14 days</li>
                  </artical>
                  <artical className="listMain">
                    <img src={Images.Mark} alt="mark" className="tickMark me-2" />
                    <li className="innerSubtext">One-stop shopping for multiple certifications</li>
                  </artical>
                </ul>
                <p className='Certificationstxt20 mb-0'>With a certification process accepted by a growing number of global supplier diversity programs, gain access to procurement opportunities, strategic partnerships and invaluable resources. </p>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="diveersityRight">
                <img src={Images.divercityLeftImg} className='img-fluid' alt="img" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Certificate Section */}
      {/* Start Supplier Section */}
      <section className="supplierSection" id="whyItMatter">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 lg-mb-0">
              <div className="supplierLeft">
                <img src={Images.supplierLeft} className='img-fluid' alt="img" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="supplierRight">
                <h1 className='headingGreen30 mb-2'><span className='oppsbell500'> Why</span> Supplier Diversity <span className='oppsbell500'>Matters</span></h1>
                <p className='textHeading25'>Procurement leaders appreciate the benefits of <strong>Supplier Diversity Programs.</strong></p>
                <p className='txt20 my-2'>Successful and innovative programs:</p>
                <ul className='supplierList mb-4'>
                  <li><p className='txt14'>Provide alternative sourcing strategies</p></li>
                  <li><p className='txt14'>Serve untapped niche markets</p></li>
                  <li><p className='txt14'>Reduce supply chain disruption risks by diversifying supplier base</p></li>
                  <li><p className='txt14'>Support the growth of underrepresented businesses</p></li>
                  <li><p className='txt14'>Demonstrate a commitment to social responsibility and inclusion</p></li>
                  <li><p className='txt14'>Increase brand loyalty and positive public perception</p></li>
                </ul>
                <p className='txt20 mb-4'>OPPSWell is putting diverse businesses on the global procurement radar screen.</p>
                {/* <p className='txt20 blueHeading'><strong>This is the OPPSWell Win-Win!!</strong></p> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Certificate Banner Section */}
      {/* Start FAQ Section  */}
      <section className="certificateFaq" id="requirement">
        <div className="container">
          <div className="containerBox">
            <div className="row">
              <div className="col-md-12 "><h3 className='heading31 fw-500 text-center mb-5'> <span className='CertificationHeading'>Diverse Certification</span> <strong>Requirements</strong></h3>
                {/* <p className='txt23 text-center mb-5'>Document Requirements</p> */}
              </div>
            </div>
            <div className="row">
              <div className="memberHomeImage_">
                <div className="row">
                  <div className="col-md-12 col-lg-12">
                    <div className="faqcontentBox_">
                      <Faq
                        getRowOptions={setRowsOption}
                        data={supplierdata}
                        styles={styles}
                        config={config}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End FAQ Section */}
      {/* Start Diversity Section */}
      <section className="diversitySection" id='pricing'>
        <div className="container">
          <div className="row align-items-center">
            <div className='diversityCon'>
              <div className="col-lg-6">
                <div className="diversityLeft">
                  <h1 className='headingGreen30 mb-3 fw-800'>OPPSWell  <span className='headingBlue30'><span className='headingBlue30 fw-800'>Diversity <br />Certification</span>  Pricing</span></h1>
                  <p className='txt24'>Initial Certifications & Annual Renewals</p>
                  <div className='diversityRenewal'>
                    <img src={Images.diversityRenewlImg} alt="icon" />
                    <div className='diversityRenLeft'>
                      <h1 className='txtBlue15'>ONE Certification</h1>
                      {/* <p className='txt20'>$1,000</p> */}
                      <p className='txt20'>TBA</p>
                    </div>
                    <div className='diversityRenLeft'>
                      <h1 className='txtBlue15'>TWO Certifications</h1>
                      {/* <p className='txt20'>$1,750</p> */}
                      <p className='txt20'>TBA</p>
                    </div>
                    <div className='diversityRenRight'>
                      <h1 className='txtBlue15'>THREE Certifications</h1>
                      {/* <p className='txt20'>$2,500</p> */}
                      <p className='txt20'>TBA</p>
                    </div>
                  </div>
                  {/* <Link>
                    <button className='getStaredBtn' onClick={(e) => handleCertification(e)}>Get Certified</button>
                  </Link> */}
                  <p><strong>Coming April 2024</strong></p>
                  <p>certquestions@oppswell.com</p>
                </div>
              </div>
              <img src={Images.diversityRightImg} alt='image' className='diversityRightImg img-fluid' />
            </div>
          </div>
        </div>
      </section>
      {/* End Certificate Banner Section */}
      {/* Start FAQ Section  */}
      <section className="certificateFaq certificateFaqSec" id="faq">
        <div className="container">
          <div className="containerBox p-0">
            <div className="row">
              <div className="col-md-12 ">
                {/* <h3 className="headingGreen36  fw-800 text-center mb-5"> */}
                <h3 className='heading31 fw-500 text-center mb-5'> <span className='CertificationHeading'>Certification</span> <strong>Frequently Asked Questions</strong>
                </h3>
                {/* <p className='txt23 text-center mb-5'>Frequently Asked Questions</p> */}
              </div>
            </div>
            <div className="row">
              <div className="memberHomeImage_">
                <div className="row">
                  <div className="col-md-12 col-lg-12">
                    <div className="faqcontentBox_">
                      <Faq
                        getRowOptions={setRowsOption}
                        data={data}
                        styles={styles}
                        config={config}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End FAQ Section */}



    </>
  )
}

export default Certificate