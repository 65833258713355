import React, { useEffect, useState } from "react";
import * as Images from "../../../utilities/images";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  createCustomer,
  createSubscription,
  deleteACard,
  paymentMethod,
} from "../../../redux/slices/stripeIntegration";
import CustomModal from "../../components/common/shared/CustomModal";
import AddPaymentCard from "./addPaymentCard";
import swal from "sweetalert";
import { useStripeIntegrationSelector } from "../../../redux/selector/stripeIntegration";


function Cards(props) {
  const dispatch = useDispatch();
  const stripeInfo = useStripeIntegrationSelector();
  const [paymentCards, setPaymentCards] = useState([]);
  const [cardLoading, setCardLoading] = useState(false);
  const [key, setKey] = useState(Math.random());

  //   const [modalDetail, setModalDetail] = useState({
  //     show: false,
  //     title: "",
  //     flag: "",
  //   });
  const handleOnCloseModal = () => {
    props?.setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  const createCustomerAction = () => {
    setCardLoading(true);
    const params = {};
    dispatch(
      createCustomer({
        ...params,
        cb(res) {
          try {
            if (res.status == 200) {
              props?.setCustomerId(res?.data?.payload?.customerId);
              setPaymentCards(res?.data?.payload?.data);
              var response = res?.data?.payload?.data;
              if (response.length > 0) {
                props?.setSelectedCard(response.slice(-1)[0]?.id);
              }
            } else {
              toast.error("Something went wrong");
            }
            setCardLoading(false);
          } catch (error) {
            toast.error("Something went wrong" + error.messsage);
          }
        },
      })
    );
    // setCardLoading(false);
  };
  const deleteCard = (id) => {
    const params = { cardNumber: id };
    if (props?.called === "settings" && paymentCards.length == 1) {
      toast.error("Minimum One Card is required");
      return;
    }
    swal({
      text: "Are you sure want to remove the card?",
      dangerMode: false,
      buttons: true,
    }).then((result) => {
      if (result) {
        dispatch(
          deleteACard({
            ...params,
            cb(res) {
              try {
                if (res.status == 200) {
                  toast.success("Card Deleted Sucessfully");
                  createCustomerAction();
                  if (props?.called !== "settings") {
                    props?.refreshCards();
                    setPaymentCards((prev) =>
                      prev.filter((prev) => prev.id !== props.selectedCard)
                    );
                    props?.setSelectedCard("");
                  }
                } else {
                  toast.error("Something went wrong");
                }
                setCardLoading(false);
              } catch (error) {}
            },
          })
        );
      }
    });
  };

  useEffect(() => {
    createCustomerAction();
  }, []);

  return (
    <>
      {stripeInfo.customerLoading ? (
        <div className="row">
          <div className="col-sm-12 text-center pt-5 pb-5">
            <div className="spinner-grow text-success" role="status"></div>
          </div>
        </div>
      ) : paymentCards && paymentCards.length > 0 ? (
        paymentCards.map((item) => {
          return (
            // <div
            //   key={item.id}
            //   className={`${
            //     item?.id === props?.selectedCard ? "cardSelected" : ""
            //   } my-4 mb-3`}
            //   onClick={() => {
            //     props?.setSelectedCard(item?.id);
            //   }}
            //   id={item?.id}
            // >
            //   <div className="card" id="payment-card">
            //     <div className="card-body manageCardBody">
            //       <div className="managePaymentNo">
            //         <div className="managePaymentNoContainer">
            //           <span className="expiryOn">
            //             Expires On &nbsp; &nbsp; CVV
            //           </span>
            //           <p className="mb-0 expiryDate">
            //             {item?.card?.exp_month >= 10
            //               ? item?.card?.exp_month
            //               : "0" + item?.card?.exp_month}
            //             /{item?.card?.exp_year} &nbsp; &nbsp; &nbsp;
            //             &nbsp;&nbsp;xxx
            //           </p>
            //         </div>
            //         <img src={Images.wifiIcon} alt="wifi" />
            //       </div>
            //       <div
            //         className="text-end my-3"
            //         onClick={() => {
            //           deleteCard(item?.id);
            //         }}
            //       >
            //         <img src={Images.deletIcon} alt="delete" />
            //       </div>
            //       <div className="managePaymentName">
            //         <div className="managePaymentNameContainer">
            //           <p className="mb-0 expiryDate"></p>
            //           <span className="expiryOn">Card No.</span>
            //           <p className="mb-0 expiryDate">
            //             xxxx xxxx xxxx xxxx {item?.card?.last4}
            //           </p>
            //         </div>
            //         <div className="masterCard">
            //           <ul className="masterCardlist">
            //             <li></li>
            //             <li></li>
            //           </ul>
            //           <span className="masterCardHeading">
            //             {item?.card?.brand}
            //           </span>
            //         </div>
            //       </div>
            //     </div>
            //   </div>
            // </div>
            <>
           
            <div
              key={item.id}
              className={`${item?.id === props?.selectedCard ? " paymentCard mt-5 cardSelected" : "paymentCard mt-5"} my-4 mb-3`}
              onClick={() => {
                props?.setSelectedCard(item?.id);
              }}
              id={item?.id}
            >
              <div className="paymentCardHead">
                <h6 className="mb-0"> {item?.card?.brand}</h6>
                <img src={Images.roundCrossIcon} className="cursor"  
                onClick={() => {
                      deleteCard(item?.id);
                    }} />
              </div>
              <div className="cardDetailsOuter mt-4">
                <div className="cardDetails me-5">
                  <h5 className="mb-0">
                    <strong>****** {item?.card?.last4}</strong>
                  </h5>
                  <p className="mb-0">CARD No.</p>
                </div>
                <div className="cardDetails">
                  <h5 className="mb-0">
                    <strong> {item?.card?.exp_month >= 10
                          ? item?.card?.exp_month
                          : "0" + item?.card?.exp_month}
                        /{item?.card?.exp_year}</strong>
                  </h5>
                  <p className="mb-0">EXPIRY</p>
                </div>
              </div>
            </div>
            </>
          );
        })
      ) : (
        <></>
      )}

      {(props?.called === "settings" || paymentCards.length <= 0 ) && paymentCards.length < 3 ? (
        <button
          className="addNewPaymentBtn mt-3"
          type="button"
          onClick={() => {
            props?.setModalDetail({
              show: true,
              flag: "paymentCard",
            });
            setKey(Math.random());
            createCustomerAction();
          }}
        >Add new payment method

        </button>
      ) : (
        <></>
      )}
      {/* Modal */}
      <CustomModal
        key={key}
        show={props?.modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={
          props?.modalDetail.flag === "paymentCard" ? "SavedSearches" : ""
        }
        ids={props?.modalDetail.flag === "paymentCard" ? "paymentCard" : null}
        child={
          <AddPaymentCard
            close={() => handleOnCloseModal()}
            updateCustomer={() => createCustomerAction()}
          />
        }
        header={
          <>
            <h1>Add a new Card</h1>
          </>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
}

export default Cards;
