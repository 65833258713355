import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as images from "../../../utilities/images";


const SupplierBusiness = () => {
    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState("");
    const [id, setId] = useState("");

    //tab click
    const tabClick = (id) => {
        setId(id)
    }
    
  return (
    <>
     <div className="row justify-content-center">
            <div className="col-md-10 text-center">
                <div className="detailsContainer">
                    <h3 className="headTxt mt-3 mb-4">Which best describes your business?</h3>
                    <form>
                        <div className='row justify-content-center'>
                            <div onClick={() => tabClick(1)} className='col-md-6 col-lg-5 mb-3'>
                                <div className={activeTab == 'Buyer' ? "rolesContainer active" : "rolesContainer"} onClick={() => setActiveTab("Buyer")}>
                                    <img src={images.buyer} alt='Buyer' className='img-fluid' />
                                    <h4 className="heading_ mt-3 mb-3">Enterprise Supplier</h4>
                                    <p className='textInner'>Annual Gross Revenue $5 Million +</p>
                                </div>
                            </div>
                            <div onClick={() => tabClick(2)} className='col-md-6 col-lg-5 mb-3'>
                                <div className={activeTab == 'Supplier' ? "rolesContainer active" : "rolesContainer"} onClick={() => setActiveTab("Supplier")}>
                                    <img src={images.supplier} alt='Supplier' className='img-fluid' />
                                    <h4 className="heading_ mt-3 mb-3">Small Business Supplier</h4>
                                    <p className='textInner'>`{"Annual Gross Revenue < $5 Million"}`</p>
                                </div>
                            </div>
                            <div className='col-12 col-md-12 col-lg-7 mt-3 mb-3'>
                                    <>
                                        <button  className='primaryBtn w-100 mt-2' type="submit">
                                            &nbsp;&nbsp;
                                            <span>Create Account</span>
                                        </button>
                                        <div className='accountRelate'>
                                            <p className='innerTxt mt-4 w-100'>Don't have an account? <button className='linkTxt'><b>Log In</b></button></p>
                                        </div>
                                    </>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
  )
}

export default SupplierBusiness