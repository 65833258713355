import React from 'react'

const SettingUpdate = (props) => {
    return (
        <form class="registrationForm row mt-3 justify-content-center">
            <div class="membershipRegistration_">
                <div className='heading text-center'>
                    <h3 className="headingSmall">Update Account</h3>
                    <p>
                        Your account has been deactivated!!
                    </p>
                </div>
            </div>
            <div class="row justify-content-center my-3">
                <div class="col-md-6">
                    <button class="submitopportunity w-75" onClick={() => { props.close() }}>OK</button>
                </div>
            </div>
        </form>
    )
}

export default SettingUpdate