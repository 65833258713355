import React, { useState } from "react"
import * as Images from "../../../utilities/images";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavItem } from "react-bootstrap";
import { useAuthSelector } from "../../../redux/selector/auth";
import { useLocation, useNavigate } from "react-router-dom";
import AuthNav from "./authNav";
import { Link } from "react-router-dom";

const MainNav = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const authSelector = useAuthSelector();
    const checkAccount = authSelector?.userInfo?.user?.is_deactivate
    const [activeTab, setActiveTab] = useState("");
    const step = authSelector?.userInfo?.user?.step <= 6 || authSelector?.userStep?.step == 6;
    const button = authSelector?.userInfo?.user?.step == 6;
    const complete_step = authSelector?.userStep?.step == 6;
    const token = localStorage.getItem("authToken")
    const path = location.pathname;


    return (
        <>
            {token && step && button === true ?
                <AuthNav /> : <div className={`authNavBar navbarContain mainNavBar ${path === '/small-business' ? 'membershipAuthNav' : path === '/terms-of-service' ? 'membershipAuthNav' : path === '/privacy-policy' ? 'membershipAuthNav' : path === '/faqs' ? 'membershipAuthNav' : path === '/contact-us' ? 'membershipAuthNav' : path === '/membership-choose-per-role' ? 'membershipAuthNav' : path === '/enterprise-business' ? 'membershipAuthNav' : ''}`} >
                    <Navbar className={`${path == "/" ? "homePage" : ""}`} expand="lg">
                        <div className="container"  >
                            <Navbar.Brand href="/">
                                <img src={Images.logoWhite} alt="logo" className="img-fluid" />
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" className="border-0">
                                <i className="las la-bars barIcon"></i>
                            </Navbar.Toggle>
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="ms-auto">
                                    <Nav.Item>
                                        <Link to="/about"
                                            className={`${activeTab === "about" ? "active" : ""}  nav-link`}
                                            // className={activeTab == 'about' ? 'active' : ''}
                                            onClick={() => setActiveTab('about')}
                                        >About</Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Link to={`/#membership`}
                                        className={`${activeTab === "membership" ? "active" : ""}  nav-link`}
                                            onClick={() => { setActiveTab('membership') }}
                                        >Membership</Link>
                                    </Nav.Item> 
                                    <Nav.Item>
                                        <Link to={`/#buyers`}
                                        className={`${activeTab === "buyers" ? "active" : ""}  nav-link`}
                                            onClick={() => setActiveTab('buyers')}
                                        >Buyers</Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                    <Link to={`/#suppliers`}
                                    className={`${activeTab === "suppliers" ? "active" : ""}  nav-link`}
                                            onClick={() => setActiveTab('suppliers')}
                                        >Suppliers</Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Link
                                        to={`/#opportunities`}
                                        className={`${activeTab === "opportunities" ? "active" : ""}  nav-link`}
                                            onClick={() => setActiveTab('opportunities')}
                                        >Opportunities</Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Link to="/#community"
                                              className={`${activeTab === "community" ? "active" : ""}  nav-link`}
                                            onClick={() => setActiveTab('community')}
                                        >Community</Link>
                                    </Nav.Item>

                                    {token && step && button ?
                                        <Nav.Item>
                                            <Link to="/resources"
                                                className={`${activeTab === "resources" ? "active" : ""}  nav-link`}
                                                onClick={() => setActiveTab('resources')}
                                            >Resources</Link>
                                        </Nav.Item>
                                        :
                                        <Nav.Item>
                                            <Link to="/resource"
                                                className={`${activeTab === "resources" ? "active" : ""}  nav-link`}
                                                onClick={() => setActiveTab('resources')}
                                            >Resources</Link>
                                        </Nav.Item>
                                    }
                                    {token && step && button ?
                                        <div className="loginDetails ms-lg-4 ms-0">
                                            <NavItem>
                                                {
                                                    checkAccount === 1 ?
                                                        <Nav.Link href="/settings/overview" className="primaryBtn">Dashboard</Nav.Link>
                                                        :
                                                        <Nav.Link href="/dashboard" className="primaryBtn">Dashboard</Nav.Link>
                                                }
                                            </NavItem>
                                        </div> : button ?
                                            <div className="loginDetails ms-lg-4 ms-0 d-block d-md-flex">
                                                <Nav.Item>
                                                    <Nav.Link href="/login"><b>Login Account</b></Nav.Link>
                                                </Nav.Item>
                                            </div> : complete_step ?
                                                <div className="loginDetails ms-lg-4 ms-0">
                                                    <NavItem>
                                                        {
                                                            checkAccount === 1 ?
                                                                <Nav.Link href="/settings/overview" className="primaryBtn">Dashboard</Nav.Link>
                                                                :
                                                                <Nav.Link href="/dashboard" className="primaryBtn">Dashboard</Nav.Link>
                                                        }
                                                    </NavItem>
                                                </div> :
                                                <div className="loginDetails ms-lg-4 ms-0 d-block d-md-flex loginButton">
                                                    <Nav.Item>
                                                        <Link to="/login"><b>Login Account</b></Link>
                                                    </Nav.Item>
                                                </div>
                                    }
                                </Nav>
                            </Navbar.Collapse>
                        </div>
                    </Navbar>
                </div>
            }
        </>
    )

}

export default MainNav;