import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { categoryData, getAllResources } from '../../../redux/slices/support'
import { useLocation } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import * as Images from "../../../utilities/images";
import CategorySearchData from '../categorySearchData'

const CategoryInfo = () => {

    const [info, setInfo] = useState("")

    const dispatch = useDispatch()
    const location = useLocation()
    const [currentPage, setCurrentPage] = useState(0);
    const [showCategoryData, setShowCategoryData] = useState(false)
    const [searchInput, setSearchInput] = useState("")
    const [searchedData, setSearchedData] = useState()
    const [pageCount, setPageCount] = useState(1);
    const parts = location.pathname.split('/');
    const categoryId = parseFloat(parts[parts.length - 1]);

    const getCategoryData = (page = 1, limit = 10) => {
        let params = {
            search: searchInput ? searchInput : "",
            id: categoryId,
            page: page,
            limit: limit,
        }
        dispatch(categoryData({
            ...params,
            cb(res) {
                if (res.status) {
                    setInfo(res?.data?.payload?.data)
                    setPageCount(res?.data?.payload?.total_pages);
                }
            }
        }))
    }

    //on change page pagination
    const handlePageClick = (e) => {
        getCategoryData(e.selected + 1, 10)
    };

    useEffect(() => {
        if (categoryId) {
            getCategoryData()
        }
    }, [categoryId])

    const handleSubmit = (e) => {
        e.preventDefault()
        setShowCategoryData(true)
    }

    const getAllUserWithSearch = (page = 1, limit = 10) => {
        let params = {
            search: searchInput ? searchInput : "",
            page: page,
            limit: limit,
        }
        dispatch(
            getAllResources({
                ...params,
                cb(res) {
                    if (res.status) {
                        setSearchedData(res?.data?.payload?.allPages?.data)
                        // setPageCount(res?.data?.payload?.data?.total_pages)
                    }
                },
            })
        );
    };

    //reset input
    const onChangeReset = () => {
        setSearchInput("")
    }


    useEffect(() => {
        getAllUserWithSearch()
    }, [searchInput])

    return (
        <div className='container'>
            {
                showCategoryData ?
                    <CategorySearchData
                        searchedData={searchedData}
                        setSearchInput={setSearchInput}
                        pageCount={pageCount}
                        getAllUserWithSearch={() => getAllUserWithSearch()}
                    />
                    :
                    <div className="suggestedArticles">
                        <div className="row">
                            <div className="col-12">
                                <div className="searchBox mx-auto newHelpDesk">
                                    <form className="inputSearchForm" onSubmit={(e) => handleSubmit(e)}>
                                        <div className="input-group me-2">
                                            <input
                                                type="text"
                                                className="form-control rounded"
                                                placeholder="How can we help?"
                                                aria-label="Search"
                                                value={searchInput}
                                                aria-describedby="search-addon"
                                                onChange={(e) => {
                                                    e.preventDefault()
                                                    setSearchInput(e.target.value)
                                                }}
                                            />
                                            {
                                                searchInput !== "" ?
                                                    <button className="searchBtn" type="reset" onClick={() => {
                                                        onChangeReset()
                                                    }}> <img src={Images.crossIcon} /> </button>
                                                    :
                                                    <button className="searchBtn" type="button">
                                                        <img src={Images.searchIcon_} alt="" />
                                                    </button>
                                            }
                                        </div>
                                        {/* <button className="searchBtn" type="button">
                                            <img src={Images.searchIcon_} alt="" />
                                        </button> */}
                                    </form>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-4">
                                    <hr className="my-5" />
                                </div>
                                {
                                    info?.length > 0 ?
                                        <>
                                            {info && info?.map((data, index) => (
                                                <div className="suggestedArticleInner mb-5" key={index}>
                                                    <h2 className="mb-2 suggestedHeading">
                                                        {data?.title}
                                                    </h2>
                                                    <span className="suggestedSubHeading mb-5">{data?.help_category_details?.name}</span>
                                                    <p className="suggestedDes" dangerouslySetInnerHTML={{ __html: data?.description }}>
                                                        {/* {data?.description} */}
                                                    </p>
                                                </div>
                                            ))}
                                        </>
                                        : "No data found"
                                }

                            </div>

                            <div className="col-12 ">
                                <div className="customPagination mt-4">
                                    <div className="pagination">
                                        {info?.length > 3 ? (
                                            <ReactPaginate
                                                forcePage={currentPage}
                                                // initialPage={currentPage}
                                                // key={pagination}
                                                // breakLabel=""
                                                // nextLabel=">"
                                                nextLabel={<i className="fas fa-caret-right"></i>}
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={pageCount}
                                                marginPagesDisplayed={0}
                                                pageCount={pageCount}
                                                previousLabel={<i className="fas fa-caret-left"></i>}
                                                // previousLabel="<"
                                                renderOnZeroPageCount={null}
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}

export default CategoryInfo