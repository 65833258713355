import React from 'react'
import BaseUrl from '../../../../constants/baseUrl'

const ResourceThumbnail = (props) => {
  return (
    <div className=''>
        <div className='bitesCrad'>
          <video controls>
            <source src={`${BaseUrl.API_URL}uploads/videos/business_resources.mp4`} className='soundbiteImg' />
          </video>
        </div>
      </div>
  )
}

export default ResourceThumbnail