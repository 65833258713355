import React, { useEffect, useState } from 'react'
import * as Images from "../../../utilities/images";
import { getLabels, getAllCompanyInfo, createDraft, messageRequest } from "../../../redux/slices/messages";
import { uploadAllDocuments } from "../../../redux/slices/documents";
import { useDispatch } from 'react-redux';
import { useAuthSelector } from "../../../redux/selector/auth";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const SendMessage = (props) => {
    console.log(props, "sdfsdfsdfsd");
    const navigate = useNavigate()
    // const socketInstance = socket();
    const authSelector = useAuthSelector()
    const userInfo = authSelector?.userInfo?.user
    const dispatch = useDispatch();
    const [searchInput, setSearchInput] = useState(props?.chatDraft?.company_details?.company_name ? props?.chatDraft?.company_details?.company_name : props?.chatConnectionCompanyName?.user_company_information?.company_name ? props?.chatConnectionCompanyName?.user_company_information?.company_name : props?.companyinfo?.companyDetails?.company_name ? props?.companyinfo?.companyDetails?.company_name : props?.opportunityCompanyName ? props?.opportunityCompanyName : "");
    const [getAllLabel, setGetAllLabel] = useState("");
    const [selectedLabel, setSelectedLabel] = useState(props?.chatDraft?.label ? props?.chatDraft?.label : "");
    const [messages, setMessages] = useState(props?.chatDraft?.message ? props?.chatDraft?.message : "");
    const [customLabel, setCustomLabel] = useState("");
    const [loading, setLoading] = useState(false);
    const [allCompanyList, setAllCompanyList] = useState("");
    const [subject, setSubject] = useState(props?.chatDraft?.subject ? props?.chatDraft?.subject : "")
    const [selectedCompaniesId, setSelectedCompaniesId] = useState(props?.chatDraft?.company_id ? props?.chatDraft?.company_id : props?.chatConnectionCompanyName?.user_company_information?.id ? props?.chatConnectionCompanyName?.user_company_information?.id : props?.companyinfo?.companyDetails?.id ? props?.companyinfo?.companyDetails?.id : props?.opportunityCompanyName ? props?.opportunityCompanyName : "");
    // Company id and Sender id came out of Redux

    const company_id = props?.companyInfo?.companyDetails?.id ? props?.companyInfo?.companyDetails?.id : props.companyInfo.chatHeadDetails?.company_id ? props.companyInfo.chatHeadDetails?.company_id : props?.companyInfo?.id ? props?.companyInfo?.id : props?.companyInfo?.user_details?.user_company_information?.id
    const receiver_id = props?.companyInfo?.companyDetails?.user_id ? props?.companyInfo?.companyDetails?.user_id : props.companyInfo.chatHeadDetails?.receiver_id ? props.companyInfo.chatHeadDetails?.receiver_id : props?.companyInfo?.user_id ? props?.companyInfo?.user_id : props?.companyInfo?.user_details?.user_company_information?.user_id


    //    start work on socket and firstMesage function for start communication
    const firstMesage = (e) => {
        console.log("first console");
        e.preventDefault();
        // const selectedId = props.chatHeadListing?.data?.find(item => item?.company_id == selectedCompaniesId)
        // if (!selectedCompaniesId) {
        //     toast.error("Please select company")
        //     return;
        // }
        if (!messages) {
            toast.error("Please enter message");
            return;
        }
        if (!subject) {
            toast.error("Please enter subject");
            return;
        }
        let params = {
            receiver_id: receiver_id,
            company_id: company_id,
            message_type: 1,
            subject: subject,
            message: messages,
            chat_type: 2
            // label: customLabel ? customLabel : selectedLabel
        }

        dispatch(messageRequest({
            ...params,
            cb(res) {
                if (res?.data) {
                    console.log(res, "respons eof chgeck");
                    if(res?.data?.msg === "Chat exists already, please take it to the inbox"){
                        navigate(`/account-message/${res?.data?.payload[0]?.id}`)
                    }
                    // if (props.from == "applicationmodal") {

                    //     navigate(`/account-message`)

                    // }
                    else{
                        // props?.handeCompany()
                        toast.success(res?.data?.msg)
                        props?.close()
                        props?.applicationModalClose()
                    }
                }
            }
        }))
    }

    // apply API for find out all companies to start communication
    const searchAllCompanyList = () => {
        let params = {
            search: searchInput ? searchInput : ""
        }
        dispatch(getAllCompanyInfo({
            ...params, cb(res) {
                if (res?.data) {
                    setAllCompanyList(res?.data?.payload?.data)
                }
            }
        }))
    };

    // apply API for find out all Lebels
    // const getLebelsList = (data) => {
    //     setSelectedLabel(data)
    //     dispatch(getLabels({
    //         cb(res) {
    //             if (res?.data) {
    //                 setGetAllLabel(res?.data?.payload)
    //             }
    //         }
    //     }))
    // };

    // apply API for save data in draft
    const createDrafts = () => {
        if (!subject) {
            toast.error("Please enter subject")
            return;
        }
        let params = {
            company_id: company_id,
            subject: subject,
            chat_type: 2
        }
        if (messages) {
            params = {
                ...params,
                message: messages,
                message_type: 1,
            }
        }
        dispatch(createDraft({
            ...params, cb(res) {
                if (res?.data) {
                    props?.close()
                    //    props?.chatDraftsRefresh()
                }
            }
        }))
    };

    // selectedCompany function for save company data in state 
    const selectedCompany = (name, id) => {
        setSearchInput(name)
        setSelectedCompaniesId(id)
        setAllCompanyList("")
    }

    // handleChnage function for change company to communication
    const handleChnage = (e) => {
        if (!e.target.value) {
            setSelectedCompaniesId("")
        }
        setSearchInput(e.target.value)
    }


    // useEffect(() => {
    //     getLebelsList(props?.chatDraft?.label ? props?.chatDraft?.label : "");
    //     document.title = "messages";
    // }, []);

    useEffect(() => {
        if (props?.opportunityCompanyName) {
            setSelectedCompaniesId(props?.opportunityCompanyId)
        }
    })


    useEffect(() => {
        searchAllCompanyList();
    }, [searchInput]);

    return (
        <>
            <form onSubmit={(e) => firstMesage(e)}>
                <div className='form-group col-md-12 mb-3'>
                    <input name="labelcustom" type='text' className='customFormControl' placeholder='Subject' value={subject} onChange={e => setSubject(e.target.value)}></input>
                </div>
                <div className='form-group col-md-12 mb-3'>
                    <textarea name="email" type='text' className='customFormControl' placeholder='Message' value={messages} onChange={e => setMessages(e.target.value)}></textarea>
                </div>

                {selectedLabel == "Custom Label" ?
                    <div className='form-group col-md-12 mb-3'>
                        <input name="labelcustom" type='text' className='customFormControl' placeholder='Type in a Topic Title for Label' value={customLabel} onChange={e => setCustomLabel(e.target.value)}></input>
                    </div> : ""
                }
                <div className='sendMessage text-center'>
                    <button type="button" className='subsmallBtnblue_ me-2' onClick={() => createDrafts()}>Save in Draft</button>
                    <button className='primaryBtnSmall' type="submit">
                        {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        <span >Send</span>
                    </button>
                </div>
            </form>

        </>
    )
}

export default SendMessage