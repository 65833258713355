import React, { useEffect, useState } from 'react'
import ReviewRespondents from './viewRespondents';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import swal from 'sweetalert';
import ReactPaginate from "react-paginate";
import { myReviewResponsesOpportunity, reviewResponseDelete } from '../../../redux/slices/opportunity';
import { useOpportunitySelector } from '../../../redux/selector/opportunity';
import { useNavigate } from 'react-router-dom';

const ReviewResponses = (props) => {
    const dispatch = useDispatch();
    const opportunitySelector = useOpportunitySelector();
    const [activeTab, setActiveTab] = useState("");
    const [reviewResponses, setReviewResponses] = useState('');
    const [opportunityId, setOpportunityId] = useState('');
    const [pageCount, setPageCount] = useState(1);
    const navigate = useNavigate()

    //on change page pagination
    const handlePageClick = (e) => {
        ReviewResponsesOpportunity(e.selected + 1);
    };

    // handle view response 
    const handleViewResponse = (flag, id) => {
        setActiveTab(flag)
        setOpportunityId(id)
    }

    // API for get Review Responses Opportunity ............
    const ReviewResponsesOpportunity = (page = 1, limit = 10) => {
        let params = {
            page: page,
            limit: limit,
        }
        dispatch(myReviewResponsesOpportunity({
            ...params, cb(res) {
                if (res.status) {
                    setReviewResponses(res?.data?.payload?.data)
                    setPageCount(res?.data?.payload?.total_pages)
                }
            }
        }))
    }

    // Review Responses  delete action
    const reviewResponsesDeleteAction = (id) => {
        swal({
            text: `Are you sure want to permanently remove  this opportunity?`,
            dangerMode: false,
            buttons: true,
        })
            .then(result => {
                if (result) {
                    const params = {
                        id: id
                    }
                    dispatch(reviewResponseDelete({
                        ...params, cb(res) {
                            if (res.status) {
                                setPageCount(1)
                                ReviewResponsesOpportunity()
                            }
                        }
                    }))
                }
            })
            .then(result => {
                if (result) {
                    const params = {
                        id: id
                    }
                    dispatch(reviewResponseDelete({
                        ...params, cb(res) {
                            if (res.status) {
                                setPageCount(1)
                                ReviewResponsesOpportunity()
                            }
                        }
                    }))
                }
            })

    }

    // this code use for select url according to button tab
    const handleButtonClick = (buttonName, id) => {
        // Change the URL using pushState
        navigate('/create-opportunities/view-response', { state: { activeTab: "ReviewRespondents", oppId: id } })
        localStorage.setItem("selectedOpportunity", "ReviewRespondents")
    };

    const handleTabChange = (tabIndex) => {
        setActiveTab(tabIndex);
        // Save the active tab to localStorage
        localStorage.setItem("selectedOpportunity", tabIndex);
    };


    useEffect(() => {
        ReviewResponsesOpportunity()
        document.title = "opportunities";
    }, []);

    return (
        <>
            {activeTab == "ReviewRespondents" ?
                <ReviewRespondents
                    opportunityId={opportunityId}
                    setActiveTab={() => {
                        setActiveTab("ReviewResponses")
                    }}
                /> :
                <div className='reviewResponse'>
                    <h3 className="headingTitle_ mb-4">Review Responses</h3>
                    <div className='postContainerArea post-container-area'>
                        {opportunitySelector.loading ?
                            <>
                                <div className="row">
                                    <div className="col-sm-12 text-center pt-5 pb-5">
                                        <div className="spinner-grow text-success" role="status"></div>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                {reviewResponses?.length ?
                                    <>
                                        {reviewResponses?.map((data, index) => {

                                            return (
                                                <>
                                                    <div className='posts align-items-center justify-content-between mt-2 mb-4' key={index}>
                                                        <div className='reviewContent_'>
                                                            <div className='leftOpportunitycontent'>
                                                                <p className='postHours_ mb-2'>{moment(data?.created_at).fromNow()}</p>
                                                                <h6 className='opportunityHead_ '>{data?.posting_title}</h6>
                                                                <h6 className='contractLength'>{data?.currency === "USD" || data?.currency == "CAD" ? `$` : `€`}{data?.opportunity_amount.toLocaleString()} {data?.currency === "USD" || data?.currency == "CAD" ? `${(data?.currency)}` : `${(data?.currency)}`} {data?.contract_length === 0 || data?.currency === "" ? " " : "/"} {data?.contract_length === 1 ? "Yearly" : data?.contract_length === 2 ? "Monthly" :
                                                                    data?.contract_length === 3 ? "Weekly" : data?.contract_length === 4 ? "Daily" : data?.contract_length === 5 ? "Hourly" : ""}
                                                                </h6>

                                                            </div>
                                                            <div className='newApplication'>
                                                                <div className='application_count'>
                                                                    <h3 className='subheadingBlue_'>{data?.opportunity_application_count}<sub className='newsubtext'>({data?.new_count})</sub></h3>
                                                                    <p className='headingTitleLight_'>Applications</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='responseBtn_ justify-content-end'>
                                                            {/* <button onClick={() => reviewResponsesDeleteAction(data?.id)} className="subsmallBtnblack_ mb-2 me-3">Delete <i className="far fa-trash-alt ms-1"></i></button> */}
                                                            <div className="dropdown" onClick={() => {
                                                                handleTabChange("ReviewRespondents")
                                                                handleButtonClick("view-response", data?.id)
                                                                handleViewResponse("ReviewRespondents", data?.id)
                                                            }}>
                                                                <button className="subsmallBtnblue_ mb-2" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <i className="fas fa-eye "></i>
                                                                </button>
                                                                {/* <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                    <li onClick={() => {
                                                                        handleTabChange("ReviewRespondents")
                                                                        handleButtonClick("view-response", data?.id)
                                                                        handleViewResponse("ReviewRespondents", data?.id)
                                                                    }} ><a className="dropdown-item" >View</a></li>
                                                                </ul> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })
                                        }
                                    </>
                                    : <b>No Review Responses Found</b>
                                }
                            </>
                        }
                        <div className="col-12 ">
                            <div className="customPagination mt-4">
                                <div className="pagination">
                                    {reviewResponses?.length > 0 ? (
                                        <ReactPaginate
                                            breakLabel=""
                                            // nextLabel=">"
                                            nextLabel={<i className='fas fa-caret-right'></i>}
                                            onPageChange={handlePageClick}
                                            pageRangeDisplayed={pageCount}
                                            marginPagesDisplayed={0}
                                            pageCount={pageCount}
                                            previousLabel={<i className='fas fa-caret-left'></i>}
                                            // previousLabel="<"
                                            renderOnZeroPageCount={null}
                                        />
                                    ) : ""}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>}
        </>
    )
}

export default ReviewResponses