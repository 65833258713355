import React, { useState } from 'react'
import * as Images from "../../../utilities/images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomModal from '../../components/common/shared/CustomModal';
import ComposeMessage from './ComposeMessages';
import AddLabels from './AddLabels';

const MessageSideBar = (props) => {
    const navigate = useNavigate()
    const location = useLocation()
    const [activeTab, setActiveTab] = useState(location.pathname);
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });

    // for close Modal
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };

    return (
        <div className="col-md-3">
            <h3 className="mainHeading">Manage Messages</h3>
            <div className="containerBox">
                <button
                    className="subsmallBtnblue_ mb-4"
                    onClick={() => {
                        setModalDetail({ show: true, flag: "ComposeMessage" });
                        setKey(Math.random());
                    }}
                >
                    Compose Message
                </button>
                <ul className="oppotunityMenu">
                    <li
                        onClick={() => {
                            navigate("/account-message")
                        }}
                        className="settingItems"
                    >
                        <Link
                            to=""
                            className={`${activeTab === "/account-message" ? "settingLinks active" : ""
                                }settingLinks`}
                        >
                            <img src={Images.newInbox} alt="activeOpportunity" />
                            <span>Inbox</span>
                        </Link>
                    </li>
                    <hr className="topBorder" />
                    <li
                        // onClick={() => handleTabChange("Requests")}
                        onClick={() => {
                            navigate("/message-requests")
                        }}
                        className="settingItems"
                    >
                        <Link
                            to=""
                            className={`${activeTab === "/message-requests" ? "settingLinks active" : ""}settingLinks`}
                        >
                            <img src={Images.requestIcon} alt="editOpportunity" />
                            <span>Requests</span>
                        </Link>
                    </li>
                    <hr className="topBorder" />
                    <li
                        onClick={() => {
                            navigate("/drafts")
                        }}
                        className="settingItems"
                    >
                        <Link
                            to=""
                            className={`${activeTab === "/drafts" ? "settingLinks active" : ""
                                }settingLinks`}
                        >
                            <img src={Images.archived} alt="archived" />
                            <span>Drafts</span>
                        </Link>
                    </li>
                    {/* <hr className="topBorder" />
                    <li
                        onClick={() => {
                            navigate("/sent-message")
                        }}
                        className="settingItems"
                    >
                        <Link
                            to=""
                            className={`${activeTab === "/sent-message" ? "settingLinks active" : ""
                                }settingLinks`}
                        >
                            <img src={Images.newSend} alt="archived" />
                            <span>Sent</span>
                        </Link>
                    </li> */}
                    <hr className="topBorder" />
                    <li
                        onClick={() => {
                            navigate("/trash")
                        }}
                        className="settingItems"
                    >
                        <Link
                            to=""
                            className={`${activeTab === "/trash"
                                ? "settingLinks active"
                                : ""
                                }settingLinks`}
                        >
                            <img src={Images.deletedicon} alt="recentlyDelete" />
                            <span>Trash</span>
                        </Link>
                    </li>
                    <hr className="topBorder" />
                </ul>
                {/* <div className="compose_labels mb-3">
                <h5 className="headingSmall_ mb-2">Labels</h5>
                <img
                  src={Images.Plus}
                  alt="recentlyDelete"
                  className="addLabelIcon"
                  onClick={() => {
                    setModalDetail({ show: true, flag: "Addlabels" });
                    setKey(Math.random());
                  }}
                />
              </div>
              <ul className="addLabelList">
                {getAllLabel?.length > 0 ? (
                  <>
                    {getAllLabel?.map((data, index) => {
                      return (
                        <>
                          <li
                            onClick={() => handleupdateLabel(data?.name)}
                            className="labels_status"
                            key={index}
                          >
                            {data?.name}
                          </li>
                        </>
                      );
                    })}
                  </>
                ) : (
                  "No Label Found"
                )}
              </ul> */}
            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                className={
                    modalDetail.flag === "Addlabels" ? "commonWidth customContent" : ""
                }
                ids={modalDetail.flag === "Addlabels" ? "Addlabels" : "ComposeMessage"}
                child={
                    modalDetail.flag === "Addlabels" ? (
                        <AddLabels
                            id={modalDetail.id}
                            close={() => handleOnCloseModal()}
                        // dataList={() => handleDataList()}
                        />
                    ) : modalDetail.flag === "ComposeMessage" ? (
                        <ComposeMessage
                            chatHeadListing={props?.CustomModalchatHeadListing}
                            chatHeadSearchRefresh={props?.chatHeadSearchRefresh}
                            close={() => handleOnCloseModal()}
                            dataList={props?.dataList}
                            activeTab={props?.activeTab}
                            firstMessage={props?.firstMessage}
                            companyinfo={props?.companyInfo}
                        />
                    ) : (
                        ""
                    )
                }
                header={
                    <div className="modalHeader_">
                        <div className="common_">
                            {modalDetail.flag === "Addlabels" ? (
                                <h2 className="addLabels_heading">Create New Label</h2>
                            ) : (
                                <h2 className="addLabels_heading">Compose Message</h2>
                            )}
                        </div>
                    </div>
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </div>
    )
}

export default MessageSideBar