import React from 'react'
import BaseUrl from '../../../constants/baseUrl'

const HomeThumbnail = (props) => {
    return (
        <>
            <div className=''>

                <video controls className='w-100 mt-2 videoBox newBox' autoPlay>
                    <source src={props.videoThumbnail} type="video/mp4" className='soundbiteImg' />
                </video>
            </div>
        </>
    )
}

export default HomeThumbnail