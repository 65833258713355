import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    UnfollowConnection: {},
    connectionList: {},
    addConnections: {},
    requestConnections: {},
    PendingConnections: {},
    SentConnections: {},
    connectionfollow: {},
    requestCancel: {},
    activeTab: {},
    loading: false
}

export const connectionSlice = createSlice({
    name: 'connection',
    initialState,
    reducers: ({
        activeTab: (state) => {
            state.loading = true
        },
        setActiveTab: (state, action) => {
            state.loading = false
            state.activeTab = action.payload
        },
        addConnection: (state) => {
            state.loading = true
        },
        setAddConnection: (state, action) => {
            state.loading = false
            state.addConnections = action.payload
        },
        updateRequestConnection: (state) => {
            state.loading = true
        },
        setUpdateRequestConnection: (state, action) => {
            state.loading = false
            state.requestConnections = action.payload
        },
        pendingConnectionListing: (state) => {
            state.loading = true
        },
        setpendingConnectionListing: (state, action) => {
            state.loading = false
            state.PendingConnections = action.payload
        },
        sentConnectionListing: (state) => {
            state.loading = true
        },
        setSentConnectionListing: (state, action) => {
            state.loading = false
            state.SentConnections = action.payload
        },
        connectionListing: (state) => {
            state.loading = true
        },
        setConnectionListing: (state, action) => {
            state.loading = false
            state.connectionList = action.payload
        },
        connectionFollow: (state) => {
            state.loading = true
        },
        setConnectionFollow: (state, action) => {
            state.loading = false
            state.connectionfollow = action.payload
        },
        connectionUnfollow: (state) => {
            state.loading = true
        },
        setConnectionUnfollow: (state, action) => {
            state.loading = false
            state.UnfollowConnection = action.payload
        },
        connectionRequsetCancel: (state) => {
            state.loading = true
        },
        setConnectionRequsetCancel: (state, action) => {
            state.loading = false
            state.requestCancel = action.payload
        },
        connectionRequsetUnfriend: (state) => {
            state.loading = true
        },
        setConnectionRequsetUnfriend: (state, action) => {
            state.loading = false
            state.requestCancel = action.payload
        },
        onErrorStopLoad: (state) => {
            state.loading = false
        }
    }),
})

// Action creators are generated for each case reducer function
export const { activeTab, setActiveTab, connectionListing, setConnectionListing, addConnection, setAddConnection, updateRequestConnection, setUpdateRequestConnection, pendingConnectionListing, setpendingConnectionListing,sentConnectionListing,setSentConnectionListing, connectionFollow, setConnectionFollow, connectionUnfollow, setConnectionUnfollow, connectionRequsetCancel, setConnectionRequsetCancel, connectionRequsetUnfriend, setConnectionRequsetUnfriend, onErrorStopLoad } = connectionSlice.actions

export default connectionSlice.reducer