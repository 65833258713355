import React, { useCallback, useEffect, useState } from 'react'
import { useAuthSelector } from "../../../redux/selector/auth";
import * as Images from "../../../utilities/images";
const CompanyLogo = (props) => {
    const authSelector = useAuthSelector();
    const userStepInfo = authSelector?.userStep;

     // For set signup and signin Comapny Logo
    let ComapnyLogo;
    if (props?.userDetail?.company_logo) {
        ComapnyLogo = props.userDetail?.company_logo;
    }
    else {
        ComapnyLogo = Images.puzzleProfile;
    }
    return (
   
        <img src={ComapnyLogo} className='img-fluid motionModalImg' alt='motionImg' />

    )
}

export default CompanyLogo;