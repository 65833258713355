import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import * as Images from "../../../utilities/images";
import moment from 'moment';
import { getTrashChat, trashChatUpdate } from "../../../redux/slices/messages";
import { useAuthSelector } from '../../../redux/selector/auth';
import MessageSideBar from '../accountMessage/MessageSideBar';

const Trash = () => {
  const dispatch = useDispatch();
  const [chatTrash, setChatTrash] = useState("");
  const authSelector = useAuthSelector();
  const userInfo = authSelector?.userInfo?.user;

  // apply API for get Trash Chats list

  const getTrashChats = () => {
    dispatch(getTrashChat({
      cb(res) {
        if (res?.data) {
          setChatTrash(res?.data?.payload?.data)
        }
      }
    }))
  };

  // apply API for recover the chat head and permanent delete chat head

  const chatTrashUpdated = (id, flag) => {
    let params = {
      id: id,
      status: flag
    }
    dispatch(trashChatUpdate({
      ...params, cb(res) {
        if (res?.data) {
          getTrashChats();
        }
      }
    }))

  };

  useEffect(() => {
    getTrashChats();
  }, []);
  return (
    <>
      <div className='messageInboxcontent'>
        <div className='opportunityMain_ opportunityMsgHeight'>
          <div className='row'>
            <MessageSideBar />
            <div className='col-md-9'>
              <h3 className='mainHeading'>Trash Messages</h3>
              <div className='containerBox messageContain'>
                <div className='MessageInbox_'>
                  {chatTrash?.length > 0 ?
                    <>
                      {
                        chatTrash?.map((val, index) => {
                          // let company_details = val?.chat_type === 1 ? val?.company_details : userInfo?.id === val?.receiver_details?.id ? val?.sender_details?.user_company_information : userInfo?.id === val?.sender_details?.id ? val?.receiver_details?.user_company_information : "";

                          let company_details =
                            val?.sender_details?.id != userInfo?.id ?
                              val?.sender_details
                                ?.user_company_information
                              : val?.company_details;


                          // Use the split() method to separate the URL by periods (.)
                          const parts = val?.last_message?.split(".");

                          // The last part of the split URL should be the file extension
                          const extension = parts[parts?.length - 1];

                          return (
                            <div key={index} className='postContainerArea' >
                              <div className='posts messageListing align-items-center border-0' >
                                <img src={company_details?.company_logo ? company_details?.company_logo : Images.puzzleProfile} className='postUser me-2' alt='UserImage' />
                                <div className='postsDetails'>
                                  <h5 className='innerSubtextSmall d-flex align-items-center justify-content-between mb-2'>
                                    <span><strong>{company_details?.company_name}</strong></span>
                                  </h5>
                                  {val?.message_type === 1 ? (
                                    <p className="discription_">
                                      {val?.last_message}
                                    </p>
                                  ) : val?.message_type === 3 ? (
                                    <div className=" discription_">
                                      <a
                                        href={val?.last_message}
                                        target="_blank"
                                        className=" me-3 text-center"
                                      >
                                        <img
                                          src={val?.last_message}
                                          alt="UserImage"
                                          className="uploadedImage"
                                          style={{ width: 50, height: 50 }}
                                        />
                                      </a>
                                    </div>
                                  ) : val?.message_type === 2 ? (
                                    <div className="discription_">
                                      <a
                                        href={val?.last_message}
                                        target="_blank"
                                        className="me-3 text-center"
                                      >
                                        {extension === "doc" || extension === "docx" ?
                                          <img
                                            src={Images.docFile}
                                            alt="UserImage"
                                            className="uploadedImage"
                                            style={{ width: 50, height: 50 }}
                                          /> :
                                          <img
                                            src={Images.PDFImage}
                                            alt="UserImage"
                                            className="uploadedImage"
                                            style={{ width: 50, height: 50 }}
                                          />
                                        }
                                      </a>
                                    </div>
                                  ) :
                                    val?.message_type === 4 ? (
                                      <div className="discription_">
                                        <a
                                          href={val?.last_message}
                                          target="_blank"
                                          className="me-3 text-center"
                                        >
                                          <img
                                            src={Images.vedioThumbnail}
                                            alt="UserImage"
                                            className="uploadedImage"
                                            style={{ width: 50, height: 50 }}
                                          />
                                        </a>
                                      </div>
                                    ) :
                                      (
                                        ""
                                      )}
                                  {/* <p className='discription_'>{val?.last_message}</p> */}
                                </div>
                                <div className='postionlabel'>
                                  <div className="dropdown">
                                    <button className="" type="button" id="moreMenu" data-bs-toggle="dropdown" aria-expanded="false">
                                      <img src={Images.Moreoption} className='moreOption me-2' alt='UserImage' />
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="moreMenu">
                                      <li><a className="dropdown-item" onClick={() => chatTrashUpdated(val?.id, 1)}>Recover Chat</a></li>
                                      <li><a className="dropdown-item" onClick={() => chatTrashUpdated(val?.id, 2)}>Permanent Delete Chat</a></li>
                                    </ul>
                                  </div>
                                  <p className='loadMOre'>{moment(val?.updated_at).fromNow()}</p>
                                </div>
                              </div>
                            </div>
                          )
                        }
                        )
                      }
                    </>
                    : "No Data Found"
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default Trash