import React, { useEffect, useState } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import * as Images from "../../../utilities/images";
import CustomModal from '../../components/common/shared/CustomModal';
import SuggestionBox from './suggestionBox';
import { useAuthSelector } from '../../../redux/selector/auth';
import ResourceThumbnail from './soundbytesThumbnailsModal/resourceThumbnail';
import Soundbyterbank from './soundbytesThumbnailsModal/soundbyterbank';
import BaseUrl from '../../../constants/baseUrl';

const Resource = () => {
    const navigate = useNavigate();
    const [videoUrl, setVideoUrl] = useState("");
    const authSelector = useAuthSelector();
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });

    //closeModal
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };
    // home page modal
    const HomePageFunction = () => {
        setModalDetail({ show: true, flag: "HomeThumbnail", });
        setVideoUrl('https://api.wellopps.itechnolabs.tech:3000/uploads/videos/intro.mp4')
        setKey(Math.random());
    }

    // go to particular section
    const SectionToScroll = (flag) => {
        var section = document.getElementById(flag);
        if (section.id == "Knowledgeexchange") {
            section.scrollIntoView({ behavior: 'smooth' });
        }
        else if (section.id == "Mainstage") {
            section.scrollIntoView({ behavior: 'smooth' });
        }
        else if (section.id == "Paneldiscussion") {
            section.scrollIntoView({ behavior: 'smooth' });
        }
        else if (section.id == "Virtualtrade") {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = "Resources";
    }, []);
    return (
        <>

            <div className="homePage bussinessResourcePage">
                <section className="homeBanner landingHead_">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-6 col-md-12">
                                <div className="leftSec">
                                    <h3 className="heading_Blue mb-3">Business <b>Resources</b></h3>
                                    <p className="innerSubtext fw-normal"><b className=""> Building, sustaining </b> and <b> growing</b> a business is a <br /> never-ending journey that involves continuous infusions of <b> knowledge, wisdom </b> and <b> innovation.</b> </p>

                                    <div className="btn-group d-block d-sm-flex">
                                 
                                            <button onClick={() => {navigate("/membership-choose-per-role")}}
                                                className="primaryBtnOther d-flex justify-content-between align-items-center">
                                                <span className="me-3">Get Started</span>
                                                <i className="las la-angle-right"></i>
                                            </button>
                                     
                                    </div>
                                </div>
                            </div>
                           
                            <div className="col-md-6  d-none d-lg-block">
                                
                                <div className='bannerInner' >
                                    <img

                                        src={Images.publicResouce}
                                        alt="Image"
                                        className="soundbiteImg h-100" 
                                    />
                                     <img src={Images.newPlay} onClick={() => {
                                    setVideoUrl(`${BaseUrl.API_URL}uploads/videos/business_resources.mp4`)
                                    setModalDetail({ show: true, flag: "resourceThumbnail", });
                                    setKey(Math.random());
                                }} className="goIcon"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='bussinessDevelopmentSection'>
                    <div className="container">
                        <div className='row'>
                            <div className='col-md-9'>
                                <p class="outerBtn mb-4">BUSINESS DEVELOPMENT PROGRAMS</p>
                                <h4 className="heading_Blue mb-4 fw-normal letterSpace">
                                    <b>Education</b> is the ultimate equalizer. The most <b> successful companies </b>are run by people who appreciate the need to stay on an <b>evolutionary journey of learning</b> and development
                                </h4>
                                <p className='innerSubtext txt20 mb-5'><b>Recognizing </b> that knowledge is power, <b>OPPSWell</b> offers a number of <b> Business  <br />Development Programs </b> to fit the schedules and educational needs  of its <br /> Members.</p>
                            </div>
                        </div>
                        <div className='diverseCertification mt-4'>
                            <div className='row'>
                                <div className='col-md-6 mb-4'>
                                    <div className="imgContainer mb-4">
                                        <img src={Images.bussinessDevel1} className='secImg' alt='BuyerService' />
                                        <img onClick={() => {
                                            setVideoUrl(`${BaseUrl.API_URL}uploads/videos/bankrupt.mp4`)
                                            // setVideoUrl('https://api.wellopps.itechnolabs.tech:3000/uploads/videos/bankrupt.mp4')

                                            setModalDetail({ show: true, flag: "SoundBytesBank", title: "How Not To Go Bankrupt", });
                                            setKey(Math.random());
                                        }} src={Images.goIcon} className='goIcon' alt='goIcon' />
                                    </div>
                                    <h4 className="heading_Blue mb-2 fw-normal letter letterSpace">
                                        <b>SoundBytes</b>
                                    </h4>
                                    <p className='innerSubtext txt20 mb-2'><b>{'<'} 5-minute videos  </b> for those running short on time but craving a quick dose of knowledge.</p>

                                </div>
                                <div className='col-md-6 mb-4'>
                                    <div className="imgContainer mb-4 ">
                                        <img src={Images.bussinessDevel2} className='secImg' alt='BuyerService' />
                                    </div>
                                    <h4 className="heading_Blue mb-2 fw-normal letter letterSpace">
                                        <b>Knowledge Exchanges</b>
                                    </h4>
                                    <p className='innerSubtext txt20 mb-2'><b>45-minute on demand </b> sessions curated by the OPPSWell team with topics ranging from Business Mentoring 101 to Navigating RFPs with First Class Responses to Building a First-Class Supplier Diversity Program.</p>
                                </div>
                                <div className='col-md-6 mb-4'>
                                    <div className="imgContainer mb-4">
                                        <img src={Images.bussinessDevel3} className='secImg' alt='BuyerService' />
                                    </div>
                                    <h4 className="heading_Blue mb-2 fw-normal letter letterSpace">
                                        <b>Mainstage Learnings</b>
                                    </h4>
                                    <p className='innerSubtext txt20 mb-2'><b>60-minute sessions </b> where Enterprise Companies can showcase their products and services to help other businesses continue to manage and grow their businesses.  Topics will range from Retirement Planning to Tax and Legal Issues to Business Money Management to subjects specific to the presenting company’s business.  </p>

                                </div>
                                <div className='col-md-6 mb-4'>
                                    <div className="imgContainer mb-4 ">
                                        <img src={Images.bussinessDevel4} className='secImg' alt='BuyerService' />
                                    </div>
                                    <h4 className="heading_Blue mb-2 fw-normal letter letterSpace">
                                        <b>Panel Discussions</b>
                                    </h4>
                                    <p className='innerSubtext txt20 mb-2'><b>Interactive Panel Discussions </b> have a myriad of benefits and OPPSWell is bringing those benefits to its Members.  OPPSWell will assemble panels of top experts in their fields from various industries who will share their diverse perspectives on a wide-range of topics. </p>

                                </div>
                                <div className='col-md-12 text-center'>
                                    <p className='pannelDisscussionTxt mb-0'>So become a member and let OPPSWell be an integral part of your <b> educational journey!</b></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="supplierService virtualTrade">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 mb-3 mb-md-0">
                                <div className='imgContainer'>
                                    <img src={Images.resourceVertual2} className='secImg' alt='BuyerService' />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <p className="outerBtn">VIRTUAL TRADE FAIRS</p>
                                <h4 className="heading_Blue mt-3 mb-3 fw-normal">
                                    <b>Trade Fairs</b> offer <b>great benefits. </b> However, travel expenses can be cost prohibitive for <b>smaller businesses</b>
                                </h4>
                                <p className='innerSubtext txt20 mb-2'><b>OPPSWell</b> is bringing the Trade Fairs to its members and reducing the need for trains, planes and automobiles!</p>
                                <p className='innerSubtext txt20'>The <b>OPPSWell</b> virtual Trade Fairs will offer its members:</p>

                                <ul className='memberSpotlightList'>
                                    <artical className="listMain">
                                        <img src={Images.Mark} alt="mark" className="tickMark" />
                                        <li className="innerSubtext">Opportunities to network</li>
                                    </artical>
                                    <artical className="listMain">
                                        <img src={Images.Mark} alt="mark" className="tickMark" />
                                        <li className="innerSubtext">The ability to connect with potential Partners, Buyers and Suppliers in one setting</li>
                                    </artical>
                                    <artical className="listMain">
                                        <img src={Images.Mark} alt="mark" className="tickMark" />
                                        <li className="innerSubtext">The ability to showcase products and services with a targeted audience</li>
                                    </artical>
                                    <artical className="listMain">
                                        <img src={Images.Mark} alt="mark" className="tickMark" />
                                        <li className="innerSubtext">Opportunities to stay updated on industry trends, innovations and technologies</li>
                                    </artical>
                                </ul>
                                <p className="innerSubtext mt-3">So become a Member and <b>make</b> connections at the <br /> <b> OPPSWell virtual Trade Fairs!</b>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="supplierService memberSpotLight">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <p className="outerBtn">MEMBER SPOTLIGHTS</p>
                                <h4 className="heading_Blue mt-3 mb-3 fw-normal">
                                    <b>Each week OPPSWell</b> will <b>spotlight </b> <br />one of its <b>Member businesses</b>
                                </h4>
                                <ul className='memberSpotlightList'>
                                    <artical className="listMain">
                                        <img src={Images.Mark} alt="mark" className="tickMark" />
                                        <li className="innerSubtext"><strong>For Small Businesses</strong> Highlight milestone accomplishments and increase visibility with Buyer and other Member companies on OPPSWell</li>
                                    </artical>
                                    <artical className="listMain">
                                        <img src={Images.Mark} alt="mark" className="tickMark" />
                                        <li className="innerSubtext mb-3"><strong>For Enterprise Companies</strong> Highlight company accolades, community involvement, new products and services and support of Supplier businesses</li>
                                    </artical>
                                </ul>
                                <p className="innerSubtext mt-3">So <b> become a Member </b>of OPPSWell and see which <br /> businesses are in the <b> spotlight each week </b> – it could be <strong>YOURS!</strong>
                                </p>
                            </div>
                            <div className="col-md-6 mb-3 mb-md-0">
                                <div className='imgContainer'>
                                    <img src={Images.memberSpotLight} className='secImg' alt='BuyerService' />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="supplierService sundayFundaySec">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-md-5 mb-3 mb-md-0">
                                <div className='imgContainer resourceSunday'>
                                    <img src={Images.resourceSunday} className='secImg' alt='BuyerService' />
                                </div>
                            </div>
                            <div className="col-md-7">
                                <p className="outerBtn">SUNDAY FUNDAYS</p>
                                <h4 className="heading_Blue mt-3 mb-3 fw-normal">
                                    <b>All work </b> and <b>no play</b> makes for a very dull  <b>day! OPPSWell</b> is putting a bit more Fun in Sundays by <b>hosting monthly, live, interactive game sessions</b>  for its Members
                                </h4>
                                <p className='innerSubtext txt20 mb-4'>Sunday Fundays are a great way for Member companies to meet other Members who could be potential Business Partners. And if chosen to compete in the games, Members can: </p>
                                <ul className='memberSpotlightList'>
                                    <artical className="listMain">
                                        <img src={Images.Mark} alt="mark" className="tickMark" />
                                        <li className="innerSubtext"> Raise the visibility for their businesses</li>
                                    </artical>
                                    <artical className="listMain mb-3">
                                        <img src={Images.Mark} alt="mark" className="tickMark" />
                                        <li className="innerSubtext ">Win a monetary price offered by the OPPSWell team</li>
                                    </artical>
                                </ul>
                                <p className='innerSubtext txt20'>So if you are looking for a little more Fun to begin your week, become a Member of <b>OPPSWell</b> and we will see you on Sunday!
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="buyerService homeRelevance" >
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-7">
                                <div className="resourceDiverse">
                                    <p class="outerBtn mb-4">DIVERSE BUSINESS CERTIFICATIONS</p>
                                    <h4 className="heading_Blue mb-4 fw-normal letter letterSpace">
                                        <strong>Buyers</strong> are always searching for <strong>diverse Suppliers</strong> for their <strong>Supplier Diversity <br /> Programs</strong> yet diverse Suppliers often do not <br /> have the required <strong>diversity certifications*</strong>
                                    </h4>
                                    <p className='innerSubtext txt20 mb-4'>Supplier Diversity Programs matters because they:</p>

                                    <ul>
                                        <artical className="listMain">
                                            <img src={Images.Mark} alt="mark" className="tickMark" />
                                            <li className="innerSubtext">Provide untapped location demographics </li>
                                        </artical>
                                        <artical className="listMain">
                                            <img src={Images.Mark} alt="mark" className="tickMark" />
                                            <li className="innerSubtext">Support the growth of underrepresented businesses </li>
                                        </artical>
                                        <artical className="listMain">
                                            <img src={Images.Mark} alt="mark" className="tickMark" />
                                            <li className="innerSubtext">Increase brand loyalty and commitment to inclusion</li>
                                        </artical>
                                    </ul>
                                    <p className='innerSubtext txt20 mb-2 mt-5'>
                                        The <strong>OPPSWell</strong> streamlined Diverse Business Certification process is the solution and the disruption truly needed!
                                    </p>
                                    <ul>
                                        <artical className="listMain">
                                            <img src={Images.Mark} alt="mark" className="tickMark" />
                                            <li className="innerSubtext">Thorough vetting yet less onerous and invasive</li>
                                        </artical>
                                        <artical className="listMain">
                                            <img src={Images.Mark} alt="mark" className="tickMark" />
                                            <li className="innerSubtext">Certification decisions rendered in 7-14 days </li>
                                        </artical>
                                        <artical className="listMain">
                                            <img src={Images.Mark} alt="mark" className="tickMark" />
                                            <li className="innerSubtext">One-stop shopping for multiple certifications</li>
                                        </artical>
                                    </ul>
                                    <p><strong>* Diverse Business Certifications Available March 2024</strong></p>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="imgContainer mb-3 mb-md-0">
                                    <img src={Images.resourceDiverse} className='secImg' alt='BuyerService'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="supplierService suggestionBoxSec">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 mb-3 mb-md-0">
                                <div className='imgContainer resourceSunday'>
                                    <img src={Images.resourceSuggection} className='secImg' alt='BuyerService' />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <p className="outerBtn">SUGGESTION BOX</p>
                                <h4 className="heading_Blue mt-3 mb-3 fw-normal">
                                    If <b> Members</b> do not see what they want, <b>we want to know about it </b>
                                </h4>
                                <p className='innerSubtext txt20 mb-4'>All Members are encouraged to take advantage of the OPPSWell Suggestion Box and let us know about additional developmental topics, resources and functions that could be helpful to their businesses. </p>

                                <p className='innerSubtext txt20 pannelDisscussionTxt'><b>OPPSWell</b> is a great listener and we cannot wait for our  <br />Members to suggest away!
                                </p>
                                <a href="/membership-choose-per-role">
                                    <button className="primaryBtnOther mt-3">
                                        <span className="me-3">Get Started</span>
                                        <i className="las la-angle-right"></i>
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                className={modalDetail.flag === "suggestionbox" ? "commonWidth customContent" : modalDetail.flag === "resourceThumbnail" ? "resourceThumbnail" : modalDetail.flag === "SoundBytesBank" ? "resourceThumbnail" : ""}
                ids={modalDetail.flag === "suggestionbox" ? "suggestionbox" :
                    modalDetail.flag === "resourceThumbnail" ? "soundbytesThumbnail" :
                        modalDetail.flag === "SoundBytesBank" ? "soundbytesThumbnail"
                            : "ComposeMessage"}
                child={
                    modalDetail.flag === "suggestionbox" ? (
                        <SuggestionBox
                            close={() => handleOnCloseModal()}
                        />
                    ) :
                        modalDetail.flag === "resourceThumbnail" ? (
                            <ResourceThumbnail
                                close={() => handleOnCloseModal()}
                            />
                        )
                            :
                            modalDetail.flag === "SoundBytesBank" ?
                                (
                                    <Soundbyterbank
                                        close={() => handleOnCloseModal()}
                                    />
                                )
                                :
                                ""
                }
                header={
                    <div className='modalHeader_'>
                        <div className="common_">
                            {modalDetail.flag === "suggestionbox" ?
                                <>
                                    <h2 className="addLabels_heading">
                                        Have a Suggestion?
                                    </h2>
                                    <p>We are always looking for input please provide below</p>
                                </>

                                : modalDetail.flag === "SoundBytesBank" ?

                                    <>
                                        <h2 className="addLabels_heading">
                                            {modalDetail.title}
                                        </h2>
                                    </>

                                    : ""
                            }
                        </div>
                    </div>
                }

                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

export default Resource