import React, { useEffect } from 'react'
import AwesomeSlider from 'react-awesome-slider';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import * as Images from "../../../utilities/images";
import OwlCarousel from 'react-owl-carousel';

const AboutUs = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = [1, 2, 3]

  // go to particular section

  const SectionToScroll = (flag) => {
    var section = document.getElementById(flag);

    if (section) {
      if (section.id == "thefounder") {
        section.scrollIntoView({ behavior: 'smooth' });
      }
      else if (section.id == "mission") {
        section.scrollIntoView({ behavior: 'smooth' });
      }
      else if (section.id == "leadership") {
        section.scrollIntoView({ behavior: 'smooth' });
      }
      else if (section.id == "press") {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  const handleRedirectPage = () => {
    navigate("/contact-us")
  }

  useEffect(() => {
    if (location.hash) {
      let element = document.getElementById(location.hash.replace("/about#", ""));
      element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }
    document.title = "About-us";
  }, []);

  useEffect(() => {
    // getAllUserWithSearch();
    window.scrollTo(0, 0);
    document.title = "About-us";
  }, []);


  return (
    <div className='aboutUs'>
      {/* Start HomePage Section */}
      <section className='outerPages'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 col-md-12'>
              <div className='aboutContainer p-0'>
                <div className=''>
                  <h3 className='heading_Blue mb-5'>
                    About <b>OPPSWell</b>
                  </h3>
                  <p className='innerSubtext fw-normal'>
                    <b>OPPSWell</b> is a dynamic social media networking platform matchmaking Buyers and Suppliers for contracting opportunities.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End HomePage Section */}
      {/* Start AboutMenus Section */}
      <section className='aboutMenus'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <ul className='aboutMenusContainer'>
                <li className='aboutItem'>
                  <button onClick={() => SectionToScroll("thefounder")} className='aboutLinks'>The Founder</button>
                </li>
                <li className='aboutItem'>
                  <button onClick={() => SectionToScroll("mission")} className='aboutLinks'>MISSION</button>
                </li>
                <li className='aboutItem'>
                  <button onClick={() => SectionToScroll("leadership")} className='aboutLinks'>LEADERSHIP</button>
                </li>
                <li className='aboutItem'>
                  <button onClick={() => SectionToScroll("press")} className='aboutLinks'>PRESS</button>
                </li>
                <li className='aboutItem'>
                  <button onClick={() => handleRedirectPage()} className='aboutLinks'>CONTACT</button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* Start AboutMenus Section */}
      {/* Start AboutFounder Section */}
      <section className='aboutFounder' id='thefounder'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-9 col-md-12'>
              <div className='mb-4'>
                <h3 className='heading_Blue'>About <b>The Founder</b></h3>
                <p className='founderSubHeading'>OPPSWell Visionary, Marilyn Booker is a Corporate OG.</p>
              </div>
              <p className='txtBlack17'>
                She is a wave maker and has been influencing diversity, equity, and inclusion programs in the United States and around the globe for the past 30
                years. After graduating from Spelman College and IIT Chicago Kent College of Law, she earned a seat at the head table. Marilyn understands the
                challenges faced by many Suppliers to connect with Buyers. That is how and why her vision for OPPSWell came to life.
              </p>
              <p className='txtBlack17'>
                Marilyn has worked with both private and public sectors of enterprise corporations and government entities. She has experienced how
                organizations’ internal and external procurement and inclusion processes are designed to work and the headwinds often faced. It is this experience
                that puts her in the unique position to address challenges faced by Buyers looking for Suppliers and the challenges of all businesses, particularly
                small businesses, in their quest to create and maintain sustainable companies.

              </p>
              <p className='txtBlack17'>
                Marilyn and team have created a tool to disrupt the old ways of connecting businesses to each other while providing access to education focused on
                long term sustainability and financial security.

              </p>
              <p className='txtBlack17'>
                OPPSWell will provide unprecedented connections between Buyers and Suppliers.
              </p>
            </div>
            <div className='col-lg-3'  >
              <figure className='aboutLadymain_ mt-5'>
                <img src={Images.aboutLady} className='img-fluid aboutLady' alt='BuyerService' />
              </figure>
            </div>
          </div>
        </div>
      </section>
      {/* End AboutFounder Section */}
      {/* Start Mission Section */}
      <section className='mission' id="mission">
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <h4 className='heading_Blue mb-3'>
                {/* Purpose <b>& Mission</b> */}

                <b>Mission</b> 
              </h4>
              <p className='innerSubtext mb-3'>
                <b>The OPPSWell mission is to provide a platform for large and small businesses of all hues to connect and engage with each other as a business community.  These connections will allow all types of businesses to discover and present new products and services and foster valuable procurement relationships.</b>
              </p>
              <p className='innerSubtext mt-2 mb-4'>To accomplish its mission, OPPSWell is designed to: </p>
              <ul className='aboutList mt-2 mb-4'>
                <li>
                  <p className='innerSubtext'>Provide Buyer companies with efficiency, organization and real time exposure to a broader and more comprehensive base of large, small and diverse businesses
                  </p>
                </li>
                <li>
                  <p className='innerSubtext'>Relieve Buyer companies of the burden of struggling with stale databases as Suppliers update their own company profiles in real time with current, fresh data
                  </p>
                </li>
                <li>
                  <p className='innerSubtext'>Put Suppliers on the global radar screens of companies that otherwise would not know they existed
                  </p>
                </li>
                <li>
                  <p className='innerSubtext'>Provide business resources that can play a role in the educational journey of up-and-coming businesses
                  </p>
                </li>
                <li>
                  <p className='innerSubtext'>Provide a safe space for Buyers and Suppliers to build trust and create genuine relationships
                  </p>
                </li>
              </ul>
              <p className='innerSubtext mt-2 mb-4'>Technology is a great tool, but technology without that human element can be challenging because people are the enhanced value most are looking for.  OPPSWell is that bridge bringing all of the elements of its mission to bear - connecting real people to real opportunities to real businesses, large, small and diverse. </p>

              <p className='innerSubtext mt-2 mb-4'>In today’s global marketplace, effective marketing and visibility are critical to the success of any company. By merging business, marketing, social media, technology and relationship building into the Buyer/Supplier relationship, OPPSWell is filling the void that can often be fatal to companies trying to get their businesses on the map. With well-organized dashboards and direct communication for opportunities, Buyers and Suppliers can find each other and reach out to follow up and chat without feeling like they are blocked out by the deep abyss of the cloud. </p>
              <p className='innerSubtext mt-2 mb-4'>With the OPPSWell tools, Suppliers can search for contracting opportunities directly based on their specific needs and reach out to Buyers with a message or apply to a specific opportunity. Buyers too can search for and reach out to Suppliers and quickly complete due diligence, post opportunities and strategically expand their business network. Large, small and diverse companies can highlight business status, certifications, skills and services to attract desired audiences. And to enhance their marketing and visibility, everyone has the opportunity to showcase their accomplishments, intentions and missions with featured highlights.</p>
              <p className='txtBlue17 mt-2 mb-4'>
                Whether you are a Supplier or a Buyer or Both, OPPSWell is for you!  
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* End Mission Section */}
      {/* Start Mission Section */}
      <section className='missionBoard' id="leadership">
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <h4 className='heading_Blue mb-5 fw-bold'>
                Leadership
              </h4>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <div className='containerBoard'>
                <figure>
                  <img src={Images.containerBoard1} className='boardImg' alt='BuyerService' />
                </figure>
                <artical>
                  <b className='innerSubtextBold'> Marilyn F. Booker, President, CEO</b>
                  <p className='innerSubtext mb-2'> An Attorney and 30+ year veteran of Wall Street, Marilyn is an expert in small and minority business having built a world class Supplier Diversity Program for one of the leading global financial services firms.  She has also worked with many of the governmental and trade organizations that support the growth of small and diverse businesses.</p>
                </artical>
              </div>

              <div className='containerBoard'>
                <figure>
                  <img src={Images.containerBoard2} className='boardImg' alt='BuyerService' />
                </figure>
                <artical>
                  <b className='innerSubtextBold'> Walter K. Booker, Jr. COO</b>
                  <p className='innerSubtext mb-2'> A self-taught technology and business aficionado, Walter understands the intersection of technology and business in ways that will allow him to lead the business in a direction that will continually adapt, evolve and serve the needs of its Members. He will oversee the development and dissemination of technology to all Members of the platform.</p>
                </artical>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Mission Section */}
      {/* Start News&Press Section */}
      <section className='newsPress' id="#press">
        <div id='press' className='container'>
          <div className='row'>
            <div className='col-md-12 text-center mb-4'>
              <h4 className="heading_Blue mb-3"><b>Press</b></h4>
            </div>
          </div>
          <div className='row'>
            <OwlCarousel
              className='owl-theme'
              loop={true}
              margin={10}
              nav={false}
              responsiveClass={true}
              responsive={{
                0: {
                  items: 1
                },
                600: {
                  items: 1
                },
                991: {
                  items: 2
                },
                1000: {
                  items: 3
                }
              }}
            >
              <div className='item'>
                <div className='newsCard'>
                  <h5 className='innerSubtextbold  mb-1'>
                    <b>Marilyn Booker Urges William & Mary Law Graduates to Pursue the Possibilities of Positive Change</b>
                  </h5>
                  <p className='innerSubtext'>
                    Providing words of inspiration to William & Mary Law School’s Class of 2018 as they prepared to receive their diplomas, Marilyn Booker, looked back to her own work after graduation.
                  </p>
                  <div className='readMoreData'>
                    <Link to='https://law.wm.edu/news/stories/2018/marilyn-booker-urges-william-mary-law-graduates-to-pursue-the-possibilities-of-positive-change.php' target='_blank' className='readMore'>Read More</Link>
                  </div>
                </div>
              </div>

              <div className='item'>
                <div className='newsCard'>
                  <h5 className='innerSubtextbold  mb-1'>
                    <b>Marilyn Booker Delivers the Keynote at Webster University’s Diversity Conference in St. Louis, MO.</b>
                  </h5>
                  <p className='innerSubtext'>
                    When asked what the average person can do to help overcome disparities in society, Wall Street powerhouse Marilyn F. Booker, said if you keep your character in check, while living by a few guiding principles, the rest will naturally follow.
                  </p>
                  <div className='readMoreData'>
                    <Link to='https://gazellemagazine.com/marilyn-booker-keynote-websters-diversity-conference/' target='_blank' className='readMore'>Read More</Link>
                  </div>
                </div>
              </div>

              <div className='item'>
                <div className='newsCard'>
                  <h5 className='innerSubtextbold  mb-1'>
                    <b>Booker Moderates Ladylike Foundation Event for Young Girls at UCLA</b>
                  </h5>
                  <p className='innerSubtext'>
                    The Ladylike Foundation, founded and run by Leah Pump, holds an annual Day at UCLA that invites powerful speakers to share words of wisdom with the young ladies of Ladylike. With a panel of powerful women each in their own rights, Marilyn Booker moderated a discussion on life lessons and the young ladies were given free reign to ask questions of the guests on any topic and about anything.
                  </p>
                  <div className='readMoreData'>
                    <Link to='https://lasentinel.net/ladylike-foundation-event-at-ucla.html' target='_blank' className='readMore'>Read More</Link>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </section>
      {/* End News&Press Section */}
    </div>
  )
}

export default AboutUs