import React, { useEffect, useState } from 'react'
import { getopportunityList } from '../../../redux/slices/opportunity'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import * as Images from "../../../utilities/images";
import moment from 'moment';
import ReactPaginate from 'react-paginate';

const OpportunitiesList = () => {

    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(1);
    const [opportunityList, setOpportunityList] = useState("")
    const id = location?.state?.oppId

    const opportunityListing = (id, page = 1, limit = 5) => {
        let params = {
            id: id,
            page: page,
            limit: limit,
            
        }
        dispatch(getopportunityList({
            ...params, cb(res) {
                if (res.status) {
                    setOpportunityList(res?.data?.payload)
                    setPageCount(res?.data?.payload?.total_pages);
                }
            }
        }))

    }

    const handlePageClick = (e) => {

        opportunityListing(id,e.selected + 1, 5);
        // setTimeout(() => {
        //     scrollToBottom();
        // }, 10);
    };

    const handleSubmited = (id) => {
        //navigate(`/view-profile`, { state: { id: id } });
        navigate(`/${"view-profile"}/${id}`);
    };

    useEffect(() => {
        if (id) {
            opportunityListing(id)
        }
    }, [id]) 

    return (
        <>

            <div className='container'>
                {
                    opportunityList?.data?.map((val, index) => (
                        <>
                        <div class="businessDesincontent_ mt-4">
                            <div class="row">
                                <div class="col-lg-2 col-md-4">
                                    <div class="designImg_">
                                        <img class="imgproduct_" src={val?.opportunity_media ? val?.opportunity_media : Images.puzzleProfile} />
                                    </div>
                                </div>
                                <div class="col-lg-10 col-md-8">
                                    <div class="designContent_ h-auto">
                                        <div class="designcontentTop_ align-items-start mb-2">
                                            <div class="designLeft_ pe-2">
                                                <p class="txt23 fw-bold mb-0">{val?.posting_title}</p>
                                                <h4 class="companyName_ mb-0">{val?.user_details?.user_company_information?.company_name}</h4>
                                                <h5 class="weeksTxt">{val?.province_details?.name ? val?.province_details?.name : ""},  {val?.province_details?.country_details?.name ? val?.province_details?.country_details?.name : ""}</h5>
                                            </div>
                                            <div class="designbtnRight buttonMinWidth">
                                                <button class="subsmallBtnblue_" onClick={() => {
                                                    handleSubmited(
                                                        val?.id
                                                    );
                                                }}
                                                >View Details</button>
                                            </div>
                                        </div>
                                        <div class="advanceDetails wordLimit mt-3">
                                            <p class="mb-0">{val?.description}</p>
                                        </div>
                                        <div class="opportunitiesPlans">
                                            <div class="d-flex">
                                                <span class="mb-0 me-1 opportunitesMonth">
                                                    <img src="/static/media/clockIcon.19e84761c51692b4d2698b6f3889656a.svg" class="me-2" />
                                                </span>
                                                <span class="mb-0 me-3 opportunitesMonth">{moment(val?.created_at).fromNow()}</span>
                                                <p class="mb-0 opportunitesMonth">
                                                    <img src="/static/media/dollarSign.b6e567d47eb90506dfb0c06ad023638f.svg" class="me-2" />{val?.currency === "USD" || val?.currency == "CAD" ? `$` : val?.currency == "EURO" ? `€` : ""}{val?.opportunity_amount ? val?.opportunity_amount.toLocaleString() : ""} {val?.currency === "USD" || val?.currency == "CAD" ? `${(val?.currency)}` : `${(val?.currency)}`} {val?.contract_length === 0 || val?.currency === "" ? " " : "/"} 
                                                    {val?.contract_length === 1 ? "Yearly" : val?.contract_length === 2 ? "Monthly" :
                                                            val?.contract_length === 3 ? "Weekly" : val?.contract_length === 4 ? "Daily" : val?.contract_length === 5 ? "Hourly" : ""}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                         <hr/>
                         </>
                      
                    ))
                }
               
            </div>
            <div className="col-12 ">
                <div className="customPagination mt-4">
                    <div className="pagination">
                        {opportunityList?.data?.length > 0 ? (
                            <ReactPaginate
                                forcePage={currentPage}
                                // initialPage={currentPage}
                                // key={pagination}
                                // breakLabel=""
                                // nextLabel=">"
                                nextLabel={<i className="fas fa-caret-right"></i>}
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={pageCount}
                                marginPagesDisplayed={0}
                                pageCount={pageCount}
                                previousLabel={<i className="fas fa-caret-left"></i>}
                                // previousLabel="<"
                                renderOnZeroPageCount={null}
                            />
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default OpportunitiesList