import React, { useEffect, useState } from 'react'
import * as Images from "../../../utilities/images";
import { useDispatch } from 'react-redux';
import { createDraft, getLabels, messageRequest } from '../../../redux/slices/messages';
import { toast } from "react-toastify"

const MessageResponseBox = (props) => {

  // const socketInstance = socket();
  const [messages, setMessages] = useState();
  const [selectedLabel, setSelectedLabel] = useState();
  const [getAllLabel, setGetAllLabel] = useState("");
  const dispatch = useDispatch()
  const [subject, setSubject] = useState()

  const sendMessage = (e) => {
    e.preventDefault();

    const selectedId = props?.selectedCompanyId

    if (!selectedId) {
      toast.error("Please select company")
      return;
    }
    else if (!messages) {
      toast.error("Please enter message");
      return;
    }
    else if (!subject) {
      toast.error("Please enter subject");
      return;
    }
    let params = {
      receiver_id: props?.receiverId,
      company_id: selectedId,
      message_type: 1,
      subject: subject,
      message: messages,
      chat_type: props?.chatType
    }
    // if (props?.chatId) {
    //   params = {
    //     ...params,
    //     chat_draft_id: props?.chatId
    //   }
    // }
    dispatch(messageRequest({
      ...params,
      cb(res) {
        if (res?.data) {
          props?.close()
          toast.success("Message sent successfully")
        }
      }
    }))
  }


  // const getLebelsList = (data) => {
  //   setSelectedLabel(data)
  //   dispatch(getLabels({
  //     cb(res) {
  //       if (res?.data) {
  //         setGetAllLabel(res?.data?.payload)
  //       }
  //     }
  //   }))
  // };

  const createDrafts = () => {
    const selectedId = props?.selectedCompanyId
    let params = {
      company_id: selectedId,
      // label: selectedLabel
      subject: subject,
      chat_type: 2
    }
    if (messages) {
      params = {
        ...params,
        message: messages,
        message_type: 1,
      }
    }
    dispatch(createDraft({
      ...params, cb(res) {
        if (res?.data) {
          props.close()
        }
      }
    }))
  };

  // useEffect(() => {
  //   getLebelsList();
  //   document.title = "messages";
  // }, []);

  return (
    <>

      <form onSubmit={(e) => sendMessage(e)}>
        <div className="dropdown">
          <div className="business_search mb-4" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            <img className="searchIcon_" src={Images.search_} alt="Search" />
            <input type="text" className="custoInput" placeholder="Search Company" aria-label="discount code" aria-describedby="basic-addon2" value={props?.companyName} disabled />
          </div>
        </div>
        <div className='form-group col-md-12 mb-3'>
          <input name="labelcustom" type='text' className='customFormControl' placeholder='Subject' value={subject} onChange={e => setSubject(e.target.value)}></input>
        </div>
        <div className='form-group col-md-12 mb-3'>
          <textarea name="email" type='text' className='customFormControl' placeholder='message' value={messages} onChange={e => setMessages(e.target.value)}></textarea>
        </div>

        <div className='sendMessage text-center'>
          <button type="button" className='subsmallBtnblue_ me-2' onClick={() => createDrafts()}>Save in Draft</button>

          <button className='primaryBtnSmall' type="submit">
            <span >Send</span>
          </button>
        </div>
      </form>
    </>
  )
}

export default MessageResponseBox