import React, { useState } from 'react'
import * as Images from "../../../utilities/images";
import swal from 'sweetalert';
import moment from 'moment';
import { deleteApplication, getChatHead } from '../../../redux/slices/opportunity';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify"
import { useAuthSelector } from '../../../redux/selector/auth';
import CustomModal from '../../components/common/shared/CustomModal';
import SendMessage from '../companyfrontProfile/sendMessage';

const ApplicationModal = (props) => {
    const authSelector = useAuthSelector();
    const dispatch = useDispatch()
    const [key, setKey] = useState(Math.random());
    const [userName, setUserName] = useState(props?.details?.user_details?.user_profile)
    const [senderId, setSenderId] = useState(props?.details?.sender_details)
    const navigate = useNavigate();
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });

    const data = props?.details?.user_details?.user_company_information;
    const oppId = props?.details

    console.log(modalDetail, "modal detail");


    const chooseBusinessType = authSelector?.userStep?.user?.business_type ?? authSelector?.userStep?.business_type;

    const choosePlan = authSelector?.userStep?.user?.membership_details?.name ?? authSelector?.userStep?.membership_details?.name;

    // API for delete Opportunities ..............
    const handledeleteApplication = (id) => {
        props?.close()
        swal({
            // title: "Are you sure?",
            text: `Are you sure want to delete  this application?`,
            // icon: "warning",
            dangerMode: false,
            buttons: true,
        })
            .then(result => {
                if (result) {
                    let params = {
                        id: id
                    }
                    dispatch(deleteApplication({
                        ...params, cb(res) {
                            if (res.status) {
                                props?.ReviewRespondentsOpportunity()
                            }
                        }
                    }))

                }
            });
    }

    const handleChat = (userID) => {
        let params = {
            id: userID,
        }
        dispatch(getChatHead({
            ...params, cb(res) {
                if (res.status) {
                    if (choosePlan == "Bronze" && chooseBusinessType === 1 || choosePlan == "Bronze" && chooseBusinessType === 2 && res?.data?.payload?.connectionRequest === false) {
                        toast.dismiss();
                        toast.error("You need to connect to the member or Upgrade your plan")
                        return
                    }
                    else {
                        // setModalDetail({ show: true, flag: "ComposeMessage", response: res?.data?.payload, application: "applicationmodal" });
                        // setKey(Math.random());
                        // return
                        // navigate(`/account-message/${res?.data?.payload?.chatHeadDetails?.id}`)
                        if (res?.data?.payload?.connectionRequest) {
                            if (res?.data?.payload?.connectionRequest?.status === 2) {
                                if (res?.data?.payload?.chatHeadDetails) {
                                    navigate(`/account-message/${res?.data?.payload?.chatHeadDetails?.id}`)
                                } else {
                                    props?.setModalDetail({ show: true, flag: "messageResponse", });
                                }

                            } else {
                                if (res?.data?.payload?.chatRequest) {
                                    if (res?.data?.payload?.chatRequest?.status === 1) {
                                        navigate(`/account-message/${res?.data?.payload?.chatHeadDetails?.id}`)
                                    } else if (res?.data?.payload?.chatRequest?.status === 0) {
                                        toast.warning(" Message request already sent to the user")
                                        props?.close()
                                    } else {
                                        props?.setModalDetail({ show: true, flag: "messageResponse", });
                                    }
                                } else {
                                    props?.setModalDetail({ show: true, flag: "messageResponse", });
                                }
                            }
                        }
                        else {
                            if (res?.data?.payload?.chatRequest) {
                                if (res?.data?.payload?.chatRequest?.status === 1) {
                                    navigate(`/account-message/${res?.data?.payload?.chatHeadDetails?.id}`)
                                } else if (res?.data?.payload?.chatRequest?.status === 0) {
                                    toast.warning(" Message request already sent to the user")
                                    props?.close()
                                }
                                else {
                                    props?.setModalDetail({ show: true, flag: "messageResponse", });
                                }
                            } else {
                                setModalDetail({ show: true, flag: "ComposeMessage", response: data, application: "applicationmodal" });
                                setKey(Math.random());
                            }

                        }
                    }
                }
            }
        }))
    }



    // function for user veiw profile buyer & supplier.........
    const handleSubmitedViewProfile = (id, name) => {
        const formattedName = name.replace(/\s+/g, "-"); // Replace spaces with hyphens
        navigate(`/${formattedName}/${id}`);
    };

    // for close Modal
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };


    // const senderId = props?.details?.sender_details

    console.log(data, "data i received");

    return (
        <>
            <div className='respondApplication'>
                <div className='respondAppHeader mb-4'>
                    <div className='respondAppHeading'>
                        <h1 className='mb-0'>Application</h1>

                    </div>
                    <div className='respondAppDate'>
                        <span className='sendTime'>  {moment(data?.created_at).fromNow()}
                        </span>
                    </div>
                </div>
                <div className='respondAppMain mb-4'>
                    <div className='chatUserImg me-3' onClick={() => {
                        handleSubmitedViewProfile(
                            data?.id,
                            data?.company_name
                        );
                    }}>

                        <img src={data?.company_logo ? data?.company_logo : Images.puzzleProfile} alt="company Image" className='img-fluid' />
                    </div>
                    <div className='respondAppMainCompany'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <p className='mb-0'><b></b> <span> {data?.company_name}</span></p>
                                <h1 className='mb-2'>{userName?.first_name} {userName?.last_name}</h1>
                                {
                                    data?.domestic_service_areas ?
                                        <div className=''>
                                            <img src={Images.locationpin} alt='Location' className='me-1' />
                                            <span className='responseLocation'>{data?.domestic_service_areas}</span>
                                        </div>
                                        : ""
                                }

                            </div>
                        </div>
                    </div>
                </div>
                {
                    data?.company_service_description === "" ? ""
                        :
                        <div className='respondAppInfo'>
                            <h4 className='mb-3'>Company Information</h4>
                            <p className='mb-3'>{data?.company_service_description}</p>
                        </div>
                }
                <div className='respondAppBtns text-end'>
                    <button className='sendBtn deActiveBtn me-2' onClick={() => handledeleteApplication(oppId?.id)}>Delete Application</button>
                    <button onClick={() => {
                        handleChat(data?.user_id)
                        // props?.setModalDetail({ show: true, flag: "messageResponse", });
                        props?.setSelectedCompanyName(data?.company_name)
                        props?.setSelectedCompanyId(props?.details?.company_id)
                        props?.setSenderId(data?.user_id)

                    }} className='sendBtn'>Send Messsage</button>
                </div>
            </div>

            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                className={modalDetail.flag === "submitendorsement" ? "submitendorsement" : ""}
                ids={modalDetail.flag === "submitendorsement" ? "submitendorsement" : ""}
                child={modalDetail.flag === "ComposeMessage" ? (
                    <SendMessage
                        close={() => handleOnCloseModal()}
                        applicationModalClose={() => props?.close()}
                        companyInfo={modalDetail?.response}
                    // handeCompany={() => CompanyInformation(id)}
                    />
                ) :
                    ""
                }
                header={
                    <div className='modalHeader_'>
                        <div className="common_">
                            {
                                modalDetail.flag === "ComposeMessage" ?
                                    <h2 className="addLabels_heading">
                                        Compose Message
                                    </h2>
                                    : ""
                            }
                        </div>
                    </div>
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

export default ApplicationModal