import React from 'react'
import moment from 'moment';


const ArticleInfo = (props) => {

  const data = props?.singleInfo;

  return (
    <>
      <>
        <h1 className='heading42 fw-normal'>{data?.title}</h1>
        <p className='headingBlack21 fw-normal mb-1'>Help Desk {'>'} {data?.help_category_details?.name}</p>
        <p className='lastUpdateQue'>Last Updated - {moment(data?.help_category_details?.updated_at).format('MMM D, YYYY')}</p>
        <div className="suggestedArticleInner mb-5">
          <p className="suggestedDes" dangerouslySetInnerHTML={{ __html: data?.description }}>
          </p>
        </div>
      </>
    </>
  )
}

export default ArticleInfo