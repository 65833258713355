import React from "react"
import { Link, useNavigate } from "react-router-dom";
import * as images from "../../../utilities/images";
import { Nav } from "react-bootstrap";
import { useAuthSelector } from "../../../redux/selector/auth";

const AuthFooter = () => {
    const authSelector = useAuthSelector();
    const checkAccount = authSelector?.userInfo?.user?.is_deactivate;


    const chooseBusinessType = authSelector?.userStep?.user?.business_type ?? authSelector?.userStep?.business_type;

    const choosePlan = authSelector?.userStep?.user?.membership_details?.name ?? authSelector?.userStep?.membership_details?.name;

    return (
        <>
            <section className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-6 col-md-6 col-12">
                            <h4 className="heading_">Company</h4>
                            <ul className="footerMenus">
                                <li className="footerItems">
                                    {
                                        checkAccount === 1 ?
                                            <Link className="footerLinks"
                                            >Press</Link> :
                                            <Link className="footerLinks" to="/about/#press"
                                            >Press</Link>
                                    }
                                </li>

                                <li className="footerItems">
                                    {
                                        checkAccount === 1 ?
                                            <Link className="footerLinks">Privacy Policy</Link>
                                            :
                                            <Link to='/privacy-policy' className="footerLinks">Privacy Policy</Link>
                                    }
                                </li>
                                <li className="footerItems">
                                    {
                                        checkAccount === 1 ?
                                            <Link to='/community-standards' className="footerLinks">Community Standards</Link>
                                            :
                                            <Link to='/community-standards' className="footerLinks">Community Standards</Link>
                                    }
                                </li>

                                <li className="footerItems">
                                    {
                                        checkAccount === 1 ?
                                            <Link className="footerLinks">Terms of Service</Link>
                                            :
                                            <Link to='/terms-of-service' className="footerLinks">Terms of Service</Link>
                                    }
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-6 col-md-6 col-12">
                            <h4 className="heading_">Community</h4>
                            <ul className="footerMenus">
                                {/* <li className="footerItems">
                                    {
                                        checkAccount === 1 ?
                                            <Link className="footerLinks">Get Started</Link>
                                            :
                                            <Link to='/membership-choose-per-role' className="footerLinks">Get Started</Link>
                                    }
                                </li> */}
                                <li className="footerItems">
                                    {
                                        checkAccount === 1 ?
                                            <Link className="footerLinks">Membership</Link>
                                            :
                                            <Link to='/membership-choose-per-role' className="footerLinks">Membership</Link>
                                    }
                                </li>

                                {choosePlan === "Bronze" ? ""
                                    :
                                    <li className="footerItems">
                                        {
                                            checkAccount === 1 ?
                                                <Link className="footerLinks">Resources</Link>
                                                :
                                                <Link to='/resource' className="footerLinks">Resources</Link>
                                        }
                                    </li>
                                }

                                <li className="footerItems">
                                    {
                                        checkAccount === 1 ?
                                            <Link className="footerLinks">Help Desk</Link>
                                            :
                                            <Link to='/help-desk' className="footerLinks">Help Desk</Link>
                                    }
                                </li>

                            </ul>
                        </div>
                        <div className="col-lg-3 col-6 col-md-6 col-12">
                            <h4 className="heading_">Support</h4>
                            <ul className="footerMenus">
                                <li className="footerItems">
                                    {
                                        checkAccount === 1 ?
                                            <Link className="footerLinks">FAQs</Link>
                                            :
                                            <Link to='/faqs' className="footerLinks">FAQs</Link>
                                    }
                                </li>
                                <li className="footerItems">
                                    {
                                        checkAccount === 1 ?
                                            <Link className="footerLinks">About</Link>
                                            :
                                            <Link to='/about' className="footerLinks">About</Link>
                                    }
                                </li>
                                <li className="footerItems" >
                                    {
                                        checkAccount === 1 ?
                                            <Link className="footerLinks">Contact Us</Link>
                                            :
                                            <Link to='/contact-us' className="footerLinks">Contact Us</Link>
                                    }
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-6 col-md-6 text-center text-md-start col-12">
                            {
                                checkAccount === 1 ?
                                    <a href='' className="footerLinks">
                                        <img src={images.logoBlack} className='img-fluid footerImg' alt='footerImg' />
                                    </a>
                                    :
                                    <a href='/' className="footerLinks">
                                        <img src={images.logoBlack} className='img-fluid footerImg' alt='footerImg' />
                                    </a>

                            }
                        </div>
                    </div>
                    <section className="footerBottom">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-4 mb-3">
                                <ul className="socialMenus ">
                                    <li className="socialItems">
                                        {
                                            checkAccount === 1 ?
                                                <a className="socialLinks" href="https://www.instagram.com/OppswellInc/" target="_blank">
                                                    <img src={images.instaIcon} alt="Insta" />
                                                </a>
                                                :
                                                <a className="socialLinks" href="https://www.instagram.com/OppswellInc/" target="_blank">
                                                    <img src={images.instaIcon} alt="Insta" />
                                                </a>
                                        }
                                    </li>
                                    <li className="socialItems">
                                        {
                                            checkAccount === 1 ?
                                                <Link className="socialLinks">
                                                    <img src={images.facebookIcon} alt="Facebook" />
                                                </Link>
                                                :
                                                <Link to='' className="socialLinks">
                                                    <img src={images.facebookIcon} alt="Facebook" />
                                                </Link>
                                        }
                                    </li>
                                    <li className="socialItems">
                                        {
                                            checkAccount === 1 ?
                                                <Link className="socialLinks">
                                                    <img src={images.Linkedin} alt="LinkedIn" />
                                                </Link>
                                                :
                                                <Link to='' className="socialLinks">
                                                    <img src={images.Linkedin} alt="LinkedIn" />
                                                </Link>
                                        }
                                    </li>
                                    <li className="socialItems">
                                        {
                                            checkAccount === 1 ?
                                                <Link className="socialLinks">
                                                    <img src={images.Tiktok} alt="TikTok" />
                                                </Link>
                                                :
                                                <Link to='' className="socialLinks">
                                                    <img src={images.Tiktok} alt="TikTok" />
                                                </Link>
                                        }
                                    </li>
                                    <li className="socialItems">
                                        {
                                            checkAccount === 1 ?
                                                <a href="https://twitter.com/OppswellInc" className="socialLinks">
                                                    <img src={images.twitterIcon} alt="Twitter" className="twitter" target="_blank" />
                                                </a>
                                                :
                                                <a href="https://twitter.com/OppswellInc" to='' className="socialLinks" target="_blank">
                                                    <img src={images.twitterIcon} alt="Twitter" className="twitter" />
                                                </a>
                                        }
                                    </li>


                                </ul>
                            </div>
                            <div className="col-12 col-md-8  ">
                                <p className="subtextInner text-md-center text-lg-end">
                                    ©{new Date().getFullYear()} OPPSWell - Patent Pending
                                </p>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
        </>
    )

}

export default AuthFooter;