import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addOpportunity: {},
  myPostOpportunities: {},
  updateOpportunities: {},
  getOpportunities: {},
  deleteOpportunities: {},
  activeInactiveOppertunities: {},
  applyOpportunities: {},
  opportunitiesApplication: {},
  opportunityViewDetail: {},
  deleteApplication:{},
  getopportunityList:{},
  getChatHead:{},
  loading: false,
  opportunity_loader: false
};

export const opportunitySlice = createSlice({
  name: "opportunity",
  initialState,
  reducers: {
    addOpportunity: (state) => {
      // state.loading = true;
      state.opportunity_loader = true;
    },
    setAddOpportunity: (state, action) => {
      // state.loading = false;
      state.opportunity_loader = false;
      state.isLoggedIn = true;
      state.addOpportunity = action.payload;
    },
    myPostOpportunity: (state) => {
      state.loading = true;
    },

    setMyPostOpportunity: (state, action) => {
      state.loading = false;
      state.myPostOpportunities = action.payload;
    },
    updateOpportunity: (state) => {
      // state.loading = true;
      state.opportunity_loader = true;
    },
    setUpdateOpportunity: (state, action) => {
      // state.loading = false;
      state.opportunity_loader = false;
      state.updateOpportunities = action.payload;
    },
    getOpportunity: (state) => {
      state.loading = true;
    },
    setGetOpportunity: (state, action) => {
      state.loading = false;
      state.getOpportunities = action.payload;
    },
    getopportunityList: (state) => {
      state.loading = true;
    },
    setGetOpportunityList: (state, action) => {
      state.loading = false;
      state.getopportunityList = action.payload;
    },
    deleteOpportunity: (state) => {
      state.loading = true;
    },
    setDeleteOpportunity: (state, action) => {
      state.loading = false;
      state.deleteOpportunities = action.payload;
    },
    activeInactiveOppertunity: (state) => {
      state.loading = true;
    },
    setActiveInactiveOppertunity: (state, action) => {
      state.loading = false;
      state.activeInactiveOppertunities = action.payload;
    },
    applyOpportunity: (state) => {
      state.loading = true;
    },
    setApplyOpportunity: (state, action) => {
      state.loading = false;
      state.applyOpportunities = action.payload;
    },
    getOpportunityApplication: (state) => {
      state.loading = true;
    },
    setGetOpportunityApplication: (state, action) => {
      state.loading = false;
      state.opportunitiesApplication = action.payload;
    },
    getOpportunityViewDetail: (state) => {
      state.loading = true;
    },
    setGetOpportunityViewDetail: (state, action) => {
      state.loading = false;
      state.opportunityViewDetail = action.payload;
    },
    myReviewResponsesOpportunity: (state) => {
      state.loading = true;
    },
    setMyReviewResponsesOpportunity: (state, action) => {
      state.loading = false;
    },
    opportunityResponse: (state) => {
      state.loading = true;
    },
    setOpportunityResponse: (state, action) => {
      state.loading = false;
    },
    getChatHead: (state) => {
      state.loading = true;
    },
    setGetChatHead: (state, action) => {
      state.loading = false;
    },
    myReviewRespondentsOpportunity: (state) => {
      state.loading = true;
    },
    setMyReviewRespondentsOpportunity: (state, action) => {
      state.loading = false;
    },
    addPostOpportunityUploadPhotoOrPdf: (state) => {
      state.loading = true;
    },
    setAddPostOpportunityUploadPhotoOrPdf: (state, action) => {
      state.loading = false;
    },
    getAllCurriencies: (state) => {
      state.loading = true;
    },
    setAllCurriencies: (state, action) => {
      state.loading = false;
    },
    reviewResponseDelete: (state) => {
      state.loading = false;
    },
    setReviewResponseDelete: (state, action) => {
      state.loading = true;
    },
    deleteApplication: (state) => {
      state.loading = false;
    },
    setDeleteApplication: (state, action) => {
      state.loading = true;
    },
    onErrorStopLoad: (state) => {
       state.loading = false;
      state.opportunity_loader = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addOpportunity,
  addPostOpportunityUploadPhotoOrPdf,
  setAddPostOpportunityUploadPhotoOrPdf,
  setAddOpportunity,
  myPostOpportunity,
  setMyPostOpportunity,
  updateOpportunity,
  setUpdateOpportunity,
  getOpportunity,
  setGetOpportunity,
  deleteOpportunity,
  setDeleteOpportunity,
  activeInactiveOppertunity,
  setActiveInactiveOppertunity,
  applyOpportunity,
  setApplyOpportunity,
  getOpportunityApplication,
  setGetOpportunityApplication,
  getOpportunityViewDetail,
  setGetOpportunityViewDetail,
  getChatHead,
  setGetChatHead,
  myReviewResponsesOpportunity,
  setMyReviewResponsesOpportunity,
  myReviewRespondentsOpportunity,
  setMyReviewRespondentsOpportunity,
  onErrorStopLoad,
  getAllCurriencies,
  setAllCurriencies,
  reviewResponseDelete, 
  setReviewResponseDelete,
  opportunityResponse,
  setOpportunityResponse,
  deleteApplication,
  setDeleteApplication,
  getopportunityList,
  setGetOpportunityList
} = opportunitySlice.actions;

export default opportunitySlice.reducer;
