import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import * as Images from "../../../utilities/images";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import Multiselect from 'multiselect-react-dropdown';
import "react-datepicker/dist/react-datepicker.css";
import { businessOwnershipType, getAllCountryList, getAllIndustryGroups, getAllIndustrySubGroups, getAllProvinceList, } from '../../../redux/slices/auth';
import { useDispatch } from 'react-redux';
import { addPostOpportunityUploadPhotoOrPdf, getAllCurriencies, getOpportunity, updateOpportunity } from "../../../redux/slices/opportunity";
import { diverseSupplierDescription, existingCertification, tradeMemberShip } from '../../../redux/slices/advanceSearch';
import { useOpportunitySelector } from '../../../redux/selector/opportunity';

const EditPostOpportunity = (props) => {

    // // Define arrays for days, months, and years
    // const days = Array.from({ length: 31 }, (_, i) => i + 1);
    // const months = [
    //     'January', 'February', 'March', 'April', 'May', 'June',
    //     'July', 'August', 'September', 'October', 'November', 'December'
    // ];
    // // const currentYear = new Date().getFullYear();
    // // const years = Array.from({ length: 50 }, (_, i) => currentYear - i);

    // const currentYear = new Date().getFullYear();
    // const futureYearsCount = 50; // Change this number as needed for the desired future years

    // const years = Array.from({ length: futureYearsCount }, (_, i) => currentYear + i);


    // const daysInMonth = (month, year) => {
    //     return new Date(year, month, 0).getDate();
    // };

    // const [selectedDay, setSelectedDay] = useState('');
    // const [selectedMonth, setSelectedMonth] = useState('');
    // const [selectedYear, setSelectedYear] = useState('');
    // const [daysArray, setDaysArray] = useState([]);
    // const [selectedDate, setSelectedDate] = useState('');

    // console.log(selectedDay, selectedMonth, selectedYear, "FDGGGGGGGGGGGGGG");

    // const months = [
    //     'January', 'February', 'March', 'April', 'May', 'June',
    //     'July', 'August', 'September', 'October', 'November', 'December'
    // ];

    // const currentYear = new Date().getFullYear();
    // const futureYearsCount = 50;
    // const years = Array.from({ length: futureYearsCount }, (_, i) => currentYear + i);

    // useEffect(() => {
    //     if (selectedYear !== '' && selectedMonth !== '') {
    //         const days = daysInMonth(selectedMonth, selectedYear);
    //         const daysArray = Array.from({ length: days }, (_, i) => i + 1);
    //         setDaysArray(daysArray);
    //         if (selectedDay > days) {
    //             setSelectedDay('');
    //         }
    //     }
    // }, [selectedMonth, selectedYear]);

    // const handleDayChange = (e) => {
    //     setSelectedDay(e.target.value);
    //     updateSelectedDate(e.target.value, selectedMonth, selectedYear);
    // };


    // const handleMonthChange = (e) => {
    //     setSelectedMonth(e.target.value);
    //     setSelectedDay('');
    //     updateSelectedDate(selectedDay, e.target.value, selectedYear);
    // };

    // const handleYearChange = (e) => {
    //     setSelectedYear(e.target.value);
    //     setSelectedMonth('');
    //     setSelectedDay('');
    //     updateSelectedDate(selectedDay, selectedMonth, e.target.value);
    // };


    // const updateSelectedDate = (day, month, year) => {
    //     if (day && month && year) {
    //         const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    //         setSelectedDate(formattedDate);
    //     }
    // };


    const navigate = useNavigate();
    const dispatch = useDispatch();
    const opportunitySelector = useOpportunitySelector()
    const [postingtitle, setPostingtitle] = useState("");
    const [responseDeadline, setResponseDeadline] = useState(new Date());
    const [awardDate, setAwardDate] = useState("");
    const [description, setDescription] = useState("");

    const [currency, setCurrency] = useState('');
    const [allCurriencies, setAllCurriencies] = useState([]);

    const [contractAmount, setContractAmount] = useState("");
    const [contractLength, setContractLength] = useState("");
    const [industryGroup, setIndustryGroup] = useState([])
    const [industryGroupId, setIndustryGroupId] = useState("");
    const [industrySubGroupId, setIndustrySubGroupId] = useState("")
    const [subIndustryGroup, setSubIndustryGroup] = useState([])
    const [specificServiceLocationRequired, setSpecificServiceLocationRequired] = useState(true);
    const [serviceLocation, setServiceLocation] = useState("");
    const [ownershipTypeId, setOwnershipTypeId] = useState("");
    const [ownershipType, setOwnershipType] = useState("");
    const [companyDescription, setCompanyDescription] = useState("");
    const [selectedDiverseStatus, setSelectedDiverseStatus] = useState([]);
    const [driversSupplierDescriptionId, setDriversSupplierDescriptionId] = useState();
    const [existingCertificationData, setExistingCertificationData] = useState("");
    const [selectedCertifications, setSelectedCertifications] = useState([]);
    const [existingCertificationsId, setExistingCertificationsId] = useState();
    const [serviceLicensesRequires, setServiceLicensesRequires] = useState(true);
    const [certificationOtherDescription, setCertificationOtherDescription] = useState("")

    const [countryId, setCountryId] = useState("");
    const [provinceId, setProvinceId] = useState("");

    const [getAllcountry, setGetAllcountry] = useState("");
    const [getAllProvince, setAllProvince] = useState("");
    const [workPreference, setWorkPreference] = useState(1);
    const [checkboxValuesJV, setCheckboxValuesJV] = useState("");
    const [checkboxValuesSecondTier, setCheckboxValuesSecondTier] = useState("");
    const [tradeMemberShipData, setTradeMemberShipData] = useState("");

    const [tradeMemberships, setTradeMemberships] = useState([]);
    const [tradeMemberShipId, setTradeMemberShipId] = useState([]);

    const [otherTradeMemberships, setOtherTradeMemberships] = useState("")

    // // State variables to store selected values
    // const [selectedDay, setSelectedDay] = useState('');
    // const [selectedMonth, setSelectedMonth] = useState('');
    // const [selectedYear, setSelectedYear] = useState('');
    // const [selectedDate, setSelectedDate] = useState('');

    // console.log(selectedDay, selectedMonth, selectedYear, "$$$$$$$$$$$");

    const [photo, setPhotoUpload] = useState("");
    const [photoInfo, setPhotoInfo] = useState({
        "imagename": "",
        "imagemimetype": "",
        "imagesize": "",
    });

    const exceptThisSymbols = ["e", "E", "+", "-", "."];

    const selectedValue = selectedDiverseStatus?.map((data) => { return data?.diversity_status_details })
    const certificationSelected = selectedCertifications?.map((data) => { return data?.existing_certificates_details })

    // for find selected Trade Memberships details
    const selectedMembershipsValue = tradeMemberships?.map((data) => { return data?.trade_membership_details });

    //remove file
    const removeFile = file => () => {
        const newFiles = [photo]
        newFiles.splice(newFiles.indexOf(file), 1)
        setPhotoUpload(newFiles)
        setPhotoInfo("")
    }

    // onchange date set date in the state
    const onChangeDateAward = (date) => {
        setAwardDate(date)
    }


    // onchange date set date in the state
    const onChangeDateResponse = (date) => {
        setResponseDeadline(date)
    }

    const industry = (e) => {
        dispatch(getAllIndustryGroups({
            cb(res) {
                if (res.status) {
                    setIndustryGroup(res?.data?.payload)
                }
                else {

                }
            }
        }))
    }

    const getAllCurrienciesAction = () => {
        dispatch(getAllCurriencies({
            cb(res) {
                if (res.status == 200) {
                    setAllCurriencies(res.data.payload)
                }
            }
        }))
    }

    // onchange input 
    const handleChange = (id, flag) => {
        if (flag == "industry") {
            setIndustryGroupId(id)
            subIndustry(id)
        }
        else {
            setIndustrySubGroupId(id)
        }
    }

    const subIndustry = (id) => {
        if (id) {
            setIndustryGroupId(id)
            let params = {
                id: id,
            }
            dispatch(getAllIndustrySubGroups({
                ...params, cb(res) {
                    if (res.status) {
                        setSubIndustryGroup(res?.data?.payload)
                        // setIndustrySubGroupId("")
                    }
                    else {

                    }
                }
            }))
        }
    }

    // handle change address
    const handleSelect = async value => {
        const result = await geocodeByAddress(value);
        const ll = await getLatLng(result[0]);
        setServiceLocation(value)
        // setCordinates(ll)
    }

    const businessOwnership = (id) => {
        setOwnershipTypeId(id)
        dispatch(businessOwnershipType({
            cb(res) {
                if (res.status) {
                    setOwnershipType(res?.data?.payload)
                }
                else {

                }
            }
        }))
    }

    // Existing certification
    const ExistingCertification = () => {
        dispatch(existingCertification({
            cb(res) {
                if (res.status) {
                    setExistingCertificationData(res.data.payload)
                }
                else {

                }
            }
        }))
    }


    //for add drivers Supplier Description........
    const SupplierDescription = (id) => {
        setDriversSupplierDescriptionId(id)
        // if (id == 16) {
        //     setCompanyDescriptionOtherId(id)
        //     setCompanyDescriptionId("")
        //     setCompanyOtherDescription("")
        // }
        // else {
        //     setCompanyDescriptionId(id)
        //     setCompanyDescriptionOtherId("")
        //     setCompanyOtherDescription("")
        // }

        dispatch(diverseSupplierDescription({
            cb(res) {
                if (res.status) {
                    setCompanyDescription(res?.data?.payload)
                }
            }
        }))
    }

    // Existing Certifications
    const handleChangesCertifications = (data) => {
        // setSelectedCertifications(data)
        let arr = [];
        for (let i = 0; i < data.length; i++) { arr.push(data[i].id) }
        setExistingCertificationsId(arr)
    }


    // driver supplier description
    const handleChanges = (data) => {
        // setSelectedDiverseStatus(data)
        let arr = [];
        for (let i = 0; i < data.length; i++) { arr.push(data[i].id) }
        setDriversSupplierDescriptionId(arr)
    }


    //onDrop
    const onDrop = useCallback(acceptedFiles => {
        const imageFile = acceptedFiles[0];
        // if (!imageFile.name.match(/\.(jpg|jpeg|png|gif|svg)$/)) {
        //     toast.error("Please select a valid image.");
        //     return false;
        // }
        let params = {
            photo: imageFile,
        }
        dispatch(addPostOpportunityUploadPhotoOrPdf({
            ...params, cb(res) {
                if (res.status) {
                    setPhotoUpload(res?.data?.payload?.url);
                    setPhotoInfo(({
                        imagename: res?.data?.payload?.name,
                        imagemimetype: res?.data?.payload?.format,
                        imagesize: res.data.payload.size

                    }));
                }
                else {
                }
            }
        }))

    }, [])

    const { getRootProps, getInputProps } =

        useDropzone({
            onDrop,
            accept: {
                'image/jpeg': [],
                'image/jpg': [],
                'image/png': [],
                'application/pdf': [],
                'application/msword': [], // .doc files
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [] //docx file
            }
        });

    const handleChangesTradeMembership = (data) => {
        let arr = [];
        for (let i = 0; i < data.length; i++) { arr.push(data[i].id) }


        setTradeMemberShipId(arr);
    };


    // Trade Membership
    const TradeMembership = () => {
        dispatch(
            tradeMemberShip({
                cb(res) {
                    if (res.status) {
                        setTradeMemberShipData(res.data.payload);
                    } else {
                    }
                },
            })
        );
    };

    //Open to JV supplier opportunities
    const handleCheckboxChangeJV = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            // If checkbox is checked, add the value to the array
            // setCheckboxValuesJV([...checkboxValuesJV, value]);
            // If checkbox is checked, update the checkbox values accordingly
            const updatedValues = value;
            setCheckboxValuesJV(updatedValues);
        } else {
            // If checkbox is unchecked, remove the value from the array
            // setCheckboxValuesJV(checkboxValuesJV.filter((item) => item !== value));
            // If checkbox is unchecked, remove the value from the array
            setCheckboxValuesJV("");

        }
    };


    const handleCheckboxChangeSecondTier = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            // If checkbox is checked, add the value to the array
            // setCheckboxValuesSecondTier([...checkboxValuesSecondTier, value]);
            const updatedValues = value;
            setCheckboxValuesSecondTier(updatedValues);
        } else {
            // If checkbox is unchecked, remove the value from the array
            // setCheckboxValuesSecondTier(checkboxValuesSecondTier.filter((item) => item !== value));
            setCheckboxValuesSecondTier("")
        }
    };

    //for get all countrys .......
    const allCountry = (id) => {
        setCountryId(id)
        dispatch(
            getAllCountryList({
                cb(res) {
                    if (res.status) {
                        setGetAllcountry(res?.data?.payload);
                        if (id) {
                            provinceList(id);
                            setProvinceId("")
                        }
                        else {
                            setAllProvince("")
                        }
                    }
                },
            })
        );
    };


    //for get all provinceList .......
    const provinceList = (id) => {
        if (id) {
            let params = {
                id: id
            };
            dispatch(
                getAllProvinceList({
                    ...params,
                    cb(res) {
                        if (res.status) {
                            setAllProvince(res?.data?.payload?.data);
                        }
                    },
                })
            );
        }
    };



    //for update Opportunity
    const getOpportunityDetail = (e) => {
        let params = {
            id: props.id
        }
        dispatch(getOpportunity({
            ...params, cb(res) {

                if (res.status) {
                    setPostingtitle(res?.data?.payload?.posting_title)
                    setResponseDeadline(new Date(res?.data?.payload?.response_deadline_date))
                    if (res?.data?.payload?.award_date == null) {
                        setAwardDate(null)
                    }
                    else {
                        setAwardDate(new Date(res?.data?.payload?.award_date))
                    }
                    setDescription(res?.data?.payload?.description)
                    setContractAmount(res?.data?.payload?.opportunity_amount)
                    setContractLength(res?.data?.payload?.contract_length)
                    setIndustryGroupId(res?.data?.payload?.industries_group_details?.id)
                    setSubIndustryGroup(res?.data?.payload?.industries_sub_group_details)
                    setIndustrySubGroupId(res?.data?.payload?.industries_sub_group_details?.id)
                    subIndustry(res?.data?.payload?.industries_group_details?.id)
                    setSpecificServiceLocationRequired(res?.data?.payload?.specific_service_locations_required)
                    setServiceLocation(res?.data?.payload?.hq_location)
                    setOwnershipTypeId(res?.data?.payload?.business_ownership_type)
                    setServiceLicensesRequires(res?.data?.payload?.service_licenses_required)
                    setSelectedCertifications(res?.data?.payload?.opportunity_existing_certificate_details)
                    const selectedValues = res?.data?.payload?.opportunity_existing_certificate_details?.map((data) => { return data?.existing_certificates_id })
                    setExistingCertificationsId(selectedValues)
                    setSelectedDiverseStatus(res?.data?.payload?.post_opportunity_diversity_details)
                    const selectedValuesDiverseStatus = res?.data?.payload?.post_opportunity_diversity_details?.map((data) => { return data?.diversity_status_id })
                    setDriversSupplierDescriptionId(selectedValuesDiverseStatus)
                    setPhotoUpload(res?.data?.payload?.opportunity_media);
                    setPhotoInfo(({
                        imagename: res?.data?.payload?.image_name,
                        imagemimetype: res?.data?.payload?.image_mime_type,
                        imagesize: res.data.payload.image_size
                    }));
                    setCurrency(res?.data?.payload?.currency)
                    setCountryId(res?.data?.payload?.country_id)
                    setProvinceId(res?.data?.payload?.province_id)
                    setWorkPreference(res?.data?.payload?.work_preference)
                    setCheckboxValuesJV(res?.data?.payload?.jv_opportunities)
                    setCheckboxValuesSecondTier(res?.data?.payload?.second_tier_jv_opportunities)
                    setTradeMemberships(res?.data?.payload?.opportunity_trade_membership)
                    // setTradeMemberShipData(res?.data?.payload?.opportunity_trade_membership)
                    const selectedValuesMemberships = res?.data?.payload?.opportunity_trade_membership?.map((data) => { return data?.trade_membership_id })
                    setTradeMemberShipId(selectedValuesMemberships)

                }
                else {
                }
            }
        }))
    }

    // // onchange input
    // const handleChangedCountry = (id, flag) => {
    //     if (flag == "country") {
    //         setCountryId(id);
    //         // setDiableProvince(id);
    //         provinceList(id);
    //     } else {
    //         setProvinceId(id);
    //     }
    // };

    // onchange input 
    const handleChangedProvince = (id) => {
        setProvinceId(id)
    }


    //for add Opportunity
    const handleSubmit = (e) => {
        e.preventDefault();
        const regex = /^[a-z|A-Z]+(?: [a-z|A-Z]+)*$/
        if (!postingtitle) {
            toast.error("Please enter posting title");
            return;
        }
        // else if (postingtitle.length <= 2) {
        //     toast.error("Posting title should be maximum 3 character")
        //     return;
        // }
        // else if (!postingtitle.match(regex)) {
        //     toast.error("Please enter only character and single space in posting title");
        //     return;
        // }
        // else if (!responseDeadline) {
        //     toast.error("Please select response deadline");
        //     return;
        // }
        // else if (!awardDate) {
        //     toast.error("Please select award date");
        //     return;
        // }
        else if (!description) {
            toast.error("Please enter description of contract opportunity");
            return;
        }
        else if (description.length <= 2) {
            toast.error("description should be maximum 3 character")
            return;
        }
        // else if (!currency) {
        //     toast.error("Please enter currency");
        //     return;
        // }
        // else if (!contractAmount) {
        //     toast.error("Please enter contract amount");
        //     return;
        // }
        // else if (!contractLength) {
        //     toast.error("Please enter contract length");
        //     return;
        // }
        else if (!industryGroupId) {
            toast.error("Please select Industry");
            return;
        }
        // else if (!industrySubGroupId) {
        //     toast.error("Please select Sub-industry");
        //     return;
        // }
        else if (!countryId) {
            toast.error("Please select Business Location");
            return;
        }
        // else if (!provinceId) {
        //     toast.error("Please select Business Area");
        //     return;
        // }
        // else if (!workPreference) {
        //     toast.error("Please select Work Preference");
        //     return;
        // }
        // else if (!driversSupplierDescriptionId) {
        //     toast.error("Please select Diverse Status");
        //     return;
        // }
        // else if (!checkboxValuesJV) {
        //     toast.error("Please select Open to JV Opportunities");
        //     return;
        // }
        // else if (postingtitle.length <= 2) {
        //     toast.error("Posting Title should be maximum 3 character")
        //     return;
        // }
        // else if (!postingtitle.match(regex)) {
        //     toast.error("Please enter only character and single space in Posting Title");
        //     return;
        // }
        // else if (!responseDeadline) {
        //     toast.error("Please select response Deadline");
        //     return;
        // }
        // else if (!awardDate) {
        //     toast.error("Please select award Date");
        //     return;
        // }
        // else if (!description) {
        //     toast.error("Please enter Description");
        //     return;
        // }
        // else if (description.length <= 2) {
        //     toast.error("Description should be maximum 3 character")
        //     return;
        // }
        // // else if (!description.match(regex)) {
        // //     toast.error("Please enter only character and single space in Description");
        // //     return;
        // // }
        // else if (!opportunityAmount) {
        //     toast.error("Please select opportunity Amount");
        //     return;
        // }
        // else if (!contractLength) {
        //     toast.error("Please select contract Length");
        //     return;
        // }
        // else if (!photo || !photoInfo) {
        //     toast.error("Please select opportunity media");
        //     return;
        // }
        // // else if (!photo.length) {
        // //     toast.error("Please select opportunity Media");
        // //     return;
        // // }
        // else if (!preferencesSupplier) {
        //     toast.error("Please select preferences for Supplier");
        //     return;
        // }
        // else if (!serviceCategories) {
        //     toast.error("Please select Product and Service Categories");
        //     return;
        // }
        // // else if (!serviceLocation) {
        // //     toast.error("Please select service Location");
        // //     return;
        // // }
        // else if (!annualRevenue) {
        //     toast.error("Please select annual Revenue");
        //     return;
        // }

        // else if (!numberOfEmployees) {
        //     toast.error("Please select number of Employees");
        //     return;
        // }
        // else if (!yearsInBusiness) {
        //     toast.error("Please select years in Business");
        //     return;
        // }
        // else if (!industriesServed) {
        //     toast.error("Please enter industries Served");
        //     return;
        // }
        // else if (industriesServed.length <= 2) {
        //     toast.error("Industries Served should be maximum 3 character")
        //     return;
        // }
        // else if (!industriesServed.match(regex)) {
        //     toast.error("Please enter only character and single space in Industries Served");
        //     return;
        // }
        // else if (!businessTypesServed) {
        //     toast.error("Please enter business types Served");
        //     return;
        // }
        // else if (businessTypesServed.length <= 2) {
        //     toast.error("Business Types Served should be maximum 3 character")
        //     return;
        // }
        // else if (!businessTypesServed.match(regex)) {
        //     toast.error("Please enter only character and single space in business Types Served");
        //     return;
        // }
        // else if (!certifications) {
        //     toast.error("Please enter certifications");
        //     return;
        // }
        // else if (certifications.length <= 2) {
        //     toast.error("Certifications should be maximum 3 character")
        //     return;
        // }
        // else if (!certifications.match(regex)) {
        //     toast.error("Please enter only character and single space in Certifications");
        //     return;
        // }
        // }
        if (existingCertificationsId?.includes(11) && !certificationOtherDescription) {
            toast.error("please enter other certificate description");
            return;
        }
        let params = {
            id: props.id,
            posting_title: postingtitle.trim(),
            response_deadline_date: responseDeadline,
            award_date: awardDate ? awardDate : "",
            description: description.trim(),
            currency: currency ? currency : "",
            opportunity_amount: contractAmount ? contractAmount : 0,
            contract_length: contractLength ? contractLength : null,
            industry_group: industryGroupId ? industryGroupId : 0,
            industry_sub_group: industrySubGroupId ? industrySubGroupId : 0,
            specific_service_locations_required: specificServiceLocationRequired,
            hq_location: serviceLocation,
            business_ownership_type: ownershipTypeId ? ownershipTypeId : null,
            diversity_status: driversSupplierDescriptionId,
            existing_certificates: existingCertificationsId,
            service_licenses_required: serviceLicensesRequires,
            opportunity_media: photo.toString() ? photo.toString() : "",
            image_name: photoInfo?.imagename ? photoInfo?.imagename : "",
            image_size: photoInfo?.imagesize ? photoInfo?.imagesize : 0,
            image_mime_type: photoInfo?.imagemimetype ? photoInfo?.imagemimetype : "",
            jv_opportunities: checkboxValuesJV ? checkboxValuesJV : "0",
            second_tier_jv_opportunities: checkboxValuesSecondTier ? checkboxValuesSecondTier : "0",
            country_id: countryId ? countryId : null,
            province_id: provinceId ? provinceId : null,
            work_preference: workPreference ? workPreference : "",
            opportunity_trade_membership: tradeMemberShipId ? tradeMemberShipId : ""

        }
        if (certificationOtherDescription) {
            params = {
                ...params,
                existing_certificates_other: certificationOtherDescription,
            }
        }
        dispatch(updateOpportunity({
            ...params, cb(res) {
                if (res.status) {
                    props.setActiveTab("activeOpportunity");
                    // setUpdateOpportunities(res?.data?.payload)
                }
                else {
                }
            }
        }))
    }

    useEffect(() => {
        TradeMembership();
        allCountry();
        provinceList();
        getOpportunityDetail();
        industry();
        businessOwnership();
        SupplierDescription();
        ExistingCertification();
        getAllCurrienciesAction();
        document.title = "opportunities";
    }, []);

    useEffect(() => {
        if (countryId) {
            provinceList(countryId)
        }
    }, [countryId])

    return (
        <>
            <div className='newPostOpportunity'>
                <form onSubmit={(e) => handleSubmit(e)} className='editprofileForm_ row'>
                    <h6 className='postHeading'>Edit Opportunity </h6>
                    {
                        opportunitySelector?.loading ? <>
                            <div className="row">
                                <div className="col-sm-12 text-center pt-5 pb-5">
                                    <div className="spinner-grow text-success" role="status"></div>
                                </div>
                            </div>
                        </>
                            :
                            <>

                                <div className='row'>
                                    <div className='form-group col-lg-6 col-md-12 mb-4'>
                                        <label className='postopportunity_label'>Opportunity Title <span className='ColoredText'>*</span></label>
                                        <input type='text' name="postingTitle" className='customFormControl' placeholder='Enter Title' value={postingtitle} onChange={e => setPostingtitle(e.target.value.slice(0, 30))} />
                                    </div>
                                    <div className='form-group col-lg-6  col-md-6 mb-4'>
                                        <div className='position-relative opportunityDatePicker'>
                                            <label className='postopportunity_label'>Response Deadline </label>
                                            <DatePicker
                                                className='customFormControl'
                                                selected={responseDeadline}
                                                onChange={onChangeDateResponse}
                                                // maxDate={moment().toDate()}
                                                dateFormat="MMMM d, yyyy" // Format as "Month Day, Year"
                                                placeholderText="Month Day, Year" // Example placeholder text
                                            />
                                            <img src={Images.calanderIcon} alt='calendar' className='img-fluid inputIcon newCalander' />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='form-group col-md-12 mb-5'>
                                        <label className='postopportunity_label'>Description of Opportunity <span className='ColoredText'>*</span></label>
                                        <textarea value={description} onChange={e => setDescription(e.target.value)} name="description" type='text' className='customFormControl' placeholder='Type here...' rows='7'></textarea>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='form-group col-md-2 mb-5'>
                                        <label className='postopportunity_label'>Currency</label>
                                        <select className="cateSelectbox" required="" value={currency} onChange={(e) => { setCurrency(e.target.value) }} >
                                            <option value="" >Select</option>
                                            {allCurriencies.length > 0 ?
                                                <>
                                                    {
                                                        allCurriencies.map((data) => {
                                                            return <option value={data.code}>{data.code}</option>
                                                        })
                                                    }
                                                </>
                                                :
                                                null
                                            }
                                        </select>
                                    </div>
                                    <div className='form-group col-lg-5 col-md-6 mb-5'>
                                        <label className='postopportunity_label'>Opportunity Amount</label>
                                        <input onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} value={contractAmount} onChange={e => setContractAmount(e.target.value)} type="number" className='customFormControl' pattern="[0-9]+" />
                                    </div>
                                    <div className='form-group col-lg-5 col-md-6 mb-5'>
                                        <label className='postopportunity_label'>Opportunity Length</label>
                                        <select className="cateSelectbox" required="" value={contractLength} onChange={(e) => { setContractLength(e.target.value) }}>
                                            <option value="">Select</option>
                                            <option value="1">Yearly</option>
                                            <option value="2">Monthly</option>
                                            <option value="3">Weekly</option>
                                            <option value="4">Daily</option>
                                            <option value="5">Hourly</option>
                                            <option value="0">N/A</option>
                                        </select>
                                    </div>
                                </div>
                                <hr />
                                <h6 className='postHeading pt-3 mb-5'><b>Company Details</b> </h6>
                                <div className='row'>
                                    <div className='form-group col-md-6 mb-5'>
                                        <label className='postopportunity_label'>Industry Group <span className='ColoredText'>*</span></label>
                                        <select className="cateSelectbox" required="" value={industryGroupId} onChange={(e) => { handleChange(e.target.value, "industry") }}
                                        >
                                            <option value="" >Select Industry Groups</option>
                                            {industryGroup?.length > 0 ?
                                                <>
                                                    {industryGroup.map((data) => {
                                                        return <option value={data?.id}>{data?.name}</option>
                                                    })}
                                                </>
                                                : ""}
                                        </select>
                                    </div>
                                    <div className='form-group col-md-6 mb-5'>
                                        <label className='postopportunity_label'>Sub Industry Group </label>
                                        <select className="cateSelectbox" required="" value={industrySubGroupId}
                                            onChange={(e) => { handleChange(e.target.value, "subindustry"); }}
                                        >
                                            <option value="">Select Industry Sub Groups</option>
                                            {subIndustryGroup?.length > 0 ?
                                                <>
                                                    {subIndustryGroup.map((data) => {
                                                        return <option value={data.id}>{data?.name}</option>
                                                    })}
                                                </>
                                                : ""}
                                        </select>
                                    </div>
                                    <div className='form-group col-md-6 mb-5'>
                                        <label className='postopportunity_label'>Business Location <span className='ColoredText'>*</span></label>
                                        {/* <input onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} value={contractAmount} onChange={e => setContractAmount(e.target.value)} type="number" className='customFormControl' pattern="[0-9]+" placeholder='Country, State and City' /> */}
                                        <select
                                            className="cateSelectbox"
                                            required=""
                                            value={countryId}
                                            onChange={(e) => {
                                                allCountry(e.target.value, "country");
                                            }}
                                        >
                                            <option value="" selected>Select Business Location</option>
                                            {getAllcountry?.length > 0 ? (
                                                <>
                                                    {getAllcountry.map((option, id) => (
                                                        <option value={option?.id}>{option?.name}</option>
                                                    ))}
                                                </>
                                            ) : (
                                                ""
                                            )}
                                        </select>
                                    </div>
                                    <div className='form-group col-md-6 mb-5'>
                                        {/* {countryId ? (
                            <> */}
                                        <div className="location_ mt-2 mb-2 ">
                                            <h5 className="postopportunity_label mb-2">
                                                Business Area
                                            </h5>
                                            <select
                                                className="cateSelectbox"
                                                required=""
                                                value={provinceId}
                                                onChange={(e) => {
                                                    handleChangedProvince(e.target.value, "province");
                                                }}
                                            >
                                                <option value="">Select Business Area</option>
                                                {getAllProvince?.length > 0 ? (
                                                    <>
                                                        {getAllProvince.map((option, id) => (
                                                            <option value={option?.id}>
                                                                {option?.name}
                                                            </option>
                                                        ))}
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                            </select>
                                        </div>
                                        {/* </>
                        ) : (
                            <></>
                        )} */}
                                    </div>
                                    {/* <div className='form-group col-md-6 mb-5'>
                        <label className='postopportunity_label'>Business Area </label>
                        <select className="cateSelectbox" required="" value={industrySubGroupId}
                            onChange={(e) => { handleChange(e.target.value, "subindustry"); }}
                        >
                            <option value="">Select Industry Sub Groups</option>
                            {subIndustryGroup?.length > 0 ?
                                <>
                                    {subIndustryGroup.map((data) => {
                                        return <option value={data.id}>{data.name}</option>
                                    })}
                                </>
                                : ""}
                        </select>
                    </div> */}
                                    <div className='form-group col-md-6 mb-5'>
                                        <label className='postopportunity_label'>Work Preference</label>
                                        <select className="cateSelectbox" required="" value={workPreference} onChange={(e) => { setWorkPreference(e.target.value) }}>
                                            <option value="1">Onsite</option>
                                            <option value="2">Remote</option>
                                            <option value="3">Hybrid</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='row'>
                                    {/* <div className='form-group col-md-5 mb-5'>
                        <label className='postopportunity_label'>Specific Service Location Required?</label>
                        <div className="form-group col-md-12 mt-3 d-flex">
                            <div className="checkmark_ radioCheck_" >
                                <input
                                    type="radio"
                                    id="Location"
                                    className="businessType_"
                                    checked={specificServiceLocationRequired === true}
                                    name="radio-group"
                                    onChange={() => {
                                        setSpecificServiceLocationRequired(true);
                                    }}
                                />
                                <label className="labelTxt_" for="Location">
                                    <span className='innerSubtextSmall'>Yes</span>
                                </label>
                            </div>
                            <div className="checkmark_ radioCheck_" >
                                <input
                                    type="radio"
                                    id="useriD"
                                    className="businessType_"
                                    checked={specificServiceLocationRequired === false}
                                    name="radio-group"
                                    onChange={() => {
                                        setSpecificServiceLocationRequired(false);
                                    }}
                                />
                                <label className="labelTxt_" for="useriD">
                                    <span className='innerSubtextSmall'>No</span>
                                </label>
                            </div>
                        </div>
                    </div> */}
                                </div>
                                {/* <div className='form-group col-md-5 mb-5'>
                    <label className='postopportunity_label'>Service Location</label>
                    <PlacesAutocomplete
                        value={serviceLocation}
                        onChange={setServiceLocation}
                        onSelect={handleSelect}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                                <input
                                    {...getInputProps({
                                        placeholder: 'Search Places ...',
                                        className: ' customFormControl location-search-input form-control textArea',
                                    })}
                                />
                                <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map(suggestion => {
                                        const className = suggestion.active
                                            ? 'suggestion-item--active'
                                            : 'suggestion-item';
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                        return (
                                            <div
                                                {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style,
                                                })}
                                            >
                                                <span>{suggestion.description}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </PlacesAutocomplete>
                </div> */}

                                <div className='row'>
                                    <div className='form-group col-md-6 mb-5'>
                                        <label className="headingSmall_ mb-2" htmlFor="">Diverse Status</label>
                                        {companyDescription &&
                                            <Multiselect
                                                options={companyDescription} // Options to display in the dropdown
                                                selectedValues={selectedValue} // Preselected value to persist in dropdown
                                                onSelect={handleChanges} // Function will trigger on select event
                                                onRemove={handleChanges} // Function will trigger on remove event
                                                displayValue="name" // Property name to display in the dropdown options
                                            />
                                        }
                                    </div>
                                    <div className='form-group col-md-6 mb-5'>
                                        <label className="headingSmall_ mb-2" htmlFor="">Business Ownership type</label>
                                        <select className="cateSelectbox" required="" value={ownershipTypeId} onChange={(e) => { businessOwnership(e.target.value) }}>
                                            <option value="">Business Ownership type</option>
                                            {ownershipType?.length > 0 ?
                                                <>
                                                    {ownershipType?.map((data) => {
                                                        return <option value={data?.id}>{data?.name}</option>
                                                    })}

                                                </>
                                                : ""}
                                        </select>
                                    </div>

                                    {/* {driversSupplierDescriptionId?.includes(16) ?

                        <div className='form-group col-md-5 mb-5'>
                            <label className="headingSmall_ mb-2" htmlFor="">Diverse Status Other</label>
                            <input name="companyDescription" type='text' className='customFormControl' value={companyOtherDescription} placeholder='Other Description' disabled="" onChange={e => setCompanyOtherDescription(e.target.value)} />
                        </div> : ""

                    } */}
                                    <div className='form-group col-md-6 mb-5'>
                                        <label className='postopportunity_label'>Open to Joint Venture Opportunities </label>
                                        <div className="form-group col-md-12 mt-3 d-flex">
                                            <div className="checkmark_ radioCheck_">
                                                <input
                                                    type="checkbox"
                                                    id="Yes"
                                                    value="1"
                                                    checked={checkboxValuesJV.includes("1")}
                                                    onChange={handleCheckboxChangeJV}
                                                    className="checkBox me-2"
                                                />
                                                <label
                                                    htmlFor="Yes"
                                                    className="checkBoxTxt align-items-start align-items-md-center"
                                                >
                                                    <span>Yes</span>
                                                </label>
                                            </div>
                                            <div className="checkmark_ radioCheck_">
                                                <input
                                                    type="checkbox"
                                                    id="No"
                                                    value="2"
                                                    checked={checkboxValuesJV.includes("2")}
                                                    onChange={handleCheckboxChangeJV}
                                                    className="checkBox me-2"
                                                />
                                                <label
                                                    htmlFor="No"
                                                    className="checkBoxTxt align-items-start align-items-md-center"
                                                >
                                                    <span>No</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='form-group col-md-6 mb-5'>
                                        <label className='postopportunity_label'>Open to Second Tier Opportunities</label>
                                        <div className="form-group col-md-12 mt-3 d-flex">
                                            <div className="checkmark_ radioCheck_">
                                                <input
                                                    type="checkbox"
                                                    id="Yes1"
                                                    value="1"
                                                    checked={checkboxValuesSecondTier.includes("1")}
                                                    onChange={handleCheckboxChangeSecondTier}
                                                    className="checkBox me-2"
                                                />
                                                <label
                                                    htmlFor="Yes1"
                                                    className="checkBoxTxt align-items-start align-items-md-center"
                                                >
                                                    <span>Yes</span>
                                                </label>
                                            </div>
                                            <div className="checkmark_ radioCheck_">
                                                <input
                                                    type="checkbox"
                                                    id="No1"
                                                    value="2"
                                                    checked={checkboxValuesSecondTier.includes("2")}
                                                    onChange={handleCheckboxChangeSecondTier}
                                                    className="checkBox me-2"
                                                />
                                                <label
                                                    htmlFor="No1"
                                                    className="checkBoxTxt align-items-start align-items-md-center"
                                                >
                                                    <span>No</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='form-group col-md-6 mb-5 multiselect__'>
                                        <label className='postopportunity_label'>Diversity Certifications</label>
                                        {existingCertificationData &&
                                            <Multiselect
                                                options={existingCertificationData} // Options to display in the dropdown
                                                selectedValues={certificationSelected} // Preselected value to persist in dropdown
                                                onSelect={handleChangesCertifications} // Function will trigger on select event
                                                onRemove={handleChangesCertifications} // Function will trigger on remove event
                                                displayValue="name" // Property name to display in the dropdown options
                                            />
                                        }
                                    </div>
                                    {existingCertificationsId?.includes(11) ?
                                        <div className='form-group col-md-12 mb-3'>
                                            <input name="companyDescription" type='text' className='customFormControl' value={certificationOtherDescription} placeholder='Other Description' disabled="" onChange={e => setCertificationOtherDescription(e.target.value)} />
                                        </div> : ""

                                    }
                                    <div className='form-group col-md-6 mb-5 multiselect__'>
                                        <label className='postopportunity_label'>Trade Memberships</label>
                                        {tradeMemberShipData && (
                                            <>
                                                <Multiselect
                                                    options={tradeMemberShipData} // Options to display in the dropdown
                                                    selectedValues={selectedMembershipsValue} // Preselected value to persist in dropdown
                                                    onSelect={handleChangesTradeMembership} // Function will trigger on select event
                                                    onRemove={handleChangesTradeMembership} // Function will trigger on remove event
                                                    displayValue="name" // Property name to display in the dropdown options
                                                    className="selectIndex"
                                                />
                                                <i class="las la-plus inputIcon"></i>

                                            </>
                                        )}
                                        {tradeMemberShipId?.includes(11) ?
                                            <div className='form-group col-md-12 mb-3'>
                                                <label className="headingSmall_ mt-3 mb-2" htmlFor="">Trade Memberships Other</label>
                                                <input name="companyDescription" type='text' className='customFormControl' value={otherTradeMemberships} placeholder='Other Trade Memberships' disabled="" onChange={e => setOtherTradeMemberships(e.target.value)} />
                                            </div> : ""
                                        }
                                    </div>
                                </div>
                                {/* <div className='form-group col-md-4 mb-3'>
                    <label className='postopportunity_label'>Service Licenses Requires?</label>
                    <div className="form-group col-md-12 mt-3 d-flex">
                        <div className="checkmark_ radioCheck_" >
                            <input

                                type="radio"
                                id="Service"
                                className="businessType_"
                                checked={serviceLicensesRequires === true}
                                value="true"
                                name="radio-group2"
                                onChange={() => {
                                    4(true);
                                }}
                            />
                            <label className="labelTxt_" for="Service">
                                <span className='innerSubtextSmall'>Yes</span>
                            </label>
                        </div>
                        <div className="checkmark_ radioCheck_" >
                            <input
                                type="radio"
                                id="ServiceLicenses"
                                className="businessType_"
                                checked={serviceLicensesRequires === false}
                                value="false"
                                name="radio-group2"
                                onChange={() => {
                                    setServiceLicensesRequires(false);
                                }} />
                            <label className="labelTxt_" for="ServiceLicenses">
                                <span className='innerSubtextSmall'>Yes</span>
                            </label>
                        </div>
                    </div>
                </div> */}
                                <div className='row'>
                                    <h6 class="postHeading"><b>Opportunity Media</b> </h6>
                                    <div className='form-group col-md-12 mb-5'>
                                        {photo != "" ?
                                            "" :
                                            <div className="uploadImgeboxNew">

                                                <div {...getRootProps({ className: "dropzone" })}>
                                                    <input {...getInputProps()} />
                                                    <img src={Images.uploadIcon} alt="uploadIcon" className='img-fluid uploadfileIcon hjgfhjfhj' />
                                                    <p className='uploadbtn'>Drag & Drop or <span className='ColoredText'>browse</span></p>
                                                    <p className='uploadText mt-2'>Supports: JPEG, JPG, PNG, PDF, DOC or DOCX</p>
                                                </div>
                                            </div>
                                        }
                                        {photo != "" ?
                                            <aside >
                                                <h4 className='headingSmall_'> Uploaded Files</h4>
                                                <ul className='uploadProfile_'>
                                                    {/* {photoInfo.imagemimetype == "application/pdf" ?

                                        <img className="w3-round" src={Images.PDFImage} />
                                        : <img className="w3-round" src={photo} />
                                    } */}
                                                    {photoInfo.imagemimetype == "application/pdf" ?
                                                        <img className="w3-round" src={Images.PDFImage} />
                                                        : photoInfo.imagemimetype == "application/msword" || photoInfo.imagemimetype == "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ?
                                                            <img className="w3-round" src={Images.DocImage} />
                                                            : <img className="w3-round" src={photo} />
                                                    }

                                                    <div className='profileDetail_'>
                                                        <h5 className='profileboldHead'>{photoInfo.imagename}</h5>
                                                        <p className='profileboldgraph'> {(photoInfo?.imagesize / (1024 * 1024)).toFixed(2)} MB</p>
                                                    </div>
                                                    <i className="fa fa-trash text-red" onClick={removeFile(photo)}></i>
                                                </ul>
                                            </aside>
                                            : ""
                                        }
                                    </div>
                                </div>
                                <button className='submitopportunity opportunityButton  mt-2 w-25 '>
                                    {console.log(opportunitySelector?.opportunity_loader, "loading data")}
                                    {opportunitySelector?.opportunity_loader && (
                                        <span className="spinner-border spinner-border-sm"></span>
                                    )} Update
                                </button>
                            </>
                    }
                </form>
            </div>
        </>

    )
}

export default EditPostOpportunity