import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import * as Images from "../../../utilities/images";
import { getChatDraft, getMessageRequest, updateRequest } from "../../../redux/slices/messages";
import moment from 'moment';
import CustomModal from '../../components/common/shared/CustomModal';
import ComposeMessage from '../accountMessage/ComposeMessages';
import { Button } from 'react-bootstrap';
import { useMessagesSelector } from '../../../redux/selector/messages';
import MessageSideBar from '../accountMessage/MessageSideBar';


const MessageRequests = () => {

    const messagesSelector = useMessagesSelector();
    const dispatch = useDispatch();
    const [chatDraft, setChatDraft] = useState("");
    const [requests, setRequests] = useState("");
    const [selectedChatDraft, setSelectedChatDraft] = useState("");
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });

    //for closeModal
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };

    // apply API for get all Drafts list

    const getRequestsData = () => {
        dispatch(getMessageRequest({
            cb(res) {
                if (res?.data) {
                    setRequests(res?.data?.payload.data)
                }
            }
        }))

    };

    const handleRequest = (e, status, id) => {
        e.preventDefault()
        let params = {
            id: id,
            status: status
        }
        dispatch(updateRequest({
            ...params,
            cb(res) {
                if (res?.data) {
                    getRequestsData()
                }
            }
        }))
    }

    useEffect(() => {
        getRequestsData();
    }, []);


    return (
        <>
            <div className='messageInboxcontent'>
                <div className='opportunityMain_ opportunityMsgHeight'>


                    <div className='row'>
                        <MessageSideBar />
                        <div className='col-md-9'>
                            <h3 className='mainHeading'>Messages Requests</h3>
                            <div className='containerBox messageContain'>

                                <div className='MessageInbox_'>
                                    {
                                        messagesSelector?.loading ?
                                            <>
                                                <div className="row">
                                                    <div className="col-sm-12 text-center pt-5 pb-5">
                                                        <div className="spinner-grow text-success" role="status"></div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                {requests?.length > 0 ?
                                                    <>
                                                        {
                                                            requests?.map((val, index) => {
                                                                return (
                                                                    <div className='postContainerArea' >
                                                                        <div className='posts messageListing align-items-center border-0' >
                                                                            <img src={val?.sender_details?.user_company_information?.company_logo ? val?.sender_details?.user_company_information?.company_logo : Images.puzzleProfile} className='postUser me-2' alt='UserImage' />
                                                                            <div className='postsDetails'>
                                                                                <h5 className='innerSubtextSmall d-flex align-items-center justify-content-between mb-2'>
                                                                                    <span><strong>{val?.sender_details?.user_company_information?.company_name}</strong></span>
                                                                                </h5>
                                                                                <p className='discription_'>{val?.chat_head_details?.last_message ? val?.chat_head_details?.last_message : ""}</p>
                                                                            </div>
                                                                            <div className='postionlabel'>
                                                                                <div className="dropdown">

                                                                                    {/* <img src={Images.Moreoption} className='moreOption me-2' alt='UserImage' /> */}
                                                                                    <button onClick={(e) => handleRequest(e, 2, val?.chat_id)}><i class="fas fa-times-circle"></i></button>
                                                                                    <button onClick={(e) => handleRequest(e, 1, val?.chat_id)}><i class="fas fa-check"></i></button>
                                                                                    {/* <ul className="dropdown-menu" aria-labelledby="moreMenu">
                                                                        <li><a className="dropdown-item" onClick={() => {
                                                                            setModalDetail({ show: true, flag: "ComposeMessage" });
                                                                            setKey(Math.random());
                                                                            setSelectedChatDraft(val)
                                                                        }}>Message</a></li>

                                                                    </ul> */}
                                                                                </div>
                                                                                <p className='loadMOre'>{moment(val?.created_at).fromNow()}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                            )
                                                        }
                                                    </>
                                                    : "No Data Founds"
                                                }
                                            </>

                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/*=== Start compose message Modal ===*/}

            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                className={modalDetail.flag === "ComposeMessage" ? "commonWidth customContent" : ""}
                ids={modalDetail.flag === "ComposeMessage" ? "ComposeMessage" : "ComposeMessage"}
                child={modalDetail.flag === "ComposeMessage" ? (
                    <ComposeMessage
                        close={() => handleOnCloseModal()}
                        chatDraft={selectedChatDraft}
                    // chatDraftsRefresh={() => getDrafts()}
                    />
                ) :
                    ""
                }
                header={
                    <div className='modalHeader_'>
                        <div className="common_">
                            {modalDetail.flag === "ComposeMessage" ?
                                <h2 className="addLabels_heading">
                                    Compose Message
                                </h2>
                                : ""
                            }
                        </div>
                    </div>
                }

                onCloseModal={() => handleOnCloseModal()}
            />
            {/*=== End compose message Modal ===*/}
        </>
    )
}

export default MessageRequests