import Cards from "./cards";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import Multiselect from "multiselect-react-dropdown";
import MultiStepProgressBar from "./component/multiStepProgressBar";
import { useDispatch } from "react-redux";
import {
  diverseSupplierDescription,
  existingCertification,
} from "../../../redux/slices/advanceSearch";
import {
  signUpStep,
  stepThreeCompanyLogoUplaod,
  stepTwo,
  getAllIndustryGroups,
  getAllIndustrySubGroups,
  businessOwnershipType,
  companyListType,
  getSessionId,
  updateMemberShipAmount,
  getPaymentViaPromoCode,
  updateUserStep,
  updateUserStepInside,
  getCompanyProfileInfo,
  logout,
} from "../../../redux/slices/auth";
import * as Images from "../../../utilities/images";
import { useAuthSelector } from "../../../redux/selector/auth";
import {
  getMemberShipPlans,
  getPaymentSession,
  verifySession,
} from "../../../redux/slices/dashboard";
import {
  checkCoupon,
  createCustomer,
  createSubscription,
  paymentMethod,
} from "../../../redux/slices/stripeIntegration";
import moment from "moment";
import CustomModal from "../../components/common/shared/CustomModal";
import AddPaymentCard from "./addPaymentCard";
import { getPaymentPlanSmallBusiness } from "../../../redux/slices/paymentPlans";
import { useStripeIntegrationSelector } from "../../../redux/selector/stripeIntegration";

const Registration = () => {

  const navigate = useNavigate();
  const stripeInfo = useStripeIntegrationSelector();
  const dispatch = useDispatch();
  const location = useLocation();
  const [customerId, setCustomerId] = useState();
  const [price_id, setPriceId] = useState(stripeInfo?.plan?.price_id);
  const [membership_id, setMembership_id] = useState(stripeInfo?.plan?.id);
  const [selectedCard, setSelectedCard] = useState("");
  const [userDeatil, setUserDeatil] = useState([])


  const [activeTab, setActiveTab] = useState("quarterly");

  const [cardLoading, setCardLoading] = useState(false);
  const [smallBusiness, setSmallBusiness] = useState("");

  const [loading, setLoading] = useState(false);
  const [paymentCards, setPaymentCards] = useState([]);

  const queryParams = new URLSearchParams(location.search);

  const authSelector = useAuthSelector();
  const Businesstype = authSelector?.userInfo?.user?.business_type;

  const flag =
    authSelector?.userStep?.step || authSelector?.userInfo?.user?.step;
  const memberShipType =
    authSelector?.userStep?.membership_type ||
    authSelector?.userInfo?.user?.membership_type;
  const membership_details =
    authSelector?.userStep?.membership_details ||
    authSelector?.userInfo?.user?.membership_details;

  const [pageNumber, setPageNumber] = useState(flag ? flag + 1 : 2);
  const [page, setPage] = useState(
    flag == 2
      ? "pagethree"
      : flag == 3
        ? "pagefour"
        : flag == 4
          ? "pagefive"
          : flag == 5
            ? "pagefive"
            : flag == 6
              ? "pagesix"
              : "pageone"
  );


  const [photo, setPhotoUpload] = useState("");
  const [photoInfo, setPhotoInfo] = useState();
  const [companyName, setCompanyName] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [companyDescription, setCompanyDescription] = useState("");
  const [companyOtherDescription, setCompanyOtherDescription] = useState("");
  const [certificationOtherDescription, setCertificationOtherDescription] =
    useState("");
  const [companyDescriptionId, setCompanyDescriptionId] = useState("");
  const [companyDescriptionOtherId, setCompanyDescriptionOtherId] =
    useState("");
  const [industryGroup, setIndustryGroup] = useState([]);
  const [subIndustryGroup, setSubIndustryGroup] = useState([]);
  const [industryGroupId, setIndustryGroupId] = useState("");
  const [industrySubGroupId, setIndustrySubGroupId] = useState("");
  const [ownershipType, setOwnershipType] = useState("");
  const [ownershipTypeId, setOwnershipTypeId] = useState("");
  const [companyType, setCompanyType] = useState("");
  const [companyTypeId, setCompanyTypeId] = useState("");
  const [companyKeyWords, setCompanyKeyWords] = useState("");
  const [companyServicesDescription, setCompanyServicesDescription] =
    useState("");
  // const [existingCertificationData, setExistingCertificationData] = useState("");
  const [existingCertificationId, setExistingCertificationId] = useState("");

  const [memberShip, setMemberShip] = useState(stripeInfo?.plan?.id);

  const [memberShipPackage, setMemberShipPackage] = useState("");
  // const [selected, setSelected] = useState([]);
  const [driversSupplierDescriptionId, setDriversSupplierDescriptionId] =
    useState([]);

  const [totalMembershipPrice, setTotalMembershipPrice] = useState("");
  const [totalMembershipPriceInteger, setTotalMembershipPriceInteger] =
    useState(0);

  const [selectedCertifications, setSelectedCertifications] = useState([]);
  const [existingCertificationData, setExistingCertificationData] =
    useState("");
  const [existingCertificationsId, setExistingCertificationsId] = useState();

  const [newMembershipPlans, setNewMembershipPlans] = useState([]);
  const [activeTabMembership, setActiveTabMembership] = useState("enter");

  const resultIndicator = queryParams.get("resultIndicator");
  const sessionVersion = queryParams.get("sessionVersion");
  const checkoutVersion = queryParams.get("checkoutVersion");
  const [key, setKey] = useState(Math.random());
  const [roleId, setRoleId] = useState("");
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const [selectedDiverseStatus, setSelectedDiverseStatus] = useState([]);
  const selected = selectedDiverseStatus?.map((data) => {
    return data?.diverse_supplier_description_details
  })

  const certificationSelected = selectedCertifications?.map((data) => { return data?.existing_certificates_details })

  useEffect(() => {
    if (Businesstype) {
      getMemberShipPlansAction(Businesstype);
    }
  }, [Businesstype]);
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  useEffect(() => {
    if (membership_details) {
      handleMembershipForPageLoad(
        membership_details.id,
        membership_details.name,
        membership_details
      );
    }
  }, [membership_details]);

  const getMemberShipPlansAction = (type) => {
    const params = {
      type: type,
    };

    dispatch(
      getMemberShipPlans({
        ...params,
        cb(res) {
          if (res.status == 200) {
            setNewMembershipPlans(res?.data?.payload);
          } else {
            toast.warning("Something went wrong");
          }
        },
      })
    );
  };

  // stripe Integrations

  // This post request will check if the customer Exists from the auth token if doesnot exist will create one
  const [discount, setDiscount] = useState(0)
  const [showCoupon, setShowCoupon] = useState(false) // MAke it false to remove promoCode 
  const [showCouponInput, setShowCouponInput] = useState(true) // MAke it true to remove promoCode 
  const [promoCodeChecked, setPromoCodeChecked] = useState(false) // MAke it false to remove promoCode 
  const [discountPercentage, setDiscountPercentage] = useState(0)
  const [promoCode, setPromoCode] = useState("FIRST50"); // MAke it "" to remove promoCode 
  const [couponId, setCouponId] = useState('')
  const createCustomerAction = () => {
    const params = {};
    dispatch(
      createCustomer({
        ...params,
        cb(res) {
          if (res.status == 200) {
            setPaymentCards(res?.data?.payload?.data);
          } else {
            toast.warning("Something went wrong");
          }
        },
      })
    );
    setCardLoading(false);
  };

  const createStripeSubscription = () => {
    return new Promise((resolve, reject) => {
      var x = true;
      if (selectedCard === "") {
        toast.dismiss();
        toast.error("Select a card First");
        resolve(false);
        return;
      }

      const params = {
        price_id: price_id,
        payment_id: selectedCard,
        customer_id: customerId,
        membership_id,
      };
      if (couponId !== "" && promoCodeChecked) {
        params.coupon = couponId
      }
      dispatch(
        createSubscription({
          ...params,
          cb(res) {
            if (res.status === 200) {
              x = true;
            } else {
              // toast.warning("Something went wrong");
              x = false;
            }

            resolve(x);
          },
        })
      );
    });
  };
  const calculateDiscount = () => {
    setDiscount(((Number(totalMembershipPrice) * Number(discountPercentage?.discount_percentage)) / 100))
  }

  const checkPromotionCode = () => {
    const params = {
      promoCode
    }
    dispatch(checkCoupon({
      ...params, cb(res) {
        if (res) {
          const resp = res?.data?.payload;
          setDiscountPercentage(Number(resp?.discount_percentage))
          setDiscount(((Number(totalMembershipPrice) * Number(resp?.discount_percentage)) / 100))
          setShowCoupon(true)
          setShowCouponInput(false)
          setPromoCodeChecked(true)
          setCouponId(resp?.promo_code_id)
          // toast.success("Promo Code Applied Sucessfully")
        }
        else {
          setPromoCode("")
        }
      }
    }))


  }

  //

  useEffect(() => {
    if (resultIndicator && sessionVersion) {
      verifySess(resultIndicator);
    }
  }, [resultIndicator && sessionVersion]);

  const verifySess = (resultIndicator) => {
    const params = {
      status: 2,
      success_token: resultIndicator,
    };

    dispatch(
      verifySession({
        ...params,
        cb(res) {
          if (res.status == 200) {
            if (res.data?.payload?.status == 2) {
              // setPageNumber(flag)
              navigate("/dashboard");
            } else {
              toast.warning("Something went wrong");
            }
          } else {
            toast.warning("Something went wrong");
          }
        },
      })
    );
  };

  //onDrop
  const onDrop = useCallback((acceptedFiles) => {
    const imageFile = acceptedFiles[0];
    if (!imageFile.name.match(/\.(jpg|jpeg|png|gif|svg)$/)) {
      toast.error("Please select a valid image.");
      return false;
    }
    let params = {
      photo: imageFile,
    };
    dispatch(
      stepThreeCompanyLogoUplaod({
        ...params,
        cb(res) {
          if (res.status) {
            setPhotoUpload(res?.data?.payload?.url);
            setPhotoInfo(res?.data?.payload);
          } else {
          }
        },
      })
    );
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  //remove file
  const removeFile = (file) => () => {
    const newFiles = [photo];
    newFiles.splice(newFiles.indexOf(file), 1);
    setPhotoUpload(newFiles);
    setPhotoInfo("");
  };

  //next page
  const nextPage = (page) => {
    if (page === "pagesix" && discountPercentage != 0) {
      setDiscount(((Number(totalMembershipPrice) * Number(discountPercentage)) / 100))
    }
    setPage(page);
  };

  //next page number
  const backPageNumber = () => {
    const newPageNumber = pageNumber.toString();
    switch (newPageNumber) {
      case "1":
        setPage("pagetwo");
        setPageNumber(2);
        break;
      case "2":
        setPage("pagetwo");
        setPageNumber(2);
        break;
      case "3":
        setPage("pagetwo");
        setPageNumber(2);
        break;
      case "4":
        setPage("pagethree");
        setPageNumber(3);
        break;
      case "5":
        setPage("pagefour");
        setPageNumber(4);
        break;
      case "6":
        setPage("pagefive");
        setPageNumber(5);
        createCustomerAction();
        break;
      default:
        setPage(1);
    }
  };

  //next page numbernextPageNumber
  const nextPageNumber = (pageNumber) => {
    switch (pageNumber) {
      case "1":
        setPage("pageone");
        setPageNumber(1);
        break;
      case "2":
        setPage("pagetwo");
        setPageNumber(2);
        break;
      case "3":
        setPage("pagethree");
        setPageNumber(3);
        break;
      case "4":
        setPage("pagefour");
        setPageNumber(4);
        break;
      case "5":
        setPage("pagefive");
        setPageNumber(5);
        break;
      case "6":
        setPage("pagesix");
        setPageNumber(6);
        break;
      default:
        setPage(1);
    }
  };
  const [currentPlan, setCurrentPlan] = useState("")
  //select membership
  const handleMembership = (event, flag, plan) => {
    setCurrentPlan(plan.name)
    setMemberShip(event.target.value);
    setMemberShipPackage(flag);
    setActiveTab("yearly");
    setTotalMembershipPriceInteger(plan.one_year_cost ?? 0);
    setTotalMembershipPrice((plan.one_year_cost ?? 0));
    setPriceId(plan.price_id);
    setMembership_id(plan.id);
  };

  //select membership
  const handleMembershipForPageLoad = (id, flag, plan) => {
    setMemberShip(id);
    setMemberShipPackage(flag);
    setActiveTab("yearly");
    setTotalMembershipPriceInteger(plan.one_year_cost ?? 0);
    setTotalMembershipPrice((plan.one_year_cost ?? 0));
    setPriceId(plan.price_id);
    setMembership_id(plan.id);
  };


  //form login
  const handleSubmit = async (e, flag, save) => {
    e.preventDefault()
    const URL =
      /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;
    e.preventDefault();

    if (flag == 3) {
      if (companyTypeId) {
        let params = {
          step: -1,
          company_id: parseInt(companyTypeId),
        };

        dispatch(
          signUpStep({
            ...params,
            cb(res) {
              if (save == "save&finish") {
                dispatch(logout({
                  cb(res) {
                    if (res.status) {
                      localStorage.removeItem("authToken");
                      localStorage.removeItem("persist:root");
                      navigate("/");
                    }
                    else {
                    }
                  }
                }))
              } else if (res.status) {
                setPageNumber(5);
                nextPage("pagefive");
              }
            },
          })
        );
      } else {
        if (!companyName) {
          toast.error("Please enter Company name");
          return;
        } else if (roleId == "") {
          toast.error("Please Select Role");
          return
        } else if (companyName.length <= 2) {
          toast.error("Company name should be minimum 3 character");
          return;
        } else if (!companyWebsite) {
          toast.error("Please enter company website url");
          return;
        } else if (
          driversSupplierDescriptionId?.includes(16) &&
          !companyOtherDescription
        ) {
          toast.error("please enter other description");
          return;
        }

        // else if (!companyWebsite.match(URL)) {
        //     toast.error("Please enter the correct company url");
        //     return;
        // }
        else if (!driversSupplierDescriptionId || !driversSupplierDescriptionId.length > 0) {
          toast.error("Please select diverse status");
          return;
        }

        // else if (!driversSupplierDescriptionId.length > 0 || !companyOtherDescription ) {
        //     toast.error("Please select diverse supplier description");
        //     return;
        // }
        // else if (!companyOtherDescription) {
        //     toast.error("Please select diverse supplier other description");
        //     return;
        // }

        // else if (!ownershipTypeId) {
        //     toast.error("Please select business ownership Type");
        //     return;
        // }

        // else if (!companyDescriptionId && !companyDescriptionOtherId) {
        //     toast.error("Please select diverse supplier description");
        //     return;
        // }

        // else if (companyDescriptionOtherId && !companyOtherDescription) {
        //     toast.error("Please enter diverse supplier description");
        //     return;
        // }

        let params = {
          step: 2,
          company_name: companyName,
          company_website: companyWebsite,
          diverse_supplier_description: driversSupplierDescriptionId,
          // diverse_supplier_description: parseInt(companyDescriptionOtherId) ? parseInt(companyDescriptionOtherId) : parseInt(companyDescriptionId),
          business_ownership_type: parseInt(ownershipTypeId)
            ? parseInt(ownershipTypeId)
            : 0,
        };
        if (companyOtherDescription) {
          params = {
            ...params,
            diverse_supplier_description_other: companyOtherDescription,
          };
        }
        if (roleId) {
          params.role_id = roleId;
        }
        dispatch(
          signUpStep({
            ...params,
            cb(res) {
              if (save == "save&finish") {
                dispatch(logout({
                  cb(res) {
                    if (res.status) {
                      localStorage.removeItem("authToken");
                      localStorage.removeItem("persist:root");
                      navigate("/");
                    }
                    else {
                    }
                  }
                }))
              } else if (res.status) {
                setPageNumber(flag);
                nextPage("pagethree");
              }
            },
          })
        );
      }
    } else if (flag == 4) {
      // if (!photo) {
      //     toast.error("Please select company logo ");
      //     return;
      // }

      let params = {
        step: 3,
        id: 350,
      };

      if (photo) {
        params = {
          ...params,
          company_logo: photo.length ? photo : "",
          company_logo_name: photoInfo ? photoInfo.name : "",
          company_logo_size: photoInfo ? photoInfo.size : 0,
        }
      }
      dispatch(
        signUpStep({
          ...params,
          cb(res) {
            if (save == "save&finish") {
              dispatch(logout({
                cb(res) {
                  if (res.status) {
                    localStorage.removeItem("authToken");
                    localStorage.removeItem("persist:root");
                    navigate("/");
                  }
                  else {
                  }
                }
              }))
            } else if (res.status) {
              setPageNumber(flag);
              nextPage("pagefour");
            }
          },
        })
      );
    } else if (flag == 5) {
      // if (!industryGroupId) {
      //     toast.error("Please select industry Groups");
      //     return;
      // }
      // else if (!industrySubGroupId) {
      //     toast.error("Please select industry Sub Groups");
      //     return;
      // }
      // else if (!companyKeyWords) {
      //     toast.error("Please enter company Key Words");
      //     return;
      // }
      // else if (!companyServicesDescription) {
      //     toast.error("Please enter company about");
      //     return;
      // }
      if (
        existingCertificationsId?.includes(11) &&
        !certificationOtherDescription
      ) {
        toast.error("please enter other certificate description");
        return;
      }
      let params = {
        step: 4,
        industry_groups: parseInt(industryGroupId)
          ? parseInt(industryGroupId)
          : null,
        industry_sub_groups: parseInt(industrySubGroupId)
          ? parseInt(industrySubGroupId)
          : null,
        company_existing_certificates_id: existingCertificationsId
          ? existingCertificationsId
          : [],
        key_words: companyKeyWords ? companyKeyWords : "",
        company_service_description: companyServicesDescription ? companyServicesDescription : "",
      };
      if (certificationOtherDescription) {
        params = {
          ...params,
          company_existing_certificates_other: certificationOtherDescription,
        };
      }
      dispatch(
        signUpStep({
          ...params,
          cb(res) {
            if (save == "save&finish") {
              dispatch(logout({
                cb(res) {
                  if (res.status) {
                    localStorage.removeItem("authToken");
                    localStorage.removeItem("persist:root");
                    navigate("/");
                  }
                  else {
                  }
                }
              }))
            } else if (res.status) {
              setPageNumber(flag);
              nextPage("pagefive");
            }
          },
        })
      );
    } else if (flag == 6) {
      //uncomment this code after approval for promo code

      // if(activeTabMembership =='enter' && authSelector.userInfo?.user?.business_type == 1){
      //     if(!promoCode) {
      //         toast.error("Please enter promo code");
      //         return;
      //     }
      //     const params = {
      //         promo_code:promoCode
      //     }
      //     dispatch(getPaymentViaPromoCode({
      //         ...params, cb(res) {
      //             if (res.status == 200) {
      //                 dispatch(updateUserStepInside(res.data?.payload?.step ?? 6))
      //                 toast.success('Payment verified successfully')
      //                 navigate('/dashboard');

      //             }
      //         }
      //     }))

      // }
      // else{
      if (paymentCards.length == 0) {
        setModalDetail({
          show: true,
          flag: "paymentCard",
        });
      }
      if (!memberShip) {
        toast.error("Please select member ship");
        return;
      }
      let params = {
        step: 5,
        membership_type: parseInt(memberShip),
      };
      dispatch(
        signUpStep({
          ...params,
          cb(res) {
            if (save == "save&finish") {
              dispatch(logout({
                cb(res) {
                  if (res.status) {
                    localStorage.removeItem("authToken");
                    localStorage.removeItem("persist:root");
                    navigate("/");
                  }
                  else {
                  }
                }
              }))
            } else if (res.status) {
              setPageNumber(flag);
              nextPage("pagesix");
            }
          },
        })
      );
      // if (Businesstype == 1) {
      //   handleSubmit(e, "7");
      // }
      // }
    } else if (flag == 7) {
      // setLoading(true)
      // const currentUrl = window.location.href;
      // const baseUrl = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');

      // let params = {
      //     authentication_time: 15,
      //     amount: parseFloat(totalMembershipPriceInteger) + 253,
      //     description: "Test",
      //     returnUrl: currentUrl,
      //     cancelUrl: `${baseUrl}/paymentCancelled`,
      //     timeoutUrl: `${baseUrl}/paymentCancelled`,
      //     membership_id: parseInt(memberShip)
      // }

      // dispatch(getPaymentSession(({
      //     ...params, cb(res) {

      //         if (res.status == 200) {
      //             window.location.href = res.data?.payload?.url ?? currentUrl
      //         }
      //         else {
      //             toast.warning('Something went wrong')
      //         }
      //     }
      // })))

      // let params = {
      //     authentication_time: 15,
      //     amount: parseFloat(totalMembershipPriceInteger) +253,
      //     description: "Test",
      //     returnUrl: currentUrl,
      //     cancelUrl: `${baseUrl}/paymentCancelled`,
      //     timeoutUrl: `${baseUrl}/paymentCancelled`,
      //     membership_id : parseInt(memberShip)
      // }

      // dispatch(getPaymentSession(({
      //     ...params, cb(res) {
      //         if(res.status == 200){
      //             window.location.href = res.data?.payload?.url ?? currentUrl
      //         }
      //         else{
      //             toast.warning('Something went wrong')
      //         }
      //     }
      // })))
      if (Businesstype == 2) {
        const result = await createStripeSubscription();
        if (!result) {
          // toast.error("Something went Really Wrong");
          return;
        }
      }
      let params = {
        step: 6,
      };
      dispatch(
        signUpStep({
          ...params,
          cb(res) {
            if (save == "save&finish") {
              dispatch(logout({
                cb(res) {
                  if (res.status) {
                    alert("LastStep")
                    localStorage.removeItem("authToken");
                    localStorage.removeItem("persist:root");
                    navigate("/");
                  }
                  else {
                  }
                }
              }))
            } else if (res.status) {
              setPageNumber(flag);
              navigate("/dashboard");

            }
          },
        })
      );
    }
  };

  const industry = (e) => {
    dispatch(
      getAllIndustryGroups({
        cb(res) {
          if (res.status) {
            setIndustryGroup(res?.data?.payload);
          } else {
          }
        },
      })
    );
  };

  // onchange input
  const handleChange = (id, flag) => {
    if (flag == "industry") {
      setIndustryGroupId(id);
      subIndustry(id);
    } else {
      setIndustrySubGroupId(id);
    }
  };

  const subIndustry = (id) => {
    if (id) {
      setIndustryGroupId(id);
      let params = {
        id: id,
      };
      dispatch(
        getAllIndustrySubGroups({
          ...params,
          cb(res) {
            if (res.status) {
              setSubIndustryGroup(res?.data?.payload);
              // setIndustrySubGroupId("");
            } else {

            }
          },
        })
      );
    }
  };

  const businessOwnership = (id) => {
    setOwnershipTypeId(id);
    dispatch(
      businessOwnershipType({
        cb(res) {
          if (res.status) {
            setOwnershipType(res?.data?.payload);
          } else {
          }
        },
      })
    );
  };

  const companyListAll = (id) => {
    setCompanyTypeId(id);
    dispatch(
      companyListType({
        cb(res) {
          if (res.status) {
            setCompanyType(res?.data?.payload?.data);
          } else {
          }
        },
      })
    );
  };

  //for add drivers Supplier Description........
  const supplierDescription = (id) => {

    setDriversSupplierDescriptionId(id)
    if (id == 18) {
      setCompanyDescriptionOtherId(id);
      setCompanyDescriptionId("");
      setCompanyOtherDescription("");
    } else {
      setCompanyDescriptionId(id);
      setCompanyDescriptionOtherId("");
      setCompanyOtherDescription("");
    }

    dispatch(
      diverseSupplierDescription({
        cb(res) {
          if (res.status) {
            setCompanyDescription(res?.data?.payload);
          }
        },
      })
    );
  };

  // driver supplier description
  const handleChanges = (data) => {
    // setSelected(data);
    let arr = [];
    for (let i = 0; i < data.length; i++) {
      arr.push(data[i].id);
    }
    setDriversSupplierDescriptionId(arr);
  };

  //Update Memnber ship amount
  const memberShipAmount = (id) => {
    let params = {
      session_id: id,
      amount: 250,
    };
    dispatch(
      updateMemberShipAmount({
        ...params,
        cb(res) {
          if (res.status) {
          } else {
          }
        },
      })
    );
  };

  // Existing certification
  const ExistingCertification = () => {
    dispatch(
      existingCertification({
        cb(res) {
          if (res.status) {
            setExistingCertificationData(res.data.payload);
          } else {
          }
        },
      })
    );
  };

  // Existing Certifications
  const handleChangesCertifications = (data) => {
    // setSelectedCertifications(data);
    let arr = [];
    for (let i = 0; i < data.length; i++) {
      arr.push(data[i].id);
    }
    setExistingCertificationsId(arr);
  };
  // handle change certification
  const handleChangeCertification = (id) => {
    setExistingCertificationId(id);
  };

  //yearly membership
  const handleMemberShipCard = () => {
    setTotalMembershipPrice(
      memberShipPackage === "Bronze" && Businesstype == 1
        ? 30000
        : memberShipPackage === "Bronze" && Businesstype == 2
          ? 2500
          : memberShipPackage === "Gold" && Businesstype == 1
            ? 40000
            : memberShipPackage === "Gold" && Businesstype == 2
              ? 5000
              : memberShipPackage === "Platinum" && Businesstype == 1
                ? 50000
                : memberShipPackage === "Platinum" && Businesstype == 2
                  ? 7500
                  : ""
    );
  };

  const companyProfileInfo = () => {
    dispatch(
      getCompanyProfileInfo({
        cb(res) {
          if (res?.data?.payload?.userDetails?.user_company_information !== null) {
            setUserDeatil(res?.data?.payload?.userDetails?.user_company_information)
            setCompanyName(res?.data?.payload?.userDetails?.user_company_information?.company_name)
            setCompanyWebsite(res?.data?.payload?.userDetails?.user_company_information?.company_website)

            setSelectedDiverseStatus(res?.data?.payload?.userDetails?.user_company_information?.company_diverse_supplier_description)
            setOwnershipTypeId(res?.data?.payload?.userDetails?.user_company_information?.business_ownership_type)
            setPhotoInfo(({
              name: res?.data?.payload?.userDetails?.user_company_information?.company_logo_name,
              size: res.data.payload?.userDetails?.user_company_information?.company_logo_size
            }));
            setPhotoUpload(res?.data?.payload?.userDetails?.user_company_information?.company_logo);
            setRoleId(res?.data?.payload?.userDetails?.user_role?.role_id)
            const selectedValuesDiverseStatus = res?.data?.payload?.userDetails?.user_company_information?.company_diverse_supplier_description?.map((data) => { return data?.diverse_supplier_description })
            setDriversSupplierDescriptionId(selectedValuesDiverseStatus)
            setIndustryGroupId(res?.data?.payload?.userDetails?.user_company_information?.industries_group_details?.id)
            setIndustrySubGroupId(res?.data?.payload?.userDetails?.user_company_information?.industries_sub_group_details?.id)
            setCompanyKeyWords(res?.data?.payload?.userDetails?.user_company_information?.key_words)
            setCompanyServicesDescription(res?.data?.payload?.userDetails?.user_company_information?.company_service_description)
            setSelectedCertifications(res?.data?.payload?.userDetails?.user_company_information?.company_existing_certificate_details)
          }
          else {
            setUserDeatil(res?.data?.payload?.userDetails?.user_selected_company_information?.user_company_information)
          }
        }
      }))
  }
  useEffect(() => {
    companyProfileInfo()
    industry();
    businessOwnership();
    companyListAll();
    supplierDescription();
    ExistingCertification();
    createCustomerAction();
    document.title = "Sign-up-steps";
    setMembership_id(stripeInfo?.planId);
    setCurrentPlan(stripeInfo?.plan?.name)
    setTotalMembershipPrice(stripeInfo?.plan?.one_year_cost)
    checkPromotionCode() // Comment This to remove promoCode
  }, []);

  useEffect(() => {
    companyProfileInfo();
  }, [page]);

  function formatNumberToDollars(number) {
    // Convert the number to a string
    const numberString = number.toString();
    // Add commas to the number for thousands
    const formattedNumber = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // Add a "$" symbol in front of the formatted number
    return "$" + formattedNumber;
  }

  //for get payment plans.......
  const getPaymentPlans = () => {
    let params = {
      type: 1,
    };
    dispatch(
      getPaymentPlanSmallBusiness({
        ...params,
        cb(res) {
          if (res.status) {
            setSmallBusiness(res?.data?.payload);
          } else {
          }
        },
      })
    );
  };

  useEffect(() => {
    getPaymentPlans();
    window.scrollTo(0, 0);
    document.title = "Member-Ship";
  }, []);



  return (
    <>
      <div className="registration">
        <div className="container">
          <div className="row">
            <div className="col-md-7" id="leftArea">
              <div className="formContainer mb-3 mb-md-0">
                <div className="mb-5">
                  <div className="stepBox">
                    {pageNumber >= 3 ? (
                      <div className="text-end">
                        <button className="" onClick={() => backPageNumber()}>
                          <i className="las la-arrow-left backIcon"></i>
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                    <button className="stepBtn">Step {pageNumber}</button>
                  </div>
                  <div className="stepProgress mt-4 mb-4">
                    <MultiStepProgressBar
                      onPageNumberClick={(e) => {
                        return false;
                      }}
                      page={page}
                    />
                    {
                      {
                        pageone: (
                          <div onButtonClick={nextPage} className="mt-4">
                            <h3 className="headTxtBlue">
                              <b>Company Information</b>
                            </h3>
                            <p className="company_info mt-1 mb-4">
                              Tell us about your company.
                            </p>
                            <form
                              onSubmit={(e) => handleSubmit(e, "3")}
                              className="registrationForm row mt-3"
                            >
                              <div
                                className={`${companyTypeId ? "disable_" : ""}`}
                              >
                                <label className="company_info mb-2">
                                  Add{" "}
                                </label>
                                <div className="form-group col-md-12 mb-3">
                                  <input
                                    name="companyName"
                                    type="text"
                                    className="customFormControl"
                                    value={companyName}
                                    placeholder="Company Name"
                                    onChange={(e) =>
                                      setCompanyName(e.target.value)
                                    }
                                    disabled={companyTypeId}
                                  />
                                </div>
                                <div className="form-group col-md-12 mb-3">
                                  <input
                                    name="companyWebsite"
                                    type="text"
                                    className="customFormControl"
                                    value={companyWebsite}
                                    placeholder="Company Website"
                                    onChange={(e) =>
                                      setCompanyWebsite(e.target.value)
                                    }
                                    disabled={companyTypeId}
                                  />
                                </div>
                                {/* <div className='form-group col-md-12 mb-3'>
                                                                <textarea name="email" type='text' className='customFormControl' placeholder='Diverse Supplier Description' value={companyDescription} onChange={e => setCompanyDescription(e.target.value)} disabled={companyTypeId} />
                                                            </div> */}
                                {/* <div className='form-group col-md-12 mb-3'>
                                                                <select className="cateSelectbox" required="" disabled={companyTypeId} value={companyDescription?.id} onChange={(e) => { supplierDescription(e.target.value) }}>
                                                                    <option value="">Select Diverse Supplier Description</option>
                                                                    {companyDescription?.length > 0 ?
                                                                        <>
                                                                            {companyDescription?.map((data) => {
                                                                                return <option value={data?.id}>{data?.name}</option>
                                                                            })}

                                                                        </>
                                                                        : ""}
                                                                </select>
                                                            </div> */}
                                <div className="tagsType mt-3 mb-3 col-md-12">
                                  <label
                                    className="headingSmall_ mb-2"
                                    htmlFor=""
                                  >
                                    Diverse Status
                                  </label>



                                  {companyDescription && (
                                    <Multiselect
                                      options={companyDescription} // Options to display in the dropdown
                                      selectedValues={selected} // Preselected value to persist in dropdown
                                      onSelect={handleChanges} // Function will trigger on select event
                                      onRemove={handleChanges} // Function will trigger on remove event
                                      displayValue="name" // Property name to display in the dropdown options
                                    />
                                  )}
                                </div>
                                {driversSupplierDescriptionId?.includes(16) ? (
                                  <div className="form-group col-md-12 mb-3">
                                    <input
                                      name="companyDescription"
                                      type="text"
                                      className="customFormControl"
                                      value={companyOtherDescription}
                                      placeholder="Other Description"
                                      disabled=""
                                      onChange={(e) =>
                                        setCompanyOtherDescription(
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}

                                <div className="form-group col-md-12 mb-3">
                                  <select
                                    className="cateSelectbox"
                                    required=""
                                    value={ownershipTypeId}
                                    onChange={(e) => {
                                      businessOwnership(e.target.value);
                                    }}
                                  >
                                    <option value="0">Types of Business</option>
                                    {ownershipType?.length > 0 ? (
                                      <>
                                        {ownershipType?.map((data) => {
                                          return (
                                            <option value={data?.id}>
                                              {data?.name}
                                            </option>
                                          );
                                        })}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </select>
                                </div>
                              </div>
                              <div className="form-group col-md-12 mb-3">
                                <select
                                  className="cateSelectbox"
                                  onChange={(e) => {
                                    setRoleId(e.target.value);
                                  }}
                                  value={roleId}
                                >
                                  <option value="" disabled>
                                    I am a ..
                                  </option>
                                  <option value="1">Buyer</option>
                                  <option value="2">Supplier</option>
                                </select>
                              </div>

                              <div className="col-md-12">
                                <div className="d-flex justify-content-end align-items-center">
                                  <p
                                    onClick={(e) =>
                                      handleSubmit(e, "3", "save&finish")
                                    }
                                    className="innerTxt me-3"
                                  >
                                    Save &{" "}
                                    <span className="linkTxt">
                                      <b>Finish Later</b>
                                    </span>
                                  </p>
                                  <button
                                    className="primaryBtn d-inline-block"
                                    type="submit"
                                    // value="Continue"
                                  >
                                    {authSelector?.signup_loader && (
                                      <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    Continue</button>
                                </div>
                              </div>
                            </form>
                          </div>
                        ),
                        pagetwo: (
                          <div onButtonClick={nextPage} className="mt-4">
                            <h3 className="headTxtBlue">
                              <b>Tell us about your company.</b>
                            </h3>
                            {/* <h6 className='innerSubtext my-3'>"It always seems impossible until it's done." Nelson Mandela</h6> */}

                            {/* <p className='company_info mt-1 mb-4'>Tell us about your company.</p> */}
                            <form
                              onSubmit={(e) => handleSubmit(e, "3")}
                              className="registrationForm row mt-3"
                            >
                              {/* <label className='company_info mb-2'>Select A Company List</label>
                                                        <div className='form-group col-md-12 mb-3'>
                                                            <select className="cateSelectbox" required="" value={companyType?.id} onChange={(e) => { companyListAll(e.target.value) }}>
                                                                <option value="">Select a Company</option>
                                                                {companyType?.length > 0 ?
                                                                    <>
                                                                        {companyType?.map((data) => {
                                                                            return <option value={data.id}>{data?.company_name}</option>
                                                                        })}

                                                                    </>
                                                                    : ""}
                                                            </select>
                                                        </div>
                                                        <div className='text-center mb-3 company_option'>OR</div> */}
                              <div
                                className={`${companyTypeId ? "disable_" : ""}`}
                              >
                                <div className="form-group col-md-12 mb-3">
                                  <input
                                    name="companyName"
                                    type="text"
                                    className="customFormControl"
                                    disabled={companyTypeId}
                                    value={companyName}
                                    placeholder="Company Name"
                                    onChange={(e) =>
                                      setCompanyName(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="form-group col-md-12 mb-3">
                                  <input
                                    name="companyWebsite"
                                    type="text"
                                    className="customFormControl"
                                    disabled={companyTypeId}
                                    value={companyWebsite}
                                    placeholder="Company Website"
                                    onChange={(e) =>
                                      setCompanyWebsite(e.target.value)
                                    }
                                  />
                                </div>
                                {/* <div className='form-group col-md-12 mb-3'>
                                                                <textarea disabled={companyTypeId} name="=diversesupplierdescription" type='text' className='customFormControl' placeholder='Diverse Supplier Description' value={companyDescription} onChange={e => setCompanyDescription(e.target.value)} />
                                                            </div> */}
                                {/* <div className='form-group col-md-12 mb-3'>
                                                                <select className="cateSelectbox" required="" disabled={companyTypeId} value={companyDescription?.id} onChange={(e) => { supplierDescription(e.target.value) }}>
                                                                    <option value="">Diverse Supplier Description</option>
                                                                    {companyDescription?.length > 0 ?
                                                                        <>
                                                                            {companyDescription?.map((data) => {
                                                                                return <option value={data?.id}>{data.name}</option>
                                                                            })}

                                                                        </>
                                                                        : ""}
                                                                </select>
                                                            </div> */}
                                <div className="tagsType mt-3 mb-3 col-md-12">
                                  <label
                                    className="headingSmall_ mb-2"
                                    htmlFor=""
                                  >
                                    Diverse Status
                                  </label>

                                  {companyDescription && (
                                    <Multiselect
                                      options={companyDescription} // Options to display in the dropdown
                                      selectedValues={selected} // Preselected value to persist in dropdown
                                      onSelect={handleChanges} // Function will trigger on select event
                                      onRemove={handleChanges} // Function will trigger on remove event
                                      displayValue="name" // Property name to display in the dropdown options
                                    />
                                  )}
                                </div>
                                {driversSupplierDescriptionId?.includes(16) ? (
                                  <div className="form-group col-md-12 mb-3">
                                    <input
                                      name="companyDescription"
                                      type="text"
                                      className="customFormControl"
                                      value={companyOtherDescription}
                                      placeholder="Other Description"
                                      disabled=""
                                      onChange={(e) =>
                                        setCompanyOtherDescription(
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                                <div className="form-group col-md-12 mb-3">
                                  <select
                                    className="cateSelectbox"
                                    required=""
                                    disabled={companyTypeId}
                                    value={ownershipTypeId}
                                    onChange={(e) => {
                                      businessOwnership(e.target.value);
                                    }}
                                  >
                                    <option value="">
                                      Business Ownership type
                                    </option>
                                    {ownershipType?.length > 0 ? (
                                      <>
                                        {ownershipType?.map((data) => {
                                          return (
                                            <option value={data?.id}>
                                              {data.name}
                                            </option>
                                          );
                                        })}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </select>
                                </div>
                                <div className="form-group col-md-12 mb-3">
                                  <select
                                    className="cateSelectbox"
                                    onChange={(e) => {
                                      setRoleId(e.target.value);
                                    }}
                                    value={roleId}
                                  >
                                    <option value="" disabled>
                                      I am a ..
                                    </option>
                                    <option value="1">Buyer</option>
                                    <option value="2">Supplier</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="d-flex justify-content-end align-items-center">
                                  <p
                                    onClick={(e) =>
                                      handleSubmit(e, "3", "save&finish")
                                    }
                                    className="innerTxt me-3"
                                  >
                                    Save &{" "}
                                    <span className="linkTxt">
                                      <b>Finish Later</b>
                                    </span>
                                  </p>
                                  <button
                                    className="primaryBtn d-inline-block"
                                    type="submit"
                                    // value="Continue"
                                  >
                                    {authSelector?.signup_loader && (
                                      <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    Continue</button>
                                </div>
                              </div>
                            </form>
                          </div>
                        ),
                        pagethree: (
                          <div onButtonClick={nextPage} className="mt-4">
                            {/* <h3 className='headTxtBlue'><b>Company </b></h3> */}
                            {/* <h6 className='innerSubtext my-3'>"I never dreamed about success. I worked for it." - Estée Lauder, Founder of Estée Lauder Companies</h6> */}
                            <p className="company_info mt-1 mb-4">
                              Upload Company Picture/Logo
                            </p>
                            <form
                              onSubmit={(e) => handleSubmit(e, "4")}
                              className="registrationForm row mt-3"
                            >
                              <div className="row">
                                <div className="col-md-12">
                                  {photo?.length > 0 ? (
                                    ""
                                  ) : (
                                    <div className="uploadImgebox">
                                      <div
                                        {...getRootProps({
                                          className: "dropzone",
                                        })}
                                      >
                                        <input {...getInputProps()} />
                                        <p className="uploadbtnn">Upload</p>
                                        <p className="uploadText mt-2">
                                          PNG, JPG,JPEG,GIF file upload
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                  {photo?.length ? (
                                    <aside>
                                      <h4 className="headingSmall_">
                                        {" "}
                                        Uploaded Files
                                      </h4>
                                      <ul className="uploadProfile_">
                                        <img
                                          className="w3-round"
                                          src={photo}
                                        ></img>
                                        <div className="profileDetail_">
                                          <h5 className="profileboldHead">
                                            {photoInfo.name}
                                          </h5>
                                          <p className="profileboldgraph">
                                            {(
                                              photoInfo?.size /
                                              (1024 * 1024)
                                            ).toFixed(2)}{" "}
                                            MB
                                          </p>
                                        </div>
                                        <i
                                          className="fa fa-trash text-red"
                                          onClick={removeFile(photo)}
                                        ></i>
                                      </ul>
                                    </aside>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="d-flex justify-content-end align-items-center">
                                  <p
                                    onClick={(e) =>
                                      handleSubmit(e, "4", "save&finish")
                                    }
                                    className="innerTxt me-3"
                                  >
                                    Save &{" "}
                                    <span className="linkTxt">
                                      <b>Finish Later</b>
                                    </span>
                                  </p>
                                  <button
                                    className="primaryBtn d-inline-block"
                                    type="submit"
                                    // value="Continue"
                                  >
                                    {authSelector?.signup_loader && (
                                      <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    Continue</button>
                                </div>
                              </div>
                            </form>
                          </div>
                        ),
                        pagefour: (
                          <div className="mt-4">
                            <h3 className="headTxtBlue">
                              <b>Tell us about your company.</b>
                            </h3>
                            {/* <h6 className='innerSubtext my-3'>"If you want to go fast, go alone. If you want to go far, go together." - African Proverb</h6> */}
                            {/* <p className='innerSubtext mt-1 mb-4'>Tell us about your company.</p> */}
                            <form
                              onSubmit={(e) => handleSubmit(e, "5")}
                              className="registrationForm row mt-3"
                            >
                              <div className="form-group col-md-6 mb-3">
                                <select
                                  className="cateSelectbox"
                                  required=""
                                  value={industryGroupId}
                                  onChange={(e) => {
                                    handleChange(e.target.value, "industry");
                                  }}
                                >
                                  <option value="">
                                    Select Industry Groups
                                  </option>
                                  {industryGroup?.length > 0 ? (
                                    <>
                                      {industryGroup.map((data) => {
                                        return (
                                          <option value={data.id}>
                                            {data.name}
                                          </option>
                                        );
                                      })}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </select>
                              </div>
                              <div className="form-group col-md-6 mb-3">
                                <select
                                  className="cateSelectbox"
                                  required=""
                                  value={industrySubGroupId}
                                  onChange={(e) => {
                                    handleChange(e.target.value, "subindustry");
                                  }}
                                // onChange={(e) => { subIndustry(e.target.value) }}
                                >
                                  <option value="">
                                    Select Industry Sub Groups
                                  </option>
                                  {subIndustryGroup?.length > 0 ? (
                                    <>
                                      {subIndustryGroup.map((data) => {
                                        return (
                                          <option value={data.id}>
                                            {data.name}
                                          </option>
                                        );
                                      })}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </select>
                              </div>
                              {/* <div className='form-group col-md-12 mb-3'>
                                                            <input name="existingcertifications" type='text' className='customFormControl' placeholder='Existing Certifications' value={existingCertifications} onChange={e => setExistingCertifications(e.target.value)} ></input>
                                                        </div> */}
                              <div className="location_ mt-3 mb-2 ">
                                {/* <h5 className='postopportunity_label mb-2'>Existing Certification</h5>
                                                            <select className="cateSelectbox" required="" onChange={(e) => { handleChangeCertification(e.target.value) }} >
                                                                <option value="">Select Option</option>
                                                                {existingCertificationData?.length > 0 ?
                                                                    <>
                                                                        {existingCertificationData.map((option, id) => (
                                                                            <option value={option?.id}>{option?.name}</option>
                                                                        ))}
                                                                    </>
                                                                    : ""
                                                                }
                                                            </select> */}
                                {/* <div className='form-group col-md-5 mb-3'> */}
                                <label className="postopportunity_label">
                                  Existing Certifications
                                </label>
                                {existingCertificationData && (
                                  <Multiselect
                                    options={existingCertificationData} // Options to display in the dropdown
                                    selectedValues={certificationSelected} // Preselected value to persist in dropdown
                                    onSelect={handleChangesCertifications} // Function will trigger on select event
                                    onRemove={handleChangesCertifications} // Function will trigger on remove event
                                    displayValue="name" // Property name to display in the dropdown options
                                  />
                                )}
                                {/* </div> */}
                              </div>
                              {existingCertificationsId?.includes(11) ? (
                                <div className="form-group col-md-12 mb-3">
                                  <input
                                    name="companyDescription"
                                    type="text"
                                    className="customFormControl"
                                    value={certificationOtherDescription}
                                    placeholder="Other Description"
                                    disabled=""
                                    onChange={(e) =>
                                      setCertificationOtherDescription(
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="form-group col-md-12 mb-3">
                                <input
                                  name="keywords"
                                  type="text"
                                  className="customFormControl"
                                  placeholder="Key Words"
                                  value={companyKeyWords}
                                  onChange={(e) =>
                                    setCompanyKeyWords(e.target.value)
                                  }
                                />
                              </div>
                              <div className="form-group col-md-12 mb-3">
                                <textarea
                                  name="companyservicesdescription"
                                  type="text"
                                  className="customFormControl"
                                  placeholder="About"
                                  value={companyServicesDescription}
                                  onChange={(e) =>
                                    setCompanyServicesDescription(
                                      e.target.value
                                    )
                                  }
                                ></textarea>
                              </div>
                              <div className="col-md-12">
                                <div className="d-flex justify-content-end align-items-center">
                                  <p
                                    onClick={(e) =>
                                      handleSubmit(e, "5", "save&finish")
                                    }
                                    className="innerTxt me-3"
                                  >
                                    Save &{" "}
                                    <span className="linkTxt">
                                      <b>Finish Later</b>
                                    </span>
                                  </p>
                                  {/* <Link  className='primaryBtn d-inline-block'>Continue</Link> */}
                                  <button
                                    className="primaryBtn d-inline-block"
                                    type="submit"
                                    // value="Continue"
                                  >
                                    {authSelector?.signup_loader && (
                                      <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    Continue</button>
                                </div>
                              </div>
                            </form>
                          </div>
                        ),
                        pagefive: (
                          <div className="mt-4">
                            <h3 className="headTxtBlue">
                              <b>Membership</b>
                            </h3>
                            <p className="innerSubtext mt-1 mb-4">
                              Select which membership level you would like.
                            </p>
                            <form
                              onSubmit={(e) => handleSubmit(e, "6")}
                              className="registrationForm row mt-3"
                            >
                              <div className="membershipRegistration_">
                                <div className="checkmark_ memberCheck_"></div>

                                {newMembershipPlans.length > 0 ? (
                                  <>
                                    {newMembershipPlans.map((plan, index) => {
                                      return (
                                        <div
                                          className="checkmark_ memberCheck_"
                                          key={index}
                                        >
                                          <input
                                            value={plan.id}
                                            onChange={(e) =>
                                              handleMembership(
                                                e,
                                                plan.name,
                                                plan
                                              )
                                            }
                                            checked={memberShip == plan.id}
                                            type="radio"
                                            id={`member${plan.id}`}
                                            name="radio-group"
                                            className="businessType_"
                                          />
                                          <label
                                            for={`member${plan.id}`}
                                            className="labelTxt_"
                                          >
                                            <div className="growMainbox_">
                                              <div className="growbusinessLeft_">
                                                <div className="growbusinessAbout_">
                                                  <h3 className="memberblueHead_">
                                                    {plan.name ?? ""}
                                                    <div className="infoiconText">
                                                      <img
                                                        className="info"
                                                        src={Images.information}
                                                      />
                                                      <span className="showinfoText">
                                                        {smallBusiness &&
                                                          smallBusiness?.map(
                                                            (data, ind) => (
                                                              <>
                                                                {index == 0 &&
                                                                  data?.name ===
                                                                  "Bronze" ? (
                                                                  <>
                                                                    <ul>
                                                                      <p className="m-0">
                                                                        {
                                                                          data?.name
                                                                        }{" "}
                                                                        Features:
                                                                      </p>
                                                                      {data?.extra_data?.map(
                                                                        (
                                                                          item,
                                                                          id
                                                                        ) => (
                                                                          <li>
                                                                            {" "}
                                                                            <span className="listHead_">
                                                                              {
                                                                                item?.info
                                                                              }
                                                                            </span>
                                                                          </li>
                                                                        )
                                                                      )}
                                                                    </ul>
                                                                  </>
                                                                ) : index ==
                                                                  1 &&
                                                                  data?.name ===
                                                                  "Gold" ? (
                                                                  <>
                                                                    <ul>
                                                                      <p className="m-0">
                                                                        {
                                                                          data?.name
                                                                        }{" "}
                                                                        Features:
                                                                      </p>
                                                                      {data?.extra_data?.map(
                                                                        (
                                                                          item,
                                                                          id
                                                                        ) => (
                                                                          <li>
                                                                            {" "}
                                                                            <span className="listHead_">
                                                                              {
                                                                                item?.info
                                                                              }
                                                                            </span>
                                                                          </li>
                                                                        )
                                                                      )}
                                                                    </ul>
                                                                  </>
                                                                ) : index ==
                                                                  2 &&
                                                                  data?.name ===
                                                                  "Platinum" ? (
                                                                  <>
                                                                    <ul>
                                                                      <p className="m-0">
                                                                        {
                                                                          data?.name
                                                                        }{" "}
                                                                        Features:
                                                                      </p>
                                                                      {data?.extra_data?.map(
                                                                        (
                                                                          item,
                                                                          id
                                                                        ) => (
                                                                          <li>
                                                                            {" "}
                                                                            <span className="listHead_">
                                                                              {
                                                                                item?.info
                                                                              }
                                                                            </span>
                                                                          </li>
                                                                        )
                                                                      )}
                                                                    </ul>
                                                                  </>
                                                                ) : (
                                                                  ""
                                                                )}
                                                              </>
                                                            )
                                                          )}
                                                      </span>
                                                    </div>
                                                  </h3>
                                                  <p className="memberblueSub_">
                                                    {plan.description ?? ""}{" "}
                                                  </p>
                                                </div>
                                              </div>
                                              <div className="growbusinessRight_">
                                                <p className="yearBadge text-end">
                                                  $ {plan.one_year_cost}
                                                </p>
                                                {/* <p className='yearBadge text-end'>1 Year {formatNumberToDollars(plan.one_year_cost)}</p> */}
                                              </div>
                                            </div>
                                          </label>
                                        </div>
                                      );
                                    })}
                                  </>
                                ) : null}

                                <div className="col-md-12 mt-4">
                                  <div className="d-flex justify-content-end align-items-center">
                                    <p
                                      onClick={(e) =>
                                        handleSubmit(e, "6", "save&finish")
                                      }
                                      className="innerTxt me-3"
                                    >
                                      Save &{" "}
                                      <span className="linkTxt">
                                        <b>Finish Later</b>
                                      </span>
                                    </p>
                                    <button
                                    className="primaryBtn d-inline-block"
                                    type="submit"
                                    // value="Continue"
                                  >
                                    {authSelector?.signup_loader && (
                                      <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    Continue</button>
                                  </div>
                                </div>
                              </div>
                            </form>
                            {/* </>
                                                    } */}
                          </div>
                        ),
                        pagesix: (
                          <div className="mt-4">
                            <h3 className="headTxtBlue">
                              <b>Billing</b>
                            </h3>
                            {/* <h6 className='innerSubtext my-3'>"It is not because things are difficult that we do not dare; it is because we do not dare that they are difficult." Seneca the Elder </h6> */}
                            <p className="innerSubtext mt-1 mb-4">
                              Verify your payment and process further
                            </p>

                            <form
                              onSubmit={(e) => handleSubmit(e, "7")}
                              className="registrationForm row mt-3"
                            >
                              <div className="billingBox">
                                <div className="row">
                                  <div className="col-md-12">
                                    <div
                                      className={`penCard mb-4 ${activeTab === "yearly" ? "active" : ""
                                        }`}
                                    >
                                      <input
                                        checked
                                        type="radio"
                                        id="test2"
                                        name="radio-group"
                                        className="customRadio"
                                      />
                                      <label for="test2">
                                        <p className="headingTitleSmall_ mt-2">
                                          Quarterly
                                        </p><p className="headingTitleSmall_ mt-2">
                                          {currentPlan}
                                        </p>
                                        <div className="amountArea">
                                          <p className="amountvalue mb-1">
                                            {/* {totalMembershipPrice} / 1 year */}
                                            $ {totalMembershipPrice}
                                          </p>
                                          {/* <Link to='' className='amountMsg'>Cancel account anytime.</Link> */}
                                        </div>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="companyDetails mt-3">
                                  <ul className="companyDetailsMenus">
                                    <li className="billingItems mt-3">
                                      <span className="billingItemslinks">
                                        Sub Total
                                      </span>
                                      {/* <span className="billingItemslinks"><b>{totalMembershipPrice}</b></span> */}
                                      <span className="billingItemslinks">
                                        <b>${totalMembershipPrice}</b>
                                      </span>
                                    </li>
                                    <li className={`${showCoupon ? "" : "d-none"} billingItems my-3`}>
                                      <span className="billingItemslinks">
                                        Discount
                                      </span>
                                      {/* <span className="billingItemslinks"><b>{formatNumberToDollars(totalMembershipPriceInteger + 253)}</b></span> */}
                                      <span className="billingItemslinks">
                                        <b>${discount}</b>
                                      </span>
                                    </li>
                                    <li className="billingItems mt-3">
                                      <span className="billingItemslinks">
                                        Tax
                                      </span>
                                      {/* <span className="billingItemslinks"><b>$253</b></span> */}
                                      <span className="billingItemslinks">
                                        <b>$ 0</b>
                                      </span>
                                    </li>
                                    <hr className="membertopboeder"></hr>
                                    <li className="billingItems my-3">
                                      <span className="billingItemslinks">
                                        Total
                                      </span>
                                      {/* <span className="billingItemslinks"><b>{formatNumberToDollars(totalMembershipPriceInteger + 253)}</b></span> */}
                                      <span className="billingItemslinks">
                                        <b>${totalMembershipPrice - discount}</b>
                                      </span>
                                    </li>
                                    <li className={`${showCouponInput ? "" : "d-none"}`}>
                                      <form className="promoCodeForm" onSubmit={(event) => { event.preventDefault() }}>
                                        <div className="col-12">
                                          <label for="promocode ">Promo Code</label>
                                          <div className="position-relative mt-2">
                                            <input type="search" placeholder='Enter discount code' id="promocode" className='promoSearch text-uppercase' onChange={(e) => { setPromoCode(e.target.value.toUpperCase()) }} value={promoCode} />
                                            <button className='promoBtn' type="button" onClick={checkPromotionCode}>Apply</button>
                                          </div>
                                        </div>
                                      </form>
                                    </li>
                                    <li className={`${showCoupon ? "" : "d-none"}`}>
                                      <p className="promocode mt-4">Activation / Promotional Code</p>
                                      <div class="activationPromo">
                                        <p class="enterPriceSub mb-0">{promoCode}</p>
                                        <img src={Images.roundCrossIcon} onClick={() => {
                                          setPromoCodeChecked(false)
                                          setDiscount(0)
                                          setDiscountPercentage(0)
                                          setShowCoupon(false)
                                          setShowCouponInput(true)
                                          setPromoCode("")
                                          setCouponId("")
                                        }} className="cursor" />
                                      </div>
                                    </li>
                                  </ul>
                                  <Cards
                                    refreshCards={createCustomerAction}
                                    setSelectedCard={setSelectedCard}
                                    selectedCard={selectedCard}
                                    customerId={customerId}
                                    setCustomerId={setCustomerId}
                                    setModalDetail={setModalDetail}
                                    modalDetail={modalDetail}
                                  />
                                </div>

                                <div className="col-md-12 mt-4">
                                  <div className="d-flex justify-content-end align-items-center">
                                    {loading ? (
                                      <>
                                        <div
                                          className="spinner-border text-success"
                                          role="status"
                                        >
                                          <span className="sr-only">
                                            Loading...
                                          </span>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        {selectedCard == "" ? (
                                          <></>
                                        ) : (
                                          <button
                                            className="primaryBtn"
                                            type="submit"
                                            disabled={
                                              cardLoading || stripeInfo.loading
                                            }
                                          // value="Process Payment"
                                          >
                                            {cardLoading || stripeInfo.loading ? (
                                              <span className="spinner-border spinner-border-sm"></span>
                                            ) : (
                                              <></>
                                            )}{" "}
                                            Complete Payment
                                          </button>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        ),
                      }[page]
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5" id="rightArea">
              {pageNumber == 2 ? (
                <div className="rightContentArea">
                  <p>"It always seems impossible until it's done".</p>
                  <b>Nelson Mandela</b>
                  {/* <p>co-founder, chairman and CEO of Apple Inc.</p> */}
                </div>
              ) : pageNumber == 3 ? (
                <div className="rightContentArea stepBoxMain">
                  <p>"I never dreamed about success. I worked for it." -</p>
                  <b>Estée Lauder, Founder of Estée Lauder Companies</b>
                  {/* <p>co-founder, chairman and CEO of Apple Inc.</p> */}
                </div>
              ) : pageNumber == 4 ? (
                <div className="rightContentArea stepBoxFourMain">
                  <p>
                    "If you want to go fast, go alone. If you want to go far, go
                    together." -
                  </p>
                  <b>African Proverb</b>
                  {/* <p>co-founder, chairman and CEO of Apple Inc.</p> */}
                </div>
              ) : pageNumber == 5 ? (
                <div className="rightContentArea stepBoxfiveMain">
                  <p>
                    "Never allow a person to tell you no who doesn’t have the
                    power to say yes."
                  </p>
                  <b>Eleanor Roosevelt</b>
                  {/* <p>co-founder, chairman and CEO of Apple Inc.</p> */}
                </div>
              ) : pageNumber == 6 ? (
                <div className="rightContentArea stepBoxsixMain">
                  <p>
                    "It is not because things are difficult that we do not dare;
                    it is because we do not dare that they are difficult."
                  </p>
                  <b>Seneca the Elder</b>
                  {/* <p>co-founder, chairman and CEO of Apple Inc.</p> */}
                </div>
              ) : pageNumber == 1 ? (
                <div className="rightContentArea">
                  <p>"It always seems impossible until it's done".</p>
                  <b>Nelson Mandela</b>
                  {/* <p>co-founder, chairman and CEO of Apple Inc.</p> */}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={modalDetail.flag === "paymentCard" ? "SavedSearches" : ""}
        ids={modalDetail.flag === "paymentCard" ? "paymentCard" : null}
        child={
          <AddPaymentCard
            close={() => handleOnCloseModal()}
            updateCustomer={() => createCustomerAction()}
          />
        }
        header={
          <>
            <h1>Add a new Card</h1>
          </>
        }
        onCloseModal={() => handleOnCloseModal()}
      /> */}
    </>
  );
};

export default Registration;
