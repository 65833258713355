import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { logout } from '../../../redux/slices/auth';
import * as Images from "../../../utilities/images";
import EditProfile from '../editProfile';
import Notifications from '../notification';
import PrivacySettings from '../privacySettings';
import Subscription from '../subscription';
import EditUserDetail from '../editUserDetail';
import Support from '../support';
import Editprofile from '../editProfile/editprofile';
import ManageUser from '../manageUser/manageUser';
import { setDiverseStatusEmpty } from '../../../redux/slices/certification';
import { useAuthSelector } from '../../../redux/selector/auth';
import SettingUpdate from '../settingsUpdate';
import CustomModal from '../../components/common/shared/CustomModal';

const Setting = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const authSelector = useAuthSelector();
    const checkAccount = authSelector?.userInfo?.user?.is_deactivate

    const queryParams = new URLSearchParams(window.location.search);
    const tab = location?.state?.activeTab;

    const active = queryParams.get('active')

    const [activeTab, setActiveTab] = useState(tab != "" ? tab : "privacy");

    const [hideManageUser, setHideManageUser] = useState(false)
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });
    const chooseBusinessType = authSelector?.userStep?.user?.business_type ?? authSelector?.userStep?.business_type;

    const choosePlan = authSelector?.userStep?.user?.membership_details?.name ?? authSelector?.userStep?.membership_details?.name; 
 
 
    //closeModal
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random())

    }; 


    const emptyDiverseCertificates = () => {
        let params = {}
        dispatch(setDiverseStatusEmpty({
            ...params, cb(res) {

            }
        }))
    }

    //user logout
    const userLogout = () => {
        dispatch(logout({
            cb(res) {
                if (res.status) {
                    navigate("/")
                    emptyDiverseCertificates()
                }
                else {
                }
            }
        }))
    }

    // this code use for select url according to button tab
    const handleButtonClick = (buttonName) => {
        // Construct the new URL without causing a full page reload

        if (checkAccount !== 1) {
            const currentUrl = window.location.href;
            const newUrl = `/settings/${buttonName}`;

            // Change the URL using pushState
            window.history.pushState({}, '', newUrl);
        }

    };


    // handle tab chnage
    const handleTabChange = (tabIndex) => {
        if (checkAccount != 1) {
            setActiveTab(tabIndex);
            // Save the active tab to localStorage
            localStorage.setItem("activeTab", tabIndex);
        }
    };

    useEffect(() => {
        if (authSelector?.userStep?.user?.user_company_information !== null) {
            setHideManageUser(false)
        }
        else if (authSelector?.userStep?.user?.user_company_information === null) {
            setHideManageUser(true)
        }
    }, [])

    useEffect(() => {
        // Retrieve the active tab from localStorage on component mount
        const savedActiveTab = localStorage.getItem("activeTab");
        setActiveTab(savedActiveTab === null ? "privacy" : savedActiveTab ? savedActiveTab : 'privacy');
    }, []);

    useEffect(() => {
        if (checkAccount === 1) {
            setModalDetail({
                show: true,
                title: "settingUpdate",
                flag: "settingUpdate"
            });
            setKey(Math.random())
        }
    }, [checkAccount === 1])


    return (
        <div className='settings membershipDetails'>
            <div className='row'>
                <div className='col-lg-4 col-md-12'>
                    <div className='containerBox'>
                        <h3 className='headTxtBlueSmall mb-4'><b>Settings</b></h3>
                        <ul className='settingMenus'>
                            <li
                                onClick={() => {
                                    handleTabChange("privacy")
                                    handleButtonClick('overview')
                                }}
                                className='settingItems'>

                                <Link to='' className={`${activeTab === 'privacy' ? 'settingLinks active' : ''}settingLinks`}>
                                    <img src={Images.privacy} alt='Privacy' />
                                    <span>Privacy & Settings</span>
                                </Link>
                            </li>
                            <hr className='topBorder' />
                            <li
                                onClick={() => {
                                    handleTabChange("account")
                                    handleButtonClick('edit-profile')
                                }}
                                className='settingItems'>
                                <Link to='' className={`${activeTab === 'account' ? 'settingLinks active' : ''}settingLinks`}>
                                    <img src={Images.accountUser} alt='Account' />
                                    <span>Edit Profile </span>
                                </Link>
                            </li>
                            {
                                hideManageUser === true ? " "
                                    :
                                    <>
                                        <hr className='topBorder' />
                                        <li
                                            onClick={() => {
                                                handleTabChange("subscription")
                                                handleButtonClick('manage-subscription')
                                            }}
                                            className='settingItems'>
                                            <Link to='' className={`${activeTab === 'subscription' ? 'settingLinks active' : ''}settingLinks`}>
                                                <img src={Images.subscription} alt='Subscription' />
                                                <span>Manage Membership</span>
                                            </Link>
                                        </li>
                                    </>
                            }
                            {
                                hideManageUser === true ? " "
                                    :
                                    <>
                                        <hr className='topBorder' />
                                        <li
                                            onClick={() => {
                                                handleTabChange("manageUser")
                                                handleButtonClick('manage-users')
                                            }}
                                            className='settingItems'>
                                            <Link to='' className={`${activeTab === 'manageUser' ? 'settingLinks active' : ''}settingLinks`}>
                                                <img src={Images.subscription} alt='Subscription' />
                                                <span>Manage Co-worker Accounts</span>
                                            </Link>
                                        </li>
                                    </>

                            }
                            <hr className='topBorder' />
                            <li
                                onClick={() => {
                                    handleTabChange("support")
                                    handleButtonClick('support')
                                }}
                                className='settingItems'>
                                <Link to='' className={`${activeTab === 'support' ? 'settingLinks active' : ''}settingLinks`}>
                                    <img src={Images.support} alt='Support' />
                                    <span>Support</span>
                                </Link>
                            </li>
                            <hr className='topBorder' />
                            <li onClick={() => { userLogout() }}
                                className='settingItems'>
                                <Link to='' className={`${activeTab === 'logout' ? 'settingLinks active' : ''}settingLinks`}>
                                    <img src={Images.logout} alt='Logout' />
                                    <span>Log Out</span>
                                </Link>
                            </li>
                            <hr className='topBorder' />
                        </ul>
                        <div className='advData mb-5'>
                            <h4 className='headingSmall_ mb-2'>Cookies Data</h4>
                            <p className='headingTitleSmall_'>OPPSWell collects cookies to deliver a better user experience. We collect cookies to analyze our website traffic and performance. Please review our <strong><em>Terms of Service</em></strong> for more information on the OPPSWell's Cookie Policy.</p>
                        </div>
                    </div>
                </div>
                <div className='col-lg-8 col-md-12'>
                    {activeTab == 'privacy' ?
                        <PrivacySettings />
                        // navigate("/overview")
                        : activeTab == "notifications" ?
                            <Notifications />
                            : activeTab == "subscription" ?
                                <Subscription />
                                : activeTab == "editProfile" ?
                                    <Editprofile />
                                    : activeTab == "account" ?
                                        <Editprofile />
                                        : activeTab == "manageUser" ?
                                            <ManageUser />
                                            : activeTab == "support" ?
                                                <Support /> : ""
                    }
                </div>
                <CustomModal
                    key={key}
                    show={modalDetail.show}
                    backdrop="static"
                    showCloseBtn={false}
                    isRightSideModal={false}
                    mediumWidth={false}
                    className={modalDetail.flag ? "settingUpdate" : ''}
                    ids={modalDetail.flag}
                    child={
                        modalDetail.flag === "settingUpdate" ? (
                            <SettingUpdate
                                close={() => handleOnCloseModal()} />
                        )
                            :
                            null
                    }
                    onCloseModal={() => handleOnCloseModal()}
                />
            </div>
        </div>
    )
}

export default Setting