import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { toast } from "react-toastify";
import { uploadAllDocuments } from "../../../redux/slices/documents";
import { useNavigate } from 'react-router-dom';
import { useAuthSelector } from '../../../redux/selector/auth';
import { getCompanyProfileInfo } from '../../../redux/slices/auth';


const UploadDocuments = (props) => {
  const authSelector = useAuthSelector();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [documentsInfo, setDocumentsInfo] = useState();
  const [documents, setDocuments] = useState("");
  const [allMemberShipPlanCount, setAllMemberShipPlanCount] = useState("");

  const chooseBusinessType = authSelector?.userStep?.user?.business_type ?? authSelector?.userStep?.business_type;

  // const chooserole = authSelector?.userStep?.user?.user_role?.role_id ?? authSelector?.userStep?.user_role?.role_id;

  const choosePlan = authSelector?.userStep?.user?.membership_details?.name ?? authSelector?.userStep?.membership_details?.name;

  const searchCount = allMemberShipPlanCount?.storage;


  const toastId = useRef(null);
  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };




  //for COMPANY PROFILE INFORMATION.......
  const companyProfileInfo = () => {
    dispatch(
      getCompanyProfileInfo({
        cb(res) {
          if (res.status) {
            if (res?.data?.payload?.userDetails?.user_company_information !== null) {
              setAllMemberShipPlanCount(res?.data?.payload?.userDetails?.user_data[0])
            }
            else {
              setAllMemberShipPlanCount(res?.data?.payload?.userDetails?.user_selected_company_information?.user_data[0])
            }
          } else {
          }
        },
      })
    );
  };

  //onDrop
  const onDrop = useCallback(acceptedFiles => {
    const imageFile = acceptedFiles[0];
    // if (!imageFile.name.match(/\.(jpg|jpeg|png|gif|svg)$/)) {
    //     toast.error("Please select a valid image.");
    //     return false;
    // }
    let params = {
      document: imageFile,
      storageKey: true
    }

    const size = imageFile.size;
    const sizeInMB = size / (1024 * 1024);

    const total_search_count_in_bytes = sizeInMB + searchCount;

    const truncatedTotal = parseFloat(total_search_count_in_bytes.toFixed(4));

    if (truncatedTotal >= 10 && choosePlan === "Bronze" && chooseBusinessType === 2) {
      showToast("Current plan bronze. Please upgrade your membership plan ")
    }
    else if (truncatedTotal >= 250 && choosePlan === "Gold" && chooseBusinessType === 2) {
      showToast("Current plan gold. Please upgrade your membership plan")
    }
    else if (truncatedTotal >= 750 && choosePlan === "Platinum" && chooseBusinessType === 2) {
      showToast("Current plan Platinum. ")
    }
    else if(chooseBusinessType === 1 && truncatedTotal >= 10 && choosePlan === "Bronze"){
      showToast("Current plan bronze. Please upgrade your membership plan ")
    }
    else if (chooseBusinessType === 1 && truncatedTotal >= 250 && choosePlan === "Gold" ) {
      showToast("Current plan gold. Please upgrade your membership plan")
    }
    else if (truncatedTotal >= 750 && choosePlan === "Platinum" && chooseBusinessType === 1) {
      showToast("Current plan Platinum. ")
    }
    else {
      dispatch(uploadAllDocuments({
        ...params, cb(res) {
          if (res.status) {
            setDocuments(res?.data?.payload?.document);
            setDocumentsInfo(res?.data?.payload)
            props.setActiveTab("allDocuments");
          }
          else {
          }
        }
      }))
    }
  }, [searchCount])




  const { getRootProps, getInputProps } =

    useDropzone({
      onDrop,
      accept: {
        'application/pdf': [],
        'image/jpeg': [],
        'image/png': [],
        'application/msword': [], // .doc files
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [] //docx file
      }
    });

  //remove file
  const removeFile = file => () => {
    const newFiles = [documents]
    newFiles.splice(newFiles.indexOf(file), 1)
    setDocuments(newFiles)
  }


  useEffect(() => {
    companyProfileInfo();
  }, []);
  return (
    <>
      <form className='editprofileForm_ row'>

        <div className='note_box'>
          <h6 className='postHeading mt-3'>Document Upload</h6>
          <span className="noteHeading mt-3">pdf file not more than 5mb</span>
        </div>
        <div className='form-group col-md-12 mb-3'>
          {documents?.length > 0 ?
            "" :
            <div className="uploadImgebox">

              <div {...getRootProps({ className: "dropzone" })}>
                <input  {...getInputProps()} />
                <p className='uploadbtnn'>Upload</p>
                <p className='uploadText mt-2'>PNG, JPG, PDF, DOC, DOCX file upload</p>
              </div>
            </div>
          }
        </div>
        {documents?.length > 0 ?
          <aside >
            <h4 className='headingSmall_'> Uploaded Files</h4>
            <ul className='uploadProfile_'>
              <img className="w3-round" src={documents} ></img>
              <div className='profileDetail_'>
                <h5 className='profileboldHead'>{documentsInfo.name}</h5>
                <p className='profileboldgraph'>
                  {(documentsInfo?.size / (1024 * 1024)).toFixed(2)} MB
                </p>
              </div>
              <i className="fa fa-trash text-red" onClick={removeFile(documents)}></i>
            </ul>
          </aside>
          : ""
        }
      </form>
    </>
  )
}
export default UploadDocuments;