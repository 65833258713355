import React, { useCallback, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import MultiStepProgressBar from "./component/multiStepProgressBar";
import { useDispatch } from 'react-redux';
import { signUpStep, stepThreeCompanyLogoUplaod, stepTwo } from '../../../redux/slices/auth';
import * as Images from "../../../utilities/images";

const PostOpportunity = () => {
    const [activeTab, setActiveTab] = useState('quarterly');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const flag = location?.state?.step;
    const [pageNumber, setPageNumber] = useState(flag ? flag + 1 : 2);
    const [page, setPage] = useState(flag == 2 ? "pagethree" : flag == 3 ? "pagefour" : flag == 4 ? "pagefive" : flag == 5 ? "pagesix" : flag == 6 ? "pagesix" : "pageone");
    const [photo, setPhotoUpload] = useState();
    const [companyName, setCompanyName] = useState("")
    const [companyWebsite, setCompanyWebsite] = useState("")
    const [companyDescription, setCompanyDescription] = useState("")
    const [companyType, setCompanyType] = useState("1")
    const [industryGroups, setIndustryGroups] = useState("1")
    const [industrySubGroups, setIndustrySubGroups] = useState("1")
    const [companyKeyWords, setCompanyKeyWords] = useState("")
    const [companyServicesDescription, setCompanyServicesDescription] = useState("")
    const [memberShip, setMemberShip] = useState("");

    //onDrop
    const onDrop = useCallback(acceptedFiles => {
        const imageFile = acceptedFiles[0];
        if (!imageFile.name.match(/\.(jpg|jpeg|png|gif|svg)$/)) {
            toast.error("Please select a valid image.");
            return false;
        }
        let params = {
            photo: imageFile,
        }
        dispatch(stepThreeCompanyLogoUplaod({
            ...params, cb(res) {
                if (res.status) {
                    setPhotoUpload(res?.data?.payload?.url);
                }
                else {
                }
            }
        }))
    }, [])

    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    //remove file
    const removeFile = file => () => {
        const newFiles = [photo]
        newFiles.splice(newFiles.indexOf(file), 1)
        setPhotoUpload(newFiles)
    }

    //next page
    const nextPage = (page) => {
        setPage(page);
    };

    //next page number
    const nextPageNumber = (pageNumber) => {
        switch (pageNumber) {
            case "1":
                setPage("pageone");
                break;
            case "2":
                setPage("pagetwo");
                break;
            case "3":
                setPage("pagethree");
                break;
            default:
                setPage(1);

        }
    };

    //select membership
    const handleMembership = (event) => {
        setMemberShip(event.target.value)
    }

    //form login
    const handleSubmit = (e, flag, save) => {
        setPageNumber(flag)
        e.preventDefault();
        if (flag == 3) {
            if (!companyName) {
                toast.error("Please enter company name");
                return;
            }
            else if (!companyWebsite) {
                toast.error("Please enter company website");
                return;
            }
            else if (!companyDescription) {
                toast.error("Please enter diverse supplier description");
                return;
            }
            let params = {
                step: 2,
                company_name: companyName,
                company_website: companyWebsite,
                diverse_supplier_description: companyDescription,
                business_ownership_type: parseInt(companyType)
            }
            dispatch(signUpStep({
                ...params, cb(res) {
                    if (save == "save&finish") {
                        localStorage.removeItem('authToken');
                        localStorage.removeItem('persist:root');
                        navigate('/');
                    }
                    else if (res.status) {
                        nextPage("pagethree")
                    }
                }
            }))
        }
        else if (flag == 4) {
            if (!photo) {
                toast.error("Please select company logo ");
                return;
            }
            let params = {
                step: 3,
                company_logo: photo,
            }
            dispatch(signUpStep({
                ...params, cb(res) {
                    if (save == "save&finish") {
                        localStorage.removeItem('authToken');
                        localStorage.removeItem('persist:root');
                        navigate('/');
                    }
                    else if (res.status) {
                        nextPage("pagefour")
                    }
                }
            }))
        }
        else if (flag == 5) {
            if (!industryGroups) {
                toast.error("Please select industry Groups");
                return;
            }
            else if (!industrySubGroups) {
                toast.error("Please select industry Sub Groups");
                return;
            }
            else if (!companyKeyWords) {
                toast.error("Please enter company Key Words");
                return;
            }
            else if (!companyServicesDescription) {
                toast.error("Please enter company Services Description");
                return;
            }
            let params = {
                step: 4,
                industry_groups: parseInt(industryGroups),
                industry_sub_groups: parseInt(industrySubGroups),
                key_words: companyKeyWords,
                company_service_description: companyServicesDescription
            }
            dispatch(signUpStep({
                ...params, cb(res) {
                    if (save == "save&finish") {
                        localStorage.removeItem('authToken');
                        localStorage.removeItem('persist:root');
                        navigate('/');
                    }
                    else if (res.status) {
                        nextPage("pagefive")
                    }
                }
            }))
        }
        else if (flag == 6) {
            if (!memberShip) {
                toast.error("Please select member ship");
                return;
            }

            let params = {
                step: 5,
                membership_type: parseInt(memberShip),

            }
            dispatch(signUpStep({
                ...params, cb(res) {
                    if (save == "save&finish") {
                        localStorage.removeItem('authToken');
                        localStorage.removeItem('persist:root');
                        navigate('/');
                    }
                    else if (res.status) {
                        nextPage("pagesix")
                    }
                }
            }))
        }
        else if (flag == 7) {
            let params = {
                step: 6,
            }
            dispatch(signUpStep({
                ...params, cb(res) {
                    if (save == "save&finish") {
                        localStorage.removeItem('authToken');
                        localStorage.removeItem('persist:root');
                        navigate('/');
                    }
                    else if (res.status) {
                        navigate('/dashboard');
                    }

                }
            }))
        }
    }

    return (
        <div className='registration'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-7' id="leftArea" >
                        <div className='formContainer'>
                            <div className='mb-5'>
                                <button className='stepBtn'>Step {pageNumber}</button>
                                <div className='stepProgress mt-4 mb-4'>
                                    <MultiStepProgressBar page={page} onPageNumberClick={nextPageNumber} />
                                    {
                                        {
                                            pageone:
                                                <div onButtonClick={nextPage} className='mt-4'>
                                                    <h3 className='headTxtBlue'><b>Post Opportunity</b></h3>
                                                    <p className='innerSubtext mt-1 mb-4'>Add details about opportunity below.</p>
                                                    <form onSubmit={(e) => handleSubmit(e, "3")} className='registrationForm row mt-3'>
                                                        <div className='form-group col-md-12 mb-3'>
                                                            <input name="companyName" type='text' className='customFormControl' value={companyName} placeholder='Posting Title' onChange={e => setCompanyName(e.target.value)} />
                                                        </div>

                                                        <div className='form-group col-md-12 mb-3'>
                                                            <textarea name="email" type='text' className='customFormControl' placeholder='Description' value={companyDescription} onChange={e => setCompanyDescription(e.target.value)} />
                                                        </div>
                                                        <div className='form-group col-md-6 mb-3'>
                                                            <input name="companyWebsite" type='text' className='customFormControl' placeholder='Response Deadline' />
                                                        </div>
                                                        <div className='form-group col-md-6 mb-3'>
                                                            <input name="companyWebsite" type='text' className='customFormControl' placeholder='Award Date' />
                                                        </div>
                                                        <div className='form-group col-md-6 mb-3'>
                                                            <input name="companyWebsite" type='text' className='customFormControl' placeholder='Opportunity Amount- Range' />
                                                        </div>
                                                        <div className='form-group col-md-6 mb-3'>
                                                            <input name="companyWebsite" type='text' className='customFormControl' placeholder='Contract Length' />
                                                        </div>

                                                        <div className='col-md-12'>
                                                            {photo?.length > 0 ?
                                                                "" :
                                                                <div className="uploadImgebox">

                                                                    <div {...getRootProps({ className: "dropzone" })}>
                                                                        <input {...getInputProps()} />
                                                                        <p className='uploadbtnn'>Upload</p>
                                                                        <p className='uploadText mt-2'>PNG, JPG, PDF, VIDEO file upload</p>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {photo?.length > 0 ?
                                                                <aside >
                                                                    <h4 className='headingSmall_'> Uploaded Files</h4>
                                                                    <ul className='uploadProfile_'>
                                                                        <img className="w3-round" src={photo} ></img>
                                                                        <div className='profileDetail_'>
                                                                            <h5 className='profileboldHead'>Company Logo</h5>
                                                                            <p className='profileboldgraph'>443 kb</p>
                                                                        </div>
                                                                        <i className="fa fa-trash text-red" onClick={removeFile(photo)}></i>
                                                                    </ul>
                                                                </aside>
                                                                : ""
                                                            }
                                                        </div>
                                                        <div className='col-md-12'>
                                                            <div className='d-flex justify-content-end align-items-center'>
                                                                <p onClick={(e) => handleSubmit(e, "3", "save&finish")} className='innerTxt me-3'>Save & <span className='linkTxt'><b>Finish Later</b></span></p>
                                                                <input
                                                                    className='primaryBtn d-inline-block'
                                                                    type="submit"
                                                                    value="Continue"
                                                                />
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>,
                                            pagetwo:
                                                <div onButtonClick={nextPage} className='mt-4'>
                                                    <h3 className='headTxtBlue'><b>Post Opportunity</b></h3>
                                                    <p className='innerSubtext mt-1 mb-4'>Choose Preferences for Supplier:</p>
                                                    <form onSubmit={(e) => handleSubmit(e, "3")} className='registrationForm row mt-3'>
                                                        <div className='form-group col-md-12 mb-3'>
                                                            <select className="cateSelectbox" required="" value={companyType} onChange={e => setCompanyType(e.target.value)}>
                                                                <option value="1">Product and Service Categories</option>
                                                                <option value="2" >Business Ownership type 2</option>
                                                                <option value="3" >Business Ownership type 3</option>
                                                            </select>
                                                        </div>
                                                        <div className='form-group col-md-12 mb-3'>
                                                            <input name="companyName" type='text' className='customFormControl' placeholder='Ship-to or Service Locations' onChange={e => setCompanyName(e.target.value)} />
                                                        </div>
                                                        <div className='form-group col-md-6 mb-3'>
                                                            <input name="companyWebsite" type='text' className='customFormControl' placeholder='Annual Revenue' onChange={e => setCompanyWebsite(e.target.value)} />
                                                        </div>
                                                        <div className='form-group col-md-6 mb-3'>
                                                            <input name="companyWebsite" type='text' className='customFormControl' placeholder='Number of Employees' onChange={e => setCompanyWebsite(e.target.value)} />
                                                        </div>
                                                        <div className='form-group col-md-6 mb-3'>
                                                            <input name="companyWebsite" type='text' className='customFormControl' placeholder='Years in Business' onChange={e => setCompanyWebsite(e.target.value)} />
                                                        </div>

                                                        <div className='col-md-12'>
                                                            <div className='d-flex justify-content-end align-items-center'>
                                                                <p onClick={(e) => handleSubmit(e, "3", "save&finish")} className='innerTxt me-3'>Save & <span className='linkTxt'><b>Finish Later</b></span></p>
                                                                <input
                                                                    className='primaryBtn d-inline-block'
                                                                    type="submit"
                                                                    value="Continue"
                                                                />
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>,
                                            pagethree:
                                                <div onButtonClick={nextPage} className='mt-4'>
                                                    <h3 className='headTxtBlue'><b>Post Opportunity</b></h3>
                                                    <p className='innerSubtext mt-1 mb-4'>Choose Preferences for Supplier:</p>
                                                    <form onSubmit={(e) => handleSubmit(e, "4")} className='registrationForm row mt-3'>
                                                        <div className='form-group col-md-6 mb-3'>
                                                            <select className="cateSelectbox" required="" onChange={e => setCompanyType(e.target.value)}>
                                                                <option value="1">Industries Served</option>
                                                                <option value="2" >Business Ownership type 2</option>
                                                                <option value="3" >Business Ownership type 3</option>
                                                            </select>
                                                        </div>
                                                        <div className='form-group col-md-6 mb-3'>
                                                            <select className="cateSelectbox" required="" onChange={e => setCompanyType(e.target.value)}>
                                                                <option value="1">Business Types Served</option>
                                                                <option value="2" >Business Ownership type 2</option>
                                                                <option value="3" >Business Ownership type 3</option>
                                                            </select>
                                                        </div>
                                                        <div className='form-group col-md-6 mb-3'>
                                                            <select className="cateSelectbox" required="" onChange={e => setCompanyType(e.target.value)}>
                                                                <option value="1">Certifications</option>
                                                                <option value="2" >Business Ownership type 2</option>
                                                                <option value="3" >Business Ownership type 3</option>
                                                            </select>
                                                        </div>
                                                        <div className='form-group col-md-6 mb-3'>
                                                            <select className="cateSelectbox" required="" onChange={e => setCompanyType(e.target.value)}>
                                                                <option value="1">Industries Served</option>
                                                                <option value="2" >Business Ownership type 2</option>
                                                                <option value="3" >Business Ownership type 3</option>
                                                            </select>
                                                        </div>


                                                        <div className='col-md-12'>
                                                            <div className='d-flex justify-content-end align-items-center'>
                                                                <p onClick={(e) => handleSubmit(e, "3", "save&finish")} className='innerTxt me-3'>Save & <span className='linkTxt'><b>Finish Later</b></span></p>
                                                                <input
                                                                    className='primaryBtn d-inline-block'
                                                                    type="submit"
                                                                    value="Continue"
                                                                />
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>,
                                        }[page]
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-5' id='rightArea'>
                        <div className='rightContentArea'>
                            <p>
                                “Your work is going to fill a large part of your life, and the only way to be truly satisfied is to do what you believe is great work. And the only way to do great work is to love what you do.”
                            </p>
                            <b>Steve Jobs</b>
                            <p>co-founder, chairman and CEO of Apple Inc.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PostOpportunity