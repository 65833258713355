import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuthSelector } from "../../../../redux/selector/auth";
import { searchCompanyUserFun } from "../../../../redux/slices/auth";

const AddTagToPost = (props) => {


  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authSelector = useAuthSelector();

  const [activeTab, setActiveTab] = useState("company");
  
  const data = authSelector?.searchCompanyUsers ?? []; // Placeholder for data, you can replace with actual data
  const [searchTerm, setSearchTerm] = useState("");

  const [tags, setTags] = useState({
    companies : props.selectedCompanies ?? [],
    users: props.selectedUsers ?? [],
  });

  const [selectedId , setSelectedId] = useState({
    companies : props.selectedCompanies?.map((item,index)=> {return item.id}) ?? [],
    users: props.selectedUsers?.map((item,index)=> {return item.id}) ?? [],
  });

  const handleTabClick = (tab) => {

    setActiveTab(tab);
    setSearchTerm('');
    searchCompanyUser(tab,null)
  
};

  const handleSearch = (val) => {
    setSearchTerm(val);
    searchCompanyUser(activeTab,val)
  };



//   const handleCheckboxChange = (itemId,name) => {
//     const data = {...tags}
//     const IdData = {...selectedId}

//     if(activeTab === 'company'){
//         data.companies.push({
//             id:itemId,
//             name:name
//         })
//         IdData.companies.push(itemId)
//     }
//     else{
//         data.users.push({
//             id:itemId,
//             name:name
//         })               
//         IdData.users.push(itemId)

//     }

//     setTags(data)
//     setSelectedId(IdData)

//   };

  const handleCheckboxChange = (itemId, name) => {

   

    const data = { ...tags };
    const IdData = { ...selectedId };

    if (activeTab === 'company') {

        if (IdData.companies.includes(itemId)) {
            // Remove the company from the selected list and data
            const index = IdData.companies.indexOf(itemId);
            if (index > -1) {
                IdData.companies.splice(index, 1);
                data.companies = data.companies.filter(company => company.id !== itemId);
            }
        } else {

            if(IdData.companies.length >= 10){
                toast.dismiss()
                toast.warning('You can tag maximum of 10 companies in a post')
                return false;
            }
            // Add the company to the selected list and data
            data.companies.push({
                id: itemId,
                name: name,
            });
            IdData.companies.push(itemId);
        }
    } else {

        if (IdData.users.includes(itemId)) {
            // Remove the user from the selected list and data
            const index = IdData.users.indexOf(itemId);
            if (index > -1) {
                IdData.users.splice(index, 1);
                data.users = data.users.filter(user => user.id !== itemId);
            }
        } else {
            
            if(IdData.users.length >= 10){
                toast.dismiss()
                toast.warning('You can tag maximum of 10 users in a post')
                return false;
            }
            // Add the user to the selected list and data
            data.users.push({
                id: itemId,
                name: name,
            });
            IdData.users.push(itemId);
        }
    }

    setTags(data);
    setSelectedId(IdData);
};

  const addDataToTags = () => {
    props?.updateTagData(tags,selectedId)
    props?.close()
  }

  const searchCompanyUser = (type = 'company', search = searchTerm) => {

    let params = {
        type: type === 'company' ? 2 : 1 ,
        search:  search,
        limit: 20
    }

    dispatch(searchCompanyUserFun({
        ...params, cb(res) {
            
        }
    }))

  }

  useEffect(() => {
    handleTabClick("company");
  }, []);

  return (
    <div className="container add-tag-to-post">
      <div className="row">
        <div className="buttons text-center">
          <button
            className={`tagBox ${activeTab === "company" ? "active" : ""}`}
            onClick={() => handleTabClick("company")}
          >
            Company
          </button>
          <button
            className={`tagBox ${activeTab === "user" ? "active" : ""}`}
            onClick={() => handleTabClick("user")}
          >
            Users
          </button>
        </div>
      </div>
      <div className="row content">
        <div className="tab-content">
          <div className="list">
            <h5 className="postopportunity_label mt-3">
              {activeTab === "company" ? "Companies" : "Users"}
            </h5>
            <input
              className="customFormControl"
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
            />
            <div className="row postTags mt-3">
            {activeTab === 'company' ?
                <>
                {tags.companies.map((val, index) => {
                    return (
                        <div className="tag col-md-3">
                        <div>
                            <sapn>{val.name}</sapn>
                        </div>
                        <div className="close" onClick={() =>{handleCheckboxChange(val.id, val.name)}} >
                            <i
                            className="fa fa-times-circle"
                            aria-hidden="true"
                            ></i>
                        </div>
                        </div>
                    );
                })}
                </>
            :
            <>
                {tags.users.map((val, index) => {
                    return (
                        <div className="tag col-md-3">
                        <div>
                            <sapn>{val.name}</sapn>
                        </div>
                        <div className="close" onClick={() =>{handleCheckboxChange(val.id, val.name)}}>
                            <i
                            className="fa fa-times-circle"
                            aria-hidden="true"
                            ></i>
                        </div>
                        </div>
                    );
                })}

            </>}
    
            </div>
            <ul className="tagList">
              {data.length > 0 ? 
              <>
              {data.map((item) => (
                <li key={item.id}>
                  <div className="checkmark_ mt-3">
                    <input
                      type="checkbox"
                      id={`business${item.id}`}
                      name=""
                      className="businessType_"
                        onChange={() =>{
                        
                            const name = activeTab === 'company' ? item.company_name : item?.user_profile?.first_name+" "+item?.user_profile?.last_name 
                            handleCheckboxChange(item.id , name)
                        
                        }}
                      checked={activeTab === 'company' ? selectedId.companies.includes(item.id) : selectedId.users.includes(item.id) }
                    />
                    <label for={`business${item.id}`} className="labelTxt_">

                    {activeTab === 'company' ? 
                        <>
                            {item.company_name}
                        </>
                      :
                        <>
                            {item?.user_profile?.first_name+" "+item?.user_profile?.last_name}
                        </>
                    }
                    
                    </label>
                  </div>
                </li>
              ))}
                </>

              :
                <>
                <li>
                  <div className="checkmark_ mt-3">
                    {activeTab === "company" ? "No Companies Found" : "No Users Found"}
                  </div>
                </li>
                </>
            }
            </ul>
            <div className="button_box text-center  my-2">
              <button className="primaryBtn mx-2" type="submit" onClick={()=>{
                addDataToTags()
              }}>
                Add
              </button>
              <button
                className="subsmallBtnblue_ mx-2"
                onClick={() => props.close()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTagToPost;
