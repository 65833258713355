import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import * as Images from "../../../../utilities/images";

import { faImages } from "@fortawesome/free-regular-svg-icons";
import { editPost, getPostSingleDetails, postImageUplaod, userPost } from "../../../../redux/slices/dashboard";
import { getCompanyProfileInfo } from "../../../../redux/slices/auth";
import { useAuthSelector } from "../../../../redux/selector/auth";
import CustomModal from "../../../components/common/shared/CustomModal";
import AddTagToPost from "./addTagToPost";
import { useDashboardSelector } from "../../../../redux/selector/dashboard";


const AddNewPost = (props) => {

    const dispatch = useDispatch();

    const authSelector = useAuthSelector()

    const dashboardSelector = useDashboardSelector()

    const [key, setKey] = useState(Math.random());

    // const [previewData, setPreviewData] = useState(null);
    // const [loading, setLoading] = useState(true);

    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });

    const [userDetailProfile, setUserDetailProfile] = useState("");
    const [post, setPost] = useState("");
    const [loader, setLoader] = useState(false)
    const [photo, setPhotoUpload] = useState({
        uploadPhoto: "",
        flag: ""
    });
    const [photoInfo, setPhotoInfo] = useState();
    const [uploadloader, setUploadLoader] = useState(false);
    const [videoUrlOriginal, setVideoUrlOriginal] = useState();

    const [mainTags, setMainTags] = useState({
        companies: [],
        users: [],
    })

    const [selectedIds, setSelectedIds] = useState({
        companies: [],
        users: [],
    })

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
        // fetchData();
    };



    const updateTagData = (tagData, idData) => {
        setMainTags(tagData)
        setSelectedIds(idData)
    }

    //onChange input
    const onChangeInput = (e, fieldName) => {
        const value = e.target.value;
        if (fieldName === "post") {
            setPost(value)
        }
    };


    //remove file
    const removeFile = file => () => {
        const newFiles = [photo]
        newFiles.splice(newFiles.indexOf(file), 1)
        setPhotoUpload(newFiles)
        setPhotoInfo("")
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!post.trim()) {
            toast.error("Please enter Post text");
            return;
        }
        else if (!post) {
            toast.error("Please enter Post without trim");
            return;
        }

        else if (post?.length <= 2) {
            toast.error("Name should be maximum 3 character")
            return;
        }
        let params = {};

        if (post !== "") {
            params.post = post.trim();
        }

        if (photoInfo) {
            params.attachment = videoUrlOriginal;
            params.attachment_type = photo.flag;
            params.thumbnail_url = photo.uploadPhoto?.url;
            params.attachment_name = photo.uploadPhoto?.name;
            params.attachment_size = photo.uploadPhoto?.size;
        }

        if (selectedIds.companies?.length > 0) {
            params.company_tags = selectedIds.companies;
        }

        if (selectedIds?.users?.length > 0) {
            params.user_tags = selectedIds.users;
        }

        if (props?.editFlag) {
            params.id = props?.editId
            dispatch(editPost({
                ...params, cb(res) {
                    if (res.status == 200) {
                        props?.close()
                        props?.setEditFlag(false)
                        props?.setEditId("")
                        props?.setEditPostID(null)
                        setVideoUrlOriginal("")
                        setPhotoInfo("")
                        setPost("")
                        setPhotoUpload({ ...photo, uploadPhoto: "", flag: "" });
                        setMainTags({
                            companies: [],
                            users: [],
                        })
                        setSelectedIds({
                            companies: [],
                            users: [],
                        })

                        props?.setAllPostList((prevData) => {
                            const data = prevData.filter((item, i) => res.data.payload.id != item.id);
                            const newData = [res.data.payload, ...data]
                            return newData;
                        })
                        toast.success("Post Updated Successfully")
                    }
                    else {

                    }

                }
            }))

        }

        else {
            setLoader(true)
            dispatch(userPost({
                ...params, cb(res) {
                    if (res.status) {
                        props?.close()
                        setLoader(false)
                        setVideoUrlOriginal("")
                        setPhotoInfo("")
                        setPost("")
                        setMainTags({
                            companies: [],
                            users: [],
                        })
                        setSelectedIds({
                            companies: [],
                            users: [],
                        })
                        setPhotoUpload({ ...photo, uploadPhoto: "", flag: "" });

                        props?.setAllPostList([res.data.payload, ...props?.allPost]);
                    }
                }
            }))
        }
    }


    //onDrop
    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
        if (rejectedFiles && rejectedFiles.length > 0) {

            rejectedFiles.forEach(file => {
                toast.warning(`Please select mentioned file type only.`);
            });
            return;
        }
        const imageFile = acceptedFiles[0];
        let params = {
            photo: imageFile,
            type: "post"
        }
        dispatch(postImageUplaod({
            ...params, cb(res) {
                if (res.status) {

                    if (res?.data?.payload?.originalUrl?.format == "video/mp4") {
                        setPhotoUpload({ ...photo, uploadPhoto: res?.data?.payload?.thumbnailUrl, flag: 3 });
                        setVideoUrlOriginal(res?.data?.payload?.originalUrl.url)
                    }
                    else if (res?.data?.payload?.originalUrl?.format == "application/pdf") {
                        setPhotoUpload({ ...photo, uploadPhoto: res?.data?.payload?.originalUrl, flag: 2 });
                        setVideoUrlOriginal(res?.data?.payload?.originalUrl.url)
                    }

                    else {
                        setPhotoUpload({ ...photo, uploadPhoto: res?.data?.payload?.thumbnailUrl, flag: 1 })
                        setVideoUrlOriginal(res?.data?.payload?.originalUrl.url)
                    }
                    setPhotoInfo([res?.data?.payload?.thumbnailUrl.url])
                }
                else {
                }
            }
        }))

    }, [])

    const { getRootProps, getInputProps } =
        useDropzone({
            onDrop,
            accept: {
                'image/jpeg': [],
                'image/jpg': [],
                'image/png': [],
                'application/pdf': [],
                'video/mp4': [], // Allow MP4 video files
            }
        });



    const removeTags = (itemId, name, type) => {

        const data = { ...mainTags };
        const IdData = { ...selectedIds };

        if (type === 'company') {

            if (IdData.companies.includes(itemId)) {
                // Remove the company from the selected list and data
                const index = IdData.companies.indexOf(itemId);
                if (index > -1) {
                    IdData.companies.splice(index, 1);
                    data.companies = data.companies.filter(company => company.id !== itemId);
                }
            }
        } else {

            if (IdData.users.includes(itemId)) {
                // Remove the user from the selected list and data
                const index = IdData.users.indexOf(itemId);
                if (index > -1) {
                    IdData.users.splice(index, 1);
                    data.users = data.users.filter(user => user.id !== itemId);
                }
            }
        }

        setMainTags(data)
        setSelectedIds(IdData)
    };

    let ComapnyLogo;
    if (props?.userDeatil?.company_logo) {
        ComapnyLogo = props?.userDeatil?.company_logo;
    }
    else {
        ComapnyLogo = Images.puzzleProfile;
    }

    const editPostAction = (id) => {
        let params = {
            id: id,
        }
        dispatch(getPostSingleDetails({
            ...params, cb(res) {
                if (res.status) {

                    props?.setEditFlag(true)
                    // setEditPostID(res.data.payload?.id)
                    setPost(res.data.payload?.post ?? '')

                    const companyTagData = res.data.payload?.tag_company_details?.map((item, i) => {
                        return {
                            id: item.company_id,
                            name: item?.company_information?.company_name
                        }
                    })

                    const userTagData = res.data.payload?.tag_user_details?.map((item, i) => {
                        return {
                            id: item.user_id,
                            name: item?.user_details?.user_profile?.first_name + " " + item?.user_details?.user_profile?.last_name
                        }
                    })

                    setMainTags({
                        companies: companyTagData,
                        users: userTagData
                    })

                    setSelectedIds({
                        companies: companyTagData.map((item) => item.id),
                        users: userTagData.map((item) => item.id)
                    })

                    if (res?.data?.payload?.attachment) {

                        setPhotoUpload({
                            uploadPhoto: {

                                name: res?.data?.payload?.attachment_name ?? 'File',
                                size: res?.data?.payload?.attachment_size ?? 100000,
                                url: res?.data?.payload?.thumbnail_url

                            }, flag: res?.data?.payload?.attachment_type
                        });

                        setVideoUrlOriginal(res?.data?.payload?.attachment)

                        setPhotoInfo([res?.data?.payload?.thumbnail_url])

                    }

                    if (window)
                        window.scrollTo(0, 0);

                }
            }
        }))
    }



    useEffect(() => {
        if (props?.editId) {
            editPostAction(props?.editId)
        }
    }, [props?.editId])

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             let thumbnailUrl = '';

    //             // Check if the post is a YouTube video URL
    //             const youtubeMatch = post.match(/[?&]v=([^&]+)/);
    //             if (youtubeMatch) {
    //                 const videoId = youtubeMatch[1];
    //                 thumbnailUrl = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
    //             } else {
    //                 // Handle other types of URLs here, e.g., fetching Open Graph metadata
    //                 // For simplicity, let's use a placeholder image for non-YouTube URLs
    //                 const response = await fetch(`https://api.microlink.io?url=${encodeURIComponent(post)}`);
    //                 const data = await response.json();
    //                 thumbnailUrl = data
    //             }

    //             setPreviewData({ thumbnailUrl });
    //             setLoading(false);
    //         } catch (error) {
    //             console.error(error);
    //             setLoading(false);
    //         }
    //     };

    //     fetchData();
    // }, [post]);

    // if (loading) {
    //     return <p>Loading...</p>;
    // }

    // const handleClick = () => {
    //     window.open(post, '_blank');
    // };

    // console.log(previewData, "preveiew dataa");

    return (
        <>
            <div className="hrRow"></div>
            <div className="container add-tag-to-post mt-3">
                <div className="addPostCon mb-4">
                    <>
                        <img src={ComapnyLogo} alt="" className="addPostImg" />
                        <div className="addPostName">
                            <h4 className="addPostTitle mb-0">{authSelector?.userInfo?.user?.user_profile?.first_name + " " + (authSelector?.userInfo?.user?.user_profile?.last_name === null ? " " : authSelector?.userInfo?.user?.user_profile?.last_name)}</h4>
                            <p className="addPostSub mb-0">{props?.userDeatil?.company_name}</p>
                        </div>
                    </>

                </div>
                <div className="form-group mb-3 col-md-12">
                    <textarea type="text" className="customFormControl" placeholder="What do you have to tell people..." id="inputName" name="post" value={post} onChange={e => { onChangeInput(e, 'post') }} rows="4" />
                    <div>
                    </div>
                </div>
                {/* <div className="row">
                    {post?.startsWith('https') ?
                        <div onClick={handleClick} style={{ cursor: 'pointer' }}>
                            {previewData?.thumbnailUrl && <img src={previewData.thumbnailUrl} alt="YouTube Thumbnail" />}
                        </div>
                        : ""}
                </div> */}
                <div className='row'>
                    {
                        dashboardSelector?.loading ? <div className="row">
                            <div className="col-sm-12 text-center pt-5 pb-5">
                                <div className="spinner-grow text-success" role="status"></div>
                            </div>
                        </div>
                            :
                            <div className="uploadFile_ col-md-12">
                                {photoInfo ?
                                    <>
                                        {photo?.uploadPhoto != "" && photo?.uploadPhoto?.format == "video/mp4" ?
                                            <>
                                                <div className="uploadProfile_">
                                                    <video controls className="videoBox_ me-3">
                                                        <source src={photoInfo} type="video/mp4" className='soundbiteImg' />
                                                    </video>
                                                    <img className="w3-round" src={photoInfo}></img>
                                                    <h5 className='profileboldHead me-2'>{photo?.uploadPhoto?.name?.length > 40.5 ? photo?.uploadPhoto?.name.substring(0, 40.5) + "...." : photo?.uploadPhoto?.name}</h5>
                                                    <p className='profileboldgraph'>
                                                        {(photo.uploadPhoto?.size / (1024 * 1024)).toFixed(2)} MB
                                                    </p>
                                                    <i className="fa fa-times crossfile text-red"
                                                        onClick={removeFile(photoInfo)}
                                                    ></i>
                                                </div>
                                            </> : photo?.uploadPhoto?.format === "application/pdf" ?
                                                <>


                                                    <ul className='uploadProfile_'>
                                                        <img className="w3-round" src={Images.PDFImage} />
                                                        <div className='profileDetail_'>
                                                            <h5 className='profileboldHead'>{photo?.uploadPhoto?.name > 40.5 ? photo?.uploadPhoto?.name.substring(0, 40.5) + "...." : photo?.uploadPhoto?.name}</h5>
                                                            <p className='profileboldgraph'>
                                                                {(photo.uploadPhoto?.size / (1024 * 1024)).toFixed(2)} MB
                                                            </p>
                                                        </div>
                                                        <i className="fa fa-times crossfile text-red"
                                                            onClick={removeFile(photoInfo)}
                                                        ></i>
                                                    </ul>
                                                </>
                                                : photo?.uploadPhoto ?
                                                    <>

                                                        <ul className='uploadProfile_'>
                                                            <img className="w3-round" src={photoInfo}></img>
                                                            <div className='profileDetail_'>
                                                                <h5 className='profileboldHead'>{photo?.uploadPhoto?.name > 40.5 ? photo?.uploadPhoto?.name.substring(0, 40.5) + "...." : photo?.uploadPhoto?.name}</h5>
                                                                <p className='profileboldgraph'>
                                                                    {(photo.uploadPhoto?.size / (1024 * 1024)).toFixed(2)} MB
                                                                </p>
                                                            </div>
                                                            <i className="fa fa-times crossfile text-red"
                                                                onClick={removeFile(photoInfo)}
                                                            ></i>
                                                        </ul>
                                                    </>
                                                    : ""
                                        }
                                    </>
                                    : <div className='form-group col-md-12 mb-3'>

                                        <div className="uploadImgeboxNew">

                                            <div {...getRootProps({ className: "dropzone" })}>
                                                <input {...getInputProps()} />
                                                <img src={Images.uploadIcon} alt="uploadIcon" className='img-fluid uploadfileIcon hjgfhjfhj' />
                                                <p className='uploadbtn mb-0'>Drag & Drop or <span className='ColoredText'>browse</span></p>
                                                <p className='uploadText'>Supports: JPEG, JPG, PNG or PDF</p>
                                            </div>
                                        </div>

                                    </div>
                                }
                                <div className="form-group mb-3 col-md-12">
                                    <div className="row postTags">
                                        {mainTags.companies.map((val, index) => {
                                            return (
                                                <div className="tag col-md-3">
                                                    <div>
                                                        <span>{val.name}</span>
                                                    </div>
                                                    <div className="close" onClick={() => { removeTags(val.id, val.name, 'company') }}>
                                                        <i className="fa fa-times-circle" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        {mainTags.users.map((val, index) => {
                                            return (
                                                <div className="tag col-md-3">
                                                    <div>
                                                        <span>{val.name}</span>
                                                    </div>
                                                    <div className="close" onClick={() => { removeTags(val.id, val.name, 'users') }} >
                                                        <i className="fa fa-times-circle" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        {/* <div className="add-tag col-md-3" onClick={() => {
                                            if (post == null || post == '') {
                                                toast.dismiss()
                                                toast.warning('Post text must be added before adding tags')
                                                return false;
                                            }
                                            setModalDetail({ show: true, flag: "addTagToPost", header: "Add tags to post" });
                                            setKey(Math.random());
                                        }}>
                                            <p>
                                                + Add Tag
                                            </p>

                                        </div> */}
                                    </div>
                                </div>
                            </div>}
                </div>


                <button onClick={(e) => handleSubmit(e)} className="postBtn__">
                    {dashboardSelector?.dashboard_loader && (
                        <span className="spinner-border spinner-border-sm"></span>
                    )}
                    Post Message</button>
            </div>
            <CustomModal
                key={key}
                flag={modalDetail.flag}
                showCustomBackdrop={modalDetail.flag === "CommentBox" ? true : false}
                show={modalDetail.show}
                backdrop={modalDetail.flag === "CommentBox" ? null : "static"}
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                className={modalDetail.flag === "CommentBox" ? "commonWidth customContent" : ""}
                ids={modalDetail.flag === "AddANewPost" ? "addNewPost" : modalDetail.flag === "CommentBox" ? "commentBox" : modalDetail.flag === "CompanyLogo" ? "CompanyLogo" : modalDetail.flag === "allPostLikeUser" ? "allPostLikeUser" : modalDetail.flag === "addTagToPost" ? "addTagToPost" : modalDetail.flag === "Endorsements" ? "Endorsements" : ""}
                child={
                    modalDetail.flag == "addTagToPost" ?
                        <AddTagToPost
                            id={modalDetail.id}
                            close={() => handleOnCloseModal()}
                            updateTagData={(data, ids) => updateTagData(data, ids)}
                            selectedCompanies={props?.selectedCompanies}
                            selectedUsers={props?.selectedUsers}
                        />

                        : ""
                }

                header={
                    modalDetail.flag === "CommentBox" ?
                        <h2 className="headingSmall_">
                            Comments
                        </h2>
                        :
                        <div className='modalHeader_'>
                            <div className="common_">
                                {modalDetail.flag === "CompanyLogo" ?
                                    <h2 className="headingSmall_">
                                        Company Logo
                                    </h2>
                                    : modalDetail.flag == "allPostLikeUser" ?
                                        <h2 className="headingSmall_">
                                            Post Like List
                                        </h2>
                                        : modalDetail.flag == "addTagToPost" ?
                                            <h2 className="headingSmall_">
                                                {modalDetail.header}
                                            </h2>
                                            : modalDetail.flag == "AddANewPost" ?
                                                <>

                                                    <h2 className="headingSmall_">
                                                        Post a Message
                                                    </h2>
                                                </>
                                                :
                                                ""}
                            </div>
                        </div>
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    );
};

export default AddNewPost;
