import { all, call, put, takeLatest } from "redux-saga/effects";
import { ApiClient } from "../../../utilities/api";
import ApiPath from "../../../constants/apiPath";
import { toast } from "react-toastify";
import {
  setCreateCustomer,
  setPaymentMethod,
  onErrorStopLoad,
  setCreateSubscription,
  setCouponInfo,
  setDeletedCard,
  setPaymentHistory
} from "../../slices/stripeIntegration";


// Worker saga will be fired on USER_FETCH_REQUESTED actions

function* createCustomer(action) {
  try {
    var dataToSend = { ...action.payload };
    delete dataToSend.cb;
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.StripeIntegration.CHECK_CUTOMER),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setCreateCustomer(resp?.data?.payload));
      yield call(action.payload.cb, (action.res = resp));
      // toast.success(action.res.data.msg);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setCreateCustomer({}));
    yield put(onErrorStopLoad());
    toast.error(e?.response?.data?.msg);
  }
}

function* updatePaymentMethod(action) {
  try {
    var dataToSend = { ...action.payload };
    delete dataToSend.cb;
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.StripeIntegration.UPDATE_CUSTOMER_PAYMENT_METHOD),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setPaymentMethod(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      // toast.success(action.res.data.msg);
    } else {
      throw resp;
    }
  } catch (e) {

    yield put(setPaymentMethod({}));
    yield put(onErrorStopLoad());
    toast.dismiss();
    if(e?.response?.data?.payload && e?.response?.data?.payload[0]==="Payment method is required" ){
      toast.error("Enter a valid card details")
      return
    }
    toast.error(e?.response?.data?.msg);
  }
}

function* createSubscription(action) {
  try {
    var dataToSend = { ...action.payload };
    delete dataToSend.cb;
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.StripeIntegration.CREATE_SUBSCRIPTION),
      (dataToSend)
    );
    if (resp) {
      yield put(setCreateSubscription(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      // toast.success(action.res.data.msg);
    } else {
      throw resp;
    }
  } catch (e) {
    if(e?.response?.status == 500){
      toast.error(e?.response?.data?.error);
      yield put(setCreateSubscription({}));
      yield put(onErrorStopLoad());
      return
    }
    yield put(setCreateSubscription({}));
    yield put(onErrorStopLoad());
    toast.error(e?.response?.data?.payload ? e?.response?.data?.payload[0] : "There was some we will resolve it soon");
  }
}

function* deleteACard(action) {
  try {
    var dataToSend = { ...action.payload };
    delete dataToSend.cb;
    const resp = yield call(
      ApiClient.delete,
      (action.url =
        ApiPath.StripeIntegration.DELETE_CARD + "/" + dataToSend.cardNumber),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setDeletedCard(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      // toast.success(action.res.data.msg);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setDeletedCard({}));
    yield put(onErrorStopLoad());
    toast.error(e?.response?.data?.error);
  }
}

function* checkCoupon(action) {
  try {
    var dataToSend = { ...action.payload };
    delete dataToSend.cb;
    const resp = yield call(
      ApiClient.get,
      (action.url =
        ApiPath.StripeIntegration.CHECK_COUPON + "/" + dataToSend.promoCode)
    );
    if (resp.status) {
      yield put(setCouponInfo(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      // toast.success(action.res.data.msg);
    } else {
      throw resp;
    }
  } catch (e) {
    yield call(action.payload.cb);
    yield put(setCouponInfo({}));
    yield put(onErrorStopLoad());
    toast.error("No Coupon Found!");
  }
}

function* getPaymentHistory(action) {
  try {
    var dataToSend = { ...action.payload };
    delete dataToSend.cb;
    const resp = yield call(
      ApiClient.get,
      (action.url =
        ApiPath.StripeIntegration.GET_PAYMNET_HISTORY)
    );
    if (resp.status) {
      yield put(setPaymentHistory(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      // toast.success(action.res.data.msg);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setPaymentHistory({}));
    yield put(onErrorStopLoad());
    toast.error("No Data Found!");
  }
}

function* stripeIntegrationSaga() {
  yield all([
    takeLatest("stripeIntegration/createCustomer", createCustomer),
    takeLatest("stripeIntegration/addPaymentMethod", updatePaymentMethod),
    takeLatest("stripeIntegration/createSubscription", createSubscription),
    takeLatest("stripeIntegration/deleteACard", deleteACard),
    takeLatest('stripeIntegration/checkCoupon', checkCoupon),
    takeLatest('stripeIntegration/getPaymentHistory', getPaymentHistory),
  ]);
}

export default stripeIntegrationSaga;
