import React, { useEffect, useState } from "react";
import * as Images from "../../../../utilities/images";
import { useDispatch } from "react-redux";
import moment from 'moment';
import { allPostComment, commentLike, getNestedComments, userPostComment } from "../../../../redux/slices/dashboard";
import { toast } from 'react-toastify';
import { useAuthSelector } from "../../../../redux/selector/auth";

const CommmentBox = (props) => {
    const dispatch = useDispatch();
    const post_id = props?.id;
    const [allCommentList, setAllCommentList] = useState([]);
    const [allCommentData, setAllCommentData] = useState({});
    const [comment, setComment] = useState("");
    const [page, setPage] = useState(1);

    const authSelector = useAuthSelector();
    const userInfo = authSelector?.userInfo?.user

    const [replyFlag, setReplyFlag] = useState(false)
    const [replyName, setReplyName] = useState('')
    const [replyId, setReplyId] = useState(null)
    const [selectedIndex, setSelectedIndex] = useState(null)

    const handleReplyComent = async (commentId, name, index = null, flag) => {
        getNestedCommentAction(commentId, index)
        if (flag === 'open') {
            if (replyFlag) {
                setReplyFlag(false)
                setReplyName('')
                setReplyId(null)
                setSelectedIndex(null)
            }
            setAllCommentList((prevData) => {
                let data = [...prevData]
                const updatedComment = {
                    ...data[index], show_nested_comment: (data[index]?.show_nested_comment ? false : true)
                };
                data[index] = updatedComment;
                return data;
            })
        }
        else {
            setReplyFlag(true)
            setReplyName(name)
            setReplyId(commentId)
            setSelectedIndex(index)
            setAllCommentList((prevData) => {
                let data = [...prevData]
                const updatedComment = {
                    ...data[index], show_nested_comment: true
                };
                data[index] = updatedComment;
                return data;
            })
        }

    }

    //post all comments
    const getAllPostComment = (page = 1, limit = 10) => {
        let params = {
            id: post_id,
            page: page,
            limit: limit
        };
        dispatch(allPostComment({
            ...params, cb(res) {
                if (res.status) {
                    if (allCommentList.length == 0) {
                        setAllCommentList(res.data.payload?.comments)
                    }
                    else {
                        setAllCommentList((prevData) => {
                            return [
                                ...prevData,
                                ...res.data.payload?.comments
                            ]
                        })
                    }
                    setAllCommentData(res.data.payload);
                    setPage(res.data.payload?.current_page);
                    // props.fetchData()
                }
            }
        }))
    };

    //for post comment...............
    const postComment = () => {
        if (!comment?.length) {
            toast.error("Please enter text to comment");
            return;
        }

        const params = {
            post_id: post_id,
            comment: comment
        }

        if (replyFlag) {
            params.comment_parent_id = replyId
        }

        dispatch(userPostComment({
            ...params, cb(res) {
                if (res.status) {

                    if (replyFlag) {
                        setReplyId(null)
                        setReplyName('')
                        setReplyFlag(false)

                        setAllCommentList((prevData) => {

                            let data = [...prevData]

                            let newReplies

                            if (data[selectedIndex].comment_replies && data[selectedIndex].comment_replies.length == 0) {
                                newReplies = [
                                    res?.data?.payload
                                ]
                            } else {
                                newReplies = [
                                    ...data[selectedIndex].comment_replies,
                                    res?.data?.payload
                                ]
                            }

                            const updatedComment = {
                                ...data[selectedIndex],
                                comment_replies: newReplies,
                                nested_comment_count: parseInt(data[selectedIndex].nested_comment_count) + 1
                            }

                            data[selectedIndex] = updatedComment;

                            return data;
                        })

                        setSelectedIndex(null)

                    }
                    else {
                        setAllCommentList((prevData) => {

                            if (prevData && prevData.length > 0) {
                                return [
                                    res?.data?.payload,
                                    ...prevData
                                ]
                            }
                            else {
                                return [
                                    res?.data?.payload,
                                ]
                            }
                        })

                        document.getElementById('dashboardComments').scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth'
                        })
                    }

                    setComment("");

                    // props.fetchData()
                }
                else {
                }
            }
        }))
    }

    //get nested post comment...............
    const getNestedCommentAction = (commentId, index, page = 1, limit = 10) => {

        let params = {
            id: commentId,
            page: page,
            limit: limit
        };

        dispatch(getNestedComments({
            ...params, cb(res) {
                if (res.status) {

                    setAllCommentList((prevData) => {
                        let data = [...prevData]

                        let nestedComments
                        if (data[index].comment_replies.length == 0 || page == 1) {
                            nestedComments = res?.data?.payload?.comments ?? []
                        }
                        else {
                            nestedComments = [
                                ...data[index].comment_replies,
                                ...res?.data?.payload?.comments
                            ]
                        }

                        const updatedComment = {
                            ...data[index],
                            comment_replies: nestedComments,
                            comment_page_number: res?.data?.payload?.current_page
                        }

                        data[index] = updatedComment;

                        return data;
                    })


                }
            }
        }))
    }


    //for liiking comment...............
    const commentLikeAction = (id, flag, index, reIndex = null) => {

        const params = {
            comment_id: id,
            status: flag
        }

        dispatch(commentLike({
            ...params, cb(res) {
                if (res.status) {


                    if (reIndex == null) {

                        setAllCommentList((prevData) => {
                            let data = [...prevData]

                            const updatedComment = {
                                ...data[index], is_comment_liked: res?.data?.payload?.commentLikeDetails ?? null, total_like_count: res?.data?.payload?.commentDetails?.likeCount ?? 0
                            };
                            data[index] = updatedComment;

                            return data;
                        })

                    }
                    else {

                        setAllCommentList((prevData) => {
                            let data = [...prevData]


                            const newReplies = [
                                ...data[index].comment_replies
                            ]

                            const updatedReplyComment = {
                                ...newReplies[reIndex],
                                is_comment_liked: res?.data?.payload?.commentLikeDetails ?? null, total_like_count: res?.data?.payload?.commentDetails?.likeCount ?? 0
                            }

                            newReplies[reIndex] = updatedReplyComment

                            const updatedComment = {
                                ...data[index],
                                comment_replies: newReplies
                            }

                            data[index] = updatedComment;

                            return data;
                        })

                    }

                }
                else {

                }
            }
        }))
    }

    useEffect(() => {
        getAllPostComment();
        document.title = "Dashboard";
    }, []);
    return (
        <>

            <div className='accountDashboard'>
                <div className="dashboardComments" id="dashboardComments">
                    {allCommentList?.length ?
                        <>
                            {allCommentList.map((allComment, index) => {
                                return (
                                    <> 
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="listComments">
                                                    <div className='comments mt-2 mb-3'>
                                                        <h5 className='headingTitleSmall_ d-flex align-items-center justify-content-end'>
                                                            <span className='commentText mb-2'>{moment(allComment?.created_at).fromNow()}</span>
                                                        </h5>
                                                        <div className='commentReply '>
                                                            <figure>
                                                                <img src={allComment?.user_details?.user_profile?.profile_photo ? allComment?.user_details?.user_profile?.profile_photo : Images.companyProfile2} className='posttedUser me-3' alt='UserImage' />
                                                            </figure>
                                                            <p className="innerSubtextSmall">{allComment?.comment}</p>
                                                        </div>
                                                        <div className='commentReply justify-content-between mt-2'>
                                                            <p className="innerSubtextSmall"><b>{allComment?.user_details?.user_profile?.first_name} {allComment?.user_details?.user_profile?.last_name}</b></p>
                                                            <figure>
                                                                <p className="postrate_">

                                                                    {allComment?.is_comment_liked ?
                                                                        <img className='likepost_ me-2' src={Images.thumbsup} onClick={(e) => { commentLikeAction(allComment.id, 2, index) }} />
                                                                        : <img className='likepost_ me-2' src={Images.greyLike} onClick={(e) => { commentLikeAction(allComment.id, 1, index) }} />
                                                                    }
                                                                    <span>{allComment?.total_like_count ?? 0}</span>

                                                                    <img className='likepost_ me-2' src={Images.message} onClick={(e) => { handleReplyComent(allComment.id, (allComment?.user_details?.user_profile?.first_name + " " + allComment?.user_details?.user_profile?.last_name), index, 'open') }} />

                                                                    <span>{allComment?.nested_comment_count ?? 0}</span>
                                                                </p>
                                                            </figure>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {allComment?.show_nested_comment &&

                                                <div className="col-lg-10 offset-2 commentReplyBox">


                                                    {allComment?.comment_replies?.length > 0 ?
                                                        <>
                                                            {allComment?.comment_replies?.map((reply, reIndex) => {
                                                                return (
                                                                    <div className="row">
                                                                        <div className="col-lg-1 col-md-1 p-0">
                                                                            <img src={Images.commentReply} className='commentReplyIcon img-fluid me-1' alt='UserImage' />
                                                                        </div>
                                                                        <div className="col-lg-11 col-md-11">
                                                                            <div className="listComments commentreply">
                                                                                <div className='comments mt-2 mb-3'>
                                                                                    <h5 className='headingTitleSmall_ d-flex align-items-center justify-content-end'>
                                                                                        <span className='commentText mb-2'>{moment(reply?.created_at).fromNow()}</span>
                                                                                    </h5>
                                                                                    <div className='commentReply '>
                                                                                        <figure>
                                                                                            <img src={reply?.user_details?.user_profile?.profile_photo ? reply?.user_details?.user_profile?.profile_photo : Images.companyProfile2} className='posttedUser me-1' alt='UserImage' />
                                                                                        </figure>
                                                                                        <p className="innerSubtextSmall">{reply?.comment}</p>
                                                                                    </div>
                                                                                    <div className='commentReply justify-content-between mt-2'>
                                                                                        <p className="innerSubtextSmall"><b>{reply?.user_details?.user_profile?.first_name} {reply?.user_details?.user_profile?.last_name}</b></p>
                                                                                        <figure>
                                                                                            <p className="postrate_">
                                                                                                {reply?.is_comment_liked ?
                                                                                                    <img className='likepost_ me-2' src={Images.thumbsup} onClick={(e) => { commentLikeAction(reply.id, 2, index, reIndex) }} />
                                                                                                    : <img className='likepost_ me-2' src={Images.greyLike} onClick={(e) => { commentLikeAction(reply.id, 1, index, reIndex) }} />
                                                                                                }
                                                                                                <span>{reply?.total_like_count ?? 0}</span>
                                                                                            </p>
                                                                                        </figure>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </>
                                                        :
                                                        <>
                                                            <div className="row">
                                                                <div className="col-lg-12"><h6 className="">No comment replies till now</h6></div>
                                                            </div>
                                                        </>
                                                    }


                                                </div>
                                            }

                                            <div className="text-center  flexBox">
                                                <span className='loadMoreText mb-2' onClick={(e) => { handleReplyComent(allComment.id, (allComment?.user_details?.user_profile?.first_name + " " + allComment?.user_details?.user_profile?.last_name), index, 'reply') }} >Reply </span>
                                                {allComment?.show_nested_comment && allComment?.nested_comment_count > allComment?.comment_replies?.length &&
                                                    <span className='loadMoreText mb-2' onClick={() => { getNestedCommentAction(allComment.id, index, (allComment.comment_page_number + 1)) }}>Load More...</span>
                                                }
                                            </div>

                                        </div>
                                    </>
                                )
                            })}

                        </>

                        :
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <h6 className="mt-4">No Comments found</h6>
                            </div>
                        </div>
                    }

                    {allCommentList?.length > 0 && allCommentData.total > allCommentList?.length &&
                        <div className="text-center" onClick={() => { getAllPostComment(page + 1) }} >
                            <i class="las la-angle-double-down loadMore"></i>
                        </div>
                    }
                </div>
                <div className="footerComment">
                    <hr className='topBorder' />
                    <div className="commentBox_" >
                        <h2 className="mainHeading mb-3">Join the Conversation</h2>
                        <div className="row">
                            <div className="col-md-2">
                                <img className="img-fluid posttedUserUnder" src={userInfo?.user_profile?.profile_photo ? userInfo?.user_profile?.profile_photo : Images.motionImg} />
                            </div>

                            <div className="form-group mb-3 col-md-10">
                                {replyFlag &&
                                    <div className="d-flex justify-content-between">
                                        <h6>
                                            Reply to {replyName}
                                        </h6>

                                        <i className="fa fa-times mt-0 cursor-pointer" onClick={() => {
                                            setReplyId(null)
                                            setReplyName('')
                                            setReplyFlag(false)
                                            setSelectedIndex(null)
                                        }}></i>
                                    </div>
                                }
                                <textarea type="text" className="customFormControl" placeholder="contribute your insights" id="inputName" name="post" value={comment} onChange={e => setComment(e.target.value)} />
                                <img className="sendIcon_" src={Images.sendIcon_} onClick={() => { postComment() }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default CommmentBox;