import React, { useEffect, useRef, useState } from 'react'
import CustomModal from "../../components/common/shared/CustomModal";
import * as Images from "../../../utilities/images";
import ReactPaginate from "react-paginate";
import swal from 'sweetalert';
import { toast } from "react-toastify";
import ManageUserModal from './manageUserModal';
import { useDispatch } from 'react-redux';
import { deleteManageUser, getCompanyUsers } from '../../../redux/slices/manageusers';
import { useManageUsersSelector } from '../../../redux/selector/manageUsers';
import moment from 'moment';
import { useAuthSelector } from '../../../redux/selector/auth';
import { getCompanyProfileInfo } from '../../../redux/slices/auth';

const ManageUser = () => {
    const dispatch = useDispatch();
    const toastId = useRef(null);
    const manageUsersSelector = useManageUsersSelector();
    const [key, setKey] = useState(Math.random());
    const [userList, setUserList] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [totalRecords, setTotalRecords] = useState()
    const authSelector = useAuthSelector()
    const users = authSelector?.userInfo?.user?.user_membership_data[0]?.membership_details?.total_users;

    // const number_of_users = authSelector?.userInfo?.user?.user_membership_data[0]?.membership_details?.membership_features ? authSelector?.userInfo?.user?.user_membership_data[0]?.membership_details?.membership_features[0]?.number_of_users : authSelector?.userInfo?.user?.user_membership_data[0]?.membership_details ? authSelector?.userInfo?.user?.user_membership_data[0]?.membership_details?.total_users : 0;

    const chooseBusinessType = authSelector?.userStep?.user?.business_type ?? authSelector?.userStep?.business_type;

    const choosePlan = authSelector?.userStep?.user?.membership_details?.name ?? authSelector?.userStep?.membership_details?.name;

    const [userDeatil, setUserDeatil] = useState();
    const Number_Of_Users = userDeatil?.user_membership_data[0]?.membership_details?.membership_features ? userDeatil?.user_membership_data[0]?.membership_details?.membership_features[0]?.number_of_users : userDeatil?.user_membership_data[0]?.membership_details ? userDeatil?.user_membership_data[0]?.membership_details?.total_users :  0;

    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });


    //for COMPANY PROFILE INFORMATION.......
    const companyProfileInfo = () => {
        dispatch(
            getCompanyProfileInfo({
                cb(res) {
                    if (res.status) {
                        if (res?.data?.payload?.userDetails?.user_company_information !== null) {
                            setUserDeatil(res?.data?.payload?.userDetails)
                        }
                        else {
                            setUserDeatil(res?.data?.payload?.userDetails?.user_selected_company_information)
                        }

                    } else {
                    }
                },
            })
        );
    };

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };

    //on change page pagination
    const handlePageClick = (e) => {
        companyUsers(e.selected + 1);
    };

    //for COMPANY USERS INFORMATION.......
    const companyUsers = (page = 1, limit = 10) => {
        let params = {
            page: page,
            limit: limit,
        }
        dispatch(getCompanyUsers({
            ...params, cb(res) {
                if (res.status) {
                    setUserList(res?.data?.payload?.data)
                    setPageCount(res?.data?.payload?.per_page)
                    setTotalRecords(res?.data?.payload?.total_records)
                }
                else {

                }
            }
        }))
    }
    // delete user
    const DeleteUser = (id) => {
        swal({
            text: `Are you sure want to remove this user?`,
            dangerMode: false,
            buttons: true,
        })
            .then(result => {
                if (result) {
                    const params = {
                        id: id
                    }
                    dispatch(deleteManageUser({
                        ...params, cb(res) {
                            if (res.status) {
                                setPageCount(1)
                                companyUsers();
                            }
                        }
                    }))
                }
            })

    }

    // show only one toast at one time
    const showToast = (msg) => {
        if (!toast.isActive(toastId.current)) {
            toastId.current = toast.error(msg);
        }
    };


    useEffect(() => {
        companyUsers();
        companyProfileInfo();
    }, []);

    const user = users ? users : "0"
    const totalUsersInvited = totalRecords ? totalRecords : "0"

    return (
        <>
            <div className='manageUser'>
                <h3 class="headTxtBlueSmall">Manage Co-worker Accounts</h3>
                <div className='userAvailable'>
                    <p className='mb-0 me-3'><b> {totalUsersInvited} of  {Number_Of_Users}

                        {/* {number_of_users} */}
                    </b> Users Available</p> <button className='primaryBtn'
                        onClick={() => {

                            if (totalUsersInvited >= 2 && choosePlan === "Bronze" && chooseBusinessType === 2) {
                                showToast("Current plan bronze. Please upgrade your membership plan ")
                            }
                            else if (totalUsersInvited >= 6 && choosePlan === "Gold" && chooseBusinessType === 2) {
                                showToast("Current plan Gold. Please upgrade your membership plan ")
                            }
                            else if (totalUsersInvited >= 10 && choosePlan === "Platinum" && chooseBusinessType === 2) {
                                showToast("Current plan Platinum. Please upgrade your membership plan ")
                            }
                            else if (chooseBusinessType === 1 && totalUsersInvited >= 2 && choosePlan === "Bronze") {
                                showToast("Current plan bronze. Please upgrade your membership plan ")
                            }
                            else if (chooseBusinessType === 1 && totalUsersInvited >= 6 && choosePlan === "Gold") {
                                showToast("Current plan Gold. Please upgrade your membership plan ")
                            }
                            else if (chooseBusinessType === 1 && totalUsersInvited >= 10 && choosePlan === "Platinum") {
                                showToast("Current plan Platinum. Please upgrade your membership plan ")
                            }
                            else {
                                setModalDetail({ show: true, flag: "manageUser" });
                                setKey(Math.random());
                            }
                        }}
                    >Invite Co-worker</button>
                </div>
                {manageUsersSelector.loading ?
                    <>
                        <div className="row">
                            <div className="col-sm-12 text-center pt-5 pb-5">
                                <div className="spinner-grow text-success" role="status"></div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className='table-responsive'>
                            <table className='manageUserTable'>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email Address</th>
                                        <th>Position</th>
                                        <th>Action</th>
                                        <th>Member Since</th>
                                    </tr>
                                </thead>
                                {userList?.length ?
                                    <>

                                        {userList?.map((data, index) => {
                                            return (
                                                <>
                                                    <tbody>
                                                        <tr>
                                                            <td>  {data.user_details.user_profile.first_name}</td>
                                                            <td>{data?.user_details?.email}</td>
                                                            <td>{data?.user_details?.user_membership_data[0]?.membership_details?.name}</td>
                                                            <td>
                                                                <img
                                                                    onClick={() => DeleteUser(data?.user_id)}
                                                                    src={Images.trashIcon} alt="icon" className='trashIcon me-2' />
                                                                <img src={Images.greenWarning} alt="icon" className='trashIcon me-2' />
                                                            </td>
                                                            <td>{moment(data?.user_details?.created_at).format("YYYY-MM-DD")}</td>
                                                        </tr>
                                                    </tbody>
                                                </>
                                            )
                                        })
                                        }

                                    </>

                                    : <tr><td colSpan={5}> <b>No Users Found</b></td></tr>
                                }
                            </table>
                        </div>
                    </>
                }
            </div>
            {/* <div className="col-12 ">
                <div className="customPagination mt-4">
                    <div className="pagination">
                        {userList?.length > 0 ? (
                            <ReactPaginate
                                breakLabel=""
                                // nextLabel=">"
                                nextLabel={<i className='fas fa-caret-right'></i>}
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={pageCount}
                                marginPagesDisplayed={0}
                                pageCount={pageCount}
                                previousLabel={<i className='fas fa-caret-left'></i>}
                                // previousLabel="<"
                                renderOnZeroPageCount={null}
                            />
                        ) : ""}
                    </div>
                </div>
            </div> */}
            <CustomModal
                key={key}
                flag={modalDetail.flag}
                // showCustomBackdrop={modalDetail.flag === "manageUser" ? true : false}
                show={modalDetail.show}
                backdrop={modalDetail.flag === "manageUser" ? null : "static"}
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                className={modalDetail.flag === "manageUser" ? "commonWidth customContent" : ""}
                ids={modalDetail.flag === "manageUser" ? "hello" : ""}
                child={
                    modalDetail.flag === "manageUser" ? (
                        <ManageUserModal
                            id={modalDetail.id}
                            companyUsers={() => companyUsers()}
                            close={() => handleOnCloseModal()} />
                    )
                        : ""
                }
                header={
                    modalDetail.flag === "manageUser" ?
                        <h2 className="headingSmall_">
                            Manage User
                        </h2>
                        : ""
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

export default ManageUser