import React, { useCallback, useEffect, useState } from 'react'
import * as Images from "../../../utilities/images";
import { companyLogoUpload, getCompanyProfileInfo, userCompanyProfileUpdate, userProfileUpdate } from '../../../redux/slices/auth';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import Multiselect from 'multiselect-react-dropdown';
import { useAuthSelector } from '../../../redux/selector/auth';
import { diverseSupplierDescription } from '../../../redux/slices/advanceSearch';

const EditCompanyInformation = (props) => {
    const dispatch = useDispatch();
    const authSelector = useAuthSelector()
    const [photo, setPhotoUpload] = useState("");
    const [photoInfo, setPhotoInfo] = useState({
        format: "",
        name: "",
        size: "",
        url: ""
    });

    const [companyDetails, setCompanyDetails] = useState("");
    const [companyWebsite, setCompanyWebsite] = useState("");
    const [companyKeyword, setCompanyKeyword] = useState("");
    const [companyDescription, setCompanyDescription] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [companyStatusData, setCompanyStatusData] = useState("");
    const [selectedDiverseStatus, setSelectedDiverseStatus] = useState([]);
    const [driversSupplierDescriptionId, setDriversSupplierDescriptionId] = useState();
    const [companyOtherDescription, setCompanyOtherDescription] = useState("")

    const selectedValue = selectedDiverseStatus?.map((data) => { return data?.diverse_supplier_description_details });

    // driver supplier description
    const handleChanges = (data) => {
        // setSelectedDiverseStatus(data)
        let arr = [];
        for (let i = 0; i < data.length; i++) { arr.push(data[i].id) }
        setDriversSupplierDescriptionId(arr)
    }


    //for add drivers Supplier Description........
    const SupplierDescription = (id) => {
        setDriversSupplierDescriptionId(id)
        dispatch(diverseSupplierDescription({
            cb(res) {
                if (res.status) {
                    setCompanyStatusData(res?.data?.payload)
                }
            }
        }))
    }


    //onDrop
    const onDrop = useCallback(acceptedFiles => {
        const imageFile = acceptedFiles[0];
        if (!imageFile.name.match(/\.(jpg|jpeg|png|gif|svg)$/)) {
            toast.error("Please select a valid image.");
            return false;
        }
        let params = {
            photo: imageFile,
        }
        dispatch(companyLogoUpload({
            ...params, cb(res) {
                if (res.status) {
                    setPhotoUpload(res?.data?.payload?.url);
                    setPhotoInfo(res?.data?.payload)
                }
                else {
                }
            }
        }))
    }, [])

    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    // company edit profile
    const handleSubmit = (e) => {
        e.preventDefault()
        if (!companyWebsite) {
            toast.error("Please enter company website url");
            return;
        }
        else if (driversSupplierDescriptionId?.includes(16) && !companyOtherDescription) {
            toast.error("please enter Other Description");
            return;
        }
        let params = {
            id: companyDetails?.id,
            company_website: companyWebsite,
            key_words: companyKeyword,
            company_service_description: companyDescription,
            diverse_supplier_description: driversSupplierDescriptionId,
            company_name: companyName
        };

        if (photoInfo.name != "") {
            params = {
                ...params,
                company_logo: photoInfo?.url ? photoInfo?.url : "",
                company_logo_size: photoInfo?.size ? photoInfo?.size : "",
                company_logo_name: photoInfo?.name ? photoInfo?.name : ""
            }
        }

        if (companyOtherDescription) {
            params = {
                ...params,
                diverse_supplier_description_other: companyOtherDescription,
            }
        }

        dispatch(userCompanyProfileUpdate({
            ...params, cb(res) {
                if (res.status) {
                    props.refreshCompanyDetail();
                    props.close();
                }
            }
        }))
    };

    const handleChange = (e, flag) => {
        const { value } = e.target;
        if (flag == "website") {
            setCompanyWebsite(value);
        }
        else if (flag == "keywords") {
            setCompanyKeyword(value);
        }
        else if (flag == "description") {
            setCompanyDescription(value);
        }
        else if (flag == "companyName") {
            setCompanyName(value);
        }
    }

    //for COMPANY PROFILE INFORMATION.......
    const companyProfileInfo = () => {
        dispatch(getCompanyProfileInfo({
            cb(res) {
                if (res.status) {
                    if (res?.data?.payload?.userDetails?.user_company_information !== null) {
                        setCompanyDetails(res?.data?.payload?.userDetails?.user_company_information)
                        setCompanyWebsite(res?.data?.payload?.userDetails?.user_company_information?.company_website)
                        setCompanyKeyword(res?.data?.payload?.userDetails?.user_company_information?.key_words)
                        setCompanyDescription(res?.data?.payload?.userDetails?.user_company_information?.company_service_description)
                        setCompanyName(res?.data?.payload?.userDetails?.user_company_information?.company_name)
                        setSelectedDiverseStatus(res?.data?.payload?.userDetails.user_company_information.company_diverse_supplier_description)
                        const selectedValuesDiverseStatus = res?.data?.payload?.userDetails.user_company_information.company_diverse_supplier_description?.map((data) => { return data?.diverse_supplier_description })
                        setDriversSupplierDescriptionId(selectedValuesDiverseStatus)
                        setPhotoInfo(detail => ({
                            ...detail,
                            url: res?.data?.payload?.userDetails?.user_company_information?.company_logo,
                        }));
                    }
                    else {
                        setCompanyDetails(res?.data?.payload?.userDetails?.user_selected_company_information?.user_company_information)
                        setCompanyWebsite(res?.data?.payload?.userDetails?.user_selected_company_information?.user_company_information?.company_website)
                        setCompanyKeyword(res?.data?.payload?.userDetails?.user_selected_company_information?.user_company_information?.key_words)
                        setCompanyDescription(res?.data?.payload?.userDetails?.user_selected_company_information?.user_company_information?.company_service_description)
                        setCompanyName(res?.data?.payload?.userDetails?.user_selected_company_information?.user_company_information?.company_name)
                        setSelectedDiverseStatus(res?.data?.payload?.userDetails?.user_selected_company_information.user_company_information.company_diverse_supplier_description)
                        const selectedValuesDiverseStatus = res?.data?.payload?.userDetails?.user_selected_company_information?.user_company_information.company_diverse_supplier_description?.map((data) => { return data?.diverse_supplier_description })
                        setDriversSupplierDescriptionId(selectedValuesDiverseStatus)
                        setPhotoInfo(detail => ({
                            ...detail,
                            url: res?.data?.payload?.userDetails?.user_selected_company_information?.user_company_information?.company_logo,
                        }));
                    }
                }
                else {

                }
            }
        }))
    }

    useEffect(() => {
        companyProfileInfo();
        SupplierDescription();
    }, []);

    return (
        <>

            {authSelector.userDeatil ?
                <>
                    <div className="row">
                        <div className="col-sm-12 text-center pt-5 pb-5">
                            <div className="spinner-grow text-success" role="status"></div>
                        </div>
                    </div>
                </>
                :
                <>
                    <div className='row'>
                        <form onSubmit={(e) => handleSubmit(e)} id="edit-company-info" className='signupForm row justify-content-center mt-3'>
                            <div className='form-group col-md-12 mb-2 text-center'>
                                <div class="custom-file-input">
                                    <div {...getRootProps({ className: "dropzone" })} >
                                        <input {...getInputProps()} />
                                        <img src={photoInfo?.url ? photoInfo?.url : Images.puzzleProfile} alt='AddDocument' className='me-2 uploadProfile' />
                                        <span class="upload-icon">
                                            <img src={Images.adddocument} alt='AddDocument' className='me-2 uploadprofileIcon' />
                                        </span>
                                    </div>

                                </div>
                            </div>
                            <div className='form-group col-md-12 mb-2'>
                                <label className='postopportunity_label'>Website</label>
                                <input type="text" value={companyWebsite} className='customFormControl' onChange={(e) => handleChange(e, "website")} placeholder='' />
                            </div>
                            <div className='form-group col-md-12 mb-2'>
                                <label className='postopportunity_label'>Keywords</label>
                                <input type="text" value={companyKeyword} className='customFormControl' onChange={(e) => handleChange(e, "keywords")} placeholder='' />
                            </div>
                            <div className='form-group col-md-12 mb-2'>
                                <label className="headingSmall_ mb-2" htmlFor="">Diverse Status</label>
                                {companyStatusData &&
                                    <Multiselect
                                        options={companyStatusData} // Options to display in the dropdown
                                        selectedValues={selectedValue} // Preselected value to persist in dropdown
                                        onSelect={handleChanges} // Function will trigger on select event
                                        onRemove={handleChanges} // Function will trigger on remove event
                                        displayValue="name" // Property name to display in the dropdown options
                                    />
                                }
                                {driversSupplierDescriptionId?.includes(16) ?

                                    <div className='form-group col-md-12 mt-3'>
                                        <label className="headingSmall_ mb-2" htmlFor="">Diverse Status Other</label>
                                        <input name="companyDescription" type='text' className='customFormControl' value={companyOtherDescription} placeholder='Other Description' disabled="" onChange={e => setCompanyOtherDescription(e.target.value)} />
                                    </div> : ""

                                }
                            </div>
                            <div className='form-group col-md-12 mb-2'>
                                <label className='postopportunity_label'>Company Description</label>
                                <textarea type="text" value={companyDescription} className='customFormControl' onChange={(e) => handleChange(e, "description")} placeholder='' />
                            </div>
                            <div className='form-group col-md-12 mb-2'>
                                <label className='postopportunity_label'>Company Name</label>
                                <textarea type="text" value={companyName} className='customFormControl' onChange={(e) => handleChange(e, "companyName")} placeholder='' />
                            </div>
                            {/* <div className='modal-footer'>
                                <div className='button_box text-center  my-2'>
                                    <button className='primaryBtn mx-2' type="submit">
                                        Save
                                    </button>
                                    <button className='subsmallBtnblue_ mx-2' onClick={() => props.close()}  >Cancel</button>
                                </div>
                            </div> */}
                        </form>
                    </div>
                </>
            }
        </>
    )
}

export default EditCompanyInformation