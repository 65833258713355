import React, { useEffect, useState } from "react";
import Payment from "./payment";
import CustomModal from "../../components/common/shared/CustomModal";
import * as Images from "../../../utilities/images";
import {
  getCompanyProfileInfo,
  setUserProfileUpdate,
  userInfoUpdate,
} from "../../../redux/slices/auth";
import { useDispatch } from "react-redux";
import moment from "moment";
import Plans from "./plans";
import {
  getApplyPromoCode,
  getMemberShipPlans,
  getPaymentSession,
  verifySession,
} from "../../../redux/slices/dashboard";
import { toast } from "react-toastify";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useAuthSelector } from "../../../redux/selector/auth";
import { cancelcurrentMemberShip } from "../../../redux/slices/paymentPlans";
import Cards from "../registration/cards";
import swal from "sweetalert";
import { getPaymentHistory } from "../../../redux/slices/stripeIntegration";
import { useDashboardSelector } from "../../../redux/selector/dashboard";
const Subscription = () => {
  const [selectedCard, setSelectedCard] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const authSelector = useAuthSelector();
  const dispatch = useDispatch();
  const [key, setKey] = useState(Math.random());
  const [loading, setLoading] = useState(false);
  const [memberShipDetails, setMemberShipDetails] = useState("");
  const [memberShipPaymentHistory, setMemberShipPaymentHistory] = useState("");
  const [userDetails, setUserDetails] = useState(null);

  const navigate = useNavigate();

  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const chooseBusinessType =
    authSelector?.userStep?.user?.business_type ??
    authSelector?.userStep?.business_type;

  var formattedDate = moment(memberShipDetails?.created_at).format(
    "MMMM D, YYYY"
  );

  var dueDate = moment(memberShipDetails?.end_date).format("MMMM D, YYYY");

  const Businesstype = authSelector?.userInfo?.user?.business_type;

  const queryParams = new URLSearchParams(window.location.search);

  const resultIndicator = queryParams.get("resultIndicator");
  const sessionVersion = queryParams.get("sessionVersion");
  const checkoutVersion = queryParams.get("checkoutVersion");
  const dashboard = useDashboardSelector()

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  useEffect(() => {
    if (resultIndicator && sessionVersion) {
      verifySess(resultIndicator);
    }
  }, [resultIndicator && sessionVersion]);

  const verifySess = (resultIndicator) => {
    const params = {
      status: 2,
      success_token: resultIndicator,
    };

    dispatch(
      verifySession({
        ...params,
        cb(res) {
          if (res.status == 200) {
            if (res.data?.payload?.status == 2) {
              toast.success("Payment updated successfully");
              companyProfileInfo();
              navigate("/settings", { state: { activeTab: "subscription" } });
            } else {
              toast.warning("Something went wrong");
            }
          } else {
            toast.warning("Something went wrong");
          }
        },
      })
    );
  };

  const getAllPayments = () => {
    if (chooseBusinessType === 2) {
      dispatch(
        getPaymentHistory({
          cb(res) {
            if (res.status) {
              setMemberShipPaymentHistory(res?.data?.payload?.data);
            } else {
            }
          },
        })
      );
    }
  };

  //for COMPANY PROFILE INFORMATION.......
  const companyProfileInfo = () => {
    dispatch(
      getCompanyProfileInfo({
        cb(res) {
          if (res.status) {
            setMemberShipDetails(res?.data?.payload?.userDetails?.currentPlan);
            setUserDetails(res?.data?.payload?.userDetails);
            // setMemberShipPaymentHistory(res?.data?.payload?.userDetails?.user_membership_data)
            dispatch(userInfoUpdate(res?.data?.payload?.userDetails));
          } else {
          }
        },
      })
    );
  };

  const proceedPayment = () => {
    // setLoading(true)
    const currentUrl = window.location.href;
    const baseUrl =
      window.location.protocol +
      "//" +
      window.location.hostname +
      (window.location.port ? ":" + window.location.port : "");

    let params = {
      authentication_time: 15,
      amount: memberShipDetails.membership_details.later_cost,
      description: "Test",
      returnUrl: `${currentUrl}?active=subscription`,
      cancelUrl: `${baseUrl}/paymentCancelled?fromInside=true`,
      timeoutUrl: `${baseUrl}/paymentCancelled?fromInside=true`,
      membership_id: memberShipDetails.membership_details.id,
    };

    dispatch(
      getPaymentSession({
        ...params,
        cb(res) {
          if (res.status == 200) {
            window.location.href = res.data?.payload?.url ?? currentUrl;
          } else {
            toast.warning("Something went wrong");
          }
        },
      })
    );
  };

  // cancel membership

  const cancelMemberShip = () => {
    swal({
      // title: "Are you sure?",
      text: `Are you sure want to cancel the subscription?`,
      // icon: "warning",
      dangerMode: false,
      buttons: true,
    }).then((result) => {
      if (result) {
        setLoading(true);
        let params = {};
        dispatch(
          cancelcurrentMemberShip({
            ...params,
            cb(res) {
              if (res.status == 200) {
                toast.success("Membership cancelled successfully !!");
                companyProfileInfo();
              } else {
                toast.warning("Something went wrong");
              }
              setLoading(false);
            },
          })
        );
      }
    });
  };

  //format number to dollars
  function formatNumberToDollars(number) {
    // Convert the number to a string
    const numberString = number.toString();
    // Add commas to the number for thousands
    const formattedNumber = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // Add a "$" symbol in front of the formatted number
    return "$" + formattedNumber;
  }

  const applyPromoCode = () => {
    if (!promoCode || promoCode.trim() === "") {
      toast.error("Please enter a valid activation code");
      return;
    }

    let params = {
      voucher_code: promoCode,
    };
    dispatch(
      getApplyPromoCode({
        ...params,
        cb(res) {
          setPromoCode("");
          if (res.status == 200) {
            setPromoCode("");
            companyProfileInfo();
          } else {
            toast.warning("Something went wrong");
          }
        },
      })
    );
  };
  useEffect(() => {
    companyProfileInfo();
    getAllPayments();
  }, []);

  return (
    <div className="containerBox">
      <div className="subscription">
        {/* <div className='d-flex justify-content-between'> */}
        <h3 className="headTxtBlueSmall mb-4">Membership Details </h3>
        {/* <p className='enterPriceSub'>{moment(memberShipDetails?.start_date).format('MMM D, YYYY')} to  {moment(memberShipDetails?.end_date).format('MMM D, YYYY')}</p> */}
        {/* </div> */}
        <div className="row">
          <div className="col-lg-6">
            <h5 className="headingBlack21 mb-3"> Current Plan</h5>
            <div className="startUps flexBox">
              <p className="startupHeading">
                {memberShipDetails?.membership_details?.name}
              </p>
              {chooseBusinessType === 2 ? (
                <p className="startupHeading">
                  {memberShipDetails?.membership_details?.one_year_cost} $ /
                  Quarterly
                </p>
              ) : (
                <></>
              )}
              {chooseBusinessType === 1 ? (
                <p className="startupHeading">
                  {new Date(memberShipDetails?.start_date)?.getFullYear() === new Date(memberShipDetails?.end_date)?.getFullYear()  ? "Trial": "Yearly"}
                </p>
              ) : (
                <></>
              )}

              {/* <p className='startupHeading'>{formatNumberToDollars(memberShipDetails?.membership_details?.later_cost ?? 0)} / 3 months</p> */}
            </div>
            <div className="dropdown mt-3">
              {chooseBusinessType === 2 ? (
                <>
                  {" "}
                  <button
                    className="updatePlanBtn_"
                    type="button"
                    id="dropdownMenuButtons"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    // onClick={() => {
                    //     if (userDetails?.nextPlan) {
                    //         toast.dismiss()
                    //         toast.warning('Cannot update plan level, as you already have next plan in queue')
                    //         return
                    //     }

                    //     setModalDetail({ show: true, flag: "plans" });
                    //     setKey(Math.random());
                    // }}
                  >
                    <div className="dFlex">
                      <span className="d-inline-block">Update Plan Level</span>
                      <img src={Images.downDark} />
                    </div>
                  </button>{" "}
                </>
              ) : (
                ""
              )}

              {/* <ul
                class="dropdown-menu w-100"
                aria-labelledby="dropdownMenuButtons"
              >
                <li>
                  <Link className="dropdown-item">All Members</Link>
                </li>
                <li>
                  <Link className="dropdown-item">Connected Members Only</Link>
                </li>
              </ul> */}
            </div>
            <p className="readPlans mt-2 ms-1">
              <span
                onClick={() => {
                  navigate("/membership-choose-per-role");
                }}
                to="#"
              >
                Click here
              </span>{" "}
              to read about plan’s we offer.
            </p>
            <div className="d-flex justify-content-between mt-4">
              <p className="enterPriceSub mb-0">
                Valid Upto{" "}
                {moment(memberShipDetails?.start_date).format("MMM D, YYYY")} to{" "}
                {moment(memberShipDetails?.end_date).format("MMM D, YYYY")}
              </p>
              {chooseBusinessType === 2 ? (
                <>
                  {" "}
                  <h3 className="headTxtBlueSmall fw-500">
                    {memberShipDetails?.membership_details?.one_year_cost}$
                  </h3>
                </>
              ) : (
                ""
              )}
            </div>

            {/* <div className='flexBox startUpMain cursorPointer'  
                            onClick={() => {
                                if (userDetails?.nextPlan) {
                                    toast.dismiss()
                                    toast.warning('Cannot update plan level, as you already have next plan in queue')
                                    return
                                }
 
                                setModalDetail({ show: true, flag: "plans" });
                                setKey(Math.random());
                            }}
                        >
                            <p className="startupmoreHeading">Update Plan Level</p>
                            <img src={Images.startUpMore} className='img-fluid startUpMore' alt='startUpMore' />
                        </div> */}

            {/* {userDetails && userDetails?.nextPlan ?
                            <>
                                <div className='flexBox mt-5'>
                                    <article>
                                        <h6 className='paymentHeading'>Upcoming Plan </h6>
                                        <p className='smallDeatils'><strong>{userDetails?.nextPlan?.membership_details?.name}</strong> :
                                            Starting on {moment(userDetails?.nextPlan?.start_date).format("MMMM D, YYYY")} and
                                            due on {moment(userDetails?.nextPlan?.end_date).format("MMMM D, YYYY")} then yearly.</p>
                                    </article>
                                    <article>
                                        <h6 className='paymentSubheading'>{formatNumberToDollars(userDetails?.nextPlan?.membership_details?.later_cost ?? 0)}</h6>
                                    </article>
                                </div>
                            </>
                            :
                            <>
                                <div className='flexBox mt-5'>
                                    <article>
                                        <h6 className='paymentHeading'>Payment</h6>
                                        <p className='smallDeatils'>Due on {dueDate} then yearly.</p>
                                    </article>
                                    <article>
                                        <h6 className='paymentSubheading'>{formatNumberToDollars(memberShipDetails?.membership_details?.later_cost ?? 0)}</h6>
                                    </article>
                                </div>
                            </>
                        } */}

            {/* {
                            (dueDate > moment().format('YYYY-MM-DD HH:mm:ss'))
                                ?
                                <button className='primaryBtn w-100 mt-4 mb-2 disabled' disabled title='You can proceed payment after next due date'>Proceed Payment</button>
                                :
                                <>
                                    {loading ?
                                        <>
                                            <div className='text-center mt-5'>
                                                <div class="spinner-border text-success" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <button className='primaryBtn w-100 mt-4' onClick={() => { proceedPayment() }}>Proceed Payment</button>
                                    }
                                </>
                        } */}
            {/* {
                            userDetails?.membership_status === 2 || userDetails?.membership_status === 0 ?
                                <>
                                    <button className='primaryBtn w-100 mt-4' >Buy MemberShip</button>


                                </>
                                :
                                <>
                                    {

                                        <button className='primaryBtn w-100 mt-4' onClick={() => { cancelMemberShip() }} >
                                            {loading ?
                                                <div className="spinner-border text-success ms-3" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div> : "Cancel MemberShip"}

                                        </button>
                                    }
                                </>

                        } */}

            {chooseBusinessType === 1 ? (
              ""
            ) : (
              <Cards
                called="settings"
                setSelectedCard={setSelectedCard}
                selectedCard={selectedCard}
                customerId={customerId}
                setCustomerId={setCustomerId}
                setModalDetail={setModalDetail}
                modalDetail={modalDetail}
              />
            )}
          </div>

          <div className="col-lg-6 paymentScrolled">
            {chooseBusinessType === 2 ? (
              ""
            ) : (
              <>
                <h5 className="headingBlack21 mb-3">
                  Activation / Promotional Code
                </h5>
                <div className="promocodeOuter mb-5">
                  <input
                    value={promoCode}
                    onChange={(e) => setPromoCode(e?.target?.value)}
                    type="test"
                    placeholder="Enter activation code"
                    className="promoSearch"
                  />
                  <button onClick={() => applyPromoCode()} disabled={dashboard?.promoCodeLoading} className="promoBtn">
                    {dashboard?.promoCodeLoading ? <span className="spinner-border spinner-border-sm"></span>:"Apply"}
                  </button>
                </div>
              </>
            )}

            {chooseBusinessType === 1 ? (
              ""
            ) : (
              <>
                <h5 className="headingBlack21 mb-3">Payment History</h5>
                {memberShipPaymentHistory?.length > 0 ? (
                  <>
                    {memberShipPaymentHistory.map((paymentHistory, index) => {
                      var date = new Date(paymentHistory?.created * 1000);
                      return (
                        <div key={index} className="historyBox flexBox">
                          <p className="historyText">
                            {moment(date).format("MMMM D, YYYY")}
                          </p>
                          <article className="flexBox">
                            <span className="me-3 historyPrice">
                              {formatNumberToDollars(
                                paymentHistory.total / 100 ?? 0
                              )}
                            </span>
                            <figure>
                              <img
                                src={Images.deatilIcon}
                                className="img-fluid deatilIcon"
                                alt="deatilIcon"
                              />
                            </figure>
                          </article>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  ""
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={
          modalDetail.flag === "payment"
            ? "commonWidth customContent"
            : modalDetail.flag === "plans"
            ? "commonWidth customContent"
            : ""
        }
        ids={
          modalDetail.flag === "payment"
            ? "payment"
            : modalDetail.flag === "plans"
            ? "plans"
            : ""
        }
        child={
          modalDetail.flag === "payment" ? (
            <Payment close={() => handleOnCloseModal()} />
          ) : modalDetail.flag === "plans" ? (
            <Plans close={() => handleOnCloseModal()} />
          ) : (
            ""
          )
        }
        header={
          <div className="modalHeader_">
            <div className="common_">
              {modalDetail.flag === "payment" ? (
                <>
                  <h2 className="headingSmall_">Credit Card</h2>
                </>
              ) : (
                ""
              )}
              {modalDetail.flag === "plans" ? (
                <>
                  <h2 className="headingSmall_">
                    Select plan to upgrade or degrade membership
                  </h2>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </div>
  );
};

export default Subscription;
