import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  plan: {},
  couponInfo: {},
  paymentHistory: {},
  paymentHistoryLoding: {},
};

export const stripeIntegrationSlice = createSlice({
  name: "stripeIntegration",
  initialState,
  reducers: {
    createCustomer: (state) => {
      state.customerLoading = true;
    },
    setCreateCustomer: (state, action) => {
      state.customerLoading = false;
    },
    onErrorStopLoad: (state) => {
      state.loading = false;
      state.customerLoading = false;
    },
    addPaymentMethod: (state) => {
      state.loading = true;
    },
    setPaymentMethod: (state) => {
      state.loading = false;
    },
    createSubscription: (state) => {
      state.loading = true;
    },
    setCreateSubscription: (state) => {
      state.loading = false;
    },
    setPlanId: (state, action) => {
      state.loading = false;
      state.plan = action.payload;
    },
    deleteACard: (state) => {
      state.customerLoading = true;
    },
    setDeletedCard: (state) => {
      state.customerLoading = false;
    },
    checkCoupon: (state) => {
      state.loading = true;
    },
    setCouponInfo: (state, action) => {
      state.loading = false;
      state.couponInfo = action.payload;
    },
    getPaymentHistory: (state) => {
      state.paymentHistoryLoding = true;
    },
    setPaymentHistory: (state, action) => {
      state.customerLoading = false;
      state.paymentHistoryLoding = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  createCustomer,
  setCreateCustomer,
  onErrorStopLoad,
  addPaymentMethod,
  setPaymentMethod,
  createSubscription,
  setCreateSubscription,
  setPlanId,
  deleteACard,
  checkCoupon,
  setCouponInfo,
  setDeletedCard,
  getPaymentHistory,
  setPaymentHistory,
} = stripeIntegrationSlice.actions;

export default stripeIntegrationSlice.reducer;
