import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useOpportunitySelector } from "../../../redux/selector/opportunity";
import { myPostOpportunity, deleteOpportunity, activeInactiveOppertunity } from '../../../redux/slices/opportunity';
import { updateArchive } from '../../../redux/slices/opportunity/archiveOpportunity';
import ReactPaginate from "react-paginate";
import {AdvReadMoreMore } from "read-more-more";
import swal from 'sweetalert';
import { Navigate } from 'react-router-dom';

const InActiveOpportunity = (props) => {
    const dispatch = useDispatch();
    const opportunitySelector = useOpportunitySelector();
    const [inActiveOpportunitiesList, setinActiveOpportunitiesList] = useState('');
    const [pageCount, setPageCount] = useState(1);


    // for pass the EditOpportunities Id..............
    const editOpportunities = (id) => {
        props.setActiveTab("editOpportunity");
        props.setEditOpportunitiesId(id)
    }

    // API for get MyOpportunities ............
    const inActiveOpportunityList = (page = 1, limit = 10) => {
        let params = {
            is_active: 0,
            page: page,
            limit: limit,
        }
        dispatch(myPostOpportunity({
            ...params, cb(res) {
                if (res.status) {
                    setinActiveOpportunitiesList(res?.data?.payload?.data)
                    setPageCount(res?.data?.payload?.total_pages)
                }
            }
        }))
    }

    // API for active Opportunities ..............
    const activeOpportunities = (id, flag) => {
        let params = {
            id: id,
            is_active: flag,
        }
        dispatch(activeInactiveOppertunity({
            ...params, cb(res) {
                if (res.status) {
                    inActiveOpportunityList()
                }
            }
        }))
    }

        // API for archive Update ..............
        const archiveUpdate = (id, flag) => {
            let params = {
                id: id,
                is_archive: flag
            }
            dispatch(updateArchive({
                ...params, cb(res) {
                    if (res.status) {
                        inActiveOpportunityList()
    
                    }
                }
            }))
        }

        // API for delete Opportunities ..............
    const deleteOpportunities = (id) => {
        swal({
            // title: "Are you sure?",
            text: `Are you sure want to remove  this opportunity?`,
            // icon: "warning",
            dangerMode: false,
            buttons: true,
        })
            .then(result => {
                if (result) {
                    let params = {
                        id: id
                    }
                    dispatch(deleteOpportunity({
                        ...params, cb(res) {
                            if (res.status) {
                                inActiveOpportunityList()
                            }
                        }
                    }))

                }
            });
    }
      //on change page pagination
      const handlePageClick = (e) => {
        inActiveOpportunityList(e.selected + 1);
      };
 //on Responce
 const handleResponce = () => {
    Navigate('/create-opportunities', { state: { activeTab: "reviewapplication" } })
  };


    useEffect(() => {
        inActiveOpportunityList()
        document.title = "opportunities";
    }, []);

    return (
        <>
            <div className='activeOpportunity_'>
                <h3 className="headingTitle_ mb-4">Inactive Opportunities</h3>
                {opportunitySelector.loading ?
                    <>
                        <div className="row">
                            <div className="col-sm-12 text-center pt-5 pb-5">
                                <div className="spinner-grow text-success" role="status"></div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        {inActiveOpportunitiesList?.length ?
                            <>
                                {inActiveOpportunitiesList?.map((data, index) => {
                                    return (
                                        <>
                                            <div className='postContainerArea post-container-area' key={index}>
                                                <div className='posts align-items-center justify-content-between'>
                                                    <div className='leftOpportunitycontent'>
                                                        <p className='postHours_ mb-2'>{moment(data?.created_at).fromNow()}</p>
                                                        <h6 className='opportunityHead_ '>{data?.posting_title.length > 13.5 ? data?.posting_title.substring(0,13.5) + "...." : data?.posting_title}</h6>
                                                        {/* <h6 className='listHead_'>Fixed Term Contract</h6> */}
                                                
                                                        <h6 className='contractLength'>{data?.currency === "USD" || data?.currency == "CAD" ? `$` : data?.currency == "EURO" ? `€` : ""}{data?.opportunity_amount ? data?.opportunity_amount.toLocaleString() : ""} {data?.currency === "USD " || data?.currency == "CAD " ? `${(data?.currency)}` : `${(data?.currency)}`} {(data?.contract_length === 0 || data?.currency === "" ) ? " " : "/ "}
                                                        {data?.contract_length === 1 ? "Yearly" : data?.contract_length === 2 ? "Monthly" :
                                                            data?.contract_length === 3 ? "Weekly" : data?.contract_length === 4 ? "Daily" : data?.contract_length === 5 ? "Hourly" : ""}
                                                        </h6>
                                                    </div>
                                                    <div className='postsDetails'>
                                                    {/* <AdvReadMoreMore text={data?.description} linesToShow={3}
                                                            
                                                            checkFor={100}
                                                            transDuration={2} /> */}
                                                        <p className='innerSubtextSmall wordLimit text-start'>{data?.description}</p>
                                                    </div>
                                                    <div className='opportunityBtn_'>
                                                        <button onClick={() => activeOpportunities(data?.id, 1)} className="subsmallBtnblue_ mb-2">Active</button>
                                                        <div className="dropdown">
                                                            <button className="subsmallBtnblack_ mb-2" type="button" id="dropdownMenuButtons" data-bs-toggle="dropdown" aria-expanded="false">
                                                                More
                                                            </button>
                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButtons">
                                                                <li><a className="dropdown-item" onClick={() => archiveUpdate(data?.id, 1)} >Archive</a></li>
                                                                <li><a className="dropdown-item" onClick={() => handleResponce()}>Responses</a></li>
                                                                <li><a className="dropdown-item" onClick={() => deleteOpportunities(data?.id)}>Delete</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                                }
                            </>
                            : <b>No Active Opportunity Found</b>
                        }
                    </>
                }
                <div className="col-12 ">
                    <div className="customPagination mt-4">
                        <div className="pagination">
                            {inActiveOpportunitiesList?.length > 0 ? (
                                <ReactPaginate

                                    breakLabel=""
                                    // nextLabel=">"
                                    nextLabel={<i className='fas fa-caret-right'></i>}
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={pageCount}
                                    marginPagesDisplayed={0}
                                    pageCount={pageCount}
                                    previousLabel={<i className='fas fa-caret-left'></i>}
                                    // previousLabel="<"
                                    renderOnZeroPageCount={null}
                                />
                            ) : ""}
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default InActiveOpportunity