import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as images from "../../../utilities/images";


const MemberShipChoosePerRole = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const roleId = location?.state?.id;
    const [activeTab, setActiveTab] = useState("");
    const [id, setId] = useState("");

    //tab click
    const tabClick = (id) => {
        setId(id)
    }

    const handleClickPage = (flag) => {
        if (flag === 1) {
            navigate(`/enterprise-business`, { state: { id: 1 } })
        }
        else {
            navigate(`/small-business`, { state: { id: 2 } })   

        }
    }

    return (
        <>
            <div className='container'>
                <div className="row justify-content-center mt-5 mb-5">
                    <div className="col-md-10 text-center">
                        <div className="detailsContainer">
                            <h3 className="headTxt mt-3 mb-4">Which best describes your business?</h3>
                            <form>
                                <div className='row justify-content-center'>
                                    <div onClick={() => handleClickPage(1)} className='col-md-6 col-lg-5 mb-3'>
                                        <div className={activeTab == 'enterpriseBusiness' ? "rolesContainer active" : "rolesContainer"} onClick={() => setActiveTab("enterpriseBusiness")}>
                                            <img src={images.buyer} alt='Buyer' className='img-fluid' />
                                            <h4 className="heading_ mt-3 mb-3">Enterprise Business</h4>
                                            <p className='textInner'>Annual Gross Revenue $5 Million +</p>
                                        </div>
                                    </div>
                                    <div onClick={() => handleClickPage(2)} className='col-md-6 col-lg-5 mb-3'>
                                        <div className={activeTab == 'smallBusiness' ? "rolesContainer active" : "rolesContainer"} onClick={() => setActiveTab("smallBusiness")}>
                                            <img src={images.supplier} alt='Supplier' className='img-fluid' />
                                            <h4 className="heading_ mt-3 mb-3">Small Business</h4>
                                            <p className='textInner'>`{"Annual Gross Revenue < $5 Million"}`</p>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MemberShipChoosePerRole