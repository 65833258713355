import React, { useEffect, useState } from 'react'
import * as Images from "../../../utilities/images";
import { useLocation, useSearchParams } from 'react-router-dom';

const ArticleSideBar = (props) => {

    const [search, setSearch] = useState("")
    const [categoryId, setCategoryId] = useState("")
    const location = useLocation()
    const [activeTab, setActiveTab] = useState(location?.state?.categoryName ?? "")

    const handleCategories = (e, id) => {
        e.preventDefault()
        setCategoryId(id)
        setSearch("")
        props?.setSearchInput("")
        props?.getCategoryData(id)
        props?.setShowCategoryData(false)
        props?.setArticleShowInfo(false)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        props?.setSearchInput(search)
        props?.getAllUserWithSearch(categoryId, search)
        props?.setShowCategoryData(true)
        // props?.getCategoryData(categoryId, search)
    }

    const onChangeReset = (e) => {
        e.preventDefault()
        setSearch("")
        props?.setSearchInput("")
        props?.setShowCategoryData(false)
        props?.setArticleShowInfo(false)
    }

    useEffect(() => {
        setCategoryId(location?.state?.category_id)
    }, [])

    return (
        <div className='col-md-4'>
            <div className='helpDeskQueRight'>
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className='helpQueCanHelp'>
                        <input
                            type="text"
                            placeholder="How can we help?"
                            aria-label="Search"
                            value={search}
                            aria-describedby="search-addon"
                            onChange={(e) => {
                                e.preventDefault()
                                setSearch(e.target.value)
                            }}
                        />
                        {
                            search !== "" ?
                                <button className="p-0" type="reset" onClick={(e) => {
                                    onChangeReset(e)
                                }}> <img src={Images.crossIcon} className='helpQueSearch' /> </button>
                                :
                                <button className="p-0" type="button">
                                    <img src={Images.searchIcon_} alt="" className='helpQueSearch' />
                                </button>
                        }
                    </div>
                </form>
                <h5 className='heading27'>Recommended topics</h5>
                <ul className='recommendedList mt-3' >
                    {props?.categories?.map((cate, index) => (
                        <li className={`recommendedItem ${activeTab === cate?.name ? "active" :""}`}key={index} onClick={(e) => {
                            setActiveTab(cate?.name)
                            handleCategories(e, cate?.id)}}><span>{cate?.name}</span></li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default ArticleSideBar