import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Images from "../../../utilities/images";
import MessageInbox from "../messageInbox";
import { useDispatch } from "react-redux";
import AddLabels from "./AddLabels";
import Drafts from "../messageDrafts";
import moment from "moment";
import { toast } from "react-toastify";
import ComposeMessage from "./ComposeMessages";
import CustomModal from "../../components/common/shared/CustomModal";
import { useMessagesSelector } from "../../../redux/selector/messages";
import swal from 'sweetalert';
import {
  getChatHead,
  getChatInfo,
  getLabels,
  chatDelete,
  messageDelete,
  deleteLabels,
  assignLabels,
} from "../../../redux/slices/messages";
import {
  uploadAllDocuments,
  myDocuments,
} from "../../../redux/slices/documents";
import { useAuthSelector } from "../../../redux/selector/auth";
import Trash from "../messageTrash";
import { userPhotoUpload } from "../../../redux/slices/auth";
import SentMessages from "../sentMessages";
import MessageRequests from "../messageRequests";
import Conversation from "./conversation";
import MessageSideBar from "./MessageSideBar";
import { getSocket, closeSocket } from "../../../app/components/socketNew";

const AccountMessage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const inputRef = useRef(null);
  // get the redirect data 

  // console.log(socketInstance, "socketinstanceeeeeeeeeeeeeeeeeeeeeeeeeeee");

  const name = location?.state;
  const messagesEndRef = useRef(null);
  const messagesSelector = useMessagesSelector();
  const authSelector = useAuthSelector();
  const userInfo = authSelector?.userInfo?.user;
  // const socketInstance = socket();
  const [searchInput, setSearchInput] = useState("");
  const [labelData, setLabelData] = useState("");
  const [showChatBox, setShowChatBox] = useState(false)
  const [chatHeadListing, setChatHeadListing] = useState("");
  const [chatInfoListing, setChatInfoListing] = useState([]);
  const [chatInfoListingShow, setChatInfoListingShow] = useState(false);
  const [activeTab, setActiveTab] = useState("messageInbox");
  const [key, setKey] = useState(Math.random());
  const [getAllLabel, setGetAllLabel] = useState("");
  const [replyMessages, setReplyMessages] = useState("");
  const [chatId, setChatId] = useState("");
  const [selectedChatHead, setSelectedChatHead] = useState("");
  const [uploadImage, setUploadImage] = useState("");
  const [uploadedOriginalUrl, setUploadedOriginalUrl] = useState("");
  const [uploadedThumbnailUrl, setUploadedThumbnailUrl] = useState("");
  const [multimediaFormat, SetMultimediaFormat] = useState("");
  const [deleteChatId, setDeleteChatId] = useState("")
  const [checkedLabels, setCheckedLabels] = useState([]);
  const [showLabelBox, setShowLabelBox] = useState(false)
  const [searchLabel, setSearchLabel] = useState("")

  //dropdown 
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
    setShowLabelBox(false)
  };

  const [uploadInfo, setUploadInfo] = useState("");
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const [companyId, setCompanyId] = useState("");


  // Company id and Sender id came out of Redux
  const company_id = userInfo?.user_company_information
    ? userInfo?.user_company_information?.id
    : userInfo?.user_selected_company_information?.id;

  const sender_id = userInfo?.id;



  // for view sender company logo
  let viewImage =
    selectedChatHead?.sender_details?.id != sender_id
      ? selectedChatHead?.sender_details?.user_company_information?.company_logo
      : selectedChatHead?.company_details?.company_logo;

  //handle update label
  const handleupdateLabel = (label) => {
    setLabelData(label)
    setChatInfoListingShow(false)
    chatHeadSearch("", label);
  };

  // for view chat info listing after compose message
  const firstMessage = () => {
    chatHeadSearch("compose");
    setChatInfoListingShow(true);
    // setLoading(true)
  };

  // for scroll to Bottom in chat info listing after send message

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ block: "end", inline: "nearest" });
  };

  // start work on socket and replyMessage function for reply and send the message
  // const replyMessage = (e, data) => {
  //   e.preventDefault()
  //   setCompanyId(data?.company_id);
  //   if (data?.receiver_id == null) {
  //     let params = {
  //       sender_id: sender_id,
  //       company_id: data?.company_id,
  //       subject: selectedChatHead?.subject,
  //       temp_string: data?.id,
  //     };
  //     if (replyMessages) {
  //       params = {
  //         ...params,
  //         last_message: replyMessages,
  //         message_type: 1,
  //       };

  //     }
  //     else if (multimediaFormat === "application/pdf" || multimediaFormat === "application/msword" || multimediaFormat === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
  //       params = {
  //         ...params,
  //         last_message: uploadedOriginalUrl,
  //         message_type: 2,
  //       };
  //     }
  //     else if (multimediaFormat === "image/png" || multimediaFormat === "image/gif" || multimediaFormat === "image/jpg" || multimediaFormat === "image/jpeg") {
  //       params = {
  //         ...params,
  //         last_message: uploadedOriginalUrl,
  //         message_type: 3,
  //       };
  //     }
  //     else if (multimediaFormat === "video/mp4" || multimediaFormat === "video/quicktime") {
  //       params = {
  //         ...params,
  //         last_message: uploadedOriginalUrl,
  //         video_thumbnail: uploadedThumbnailUrl,
  //         message_type: 4,
  //       };
  //     }
  //     socketInstance.emit("first_message", params);
  //     chatHeadSearch();
  //     chatInfoList(data?.id);
  //   } else if (data?.receiver_id && data?.temp_string != 0) {
  //     let params = {
  //       sender_id: sender_id,
  //       chat_id: data?.temp_string,
  //     };
  //     if (replyMessages) {
  //       params = {
  //         ...params,
  //         last_message: replyMessages,
  //         message_type: 1,
  //       };
  //     } else if (multimediaFormat === "application/pdf" || multimediaFormat === "application/msword" || multimediaFormat === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
  //       params = {
  //         ...params,
  //         last_message: uploadedOriginalUrl,
  //         message_type: 2,
  //       };
  //     }
  //     else if (multimediaFormat === "image/png" || multimediaFormat === "image/gif" || multimediaFormat === "image/jpg" || multimediaFormat === "image/jpeg") {
  //       params = {
  //         ...params,
  //         last_message: uploadedOriginalUrl,
  //         message_type: 3,
  //       };
  //     }
  //     else if (multimediaFormat === "video/mp4" || multimediaFormat === "video/quicktime") {
  //       params = {
  //         ...params,
  //         last_message: uploadedOriginalUrl,
  //         video_thumbnail: uploadedThumbnailUrl,
  //         message_type: 4,
  //       };
  //     }
  //     socketInstance?.emit("reply", params);
  //     chatHeadSearch("reply");
  //   } else if (data?.temp_string == 0) {
  //     let params = {
  //       sender_id: sender_id,
  //       chat_id: data?.id,
  //       receiver_id: data?.receiver_id == sender_id ? data?.sender_id : data?.receiver_id,
  //       room_id: `getOppsUser_${data?.id}_room`,
  //     };
  //     if (replyMessages) {
  //       params = {
  //         ...params,
  //         last_message: replyMessages,
  //         message_type: 1,
  //       };
  //     }
  //     else if (multimediaFormat === "application/pdf" || multimediaFormat === "application/msword" || multimediaFormat === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
  //       params = {
  //         ...params,
  //         last_message: uploadedOriginalUrl,
  //         message_type: 2,
  //       };
  //     }
  //     else if (multimediaFormat === "image/png" || multimediaFormat === "image/gif" || multimediaFormat === "image/jpg" || multimediaFormat === "image/jpeg") {
  //       params = {
  //         ...params,
  //         last_message: uploadedOriginalUrl,
  //         message_type: 3,
  //       };
  //     }
  //     else if (multimediaFormat === "video/mp4" || multimediaFormat === "video/quicktime") {
  //       params = {
  //         ...params,
  //         last_message: uploadedOriginalUrl,
  //         video_thumbnail: uploadedThumbnailUrl,
  //         message_type: 4,
  //       };
  //     }
  //     socketInstance?.emit("send_message", params);
  //     chatHeadSearch();
  //   }
  //   setReplyMessages("");
  //   setUploadedOriginalUrl("");
  //   inputRef.current.focus();
  // };
  //end work on socket ///////////////


  // for close Modal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const handleDataList = () => {
    // getLebelsList();
  };

  // apply API for get all Lebels List
  // const getLebelsList = () => {
  //   let params = {

  //   }
  //   if (searchLabel) {
  //     params = {
  //       ...params,
  //       searchLabel: searchLabel ? searchLabel : "",
  //     }
  //   }
  //   dispatch(
  //     getLabels({
  //       ...params,
  //       cb(res) {
  //         if (res?.data) {
  //           setGetAllLabel(res?.data?.payload);
  //         }
  //       },
  //     })
  //   );
  // };

  // apply API for get all Lebels List
  const deleteLabelList = (e, id) => {
    e.preventDefault()
    let params = {
      id: id,
    };
    dispatch(
      deleteLabels({
        ...params,
        cb(res) {
          if (res?.data) {
            // setGetAllLabel(res?.data?.payload);
            // getLebelsList()
            // chatHeadSearch()
            chatInfoList(chatId)
          }
        },
      })
    );
  };

  const active = (flag) => {
    setActiveTab(flag);
  };



  // apply API for get all chat Head List
  const chatHeadSearch = (flag, label) => {
    let params = {
    };

    if (searchInput) {
      params = {
        ...params,
        search: searchInput ? searchInput : "",
        searchLabel: labelData ? labelData : "",
      }
    }

    if (label) {
      params = {
        ...params,
        searchLabel: label ? label : "",
      }
    }
    dispatch(
      getChatHead({
        ...params,
        cb(res) {
          if (res?.data) {
            setChatHeadListing(res?.data?.payload);
            if (true) {
              setSelectedChatHead(res?.data?.payload?.data[0]);
              chatInfoList(res?.data?.payload?.data[0].id);
            }
            else {

              const foundObject = res?.data?.payload?.data.find(obj => obj?.id === selectedChatHead?.id);
              setSelectedChatHead(foundObject)
            }
          }
        },
      })
    );
  };


  // apply API for get perticular chat head Info List
  const chatInfoList = (chatid) => {
    const socketInstance = getSocket();
    setChatId(chatid);
    let params = {
      chatId: chatid,
    };
    dispatch(
      getChatInfo({
        ...params,
        cb(res) {
          if (res?.data) {
            setChatInfoListing(res?.data?.payload?.data);
            const first = res?.data?.payload?.data[0]
            const second = first?.chat_head_details?.chat_label_details?.map((item) =>
              item?.label)

            setCheckedLabels(second)

            setTimeout(() => {
              scrollToBottom();
            }, 10);
            socketInstance.emit("join_room_user", { chat_id: chatid });
          }
        },
      })
    );
  };

  // apply API for Delete perticular chat head

  const chatDeleted = (id, flag) => {
    swal({
      // title: "Are you sure?",
      text: `Are you sure want to delete  this chat?`,
      // icon: "warning",
      dangerMode: false,
      buttons: true,
    })
      .then(result => {
        if (result) {
          let params = {
            id: id,
          };
          dispatch(
            chatDelete({
              ...params,
              cb(res) {
                if (res?.data) {
                  if (flag === "listShow") {
                    chatHeadSearch();
                    setChatInfoListingShow(false);
                    chatInfoList(id);
                  }
                  else {
                    setShowChatBox(false)
                    chatHeadSearch();
                    setChatInfoListingShow(false);
                    chatInfoList(id);
                  }
                }
              },
            })
          );

        }
      });

  };

  // apply API for Delete perticular chat info message

  const messageDeleted = (id, chatId) => {
    let params = {
      id: id,
    };
    dispatch(
      messageDelete({
        ...params,
        cb(res) {
          if (res?.data) {
            chatInfoList(chatId);
            chatHeadSearch();
          }
        },
      })
    );
  };

  // apply API for uplaod photo/Pdf

  const onChangePicture = (e) => {
    const imageFile = e.target.files[0];

    e.currentTarget.value = null;
    if (!imageFile.name.match(/\.(jpg|jpeg|png|gif|pdf|svg|heic|heif|mp4|doc|docx|mov)$/)) {
      toast.error("Please select a valid image.");
      return false;
    }
    if (imageFile.size > 25000000) {
      toast.error("File size too large.");
      return false;
    }
    let params = {
      photo: imageFile,
    };
    if (imageFile?.type === "video/mp4" || imageFile?.type === "video/quicktime") {
      params = {
        ...params,
        type: "post"
      };
    }
    dispatch(
      userPhotoUpload({
        ...params,
        cb(res) {
          if (res?.data) {
            let imageInfo = res?.data?.payload
            setUploadInfo(imageInfo);
            setUploadedOriginalUrl(imageInfo?.originalUrl?.url ? imageInfo?.originalUrl?.url : imageInfo?.url);
            setUploadedThumbnailUrl(imageInfo?.thumbnailUrl?.url);
            SetMultimediaFormat(imageInfo?.originalUrl?.format ? imageInfo?.originalUrl?.format : imageInfo?.format);
            setReplyMessages("");
          }
        },
      })
    );
  };

  //remove file
  const removeFile = (file) => () => {
    const newFiles = [uploadedOriginalUrl];
    newFiles.splice(newFiles.indexOf(file), 1);
    setUploadedOriginalUrl(newFiles);
  };

  const handleAssignLabel = (data) => {
    let params = {
      chat_id: data?.id,
      label: checkedLabels
    };
    dispatch(
      assignLabels({
        ...params,
        cb(res) {
          if (res) {
            setIsOpen(false)
            chatInfoList(chatId);
            // chatHeadSearch();
            setCheckedLabels([])
            setShowLabelBox(false)
          }
        },
      })
    );
  }

  // this id use for refresh the chat head api every time
  // socketInstance.on(`chat_heads`, (response) => {
  //   chatHeadSearch();
  // });



  useEffect(() => {
    chatHeadSearch()

  }, [])

  // this is use for when receiver get reply and send message(append socket data into chatinfo API during reply and send message)
  // useEffect(() => {
  //   socketInstance.on(`getOppsUser_${chatId}_room`, (response) => {
  //     if (response?.type == 0) {
  //       chatHeadSearch();
  //       setChatInfoListing((chatInfoListing) => [
  //         ...chatInfoListing,
  //         response?.data,
  //       ]);
  //       setTimeout(() => {
  //         scrollToBottom();
  //       }, 100);
  //     } else if (response?.type == 1) {
  //       chatHeadSearch();
  //       chatInfoList(response?.data?.id)
  //     }
  //   });
  //   return () => {
  //     socketInstance.disconnect();
  //   };
  // }, [chatInfoListing]);

  // this is use for when receiver get sender message(append socket data into chatinfo API during first message)
  useEffect(() => {
    const socket = getSocket();
      socket.on(`getOppsCompany_${company_id}_room`, (response) => {
        chatHeadSearch();
        let socketData = response?.data[0]
        const manipulatedResponse = {
          created_at: socketData?.created_at,
          deletedAt: socketData?.deletedAt,
          deleted_at: socketData?.deleted_at,
          deleted_by_receiver: socketData?.deleted_by_receiver,
          deleted_by_sender: socketData?.deleted_by_sender,
          id: socketData?.id,
          message: socketData?.last_message,
          message_type: socketData?.message_type,
          receiver_details: socketData?.receiver_details,
          receiver_id: socketData?.receiver_id,
          sender_details: socketData?.sender_details,
          sender_id: socketData?.sender_id,
          status: socketData?.status,
          updated_at: socketData?.updated_at,
          video_thumbnail: socketData?.video_thumbnail
        };
        if (response && response?.data[0]?.company_id === selectedChatHead?.company_id) {
          chatHeadSearch();
          setChatInfoListing((chatInfoListing) => [
            ...chatInfoListing, manipulatedResponse,
          ]);

          setTimeout(() => {
            scrollToBottom();
          }, 100);
        }
        chatHeadSearch();
      });
      socket.on(`getOppsUser_${chatId}_room`, (response) => {
        if (response?.type == 0) {
          chatHeadSearch();
          setChatInfoListing((chatInfoListing) => [
            ...chatInfoListing,
            response?.data,
          ]);
          setTimeout(() => {
            scrollToBottom();
          }, 100);
        } else if (response?.type == 1) {
          chatHeadSearch();
          chatInfoList(response?.data?.id)
        }else{
          chatHeadSearch();
        }
      });
      socket.on("reply", (response) => {
        if (response) {
          chatHeadSearch("reply")
        }else{
          chatHeadSearch()
        }
    })
    socket.on(`chat_heads`, (response) => {
      chatHeadSearch();
    });
    return () => {
      closeSocket();
    };
  }, []);

  // this is use for to get sender own message during the only first message
  // socketInstance.on("first_message", (response) => {
  //   let sendersocketData = response?.data[0]
  //   const senderManipulatedResponse = {
  //     created_at: sendersocketData?.created_at,
  //     deletedAt: sendersocketData?.deletedAt,
  //     deleted_at: sendersocketData?.deleted_at,
  //     deleted_by_receiver: sendersocketData?.deleted_by_receiver,
  //     deleted_by_sender: sendersocketData?.deleted_by_sender,
  //     id: sendersocketData?.id,
  //     message: sendersocketData?.last_message,
  //     message_type: sendersocketData?.message_type,
  //     receiver_details: sendersocketData?.receiver_details,
  //     receiver_id: sendersocketData?.receiver_id,
  //     sender_details: sendersocketData?.sender_details,
  //     sender_id: sendersocketData?.sender_id,
  //     status: sendersocketData?.status,
  //     updated_at: sendersocketData?.updated_at,
  //     video_thumbnail: sendersocketData?.video_thumbnail
  //   };
  //   if (response) {
  //     chatHeadSearch();
  //     setChatInfoListing((chatInfoListings) => [
  //       ...chatInfoListings, senderManipulatedResponse,
  //     ]);
  //     setTimeout(() => {
  //       scrollToBottom();
  //     }, 100);
  //   }
  // });


  // this is use for when receiver do reply(append socket data into chatinfo API during reply message)


  const handleCheckboxChange = (event) => {

    const { checked } = event.target;
    const name = event.target._wrapperState.initialValue;
    if (checked) {
      setCheckedLabels([...checkedLabels, name]); // Add the name to the checkedLabels array
    }
    else {
      if (checkedLabels.length === 1) {
        const updatedLabel = checkedLabels.filter(item => !item.includes(name))
        setCheckedLabels(updatedLabel);
        setShowLabelBox(true)
      }
      else {
        const updatedLabel = checkedLabels.filter(item => !item.includes(name))
        setCheckedLabels(updatedLabel);
      }
    }
  };


  // handle  page change
  const handlePageRedirect = (name, id) => {
    const formattedName = name?.replace(/\s+/g, '-'); // Replace spaces with hyphens
    if (id) {
      // navigate(`/companyfrontProfile/${id}`)
      navigate(`/${formattedName}/${id}`);
    }
  };

  // useEffect(() => {
  //   getLebelsList();
  // }, []);

  // handle tab chnage
  const handleTabChange = (tabIndex) => {
    if (tabIndex == "messageInbox") {
      setLabelData();
      chatHeadSearch();
    }
    setActiveTab(tabIndex);
    // Save the active tab to localStorage
    localStorage.setItem("activeTab", tabIndex);
  };

  // set flag to open the compose message modal after redirect the page
  useEffect(() => {
    if (name?.name === "ComposeMessage") {
      setModalDetail({ show: true, flag: "ComposeMessage" });
      setKey(Math.random());
    }
  }, []);


  useEffect(() => {
    // Retrieve the active tab from localStorage on component mount
    const savedActiveTab = localStorage.getItem("activeTab");
    setActiveTab(savedActiveTab ? savedActiveTab : 'messageInbox');
  }, []);

  useEffect(() => {
    chatHeadSearch();
    document.title = "messages";
  }, [searchInput]);

  useEffect(() => {
    // getLebelsList()
  }, [searchLabel])

  useEffect(() => {
    // setShowChatBox(false)
    handleTabChange("messageInbox")
  }, [])

  const backButton = () => {
    setShowChatBox(false)

    handleTabChange("messageInbox")
  }

  return (
    <>
      <div className="opportunityMain_ opportunityMsgHeight">
        <div className="row">
          {/* <div className="col-md-3">
            <h3 className="mainHeading">Manage Messages</h3>
            <div className="containerBox">
              <button
                className="subsmallBtnblue_ mb-4"
                onClick={() => {
                  setModalDetail({ show: true, flag: "ComposeMessage" });
                  setKey(Math.random());
                }}
              >
                Compose Message
              </button>
              <ul className="oppotunityMenu">
                <li
                  onClick={() => {
                    setShowChatBox(false)
                    handleTabChange("messageInbox")
                  }}
                  className="settingItems"
                >
                  <Link
                    to=""
                    className={`${activeTab === "messageInbox" ? "settingLinks active" : ""
                      }settingLinks`}
                  >
                    <img src={Images.editopp} alt="activeOpportunity" />
                    <span>Inbox</span>
                  </Link>
                </li>
                <hr className="topBorder" />
                <li
                  onClick={() => handleTabChange("Requests")}
                  className="settingItems"
                >
                  <Link
                    to=""
                    className={`${activeTab === "Requests" ? "settingLinks active" : ""}settingLinks`}
                  >
                    <img src={Images.editopp} alt="editOpportunity" />
                    <span>Requests</span>
                  </Link>
                </li>
                <hr className="topBorder" />
                <li
                  onClick={() => handleTabChange("Draft")}
                  className="settingItems"
                >
                  <Link
                    to=""
                    className={`${activeTab === "Draft" ? "settingLinks active" : ""
                      }settingLinks`}
                  >
                    <img src={Images.archived} alt="archived" />
                    <span>Drafts</span>
                  </Link>
                </li>
                <hr className="topBorder" />
                <li
                  onClick={() => handleTabChange("Sent")}
                  className="settingItems"
                >
                  <Link
                    to=""
                    className={`${activeTab === "Sent" ? "settingLinks active" : ""
                      }settingLinks`}
                  >
                    <img src={Images.archived} alt="archived" />
                    <span>Sent</span>
                  </Link>
                </li>
                <hr className="topBorder" />
                <li
                  onClick={() => handleTabChange("recentlyDelete")}
                  className="settingItems"
                >
                  <Link
                    to=""
                    className={`${activeTab === "recentlyDelete"
                      ? "settingLinks active"
                      : ""
                      }settingLinks`}
                  >
                    <img src={Images.deletedicon} alt="recentlyDelete" />
                    <span>Trash</span>
                  </Link>
                </li>
                <hr className="topBorder" />
              </ul>
            </div>
          </div> */}
          <MessageSideBar
            firstMessage={(data) => firstMessage(data)}
            chatHeadListing={chatHeadListing}
            chatHeadSearchRefresh={() => chatHeadSearch()}
            close={() => handleOnCloseModal()}
            dataList={() => handleDataList()}
            activeTab={(flag) => active(flag)}
            companyinfo={name?.companyInfo}
          />
          <div className="col-md-9">
            <div className="messageInboxcontent">
              <div className="row">
                {/* {
                  showChatBox ?
                    <div className="col-lg-12 ps-0">
                      <h3 className="mainHeading">Conversation</h3>
                      {chatInfoListingShow ? (
                        <div className="containerBox newChatHeight">
                          <h3 className="gmailSubject">Subject : {selectedChatHead?.subject}</h3>

                          <ul className="gmailHeader mb-3">
                            <li onClick={() => backButton()}><img src={Images.backGmail} alt="back-button" /></li>
                            <li onClick={() =>
                              chatDeleted(deleteChatId, "listBack")
                            }><img src={Images.deleteGmail} alt="" /></li>
                          </ul>

                          <div className="messageBox_details">

                            <div className="d-flex align-items-center">

                              <img
                                onClick={() =>
                                  handlePageRedirect(
                                    selectedChatHead?.company_details
                                      ?.company_name,
                                    selectedChatHead?.company_details?.id
                                  )
                                }
                                src={
                                  viewImage != '' ? viewImage : Images.companyProfile2
                                }
                                className="userProfile me-2"
                                alt="UserImage"
                              />
                              <div className=" ml-4">
                                <span className="userName">
                                  {selectedChatHead?.sender_details?.id !=
                                    sender_id
                                    ? selectedChatHead?.sender_details
                                      ?.user_company_information?.company_name
                                    : selectedChatHead?.company_details
                                      ?.company_name}
                                </span>
                                <p className="timeDetail">
                                  {moment(selectedChatHead?.updated_at).fromNow()}
                                </p>
                              </div>
                            </div>
                          </div>
                          {chatInfoListing && chatInfoListing?.length > 0 ? (
                            <>
                              <div className="chatContainer">
                                {chatInfoListing?.map((val, index) => {
                                  const parts = val?.message?.split(".");
                                  const extension = parts[parts?.length - 1];
                                  return (
                                    <React.Fragment key={index}>
                                      {val?.sender_details?.id != sender_id ? (
                                        <div className=" mt-5">
                                          <div
                                            className="messageDetail newChatMessage mb-2"
                                            ref={messagesEndRef}
                                          >
                                            <img
                                              src={val?.sender_details?.user_profile?.profile_photo ? val?.sender_details?.user_profile?.profile_photo : Images.companyProfile2}
                                              className="userProfile me-2 ms-0"
                                              alt="UserImage"
                                            />

                                            <div
                                              className="postsDetails newPostDetails"
                                              ref={messagesEndRef}
                                            >
                                              <h5 className="innerSubtextSmall  mb-0">
                                                <span>
                                                  <strong>
                                                    {val?.sender_details
                                                      ?.user_profile?.first_name +
                                                      " " +
                                                      val?.sender_details
                                                        ?.user_profile?.last_name}
                                                  </strong>
                                                </span>

                                              </h5>
                                              <span className="timeingDetails p-0">
                                                {moment(
                                                  val?.updated_at
                                                ).fromNow()}
                                              </span>

                                            </div>

                                          </div>
                                          {val?.message_type === 1 ? (
                                            <p className="discription_ mt-2">
                                              {val?.message}
                                            </p>
                                          ) : val?.message_type === 3 ? (
                                            <div className=" text-end">
                                              <a
                                                href={val?.message}
                                                target="_blank"
                                                className=" me-3 text-center"
                                              >
                                                <img
                                                  src={val?.message}
                                                  alt="UserImage"
                                                  className="uploadedImage"
                                                />
                                              </a>
                                            </div>
                                          ) : val?.message_type === 2 ? (
                                            <div className="text-end">
                                              <a
                                                href={val?.message}
                                                target="_blank"
                                                className="me-3 text-center"
                                              >
                                                {extension === "doc" || extension === "docx" ?
                                                  <img
                                                    src={Images.docFile}
                                                    alt="UserImage"
                                                    className="uploadedImage"
                                                  // style={{ width: 50, height: 50 }}
                                                  /> :
                                                  <img
                                                    src={Images.PDFImage}
                                                    alt="UserImage"
                                                    className="uploadedImage"
                                                  // style={{ width: 50, height: 50 }}
                                                  />
                                                }
                                              </a>
                                            </div>
                                          ) :
                                            val?.message_type === 4 ? (
                                              <div className="text-end">
                                                <a
                                                  href={val?.message}
                                                  target="_blank"
                                                  className="me-3 text-center"
                                                >
                                                  <img
                                                    src={Images.vedioThumbnail}
                                                    alt="UserImage"
                                                    className="uploadedImage"
                                                  />
                                                </a>
                                              </div>
                                            ) :
                                              (
                                                ""
                                              )}
                                        </div>
                                      ) : (

                                        <div
                                          className="messageDetailRight   mt-5"
                                          ref={messagesEndRef}
                                        >
                                          <div className="newChatMessage">
                                            <img
                                              onClick={() =>
                                                handlePageRedirect(
                                                  val?.sender_details
                                                    ?.user_company_information
                                                    ?.company_name,
                                                  val?.sender_details
                                                    ?.user_company_information?.id
                                                )
                                              }
                                              src={val?.sender_details?.user_profile?.profile_photo ? val?.sender_details?.user_profile?.profile_photo : Images.companyProfile2}
                                              className="userProfile me-2"
                                              alt="UserImage"
                                            />
                                            <div
                                              className="postsDetails newPostDetails ms-1"
                                              ref={messagesEndRef}>
                                              <h5 className="innerSubtextSmall mb-2">
                                                <span>
                                                  <strong>
                                                    {val?.sender_details
                                                      ?.user_profile?.first_name +
                                                      " " +
                                                      val?.sender_details
                                                        ?.user_profile?.last_name}
                                                  </strong>
                                                </span>
                                              </h5>
                                              <span className="timeingDetails p-0">
                                                {moment(
                                                  val?.created_at
                                                ).fromNow()}
                                              </span>

                                            </div>

                                          </div>
                                          {val?.message_type === 1 ? (
                                            <p className="discription_ mt-2 pe-2">
                                              {val?.message}
                                            </p>
                                          ) : val?.message_type === 3 ? (
                                            <div className=" text-end mt-2">
                                              <a
                                                href={val?.message}
                                                target="_blank"
                                                className=" me-3 text-center"
                                              >
                                                <img
                                                  src={val?.message}
                                                  alt="UserImage"
                                                  className="uploadedImage"
                                                />
                                              </a>
                                            </div>
                                          ) : val?.message_type === 2 ? (
                                            <div className="text-end">
                                              <a
                                                href={val?.message}
                                                target="_blank"
                                                className="me-3 text-center"
                                              >
                                                {extension === "doc" || extension === "docx" ?
                                                  <img
                                                    src={Images.docFile}
                                                    alt="UserImage"
                                                    className="uploadedImage"
                                                  // style={{ width: 50, height: 50 }}
                                                  /> :
                                                  <img
                                                    src={Images.PDFImage}
                                                    alt="UserImage"
                                                    className="uploadedImage"
                                                  // style={{ width: 50, height: 50 }}
                                                  />
                                                }
                                              </a>
                                            </div>
                                          ) : val?.message_type === 4 ? (
                                            <div className="text-end">
                                              <a
                                                href={val?.message}
                                                target="_blank"
                                                className="me-3 text-center"
                                              >
                                                <img
                                                  src={Images.vedioThumbnail}
                                                  alt="UserImage"
                                                  className="uploadedImage"
                                                />
                                              </a>
                                            </div>
                                          )
                                            : (
                                              ""
                                            )}

                                        </div>
                                      )}
                                    </React.Fragment>
                                  );
                                })}
                              </div>
                            </>
                          ) : (
                            "No Message Found"
                          )}
                          <form onSubmit={(e) => replyMessage(e, selectedChatHead)}>
                            <div className="sendBox">
                              <div className="fileattachBox_">
                                <input
                                  type="file"
                                  id="fileAttach"
                                  accept=".jpg, .jpeg, .png, .mov, .gif, .pdf, .svg, .heic , .heif, .mp4, .doc, .docx, .mov"
                                  className="form-control d-none"
                                  required=""
                                  onChange={(e) => {
                                    onChangePicture(e, 2);
                                  }}
                                />
                                <label className="fileupimg" htmlFor="fileAttach">
                                  <img
                                    src={Images.attach}
                                    alt="Attach"
                                    className="uploadIcon"
                                  />
                                </label>
                                <img
                                  src={Images.sendIcon_}
                                  alt="icon"
                                  className="img-fluid sendIcon text-end"
                                  onClick={(e) => replyMessage(e, selectedChatHead)}
                                />
                                <div className="uploadFile_ col-md-12">
                                  {uploadedOriginalUrl?.length > 0 ? (
                                    <ul className="uploadProfile_">
                                      <img
                                        className="w3-round"
                                        src={
                                          multimediaFormat == "application/msword" || multimediaFormat == "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                            ? Images.docFile
                                            : multimediaFormat == "application/pdf"
                                              ? Images.PDFImage
                                              : multimediaFormat == "video/mp4" || multimediaFormat == "video/quicktime"
                                                ? Images.vedioThumbnail
                                                : uploadedOriginalUrl
                                        }
                                      ></img>
                                      <div className="profileDetail_">
                                        <h5 className="profileboldHead">
                                          {uploadInfo?.document_name
                                            ? uploadInfo?.document_name
                                            : ""}
                                        </h5>
                                        <p className="profileboldgraph">
                                        </p>
                                      </div>
                                      <i
                                        className="fa fa-times crossfile text-red"
                                        onClick={removeFile(uploadedOriginalUrl)}
                                      ></i>
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className=" mb-4 form-group">
                                <input
                                  type="text"
                                  autoFocus
                                  className="form-control"
                                  name="text"
                                  placeholder="Type a message"
                                  value={replyMessages}
                                  ref={inputRef}
                                  onChange={(e) =>
                                    setReplyMessages(e.target.value, "text")
                                  }
                                />
                              </div>
                            </div>
                          </form>

                        </div>
                        // </>
                      ) : (
                        ""
                      )}
                    </div>
                    : */}
                    <div className="col-lg-12 ps-0">
                      <h3 className="mainHeading">Search Messages</h3>

                      <div className="containerBox messageContain">
                        <div className="business_search mb-4">
                          <img
                            className="searchIcon_"
                            src={Images.search_}
                            alt="Search"
                          />
                          <input
                            value={searchInput}
                            onChange={(e) => {
                              setSearchInput(e.target.value);
                            }}
                            type="search"
                            className="custoInput"
                            placeholder="Search Conversations"
                            aria-label="discount code"
                            aria-describedby="basic-addon2"
                          />
                          {searchInput && (
                            <button
                              className="inputClose"
                              type="reset"
                              onClick={() => {
                                setSearchInput("");
                              }}
                            >
                              {" "}
                            </button>
                          )}
                        </div>
                        <div className="MessageInbox_">
                          {
                            messagesSelector?.loading ?
                              <>
                                <div className="row">
                                  <div className="col-sm-12 text-center pt-5 pb-5">
                                    <div className="spinner-grow text-success" role="status"></div>
                                  </div>
                                </div>
                              </>
                              :
                              <>
                                {chatHeadListing?.data?.length > 0 ? (
                                  <>
                                    {chatHeadListing?.data?.map((val, index) => {

                                      let companyDetails =
                                        val?.sender_details?.id != sender_id
                                          ? val?.sender_details
                                            ?.user_company_information
                                          : val?.company_details;

                                      // Use the split() method to separate the URL by periods (.)
                                      const parts = val?.last_message?.split(".");

                                      // The last part of the split URL should be the file extension
                                      const extension = parts[parts?.length - 1];

                                      return (
                                        <div className="postContainerArea" key={index}>
                                          <div className="posts messageListing align-items-center border-0">
                                            <img
                                              onClick={() =>
                                                handlePageRedirect(
                                                  val?.company_details.company_name,
                                                  val?.company_details?.id
                                                )
                                              }
                                              src={
                                                companyDetails?.company_logo
                                                  ? companyDetails?.company_logo
                                                  : Images.puzzleProfile
                                              }
                                              className="postUser me-2"
                                              alt="UserImage"
                                            />
                                            <div className="postsDetails"
                                              onClick={() => {
                                                // chatInfoList(val?.id);
                                                // setChatInfoListingShow(true);
                                                // setShowChatBox(true);
                                                // setSelectedChatHead(val);
                                                // setDeleteChatId(val?.id)
                                                navigate(`/account-message/${val?.id}`)
                                              }}>
                                              <h5 className="innerSubtextSmall d-flex align-items-center justify-content-between mb-2"  >
                                                <span>
                                                  <strong>
                                                    {companyDetails?.company_name
                                                      ? companyDetails?.company_name
                                                      : ""}
                                                  </strong>
                                                </span>
                                              </h5>
                                              {val?.message_type === 1 ? (
                                                <p className="discription_">
                                                  {val?.last_message}
                                                </p>
                                              ) : val?.message_type === 3 ? (
                                                <div className=" discription_">
                                                  <a
                                                    href={val?.last_message}
                                                    target="_blank"
                                                    className=" me-3 text-center"
                                                  >
                                                    <img
                                                      src={val?.last_message}
                                                      alt="UserImage"
                                                      className="uploadedImage"
                                                      style={{ width: 50, height: 50 }}
                                                    />
                                                  </a>
                                                </div>
                                              ) : val?.message_type === 2 ? (
                                                <div className="discription_">
                                                  <a
                                                    href={val?.last_message}
                                                    target="_blank"
                                                    className="me-3 text-center"
                                                  >
                                                    {extension === "doc" || extension === "docx" ?
                                                      <img
                                                        src={Images.docFile}
                                                        alt="UserImage"
                                                        className="uploadedImage"
                                                        style={{ width: 31, height: 31 }}
                                                      /> :
                                                      <img
                                                        src={Images.PDFImage}
                                                        alt="UserImage"
                                                        className="uploadedImage"
                                                        style={{ width: 50, height: 50 }}
                                                      />
                                                    }
                                                  </a>
                                                </div>
                                              ) :
                                                val?.message_type === 4 ? (
                                                  <div className="discription_">
                                                    <a
                                                      href={val?.last_message}
                                                      target="_blank"
                                                      className="me-3 text-center"
                                                    >
                                                      <img
                                                        src={Images.vedioThumbnail}
                                                        alt="UserImage"
                                                        className="uploadedImage"
                                                        style={{ width: 50, height: 50 }}
                                                      />
                                                    </a>
                                                  </div>
                                                ) :
                                                  (
                                                    ""
                                                  )}
                                            </div>
                                            <div className="postionlabel">
                                              <p className="loadMOre">
                                                {moment(val?.updated_at).fromNow()}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </>
                                ) : (
                                  "No Results Found"
                                )}
                              </>
                          }
                        </div>
                      </div>
                    </div>
               {/* } */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*=== Start Add Label & compose message Modal ===*/}
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={
          modalDetail.flag === "Addlabels" ? "commonWidth customContent" : ""
        }
        ids={modalDetail.flag === "Addlabels" ? "Addlabels" : "ComposeMessage"}
        child={
          modalDetail.flag === "Addlabels" ? (
            <AddLabels
              id={modalDetail.id}
              close={() => handleOnCloseModal()}
              dataList={() => handleDataList()}
            />
          ) : modalDetail.flag === "ComposeMessage" || modalDetail.flag === name ? (
            <ComposeMessage
              chatHeadListing={chatHeadListing}
              chatHeadSearchRefresh={() => chatHeadSearch()}
              close={() => handleOnCloseModal()}
              dataList={() => handleDataList()}
              activeTab={(flag) => active(flag)}
              firstMessage={(data) => firstMessage(data)}
              companyinfo={name?.companyInfo}
            />
          ) : (
            ""
          )
        }
        header={
          <div className="modalHeader_">
            <div className="common_">
              {modalDetail.flag === "Addlabels" ? (
                <h2 className="addLabels_heading">Create New Label</h2>
              ) : (
                <h2 className="addLabels_heading">Compose Message</h2>
              )}
            </div>
          </div>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
      {/*=== End Add Label & compose message Modal ===*/}
    </>
  );
};

export default AccountMessage;
