import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import * as Images from "../../../utilities/images";
import { useDispatch } from 'react-redux';
import { articleInfo, categoryData, getAllResources } from '../../../redux/slices/support';
import moment from 'moment';
import { useSupportSelector } from '../../../redux/selector/support';
import CategorySearchData from '../categorySearchData';
import ArticleSideBar from '../articleSidebar';
import ArticleInfo from '../articleInfo';

const HelpDeskQuestion = () => {

    const [info, setInfo] = useState("")
    const supportSelector = useSupportSelector()

    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const [currentPage, setCurrentPage] = useState(0);
    const [showCategoryData, setShowCategoryData] = useState(false)
    const [searchInput, setSearchInput] = useState("")
    const [searchedData, setSearchedData] = useState()
    const [pageCount, setPageCount] = useState(1);
    const [categories, setCategories] = useState([])
    const [articleShowInfo, setArticleShowInfo] = useState(false)
    const [singleInfo, setSingleInfo] = useState()

    const categoryId = location?.state?.category_id
    const articleId = location?.state?.article_id

    const getCategoryData = (page = 1, limit = 10, id) => {
        let params = {
            search: searchInput ? searchInput : "",
            id: id ? id : categoryId,
            page: page,
            limit: limit,
        }
        dispatch(categoryData({
            ...params,
            cb(res) {
                if (res.status) {
                    setInfo(res?.data?.payload?.data)
                    setPageCount(res?.data?.payload?.total_pages);
                }
            }
        }))
    }

    //on change page pagination
    const handlePageClick = (e) => {
        getCategoryData(e.selected + 1, 10)
    };

    useEffect(() => {
        if (categoryId) {
            getCategoryData()
        }
    }, [categoryId])

    const handleSubmit = (e) => {
        e.preventDefault()
        setShowCategoryData(true)
    }

    const getAllUserWithSearch = (page = 1, limit = 10, id, search) => {
        let params = {
            // search: searchInput ? searchInput : "",
            page: page,
            limit: limit,
        }
        if (id) {
            params.help_category_id = id
        }
        if (search) {
            params = {
                ...params,
                search: search ? search : "",
            }
        }
        dispatch(
            getAllResources({
                ...params,
                cb(res) {
                    if (res.status) {
                        setCategories(res?.data?.payload?.categories)
                        setSearchedData(res?.data?.payload?.allPages?.data)
                    }
                },
            })
        );
    };

    const getArticleInfo = (articleId) => {
        setShowCategoryData(false)
        setArticleShowInfo(true)
        let params = {
            id: articleId,
        }
        dispatch(articleInfo({
            ...params,
            cb(res) {
                if (res.status) {
                    setSingleInfo(res?.data?.payload)
                }
            }
        }))
    }

    useEffect(() => {
        if (articleId) {
            getArticleInfo(articleId)
        }
    }, [articleId])

    //reset input
    const onChangeReset = () => {
        setSearchInput("")
    }
 
    useEffect(() => {
        getAllUserWithSearch()
    }, [])

    return ( 
        <div>
            <>
                <section className='aboutMenus'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <ul className='aboutMenusContainer'>
                                    <li className='aboutItem'>
                                        <button >
                                            <Link to='/privacy-policy' className='aboutLinks' >PRIVACY POLICY</Link>
                                        </button>
                                    </li>
                                    <li className='aboutItem'>
                                        <button >
                                            <Link to='/terms-of-service' className='aboutLinks' >TERMS OF SERVICE</Link>
                                        </button>
                                    </li>

                                    <li className='aboutItem'>
                                        <button>
                                            <Link to='/help-desk' className='aboutLinks' >HELP DESK</Link>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <div className='bgWhite'>
                    <section className='helpDeskSend'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-8'>
                                    <div className='helpDeskQueLeft'>
                                        {
                                            supportSelector?.loading ?
                                                <div className="row">
                                                    <div className="col-sm-12 text-center pt-5 pb-5">
                                                        <div className="spinner-grow text-success" role="status"></div>
                                                    </div>
                                                </div>
                                                :
                                                <>
                                                    {showCategoryData ?
                                                        <CategorySearchData
                                                            getArticleInfo={(id) => getArticleInfo(id)}
                                                            searchedData={searchedData}
                                                            setSearchInput={setSearchInput}
                                                            pageCount={pageCount}
                                                            getAllUserWithSearch={(id) => getAllUserWithSearch(1, 10, id)}
                                                        />

                                                        :
                                                        articleShowInfo ?
                                                            <ArticleInfo
                                                                singleInfo={singleInfo}
                                                                setSearchInput={setSearchInput}
                                                                pageCount={pageCount}
                                                                getAllUserWithSearch={(id) => getAllUserWithSearch(1, 10, id)}
                                                            />
                                                            :
                                                            <>
                                                                {
                                                                    supportSelector?.loading ? "" :
                                                                        <>
                                                                            {
                                                                                info?.length > 0 ? <>
                                                                                    {
                                                                                        info && info?.map((data, index) => (
                                                                                            <>
                                                                                                <h1 className='heading42 fw-normal' onClick={() => {
                                                                                                    getArticleInfo(data?.id)
                                                                                                }
                                                                                                }
                                                                                                >{data?.title}</h1>

                                                                                                <div className="suggestedArticleInner mb-5" key={index}>
                                                                                                    <p className="suggestedDes wordLimit" dangerouslySetInnerHTML={{ __html: data?.description }} >
                                                                                                    </p>
                                                                                                    {info?.length > 1 ? <hr /> : ""}
                                                                                                </div>
                                                                                            </>
                                                                                        ))
                                                                                    }
                                                                                </>
                                                                                    : "No data found"
                                                                            }
                                                                        </>
                                                                }

                                                            </>
                                                    }
                                                </>
                                        }
                                        <div className='helpFullCon'>
                                            <input type="text" className='' placeholder='Was this helpful?' />
                                            <button className='stillNeedBtn' onClick={() => navigate('/contact-us')}>Still need help</button>
                                        </div>
                                    </div>
                                </div>
                                <ArticleSideBar
                                    setShowCategoryData={setShowCategoryData}
                                    setArticleShowInfo={setArticleShowInfo}
                                    categories={categories}
                                    searchInput={searchInput}
                                    onChangeReset={onChangeReset}
                                    setSearchInput={setSearchInput}
                                    getAllUserWithSearch={(id, search) => getAllUserWithSearch(1, 10, id, search)}
                                    getCategoryData={(id) => { getCategoryData(1, 10, id) }} />
                            </div>
                        </div>
                    </section>
                </div>
            </>

        </div>

    )
}

export default HelpDeskQuestion