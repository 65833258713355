import React,{useState} from 'react'
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
    CardElement
  } from "@stripe/react-stripe-js";
import { addPaymentMethod } from '../../../redux/slices/stripeIntegration';
import { useDispatch } from 'react-redux';
import { toast } from "react-toastify";


const AddPaymentCard = (props) => {

  // collect data from the user
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [priceId, setPriceId] = useState("");
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  
  // stripe items
  const stripe = useStripe();
  const elements = useElements();

  // main function
  const UpdatePaymentMethod = async (event) => {
    setLoading(true)
    
    event.preventDefault();
    try {
      
      // create a payment method
      const paymentMethod = await stripe?.createPaymentMethod({
        type: "card",
        card: elements?.getElement(CardNumberElement,CardCvcElement,CardExpiryElement),
        billing_details: {
          name,
          email,
        },
      });

  const updateCustomerPaymentMethod = () => {
    const params = {
      payment_method: paymentMethod?.paymentMethod?.id
    };

    dispatch(
      addPaymentMethod({
        ...params,
        cb(res) {
          if (res.status == 200) {
            props?.updateCustomer()
          } else {
            toast.warning("Something went wrong");
          }
        },
      })
    );
  };
  updateCustomerPaymentMethod();
    } catch (error) {
    }
    setLoading(false)
    props?.close()
  };  
  return (
   <>
    {/* <div className="grid gap-4 m-auto">
      <CardElement />
      <button className='primaryBtn d-inline-block' onClick={createSubscription} disabled={!stripe}>
    Pay Now
  </button>
    </div> */}
    <form >
          <label>Card number</label>

          <CardNumberElement
            // options={options}
            className="card_input"

          />
          <br></br>
          <label>Expiration date   </label>
          <CardExpiryElement
            // options={options}
            className="card_inputbox"

          />

          <br></br>
          <label>CVC </label>
          <CardCvcElement
            // options={options}
            className="card_inputbox"

          />

          <br></br>
      
          {loading ? 
            <div className="spinner-border text-success ms-3" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          :
            <button className='primaryBtn d-inline-block' type="button"  onClick={UpdatePaymentMethod} disabled={!stripe || loading}> Add Card </button>
          }
        </form>

   </>
  )
}

export default AddPaymentCard