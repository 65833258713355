import React, { useEffect, useState } from "react"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import * as Images from "../../../utilities/images";
import OwlCarousel from 'react-owl-carousel';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useAuthSelector } from "../../../redux/selector/auth";
import CustomModal from '../../components/common/shared/CustomModal';
import WaitList from "./waitList";
import BaseUrl from '../../../constants/baseUrl';
import HomeThumbnail from "./homeThumbnail";

const Home = () => {
    const authSelector = useAuthSelector();
    const logout_witjout_complete_steps = authSelector?.userStep ? Object.keys(authSelector?.userStep).length === 0 || authSelector?.userStep?.step < 6 || authSelector?.userStep?.user?.step < 6 : "";
    const login_with_steps = authSelector?.userInfo?.steps == 6;
    const location = useLocation();
    const navigate = useNavigate();
   
    const [activeTab, setActiveTab] = useState("");
    const [videoUrl, setVideoUrl] = useState("");
    const plusStyle = {
        color: "#388E1E",
        fontSize: "14px"
    }

    if (window && localStorage) {
        localStorage.removeItem('choosenPlan')
    }

    const [buyerVideoStatus, setBuyerVideoStatus] = useState(false)
    const [supplierVideoStatus, setSupplierVideoStatus] = useState(false)
    const [opporVideoStatus, setOpporVideoStatus] = useState(false)
    const [commVideoStatus, setCommVideoStatus] = useState(false)

    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });

    const handleChange = () => {
        navigate('/faqs');
    }

    //create Account by select role
    const handleSubmit = (flag) => {



        if (flag === "Buyer") {

            navigate('/membership-choose-per-role');

            // navigate('/businessneed', { state: { id: 1 } });
        }
        else if (flag === 'Suppliers') {

            navigate('/membership-choose-per-role');

            // navigate('/businessneed', { state: { id: 2 } });
        }
        else if (flag === "opportunitesDetails") {

            navigate('/membership-choose-per-role');

            // navigate('/chooserolesfor=signin');
        }
        else if (flag === "community") {
            navigate('/membership-choose-per-role');

            // navigate('/chooserolesfor=signin');
        }
        else if (flag == "membership") {

            navigate('/membership-choose-per-role');
        }
        else {
            navigate('/signup', { state: { id: 2 } });
        }

    }

    // Home page modal
    const HomePageFunction = () => {
        setModalDetail({ show: true, flag: "HomeThumbnail", });
        // setVideoUrl('https://api.wellopps.itechnolabs.tech:3000/uploads/videos/intro.mp4')
        setVideoUrl(`${BaseUrl.API_URL}uploads/videos/intro.mp4`)

        setKey(Math.random());
    }
    const memberServices = () => {
        setModalDetail({ show: true, flag: "HomeThumbnail", });
        // setVideoUrl('https://api.wellopps.itechnolabs.tech:3000/uploads/videos/member_services.mp4')
        setVideoUrl(`${BaseUrl.API_URL}uploads/videos/member_services.mp4`)
        setKey(Math.random());
    }
    const BuyersFunction = () => {
        setModalDetail({ show: true, flag: "HomeThumbnail", });
        setVideoUrl(`${BaseUrl.API_URL}uploads/videos/buyers.mp4`)
        setKey(Math.random());
    }
    const suppliersFunction = () => {
        setModalDetail({ show: true, flag: "HomeThumbnail", });
        setVideoUrl(`${BaseUrl.API_URL}uploads/videos/suppliers.mp4`)
        setKey(Math.random());
    }
    const opportunitiesFunction = () => {
        setModalDetail({ show: true, flag: "HomeThumbnail", });
        setVideoUrl(`${BaseUrl.API_URL}uploads/videos/opportunities.mp4`)
        setKey(Math.random());
    }

    const communityFunction = () => {
        setModalDetail({ show: true, flag: "HomeThumbnail", });
        // setVideoUrl('https://api.wellopps.itechnolabs.tech:3000/uploads/videos/ow_community.mp4')
        setVideoUrl(`${BaseUrl.API_URL}uploads/videos/ow_community.mp4`)
        setKey(Math.random());
    }

    useEffect(() => {
        if (location.hash) {
            let element = document.getElementById(location.hash.replace("#", ""));
            element?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        }
        else if (logout_witjout_complete_steps) {
            localStorage.removeItem('authToken');
            localStorage.removeItem('persist:root');
        }
        document.title = "OPPSWell"
    }, []);



    //closeModal
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };



    const SectionToScroll = (flag) => {
        var section = document.getElementById(flag);
 
        if (section) {
            if (section.id == "membership") {
                section.scrollIntoView({ behavior: 'smooth' });
            }
            else if (section.id == "buyers") {
                section.scrollIntoView({ behavior: 'smooth' });
            }
            else if (section.id == "suppliers") {
                section.scrollIntoView({ behavior: 'smooth' });
            }
            else if (section.id == "opportunities") {
                section.scrollIntoView({ behavior: 'smooth' });
            }
            else if (section.id == "community") {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }


    useEffect(() => {
        if(window?.location?.hash){
            const value = window?.location?.hash.substring(1);
            SectionToScroll(value)
        }
    },[window?.location?.hash])


    return (
        <>
            <div className="homePage">
                {/* <RunOneSignal/> */}
                {/* Start HomePage Section */}
                <section className="homeBanner landingHead_">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <div className="leftSec">
                                    <h3 className="heading_Blue mb-3">Connecting <b>Business Buyers</b>  with Small and Large <b>Business Suppliers</b></h3>
                                    <p className="innerSubtext boldhead_"><b className=""> OPPSWell</b> is the Connector!</p>
                                    <p className="innerSubtext boldhead_ mb-3"><b>No Middle Women, No Middle Men, No Middle People.</b></p>
                                    <p className="innerSubtext boldhead_">The <b className="">Premier Global B2B site</b> seamlessly integrating procurement needs into a business social networking platform.</p>
                                    {/* <p className="bannerTxt mb-5">
                                        GetOPP$ is a bridge for large, medium, small and diverse businesses looking to source and supply their goods and services from and to each other. A safe space where Buyers and Suppliers create trusted relationships.
                                        The bridge connecting Buyers with Suppliers<br />
                                        <b> No Middle Women, No Middle Men, No Middle People. </b><br />A safe space where Buyers and Suppliers create trusted relationships.
                                    </p> */}
                                    {/* <b className="bannerTxt boldhead_">
                                        We are the first platform to fuse procurement needs and business networking activities with a business social networking platform.
                                        GetOpp$ is the first B-to-B platform fusing procurement needs with a business social networking platform.
                                        GetOpp$ is the first B2B platform fusing procurement needs with a business social networking platform.
                                    </b> */}
                                    <div className="btn-group d-block d-sm-flex">
                                        {/* <a href="#buyers">
                                            <button
                                                // onClick={(e) => handleSubmit("Buyer")}
                                                className="secondaryBtn mb-2 me-3 d-flex justify-content-between align-items-center">
                                                <span className="me-5">
                                                    Buyers
                                                </span>
                                                <span><i className="las la-angle-right"></i></span>
                                            </button>
                                        </a> */}
                                        {/* <a> */}
                                        <button
                                            onClick={(e) => handleSubmit("membership")}
                                            className="primaryBtnOther d-flex justify-content-between align-items-center">
                                            <span className="me-3">Get Started</span>
                                            <i className="las la-angle-right"></i>
                                        </button>
                                        {/* </a> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6  d-none d-lg-block">
                                <img src={Images.bannerImg} className='img-fluid rightImg cursor-pointer' alt="RightImg" onClick={() => {
                                    HomePageFunction()
                                }}
                                />
                            </div>
                        </div>
                    </div>
                </section>
                {/* End HomePage Section */}
                {/* Start Relevance Section */}
                <section className="buyerService homeRelevance" id="membership" >
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-12">
                                <div className="imgContainer mb-3 mb-lg-0">

                                    <img src={Images.buyerService} className='secImg' alt='BuyerService'
                                    />
                                    <img src={Images.goIcon} className='goIcon' alt='goIcon' onClick={() => {
                                        memberServices()
                                    }} />

                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="">
                                    <p class="outerBtn mb-4">MEMBER SERVICES</p>
                                    <h4 className="heading_Blue mb-4 fw-normal">
                                        The <b>OPPSWell Relevance.</b> The Solution You Knew You <b> Needed!</b>
                                    </h4>
                                    <ul>
                                        <artical className="listMain">
                                            <img src={Images.Mark} alt="mark" className="tickMark" />
                                            <li className="innerSubtext">Equally beneficial to Buyers and Suppliers</li>
                                        </artical>
                                        <artical className="listMain">
                                            <img src={Images.Mark} alt="mark" className="tickMark" />
                                            <li className="innerSubtext">A holistic approach</li>
                                        </artical>
                                        <artical className="listMain">
                                            <img src={Images.Mark} alt="mark" className="tickMark" />
                                            <li className="innerSubtext">Real-time global marketplace exposure for Buyers and Suppliers</li>
                                        </artical>
                                        <artical className="listMain">
                                            <img src={Images.Mark} alt="mark" className="tickMark" />
                                            <li className="innerSubtext">Save time and money</li>
                                        </artical>
                                        <artical className="listMain">
                                            <img src={Images.Mark} alt="mark" className="tickMark" />
                                            <li className="innerSubtext">Relevant business resources</li>
                                        </artical>
                                        {/* <artical className="listMain">
                                        <img src={Images.Mark} alt="mark" className="tickMark" />
                                        <li className="innerSubtext"><b>Communicate with active Suppliers</b> that are continuously updating information</li>
                                    </artical>
                                    <artical className="listMain">
                                        <img src={Images.Mark} alt="mark" className="tickMark" />
                                        <li className="innerSubtext"><b>Lead educational programs </b>for GetOPP$ Business Resources and share company expertise to educate and connect with others on the platform</li>
                                    </artical> */}
                                    </ul>
                                    <p className="innerSubtext mt-2 mb-2">
                                        Recognizing the impact of social media networking on business relationships, OPPSWell is revolutionizing the procurement process.
                                        {/* <b>The GetOpp$ goal is to put all of these small businesses on the global procurement radar screen </b>so that they now have, at their fingertips, access to international B2B markets to sell their goods and services! */}
                                    </p>

                                    <button onClick={(e) => handleSubmit("membership")} className="primaryBtnOther mt-3 d-flex justify-content-between align-items-center">
                                        <span className="me-3">Membership</span>
                                        <i className="las la-angle-right"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* End Relevance Section */}
                {/* Start BuyerService Section */}
                <section className="buyerService" id="buyers">
                    <div className="container">
                        <div className="row align-items-start align-items-md-center">
                            <div className="col-lg-6 col-md-12 mb-3 mb-lg-0 order-last">
                                <div className="imgContainer buyerimgContainer">
                                    {buyerVideoStatus
                                        ?
                                        <>
                                            {/* <video controls className='w-100 mt-2 videoBox newBox' autoPlay>
                                                <source src="https://api.getopps.itechnolabs.tech:4000/uploads/users/images/GetOPPS_Buyer_Services_Video_for_Homepage.mp4" type="video/mp4" className='soundbiteImg' />
                                            </video> */}
                                            {/* <video controls className='w-100 mt-2 videoBox newBox' autoPlay>
                                                <source src="https://api.wellopps.itechnolabs.tech:3000/uploads/videos/buyers.mp4" type="video/mp4" className='soundbiteImg' />
                                            </video> */}
                                        </>
                                        :
                                        <>
                                            <img src={Images.buyerImg_} className='secImg' alt='BuyerService' />
                                            <img src={Images.goIcon} className='goIconLeft' alt='goIcon' onClick={() => {
                                                BuyersFunction()
                                            }} />
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 mb-3 ">
                                <p className="outerBtn">BUYER SERVICES</p>
                                <h4 className="heading_Blue my-3">
                                    {/* A revolutionary tool to <b>Identify Suppliers</b> for all of your <b>Contracting Needs.</b> */}
                                    A Revolutionary Tool to <b>Identify Suppliers</b> For All Of Your <b>Contracting</b> Needs

                                </h4>
                                {/* <p className="innerSubtext mt-2 mb-2">
                                    GetOpp$ is a professional network and social communication platform where Buyers can search for Suppliers based on a diverse range of filters with direct access for communication and due diligence research. GetOPP$ creates a safe space for Buyers and Suppliers to contract.
                                </p> */}
                                <ul>
                                    <artical className="listMain">
                                        <img src={Images.Mark} alt="mark" className="tickMark" />
                                        <li className="innerSubtext">Eliminate inefficiencies and frustrations with  static databases</li>
                                    </artical>
                                    <artical className="listMain">
                                        <img src={Images.Mark} alt="mark" className="tickMark" />
                                        <li className="innerSubtext">Expand business network</li>
                                    </artical>
                                    <artical className="listMain">
                                        <img src={Images.Mark} alt="mark" className="tickMark" />
                                        <li className="innerSubtext">Search, identify and connect with Suppliers</li>
                                    </artical>
                                    <artical className="listMain">
                                        <img src={Images.Mark} alt="mark" className="tickMark" />
                                        <li className="innerSubtext">Post contracting opportunites and industry queries</li>
                                    </artical>
                                    <artical className="listMain">
                                        <img src={Images.Mark} alt="mark" className="tickMark" />
                                        <li className="innerSubtext">Showcase your business</li>
                                    </artical>
                                    {/* <artical className="listMain">
                                        <img src={Images.Mark} alt="mark" className="tickMark" />
                                        <li className="innerSubtext"><b>Communicate with active Suppliers</b> that are continuously updating information</li>
                                    </artical>
                                    <artical className="listMain">
                                        <img src={Images.Mark} alt="mark" className="tickMark" />
                                        <li className="innerSubtext"><b>Lead educational programs </b>for GetOPP$ Business Resources and share company expertise to educate and connect with others on the platform</li>
                                    </artical> */}
                                </ul>
                                <p className="innerSubtext mt-3 mb-3"><b>OPPSWell </b>is merging business operations and social<br /> networking into the procurement process.</p>

                                <button onClick={(e) => handleSubmit("membership")}
                                    className="primaryBtnOther mt-3 d-flex justify-content-between align-items-center">
                                    <span class="me-3">Get Started</span>
                                    <i className="las la-angle-right"></i>
                                </button>
                         
                        </div>
                        </div>

                    </div>
                </section>
                {/* End BuyerService Section */}
                {/* Start SupplierService Section */}
                <section className="supplierService" id="suppliers">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-12 mb-3 mb-lg-0">
                                <div className="imgContainer">
                                    {supplierVideoStatus
                                        ?
                                        <>
                                            {/* <video controls className='w-100 mt-2 videoBox newBox' autoPlay>
                                                <source src="https://api.getopps.itechnolabs.tech:4000/uploads/users/images/GetOPPS_Supplier_Services_Video_for_Homepage.mp4" type="video/mp4" className='soundbiteImg' />
                                            </video> */}
                                            {/* <video controls className='w-100 mt-2 videoBox newBox' autoPlay>
                                                <source src="https://api.wellopps.itechnolabs.tech:3000/uploads/videos/suppliers.mp4" type="video/mp4" className='soundbiteImg' />
                                            </video> */}
                                        </>
                                        :
                                        <>
                                            <img src={Images.supplierService} className='secImg' alt='BuyerService' />
                                            <img src={Images.goIcon} className='goIcon' alt='goIcon' onClick={() => {
                                                suppliersFunction()
                                            }} />
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <p className="outerBtn">SUPPLIER SERVICES</p>
                                <h4 className="heading_Blue mt-3 mb-3 fw-normal">
                                    {/* GetOpp$ is the revolutionary way to be informed of and <b>seek new contracting opportunities.</b> */}
                                    <b>Direct Access</b> to <b>Business Buyers</b>
                                </h4>
                                {/* <p className="innerSubtext mt-2 mb-2">
                                    Suppliers receive direct access to large, medium, small and diverse Buyers. The platform tools provide a guide to assist in the quest to achieve prospective long term success, sustainable business models and revenue generation.
                                </p> */}
                                <ul>
                                    <artical className="listMain">
                                        <img src={Images.Mark} alt="mark" className="tickMark" />
                                        <li className="innerSubtext">Network and reach out to Buyers with Direct Messages</li>
                                    </artical>
                                    <artical className="listMain">
                                        <img src={Images.Mark} alt="mark" className="tickMark" />
                                        <li className="innerSubtext">Search and reply to targeted contracting opportunities</li>
                                    </artical>
                                    <artical className="listMain">
                                        <img src={Images.Mark} alt="mark" className="tickMark" />
                                        <li className="innerSubtext">Highlight products and services</li>
                                    </artical>
                                    <artical className="listMain">
                                        <img src={Images.Mark} alt="mark" className="tickMark" />
                                        <li className="innerSubtext">Streamlined and time efficient supplier diversity certification process</li>
                                    </artical>
                                    <artical className="listMain">
                                        <img src={Images.Mark} alt="mark" className="tickMark" />
                                        <li className="innerSubtext">Participate in Business Resources programs</li>
                                    </artical>
                                    {/* <artical className="listMain">
                                        <img src={Images.Mark} alt="mark" className="tickMark" />
                                        <li className="innerSubtext"><b>Highlight certifications, and services</b> through your business profile and the GetOPP$ community live feed</li>
                                    </artical> */}
                                </ul>
                                <p className="innerSubtext mt-3 mb-3"><b>OPPSWell</b> has resources to help your business in its quest to achieve sustainable success.
                                </p>
                                <button onClick={(e) => handleSubmit("membership")} className="primaryBtnOther mt-5 mt-md-3 d-flex justify-content-between align-items-center">
                                    <span class="me-3">Get Started</span>
                                    <i className="las la-angle-right"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
                {/* End SupplierService Section */}
                {/* Start Opportunites Section */}
                <section className="opportunites" id="opportunities">
                    <div className="container">
                        <div className="row align-items-start align-items-md-center">
                        <div className="col-lg-6 col-md-12 mb-3 mb-lg-0 order-last">
                                <div className="imgContainer">
                                    {opporVideoStatus
                                        ?
                                        <>
                                            {/* <video controls className='w-100 mt-2 videoBox newBox' autoPlay>
                                                <source src="https://api.getopps.itechnolabs.tech:4000/uploads/users/images/GetOPPS_Opportunities_Video_for_Homepage.mp4" type="video/mp4" className='soundbiteImg' />
                                            </video> */}
                                            <video controls className='w-100 mt-2 videoBox newBox' autoPlay>

                                                <source src={`${BaseUrl.API_URL}uploads/videos/opportunities.mp4`} type="video/mp4" className='soundbiteImg' />
                                            </video>
                                        </>
                                        :
                                        <>
                                            <img src={Images.opportunity} className='secImg' alt='BuyerService' />
                                            <img src={Images.goIcon} className='goIconLeft' alt='goIcon' onClick={() => {
                                                opportunitiesFunction()
                                            }} />
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 mb-3 mb-md-0">
                                <p className="outerBtn mb-3">OPPORTUNITIES</p>
                                <h4 className="heading_Blue mb-3 fw-normal">
                                    Trusted Relationships Between <b>Buyers</b> and <b>Suppliers</b> Lead to Opportunities

                                    {/* <b>GetOpp$</b> aims to eliminate the void of no response and lack of communication between  */}
                                </h4>
                                <ul>
                                    <artical className="listMain">
                                        <img src={Images.Mark} alt="mark" className="tickMark" />
                                        <li className="innerSubtext">Subscription-based service significantly reducing the incidence of bots and catfishing</li>
                                    </artical>
                                    <artical className="listMain">
                                        <img src={Images.Mark} alt="mark" className="tickMark" />
                                        <li className="innerSubtext">Dynamic search feature to search for opportunities with various filters</li>
                                    </artical>
                                    <artical className="listMain">
                                        <img src={Images.Mark} alt="mark" className="tickMark" />
                                        <li className="innerSubtext">Acclamations to highlight positive experiences of working with Members on the platform</li>
                                    </artical>
                                    <artical className="listMain">
                                        <img src={Images.Mark} alt="mark" className="tickMark" />
                                        <li className="innerSubtext">Community Feed to raise visibility of all Buyers and Suppliers</li>
                                    </artical>
                                </ul>
                                <p className="innerSubtext mt-3 mb-3">
                                    A safe space for <b>Buyers </b>and <b>Suppliers </b>to create trusted relationships.
                                </p>
                                {/* <p className="innerSubtext mt-3 mb-3">
                                    <b>GetOpp$</b> aims to <b>eliminate the void</b> of communication and connection <b>between Buyers and Suppliers.</b>
                                </p> */}

                                <button onClick={(e) => handleSubmit("membership")} className="primaryBtnOther mt-3 d-flex justify-content-between align-items-center">
                                    <span className="me-3">Get Started</span>
                                    <i className="las la-angle-right"></i>
                                </button>
                            </div>

                            {/* <ul className="opportunitesDetails" onClick={(e) => handleSubmit("opportunitesDetails")} >
                                    <li className={`opptItems ${activeTab == 'Accounting' ? 'active' : ''}`} onClick={() => setActiveTab("Accounting")}>
                                        <span className="innerSubtext"><b>Accounting</b></span>
                                        <span className="innerSubtext"><b className="me-2">45</b> Companies</span>
                                    </li>
                                    <li className={`opptItems ${activeTab == 'Automotive' ? 'active' : ''}`} onClick={() => setActiveTab("Automotive")}>
                                        <span className="innerSubtext"><b>Automotive</b></span>
                                        <span className="innerSubtext"><b className="me-2">21</b>Companies</span>
                                    </li>
                                    <li className={`opptItems ${activeTab == 'Insurance' ? 'active' : ''}`} onClick={() => setActiveTab("Insurance")}>
                                        <span className="innerSubtext"><b>Insurance</b></span>
                                        <span className="innerSubtext"><b className="me-2">6</b>Companies</span>
                                    </li>
                                    <li className={`opptItems ${activeTab == 'Marketing & Ads' ? 'active' : ''}`} onClick={() => setActiveTab("Marketing & Ads")}>
                                        <span className="innerSubtext"><b>Marketing & Ads</b></span>
                                        <span className="innerSubtext"><b className="me-2">13</b>Companies</span>
                                    </li>
                                    <li className={`opptItems ${activeTab == 'PublicRelations' ? 'active' : ''}`} onClick={() => setActiveTab("PublicRelations")}>
                                        <span className="innerSubtext"><b>Public Relations</b></span>
                                        <span className="innerSubtext"><b className="me-2">9</b>Companies</span>
                                    </li>
                                    <li className={`opptItems ${activeTab == 'Software' ? 'active' : ''}`} onClick={() => setActiveTab("Software")}>
                                        <span className="innerSubtext"><b>Software</b></span>
                                        <span className="innerSubtext"><b className="me-2">6</b>Companies</span>
                                    </li>
                                </ul> */}
                            
                        </div>
                    </div>
                </section>
                {/* End Opportunites Section */}
                {/* Start BussinessRelation Css */}
                <section className="bussinessRelation " id="community">
                    <div className="container">
                        <div className="row pb-4">
                            <div className="col-lg-5 col-md-12 mb-3 mb-lg-0">
                                <div className="row   pe-0 pe-md-4 ps-0">
                                    <div className="col-6">
                                        <div className="bussinessRelLeftImg">
                                            <div className="img-container mb-4 me-1 me-md-0">
                                                {/* <div className="commonResponse">
                                                    <img src={Images.msgWhite} />
                                                </div> */}
                                                <img src={Images.people} className='peopleImg' alt="People" />
                                                {/* <img src={Images.messageFill} className='outerIcon outerIcon1' alt='Message' /> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="img-container mb-4">
                                            {/* <div className="commonResponse commonResponse2">
                                                <img src={Images.likeWhite} />
                                            </div> */}
                                            <img src={Images.people1} className='peopleImg' alt="People" />
                                            {/* <img src={Images.checkedThumb} className='outerIcon outerIcon2' alt="CheckedThumb" /> */}
                                        </div>
                                    </div>

                                    {/* <div className="row pt-4 pb-4 pe-0 pe-md-1 ps-0"> */}
                                    <div className="col-md-12 ps-2 p-0 pe-2">
                                        <div className="position-relative">


                                            <div className="img-container lastImg mb-3 mb-md-0">
                                                {commVideoStatus
                                                    ?
                                                    <>
                                                        <video controls className='w-100 mt-1 ml-2 videoBox newBox' autoPlay>
                                                            <source src={`${BaseUrl.API_URL}uploads/videos/ow_community.mp4`} type="video/mp4" className='soundbiteImg' />
                                                        </video>
                                                    </>
                                                    :
                                                    <>
                                                        <img src={Images.people2} className='peopleImg' alt="People" />
                                                        <img src={Images.goIcon} className='goIconLeft' alt='goIcon' onClick={() => {
                                                            communityFunction()
                                                            // setCommVideoStatus(true)
                                                        }} />
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {/* </div> */}
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12" >
                                {/* <p className="otherLink pb-3"><b>Community</b></p> */}
                                <p className="outerBtn">COMMUNITY</p>

                                <h4 className="heading_Blue my-4">
                                    <b>Strengthening Relationships</b> Between Buyers and Suppliers
                                    {/* <b>GetOpp$</b> is reinforcing meaningful <b>professional</b> <b>relationships</b> between <b>Buyers</b> and <b>Suppliers.</b> Communities */}
                                    {/* Value and importance of <b>connection</b> and <b>community</b> in business relationships. */}
                                </h4>
                                {/* <p className="innerSubtext mt-4 mb-4">
                                    GetOpp$ is <b>Leveraging Technology</b> and Social Media <b>for Network Creation</b> by providing:
                                    The GetOpp$ platform provides community, professional sharing, education and opportunity to fortify that critical link in contract relationships.  All users are encouraged to post relevant company information to raise their companies’ visibility and drive valuable connections with other users on the platform.

                                    The GetOpp$ platform provides community, professional sharing, education and opportunities to bridge the missing link in contract relationships. All users are encouraged to post relevant company information to raise their companies’ visibility with other users on the platform.
                                </p> */}
                                <p className="innerSubtext">
                                    <ul className="mb-5">
                                        <artical className="listMain">
                                            <img src={Images.Mark} alt="mark" className="tickMark" />
                                            <li className="innerSubtext"><b>Exposure</b> to a broad set of Buyers and Suppliers </li>
                                        </artical>
                                        <artical className="listMain">
                                            <img src={Images.Mark} alt="mark" className="tickMark" />
                                            <li className="innerSubtext"><b>Community </b> that provides support to each other </li>
                                        </artical>
                                        <artical className="listMain">
                                            <img src={Images.Mark} alt="mark" className="tickMark" />
                                            <li className="innerSubtext"><b>Community</b> Feed that highlights company information and accolades</li>
                                        </artical>
                                        <artical className="listMain">
                                            <img src={Images.Mark} alt="mark" className="tickMark" />
                                            <li className="innerSubtext"><b>Connections</b> with other businesses by following each other and sending Direct Messages</li>
                                        </artical>
                                        <artical className="listMain">
                                            <img src={Images.Mark} alt="mark" className="tickMark" />
                                            <li className="innerSubtext"><b>Business opportunities</b> that can foster long-term relationships</li>
                                        </artical>
                                    </ul>

                                    <p className="innerSubtext"><b>OPPSWell</b> is the Premier Global B2B site for business networking -- bridging the gap in contract relationships.</p>

                                    {/* <b className="ColoredText">GetOpp$</b> is the Premier Global B2B Platform for professional networking that is <b>bridging the gap in contract relationships.</b> */}
                                </p>
                                <button className="primaryBtnOther mt-5 d-flex justify-content-between align-items-center">
                                    <span onClick={(e) => handleSubmit("membership")} className="me-3">Get Started</span>

                                    {/* <span onClick={() => {
                                        setModalDetail({ show: true, flag: "waitList", });
                                        setKey(Math.random());
                                    }}>Get Started</span> */}

                                    <i className="las la-angle-right"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </section >
                {/* End BussinessRelation Css */}
                {/* Start WhatPeopleSay Section */}
                <section className="whatPeopleSay">
                    <div className="container">
                        <div className="row mb-4">
                            <div className="col-12 text-center">
                                <p className="outerBtn">TESTIMONIALS</p>
                                <h4 className="heading_Blue">
                                    What <b>businesses</b>  are saying <b>about us</b>
                                </h4>
                            </div>
                        </div>
                        <OwlCarousel className='owl-theme' loop margin={10} nav
                            dots={true}
                            responsiveClass={true}
                            responsive={{
                                0: {
                                    items: 1
                                },
                                600: {
                                    items: 1
                                },
                                991: {
                                    items: 1
                                },
                                1000: {
                                    items: 1
                                }
                            }}
                        >
                            {/* <div className='item'>
                                <div className="row">
                                    <div className="sliderItems col-md-12 col-lg-6">
                                        <div className="slideContent">
                                            <h4 className="heading_Blue">
                                                What <b>people</b>  are saying<br /> <b>about us</b>
                                            </h4>
                                            <h5 className="innerSubtext mt-5 mb-5">
                                                Tai Savet <i className="fas fa-plus" style={plusStyle}></i> Luxury Real Estate Industry
                                            </h5>
                                            <p className="innerSubtext aboutusSubtext mt-2 mb-2 ">
                                                <i>
                                                    Marilyn is a trailblazer in diversity and inclusion. With unwavering determination, she has fearlessly broken barriers and transformed mindsets on a global scale. Her legacy will forever echo through generations, a testament to the remarkable impact one person can have on the world.
                                                </i>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="sliderItems col-md-12 col-lg-6">
                                        <div className="imgContainer">
                                            <img src={Images.clipImg} className="secImg" alt="User" />
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            <div className='item'>
                                <div className="sliderContainer row">
                                    <div className="sliderItems col-md-12 col-lg-12">
                                        <div className="slideContent">
                                            {/* <h4 className="heading_Blue">
                                                What <b>people</b>  are saying<br /> <b>about us</b>
                                            </h4> */}
                                            <p className="innerSubtext aboutusSubtext mt-2 mb-2 ">
                                                <i>
                                                    So many companies are looking for quality Suppliers overall and diverse Suppliers, in particular, for their Supplier Diversity Programs. The platform that Marilyn has developed meets the needs of all small businesses and gives them a shot at venturing into new markets and building out an even more robust customer base. And simultaneously, she has created a solution for Buyers who are often challenged with stale information on Suppliers. The ability for Suppliers to update their own profiles is a huge plus – fresh data for real opportunities. A game-changing platform for sure.
                                                </i>
                                            </p>
                                            <h5 className="innerSubtext my-3 text-center">
                                                Janet Smith <i className="fas fa-plus" style={plusStyle}></i> <b>President and Co-Founder, Ivy Planning Group</b>
                                            </h5>
                                            {/* <div className="text-center">
                                                <img src={Images.juiceBar} className="img-fluid juiceBarImg"/>
                                            </div> */}
                                        </div>
                                    </div>
                                    {/* <div className="sliderItems col-md-12 col-lg-6">
                                        <div className="imgContainer">
                                            <img src={Images.clipImg} className="secImg" alt="User" />
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className='item'>
                                <div className="sliderContainer row">
                                    <div className="sliderItems col-md-12 col-lg-12">
                                        <div className="slideContent">
                                            {/* <h4 className="heading_Blue">
                                                What <b>people</b>  are saying<br /> <b>about us</b>
                                            </h4> */}
                                            <p className="innerSubtext aboutusSubtext mt-2 mb-2 ">
                                                <i>
                                                    Marilyn has created an app to solve a real-world problem with elegance and efficiency. OPPSWell’s unique approach of merging social media into the procurement process is groundbreaking and truly sets it apart from other solutions on the market. Marilyn has shown an unwavering dedication to excellence in her development of OPPSWell and in the process not only identified a gap in the procurement landscape but successfully filled that gap with technology and an innovative, reliable and disruptive solution.
                                                </i>
                                            </p>
                                            <h5 className="innerSubtext my-3 text-center">
                                                Tai Savetsila, CEO <i className="fas fa-plus" style={plusStyle}></i><b>  Agents of LA</b>
                                            </h5>
                                            {/* <div className="text-center">
                                                <img src={Images.juiceBar} className="img-fluid juiceBarImg"/>
                                            </div> */}
                                        </div>
                                    </div>
                                    {/* <div className="sliderItems col-md-12 col-lg-6">
                                        <div className="imgContainer">
                                            <img src={Images.clipImg} className="secImg" alt="User" />
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className='item'>
                                <div className="sliderContainer row">
                                    <div className="sliderItems col-md-12 col-lg-12">
                                        <div className="slideContent">
                                            {/* <h4 className="heading_Blue">
                                                What <b>people</b>  are saying<br /> <b>about us</b>
                                            </h4> */}
                                            <p className="innerSubtext aboutusSubtext mt-2 mb-2 ">
                                                <i>
                                                    A fearless, strategic, quiet storm is how I would describe Marilyn. I have always been impressed by how she sees a problem and is the first in line to try to solve it – and not just for one side, but for all parties involved. After hearing her vision of OPPSWell and understanding the gap that she is filling, it is so “her” to identify what is missing in elevating small businesses and connecting them to larger companies thereby expanding their customer bases. And because small businesses are the lifeline of most economies, what OPPSWell will broker goes well beyond just the companies on her app. Stronger small businesses mean stronger neighborhoods, stronger economies – this is really big and who better to champion this than Marilyn.
                                                </i>
                                            </p>
                                            <h5 className="innerSubtext my-3 text-center">
                                                Sue Hrib <i className="fas fa-plus" style={plusStyle}></i> <b>Co-Founder & CEO, Boss Babe Brands, Inc.</b>
                                            </h5>
                                            {/* <div className="text-center">
                                                <img src={Images.juiceBar} className="img-fluid juiceBarImg"/>
                                            </div> */}
                                        </div>
                                    </div>
                                    {/* <div className="sliderItems col-md-12 col-lg-6">
                                        <div className="imgContainer">
                                            <img src={Images.clipImg} className="secImg" alt="User" />
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className='item'>
                                <div className="sliderContainer row">
                                    <div className="sliderItems col-md-12 col-lg-12">
                                        <div className="slideContent">
                                            {/* <h4 className="heading_Blue">
                                                What <b>people</b>  are saying<br /> <b>about us</b>
                                            </h4> */}
                                            <p className="innerSubtext aboutusSubtext mt-2 mb-2 ">
                                                <i>
                                                    I am not surprised that Marilyn has created such an innovative, revolutionary app because she is always looking for ways to help others and make their journey a lot less arduous. And just like this app is a testament to her commitment to making a positive impact on small businesses, she has used the same playbook in making positive changes in the lives of her scores of mentees around the world. She has left an indelible mark on those fortunate enough to have been under her guidance and I am honored to count myself amongst that group. With her vision and will to give back and make a difference, there is nowhere to continue to go but up!
                                                </i>
                                            </p>
                                            <h5 className="innerSubtext my-3 text-center">
                                                Charles Brooks <i className="fas fa-plus" style={plusStyle}></i> <b>MBA Candidate, University of Georgia</b>
                                            </h5>
                                            {/* <div className="text-center">
                                                <img src={Images.juiceBar} className="img-fluid juiceBarImg"/>
                                            </div> */}
                                        </div>
                                    </div>
                                    {/* <div className="sliderItems col-md-12 col-lg-6">
                                        <div className="imgContainer">
                                            <img src={Images.clipImg} className="secImg" alt="User" />
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className='item'>
                                <div className="sliderContainer row">
                                    <div className="sliderItems col-md-12 col-lg-12">
                                        <div className="slideContent">
                                            {/* <h4 className="heading_Blue">
                                                What <b>people</b>  are saying<br /> <b>about us</b>
                                            </h4> */}
                                            <p className="innerSubtext aboutusSubtext mt-2 mb-2 ">
                                                <i>
                                                    I am thrilled to bear witness to Marilyn’s exceptional work in creating this groundbreaking app. Having had the pleasure of experiencing OPPSWell firsthand, I am truly impressed with its innovation and user-friendly functionality. Its Business Resources will prove invaluable for small businesses.  The overall impact that OPPSWell is going to have in changing the landscape of the procurement process will be game-changing for sure.  I commend Marilyn for her outstanding contribution to the world of procurement and technology.
                                                </i>
                                            </p>
                                            <h5 className="innerSubtext my-3 text-center">
                                                Ivan Batista <i className="fas fa-plus" style={plusStyle}></i><b> Owner, Molly Maid of Central Las Vegas</b>
                                            </h5>
                                            {/* <div className="text-center">
                                                <img src={Images.juiceBar} className="img-fluid juiceBarImg"/>
                                            </div> */}
                                        </div>
                                    </div>
                                    {/* <div className="sliderItems col-md-12 col-lg-6">
                                        <div className="imgContainer">
                                            <img src={Images.clipImg} className="secImg" alt="User" />
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className='item'>
                                <div className="sliderContainer row">
                                    <div className="sliderItems col-md-12 col-lg-12">
                                        <div className="slideContent">
                                            {/* <h4 className="heading_Blue">
                                                What <b>people</b>  are saying<br /> <b>about us</b>
                                            </h4> */}
                                            <p className="innerSubtext aboutusSubtext mt-2 mb-2 ">
                                                <i>
                                                    Marilyn’s relentless pursuit of excellence is unparalleled. She possesses a rare combination of creative vision and technical expertise and that fusion is evident in every aspect of OPPSWell.  She is very focused on ensuring that OPPSWell is incredibly user-friendly for individuals of all technical backgrounds.  The seamless integration of cutting-edge features with real world procurement challenges demonstrates a deep understanding of user needs, problem solving and a commitment to delivering a superior product and experience.
                                                </i>
                                            </p>
                                            <h5 className="innerSubtext my-3 text-center">
                                                Karl Tombak <i className="fas fa-plus" style={plusStyle}></i> <b>Creative Director, BluePMG</b>
                                            </h5>
                                            {/* <div className="text-center">
                                                <img src={Images.juiceBar} className="img-fluid juiceBarImg"/>
                                            </div> */}
                                        </div>
                                    </div>
                                    {/* <div className="sliderItems col-md-12 col-lg-6">
                                        <div className="imgContainer">
                                            <img src={Images.clipImg} className="secImg" alt="User" />
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </OwlCarousel>
                    </div>
                </section>
                {/* End WhatPeopleSay Section */}
                {/* Have Questions start */}
                <div className="questionBox">
                    <div className="container">
                        <div className="queryBox">
                            <div className="row align-items-center">
                                <div className="col-lg-4">
                                    <h4 className="heading_Blue">
                                        Have Questions?<br></br><b>We Have Answers!</b>
                                    </h4>
                                    <button onClick={() => handleChange()} className="primaryBtnOther mt-5 d-flex justify-content-between align-items-center">
                                        <span class="me-3">Learn More</span>
                                        <i className="las la-angle-right"></i>
                                    </button>
                                </div>
                                <div className="col-lg-8">
                                    <figure>
                                        <img src={Images.haveQuestion} className="img-fluid haveQuestion" alt="haveQuestion" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Have Questions End */}
            </div >

            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                className={modalDetail.flag === "waitList" ? "commonWidth customContent" : modalDetail.flag === "HomeThumbnail" ? "HomeThumbnail" : ""}
                ids={modalDetail.flag === "waitList" ? "waitListModal" : modalDetail.flag === "HomeThumbnail" ? "HomeThumbnail" : "ComposeMessage"}
                child={
                    modalDetail.flag === "waitList" ? (
                        <WaitList
                            close={() => handleOnCloseModal()}
                        />
                    ) : modalDetail.flag === "HomeThumbnail" ? (
                        <HomeThumbnail
                            close={() => handleOnCloseModal()}
                            videoThumbnail={videoUrl}
                        />
                    ) :
                        ""
                }
                header={
                    <div className='modalHeader_'>
                        <div className="common_">
                            {modalDetail.flag === "waitList" ?
                                <>
                                    <h2 className="addLabels_heading">
                                        Join the Waitlist
                                    </h2>
                                    <p>Join the community for buyers & suppliers</p>
                                </>
                                : ""
                            }
                        </div>
                    </div>
                }

                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )

}

export default Home;