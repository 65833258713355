import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import * as Images from "../../../utilities/images";
import CustomModal from '../../components/common/shared/CustomModal';
import SuggestionBox from './suggestionBox';
import SoundbytesThumbnail from './soundbytesThumbnailsModal/soundbytesThumbnail';
import OwlCarousel from 'react-owl-carousel';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import BaseUrl from '../../../constants/baseUrl';
import { useAuthSelector } from '../../../redux/selector/auth';

const Resources = () => {
    const authSelector = useAuthSelector();
    const navigate = useNavigate()
    const [videoUrl, setVideoUrl] = useState("");
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
        desc: ""
    });
    const plusStyle = {
        color: "#388E1E",
        fontSize: "14px"
    }


    const chooseBusinessType = authSelector?.userStep?.user?.business_type ?? authSelector?.userStep?.business_type;

    const choosePlan = authSelector?.userStep?.user?.membership_details?.name ?? authSelector?.userStep?.membership_details?.name;


    //closeModal
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };

    // go to particular section
    const SectionToScroll = (flag) => {
        var section = document.getElementById(flag);
        if (section.id == "Knowledgeexchange") {
            section.scrollIntoView({ behavior: 'smooth' });
        }
        else if (section.id == "Mainstage") {
            section.scrollIntoView({ behavior: 'smooth' });
        }
        else if (section.id == "Paneldiscussion") {
            section.scrollIntoView({ behavior: 'smooth' });
        }
        else if (section.id == "spotlight") {
            section.scrollIntoView({ behavior: 'smooth' });
        }
        else if (section.id == "soundbytes") {
            section.scrollIntoView({ behavior: 'smooth' });
        }
        else if (section.id == "Sundayfunday") {
            section.scrollIntoView({ behavior: 'smooth' });
        }
        else if (section.id == "Virtualtrade") {
            section.scrollIntoView({ behavior: 'smooth' });
        }

    }

    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = "Resources";
    }, []);
    return (
        <>
            <div className='bussinessResourcePage'>
                <section className='commonContentBox  bussinessResoureCon homePage'>
                    {/* Start HomePage Section */}
                    {/* <div className='homeBanner resourceBanner pb-0'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-8'>
                                <div className='aboutContainer'>
                                    <div className=''>
                                        <h3 className='bannerHead mb-0'>
                                            Business <b>Resources</b>
                                        </h3>
                                        <p className='bannerTxt'>
                                            Building, sustaining and growing a business is a never-ending journey that involves continuous infusions of knowledge and wisdom.

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                    <section className="homeBanner landingHead_">
                        <div className="container">
                            <div className="row align-items-end">
                                <div className="col-lg-7 col-md-12">
                                    <div className="leftSec">
                                        <h3 className="heading_Blue mb-3">Business <b>Resources</b></h3>
                                        <p className="innerSubtext fw-normal">Building, sustaining and growing a business is a never-ending journey that involves continuous infusions of knowledge, wisdom and innovation. The <b>most successful companies</b> are run by people who are committed to being <b>lifelong learners.</b></p>
                                    </div>
                                </div>
                                {/* <div className="col-md-6  d-none d-lg-block">
                                <div className='position-relative w-100' onClick={() => {
                                    setVideoUrl('https://api.wellopps.itechnolabs.tech:3000/uploads/videos/business_resources.mp4')
                                    setModalDetail({ show: true, flag: "resourceThumbnail", });
                                    setKey(Math.random());
                                }}>
                                    <img
                                       
                                        src={Images.bannerImg}
                                        alt="Image"
                                        className="soundbiteImg h-100" style={{ cursor: 'pointer' }}
                                    />
                                </div>
                            </div> */}
                            </div>
                        </div>
                    </section>
                    {/* End HomePage Section */}
                    {/* Start AboutMenus Section */}
                    {choosePlan == "Gold" || choosePlan == "Platinum" ? <section className='aboutMenus resourceMenu p-0'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-9'>
                                    <ul className='aboutMenusContainer'>
                                        <li className='aboutItem'>
                                            <button onClick={() => SectionToScroll("soundbytes")} className='aboutLinks'>SoundBytes</button>
                                        </li>
                                        <div className="dropdown">
                                            <li className='aboutItem dropdown-toggle' id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                <Link className='aboutLinks'>Browse Resources</Link>
                                            </li>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li className="dropdownBox"><button className="dropdown-item" onClick={() => SectionToScroll("Knowledgeexchange")} >Knowledge Exchanges</button></li>
                                                <li className="dropdownBox"><button className="dropdown-item" onClick={() => SectionToScroll("Mainstage")} >Mainstage Learnings</button></li>
                                                <li className="dropdownBox"><button className="dropdown-item" onClick={() => SectionToScroll("Paneldiscussion")} >Panel Discussions</button></li>
                                                <li className="dropdownBox"><button className="dropdown-item" onClick={() => SectionToScroll("Virtualtrade")} >Virtual Trade Fairs</button></li>

                                            </ul>
                                        </div>
                                        <li className='aboutItem'>
                                            <button onClick={() => SectionToScroll("Sundayfunday")} className='aboutLinks'>Sunday Fundays</button>
                                        </li>
                                        <li className='aboutItem'>
                                            <button onClick={() => SectionToScroll("spotlight")} className='aboutLinks'>Diversity Certifications</button>
                                        </li>


                                    </ul>
                                </div>
                                <div className='col-md-3 text-end'>
                                <div className='suggestionBox m-0'>
                                    <button className='primaryBtn'
                                    onClick={()=>{navigate('/contact-us',{state:{path:"suggestion-box"}})}}
                                    //  onClick={() => {
                                    //     setModalDetail({ show: true, flag: "suggestionbox", });
                                    //     setKey(Math.random());
                                    // }} 
                                    
                                    >Suggestion Box
                                        <img src={Images.Chat} alt="Icon" className="img-fluid suggestionIcon" />
                                    </button>
                                </div>
                            </div>
                                {/* </div> */}
                            </div>
                        </div>
                    </section> : ""}


                    {/* Start AboutMenus Section */}
                    {/* Start AboutFounder Section */}
                    <section className='newAboutFounder'>
                        <div className='container'>
                            <div className='row align-items-center'>
                                <div className='col-md-12 col-lg-6 order-last'>
                                    <div className='liveStreame'>
                                        <img className='streamImg' src={Images.livestreamImg} />
                                        {/* <img className='streamImgchat' src={Images.streamchat} /> */}
                                    </div>
                                </div>
                                <div className='col-md-12 col-lg-6 pe-4'>
                                    <div className='mb-4'>
                                        <h5 className='heading_Blue mb-4 fw-normal letterSpace'>
                                            <b>Recognizing</b> that knowledge is power, <b>OPPSWell</b> supports the evolutionary process of learning and offers a number of <b>business development programs</b> to fit the schedule and educational needs of its Members

                                        </h5>
                                    </div>
                                    <p className='innerSubtext txt20'>
                                        Programs cover a wide range of topics including how to enhance contracting opportunities, selecting the right corporate structure, how to better market your business, how to navigate company RFP processes and more.
                                    </p>

                                </div>

                            </div>
                        </div>
                    </section>
                    {/* End AboutFounder Section */}
                    {/* Start AboutFounder Section */}
                    {/* <section className='aboutFounder relevenceMain_'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12 col-lg-6 pe-4'>
                                <div className='liveStreame'>
                                    <img className='streamImg' src={Images.relavenceImg} />
                                    <img className='streamImgchat' src={Images.streamchat} />
                                </div>
                            </div>
                            <div className='col-md-12 col-lg-6 '>
                                <div className='ralevanceRight_'>
                                    <div className='mb-3'>
                                        <h5 className='anotherHeading'>Why Supplier <strong className='ColoredText'>Diversity Matters</strong>

                                        </h5>
                                    </div>
                                    <p className='bannerTxt mb-4'>
                                        Diverse Suppliers contribute to Buyers and Suppliers success because they:
                                    </p>
                                    <ul className='diversityList_ mb-5'>
                                        <li className='bannerTxt'>
                                            Serve untapped niche markets
                                        </li>
                                        <li className='bannerTxt'>
                                            Provide untapped location demographics
                                        </li>
                                        <li className='bannerTxt'>
                                            Provide alternative sourcing strategies
                                        </li>
                                        <li className='bannerTxt'>
                                            Reduce the risks of supply chain disruptions
                                        </li>
                                        <li className='bannerTxt'>
                                            Supports the growth of underrepresented businesses
                                        </li>
                                        <li className='bannerTxt'>
                                            Demonstrates a commitment to social responsibility
                                        </li>
                                        <li className='bannerTxt'>
                                            Demonstrates a commitment to inclusion
                                        </li>
                                        <li className='bannerTxt'>
                                            Increases brand loyalty
                                        </li>
                                        <li className='bannerTxt'>
                                            Increases positive public perception
                                        </li>
                                    </ul>
                                    <p className='bannerTxt'>One of the goals of OPPSWell is to put diverse businesses on global procurement radar screens. </p>

                                    <p className='bannerTxt ColoredText'><strong className='fontNineteen'>That is the OPPSWell Win-Win!!</strong></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}


                    {/* Start Waveless section */}
                    {/* <section className='waveless mt-5 memberShipSpot' id="spotlight">
                    <div className='container'>
                        <h3 className='otherHeading_  mb-3'><b>Member Spotlight</b></h3>
                        <div className='motionSec'>
                            <div className='row'>
                                <div className='col-lg-3 text-center'>
                                    <img src={Images.WaveLessLogo} alt='WaveLessLogo' className='img-fluid WaveLessLogo mb-2' />
                                    <img src={Images.WaveLess} alt='WaveLess' className='img-fluid WaveLess' />
                                </div>
                                <div className='col-lg-8 offset-1'>
                                    <div className='mb-3'>
                                        <p className='wavesubText'>Each Week OPPSWell Highlights one of our Members, Sharing their unique talents and services with our entire community.</p>
                                        <p className='wavesubText'>Waveless provide solar energy to coporate warehouses and distribution centers around the world. They have the most urban solar farms to date and joined OPPSWell because they wanted a networking tool that would expand their footprint in the United States and Globally.</p>
                                    </div>
                                    <div className='text-end'>
                                        <button className='secondaryBtnOuter me-2'>View Profile</button>
                                        <button className='secondaryBtnGrey'>View More</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                    {/* End Waveless section */}

                    {/* Start Previous Spotlights section */}
                    {/* <div className='spotlights mt-5'>
                    <div className="container">
                        <h3 className='otherHeading_  mb-3'><b>Previous Spotlights</b></h3>
                        <OwlCarousel items={4}
                            className="owl-theme"
                            loop
                            nav
                            dots={false}
                            margin={15}
                            responsive={{
                                0: {
                                    items: 1
                                },
                                600: {
                                    items: 2
                                },
                                991: {
                                    items: 3
                                },
                                1000: {
                                    items: 4
                                }
                            }}
                        >
                            <div className='motionSec text-center'>
                                <img src={Images.SpotlightsOne} alt='Spotlights' className='img-fluid Spotlights mb-2' />
                                <h4>Elevate</h4>
                                <p>Elevating Brands to realize all of their brand and marketing goals</p>
                                <button className='secondaryBtnOuter me-2'>View Profile</button>
                            </div>
                            <div className='motionSec text-center'>
                                <img src={Images.SpotlightsTwo} alt='Spotlights' className='img-fluid Spotlights mb-2' />
                                <h4>Elevate</h4>
                                <p>Elevating Brands to realize all of their brand and marketing goals</p>
                                <button className='secondaryBtnOuter me-2'>View Profile</button>
                            </div>
                            <div className='motionSec text-center'>
                                <img src={Images.SpotlightsThree} alt='Spotlights' className='img-fluid Spotlights mb-2' />
                                <h4>Elevate</h4>
                                <p>Elevating Brands to realize all of their brand and marketing goals</p>
                                <button className='secondaryBtnOuter me-2'>View Profile</button>
                            </div>
                            <div className='motionSec text-center'>
                                <img src={Images.SpotlightsFour} alt='Spotlights' className='img-fluid Spotlights mb-2' />
                                <h4>Elevate</h4>
                                <p>Elevating Brands to realize all of their brand and marketing goals</p>
                                <button className='secondaryBtnOuter me-2'>View Profile</button>
                            </div>
                            <div className='motionSec text-center'>
                                <img src={Images.SpotlightsOne} alt='Spotlights' className='img-fluid Spotlights mb-2' />
                                <h4>Elevate</h4>
                                <p>Elevating Brands to realize all of their brand and marketing goals</p>
                                <button className='secondaryBtnOuter me-2'>View Profile</button>
                            </div>
                        </OwlCarousel>
                    </div>
                </div> */}
                    {/* End Previous Spotlights section */}

                    {/* Start SoundBites Section  */}
                    <section className='mainSoundbites_ mt-5' id='soundbytes'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-10'>
                                    <div className='topheadingBites'>
                                        <h3 className='otherHeading_ '><b>SoundBytes</b></h3>
                                        <p>SoundBytes are {'<'}5 minute on-demand videos on a variety of topics curated by the OPPSWell team.</p>
                                    </div>
                                </div>
                            </div>
                            <OwlCarousel items={4}
                                className="owl-theme"
                                loop
                                nav
                                dots={false}
                                margin={15}
                                responsive={{
                                    0: {
                                        items: 1
                                    },
                                    600: {
                                        items: 2
                                    },
                                    991: {
                                        items: 3
                                    },
                                    1000: {
                                        items: 4
                                    }
                                }}
                            >
                                <div className='bitesCrad'>

                                    <div className='position-relative' onClick={() => {
                                        setVideoUrl(`${BaseUrl.API_URL}uploads/videos/certification_process.mp4`)
                                        setModalDetail({ show: true, flag: "soundbytesThumbnail", title: "Certification Process", desc: "Check out this SoundByte to assist in Making informed decisions Certifications" });
                                        setKey(Math.random());
                                    }}>
                                        <img
                                            src={`${BaseUrl.API_URL}uploads/videothumbnail/puzzle_video_icon.jpg`}
                                            alt="Image"
                                            className="soundbiteImg"
                                        /> 
                                        <img
                                            src={Images.VideoIcon}
                                            alt="Image"
                                            className="videoBtn"
                                        />
                                    </div>
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Certification Process</b></h6>
                                        <p className='listHead_'>Check out this SoundByte to assist in Making
                                            informed decisions Certifications</p>
                                    </div>
                                </div>
                                <div className='bitesCrad'>
                                    <div className='position-relative' onClick={() => {
                                        setVideoUrl(`${BaseUrl.API_URL}uploads/videos/joint_venture.mp4`)
                                        setModalDetail({ show: true, flag: "soundbytesThumbnail", title: "Doing a Joint Venture", desc: "Check out this SoundByte to assist in understanding joint ventures." });
                                        setKey(Math.random());
                                    }}>
                                        <img
                                            src={`${BaseUrl.API_URL}uploads/videothumbnail/puzzle_video_icon.jpg`}
                                            alt="Image"
                                            className="soundbiteImg"
                                        />
                                        <img
                                            src={Images.VideoIcon}
                                            alt="Image"
                                            className="videoBtn"
                                        />
                                    </div>
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Doing a Joint Venture</b></h6>
                                        <p className='listHead_'>Check out this SoundByte to assist in understanding
                                            joint ventures.</p>
                                    </div>
                                </div>
                                <div className='bitesCrad'>
                                    <div className='position-relative' onClick={() => {
                                        setVideoUrl(`${BaseUrl.API_URL}uploads/videos/avoiding_being_victimized_by_phishing_scams.mp4`)
                                        setModalDetail({ show: true, flag: "soundbytesThumbnail", title: "Avoid Being Victimized by Scams", desc: "Check out this SoundByte to learn how to avoid being victimized by scams" });
                                        setKey(Math.random());
                                    }}>
                                        <img
                                            src={`${BaseUrl.API_URL}uploads/videothumbnail/puzzle_video_icon.jpg`}
                                            alt="Image"
                                            className="soundbiteImg"
                                        />
                                        <img
                                            src={Images.VideoIcon}
                                            alt="Image"
                                            className="videoBtn"
                                        />
                                    </div>
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Avoid Being Victimized by Scams</b></h6>
                                        <p className='listHead_'>Check out this SoundByte to learn how to avoid
                                            being victimized by scams</p>
                                    </div>
                                </div>
                                <div className='bitesCrad'>
                                    <div className='position-relative' onClick={() => {
                                        setVideoUrl(`${BaseUrl.API_URL}uploads/videos/social_media_marketing.mp4`)
                                        setModalDetail({ show: true, flag: "soundbytesThumbnail", title: "Social Media", desc: "Check out this SoundByte to learn how to leverage Social Media as a marketing and branding tool." });
                                        setKey(Math.random());
                                    }}>
                                        <img
                                            src={`${BaseUrl.API_URL}uploads/videothumbnail/puzzle_video_icon.jpg`}
                                            alt="Image"
                                            className="soundbiteImg"
                                        />
                                        <img
                                            src={Images.VideoIcon}
                                            alt="Image"
                                            className="videoBtn"
                                        />
                                    </div>
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Social Media</b></h6>
                                        <p className='listHead_'>Check out this SoundByte to learn how to leverage
                                            Social Media as a marketing and branding tool.</p>
                                    </div>
                                </div>
                                <div className='bitesCrad'>
                                    <div className='position-relative' onClick={() => {
                                        setVideoUrl(`${BaseUrl.API_URL}uploads/videos/building_a_support_system_for_your_business.mp4`)
                                        setModalDetail({ show: true, flag: "soundbytesThumbnail", title: "Building A Support System for your Business", desc: "Check out this SoundByte to assist in building a support system for your business" });
                                        setKey(Math.random());
                                    }}>
                                        <img
                                            src={`${BaseUrl.API_URL}uploads/videothumbnail/puzzle_video_icon.jpg`}
                                            alt="Image"
                                            className="soundbiteImg"
                                        />
                                        <img
                                            src={Images.VideoIcon}
                                            alt="Image"
                                            className="videoBtn"
                                        />
                                    </div>
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Building A Support System for your Business</b></h6>
                                        <p className='listHead_'>Check out this SoundByte to assist in building a
                                            support system for your business</p>
                                    </div>
                                </div>
                                <div className='bitesCrad'>
                                    <div className='position-relative' onClick={() => {
                                        setVideoUrl(`${BaseUrl.API_URL}uploads/videos/marketing_your_business.mp4`)
                                        setModalDetail({ show: true, flag: "soundbytesThumbnail", title: "Marketing your Business", desc: "Check out this SoundByte a quick guide to Marketing 101." });
                                        setKey(Math.random());
                                    }}>
                                        <img
                                            src={`${BaseUrl.API_URL}uploads/videothumbnail/puzzle_video_icon.jpg`}
                                            alt="Image"
                                            className="soundbiteImg"
                                        />
                                        <img
                                            src={Images.VideoIcon}
                                            alt="Image"
                                            className="videoBtn"
                                        />
                                    </div>
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Marketing your Business</b></h6>
                                        <p className='listHead_'>Check out this SoundByte a quick guide to Marketing
                                            101.</p>
                                    </div>
                                </div>
                                <div className='bitesCrad'>
                                    <div className='position-relative' onClick={() => {
                                        setVideoUrl(`${BaseUrl.API_URL}uploads/videos/bankrupt.mp4`)
                                        setModalDetail({ show: true, flag: "soundbytesThumbnail", title: "How Not to Go Bankrupt", desc: "Check out this SoundByte to assist in best practices for your business’ financial management." });
                                        setKey(Math.random());
                                    }}>
                                        <img
                                            src={`${BaseUrl.API_URL}uploads/videothumbnail/puzzle_video_icon.jpg`}
                                            alt="Image"
                                            className="soundbiteImg"
                                        />
                                        <img
                                            src={Images.VideoIcon}
                                            alt="Image"
                                            className="videoBtn"
                                        />
                                    </div>
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>How Not to Go Bankrupt</b></h6>
                                        <p className='listHead_'>Check out this SoundByte to assist in best
                                            practices for your business’ financial
                                            management.</p>
                                    </div>
                                </div>
                                <div className='bitesCrad'>
                                    <div className='position-relative' onClick={() => {
                                        setVideoUrl(`${BaseUrl.API_URL}uploads/videos/contracting_opportunities.mp4`)
                                        setModalDetail({ show: true, flag: "soundbytesThumbnail", title: "Contracting Opportunities", desc: "Check out this SoundByte to find insight on contracting opportunities." });
                                        setKey(Math.random());
                                    }}>
                                        <img
                                            src={`${BaseUrl.API_URL}uploads/videothumbnail/puzzle_video_icon.jpg`}
                                            alt="Image"
                                            className="soundbiteImg"
                                        />
                                        <img
                                            src={Images.VideoIcon}
                                            alt="Image"
                                            className="videoBtn"
                                        />
                                    </div>
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Contracting Opportunities</b></h6>
                                        <p className='listHead_'>Check out this SoundByte to find insight on
                                            contracting opportunities.</p>
                                    </div>
                                </div>
                                <div className='bitesCrad'>
                                    <div className='position-relative' onClick={() => {
                                        setVideoUrl(`${BaseUrl.API_URL}uploads/videos/to_incorporate_your_business_or_not.mp4`)
                                        setModalDetail({ show: true, flag: "soundbytesThumbnail", title: "Identifying Opportunities", desc: "Check out this SoundByte to assist in how to identify the best opportunities for your business." });
                                        setKey(Math.random());
                                    }}>
                                        <img
                                            src={`${BaseUrl.API_URL}uploads/videothumbnail/puzzle_video_icon.jpg`}
                                            alt="Image"
                                            className="soundbiteImg"
                                        />
                                        <img
                                            src={Images.VideoIcon}
                                            alt="Image"
                                            className="videoBtn"
                                        />
                                    </div>
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Identifying Opportunities</b></h6>
                                        <p className='listHead_'>Check out this SoundByte to assist in how to
                                            identify the best opportunities for your business.</p>
                                    </div>
                                </div> 
                                <div className='bitesCrad'>
                                    <div className='position-relative' onClick={() => {
                                        setVideoUrl(`${BaseUrl.API_URL}uploads/videos/identifying_opportunities_that_fit_your_business_capabilities.mp4`)
                                        setModalDetail({ show: true, flag: "soundbytesThumbnail", title: "To incorporate Your Business or Not", desc: "Check out this SoundByte to understand a quick breakdown of business structures and their benefits." });
                                        setKey(Math.random());
                                    }}>
                                        <img src={`${BaseUrl.API_URL}uploads/videothumbnail/puzzle_video_icon.jpg`}
                                            alt="Image"
                                            className="soundbiteImg"
                                        />
                                        <img
                                            src={Images.VideoIcon}
                                            alt="Image"
                                            className="videoBtn"
                                        />
                                    </div>

                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>To incorporate Your Business or Not</b></h6>
                                        <p className='listHead_'>Check out this SoundByte for a quick breakdown of business structures and their benefits.</p>
                                    </div>
                                </div>
                            </OwlCarousel>

                        </div>
                    </section >
                    {/* End SoundBites Section  */}
                    {/* Start Knowledge Section  */}
                    {/* <section className='mainSoundbites_' id='Sundayfunday'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-10'>
                                <div className='topheadingBites'>
                                    <h3 className='otherHeading_ '><b>Sunday Funday</b></h3>
                                    <p>Get to know others on the OPPSWell platform in a fun, virtual, interactive monthly game session where companies compete against each other for a prize sponsored by OPPSWell.</p>
                                </div>
                            </div>
                        </div>
                        <OwlCarousel items={4}
                            className="owl-theme"
                            loop
                            nav
                            dots={false}
                            margin={15}
                            responsive={{
                                0: {
                                    items: 1
                                },
                                600: {
                                    items: 2
                                },
                                991: {
                                    items: 3
                                },
                                1000: {
                                    items: 4
                                }
                            }}
                        >
                            <div className='bitesCrad'>
                                <img className='soundbiteImg' src={Images.familyGameNight} />
                                <div className='soundbiteContent'>
                                    <h6 className='headingSmall_mb-2'> <b>
                                        
                                        Sunday Funday
                                    </b></h6>
                                   
                                </div>
                            </div>
                            <div className='bitesCrad'>
                                <img className='soundbiteImg' src={Images.sundayFunday} />
                                <div className='soundbiteContent'>
                                    <h6 className='headingSmall_mb-2'> <b>
                                        
                                        Sunday Funday
                                    </b></h6>
                                    
                                </div>
                            </div>
                            <div className='bitesCrad'>
                                <img className='soundbiteImg' src={Images.sundayFundayOne} />
                                <div className='soundbiteContent'>
                                    <h6 className='headingSmall_mb-2'> <b>
                                       
                                        Sunday Funday
                                    </b></h6>
                                   
                                </div>
                            </div>
                            <div className='bitesCrad'>
                                <div className='position-relative' onClick={() => {
                                    setVideoUrl("https://api.oppswell.com:3000/uploads/videos/sunday_funday_video_editted_08072023.mp4")
                                    setModalDetail({ show: true, flag: "soundbytesThumbnail", });
                                    setKey(Math.random());
                                }}>
                                    <img src="https://api.wellopps.itechnolabs.tech:3000/uploads/videothumbnail/sunday_funday.png"
                                        alt="Image"
                                        className="soundbiteImg"
                                    />
                                    <img
                                        src={Images.VideoIcon}
                                        alt="Image"
                                        className="videoBtn"
                                    />
                                </div>
                                <div className='soundbiteContent'>
                                    <h6 className='headingSmall_mb-2'> <b>
                                        Sunday Funday
                                    </b></h6>
                                   
                                </div>
                            </div>
                            <div className='bitesCrad'>
                                <div className='position-relative' onClick={() => {
                                    setVideoUrl("https://api.oppswell.com:3000/uploads/videos/sunday_funday_1.mp4")
                                    setModalDetail({ show: true, flag: "soundbytesThumbnail", });
                                    setKey(Math.random());
                                }}>
                                    <img src="https://api.wellopps.itechnolabs.tech:3000/uploads/videothumbnail/sunday_funday.png"
                                        alt="Image"
                                        className="soundbiteImg"
                                    />
                                    <img
                                        src={Images.VideoIcon}
                                        alt="Image"
                                        className="videoBtn"
                                    />
                                </div>
                                <div className='soundbiteContent'>
                                    <h6 className='headingSmall_mb-2'> <b>
                                      
                                        Sunday Funday
                                    </b></h6>
                                   
                                </div>
                            </div>
                            <div className='bitesCrad'>
                                <img className='soundbiteImg' src={Images.familyGameNight} />
                                <div className='soundbiteContent'>
                                    <h6 className='headingSmall_mb-2'> <b>
                                       
                                        Sunday Funday
                                    </b></h6>
                                 
                                </div>
                            </div>
                            <div className='bitesCrad'>
                                <img className='soundbiteImg' src={Images.sundayFunday} />
                                <div className='soundbiteContent'>
                                    <h6 className='headingSmall_mb-2'> <b>
                                        Sunday Funday
                                    </b></h6>
                                  
                                </div>
                            </div>
                            <div className='bitesCrad'>
                                <img className='soundbiteImg' src={Images.sundayFundayOne} />
                                <div className='soundbiteContent'>
                                    <h6 className='headingSmall_mb-2'> <b>
                                        Sunday Funday
                                    </b></h6>
                                   
                                </div>
                            </div>
                            <div className='bitesCrad'>
                                <div className='position-relative' onClick={() => {
                                    setVideoUrl("https://api.oppswell.com:3000/uploads/videos/sunday_funday_video_editted_08072023.mp4")
                                    setModalDetail({ show: true, flag: "soundbytesThumbnail", });
                                    setKey(Math.random());
                                }}>
                                    <img src="https://api.wellopps.itechnolabs.tech:3000/uploads/videothumbnail/sunday_funday.png"
                                        alt="Image"
                                        className="soundbiteImg"
                                    />
                                    <img
                                        src={Images.VideoIcon}
                                        alt="Image"
                                        className="videoBtn"
                                    />
                                </div>
                                <div className='soundbiteContent'>
                                    <h6 className='headingSmall_mb-2'> <b>
                                        Sunday Funday
                                    </b></h6>
                                 
                                </div>
                            </div>
                            <div className='bitesCrad'>
                                <div className='position-relative' onClick={() => {
                                    setVideoUrl("https://api.oppswell.com:3000/uploads/videos/sunday_funday_1.mp4")
                                    setModalDetail({ show: true, flag: "soundbytesThumbnail", });
                                    setKey(Math.random());
                                }}>
                                    <img src="https://api.wellopps.itechnolabs.tech:3000/uploads/videothumbnail/sunday_funday.png"
                                        alt="Image"
                                        className="soundbiteImg"
                                    />
                                    <img 
                                        src={Images.VideoIcon}
                                        alt="Image"
                                        className="videoBtn"
                                    />
                                </div>
                                <div className='soundbiteContent'>
                                    <h6 className='headingSmall_mb-2'> <b>
                                        Sunday Funday
                                    </b></h6>
                                   
                                </div>
                            </div>

                        </OwlCarousel>
                       
                    </div>
                </section> */}
                    {/* End Knowledge Section  */}
                    {/* <section className='mainSoundbites_ mainstagebox_ '>
                    <div id="Knowledgeexchange" className='container '>
                        <div className='row '>
                            <div className='col-lg-10'>
                                <div className='topheadingBites'>
                                    <h3 className='otherHeading_ '><b>Knowledge Exchange</b></h3>
                                    <p>Knowledge Exchange are 45-minute sessions on a variety of topics curated by the OPPSWell team that subscribers can watch/listen to on demand
                                    </p>
                                </div>
                            </div>
                        </div>
                       
                        <div className='sectionDisable'>
                            <OwlCarousel items={4}
                                className="owl-theme"
                                loop
                                nav
                                dots={false}
                                margin={15}
                                responsive={{
                                    0: {
                                        items: 1
                                    },
                                    600: {
                                        items: 2
                                    },
                                    991: {
                                        items: 3
                                    },
                                    1000: {
                                        items: 4
                                    }
                                }}
                            >
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbiteImg} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Recover lost emails</b></h6>
                                        <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite2} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Navigating the Web</b></h6>
                                        <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite3} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Creating a safe work area</b></h6>
                                        <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite4} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Team building exercises</b></h6>
                                        <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite4} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Team building exercises</b></h6>
                                        <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite4} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Team building exercises</b></h6>
                                        <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                            </OwlCarousel>
                        </div>
                    </div>
                    <div className='mainstagebtn'>
                        <button className='primaryBtn w-100 mt-2'>Premium Membership Required</button>
                    </div>
                </section> */}
                    {/* Start Mainstage Learning Section  */}
                    {/* <section className='mainSoundbites_ mainstagebox_ ' id="Mainstage">
                    <div className='container '>
                        <div className='row '>
                            <div className='col-lg-10'>
                                <div className='topheadingBites'>
                                    <h3 className='otherHeading_ '><b>Mainstage Learning</b></h3>
                                    <p>Attend 60 minute virtual learning sessions hosted by Platinum members on a topic of their choosing that will be both live and pre-recorded, with subsequent on-demand viewing.</p>
                                </div>
                            </div>
                        </div>
                        <div className='sectionDisable'>
                            <OwlCarousel items={4}
                                className="owl-theme"
                                loop
                                nav
                                dots={false}
                                margin={15}
                                responsive={{
                                    0: {
                                        items: 1
                                    },
                                    600: {
                                        items: 2
                                    },
                                    991: {
                                        items: 3
                                    },
                                    1000: {
                                        items: 4
                                    }
                                }}
                            >
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbiteImg} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Recover lost emails</b></h6>
                                        <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite2} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Navigating the Web</b></h6>
                                        <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite3} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Creating a safe work area</b></h6>
                                        <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite4} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Team building exercises</b></h6>
                                        <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite4} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Team building exercises</b></h6>
                                        <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite4} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Team building exercises</b></h6>
                                        <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                            </OwlCarousel>
                        </div>
                        
                    </div>
                    <div className='mainstagebtn'>
                        <button className='primaryBtn w-100 mt-2'>Premium Membership Required</button>
                    </div>
                </section> */}
                    {/* End Mainstage Learning Section  */}
                    {/* <section className='mainSoundbites_ mainstagebox_ ' id="Paneldiscussion">
                    <div className='container '>
                        <div className='row '>
                            <div className='col-lg-10'>
                                <div className='topheadingBites'>
                                    <h3 className='otherHeading_ '><b>Panel Discussions
                                    </b></h3>
                                    <p>Increase your knowledge via panel discussions of experts on a variety of topics that can help companies enhance their business performance and their chances of success in the procurement process.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='sectionDisable'>
                            <OwlCarousel items={4}
                                className="owl-theme"
                                loop
                                nav
                                dots={false}
                                margin={15}
                                responsive={{
                                    0: {
                                        items: 1
                                    },
                                    600: {
                                        items: 2
                                    },
                                    991: {
                                        items: 3
                                    },
                                    1200: {
                                        items: 4
                                    }
                                }}
                            >
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbiteImg} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Recover lost emails</b></h6>
                                        <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite2} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Navigating the Web</b></h6>
                                        <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite3} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Creating a safe work area</b></h6>
                                        <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite4} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Team building exercises</b></h6>
                                        <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite4} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Team building exercises</b></h6>
                                        <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite4} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Team building exercises</b></h6>
                                        <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                            </OwlCarousel>
                        </div>
                       
                    </div>
                    <div className='mainstagebtn'>
                        <button className='primaryBtn w-100 mt-2'>Premium Membership Required</button>
                    </div>
                </section> */}
                    {/* <section className='mainSoundbites_ mainstagebox_ ' id='Virtualtrade'>
                    <div className='container '>
                        <div className='row '>
                            <div className='col-lg-10'>
                                <div className='topheadingBites'>
                                    <h3 className='otherHeading_ '><b>Virtual Trade Show
                                    </b></h3>
                                    <p>Save precious time and money by foregoing in-person conferences and attending virtual trade fairs on the OPPSWell platform that can help “match-make”  Buyers and Suppliers.</p>
                                </div>
                            </div>
                        </div>
                        <div className='sectionDisable'>
                            <OwlCarousel items={4}
                                className="owl-theme"
                                loop
                                nav
                                dots={false}
                                margin={15}
                                responsive={{
                                    0: {
                                        items: 1
                                    },
                                    600: {
                                        items: 2
                                    },
                                    991: {
                                        items: 3
                                    },
                                    1000: {
                                        items: 4
                                    }
                                }}
                            >
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbiteImg} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Recover lost emails</b></h6>
                                        <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite2} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Navigating the Web</b></h6>
                                        <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite3} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Creating a safe work area</b></h6>
                                        <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite4} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Team building exercises</b></h6>
                                        <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite4} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Team building exercises</b></h6>
                                        <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite4} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Team building exercises</b></h6>
                                        <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                            </OwlCarousel>
                        </div>
                    </div>
                    <div className='mainstagebtn'>
                        <button className='primaryBtn w-100 mt-2'>Premium Membership Required</button>
                    </div>
                </section> */}
                    {choosePlan == "Gold" || choosePlan == "Platinum" ?
                        <section className='knowledgeExchange mb-5' id='Knowledgeexchange'>
                            <div className='container'>
                                <div className='knowledgeExchangeInner'>
                                    <div className='row align-items-center'>
                                        <div className='col-md-6 pe-0'>
                                            <div className='knowledgeInnerLeft'>
                                                <img src={Images.knowledgeExchange} className='img-fluid bussinessImgs' alt="image" />
                                            </div>
                                        </div>
                                        <div className='col-md-6 ps-0'>
                                            <div className='knowledgeInnerRight'>
                                                <h5 className='heading_Blue mb-4 fw-normal letterSpace'>
                                                    Knowledge <b>Exchanges</b>
                                                </h5>
                                                <p className='innerSubtext txt20'>
                                                    Knowledge Exchanges are 45-minute live and <br /> on-demand sessions on a variety of topics curated by<br /> the OPPSWell team.
                                                </p>
                                                <button className='premiumBtn mt-5'>PREMIUM MEMBERSHIP REQUIRED</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section> : ""
                    }

                    <section className='knowledgeExchange knowledgeExchangeReverse mb-5' id='Mainstage'>
                        <div className='container'>
                            <div className='knowledgeExchangeInner'>
                                <div className='row align-items-center'>
                                    <div className='col-md-6 pe-0'>
                                        <div className='knowledgeInnerRight'>
                                            <h5 className='heading_Blue mb-4 fw-normal letterSpace'>
                                                Mainstage <b>Learnings</b>
                                            </h5>
                                            <p className='innerSubtext txt20'>
                                                Mainstage Learnings are 60-minute sessions<br /> hosted by Platinum members on a topic of their<br /> choosing that will be both live and recorded for<br /> on-demand viewing.
                                            </p>
                                            <button className='premiumBtn mt-5'>PREMIUM MEMBERSHIP REQUIRED</button>
                                        </div>
                                    </div>
                                    <div className='col-md-6 ps-0'>
                                        <div className='knowledgeInnerLeft'>
                                            <img src={Images.knowledge4} className='img-fluid bussinessImgs' alt="image" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className='knowledgeExchange mb-5' id='Paneldiscussion'>
                        <div className='container'>
                            <div className='knowledgeExchangeInner'>
                                <div className='row align-items-center'>
                                    <div className='col-md-6 pe-0'>
                                        <div className='knowledgeInnerLeft'>
                                            <img src={Images.knowledge3} className='img-fluid bussinessImgs' alt="image" />
                                        </div>
                                    </div>
                                    <div className='col-md-6 ps-0'>
                                        <div className='knowledgeInnerRight'>
                                            <h5 className='heading_Blue mb-4 fw-normal letterSpace'>
                                                Panel <b>Discussions</b>
                                            </h5>
                                            <p className='innerSubtext txt20'>
                                                Increase your knowledge via panel discussions of<br /> experts on a variety of topics that can help companies<br /> enhance their business performance and their chances<br /> of success in the procurement process.
                                            </p>
                                            <button className='premiumBtn mt-5'>PREMIUM MEMBERSHIP REQUIRED</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className='knowledgeExchange knowledgeExchangeReverse mb-5' id='Virtualtrade'>
                        <div className='container'>
                            <div className='knowledgeExchangeInner'>
                                <div className='row align-items-center'>
                                    <div className='col-md-6 pe-0'>
                                        <div className='knowledgeInnerRight'>
                                            <h5 className='heading_Blue mb-4 fw-normal letterSpace'>
                                                Virtual Trade Fairs
                                            </h5>
                                            <p className='innerSubtext txt20'>
                                                Participate in virtual Trade Fairs on the OPPSWell<br /> platform that are designed to “match-make” Buyers<br /> and Suppliers.
                                            </p>
                                            <button className='premiumBtn mt-5'>PREMIUM MEMBERSHIP REQUIRED</button>
                                        </div>
                                    </div>
                                    <div className='col-md-6 ps-0'>
                                        <div className='knowledgeInnerLeft'>
                                            <img src={Images.knowledge33} className='img-fluid bussinessImgs' alt="image" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className='knowledgeExchange mb-5' id='Sundayfunday'>
                        <div className='container'>
                            <div className='knowledgeExchangeInner'>
                                <div className='row align-items-center'>
                                    <div className='col-md-6 pe-0'>
                                        <div className='knowledgeInnerLeft'>
                                            <img src={Images.knowledge5} className='img-fluid bussinessImgs' alt="image" />
                                        </div>
                                    </div>
                                    <div className='col-md-6 ps-0'>
                                        <div className='knowledgeInnerRight'>
                                            <h5 className='heading_Blue mb-4 fw-normal letterSpace'>
                                                Sunday <b>Fundays</b>
                                            </h5>
                                            <p className='innerSubtext txt20'>
                                                Get to know other members on the OPPSWell platform<br />  during a fun, virtual, interactive monthly game session<br />  where companies compete against each other for a<br />  prize sponsored by OPPSWell.
                                            </p>
                                            <button className='premiumBtn mt-5'>PREMIUM MEMBERSHIP REQUIRED</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className='diversityCertificationSec mb-5' id='spotlight'>
                        <div className='container'>


                            <div className='row align-items-center'>

                                <div className='col-md-5     pe-0'>
                                    <div className='knowledgeInnerLeft'>
                                        <img src={Images.knowledge34} className='img-fluid bussinessImgs' alt="image" />
                                    </div>
                                </div>
                                <div className='col-md-7 ps-0'>
                                    <div className='knowledgeInnerRight'>
                                        <h5 className='heading_Blue mb-4 fw-normal letterSpace'>
                                            Diversity Certifications
                                        </h5>
                                        <p className='innerSubtext txt20'>
                                            One of the goals of OPPSWell is to better equip diverse businesses to compete for company dollars allocated to Supplier Diversity Programs.  To compete for these contracts, Suppliers must receive diverse certification by a reputable source.  OPPSWell is that reputable source with the solution that is truly needed!

                                        </p>
                                        <ul className='memberSpotlightList'>
                                            <artical className="listMain">
                                                <img src={Images.Mark} alt="mark" className="tickMark me-2" />
                                                <li className="innerSubtext">Thorough vetting, yet less onerous and invasive</li>
                                            </artical>
                                            <artical className="listMain">
                                                <img src={Images.Mark} alt="mark" className="tickMark me-2" />
                                                <li className="innerSubtext">One-stop shopping for multiple certifications </li>
                                            </artical>
                                            <artical className="listMain">
                                                <img src={Images.Mark} alt="mark" className="tickMark me-2" />
                                                <li className="innerSubtext">Certification decisions rendered in 7-14 days</li>
                                            </artical>
                                        </ul>
                                        <Link to="/certificate">
                                            <button className='premiumBtn mt-3'>Learn More</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>

                </section >
            </div >
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                className={modalDetail.flag === "suggestionbox" ? "commonWidth customContent" : "soundbytes_Thumbnail"}
                ids={modalDetail.flag === "suggestionbox" ? "suggestionbox" : "soundbytesThumbnail"}
                child={
                    modalDetail.flag === "suggestionbox" ? (
                        <SuggestionBox
                            close={() => handleOnCloseModal()}
                        />
                    ) : modalDetail.flag === "soundbytesThumbnail" ? (
                        <SoundbytesThumbnail
                            close={() => handleOnCloseModal()}
                            desc={modalDetail.desc}
                            videoThumbnail={videoUrl}
                        />
                    )
                        :
                        ""
                }
                header={
                    <div className='modalHeader_'>
                        <div className="common_">
                            {modalDetail.flag === "suggestionbox" ?
                                <>
                                    <h2 className="addLabels_heading">
                                        Have a Suggestion?
                                    </h2>
                                    <p>We are always looking for input please provide below</p>
                                </>

                                : modalDetail.flag === "soundbytesThumbnail" ?

                                    <>
                                        <h2 className="addLabels_heading">
                                            {modalDetail.title}
                                        </h2>
                                    </>

                                    : ""
                            }
                        </div>
                    </div>
                }

                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

export default Resources