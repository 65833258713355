import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAuthSelector } from '../../../redux/selector/auth';
import { useDispatch } from 'react-redux';
import { logout } from '../../../redux/slices/auth';
import { setDiverseStatusEmpty } from "../../../redux/slices/certification";


function Sidebar() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [hideEditBtn, setHideEditBtn] = useState(false);
    const authSelector = useAuthSelector();
    const choosePlan = authSelector?.userStep?.user?.membership_details?.name ?? authSelector?.userStep?.membership_details?.name;
    const chooseBusinessType = authSelector?.userStep?.user?.business_type ?? authSelector?.userStep?.business_type;

    const handleChangePage = (flag, id) => {
        if (flag == "edit") {
            navigate('/settings/edit-profile', { state: { activeTab: "editProfile" } })
            localStorage.setItem("activeTab", "account");
        }
        else if (flag == "plan") {
            navigate('/settings/manage-subscription', { state: { activeTab: "subscription" } })
            localStorage.setItem("activeTab", "subscription")
        }
        else if (flag == "allOpportunities") {
            navigate('/create-opportunities/review-responses', { state: { activeTab: "reviewapplication" } })
            localStorage.setItem("selectedOpportunity", "reviewapplication")
        }
        else if (flag == "setting") {
            navigate('/settings', { state: { activeTab: "privacy" } })
            localStorage.setItem("activeTab", "privacy")
        }
        else if (flag == "viewResponse") {

            navigate('/create-opportunities/view-response', { state: { activeTab: "ReviewRespondents", oppId: id } })
            localStorage.setItem("selectedOpportunity", "ReviewRespondents")
        }
        else {
            navigate('/create-opportunities', { state: { activeTab: "activeOpportunity" } })
            localStorage.setItem("activeTabSetting", "activeOpportunity")
        }
    }
    const userLogout = () => {
        dispatch(logout({
            cb(res) {
                if (res.status) {
                    navigate("/")
                    emptyDiverseCertificates()
                }
                else {
                }
            }
        }))
    }
    const emptyDiverseCertificates = () => {
        let params = {}
        dispatch(setDiverseStatusEmpty({
            ...params, cb(res) {

            }
        }))
    }



    return (
        <>                            <div className='companyProfile'>
            <div className='companyDetails mt-3'>
                <ul className='companyDetailsMenus'>
                    {/* <li className='companyDetailsItems'>
                <button onClick={() => {

                    handleChangePage("edit")
                }} className='companyDetailsLinks'>Account Profile</button>
            </li> */}
                    <li className='companyDetailsItems'>
                        <Link to="/suppliers-search" >  <button
                            className='companyDetailsLinks'>Search</button></Link>

                    </li>
                    <li className='companyDetailsItems'>
                        <Link to="/create-opportunities" ><button
                            className='companyDetailsLinks'>Opportunities & Queries</button></Link>
                    </li>
                    <li className='companyDetailsItems'>
                        <Link to="/all-connections" >
                            <button className='companyDetailsLinks'>Connections</button>
                        </Link>
                    </li>

                    {choosePlan == "Bronze" && chooseBusinessType === 1 || choosePlan == "Bronze" && chooseBusinessType === 2 ?

                        "" :
                        <li className='companyDetailsItems'>
                            <Link to="/resources"> <button
                                className='companyDetailsLinks'>Resources</button></Link>

                        </li>

                    }

                    <li className='companyDetailsItems'>
                        <Link to="/certificate">
                            <button
                                className='companyDetailsLinks'>Certifications</button>
                        </Link>

                    </li>
                    <li className='companyDetailsItems'>
                        <Link to="/account-message">
                            <button
                                className='companyDetailsLinks'>Messages</button>
                        </Link>

                    </li>
                    {/* <li className='companyDetailsItems'>
                <button onClick={() => { handleChangePage("setting") }} className='companyDetailsLinks'> Privacy & Settings  </button>

            </li> */}
                    {/* {
                hideEditBtn === true ? " " :
                    <li className='companyDetailsItems'>
                        <button onClick={() => handleChangePage("plan")} className='companyDetailsLinks'>Manage Membership</button>
                    </li>
            } */}

                    {/* <li className='companyDetailsItems'>
                <Link to="/all-connections" className='companyDetailsLinks'>View Connections</Link>
            </li>
            <li className='companyDetailsItems'>
                <Link to="/account-message" className='companyDetailsLinks'>View Messages</Link>
            </li>
            <li className='companyDetailsItems'>
                <Link to="/create-opportunities" className='companyDetailsLinks'>Manage Opportunities</Link>
            </li>
            <li className='companyDetailsItems'>
                <Link to="/certificate" className='companyDetailsLinks'>Manage Certifications</Link>
            </li>
            <li className='companyDetailsItems'>
                <Link to="/suppliers-search" className='companyDetailsLinks'>Targeted   Search</Link>
            </li>
            <li className='companyDetailsItems'>
                <Link to="/resources" className='companyDetailsLinks'>Resources</Link>
            </li> */}
                    {/* <li className='companyDetailsItems'>
                <Link to="/document-view" className='companyDetailsLinks'>Documents</Link>
            </li> */}
                    {/* <li className='companyDetailsItems' onClick={() => { userLogout() }}>
                <Link to="/resources" className='companyDetailsLinks'>Logout</Link>
            </li> */}
                </ul>
            </div>
        </div>
            <div className='companyProfile newCompanyProfile'>
                <div className='companyDetails mt-3'>
                    <ul className='companyDetailsMenus'>
                        <li className='companyDetailsItems'>
                            <Link to="/document-view" className='companyDetailsLinks'>Documents</Link>
                        </li>
                        <li className='companyDetailsItems'>
                            <button onClick={() => {
                                handleChangePage("edit")
                            }} className='companyDetailsLinks'>Edit Profile</button>
                        </li>
                        {
                            hideEditBtn === true ? " " :
                                <li className='companyDetailsItems'>
                                    <button onClick={() => handleChangePage("plan")} className='companyDetailsLinks'>Manage Membership</button>
                                </li>
                        }
                        <li className='companyDetailsItems'>
                            <Link to="/settings/overview" onClick={() => { handleChangePage("setting") }} className='companyDetailsLinks'>Privacy & Settings</Link>
                        </li>
                        <li className='companyDetailsItems' onClick={() => { userLogout() }}>
                            <Link className='companyDetailsLinks'>Logout</Link>
                        </li>
                    </ul>
                </div>
            </div>
            </>
    )
}

export default Sidebar