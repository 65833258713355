
const BaseUrl = {
    API_URL: process.env.REACT_APP_API_URL,
    SOCKET_URL: process.env.REACT_APP_SOCKET_URL,

    // VIDEO_URL: 'https://api.wellopps.itechnolabs.tech:3000'

    VIDEO_URL: process.env.REACT_APP_API_URL

    // API_URL: 'https://godev.getopps.io:4000',
    // SOCKET_URL: 'https://godev.getopps.io:4000'
}

export default BaseUrl;