import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Images from "../../../utilities/images";
import { toast } from 'react-toastify';
import moment from 'moment';
import { useAuthSelector } from "../../../redux/selector/auth";
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { getCompanyProfileInfo, stepThreeCompanyLogoUplaod } from '../../../redux/slices/auth';
import { applyOpportunity, getOpportunityViewDetail } from '../../../redux/slices/opportunity';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { setActiveTab } from '../../../redux/slices/connection';

const ApplyNow = (props) => {
  const { id } = useParams();

  const navigate = useNavigate();
  // const history = useHistory()
  const dispatch = useDispatch();
  const authSelector = useAuthSelector();
  const companyID = authSelector?.userInfo?.user?.user_company_information?.id;

  const userLoginInfo = authSelector?.userStep?.user;
  const userSignupInfo = authSelector?.userStep;
  const userSelectedSignupCompanyInfo = authSelector?.userStep?.user_selected_company_information?.user_company_information?.company_name;
  const userSelectedLoginCompanyInfo = authSelector?.userStep?.user?.user_selected_company_information?.user_company_information?.company_name;

  const [photo, setPhotoUpload] = useState("");
  const [photoInfo, setPhotoInfo] = useState();
  const [introduction, setIntroduction] = useState("");
  const [description, setDescription] = useState();
  const [information, setInformation] = useState("");
  const [companyDetail, setCompanyDetail] = useState("");
  const [postOpportunity, setPostOpportunity] = useState('');


  const chooseBusinessType = authSelector?.userStep?.user?.business_type ?? authSelector?.userStep?.business_type;

  const choosePlan = authSelector?.userStep?.user?.membership_details?.name ?? authSelector?.userStep?.membership_details?.name;

  const oppportunity_reply_count = postOpportunity?.oppportunity_reply_count;


  //remove file
  const removeFile = file => () => {
    const newFiles = [photo]
    newFiles.splice(newFiles.indexOf(file), 1)
    setPhotoUpload(newFiles)
  } 

  //onDrop
  const onDrop = useCallback(acceptedFiles => {
    const imageFile = acceptedFiles[0];
    // if (!imageFile.name.match(/\.(jpg|jpeg|png|gif|svg)$/)) {
    //   toast.error("Please select a valid image.");
    //   return false;
    // }
    let params = {
      photo: imageFile,
    }
    dispatch(stepThreeCompanyLogoUplaod({
      ...params, cb(res) {
        if (res.status) {
          setPhotoUpload(res?.data?.payload?.url);
          setPhotoInfo(res?.data?.payload)
        }
        else {
        }
      }
    }))

  }, [])

  const { getRootProps, getInputProps } =

    useDropzone({
      onDrop,
      accept: {
        'image/jpeg': [],
        'image/jpg': [],
        'image/png': [],
        'application/pdf': [],
        'application/msword': [], // .doc files
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [] //docx file
      }
    });

    const toastId = useRef(null);

    // show only one toast at one time
    const showToast = (msg) => {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(msg);
      }
    };
  

  const handleSubmit = (e) => {
    e.preventDefault();
    const regex = /[a-z|A-Z]+(?: [a-z|A-Z]+)*$/
    if (!introduction) {
      toast.error("Please enter Introduction");
      return;
    }
    else if (introduction.length <= 2) {
      toast.error("Introduction Served should be maximum 3 character")
      return;
    }
    // else if (!introduction.match(regex)) {
    //   toast.error("Please enter only character and single space in Introduction");
    //   return;
    // }
    else if (!description) {
      toast.error("Please enter Description");
      return;
    }
    else if (description.length <= 2) {
      toast.error("Description should be maximum 3 character")
      return;
    }
    // else if (!description.match(regex)) {
    //   toast.error("Please enter only character and single space in Description");
    //   return;
    // }
    // else if (!photo) {
    //   toast.error("Please select Image");
    //   return;
    // }
    let params = {
      company_id: companyDetail?.id,
      opportunity_id: id,
      name: userLoginInfo?.user_profile?.first_name ? `${userLoginInfo?.user_profile?.first_name} ${userLoginInfo?.user_profile?.last_name}` : `${userSignupInfo?.user_profile?.first_name} ${userSignupInfo?.user_profile?.last_name}`,
      email_address: userLoginInfo?.email ? userLoginInfo?.email : userSignupInfo?.email,
      introduction: introduction,
      description: description,
      opportunity_media: photo,
      location: "mohali",
      phone_no: "12343"

    }

    if (oppportunity_reply_count >= 5 && choosePlan === "Bronze" && chooseBusinessType === 2) {
      showToast("Current plan bronze. Please upgrade your membership plan ")
    }
    else if (oppportunity_reply_count >= 5 && choosePlan === "Gold" && chooseBusinessType === 2) {
      showToast("Current plan gold. Please upgrade your membership plan")
    }
    else if(chooseBusinessType === 1 && oppportunity_reply_count >= 5 && choosePlan === "Bronze"){
      showToast("Current plan bronze. Please upgrade your membership plan ")
    }
    else if (chooseBusinessType === 1 && oppportunity_reply_count >= 5 && choosePlan === "Gold" ) {
      showToast("Current plan gold. Please upgrade your membership plan")
    }
    else {
      dispatch(applyOpportunity({
        ...params, cb(res) {
          if (res.status) {
            navigate('/opportunity-search')
          }
          else {
          }
        }
      }), [oppportunity_reply_count])
    }

  }

  const ViewDetails = () => {
    let params = {
      id: id,
    }
    dispatch(getOpportunityViewDetail({
      ...params, cb(res) {
        if (res.status) {
          setInformation(res?.data?.payload)
        }
      }
    }))
  }

  // function for user veiw profile.........
  const followCompany = (name, id) => {
    navigate(`/${name}/${id}`)
  }

  const BackListing = () => {
    navigate('/opportunities', { state: { opportuntyTabSearch: 3 } });
    emptyActiveTab()
  }

  const emptyActiveTab = () => {
    let params = {}
    dispatch(setActiveTab({
      ...params, cb(res) {

      }
    }))
  }

  const handleSubmited = (e, id) => {
    e.preventDefault();
    navigate(`/${"view-profile"}/${id}`);
    // ViewDetails(id)
    window.scrollTo(0, 0)

  }


  //for COMPANY PROFILE INFORMATION.......
  const companyProfileInfo = () => {
    dispatch(getCompanyProfileInfo({
      cb(res) {
        if (res.status) {
          if (res?.data?.payload?.userDetails?.user_company_information !== null) {
            setPostOpportunity(res?.data?.payload?.userDetails?.user_data[0])
            setCompanyDetail(res?.data?.payload?.userDetails?.user_company_information)
          }
          else {
            setPostOpportunity(res?.data?.payload?.userDetails?.user_data[0])
            setCompanyDetail(res?.data?.payload?.userDetails?.user_company_information)
          }

        }
        else {

        }


      }
    }))
  }

  useEffect(() => {
    ViewDetails()
    companyProfileInfo()
  }, []);


  useEffect(() => {
    const currentPath = window.location.pathname;

    if (currentPath.includes(`/apply/${id}`)) {
      const newPath = currentPath.replace(`/apply/${id}`, `/opportunity/${id}/apply`);
      window.history.replaceState(null, '', newPath);
    }
  }, [window.location]);

  return (
    <>
      <div className='row'>
        <div className='col-md-12 mb-4 text-end'>
          <button className='smallgreyBtn_' onClick={() => { BackListing() }}>Back to Listing</button>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-4 col-lg-4'>
          <div className='containerBox containerCustom'>
            <h3 className="headingTitle_ mb-4">About the Company</h3>
            <div className="companyProfile__ mb-5">
              <img className="img-fluid" src={information?.user_details?.user_company_information?.company_logo ? information?.user_details?.user_company_information?.company_logo : Images.puzzleProfile} alt="company Profile" />
            </div>
            <div className="profileData">
              <h3 className="txt16 fw-bold mb-2">{information?.user_details?.user_company_information?.company_name}</h3>
              <p className="txt16 fw-normal profileDes mb-4">{information?.user_details?.user_company_information?.company_service_description}</p>
              <button class="subsmallBtnblue_ mb-3 followBtn" onClick={() => { followCompany(information?.user_details?.user_company_information?.company_name, information?.user_details?.user_company_information?.id) }}>Follow Company</button>
            </div>
            <hr />
            <div className="containerBoxright aboutCompanyView ps-0 pe-0 mt-4">
              <div className="companyDetailsRight">
                <h5 className="headingTitle_ mb-2">
                  <b>Company Details</b>
                </h5>
                <ul className="companyDetailsList">
                  {information?.user_details?.user_company_information?.company_website === "" ? "" :
                    <li>
                      <span className="blueinnerSubtext">Website</span>
                      <span className="listHead_">
                        {information?.user_details?.user_company_information?.company_website}
                      </span>
                    </li>
                  }
                  {information?.user_details?.user_company_information?.industries_group_details?.name === null ? "" :
                    <li>
                      <span className="blueinnerSubtext">Industry</span>
                      <span className="listHead_">
                        {information?.user_details?.user_company_information?.industries_group_details?.name}
                      </span>
                    </li>
                  }
                  {information?.user_details?.user_company_information?.number_of_employees > 0 ?
                    <li>
                      <span className="blueinnerSubtext">Company Size</span>

                      <span className="listHead_">
                        {information?.user_details?.user_company_information?.number_of_employees}
                      </span>
                    </li>
                    : ""}
                  {information?.user_details?.user_company_information?.domestic_service_areas === null ? "" :
                    <li>
                      <span className="blueinnerSubtext">Locations</span>
                      <span className="listHead_">
                        {information?.user_details?.user_company_information?.domestic_service_areas}
                      </span>
                    </li>
                  }
                </ul>
              </div>
            </div>

          </div>

          <div className="lookingSuppllierCard mt-4">
            <div className="lookingSupplierCardData">
              <p className="mb-0">Looking for Suppliers? <a href="">Click here</a></p>
            </div>
          </div>
          <div className="bussinessResourceCard mt-4" onClick={() => {
            navigate("/resources")
          }}>
            <div className="bussinessResourceInner">
              <p className="mb-0">Business Resources   +</p>
            </div>
          </div>
        </div>
        <div className='col-md-8 col-lg-8 mb-4'>
          <div className='containerBox'>

            <h4 className='boldmainHeading_'>Proposal Submission</h4>
            <div className=''>
              <p className='company_heading my-4'> Company :
                <span className='company_type'>{companyDetail?.company_name}</span>
                <span className='company_details'>(Edit Profile)</span>
              </p>
              <div className='row'>
                <div className='col-lg-6'>
                  <p className='company_heading'> Name :
                    <span className='company_details'>{userLoginInfo?.user_profile?.first_name ? userLoginInfo?.user_profile?.first_name : userSignupInfo?.user_profile?.first_name} {userLoginInfo?.user_profile?.last_name ? userLoginInfo?.user_profile?.last_name : userSignupInfo?.user_profile?.last_name}</span>
                  </p>
                </div>
                <div className='col-lg-6'>
                  <p className='company_heading'> Location :
                    <span className='company_details'>{companyDetail?.domestic_service_areas}</span>
                  </p>
                </div>
                <div className='col-lg-6'>
                  <p className='company_heading'> Email Address :
                    <span className='company_details'>{userLoginInfo?.email ? userLoginInfo?.email : userSignupInfo?.email} </span>
                  </p>
                </div>
              </div>
            </div>
            <div className='form-group col-md-12 mb-3'>
              <label className='postopportunity_label'>Introduction</label>

              <textarea name="email" type='text' rows='4' className='customFormControl' placeholder='Type here...' value={introduction} onChange={e => setIntroduction(e.target.value)} />
            </div>
            <div className='form-group col-md-12 mb-3'>
              <label className='postopportunity_label'>Description</label>

              <textarea name="email" type='text' rows='7' className='customFormControl' placeholder='Type here...' value={description} onChange={e => setDescription(e.target.value)} />
            </div>
            <div className='form-group col-md-12 mb-3'>
              {photo?.length > 0 ?
                "" :
                <div className="uploadImgebox">

                  <div {...getRootProps({ className: "dropzone" })}>
                    <input type="file" accept="image/png, image/jpeg" {...getInputProps()} />
                    <p className='uploadbtnn'>Upload</p>
                    <p className='uploadText mt-2' >JPEG, JPG, PNG, PDF, DOC or DOCX file upload</p>
                  </div>
                </div>
              }
            </div>
            {photo?.length > 0 ?
              <aside >
                <h4 className='headingSmall_'> Uploaded Files</h4>
                <ul className='uploadProfile_'>

                  {photoInfo.format == "application/pdf" ?
                    <img className="w3-round" src={Images.PDFImage} />
                    : photoInfo.format == "application/msword" || photoInfo.format == "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ?
                      <img className="w3-round" src={Images.DocImage} />
                      : <img className="w3-round" src={photo} />
                  }

                  <div className='profileDetail_'>
                    <h5 className='profileboldHead'>{photoInfo.name}</h5>

                    <p className='profileboldgraph'>
                      {(photoInfo?.size / (1024 * 1024)).toFixed(2)} MB
                    </p>
                  </div>
                  <i className="fa fa-trash text-red" onClick={removeFile(photo)}></i>
                </ul>
              </aside>
              : ""
            }

            <div className='submitProposal text-end submit-proposal'>
              <button className="primaryBtnSmall" onClick={(e) => handleSubmit(e)}>Submit Proposal</button>
            </div>
            {
              information?.moreOpportunites?.data?.length > 0 ?
                <>
                  <hr className="bottomBorder_"></hr>

                  <div className='businessDesincontent_  aboutCopany_ mt-3'>
                    <div className='row mb-2'>
                      <div className='col-md-6 col-lg-6 col-6'>
                        <h3 className='boldmainHeading_ mb-4'>
                          More Opportunities</h3>
                      </div>
                    </div>
                    {
                      information?.moreOpportunites?.data?.map((data, index) => (
                        <div className="moreOpportunities ">
                          <div className="moreOppLeft">
                            <h4 className="mb-0">{data?.posting_title}</h4>
                            <p className="mb-0">{data?.hq_location}</p>
                          </div>
                          <div className="moreOppRight">
                            <p className="mb-0 me-3"><span>Posted</span> {moment(data?.created_at).fromNow()}</p>
                            <button className="" onClick={(e) => {
                              handleSubmited(e, data?.id)
                            }}>View Now</button>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </>

                : ""}

          </div>
        </div>
      </div>
    </>
  )
}

export default ApplyNow