import React, { useEffect, useState } from "react";
import * as Images from "../../../utilities/images";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { allUserWithSearch } from "../../../redux/slices/dashboard";
import { getAllResources } from "../../../redux/slices/support";
import CategorySearchData from "../categorySearchData";
import ReactPaginate from "react-paginate";

const HelpDesk = () => {
  const [allArticles, setAllArticles] = useState([]);
  const [searchedData, setSearchedData] = useState()
  const [suggestionData, setSuggestionData] = useState([])
  const [allCategories, setAllCategories] = useState([])
  const [searchInput, setSearchInput] = useState("")
  const [pageCount, setPageCount] = useState(1);
  const [showCategoryData, setShowCategoryData] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const getAllUserWithSearch = (page = 1, limit = 10) => {
    let params = {
      search: searchInput ? searchInput : "",
      page: page,
      limit: limit,
    }
    dispatch(
      getAllResources({
        ...params,
        cb(res) {
          if (res.status) {
            if (searchInput === "") {
              setSuggestionData(res?.data?.payload?.suggestedPages?.data)
            }
            else {
              setSuggestionData(res?.data?.payload?.allPages?.data)
            }
            setSearchedData(res?.data?.payload?.allPages?.data)
            setPageCount(res?.data?.payload?.allPages?.total_pages)
            setAllCategories(res?.data?.payload?.categories)
            // setSuggestionData(res?.data?.payload?.suggestedPages?.data)
            setAllArticles(res.data.payload.data)
          }
        },
      })
    );
  };


  const handleSubmit = (e) => {
    e.preventDefault()
    // setShowCategoryData(true)
    getAllUserWithSearch()
  }

  //reset input
  const onChangeReset = () => {
    setSearchInput("")
  }


  useEffect(() => {
    getAllUserWithSearch()
  }, [searchInput])

  useEffect(() => {
    getAllUserWithSearch();
    window.scrollTo(0, 0);
    document.title = "Help Desk";
  }, []);

  return (

    <div className="helpDesk">
      {
        showCategoryData ?
          <CategorySearchData
            searchedData={searchedData}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            pageCount={pageCount}
            getAllUserWithSearch={() => getAllUserWithSearch()}
          />
          :
          <>
            <section className="homeBanner">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 col-md-12">
                    <div className="aboutContainer">
                      <div className="">
                        <h3 className="bannerHead mb-0">
                          Help <b>Desk</b>
                        </h3>
                        <p className="bannerTxt mb-0">
                          Explore the Help Desk for Assistance
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="aboutMenus">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <ul className="aboutMenusContainer">
                      <li className="aboutItem">
                        <button>
                          <Link to="/privacy-policy" className="aboutLinks">
                            PRIVACY POLICY
                          </Link>
                        </button>
                      </li>
                      <li className="aboutItem">
                        <button>
                          <Link to="/terms-of-service" className="aboutLinks">
                            TERMS OF SERVICE
                          </Link>
                        </button>
                      </li>
                      <li className="aboutItem">
                        <button>
                          <Link to="/help-desk" className="aboutLinks">
                            HELP DESK
                          </Link>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>

            <section className="recommended bgWhite">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="searchBox mx-auto">
                      <form className="inputSearchForm" onSubmit={(e) => handleSubmit(e)}>
                        <div className="input-group me-2">
                          <input
                            type="text"
                            className="form-control rounded"
                            placeholder="How can we help?"
                            aria-label="Search"
                            aria-describedby="search-addon"
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                          />
                          {/* {
                            searchInput !== "" ?
                              <button class="inputClose " type="reset" onClick={() => {
                                onChangeReset()
                              }}> <i class="las la-times"></i> </button>
                              :
                              <button className="inputClose" type="reset">
                                {" "}
                                <img src={Images.searchIcon_} alt="" />{" "}
                              </button>
                          } */}
                          {
                            searchInput !== "" ?
                              <button className="searchBtn" type="reset" onClick={() => {
                                onChangeReset()
                              }}> <img src={Images.crossIcon} /> </button>
                              :
                              <button className="searchBtn" type="button">
                                <img src={Images.searchIcon_} alt="" />
                              </button>
                          }
                        </div>


                      </form>
                    </div>
                  </div>
                </div>
                <h2 className="heading27 text-center mb-4 ">Recommended topics</h2>
                <div className="row mb-4">
                  {allCategories?.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        {" "}
                        {/* <div className="col-md-4 mb-4 pe-0" onClick={() => navigate(`/help-desk-question/${item?.id}`)}> */}

                        <div className="col-md-4 mb-4 pe-0" onClick={() => navigate('/help-desk-question', { state: { category_id: item?.id, categoryName: item?.name } })}>
                          <div className="recomendedCard">
                            <h3>{item?.name}</h3>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
                <div className="suggestedArticles">
                  <div className="row">
                    <div className="col-12">
                      <h2 className="heading44 mb-3">Suggested articles</h2>
                      <div className="mb-4">
                        <hr className="my-5" />
                      </div>
                      {suggestionData?.map((data, index) => (
                        <>
                          <div className="suggestedArticleInner mb-5 pt-3" key={index}>
                            {/* <h2 className="mb-2 suggestedHeading" onClick={() => navigate(`/article-info/${data?.id}`)}> */}
                            <h2 className="mb-2 suggestedHeading" onClick={() => navigate('/help-desk-question', { state: { article_id: data?.id } })}>
                              {data?.title}
                            </h2>
                            <span className="suggestedSubHeading mb-5">{data?.help_category_details?.name}</span>
                            <p className="suggestedDes wordLimit" dangerouslySetInnerHTML={{ __html: data?.description }} >
                            </p>
                          </div>
                          <hr />
                        </>
                      ))}
                    </div>
                  </div>
                  <div className="col-12 ">
                    <div className="customPagination mt-4">
                      {/* <div className="pagination">
                                        {info?.length > 3 ? (
                                            <ReactPaginate
                                                forcePage={currentPage}
                                                // initialPage={currentPage}
                                                // key={pagination}
                                                // breakLabel=""
                                                // nextLabel=">"
                                                nextLabel={<i className="fas fa-caret-right"></i>}
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={pageCount}
                                                marginPagesDisplayed={0}
                                                pageCount={pageCount}
                                                previousLabel={<i className="fas fa-caret-left"></i>}
                                                // previousLabel="<"
                                                renderOnZeroPageCount={null}
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
      }
    </div>
  );
};

export default HelpDesk;
