import React, { useEffect, useState } from "react";
import Faq from "react-faq-component";
import * as Images from "../../../utilities/images";
import { Link, useLocation, useNavigate } from "react-router-dom";
const FrequentQuestion = () => {
  const [rows, setRowsOption] = useState(null);

  const location = useLocation()

  const data = {
    title: "For Buyers",
    rows: [
      {
        title:
          "What is OPPSWell and how can it benefit me as a Buyer?               ",
        content: `OPPSWell is a dynamic social media networking site designed for Buyers and Suppliers. It provides a platform to connect and engage with a diverse community of Members, discover new products and services and foster valuable business relationships. By joining OPPSWell, you gain access to a wide range of Suppliers and Buyers, streamline your procurement process and unlock new growth opportunities.`,
      },
      {
        title: "How can I find relevant Suppliers on OPPSWell?",
        content:
          "OPPSWell offers targeted search functionalities that allow you to find Suppliers based on specific criteria such as location, industry, certifications, and more. You can utilize advanced search filters to narrow down your options and connect with Suppliers who best align with your requirements.",
      },
      {
        title: "Can I review Supplier profiles and ratings on OPPSWell?",
        content: `Absolutely! OPPSWell provides comprehensive Supplier profiles where you can find detailed information about their offerings, certifications, experience and more. Additionally, you can benefit from reviews provided by others on the platform who have engaged with these Suppliers. These reviews can help you evaluate their reputations and thereafter make informed decisions.`,
      },
    ],
  };

  const supplierdata = {
    title: "For Suppliers",
    rows: [
      {
        title: "How can OPPSWell benefit me as a Supplier?",
        content: `OPPSWell offers immense benefits to Suppliers by providing a platform to connect and engage with a wide network of Buyers. It enables you to showcase your products or services, expand your reach and establish meaningful relationships with potential Buyers. OPPSWell helps you gain visibility and increases your chances of securing contracts and growing your business.`,
      },
      {
        title: "Can I directly message Buyers on OPPSWell?",
        content: `Absolutely! OPPSWell facilitates Direct Messaging between Suppliers and Buyers allowing you to communicate and engage with potential Buyers in a convenient and efficient manner. You can leverage this feature to discuss requirements, provide additional information and build relationships with Buyers who may be interested in your offerings.`,
      },

      {
        title: "How can I enhance my profile on OPPSWell to attract Buyers?",
        content: `To attract Buyers on OPPSWell, it's important to create a compelling and informative profile. Highlight your key offerings, showcase your unique value proposition and include relevant certifications or qualifications. Regularly update your profile and your Community Feed with fresh content such as case studies, testimonials employee profiles or industry insights to demonstrate your expertise and credibility.`,
      },
    ],
  };

  const styles = {
    // bgColor: 'white',
    titleTextColor: "dark gray",
    rowTitleColor: "dark gray",
    // rowContentColor: 'grey',
    // arrowColor: "red",
  };

  const config = {
    animate: true,
    arrowIcon: "V",
    openOnload: 0,
    expandIcon: "+",
    collapseIcon: "x",
  };

  // useEffect(() => {
  //     if (rows) {

  //             rows[0].expand();
  //             rows[1].expand();
  //             rows[2].expand();
  //             rows[4].expand();
  //             rows[5].expand();

  //         // setTimeout(() => {
  //         //     rows[0].close();
  //         // }, 5000);

  //             // rows[0].scrollIntoView();
  //             // rows[0].scrollIntoView(true);

  //     }
  // }, [rows]);

  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = "faqs";
}, []);


  return (
    <>
    <div className='helpDesk'>
    <section className='homeBanner'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8 col-md-12'>
              <div className='aboutContainer'>
                <div className=''>
                  <h3 className='bannerHead mb-0'>
                  <b>FAQs </b>
                  </h3>
                  <p className='bannerTxt mb-0'>
                  Explore Answers to Frequently Asked Questions
                    {/* <b>GetOPP$</b> is a matchmaking service for all <br />companies looking for contracting opportunities. */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <section className='aboutMenus'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <ul className='aboutMenusContainer'>
              <li className='aboutItem'>
                <button >
                  <Link to='/privacy-policy' className='aboutLinks' >PRIVACY POLICY</Link>
                </button>
              </li>
              <li className='aboutItem'>
                <button >
                  <Link to='/terms-of-service' className='aboutLinks' >TERMS OF SERVICE</Link>
                </button>
              </li>
              <li className='aboutItem'>
                <button className='aboutLinks'>
                  <Link to='/help-desk' className='aboutLinks' >HELP DESK</Link></button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <div className="bgWhite pt-5 pb-4">
    <section className="frequentyQuestion_ ">
      <div className="container">
        <div className="containerBox px-0">
          {/* <div className="row justify-content-center">
            <div className="memeberHead_ col-md-10 ">
              <h3 className="memberHead_">
                <strong>Frequently Asked Questions</strong>
              </h3>
            </div>
          </div> */}
          <div className="row">
            <div className="memberHomeImage_ mt-5 mb-4">
              <div className="row">
                <div className="col-md-12 col-lg-12">
                  <div className="faqcontentBox_">
                    <Faq
                      getRowOptions={setRowsOption}
                      data={data}
                      styles={styles}
                      config={config}
                    />
                  </div>
                  <div className="faqcontentBox_">
                    <Faq
                      getRowOptions={setRowsOption}
                      data={supplierdata}
                      styles={styles}
                      config={config}
                    />
                  </div>
                </div>
                {/* <div className='col-md-4 col-lg-4'>
                                <div className='faqImgbox'>
                                    <img className='faqImg' src={Images.faqImg} />
                                </div>
                            </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
    </>
  );
};

export default FrequentQuestion;
