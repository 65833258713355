import React, { useState, useEffect } from "react";
import Select from 'react-select';
import * as Images from "../../../utilities/images";
import { getChatHead, getOpportunityViewDetail } from '../../../redux/slices/opportunity';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import ApplyNow from "./applyNow";
import {toast} from "react-toastify"


import ComposeMessage from "../accountMessage/ComposeMessages";
import CustomModal from "../../components/common/shared/CustomModal";
import { setActiveTab } from "../../../redux/slices/connection";
import SendMessage from "../companyfrontProfile/sendMessage";
import { getCompanyProfileInfo } from "../../../redux/slices/auth";
import { useAuthSelector } from "../../../redux/selector/auth";

const ViewProfile = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const authSelector = useAuthSelector();
  const location = useLocation();
  // const id = location?.state?.id;
  const navigate = useNavigate();
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedCompanyName, setSelectedCompanyName] = useState("");
  const [information, setInformation] = useState("");
  // const [activeTab, setActiveTabs] = useState("active");
  const certificate_tooltip = " Say goodbye to tedious paperwork and complex requirements. OPPSWell simplifies and streamlines the entire certification process, empowering you to focus on growing your business. With a user-friendly interface and automated verification processes, completing your application accurately and promptly has never been easier. Gain access to procurement opportunities, strategic partnerships, and invaluable resources. Open doors to new contracts, forge connections, and showcase your commitment to diversity. "
  const maxLength = 60;
  const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
  ];
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });


  const ViewDetails = (ids) => {
    let params = {
      id: ids ? ids : id,
    }
    dispatch(getOpportunityViewDetail({
      ...params, cb(res) {
        if (res.status) {
          setInformation(res?.data?.payload)
        }
      }
    }))
  }

  // function for user veiw profile.........
  const followCompany = (name, id) => {
    navigate(`/${name}/${id}`)
  }

  const handleSubmited = (e, id) => {
    e.preventDefault();
    // navigate(`/${"view-profile"}/${id}`);
    ViewDetails(id)
    window.scrollTo(0, 0)

  }

  //reply now 
  const ReplyNow = () => {
    navigate(`/apply/${id}`);
  }

  const BackListing = () => {
    // navigate('/suppliers-search?selectedFilters=opportunity', { state: { activeTabSearch: 1 } });
    navigate('/opportunities', { state: { opportuntyTabSearch: 3 } });
    emptyActiveTab()
  }

  const emptyActiveTab = () => {
    let params = {}
    dispatch(setActiveTab({
      ...params, cb(res) {

      }
    }))
  }

  const handleChat = (userID) => {
    let params = {
      id: userID,
    }
    dispatch(getChatHead({
      ...params, cb(res) {
        if (res.status) {
          if (res?.data?.payload?.connectionRequest) {
            if (res?.data?.payload?.connectionRequest?.status === 2) {
              navigate(`/account-message/${res?.data?.payload?.chatHeadDetails?.id}`)
            }
            else {
              setModalDetail({ show: true, flag: "SendMessage" });
              setKey(Math.random());
            }
          }
          else {
            if (res?.data?.payload?.chatRequest) {
              if (res?.data?.payload?.chatRequest?.status === 1) {
                navigate(`/account-message/${res?.data?.payload?.chatHeadDetails?.id}`)
              } else if (res?.data?.payload?.chatRequest?.status === 0) {
                toast.warning(" Message request already sent to the user")
              }
              else {
                setModalDetail({ show: true, flag: "SendMessage" });
                setKey(Math.random());
              }
            }
            else {
              setModalDetail({ show: true, flag: "SendMessage" });
              setKey(Math.random());
            }
          }
        }
      }
    }))
  }

  // for close Modal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };


  const btnDisable = information?.connection_request === false ? false : information?.connection_request?.status === 2 ? true : information?.connection_request?.status === 0 ? true : false


  useEffect(() => {
    ViewDetails();
    document.title = "viewProfile";
  }, []);


  useEffect(() => {
    const currentPath = window.location.pathname;

    if (currentPath.includes(`/view-profile/${id}`)) {
      const newPath = currentPath.replace(`/view-profile/${id}`, `/opportunity/${id}/overview`);
      window.history.replaceState(null, '', newPath);
    }
  }, [window.location]);


  return (
    <div className='viewProfile_'>
      <div className='row mb-2'>
        <div className='col-md-6 col-lg-6 '>
          <h3 className='headTextmain_'>
            <span className='blueHead_ '>Opportunity</span> Overview</h3>
        </div>
        <div className='col-md-6 text-end mb-3'>
          <button className='smallgreyBtn_ backToListing' onClick={() => { BackListing() }}>Back to Listing</button>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-4 col-lg-4'>
          <div className='containerBox containerCustom'>
            <h3 className="headingTitle_ mb-4">About the Company</h3>
            <div className="companyProfile__ mb-5">
              <img className="img-fluid" src={information?.user_details?.user_company_information?.company_logo ? information?.user_details?.user_company_information?.company_logo : Images.puzzleProfile} alt="company Profile" />
            </div>
            <div className="profileData">
              <h3 className="txt16 fw-bold mb-2">{information?.user_details?.user_company_information?.company_name}</h3>
              <p className="txt16 fw-normal profileDes mb-4">{information?.user_details?.user_company_information?.company_service_description}</p>
              <button class="subsmallBtnblue_ mb-3 followBtn" onClick={() => { followCompany(information?.user_details?.user_company_information?.company_name, information?.user_details?.user_company_information?.id) }}>Follow Company</button>
            </div>
            <hr />
            <div className="containerBoxright aboutCompanyView ps-0 pe-0 mt-4">
              <div className="companyDetailsRight">
                <h5 className="headingTitle_ mb-2">
                  <b>Company Details</b>
                </h5>
                <ul className="companyDetailsList">
                  {information?.user_details?.user_company_information?.company_website === "" ? "" :
                    <li>
                      <span className="blueinnerSubtext">Website</span>
                      <span className="listHead_ text-break">
                        {information?.user_details?.user_company_information?.company_website}
                      </span>
                    </li>
                  }
                  {information?.user_details?.user_company_information?.industries_group_details?.name === null ? "" :
                    <li>
                      <span className="blueinnerSubtext">Industry</span>
                      <span className="listHead_">
                        {information?.user_details?.user_company_information?.industries_group_details?.name}
                      </span>
                    </li>
                  }
                  {information?.user_details?.user_company_information?.number_of_employees > 0 ?
                    <li>
                      <span className="blueinnerSubtext">Company Size</span>

                      <span className="listHead_">
                        {information?.user_details?.user_company_information?.number_of_employees}
                      </span>
                    </li>
                    : ""}
                  {information?.user_details?.user_company_information?.domestic_service_areas === null ? "" :
                    <li>
                      <span className="blueinnerSubtext">Locations</span>
                      <span className="listHead_">
                        {information?.user_details?.user_company_information?.domestic_service_areas}
                      </span>
                    </li>
                  }
                </ul>
              </div>
            </div>
            {/* <div className="business_search">
              <img className="searchIcon_" src={Images.search_} alt="Search" />
              <input type="text" className="custoInput" placeholder="Search" aria-label="discount code" aria-describedby="basic-addon2" />
            </div> */}
            {/* <div className='businessType_ mt-3 mb-2 pb-3'>
              <h5 className='headingSmall_ mb-2'>Types of Business</h5>
              <div className='businessDetail_ mt-2'>
                <div className="checkmark_">
                  <input type="checkbox" id="business1" name="" className="businessType_" />
                  <label for="business1" className="labelTxt_">
                    <span className='innerSubtextSmall'>Automative</span>
                  </label>
                  <span className='value_'>[32]</span>
                </div>
                <div className="checkmark_">
                  <input type="checkbox" id="business2" name="" className="businessType_" checked />
                  <label for="business2" className="labelTxt_">
                    <span className='innerSubtextSmall'>Marketing & Design</span>
                  </label>
                  <span className='value_'>[64]</span>
                </div>
                <div className="checkmark_">
                  <input type="checkbox" id="business3" name="" className="businessType_" />
                  <label for="business3" className="labelTxt_">
                    <span className='innerSubtextSmall'>Public Relations</span>
                  </label>
                  <span className='value_'>[32]</span>
                </div>
                <div className="checkmark_">
                  <input type="checkbox" id="business4" name="" className="businessType_" />
                  <label for="business4" className="labelTxt_">
                    <span className='innerSubtextSmall'>Security</span>
                  </label>
                  <span className='value_'>[13]</span>
                </div>
                <div className="checkmark_">
                  <input type="checkbox" id="business5" name="" className="businessType_" />
                  <label for="business5" className="labelTxt_">
                    <span className='innerSubtextSmall'>Technology</span>
                  </label>
                  <span className='value_'>[8]</span>
                </div>
              </div>
            </div> */}
            {/* <div className='location_ mt-3 mb-2 '>
              <h5 className='headingSmall_ mb-2'>Location</h5>
              <select className="cateSelectbox" required="">
                <option value="">City / State</option>
                <option>Category1</option>
                <option>Category2</option>
              </select>
            </div> */}
            {/* <div className='location_ mt-3 mb-2'>
              <h5 className='headingSmall_ mb-2'>Trade Memberships</h5>
              <select className="cateSelectbox" required="">
                <option value="">Disability Inclusion Network</option>
                <option>Category1</option>
                <option>Category2</option>
              </select>
            </div> */}
            {/* <div className="tagsType mt-3 mb-3 col-md-12">
              <label className="headingSmall_ mb-2" htmlFor="">Diversity Status</label>
              <Select
                isMulti
                name="tags"
                options={options}
                value={selectedTags}
                onChange={setSelectedTags}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div> */}
            {/* <div className='businessBtn mt-4'>
              <button className="secondaryBtnGrey me-2">Reset</button>
              <button className="primaryBtnSmall">Update Search</button>
            </div> */}
          </div>
          {/* <div className='cardContainer mt-4'>
            <div className='cardImg'>
              <img src={Images.certifyUser} className='img-fluid' alt='CardImg' />
            </div>
            <div className='cardBodyArea'>
              <h5>Get Certified</h5>
              <div className="infoiconText">
                <span className="showinfoText">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span>
              </div>
              <p className='my-4'>GetOPP$ Diversity Certifications for inclusive business futures. </p>
              <div className="infoiconText m-0">
                <Link to="/certificate">
                  <button className='primaryBtn'>Learn More</button>
                </Link>
                <span className="showinfoText">
                  {certificate_tooltip.substring(0, maxLength)}...
                </span>
              </div>
            </div>
          </div> */}
          <div className="lookingSuppllierCard mt-4">
            <div className="lookingSupplierCardData">
              <p className="mb-0">Looking for Suppliers? <a href="">Click here</a></p>
            </div>
          </div>
          <div className="bussinessResourceCard mt-4" onClick={() => {
            navigate("/resources")
          }}>
            <div className="bussinessResourceInner">
              <p className="mb-0">Business Resources   +</p>
            </div>
          </div>
        </div>

        {/* {activeTab == "active" ? */}
        <div className='col-md-8 col-lg-8 mb-4'>
          <div className='containerBox opportunityPage'>
            <div className='businessDesincontent_ '>

              <div className='row'>
                <div className='col-lg-12 '>
                  <div className='designbtnRight justify-content-between mb-5'>

                    <div>


                      {information?.is_applied == null ?
                        <button className='followBtn me-3' onClick={() => ReplyNow()
                        } >Apply Now</button>
                        : ""
                      }
                      <button onClick={() => {
                        // setModalDetail({ show: true, flag: "SendMessage" });
                        // setKey(Math.random());
                        handleChat(information?.user_id)
                      }}
                        // className={`subsmallBtnblue_ ${btnDisable === false ? "disabled__" : ""}`}
                        className={`followBtn sendMessageBtn ${btnDisable === true ? " disabled__" : ""}`}
                        disabled={btnDisable}
                      >Send Message</button>

                      {/* :
                          <button onClick={() => {
                            setModalDetail({ show: true, flag: "ComposeMessage" });
                            setKey(Math.random());
                          }} className=' followBtn sendMessageBtn'>Send Message</button>
                      } */}
                    </div>
                    <p className='postHours_'><span>POSTED</span> <span className="fw-normal">  {moment(information?.created_at).fromNow()}</span></p>

                  </div>
                  <div className='businessServer_'>
                    <div className='designcontentTop_'>
                      <div className='designLeft_'>
                        <img className='todoList_' src={Images.todoList} />
                        {/* <h4 className='boldmainHeading_'>{information?.user_details?.user_company_information?.company_name}</h4> */}
                        <h4 className='boldmainHeading_'>{information?.posting_title}</h4>

                      </div>

                    </div>
                    {
                      information?.hq_location === "" ? "" :
                        <h5 className='textInnerOuter mt-2 d-flex'>
                          <img className="locationImg_" src={Images.location_} alt="locationImg" />
                          {information?.hq_location}</h5>
                    }
                    <ul className='recentlyDataList'>
                      {
                        information?.post_opportunity_diversity_details?.map((data, index) => (
                          <li><div className='recentlyBtn'>{data?.diversity_status_details?.name}</div></li>

                        ))
                      }
                      {/* <li><div className='recentlyBtn'>Disabled</div></li> 
                        <li><div className='recentlyBtn'>Family Owned</div></li>    */}
                    </ul>
                    <div className="profileData">
                      <h3 className="txt16 fw-bold mb-2">Contract Details</h3>
                      <p className="txt16 fw-normal profileDes mb-4">
                        {information?.description}
                      </p>

                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="viewProfileDetails mt-5">
                <div className="row">
                  {/* <div className="col-md-4 mb-3">
                    <h4 className="memberblueHead_ mb-1">Response Deadline</h4>
                    <p className="discription_ mb-0">{moment(information?.response_deadline_date).fromNow()}</p>
                  </div> */}
                  {/* <div className="col-md-4 mb-3">
                    <h4 className="memberblueHead_ mb-1">Award Date</h4>
                    <p className="discription_ mb-0">{moment(information?.award_date).fromNow()}</p>
                  </div> */}
                  {/* <div className="col-lg-12  mb-3">
                    <h5 className='headingSmall_'>Contract Details</h5>
                    <p className='discription_ mt-2'>{information?.description}</p>
                  </div> */}
                  <div className="col-md-4 mb-3">
                    <h4 className="memberblueHead_ mb-1">Currency</h4>
                    <p className="discription_ mb-0">{information?.currency}</p>
                  </div>
                  {information?.opportunity_amount === 0 ? "" :
                    <div className="col-md-4 mb-3">
                      <h4 className="memberblueHead_ mb-1">Contract Amount</h4>
                      <p className="discription_ mb-0">${information?.opportunity_amount?.toLocaleString()}</p>
                    </div>
                  }
                  {information?.contract_length === "" ? "" :
                    <div className="col-md-4 mb-3">
                      <h4 className="memberblueHead_ mb-1">Contract Length</h4>
                      <p className="discription_ mb-0">{information?.contract_length}</p>
                    </div>
                  }
                  {information?.industries_group_details === null ? "" :
                    <div className="col-md-4 mb-3">
                      <h4 className="memberblueHead_ mb-1">Industry Group</h4>
                      <p className="discription_ mb-0">{information?.industries_group_details?.name}</p>
                    </div>
                  }
                  {information?.industries_sub_group_details === null ? "" :
                    <div className="col-md-4 mb-3">
                      <h4 className="memberblueHead_ mb-1">Sub Industry Group</h4>
                      <p className="discription_ mb-0">{information?.industries_sub_group_details?.name}</p>
                    </div>
                  }
                  <div className="col-md-4 mb-3">
                    <h4 className="memberblueHead_ mb-1">Specific Service Location</h4>
                    <p className="discription_ mb-0">{information?.specific_service_locations_required == true ? "Yes" : "No"}</p>
                  </div>
                  {
                    information?.hq_location === "" ? "" :
                      <div className="col-md-4 mb-3">
                        <h4 className="memberblueHead_ mb-1">Service Location</h4>
                        <p className="discription_ mb-0">{information?.hq_location}</p>
                      </div>
                  }
                  {information?.post_opportunity_diversity_details?.length > 0 ?
                    <>
                      <div className="col-md-4 mb-3">
                        <h4 className="memberblueHead_ mb-1">Diverse Status</h4>
                        {information?.post_opportunity_diversity_details?.length > 0 ?
                          <>
                            {information?.post_opportunity_diversity_details?.map((data, index) => {
                              return (
                                <>
                                  <p className='discription_ mb-0'>{data?.diversity_status_details?.name}</p>
                                </>
                              )
                            })
                            }
                          </> : <p>No Diverse Status found</p>
                        }
                      </div>
                    </>
                    : ""}
                  {
                    information?.opportunity_existing_certificate_details?.length > 0 ?
                      <div className="col-md-4 mb-3">
                        <h4 className="memberblueHead_ mb-1">Existing Certifications</h4>
                        {information?.opportunity_existing_certificate_details?.length > 0 ?
                          <>
                            {information?.opportunity_existing_certificate_details?.map((data, index) => {
                              return (
                                <>
                                  <p className='discription_ mb-0'>{data?.existing_certificates_details?.name}</p>
                                </>
                              )
                            })
                            }
                          </> : <p>No Existing Certifications found</p>
                        }
                      </div>
                      : ""}
                  <div className="col-md-4 mb-3">
                    <h4 className="memberblueHead_ mb-1">Service Licenses Requires?</h4>
                    <p className="discription_ mb-0">{information?.service_licenses_required == true ? "Yes" : "No"}</p>
                  </div>
                  {
                    information?.opportunity_media ?
                      <>
                        <p><b className="me-3">Opportunity Media</b> <a href={information?.opportunity_media} target="_blank" ><i class="fas fa-file opportunityDownload"></i></a></p>
                        {/* {
                          information?.image_mime_type === "image/png" ?
                            <img src={information?.opportunity_media} /> : ""
                        } */}

                      </>
                      : ""
                  }
                </div>
              </div>
            </div>

            {/* <div className='contractDetails_ mt-5'>
              <div className='designbtnRight mt-3'>
                {information?.is_applied == null ?
                  <button className='subsmallBtnblue_ me-2' onClick={() => ReplyNow()
                  } >Apply Now</button>
                  : ""
                }
                {
                  information?.is_applied == null ?
                    <button onClick={() => {
                      setModalDetail({ show: true, flag: "ComposeMessage" });
                      setKey(Math.random());
                    }} className='secondaryBtnGrey' disabled >Send Message</button>
                    :
                    <button onClick={() => {
                      setModalDetail({ show: true, flag: "ComposeMessage" });
                      setKey(Math.random());
                    }} className='subsmallBtngreen_'>Send Message</button>
                }
                <button onClick={() => {
                    setModalDetail({ show: true, flag: "ComposeMessage" });
                    setKey(Math.random());
                  }} className='subsmallBtngreen_'>Send Message</button>
              </div>
            </div> */}
            {
              information?.moreOpportunites?.data?.length > 0 ?
                <>
                  <hr className="bottomBorder_"></hr>

                  <div className='businessDesincontent_  aboutCopany_ mt-3'>
                    <div className='row mb-2'>
                      <div className='col-md-6 col-lg-6 col-6'>
                        <h3 className='boldmainHeading_ mb-4'>
                          More Opportunities</h3>
                      </div>
                      {/* <div className='col-md-6 col-lg-6 col-6 text-end'>
                  <button className='subsmallBtnblue_' onClick={() => { followCompany(information?.user_details?.user_company_information?.company_name, information?.user_details?.user_company_information?.id) }}>Follow Company</button>
                </div> */}
                    </div>
                    {
                      information?.moreOpportunites?.data?.map((data, index) => (
                        <div className="moreOpportunities ">
                          <div className="moreOppLeft">
                            <h4 className="mb-0">{data?.posting_title}</h4>
                            <p className="mb-0">{data?.hq_location}</p>
                          </div>
                          <div className="moreOppRight">
                            <p className="mb-0 me-3"><span>Posted</span> {moment(data?.created_at).fromNow()}</p>
                            <button className="" onClick={(e) => {
                              handleSubmited(e, data?.id)
                            }}>View Now</button>
                          </div>
                        </div>
                      ))
                    }

                    {/* <div className="moreOpportunities ">
                    <div className="moreOppLeft">
                      <h4 className="mb-0">Server Migration</h4>
                      <p className="mb-0">Ottawa, ON Remote</p>
                    </div>
                    <div className="moreOppRight">
                      <p className="mb-0 me-3"><span>Posted</span>  10 hours ago</p>
                      <button className="">View Now</button>
                    </div>
                  </div>
                  <div className="moreOpportunities">
                    <div className="moreOppLeft">
                      <h4 className="mb-0">Server Migration</h4>
                      <p className="mb-0">Ottawa, ON Remote</p>
                    </div>
                    <div className="moreOppRight">
                      <p className="mb-0 me-3"><span>Posted</span>  10 hours ago</p>
                      <button className="">View Now</button>
                    </div>
                  </div> */}
                    {/* <div className='row align-items-center'>
                <div className='col-lg-2 col-md-2'>
                  <div className='designImg_'>
                    <img className='imgproduct_' src={information?.user_details?.user_company_information?.company_logo ? information?.user_details?.user_company_information?.company_logo : Images.atlanticsmall} />
                  </div>
                </div>
                <div className='col-lg-10 col-md-10'>
                  <div className='designContent_'>
                    <div className='designcontentTop_'>
                      <div className='designLeft_'>
                        <h4 className='headingTitleSmall_'>{information?.user_details?.user_company_information?.company_name}</h4>
                      </div>
                    </div>
                    <p className='innerSubtextSmall mt-2'>{information?.user_details?.user_company_information?.company_service_description}</p>
                  </div>
                </div>
              </div> */}
                  </div>
                </>

                : ""}
          </div>
        </div>

        <CustomModal
          key={key}
          show={modalDetail.show}
          backdrop="static"
          showCloseBtn={false}
          isRightSideModal={false}
          mediumWidth={false}
          className={modalDetail.flag === "Addlabels" ? "commonWidth customContent" : ""}
          ids={modalDetail.flag === "Addlabels" ? "Addlabels" : "ComposeMessage"}
          child={modalDetail.flag === "ComposeMessage" ? (
            <ComposeMessage
              opportunityCompanyName={information?.user_details?.user_company_information?.company_name}
              opportunityCompanyId={information?.user_details?.user_company_information?.user_id}
              close={() => handleOnCloseModal()}
            />
          )
            :
            modalDetail.flag === "SendMessage" ?
              <SendMessage
                companyInfo={information}
                handeCompany={() => ViewDetails(id)}
                close={() => handleOnCloseModal()}
              />
              :
              ""}
          header={
            <div className='modalHeader_'>
              <div className="common_">
                <h4 className="headingSmall_">Compose Message</h4>
              </div>
            </div>
          }
          onCloseModal={() => handleOnCloseModal()}
        />
      </div>
    </div >
  )
}

export default ViewProfile