import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CustomModal from "../../components/common/shared/CustomModal";
import { useAuthSelector } from "../../../redux/selector/auth";
import * as Images from "../../../utilities/images";
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { allDashboardData, allPostComment, allPostList, commentLike, deletePost, disableEnableComment, editPost, getDashboardOpportunity, getNestedComments, getPendingEndorsement, getPostSingleDetails, postFeature, postImageUplaod, postUserLikeList, setSavedReduxData, updateEndorsementsRequest, updateUserProfileStatus, userPostComment } from "../../../redux/slices/dashboard";
import { useDashboardSelector } from "../../../redux/selector/dashboard";
import { userPost, userPostLike } from "../../../redux/slices/dashboard";
import CommmentBox from "./comment";
import CompanyLogo from "./companyLogo";
import InfiniteScroll from 'react-infinite-scroll-component';
import { opportunityResponse } from "../../../redux/slices/opportunity";
import { getCompanyProfileInfo } from "../../../redux/slices/auth";
import { logout } from '../../../redux/slices/auth';
import AllPostLikeUser from "./allPostLikeUser";
import AddTagToPost from "./modals/addTagToPost";
import EndorsementsText from "./modals/endorsementsText";
import swal from "sweetalert";
import { closeSocket, getSocket } from "../../components/socketNew";
import AddNewPost from "./modals/addNewPost";
import Linkify from 'react-linkify';
import { setDiverseStatusEmpty } from "../../../redux/slices/certification";
import Sidebar from "../../components/common/sidebar";

const AccountDashboard = () => {
    const postDivRefs = useRef([]);
    const [hideEditBtn, setHideEditBtn] = useState(false);
    const authSelector = useAuthSelector();
    const [loader, setLoader] = useState(false)
    const [uploadloader, setUploadLoader] = useState(false)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const dashboardSelector = useDashboardSelector();
    const [userDeatil, setUserDeatil] = useState();
    const [newPost, setNewPost] = useState(false)
    const { id } = useParams();
    const like_Status = dashboardSelector?.allPost;
    const [allPost, setAllPostList] = useState([]);
    const [allDashboard, setAllDashboard] = useState("");
    const [post, setPost] = useState("");
    const [reviewResponses, setReviewResponses] = useState('');
    const [userData, setUserData] = useState()
    const [photoInfo, setPhotoInfo] = useState();
    const [page, setPages] = useState(1);
    const [videoUrlOriginal, setVideoUrlOriginal] = useState();
    const [totalPages, setTotalPages] = useState();
    const [showNestedReply, setShowNestedReply] = useState(false)
    const [nestedReply, setNestedReply] = useState("")
    const [editId, setEditId] = useState("")

    const [photo, setPhotoUpload] = useState({
        uploadPhoto: "",
        flag: ""
    });
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });

    const [mainTags, setMainTags] = useState({
        companies: [],
        users: [],
    })
    const [selectedIds, setSelectedIds] = useState({
        companies: [],
        users: [],
    })

    const [myOpportunities, setMyOpportunities] = useState('');

    const userSigninInfo = authSelector?.userInfo?.user?.user_company_information !== null ? authSelector?.userInfo?.user?.user_company_information : authSelector?.userInfo?.user?.user_selected_company_information?.user_company_information;

    const userStepInfo = authSelector?.userStep?.user_company_information;

    const [hasMore, setHasMore] = useState(true);
    const observerRef = useRef();

    const [editFlag, setEditFlag] = useState(false);
    const [editPostID, setEditPostID] = useState(false);


    const [showNewBackground, setShowNewBackground] = useState(false);


    //comments section States
    const post_id = modalDetail?.id;
    const [allCommentList, setAllCommentList] = useState([]);
    const [allCommentsLoad, setAllCommentsLoad] = useState(false);
    const [postIndexMoreComments, setPostIndexMoreComments] = useState("");
    const [allCommentData, setAllCommentData] = useState({});
    const [comment, setComment] = useState("");
    const [postID, setPostID] = useState("");
    const userInfo = authSelector?.userInfo?.user;

    const [replyFlag, setReplyFlag] = useState(false)
    const [replyName, setReplyName] = useState('')
    const [replyId, setReplyId] = useState(null)
    const [selectedIndex, setSelectedIndex] = useState(null)

    const chooseBusinessType = authSelector?.userStep?.user?.business_type ?? authSelector?.userStep?.business_type;

    const choosePlan = authSelector?.userStep?.user?.membership_details?.name ?? authSelector?.userStep?.membership_details?.name;

    //End Comments Section States

    useEffect(() => {
        //Set showNewBackground to true after 1 second
        const timeoutId = setTimeout(() => {
            setShowNewBackground(true);
        }, 3000);
        const socketInstance = getSocket();
        if (socketInstance) {
            socketInstance.on('new_post', (data) => {
                if (data.user_id !== userSigninInfo?.user_id) {
                    setNewPost(true)
                }
            })
        }

        //Clean up the timeout to avoid memory leaks
        return () => {
            clearTimeout(timeoutId);
            closeSocket();
        }
    }, []);

    const updateTagData = (tagData, idData) => {
        setMainTags(tagData)
        setSelectedIds(idData)
    }

    const removeTags = (itemId, name, type) => {

        const data = { ...mainTags };
        const IdData = { ...selectedIds };

        if (type === 'company') {

            if (IdData.companies.includes(itemId)) {
                // Remove the company from the selected list and data
                const index = IdData.companies.indexOf(itemId);
                if (index > -1) {
                    IdData.companies.splice(index, 1);
                    data.companies = data.companies.filter(company => company.id !== itemId);
                }
            }
        } else {

            if (IdData.users.includes(itemId)) {
                // Remove the user from the selected list and data
                const index = IdData.users.indexOf(itemId);
                if (index > -1) {
                    IdData.users.splice(index, 1);
                    data.users = data.users.filter(user => user.id !== itemId);
                }
            }
        }

        setMainTags(data)
        setSelectedIds(IdData)
    };


    //user logout
    const userLogout = () => {
        dispatch(logout({
            cb(res) {
                if (res.status) {
                    navigate("/")
                    emptyDiverseCertificates()
                }
                else {
                }
            }
        }))
    }

    const emptyDiverseCertificates = () => {
        let params = {}
        dispatch(setDiverseStatusEmpty({
            ...params, cb(res) {

            }
        }))
    }

    //for COMPANY PROFILE INFORMATION.......
    const companyProfileInfo = () => {
        dispatch(getCompanyProfileInfo({
            cb(res) {
                if (res.status) {
                    if (res?.data?.payload?.userDetails?.user_company_information !== null) {
                        setUserDeatil(res?.data?.payload?.userDetails?.user_company_information)
                        setUserData(res?.data?.payload?.userDetails)
                    }
                    else {
                        setUserData(res?.data?.payload?.userDetails)
                        setUserDeatil(res?.data?.payload?.userDetails?.user_selected_company_information?.user_company_information)
                    }

                }
                else {

                }
            }
        }))
    }

    //closeModal
    const handleOnCloseModal = () => {

        setEditId("")
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
        fetchData();
    };

    // total user like post
    const totalUserLikeList = (id) => {
        setModalDetail({ show: true, flag: "allPostLikeUser", id: id });
        setKey(Math.random());
    }

    //handle play button icon
    const handleVideoPlay = (post, index) => {
        setAllPostList((prevData) => {
            let data = [...prevData]

            // If you want to update the like status
            const updatedPost = {
                ...data[index], isvideo: true,
            };
            data[index] = updatedPost;

            return data;
        })
    }

    //for post like...............
    const postLike = (id, flag, index) => {
        const params = {
            post_id: id,
            like_status: flag,
        }
        dispatch(userPostLike({
            ...params, cb(res) {
                if (res.status) {
                    setAllPostList((prevData) => {
                        let data = [...prevData]
                        if (flag === 1) {
                            // If you want to update the like status
                            const updatedPost = {
                                ...data[index], is_like: res?.data?.payload?.is_like, likeCount: res?.data?.payload?.likeCount
                            };
                            data[index] = updatedPost;
                        } else {
                            // If you want to reset the like status to null
                            const updatedPost = { ...data[index], is_like: res?.data?.payload?.is_like, likeCount: res?.data?.payload?.likeCount };
                            data[index] = updatedPost;
                        }
                        return data;
                    })
                }
                else {
                }
            }
        }))
    }



    //remove file
    const removeFile = file => () => {
        const newFiles = [photoInfo]
        newFiles.splice(newFiles.indexOf(file), 1)

        setPhotoInfo(newFiles)

        setPhotoInfo('')
        setPhotoUpload('')
        setVideoUrlOriginal('')

    }

    //onChange input
    const onChangeInput = (e, fieldName) => {
        const value = e.target.value;
        if (fieldName === "post") {
            setPost(value)
        }
    };

    //onChange image
    const onChangePicture = (e, flag) => {
        const imageFile = e.target.files[0];
        e.currentTarget.value = null
        // if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
        //     toast.error("Please select a valid image.");
        //     return false;
        // }

        if (imageFile) {
            const acceptedTypes = ['image/jpeg', 'image/jpg', 'image/heic', 'image/heif', 'video/mp4', 'image/gif', 'image/png'];
            if (acceptedTypes.includes(imageFile.type)) {
                // File type is supported, you can proceed with processing the file here
            } else {
                alert('Unsupported file type. Please choose a different file.');
            }
        }
        if (imageFile.size > 25000000) {
            toast.error("File size too large.");
            return false;
        }
        let params = {
            photo: imageFile,
            type: "post"
        }
        setUploadLoader(true)
        dispatch(postImageUplaod({
            ...params, cb(res) {
                if (res.status) {
                    setUploadLoader(false)
                    if (res?.data?.payload?.originalUrl?.format == "video/mp4") {
                        setPhotoUpload({ ...photo, uploadPhoto: res?.data?.payload?.thumbnailUrl, flag: 3 });
                        setVideoUrlOriginal(res?.data?.payload?.originalUrl.url)
                    }
                    else {
                        setPhotoUpload({ ...photo, uploadPhoto: res?.data?.payload?.thumbnailUrl, flag: flag })
                        setVideoUrlOriginal(res?.data?.payload?.originalUrl.url)
                    }
                    setPhotoInfo([res?.data?.payload?.thumbnailUrl.url])
                }
                else {
                }
            }
        }))
    }

    //all dashboard data
    const getAllDashboardData = () => {
        dispatch(allDashboardData({
            cb(res) {
                if (res.status) {
                    setAllDashboard(res.data.payload)
                }
            }
        }))
    }

    // handle submit
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!post.trim()) {
            toast.error("Please enter Post text");
            return;
        }
        else if (!post) {
            toast.error("Please enter Post without trim");
            return;
        }

        else if (post?.length <= 2) {
            toast.error("Name should be maximum 3 character")
            return;
        }
        // else if (!photo.uploadPhoto && !photo.flag) {
        //     toast.error('Please select image')
        //     return
        // }
        let params = {};

        if (post !== "") {
            params.post = post.trim();
        }

        if (photoInfo) {
            params.attachment = videoUrlOriginal;
            params.attachment_type = photo.flag;
            params.thumbnail_url = photo.uploadPhoto?.url;
            params.attachment_name = photo.uploadPhoto?.name;
            params.attachment_size = photo.uploadPhoto?.size;
        }

        if (selectedIds.companies?.length > 0) {
            params.company_tags = selectedIds.companies;
        }

        if (selectedIds.users?.length > 0) {
            params.user_tags = selectedIds.users;
        }

        if (editFlag) {
            params.id = editPostID
            dispatch(editPost({
                ...params, cb(res) {
                    if (res.status == 200) {
                        setEditFlag(false)
                        setEditPostID(null)
                        setVideoUrlOriginal("")
                        setPhotoInfo("")
                        setPost("")
                        setPhotoUpload({ ...photo, uploadPhoto: "", flag: "" });
                        setMainTags({
                            companies: [],
                            users: [],
                        })
                        setSelectedIds({
                            companies: [],
                            users: [],
                        })

                        setAllPostList((prevData) => {
                            const data = prevData.filter((item, i) => res.data.payload.id != item.id);
                            const newData = [res.data.payload, ...data]
                            return newData;
                        })
                        toast.success("Post Updated Successfully")
                    }
                    else {

                    }

                }
            }))

        }

        else {
            setLoader(true)
            dispatch(userPost({
                ...params, cb(res) {
                    if (res.status) {
                        setLoader(false)
                        setVideoUrlOriginal("")
                        setPhotoInfo("")
                        setPost("")
                        setPhotoUpload({ ...photo, uploadPhoto: "", flag: "" });
                        setMainTags({
                            companies: [],
                            users: [],
                        })
                        setSelectedIds({
                            companies: [],
                            users: [],
                        })
                        setAllPostList([res.data.payload, ...allPost]);
                    }
                }
            }))
        }

    }

    const fetchData = (scrollpage) => {
        let params = {
            page: scrollpage ? scrollpage : page,
            limit: 10,
        };

        dispatch(allPostList({
            ...params, cb(res) {
                if (res?.data) {
                    setTotalPages(res?.data?.payload?.total_pages)

                    if (scrollpage == 1) {
                        setAllPostList([...res?.data?.payload?.posts])
                    } else if (scrollpage >= 1) {
                        setAllPostList((prevData) => {
                            const data = [...prevData]
                            const newData = data.concat(res?.data?.payload?.posts)
                            return newData;
                        })
                    }
                    else {
                        setAllPostList([...res?.data?.payload?.posts])
                    }
                    setTimeout(() => {
                        if (id) {
                            getSelectedPostDetails(id)
                        }
                    }, 1000)
                }
            }
        }))
    };


    const fetchMoreData = () => {
        if (totalPages > page) {
            setPages(page + 1);
            fetchData(page + 1);
        }
    };

    // handle change page
    const handleChangePage = (flag, id) => {
        if (flag == "edit") {
            navigate('/settings/edit-profile', { state: { activeTab: "editProfile" } })
            localStorage.setItem("activeTab", "account");
        }
        else if (flag == "plan") {
            navigate('/settings/manage-subscription', { state: { activeTab: "subscription" } })
            localStorage.setItem("activeTab", "subscription")
        }
        else if (flag == "allOpportunities") {
            navigate('/create-opportunities/review-responses', { state: { activeTab: "reviewapplication" } })
            localStorage.setItem("selectedOpportunity", "reviewapplication")
        }
        else if (flag == "setting") {
            navigate('/settings', { state: { activeTab: "privacy" } })
            localStorage.setItem("activeTab", "privacy")
        }
        else if (flag == "viewResponse") {

            navigate('/create-opportunities/view-response', { state: { activeTab: "ReviewRespondents", oppId: id } })
            localStorage.setItem("selectedOpportunity", "ReviewRespondents")
        }
        else {
            navigate('/create-opportunities', { state: { activeTab: "activeOpportunity" } })
            localStorage.setItem("activeTabSetting", "activeOpportunity")
        }
    }

    // handle  page change 
    const handlePageRedirect = (name, id) => {
        const formattedName = name?.replace(/\s+/g, '-'); // Replace spaces with hyphens
        if (id) {
            navigate(`/${formattedName}/${id}`)
        }
    }

    // handle  page change 
    const handlePageRedirectMyProfile = (name) => {
        const formattedName = name?.replace(/\s+/g, '-'); // Replace spaces with hyphens
        navigate(`/${formattedName}`)
    }

    // handle  view profile 
    const handleViewProfile = () => {
        navigate(`/${userDeatil?.company_name}`)
    }

    const handleEndorsements = () => {
        setModalDetail({ show: true, flag: "Endorsements" });
        setKey(Math.random());
    }

    // For set signup and signin Comapny Logo
    let ComapnyLogo;
    if (userDeatil?.company_logo) {
        ComapnyLogo = userDeatil?.company_logo;
    }
    else {
        ComapnyLogo = Images.puzzleProfile;
    }

    // API for get MyOpportunities ............
    const myOpportunity = (page = 1, limit = 10) => {
        let params = {
            page: page,
            limit: limit,
        }
        dispatch(getDashboardOpportunity({
            ...params, cb(res) {
                if (res.status) {
                    setMyOpportunities(res?.data?.payload?.data)
                }
            }
        }))
    }


    // // API for post Feature ..............
    const postFetures = (id, flag, index) => {
        let params = {
            post_id: id,
            feature_status: flag,
        }
        dispatch(postFeature({
            ...params, cb(res) {
                if (res.status) {
                    setAllPostList((prevData) => {
                        let data = [...prevData]
                        if (flag === 2) {
                            // If you want to update the like status
                            const updatedPost = {
                                ...data[index], feature_post: res?.data?.payload?.feature_post
                            };
                            data[index] = updatedPost;
                        } else {
                            // If you want to reset the like status to null
                            const updatedPost = { ...data[index], feature_post: res?.data?.payload?.feature_post, };
                            data[index] = updatedPost;
                        }
                        return data;
                    })
                }
            }
        }))
    }

    // // API for post delete ..............
    const deletePostAction = (id, index) => {
        swal({
            text: `Are you sure want to remove this post?`,
            dangerMode: false,
            buttons: true,
        })
            .then(result => {
                if (result) {
                    let params = {
                        id: id,
                    }
                    dispatch(deletePost({
                        ...params, cb(res) {
                            if (res.status) {

                                setAllPostList((prevData) => {
                                    const data = prevData.filter((item, i) => i !== index);
                                    return data;
                                })

                                toast.success("Post deleted successfully")

                            }
                        }
                    }))
                }
            });

    }

    const editPostAction = (id) => {
        let params = {
            id: id,
        }
        dispatch(getPostSingleDetails({
            ...params, cb(res) {
                if (res.status) {

                    setEditFlag(true)
                    setEditPostID(res.data.payload?.id)
                    setPost(res.data.payload?.post ?? '')

                    const companyTagData = res.data.payload?.tag_company_details?.map((item, i) => {
                        return {
                            id: item.company_id,
                            name: item?.company_information?.company_name
                        }
                    })

                    const userTagData = res.data.payload?.tag_user_details?.map((item, i) => {
                        return {
                            id: item.user_id,
                            name: item?.user_details?.user_profile?.first_name + " " + item?.user_details?.user_profile?.last_name
                        }
                    })

                    setMainTags({
                        companies: companyTagData,
                        users: userTagData
                    })

                    setSelectedIds({
                        companies: companyTagData.map((item) => item.id),
                        users: userTagData.map((item) => item.id)
                    })

                    if (res?.data?.payload?.attachment) {

                        setPhotoUpload({
                            uploadPhoto: {

                                name: res?.data?.payload?.attachment_name ?? 'File',
                                size: res?.data?.payload?.attachment_size ?? 100000,
                                url: res?.data?.payload?.thumbnail_url

                            }, flag: res?.data?.payload?.attachment_type
                        });

                        setVideoUrlOriginal(res?.data?.payload?.attachment)

                        setPhotoInfo([res?.data?.payload?.thumbnail_url])

                    }

                    if (window)
                        window.scrollTo(0, 0);

                }
            }
        }))
    }

    const copyLinkToClipboard = (postId) => {

        const currentBaseUrl = window.location.origin;
        const dynamicUrl = `${currentBaseUrl}/dashboard/post/${postId}`;

        if (postId) {
            navigator.clipboard.writeText(dynamicUrl).then(() => {
                toast.dismiss()
                toast.success('Link copied to clipboard')
            });
        }
        else {
            toast.error('Somenthing went wrong')
        }

    }

    // // API for post delete ..............
    const disableEnableCommentAction = (id, flag, index) => {
        swal({
            text: `Are you sure want to ${flag == 1 ? 'disable' : 'enable'} comments on this post?`,
            dangerMode: false,
            buttons: true,
        })
            .then(result => {
                if (result) {
                    let params = {
                        id: id,
                        disable_comment: flag
                    }
                    dispatch(disableEnableComment({
                        ...params, cb(res) {
                            if (res.status) {

                                setAllPostList((prevData) => {

                                    let data = [...prevData]
                                    data[index] = { ...data[index], disable_comment: flag }
                                    return data;

                                })
                                toast.success(res?.data?.msg)
                            }
                        }
                    }))
                }
            });

    }

    const getSelectedPostDetails = (id) => {
        let params = {
            id: id,
        }
        dispatch(getPostSingleDetails({
            ...params, cb(res) {
                if (res.status) {

                    if (res.data.payload.id) {
                        setAllPostList((prevData) => {

                            const data = prevData.filter((item, i) => res.data.payload.id != item.id);
                            const newData = [res.data.payload, ...data]

                            return newData;
                        })
                    }
                }
            }
        }))
    }

    //update Endorsements
    const userProfileStatus = (flag) => {
        if (flag == 1) {
            dispatch(updateUserProfileStatus({
                cb(res) {
                    if (res.status) {
                        navigate('/setting', { state: { activeTab: "editProfile" } })
                    }
                }
            }))
        }
        else if (flag == 2) {
            dispatch(updateUserProfileStatus({
                cb(res) {
                    if (res.status) {
                        companyProfileInfo()
                    }
                }
            }))
        }

    }

    const handleObserver = (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting && hasMore) {
            // Perform additional actions if needed when the observer element is visible
            // In this example, we disable the observer to prevent further loading.
            observerRef.current = null;
        }
    };


    const handleNavigationActiveInactive = (value) => {
        // active
        if (value === 1) {
            navigate('/create-opportunities', {
                state: { activeTab: 'activeOpportunity' }
            });
        }
        else if (value === 0) {
            // in-active
            navigate('/create-opportunities', {
                state: { activeTab: 'inactiveOpportunity' }
            });
        }
    }


    // API for get Review Responses Opportunity ............
    const ReviewResponsesOpportunity = (page = 1, limit = 10) => {
        let params = {
            page: page,
            limit: limit,
        }
        dispatch(opportunityResponse({
            ...params, cb(res) {
                if (res.status) {
                    setReviewResponses(res?.data?.payload?.data)
                }
            }
        }))
    }
    const truncateText = (text, maxLength) => {
        // Function to truncate text after a certain length
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '  ';
        }
        return text;
    };

    //comments Section start
    const handleReplyComent = async (commentId, name, index = null, flag) => {
        getNestedCommentAction(commentId, index)
        if (flag === 'open') {
            if (replyFlag) {
                setReplyFlag(false)
                setReplyName('')
                setReplyId(null)
                setSelectedIndex(null)
            }

            //    setAllCommentList((prev) => {

            //    })
            setAllCommentList((prevData) => {
                let data = [...prevData]
                const updatedComment = {
                    ...data[index], show_nested_comment: (data[index]?.show_nested_comment ? false : true)
                };
                data[index] = updatedComment;
                return data;
            })
        }
        else {
            setReplyFlag(true)
            setReplyName(name)
            setReplyId(commentId)
            setSelectedIndex(index)
            setAllCommentList((prevData) => {
                let data = [...prevData]
                const updatedComment = {
                    ...data[index], show_nested_comment: true
                };
                data[index] = updatedComment;
                return data;
            })
        }

    }

    // //post all comments
    const handleLoadMoreComments = (id, index, page = 1, limit = 10) => {
        let params = {
            id: id,
            page: page,
            limit: limit
        };
        dispatch(allPostComment({
            ...params, cb(res) {
                if (res.status) {
                    setPages(page);
                    setAllCommentsLoad(true);
                    setPostIndexMoreComments(index)
                    if (page === 1) {
                        // For the first page
                        if (allCommentList.length === 0) {
                            setAllCommentList(res.data.payload?.comments || []);
                        } else {
                            setAllCommentList((prevData) => {
                                // Replace existing comments with new comments
                                const updatedComments = res.data.payload?.comments || [];
                                return updatedComments;
                            });
                        }
                    } else {
                        // For pages other than the first page (e.g., page 2)
                        setAllCommentList((prevData) => {
                            // Concatenate existing comments with new comments
                            const updatedComments = prevData.concat(res.data.payload?.comments || []);
                            return updatedComments;
                        });
                    }

                    setAllCommentData(res.data.payload);
                }

            }
        }))
    };

    //for post comment...............
    const postComment = (id, index, flag) => {
        if (comment !== "") {
            if (!comment.length === "") {
                toast.error("Please enter text to comment");
                return;
            }
        }
        const params = {
            post_id: id,
            // comment: comment ? comment : ""
        }
        if (flag === "comment") {
            params.comment = comment
        }

        if (flag === "replyFlag") {
            params.comment_parent_id = replyId
            params.comment = nestedReply
        }
        dispatch(userPostComment({
            ...params, cb(res) {
                if (res.status) {
                    if (flag === "replyFlag") {
                        setShowNestedReply(false)
                        if (postDivRefs.current[index]) {
                            postDivRefs.current[index].scrollIntoView({ behavior: 'smooth', block: 'start' });
                        }
                        setReplyId("")

                        setComment("");
                        setPostID("")
                        setAllPostList((prevData) => {

                            let data = [...prevData];

                            if (index >= 0 && index < data?.length) {
                                const postToUpdate = data[index];

                                // Assuming res?.data?.payload contains the new comment data
                                const updatedCommentReplies = [...postToUpdate.post_comments[index].comment_replies, res?.data?.payload?.comment];

                                const updatedPost = {
                                    ...postToUpdate,
                                    post_comments: [
                                        ...postToUpdate.post_comments.slice(0, index), // Keep the comments before the index
                                        {
                                            ...postToUpdate.post_comments[index],
                                            comment_replies: updatedCommentReplies // Update comment_replies at the specified index
                                        },
                                        ...postToUpdate.post_comments.slice(index + 1) // Keep the comments after the index
                                    ]
                                };

                                data[index] = updatedPost;
                            }

                            return data;
                        });
                    }
                    else {
                        if (postDivRefs.current[index]) {
                            postDivRefs.current[index].scrollIntoView({ behavior: 'smooth', block: 'start' });
                        }

                        setComment("");
                        setPostID("")
                        setSelectedIndex(index)
                        setAllCommentList((prevData) => {

                            if (prevData[index] && prevData[index]?.length > 0) {
                                return [
                                    res?.data?.payload,
                                    ...prevData[index]
                                ]
                            }
                            else {
                                return [
                                    res?.data?.payload,
                                ]
                            }
                        })
                        setAllPostList((prevData) => {
                            let data = [...prevData];

                            if (index >= 0 && index < data?.length) {
                                const postToUpdate = data[index];

                                const updatedPost = {
                                    ...postToUpdate,
                                    total_comments: (parseInt(postToUpdate.total_comments) + 1).toString(),
                                    post_comments: [res?.data?.payload, ...postToUpdate.post_comments] // Add the new comment at the beginning
                                };
                                data[index] = updatedPost;
                            }

                            return data;
                        });
                    }
                }
            }
        }))
    }

    //get nested post comment...............
    const getNestedCommentAction = (commentId, index, page = 1, limit = 10) => {
        let params = {
            id: commentId,
            page: page,
            limit: limit
        };

        dispatch(getNestedComments({
            ...params, cb(res) {
                if (res.status) {

                    setAllCommentList((prevData) => {
                        let data = [...prevData]

                        let nestedComments
                        if (data[index]?.comment_replies?.length == 0 || page == 1) {
                            nestedComments = res?.data?.payload?.comments ?? []
                        }
                        else {
                            nestedComments = [
                                ...data[index]?.comment_replies,
                                ...res?.data?.payload?.comments
                            ]
                        }

                        const updatedComment = {
                            ...data[index],
                            comment_replies: nestedComments,
                            comment_page_number: res?.data?.payload?.current_page
                        }

                        data[index] = updatedComment;

                        return data;
                    })
                }
            }
        }))
    }

    // function for user veiw profile buyer & supplier.........
  const handleSubmitedViewProfile = (id, name) => {
    const formattedName = name.replace(/\s+/g, "-"); // Replace spaces with hyphens
    navigate(`/${formattedName}/${id}`);
  };



    //for liiking comment...............
    const commentLikeAction = (id, flag, index, reIndex = null, postindexcomment) => {
        const params = {
            comment_id: id,
            status: flag
        }
        dispatch(commentLike({
            ...params, cb(res) {
                if (res.status) {


                    if (reIndex == null) {

                        setAllCommentList((prevData) => {
                            let data = [...prevData]
                            const updatedComment = {
                                ...data[index], is_comment_liked: res?.data?.payload?.commentLikeDetails ?? null, total_like_count: res?.data?.payload?.commentDetails?.likeCount ?? 0
                            };
                            data[index] = updatedComment;

                            return data;
                        })

                    }

                    else if (postindexcomment === "postindexcomment") {
                        if (flag === 1) {
                            // If you want to update the like status
                            setAllPostList((prevData) => {
                                let data = [...prevData]


                                data[index] = res?.data?.payload?.post_details;
                                return data;
                            })


                        } else {
                            // If you want to reset the like status to null
                            setAllPostList((prevData) => {
                                let data = [...prevData]

                                data[index] = res?.data?.payload?.post_details;
                                return data;
                            })
                        }

                    }
                    else {
                        if (flag === 1) {
                            // If you want to update the like status
                            setAllCommentList((prevData) => {
                                let data = [...prevData]

                                data[index] = res?.data?.payload?.post_details?.post_comments[index];
                                return data;
                            })


                        } else {
                            // If you want to reset the like status to null
                            setAllCommentList((prevData) => {
                                let data = [...prevData]

                                data[index] = res?.data?.payload?.post_details?.post_comments[index];
                                return data;
                            })

                        }

                    }
                }
                else {

                }
            }
        }))
    }

    const handlePost = (e) => {
        setPostIndexMoreComments("")
        e.preventDefault()
        setModalDetail({ show: true, flag: "AddANewPost" });
        setKey(Math.random());
    }

    useEffect(() => {
        // Create a new Intersection Observer instance
        const observer = new IntersectionObserver(handleObserver, {
            root: null,
            rootMargin: '0px',
            threshold: 1.0,
        });

        // Attach the observer to the reference element
        if (observerRef.current) {
            observer.observe(observerRef.current);
        }

        return () => {
            // Clean up the observer on unmount
            observer.disconnect();
        };
    }, [hasMore]);

    useEffect(() => {
        companyProfileInfo();
        getAllDashboardData();
        fetchData()
        myOpportunity()
        ReviewResponsesOpportunity()
        document.title = "Dashboard";
    }, []);


    useEffect(() => {
        if (authSelector?.userStep?.user?.user_company_information !== null) {
            setHideEditBtn(false)
        }
        else if (authSelector?.userStep?.user?.user_company_information === null) {
            setHideEditBtn(true)
        }
    }, [])

    useEffect(() => {
        setShowNestedReply(false)
    }, [])
    const screenWidth = window.innerWidth;

    return (
        <>
            {newPost && modalDetail.show == false &&
                <div>
                    <button className="postBtn" onClick={() => {
                        fetchData(1)
                        setNewPost(false)
                        if (window)
                            window.scrollTo(0, 0);
                    }}>New Posts</button>
                </div>}
            <div className='accountDashboard newAccountDash'>
                <div className='row'>
                    <div className={`col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-3 d-none d-lg-block ${screenWidth > 1200 ? "customColLgThree" : ""}`}>
                        <h3 className="mainHeading">
                            Company Profile
                        </h3>
                        <div className='companyContainerArea'>
                            <div className='motionSec ps-4 pe-4 '>

                                <div className="companylogo text-center" onClick={() => {
                                    setModalDetail({ show: true, flag: "CompanyLogo" });
                                    setKey(Math.random());
                                }}>
                                    {!userDeatil ?
                                        <>
                                            <div className="row">
                                                <div className="col-sm-12 text-center pt-5 pb-5">
                                                    <div className="spinner-grow text-success" role="status"></div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <img src={ComapnyLogo} className='img-fluid motionImg newMotionImg' alt='motionImg' />
                                    }
                                </div>
                                <div className='motionSecData mt-3'>

                                    <p className="mb-0 fw-500">{userInfo?.user_profile?.first_name} {userInfo?.user_profile?.last_name}</p>
                                    <h5 className='headingSmall_ mb-3'><b>{userSigninInfo?.company_name ? userSigninInfo?.company_name : userStepInfo?.company_name}</b></h5>


                                    {/* {userDeatil?.country_details?.name ?<p className='motionLoc'>
                                            <img
                                                src={Images.locationpin}
                                                alt="Location"
                                                className="me-1"
                                            />
                                            <span className="textInnerOuter">
                                                {userDeatil?.province_details?.name} / {userDeatil?.country_details?.name}
                                            </span>
                                        </p> : ""

                                    } */}
                                    <div className="dashboardButton mx-auto">
                                        
                                        {
                                            userData?.user_company_information !== null ?
                                                <button className='primaryBtnSmall d-inline-block w-75 newPlanBtn' onClick={() => { handleChangePage("plan") }}>{userData?.membership_type == 1 ? "Platinum" : userData?.membership_type == 4 ? "Platinum" : userData?.membership_type == 2 ? "Gold" : userData?.membership_type == 5 ? "Gold" : userData?.membership_type == 3 ? "Bronze" : userData?.membership_type == 6 ? "Bronze" : ""} Plan
                                                </button>
                                                : ""
                                        }
                                        <button className='secondaryBtnOuter mt-2 w-75 ' onClick={() => { handleViewProfile() }}>View Profile</button>
                                    </div>
                                </div>
                            </div>
                            <Sidebar />
                            <div className='cardContainer mt-4'>
                                <div className='cardImg'>
                                    <img src={Images.certifyUser} className='img-fluid' alt='CardImg' />
                                </div>
                                <div className='cardBodyArea'>
                                    <h5>Get Certified</h5>
                                    <div className="infoiconText">
                                        <span className="showinfoText">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span>
                                    </div>
                                    <p className='my-4'>OPPSWell Diversity Certifications for inclusive business futures. </p>
                                    <div className="infoiconText m-0">
                                        <Link to="/certificate">
                                            <button className='primaryBtn'>Learn More</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-5 ${screenWidth > 1200 ? "customColLgFive" : ""}`}>
                        <h3 className="mainHeading d-none d-md-block" style={{ visibility: 'hidden' }}>Community Feed</h3>
                        {userDeatil?.is_completed == false ?
                            <div className="updateBox">
                                <figure className="m-0">
                                    <img src={Images.Cancel} className="updateIcon" onClick={() => { userProfileStatus("2") }} />
                                </figure>
                                <artical>
                                    <h5 className="updateHeading">Heads up!</h5>
                                    <p className="updateSubheading">Your company profile is not complete yet.</p>
                                </artical>
                                <button className='primaryBtnSmall' onClick={() => { userProfileStatus("1") }}>Update now</button>
                            </div>
                            : ""
                        }
                        <div className="form-group col-md-12">

                            <div className="d-flex mb-3 startPostCon">
                                <img src={ComapnyLogo} alt="img" className="postImg__ me-2 " />
                                <button className="pe-0"><img src={Images.startPost4} alt="img" className="cursor img-fluid"
                                    onClick={(e) => {
                                        handlePost(e)

                                    }}
                                />
                                </button>
                            </div>
                            <div>
                            </div>
                        </div>
                        <div className='postSec'>
                            {
                                loader ? <div className="row">
                                    <div className="col-sm-12 text-center pt-5 pb-5">
                                        <div className="spinner-grow text-success" role="status"></div>
                                    </div>
                                </div>
                                    :
                                    <form className='postForm row align-items-center'>
                                    </form>
                            }
                            <div className='row'>
                                <div className='col-md-12 tabindex_'>
                                    <div className='postContainer set-scroll'
                                    >
                                        {authSelector?.loading ?
                                            <>
                                                <div className="row">
                                                    <div className="col-sm-12 text-center pt-5 pb-5">
                                                        <div className="spinner-grow text-success" role="status"></div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <InfiniteScroll
                                                    dataLength={allPost?.length} // Total length of the data
                                                    next={() => fetchMoreData()} // Callback function for loading more data
                                                    hasMore={true} // Set to false when there is no more data
                                                    // loader={<h4>Loading...</h4>} // Loading indicator
                                                    // endMessage={<p>No more data to load.</p>}
                                                    className="overflow-hidden"
                                                >
                                                    {allPost?.length > 0 ?
                                                        <>
                                                            {allPost?.map((post, index) => {
                                                                return (
                                                                    <div ref={(el) => (postDivRefs.current[index] = el)} className={`postContainerArea ${id && id == post.id ? `background-selected ${showNewBackground ? 'background-new' : ''}` : " "}`} key={index}  >
                                                                        <div className='posts justify-content-between'>

                                                                            <div className="posts">
                                                                                {userDeatil?.user_id == post?.user_id ?
                                                                                    <div>
                                                                                        <img onClick={() => handlePageRedirectMyProfile(post?.user_details?.user_company_information?.company_name)} src={post?.user_details?.user_profile?.profile_photo ? post?.user_details?.user_profile?.profile_photo : Images.puzzleProfile} className='postUser me-3' alt='UserImage' />
                                                                                    </div>
                                                                                    :
                                                                                    <div>
                                                                                        <img onClick={() => handlePageRedirect(post?.user_details?.user_company_information?.company_name, post?.user_details?.user_company_information?.id)} src={post?.user_details?.user_profile?.profile_photo ? post?.user_details?.user_profile?.profile_photo : Images.puzzleProfile} className='postUser me-3' alt='UserImage' />
                                                                                    </div>
                                                                                }
                                                                                <div className='postsDetails'>
                                                                                    {/* <p className='innerSubtext m-0'><strong>{post?.user_details?.user_company_information?.company_name}</strong></p> */}
                                                                                    {userDeatil?.user_id == post?.user_id ?
                                                                                        <>
                                                                                            <h5 onClick={() => handlePageRedirectMyProfile(post?.user_details?.user_company_information?.company_name)} className='headingTitleSmall_ d-flex align-items-center justify-content-between'>
                                                                                                <span className="memberName">{post?.user_details?.user_profile?.first_name} {post?.user_details?.user_profile?.last_name}</span>
                                                                                            </h5>
                                                                                            <p className='developerAcc mb-0' >{post?.user_details?.user_company_information?.company_name}</p>
                                                                                        </> :
                                                                                        <>
                                                                                            <h5 onClick={() => handlePageRedirect(post?.user_details?.user_company_information?.company_name, post?.user_details?.user_company_information?.id)} className='headingTitleSmall_ d-flex align-items-center justify-content-between mb-0'>
                                                                                                <span className="memberName">{post?.user_details?.user_profile?.first_name} {post?.user_details?.user_profile?.last_name}</span> </h5>
                                                                                            <p className='developerAcc mb-0'>{post?.user_details?.user_company_information?.company_name}</p>
                                                                                        </>

                                                                                    }

                                                                                    <span className='weeksTxt'>{moment(post?.created_at).fromNow()}</span>
                                                                                </div>
                                                                            </div>

                                                                            <div className="postOptionBox">
                                                                                <div className="dropdown">
                                                                                    <p className="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                        <i className="las la-ellipsis-h moreOptionIcon"></i>
                                                                                    </p>
                                                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                                        <li onClick={(e) => {
                                                                                            if (post && post?.feature_post) {
                                                                                                postFetures(post?.id, (post?.feature_post?.length > 0 ? 2 : 1), index);
                                                                                            }
                                                                                        }}>
                                                                                            <a className="dropdown-item">
                                                                                                {post && post?.feature_post && post?.feature_post?.length > 0 ?
                                                                                                    "Remove from Featured" : "Featured on profile"
                                                                                                }
                                                                                            </a>
                                                                                        </li>
                                                                                        <li onClick={() => copyLinkToClipboard(post?.id)}><a className="dropdown-item">Copy link to post</a></li>
                                                                                        {userDeatil?.user_id == post?.user_id &&
                                                                                            <>
                                                                                                <li onClick={() => {
                                                                                                    setEditId(post?.id)
                                                                                                    editPostAction(post?.id)
                                                                                                    setModalDetail({ show: true, flag: "AddANewPost" });
                                                                                                    setKey(Math.random());
                                                                                                }}

                                                                                                ><a className="dropdown-item">Edit post</a></li>

                                                                                                <li onClick={(e) => { deletePostAction(post?.id, index) }} >
                                                                                                    <a className="dropdown-item">Delete post</a>
                                                                                                </li>

                                                                                                <li onClick={(e) => { disableEnableCommentAction(post?.id, post?.disable_comment == 1 ? 2 : 1, index) }} >
                                                                                                    <a className="dropdown-item">
                                                                                                        {post?.disable_comment == 1 ?

                                                                                                            "Enable comments on this post"
                                                                                                            :

                                                                                                            "Disable comments on this post"
                                                                                                        }
                                                                                                    </a>
                                                                                                </li>

                                                                                                <li><Link to="/settings" className="dropdown-item">who can see this  post ?</Link></li>
                                                                                            </>
                                                                                        }
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {post?.post?.startsWith('http') || post?.post?.startsWith('https') ?
                                                                            <>
                                                                                <a className="linkIndex_" href={post?.post} target="_blank" rel="noopener noreferrer" key={key}>
                                                                                    {post?.post}
                                                                                </a>
                                                                            </>

                                                                            :
                                                                            <>
                                                                                {/* <p className='innerSubtextSmall text-break text-start'>{post?.post.includes('http') ? post?.post.split('\n') : post?.post}</p>
                                                                                            <br /> */}
                                                                                <p className='innerSubtextSmall text-break text-start mt-2' style={{ fontSize: '15px' }}>
                                                                                    {post?.post && (
                                                                                        <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                                                                            <a target="_blank" href={decoratedHref} key={key}>
                                                                                                {decoratedText}
                                                                                            </a>
                                                                                        )}>
                                                                                            {post?.post.split('\n').map((line, index) => (
                                                                                                <React.Fragment key={index}>
                                                                                                    {line}
                                                                                                    <br />
                                                                                                </React.Fragment>
                                                                                            ))}
                                                                                        </Linkify>
                                                                                    )}
                                                                                </p>
                                                                            </>
                                                                        }
                                                                        <div className="row postTagsList">
                                                                            {post?.tag_company_details?.map((val, index) => {
                                                                                return (
                                                                                    <div className="tag col-md-3">
                                                                                        <div>
                                                                                            <span>{val?.company_information?.company_name}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                            {post?.tag_user_details?.map((val, index) => {
                                                                                return (
                                                                                    <div className="tag col-md-3">
                                                                                        <div>
                                                                                            <span>{val?.user_details?.user_profile?.first_name + " " + val?.user_details?.user_profile?.last_name}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                        {
                                                                            post?.attachment_type == 2 ?
                                                                                <>
                                                                                    <div class="uploadProfile_ uploadProfile__">
                                                                                        <i class="fas fa-file-pdf ms-start me-3"></i>
                                                                                        <div className="d-flex justify-content-between align-items-center w-100">
                                                                                            <div class="profileDetail_">
                                                                                                <h5 class="profileboldHead">{post?.attachment_name}</h5>
                                                                                            </div>
                                                                                            <a href={post?.attachment} target="_blank">
                                                                                                <i class="fas fa-eye"></i>
                                                                                            </a>
                                                                                        </div>
                                                                                        {/* <a href={post?.attachment} target="_blank" className='me-3 text-center'>View</a> */}
                                                                                    </div>
                                                                                </>
                                                                                : ""
                                                                        }
                                                                        {post?.attachment_type == 3 ?
                                                                            <>
                                                                                {post?.isvideo == true ?
                                                                                    <video controls autoPlay>
                                                                                        <source src={post?.attachment} type="video/mp4" className='soundbiteImg' />
                                                                                    </video>
                                                                                    :
                                                                                    <div className="postBox mb-3">
                                                                                        <img className="imagepost_" src={post?.thumbnail_url} />
                                                                                        <img onClick={() => handleVideoPlay(post, index)} className="videoIcon" src={Images.VideoIcon} />
                                                                                    </div>
                                                                                }
                                                                            </>
                                                                            : post?.attachment == null ?
                                                                                ""
                                                                                : ""
                                                                        }
                                                                        {
                                                                            post?.attachment_type == 1 ?
                                                                                <div className="postImage_ mt-3 mb-3" >
                                                                                    <img className="imagepost_" src={post?.attachment} />
                                                                                </div> : ""
                                                                        }

                                                                        <div className='bottomPostSec d-flex align-items-center justify-content-end my-3'>
                                                                            <p className="postrate_">
                                                                                <span onClick={() => totalUserLikeList(post.id,)} >{post?.likeCount}</span>
                                                                                {post?.is_like?.like_status && post?.is_like?.like_status == 1 ? <img className='likepost_ me-2' src={Images.thumbsup} onClick={(e) => { postLike(post.id, 2, index) }} />
                                                                                    : <img className='likepost_ me-2' src={Images.greyLike} onClick={(e) => { postLike(post.id, 1, index) }} />
                                                                                }
                                                                            </p>
                                                                            {post?.disable_comment == 2 && post?.total_comments > 0 ?
                                                                                <p className="postrate_"><span >{post?.total_comments}</span>

                                                                                    <img
                                                                                        src={Images.message}
                                                                                        onClick={() => handleLoadMoreComments(post?.id, index)}
                                                                                        className='messageiconn me-2' alt='Message' />
                                                                                </p>
                                                                                : <p className="postrate_"><span >{post?.total_comments}</span>
                                                                                    <img
                                                                                        src={Images.commentGrey}
                                                                                        className='messageiconn me-2' alt='Message' />
                                                                                </p>
                                                                            }

                                                                            {userDeatil?.user_id == post?.user_id &&
                                                                                <p className="postrate_"  >
                                                                                    {post && post?.feature_post?.length > 0 ?
                                                                                        <img src={Images.star} onClick={(e) => { postFetures(post?.id, 2, index) }} className='messageiconn' alt='Message' />
                                                                                        : <img src={Images.StartUnfilled} onClick={(e) => { postFetures(post?.id, 1, index) }} className='messageiconn' alt='Message' />
                                                                                    }
                                                                                </p>
                                                                            }
                                                                        </div>
                                                                        {post && post?.post_comments[0] ?
                                                                            <>
                                                                                <div className="row">
                                                                                    <div className="col-lg-1 col-md-1 p-0">
                                                                                        <img src={Images.commentReply} className='newChatReplyIcon img-fluid me-1' alt='UserImage' />
                                                                                    </div>
                                                                                    <div className="col-lg-11 col-md-11 ps-0">
                                                                                        <div className="listComments commentreply">
                                                                                            <div className='comments mt-2 '>

                                                                                                <div className='commentReply newCommentReply'>
                                                                                                    <figure>
                                                                                                        {
                                                                                                            userDeatil?.user_id == post?.post_comments[0]?.user_id ?
                                                                                                                <img onClick={() => handlePageRedirectMyProfile(post?.post_comments[0]?.user_details?.user_company_information?.company_name)} src={post?.post_comments[0]?.user_details?.user_profile?.profile_photo ? post?.post_comments[0]?.user_details?.user_profile?.profile_photo : Images.puzzleProfile} className='chatPosttedUser me-3' alt='UserImage' />
                                                                                                                :
                                                                                                                <img onClick={() => handlePageRedirect(post?.post_comments[0]?.user_details?.user_company_information?.company_name, post?.post_comments[0]?.user_details?.user_company_information?.id)} src={post?.post_comments[0]?.user_details?.user_profile?.profile_photo ? post?.post_comments[0]?.user_details?.user_profile?.profile_photo : Images.puzzleProfile} className='chatPosttedUser me-3' alt='UserImage' />
                                                                                                        }

                                                                                                    </figure>
                                                                                                    <div>
                                                                                                        {
                                                                                                            userDeatil?.user_id == post?.post_comments[0]?.user_id ?
                                                                                                                <p className="innerSubtextSmall replyPerson" onClick={() => handlePageRedirectMyProfile(post?.post_comments[0]?.user_details?.user_company_information?.company_name)}><b>{post?.post_comments[0]?.user_details?.user_profile?.first_name} {post?.post_comments[0]?.user_details?.user_profile?.last_name}</b></p>
                                                                                                                :
                                                                                                                <p className="innerSubtextSmall replyPerson" onClick={() => handlePageRedirect(post?.post_comments[0]?.user_details?.user_company_information?.company_name, post?.post_comments[0]?.user_details?.user_company_information?.id)}><b>{post?.post_comments[0]?.user_details?.user_profile?.first_name} {post?.post_comments[0]?.user_details?.user_profile?.last_name}</b></p>
                                                                                                        }

                                                                                                        <h5 className='headingTitleSmall_ '>
                                                                                                            <span className='commentText mb-2 replyTime'>{moment(post?.post_comments[0]?.created_at).fromNow()}</span>
                                                                                                        </h5>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <p className="innerSubtextSmall mt-2">{post?.post_comments[0]?.comment}</p>
                                                                                            </div>
                                                                                            <div className='commentReply justify-content-between my-3'>
                                                                                                <figure>
                                                                                                    <p className="postrate_ justify-content-end">
                                                                                                        {post?.post_comments[0]?.is_comment_liked?.status && post?.post_comments[0]?.is_comment_liked?.status == 1 ?
                                                                                                            <img className='likepost_ me-2' src={Images.thumbsup} onClick={(e) => commentLikeAction(post?.post_comments[0].id, 2, index, "", "postindexcomment")} />
                                                                                                            : <img className='likepost_ me-2' src={Images.greyLike} onClick={(e) => commentLikeAction(post?.post_comments[0].id, 1, index, "", "postindexcomment")} />
                                                                                                        }

                                                                                                        {/* {post?.is_like?.like_status && post?.is_like?.like_status == 1 ? <img className='likepost_ me-2' src={Images.thumbsup} onClick={(e) => { postLike(post.id, 2, index) }} />
                                                                                                            : <img className='likepost_ me-2' src={Images.greyLike} onClick={(e) => { postLike(post.id, 1, index) }} />
                                                                                                        } */}
                                                                                                        <span>{post?.post_comments[0].CommentLikeCount ?? 0}</span>
                                                                                                    </p>
                                                                                                </figure>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </> : ""
                                                                        }

                                                                        {/* {allPost && allPost[0]?.post_comments == "" ?
                                                                            "" : */}
                                                                        <div className="row">
                                                                            {postIndexMoreComments === index ?
                                                                                <>
                                                                                    <div className="dashboardComments" id="dashboardComments">
                                                                                        {allCommentList && allCommentList?.length > 0 ?
                                                                                            <>
                                                                                                {allCommentList.map((allComment, commentindex) => {
                                                                                                    return (
                                                                                                        <>
                                                                                                            {commentindex === 0 ? ""
                                                                                                                //  postIndexMoreComments != 0 && index != 0  ?
                                                                                                                :
                                                                                                                <div className="row">

                                                                                                                    <div className="col-lg-1 col-md-1 p-0">
                                                                                                                        <img src={Images.commentReply} className='newChatReplyIcon img-fluid me-1' alt='UserImage' />
                                                                                                                    </div>
                                                                                                                    <div className="col-lg-11 col-md-11 ps-0">
                                                                                                                        <div className="listComments commentreply">
                                                                                                                            <div className='comments mt-2 '>

                                                                                                                                <div className='commentReply newCommentReply'>
                                                                                                                                    <figure>
                                                                                                                                        {

                                                                                                                                            userDeatil?.user_id == allComment?.user_id ?
                                                                                                                                                <img
                                                                                                                                                    onClick={() => handlePageRedirectMyProfile(allComment?.user_details?.user_company_information?.company_name)}
                                                                                                                                                    src={allComment?.user_details?.user_profile?.profile_photo ? allComment?.user_details?.user_profile?.profile_photo : Images.puzzleProfile} className='chatPosttedUser me-3' alt='UserImage'
                                                                                                                                                /> :
                                                                                                                                                <img
                                                                                                                                                    onClick={() => handlePageRedirect(allComment?.user_details?.user_company_information?.company_name, allComment?.user_details?.user_company_information?.id)}
                                                                                                                                                    src={allComment?.user_details?.user_profile?.profile_photo ? allComment?.user_details?.user_profile?.profile_photo : Images.puzzleProfile} className='chatPosttedUser me-3' alt='UserImage'
                                                                                                                                                />

                                                                                                                                        }
                                                                                                                                        {/* <img src={allComment?.user_details?.user_profile?.profile_photo ? allComment?.user_details?.user_profile?.profile_photo : Images.puzzleProfile} className='chatPosttedUser me-3' alt='UserImage' /> */}
                                                                                                                                    </figure>
                                                                                                                                    <div>
                                                                                                                                        {
                                                                                                                                            userDeatil?.user_id == allComment?.user_id ?
                                                                                                                                                <p className="innerSubtextSmall replyPerson" onClick={() => handlePageRedirectMyProfile(allComment?.user_details?.user_company_information?.company_name)}><b>{allComment?.user_details?.user_profile?.first_name} {allComment?.user_details?.user_profile?.last_name}</b></p>
                                                                                                                                                : <p className="innerSubtextSmall replyPerson" onClick={() => handlePageRedirect(allComment?.user_details?.user_company_information?.company_name, post?.user_details?.user_company_information?.id)}><b>{allComment?.user_details?.user_profile?.first_name} {allComment?.user_details?.user_profile?.last_name}</b></p>}
                                                                                                                                        <h5 className='headingTitleSmall_ '>
                                                                                                                                            <span className='commentText mb-2 replyTime'>{moment(allComment?.created_at).fromNow()}</span>
                                                                                                                                        </h5>
                                                                                                                                    </div>
                                                                                                                                </div>

                                                                                                                                <p className="innerSubtextSmall mt-2">{allComment?.comment}</p>


                                                                                                                            </div>
                                                                                                                            <div className='commentReply justify-content-between my-3'>

                                                                                                                                <figure>
                                                                                                                                    <p className="postrate_ justify-content-end">
                                                                                                                                        {allComment?.is_comment_liked ?
                                                                                                                                            <img className='likepost_ me-2' src={Images.thumbsup} onClick={(e) => { commentLikeAction(allComment?.id, 2, commentindex, "", "") }} />
                                                                                                                                            : <img className='likepost_ me-2' src={Images.greyLike} onClick={(e) => { commentLikeAction(allComment?.id, 1, commentindex, "", "") }} />
                                                                                                                                        }
                                                                                                                                        <span>{allComment?.total_like_count ? allComment?.total_like_count : allComment?.CommentLikeCount ? allComment?.CommentLikeCount : 0}</span>
                                                                                                                                    </p>
                                                                                                                                </figure>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            }
                                                                                                        </>
                                                                                                    )
                                                                                                })}
                                                                                            </>
                                                                                            : ""
                                                                                            // <div className="row">
                                                                                            //     <div className="col-lg-12 text-center">
                                                                                            //         <h6 className="mt-4">No Comments found</h6>
                                                                                            //     </div>
                                                                                            // </div>
                                                                                        }
                                                                                        {allCommentList?.length > 1 && allCommentData.total > allCommentList?.length &&
                                                                                            <div className="text-center" onClick={() => { handleLoadMoreComments(post?.id, postIndexMoreComments, page + 1) }} >
                                                                                                <i class="las la-angle-double-down loadMore"></i>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </> : ""}
                                                                            {
                                                                                post?.disable_comment == 1 ? "" :
                                                                                    <div className="footerComment">
                                                                                        <div className="commentBox_ px-0" >
                                                                                            <div className="messageTextarea">
                                                                                                <img className="img-fluid postUser" src={userInfo?.user_profile?.profile_photo ? userInfo?.user_profile?.profile_photo : Images.puzzleProfile} />
                                                                                                <div className="form-group">
                                                                                                    {replyFlag &&
                                                                                                        <div className="d-flex justify-content-between">
                                                                                                            <h6>
                                                                                                                Reply to {replyName}
                                                                                                            </h6>

                                                                                                            <i className="fa fa-times mt-0 cursor-pointer" onClick={() => {
                                                                                                                setReplyId(null)
                                                                                                                setReplyName('')
                                                                                                                setReplyFlag(false)
                                                                                                                setSelectedIndex(null)
                                                                                                            }}></i>
                                                                                                        </div>
                                                                                                    }
                                                                                                    <textarea type="text" className="customFormControl" value={post?.id === postID ? comment : ""} placeholder="Have something to say..." id="inputName" name="post" key={index}
                                                                                                        onChange={(e) => { setComment(e.target.value); setPostID(post?.id) }} />
                                                                                                    <img className="sendIcon_" src={Images.sendIcon_} onClick={() => { postComment(post?.id, index) }} />
                                                                                                </div>
                                                                                                <img className="sendIcon_" src={Images.sendIcon_} onClick={() => { postComment(post?.id, index, "comment") }} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                            }

                                                                        </div>

                                                                        {/* }  */}


                                                                    </div>
                                                                )
                                                            })
                                                            }
                                                        </>
                                                        : "No Post Found"
                                                    }
                                                </InfiniteScroll>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4'>
                        <aside className='dashboardRightArea'>
                            {choosePlan === "Bronze" ?
                                "" :
                                <>
                                    <h3 className="mainHeading">
                                        Resources
                                    </h3>
                                    <div className='lookSuplier'>
                                        <div className='cardBox'>
                                            <div className="textBox">
                                                <h6>Business<b> Resources</b></h6>
                                                <p>Building, sustaining and growing a business is a never-ending journey that involves continuos infusions of knowledge and wisdom.</p>
                                                <button onClick={() => navigate("/resources")} className='primaryBtnSmall' >Start Learning</button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }


                            <div className='currentTop mt-5'>
                                <h4 className='headingTitle_'>Opportunity Responses</h4>
                                <button className="primaryBtnSmall bgBlue" onClick={() => {
                                    handleChangePage("allOpportunities")
                                }}>View More</button>
                            </div>

                            <div className='containerBoxright userOppertunities mt-3'>
                                {authSelector?.loading ?
                                    <>
                                        <div className="row">
                                            <div className="col-sm-12 text-center pt-5 pb-5">
                                                <div className="spinner-grow text-success" role="status"></div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className='deviserCompany_'>
                                            {reviewResponses?.length > 0 ?
                                                <>
                                                    {reviewResponses?.slice(0, 4)?.map((val, index) => {
                                                        return (

                                                            <div className='certificationContainerBox_'>
                                                                <div className="certificateConInner">
                                                                    <img className="opportunitiesBox" onClick={() => {
                                                                        handleSubmitedViewProfile(
                                                                            val?.user_company_information?.id,
                                                                            val?.user_company_information?.company_name
                                                                        )
                                                                    }} 
                                                                    src={val?.user_company_information?.company_logo ? val?.user_company_information?.company_logo : Images.puzzleProfile} alt="suplierImg" 
                                                                    
                                                                    // onClick={() => handleNavigationActiveInactive(val?.is_active)}
                                                                    
                                                                    />
                                                                    <div className="opportunitiesHead w-100 pe-2">
                                                                        <h5 className="postopportunity_label mb-1" onClick={() => handleNavigationActiveInactive(val?.is_active)}>
                                                                            {truncateText(val?.post_opportunity_details?.posting_title, 38)}
                                                                            {val?.post_opportunity_details?.posting_title?.length > 38 && (
                                                                                <span className="read-more "
                                                                                    onClick={() => handleChangePage("viewResponse", val?.post_opportunity_details?.id)}
                                                                                >Read More</span>
                                                                            )}
                                                                        </h5>
                                                                        <h4 className="mb-3">{val?.user_company_information?.company_name}</h4>
                                                                        <span className="uploadText">{moment(val?.post_opportunity_details?.created_at).fromNow()}</span>
                                                                    </div>
                                                                    <div>
                                                                        <div className="opportunityViewImg" onClick={() => handleChangePage("viewResponse", val?.post_opportunity_details?.id)}>
                                                                            <img src={Images.opportunityViewImg} alt="logo" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )

                                                    })
                                                    }
                                                </>
                                                : "No Opportunity Found"
                                            }
                                        </div>
                                    </>

                                }
                            </div>

                            <div className='cardContainer mt-4 d-flex'>
                                <div className='cardImg commonCartImg'>
                                    <img src={Images.bannerendsor} className='img-fluid' alt='CardImg' />
                                    <div className='cardBodyArea'>
                                        <h5>Acclamations</h5>
                                        <p className='my-5'>Elevate Connections and Inspire Growth with OPPSWell Acclamations. </p>
                                        <div className="infoiconText m-0">
                                            <button onClick={() => { handleEndorsements() }} className='primaryBtn' >Learn More</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </aside>
                    </div>
                </div>
            </div >
            <CustomModal
                key={key}
                flag={modalDetail.flag}
                showCustomBackdrop={modalDetail.flag === "CommentBox" ? true : false}
                show={modalDetail.show}
                backdrop={modalDetail.flag === "CommentBox" ? null : "static"}
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                className={modalDetail.flag === "CommentBox" ? "commonWidth customContent" : ""}
                ids={modalDetail.flag === "AddANewPost" ? "addNewPost" : modalDetail.flag === "CommentBox" ? "commentBox" : modalDetail.flag === "CompanyLogo" ? "CompanyLogo" : modalDetail.flag === "allPostLikeUser" ? "allPostLikeUser" : modalDetail.flag === "addTagToPost" ? "addTagToPost" : modalDetail.flag === "Endorsements" ? "Endorsements" : ""}
                child={
                    modalDetail.flag === "CommentBox" ? (
                        <CommmentBox
                            id={modalDetail.id}
                            handleVideoPlay={() => handleVideoPlay()}
                            close={() => handleOnCloseModal()} />
                    ) : modalDetail.flag === "CompanyLogo" ?
                        (
                            <CompanyLogo
                                close={() => handleOnCloseModal()}
                                userDetail={userDeatil} />
                        )
                        :
                        modalDetail.flag == "allPostLikeUser" ?
                            <AllPostLikeUser
                                id={modalDetail.id}
                                close={() => handleOnCloseModal()} />
                            : modalDetail.flag == "addTagToPost" ?
                                <AddTagToPost
                                    id={modalDetail.id}
                                    close={() => handleOnCloseModal()}
                                    updateTagData={(data, ids) => updateTagData(data, ids)}
                                    selectedCompanies={mainTags.companies?.length > 0 ? mainTags.companies : []}
                                    selectedUsers={mainTags.users?.length > 0 ? mainTags.users : []}
                                /> : modalDetail.flag == "Endorsements" ?
                                    <EndorsementsText
                                        close={() => handleOnCloseModal()} />

                                    : modalDetail.flag == "AddANewPost" ?
                                        <AddNewPost
                                            // closeAllCommentsMore={setAllCommentsLoad(true)}
                                            close={() => handleOnCloseModal()}
                                            selectedCompanies={mainTags.companies?.length > 0 ? mainTags.companies : []}
                                            selectedUsers={mainTags.users?.length > 0 ? mainTags.users : []}
                                            updateTagData={(data, ids) => updateTagData(data, ids)}
                                            setAllPostList={setAllPostList}
                                            selectedIds={selectedIds}
                                            allPost={allPost}
                                            editId={editId}
                                            setEditId={setEditId}
                                            userDeatil={userDeatil}
                                            editFlag={editFlag}
                                            setEditFlag={setEditFlag}
                                        />
                                        : ""
                }
                header={
                    modalDetail.flag === "CommentBox" ?
                        <h2 className="headingSmall_">
                            Comments
                        </h2>
                        :
                        <div className='modalHeader_'>
                            <div className="common_">
                                {modalDetail.flag === "CompanyLogo" ?
                                    <h2 className="headingSmall_">
                                        Company Logo
                                    </h2>
                                    : modalDetail.flag == "allPostLikeUser" ?
                                        <h2 className="headingSmall_">
                                            Post Like List
                                        </h2>
                                        : modalDetail.flag == "addTagToPost" ?
                                            <h2 className="headingSmall_">
                                                {modalDetail.header}
                                            </h2>
                                            : modalDetail.flag == "AddANewPost" ?
                                                <>
                                                    <h2 className="headingSmall_">
                                                        Post a Message
                                                    </h2>
                                                    <button onClick={() => handleOnCloseModal()}><img src={Images.modalCross} alt="" className="cursor" type="button" /></button>
                                                </>
                                                :
                                                ""}
                            </div>
                        </div>
                }



                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

export default AccountDashboard