import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import * as Images from "../../../utilities/images";
import ActiveOpportunity from '../activeOpportunity';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import RecentlyDelete from '../recentlyDeleteOpportunity';
import AddPostOpportunity from '../addPostOpportunity';
import EditPostOpportunity from '../editPostOpportunity';
import ReviewApplications from '../ReviewApplications';
import Archive from '../archiveOpportunity';
import InActiveOpportunity from "../inActiveOpportunity";
import ReviewResponses from "../ReviewApplications/reviewResponses";
import ReviewRespondents from "../ReviewApplications/viewRespondents";
import { useAuthSelector } from "../../../redux/selector/auth";
import { getCompanyProfileInfo } from "../../../redux/slices/auth";

const Opportunities = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const tab = location?.state?.activeTab;
    const id = location?.state?.oppId;
    const [activeTab, setActiveTab] = useState(tab === "activeOpportunity" ? "activeOpportunity" : tab === "inactiveOpportunity" ? "inactiveOpportunity" : tab === "reviewapplication" ? "reviewapplication" : tab === "ReviewRespondents" ? "ReviewRespondents" : 'addPostOpportunity');
    const [editOpportunitiesId, setEditOpportunitiesId] = useState('');
  
 
    // handle tab chnage
    const handleTabChange = (tabIndex) => {
        setActiveTab(tabIndex);
        // Save the active tab to localStorage
        localStorage.setItem("selectedOpportunity", tabIndex);
    };

    // this code use for select url according to button tab
    const handleButtonClick = (buttonName) => {
        // Construct the new URL without causing a full page reload
        const currentUrl = window.location.href;
        if (buttonName === "") {
            const newUrl = `/create-opportunities`;
            window.history.pushState({}, '', newUrl);
        }
        else {
            const newUrl = `/create-opportunities/${buttonName}`;
            window.history.pushState({}, '', newUrl);
        }
        // Change the URL using pushState
    };

    const responceTab = (flag) => {
        setActiveTab(flag)
    }

    useEffect(() => {
        document.title = "opportunities";
    }, []);



    useEffect(() => {
        // Retrieve the active tab from localStorage on component mount
        const savedActiveTab = localStorage.getItem("selectedOpportunity");
        setActiveTab(savedActiveTab === null ? 'addPostOpportunity' : savedActiveTab !== "" ? savedActiveTab : tab !== "" ? tab : savedActiveTab === "" ? "addPostOpportunity" : 'addPostOpportunity');
    }, []);

    return (
        <>
            <div className='opportunityMain_'>
                <div className='row'>
                    <div className='col-lg-3 col-md-12'>
                        <div className='containerBoxleft'>
                            <button className="subsmallBtnblue_ mb-4"
                                onClick={() => {
                                    handleButtonClick('')
                                    handleTabChange("addPostOpportunity")
                                }}
                            >Post Opportunities</button>
                            <ul className='oppotunityMenu'>
                                <li
                                    onClick={() => {
                                        handleButtonClick('active-opportunity')
                                        handleTabChange("activeOpportunity")
                                    }}
                                    className='settingItems'>
                                    <Link to='' className={`${activeTab === 'activeOpportunity' ? 'settingLinks active' : ''}settingLinks`}>
                                        <img src={Images.newActiveOpp} alt='activeOpportunity' />
                                        <span>Active Opportunities</span>
                                    </Link>
                                </li>
                                <hr className='topBorder' />
                                <li
                                    onClick={() => {
                                        handleButtonClick('inactive-opportunity')
                                        handleTabChange("inactiveOpportunity")
                                    }}
                                    className='settingItems'>
                                    <Link to='' className={`${activeTab === 'inactiveOpportunity' ? 'settingLinks active' : ''}settingLinks`}>
                                        <img src={Images.newInactive} alt='inactiveOpportunity' />
                                        <span>Inactive Opportunities</span>
                                    </Link>
                                </li>
                                <hr className='topBorder' />
                                <li
                                    onClick={() => {
                                        handleButtonClick('archived')
                                        handleTabChange("archived")
                                    }}
                                    className='settingItems'>
                                    <Link to='' className={`${activeTab === 'archived' ? 'settingLinks active' : ''}settingLinks`}>
                                        <img src={Images.archived} alt='archived' />
                                        <span>Archived</span>
                                    </Link>
                                </li>
                                <hr className='topBorder' />
                                <li
                                    onClick={() => {
                                        handleButtonClick('review-responses')
                                        handleTabChange("reviewapplication")
                                    }}
                                    className='settingItems'>
                                    <Link to='' className={`${activeTab === 'reviewapplication' ? 'settingLinks active' : ''}settingLinks`}>
                                        <img src={Images.reviewicon} alt='reviewapplication' />
                                        <span>Review Responses</span>
                                    </Link>
                                </li>
                                <hr className='topBorder' />
                                <li
                                    onClick={() => {
                                        handleButtonClick('recently-delete')
                                        handleTabChange("recentlyDelete")
                                    }}
                                    className='settingItems'>
                                    <Link to='' className={`${activeTab === 'recentlyDelete' ? 'settingLinks active' : ''}settingLinks`}>
                                        <img src={Images.deletedicon} alt='recentlyDelete' />
                                        <span>Recently Deleted</span>
                                    </Link>
                                </li>
                                <hr className='topBorder' />
                            </ul>
                        </div>
                        <div className='cardContainer mt-4'>
                            <div className='cardImg'>
                                <img src={Images.certifyUser} className='img-fluid' alt='CardImg' />
                            </div>
                            <div className='cardBodyArea'>
                                <h5>Get Certified</h5>
                                <div className="infoiconText">
                                    <span className="showinfoText">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span>
                                </div>
                                <p className='my-4'>OPPSWell Diversity Certifications for inclusive business futures. </p>
                                <div className="infoiconText m-0">
                                    <Link to="/certificate">
                                        <button className='primaryBtn'>Learn More</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-9 col-md-12'>
                        <div className='containerBox'>
                            {activeTab == 'activeOpportunity' ?
                                <ActiveOpportunity
                                    setEditOpportunitiesId={(id) => setEditOpportunitiesId(id)}
                                    setActiveTab={() => setActiveTab("editOpportunity")}
                                    setResponceTab={(flag) => responceTab(flag)}
                                />
                                : activeTab == 'archived' ?
                                    <Archive /> :
                                    activeTab == "recentlyDelete" ?
                                        <RecentlyDelete setActiveTab={() => setActiveTab("activeOpportunity")} /> :
                                        activeTab == "addPostOpportunity" ?
                                            <AddPostOpportunity
                                                setActiveTab={() => setActiveTab("activeOpportunity")}
                                            /> :
                                            activeTab == "reviewapplication" ?
                                                <ReviewResponses
                                                    setActiveTab={() => setActiveTab("ReviewRespondents")}
                                                />
                                                :
                                                activeTab == "editOpportunity" ?
                                                    <EditPostOpportunity
                                                        setActiveTab={() => setActiveTab("activeOpportunity")}
                                                        id={editOpportunitiesId} /> :
                                                    activeTab == "inactiveOpportunity" ?
                                                        <InActiveOpportunity /> :
                                                        activeTab === "ReviewRespondents" ?
                                                            <ReviewRespondents
                                                                setActiveTab={() => setActiveTab("reviewapplication")}
                                                                opportunityId={id} />
                                                            : ""
                            }

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Opportunities