import React, { useState } from 'react'
import * as Images from "../../../utilities/images";
import { toast } from "react-toastify"
import moment from 'moment';
import { messageRequest } from '../../../redux/slices/messages';
import { useDispatch } from 'react-redux';
const ResponseModal = (props) => {
    const [message, setMessage] = useState("")
    const dispatch = useDispatch()
    const [selectedCompanyName, setSelectedCompanyName] = useState("")
    const [subject, setSubject] = useState("")
    const [selectedCompanyId, setSelectedCompanyId] = useState(props?.details?.user_details?.user_company_information?.id)
    const [senderId, setSenderId] = useState(props?.details?.user_id)
    const [userName, setUserName] = useState(props?.details?.user_details?.user_profile)
    const data = props?.details?.user_details?.user_company_information

    const sendMessage = (e) => {
        e.preventDefault();

        if (!subject) {
            toast.error("Please enter subject");
            return;
        }
        else if (!message) {
            toast.error("Please enter message");
            return;
        }
        let params = {
            receiver_id: props?.details?.user_id,
            company_id: selectedCompanyId,
            message_type: 1,
            subject: subject,
            message: message,
            chat_type: props?.chatType
        }
        dispatch(messageRequest({
            ...params,
            cb(res) {
                if (res?.data) {
                    props?.close()
                    toast.success("Message sent successfully")
                }
            }
        }))
    }


    return (
        <>
            <div className='respondApplication'>
                <div className='respondAppHeader mb-4'>
                    <div className='respondAppHeading'>
                        <h1 className='mb-0'>Response</h1>
                    </div>
                </div>
                <div className='respondAppMain mb-4'>
                    <div className='chatUserImg me-3'>
                        <img src={data?.company_logo ? data?.company_logo : Images.puzzleProfile} alt="company Image" className='img-fluid' />
                    </div>
                    <div className='respondAppMainCompany'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <span className='responseCompanyName d-block'> {data?.company_name}</span>
                                <h1 className='mb-2'>{userName?.first_name} {userName?.last_name}</h1>
                                {data?.domestic_service_areas ?

                                    <span className='d-block'>
                                        <img src={Images.locationpin} alt='Location' className='me-1' />
                                        <span className='responseLocation'>{data?.domestic_service_areas}</span></span> : ""

                                }

                            </div>

                        </div>


                    </div>

                </div>
                <div className='responseForm'>
                    <div className='row'>
                        <div class="form-group col-md-12 mb-3">
                            <label class="postopportunity_label">Message
                            </label>
                            <input name="labelcustom" type='text' className='customFormControl' placeholder='Subject' value={subject} onChange={e => setSubject(e.target.value)}></input>
                           
                        </div>
                        <div className='col-md-12 mb-3'>
                            <textarea name="description" type="text" class="customFormControl" placeholder="Hello Frankie, thank you for your application for Cal Center Agents.." rows="7" onChange={(e) => setMessage(e.target.value)}>
                            </textarea>
                        </div>
                        <div className='col-md-12'>
                            <div className='respondAppBtns text-end'>
                                <button onClick={(e) => {
                                    setSelectedCompanyName(data?.company_name)
                                    setSelectedCompanyId(data?.id)
                                    setSenderId(senderId?.id)
                                    sendMessage(e)

                                }} className='sendBtn'>Send Messsage</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default ResponseModal