// socketService.js
import io from "socket.io-client";
import BaseUrl from "../../constants/baseUrl";
import store from '../../redux';


const token = localStorage.getItem("authToken");
var socketInstance = null;

const createSocket = () => {
  try {


    const userId = store.getState().auth?.userInfo?.user?.id;    
    

    const socket = io(BaseUrl.SOCKET_URL, {
      path: "/api/v1/connect",
      reconnectionDelay: 1000,
      reconnectionAttempts: 0,
      extraHeaders: { Authorization: token },
        query:  {userId} ,

    });

    // console.log("Socket initialized with parameters:", {
    //   socket,
    //   BaseUrl,
    //   token,
    // });
    return socket;
  } catch (error) {
    console.error("Error initializing socket:", error);
    return null;
  }
};

export const initializeSocket = () => {
  if (socketInstance === null || socketInstance.connected === false) {
    socketInstance = createSocket();
    // console.log("Socket1111", socketInstance);
  } else {
    console.warn("Socket instance already exists.");
  }
  return socketInstance;
};

export const getSocket = () => {
  if (!socketInstance) {
    socketInstance = initializeSocket();
  }
  return socketInstance;
};

export const closeSocket = () => {
  if (socketInstance) {
    try {
      socketInstance.disconnect();
      // console.log(socketInstance)
      socketInstance = null;
      // console.log('Socket closed.');
    } catch (error) {
      // console.error('Error while disconnecting socket:', error);
    }
  } else {
    console.log('Unable to closeSocket: socketInstance is not defined');
  }
};

// Optionally, you can expose the current socketInstance for external use
export { socketInstance };
