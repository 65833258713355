import React, { useState, useEffect } from "react";
import * as Images from "../../../utilities/images";
import { useDispatch } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import auth, {
  getCompanyProfileInfo,
  stepThreeCompanyLogoUplaod,
  companyBannerUplaod,
  companyUpdateLogoBanner,
} from "../../../redux/slices/auth";
import { Link, useNavigate } from "react-router-dom";
import { useAuthSelector } from "../../../redux/selector/auth";
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  allPostComment,
  commentLike,
  deletePost,
  disableEnableComment,
  getPostSingleDetails,
  postFeature,
  userPostComment,
  userPostLike,
} from "../../../redux/slices/dashboard";
import AllPostLikeUser from "../dashboard/allPostLikeUser";
import CustomModal from "../../components/common/shared/CustomModal";
import CommmentBox from "../dashboard/comment";
import swal from "sweetalert";
import AddNewPost from "../dashboard/modals/addNewPost";

const MyCompanyProfile = () => {
  const authSelector = useAuthSelector();

  const [hideEditBtn, setHideEditBtn] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userDeatil, setUserDeatil] = useState();
  const [userAllInformation, setUserAllInformation] = useState();
  const [featurePost, setFeaturePost] = useState();
  const [allPost, setAllPostList] = useState([]);
  const [userCurrentOpportunity, setUserCurrentOpportunity] = useState();
  const [photo, setPhotoUpload] = useState({
    uploadPhoto: "",
    flag: ""
  });
  const [editPostID, setEditPostID] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [videoUrlOriginal, setVideoUrlOriginal] = useState();
  const [userEndorsementGiven, setUserEndorsementGiven] = useState();
  const [post, setPost] = useState("");
  const [userEndorsementReceived, setUserEndorsementReceived] = useState();
  const [photoInfo, setPhotoInfo] = useState();
  const [mainTags, setMainTags] = useState({
    companies: [],
    users: [],
  })
  const [companyLogo, setCompanyLogo] = useState("");
  const [companyBanner, setCompanyBanner] = useState("");
  const [comments, setComments] = useState([]);
  const [postComments, setPostComments] = useState("");
  const [key, setKey] = useState(Math.random());
  const [editId, setEditId] = useState("")
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  //comments section States
  const post_id = modalDetail?.id;
  const [allCommentList, setAllCommentList] = useState([]);
  const [allCommentsLoad, setAllCommentsLoad] = useState(false);
  const [postIndexMoreComments, setPostIndexMoreComments] = useState("");
  const [allCommentData, setAllCommentData] = useState({});
  const [comment, setComment] = useState("");
  const [postID, setPostID] = useState("");
  const [totalPages, setTotalPages] = useState();
  const userInfo = authSelector?.userInfo?.user;
  const [page, setPages] = useState(1);
  const [showNestedReply, setShowNestedReply] = useState(false)
  const [nestedReply, setNestedReply] = useState("")

  const [replyFlag, setReplyFlag] = useState(false)
  const [replyName, setReplyName] = useState('')
  const [replyId, setReplyId] = useState(null)
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [selectedIds, setSelectedIds] = useState({
    companies: [],
    users: [],
  })

  const userSignupSelectedCompanyInformation = authSelector?.userStep;
  const userLoginSelectedCompanyInformation = authSelector?.userStep?.user;
  const userLoginOwnCompanyInformation =
    authSelector?.userStep?.user?.user_company_information;
  const userSigninOwnCompanyInformation =
    authSelector?.userStep?.user_company_information;

  const chooseBusinessType = authSelector?.userStep?.user?.business_type ?? authSelector?.userStep?.business_type;

  const choosePlan = authSelector?.userStep?.user?.membership_details?.name ?? authSelector?.userStep?.membership_details?.name;

  //onchange input
  const handleChange = (e, id) => {
    const value = e.target.value;
    setComments({
      ...comment,
      [id]: value,
    });
  };


  //for COMPANY PROFILE INFORMATION.......
  const companyProfileInfo = () => {
    dispatch(
      getCompanyProfileInfo({
        cb(res) {
          if (res.status) {
            if (res?.data?.payload?.userDetails?.user_company_information !== null) {
              setUserDeatil(res?.data?.payload?.userDetails?.user_company_information)
            }
            else {
              setUserDeatil(res?.data?.payload?.userDetails?.user_selected_company_information?.user_company_information)
            }
            setAllPostList(res?.data?.payload?.feature_post);
            setUserCurrentOpportunity(res?.data?.payload?.companyPostOpportunity?.data);
            setUserEndorsementGiven(res?.data?.payload?.endorsement_given);
            setUserEndorsementReceived(
              res?.data?.payload?.endorsement_received
            );
            setUserAllInformation(res?.data?.payload?.userDetails)
          } else {
          }
        },
      })
    );
  };

  // apply API for uplaod company Logo
  const onChangePicture = (e, flag) => {
    const imageFile = e.target.files[0];
    e.currentTarget.value = null;
    if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
      toast.error("Please select a valid image.");
      return false;
    }
    if (imageFile.size > 25000000) {
      toast.error("File size too large.");
      return false;
    }
    let params = {
      photo: imageFile,
    };
    if (flag == 1) {
      dispatch(
        stepThreeCompanyLogoUplaod({
          ...params,
          cb(res) {
            if (res?.data) {
              setCompanyLogo(res?.data?.payload);
              saveProfileChnage(res?.data?.payload, 1);
            }
          },
        })
      );
    }
    if (flag == 2) {
      dispatch(
        companyBannerUplaod({
          ...params,
          cb(res) {
            if (res?.data) {
              setCompanyBanner(res?.data?.payload);
              saveProfileChnage(res?.data?.payload, 2);
            }
          },
        })
      );
    }
  };

  // apply API for update Logo and Banner
  const saveProfileChnage = (data, flag) => {
    let params;
    if (flag == 1) {
      params = {
        id:
          userSignupSelectedCompanyInformation
            ?.user_selected_company_information?.user_id ||
          userLoginSelectedCompanyInformation?.user_selected_company_information
            ?.user_id ||
          userLoginOwnCompanyInformation?.id ||
          userSigninOwnCompanyInformation?.id,
        company_logo: data?.url,
        company_logo_size: parseInt(data?.size),
        company_logo_name: data?.name,
      };
    } else if (flag == 2) {
      params = {
        id:
          userSignupSelectedCompanyInformation
            ?.user_selected_company_information?.user_id ||
          userLoginSelectedCompanyInformation?.user_selected_company_information
            ?.user_id ||
          userLoginOwnCompanyInformation?.id ||
          userSigninOwnCompanyInformation?.id,
        banner: data?.url,
        banner_name: data?.name,
        banner_size: parseInt(data?.size),
        banner_format: data?.format,
      };
    }
    dispatch(
      companyUpdateLogoBanner({
        ...params,
        cb(res) {
          if (res.status) {
            companyProfileInfo();
          }
        },
      })
    );
  };

  //handle play button icon
  const handleVideoPlay = (post, index) => {
    setAllPostList((prevData) => {
      let data = [...prevData];
      // If you want to update the like status
      const updatedPost = {
        ...data[index],
        isvideo: true,
      };
      data[index] = updatedPost;

      return data;
    });
  };

  // // API for post Feature ..............
  const postFetures = (id, flag, index) => {
    let params = {
      post_id: id,
      feature_status: flag,
    };
    dispatch(
      postFeature({
        ...params,
        cb(res) {
          if (res.status) {
            companyProfileInfo();
          }
        },
      })
    );
  };

  //for post like...............
  const postLike = (id, flag, index) => {
    const params = {
      post_id: id,
      like_status: flag,
    };
    dispatch(
      userPostLike({
        ...params,
        cb(res) {
          if (res.status) {
            setAllPostList((prevData) => {
              let data = [...prevData]
              if (flag === 1) {
                // If you want to update the like status
                const updatedPost = {
                  ...data[index],
                  post_details: {
                    ...data[index].post_details,
                    is_like: res?.data?.payload?.is_like,
                    likeCount: res?.data?.payload?.likeCount
                  },
                  is_like: res?.data?.payload?.is_like,
                  likeCount: res?.data?.payload?.likeCount
                };

                data[index] = updatedPost;
              } else {
                // If you want to reset the like status to null
                const updatedPost = {
                  ...data[index],
                  post_details: {
                    ...data[index].post_details,
                    is_like: res?.data?.payload?.is_like,
                    likeCount: res?.data?.payload?.likeCount
                  },
                  is_like: res?.data?.payload?.is_like,
                  likeCount: res?.data?.payload?.likeCount
                };
                data[index] = updatedPost;
              }
              return data;
            })
          } else {
          }
        },
      })
    );
  };

  // total user like post
  const totalUserLikeList = (id) => {
    setModalDetail({ show: true, flag: "allPostLikeUser", id: id });
    setKey(Math.random());
  };



  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
    companyProfileInfo();
  };


  //for post comment...............
  const postComment = (id, index, flag) => {
    if (comment !== "") {
      if (!comment.length === "") {
        toast.error("Please enter text to comment");
        return;
      }
    }
    const params = {
      post_id: id,
      // comment: comment ? comment : ""
    }
    if (flag === "comment") {
      params.comment = comment
    }

    if (flag === "replyFlag") {
      params.comment_parent_id = replyId
      params.comment = nestedReply
    }
    dispatch(userPostComment({
      ...params, cb(res) {
        if (res.status) {
          if (flag === "replyFlag") {
            setShowNestedReply(false)
            // if (postDivRefs.current[index]) {
            //   postDivRefs.current[index].scrollIntoView({ behavior: 'smooth', block: 'start' });
            // }
            setReplyId("")

            setComment("");
            setPostID("")
            setAllPostList((prevData) => {

              let data = [...prevData];

              if (index >= 0 && index < data?.length) {
                const postToUpdate = data[index];
                // Assuming res?.data?.payload contains the new comment data
                const updatedCommentReplies = [...postToUpdate.post_details.post_comments[index].comment_replies, res?.data?.payload?.comment];

                const updatedPost = {
                  ...postToUpdate,
                  post_comments: [
                    ...postToUpdate.post_comments.slice(0, index), // Keep the comments before the index
                    {
                      ...postToUpdate.post_comments[index],
                      comment_replies: updatedCommentReplies // Update comment_replies at the specified index
                    },
                    ...postToUpdate.post_comments.slice(index + 1) // Keep the comments after the index
                  ]
                };

                data[index] = updatedPost;
              }

              return data;
            });
            setAllCommentList((prevData) => {
              let data = [...prevData]
              data.concat(res?.data?.payload)
              return data;
            })
          }
          else {
            setShowNestedReply(false)
            // if (postDivRefs.current[index]) {
            //   postDivRefs.current[index].scrollIntoView({ behavior: 'smooth', block: 'start' });
            // }
            setReplyId("")

            setComment("");
            setPostID("")
            setAllCommentList((prevData) => ([

              res?.data?.payload,
              ...prevData
            ]))
            setAllPostList((prevData) => {
              let data = [...prevData]

              const updatedPost = {
                ...data[index],
                post_details: {
                  ...data[index].post_details,
                  total_comments: res?.data?.payload?.total_comments,
                },
              };
              data[index] = updatedPost;
              return data;
            })

          }
        }
      }
    }))
  }

  // //post all comments
  const handleLoadMoreComments = (id, index, page = 1, limit = 10) => {
    let params = {
      id: id,
      page: page,
      limit: limit
    };
    dispatch(allPostComment({
      ...params, cb(res) {
        if (res.status) {
          setPages(page);
          setAllCommentsLoad(true);
          setPostIndexMoreComments(index)
          if (page === 1) {
            // For the first page
            if (allCommentList.length === 0) {
              setAllCommentList(res.data.payload?.comments || []);
            } else {
              setAllCommentList((prevData) => {
                // Replace existing comments with new comments
                const updatedComments = res.data.payload?.comments || [];
                return updatedComments;
              });
            }
          } else {
            // For pages other than the first page (e.g., page 2)
            setAllCommentList((prevData) => {
              // Concatenate existing comments with new comments
              const updatedComments = prevData.concat(res.data.payload?.comments || []);
              return updatedComments;
            });
          }

          setAllCommentData(res.data.payload);
        }

      }
    }))
  };

  // handle  page change 
  const handlePageRedirect = (name, id) => {
    if (id) {
      navigate(`/${name}/${id}`)
    }
  }

  const handlePageRedirectMyProfile = (name) => {
    const formattedName = name?.replace(/\s+/g, '-'); // Replace spaces with hyphens
    navigate(`/${formattedName}`)
  }

  // function for navigate the active Opportunities
  const handleActiveOpp = () => {
    navigate('/create-opportunities', { state: { activeTab: "activeOpportunity" } })
    localStorage.setItem("selectedOpportunity", "activeOpportunity")
  }

  const updateTagData = (tagData, idData) => {
    setMainTags(tagData)
    setSelectedIds(idData)
  }

  const commentLikeAction = (id, flag, index, reIndex = null, postindexcomment) => {
    const params = {
      comment_id: id,
      status: flag
    }
    dispatch(commentLike({
      ...params, cb(res) {
        if (res.status) {


          if (reIndex == null) {

            setAllCommentList((prevData) => {
              let data = [...prevData]
              const updatedComment = {
                ...data[index], is_comment_liked: res?.data?.payload?.commentLikeDetails ?? null, total_like_count: res?.data?.payload?.commentDetails?.likeCount ?? 0
              };
              data[index] = updatedComment;

              return data;
            })

          }

          else if (postindexcomment === "postindexcomment") {
            if (flag === 1) {
              // If you want to update the like status
              setAllPostList((prevData) => {
                let data = [...prevData]


                data[index] = res?.data?.payload?.post_details;
                return data;
              })


            } else {
              // If you want to reset the like status to null
              setAllPostList((prevData) => {
                let data = [...prevData]

                data[index] = res?.data?.payload?.post_details;
                return data;
              })
            }

          }
          else {
            if (flag === 1) {
              // If you want to update the like status
              setAllCommentList((prevData) => {
                let data = [...prevData]

                data[index] = res?.data?.payload?.post_details?.post_comments[index];
                return data;
              })


            } else {
              // If you want to reset the like status to null
              setAllCommentList((prevData) => {
                let data = [...prevData]

                data[index] = res?.data?.payload?.post_details?.post_comments[index];
                return data;
              })

            }

          }
        }
        else {

        }


      }
    }))
  }

  const copyLinkToClipboard = (postId) => {

    const currentBaseUrl = window.location.origin;
    const dynamicUrl = `${currentBaseUrl}/dashboard/post/${postId}`;

    if (postId) {
      navigator.clipboard.writeText(dynamicUrl).then(() => {
        toast.dismiss()
        toast.success('Link copied to clipboard')
      });
    }
    else {
      toast.error('Somenthing went wrong')
    }

  }

  // // API for post delete ..............
  const deletePostAction = (id, index) => {
    swal({
      text: `Are you sure want to remove this post?`,
      dangerMode: false,
      buttons: true,
    })
      .then(result => {
        if (result) {
          let params = {
            id: id,
          }
          dispatch(deletePost({
            ...params, cb(res) {
              if (res.status) {

                setAllPostList((prevData) => {
                  const data = prevData.filter((item, i) => i !== index);
                  return data;
                })

                toast.success("Post deleted successfully")

              }
            }
          }))
        }
      });

  }


  const editPostAction = (id) => {
    let params = {
      id: id,
    }
    dispatch(getPostSingleDetails({
      ...params, cb(res) {
        if (res.status) {

          setEditFlag(true)
          setEditPostID(res.data.payload?.id)
          setPost(res.data.payload?.post ?? '')

          const companyTagData = res.data.payload?.tag_company_details?.map((item, i) => {
            return {
              id: item.company_id,
              name: item?.company_information?.company_name
            }
          })

          const userTagData = res.data.payload?.tag_user_details?.map((item, i) => {
            return {
              id: item.user_id,
              name: item?.user_details?.user_profile?.first_name + " " + item?.user_details?.user_profile?.last_name
            }
          })

          setMainTags({
            companies: companyTagData,
            users: userTagData
          })

          setSelectedIds({
            companies: companyTagData.map((item) => item.id),
            users: userTagData.map((item) => item.id)
          })

          if (res?.data?.payload?.attachment) {

            setPhotoUpload({
              uploadPhoto: {

                name: res?.data?.payload?.attachment_name ?? 'File',
                size: res?.data?.payload?.attachment_size ?? 100000,
                url: res?.data?.payload?.thumbnail_url

              }, flag: res?.data?.payload?.attachment_type
            });

            setVideoUrlOriginal(res?.data?.payload?.attachment)

            setPhotoInfo([res?.data?.payload?.thumbnail_url])

          }

          if (window)
            window.scrollTo(0, 0);

        }
      }
    }))
  }

  // // API for disabling comments ..............
  const disableEnableCommentAction = (id, flag, index) => {
    swal({
      text: `Are you sure want to ${flag == 1 ? 'disable' : 'enable'} comments on this post?`,
      dangerMode: false,
      buttons: true,
    })
      .then(result => {
        if (result) {
          let params = {
            id: id,
            disable_comment: flag
          }
          dispatch(disableEnableComment({
            ...params, cb(res) {
              if (res.status) {

                setAllPostList((prevData) => {
                  let data = [...prevData]
                  const updatedPost = {
                    ...data[index],
                    post_details: {
                      ...data[index].post_details,
                      disable_comment: flag,
                    },
                  };

                  data[index] = updatedPost;

                  return data;
                })

              }
            }
          }))
        }
      });

  }


  useEffect(() => {
    window.scrollTo(0, 0);
    companyProfileInfo();
    document.title = "Profile";
  }, []);


  useEffect(() => {
    if (authSelector?.userStep?.user?.user_company_information !== null) {
      setHideEditBtn(false)
    }
    else if (authSelector?.userStep?.user?.user_company_information === null) {
      setHideEditBtn(true)
    }
  }, [])

  useEffect(() => {
    setShowNestedReply(false)
    setPostIndexMoreComments("")
  }, [])


  //   const fetchMoreData = () => {
  //     if (totalPages > page) {
  //         setPages(page + 1);
  //         fetchData(page + 1);
  //     }
  // };


  return (
    <>
      <section className="companyProfile_">
        <div className="topprofileBanner">
          <div className="container-fluid noPadding">
            <div className="row">
              {
                authSelector.loading ?
                  <>
                    <div className="row">
                      <div className="col-sm-12 text-center pt-5 pb-5">
                        <div className="customLoader">
                          <div className="spinner-grow text-success" role="status"></div>
                        </div>
                      </div>
                    </div>
                  </>
                  : userDeatil?.banner !== null ?
                    <img
                      className="profile-img companyprofilebanner"
                      src={
                        userDeatil?.banner
                      }
                    />
                    :
                    userDeatil?.banner === null ?
                      <img
                        className="profile-img companyprofilebanner"
                        src={
                          Images.companyProfile2
                        }
                      />
                      : ""
              }
              {
                hideEditBtn === true ? "" :
                  <>
                    <input
                      type="file"
                      id="fileAttCompany"
                      accept="image/jpeg, image/jpg, image/png"
                      className="form-control d-none"
                      required=""
                      onChange={(e) => {
                        onChangePicture(e, 2);
                      }}
                    />
                    <label className="fileupimg" htmlFor="fileAttCompany">
                      <figure className="chnageProfile">
                        <img
                          src={Images.attach}
                          alt="Attach"
                          className="uploadIcon_"
                        />
                      </figure>
                    </label>
                  </>
              }
            </div>
          </div>
        </div>
        <div className="companyDetailspro_ mb-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="companyDetailsLeft">
                  <div className="containerBox pt-2">
                    <div className="row">
                      <div className="col-lg-2 col-md-3 p-0">
                        <div className="profileResponsive">
                          <div className="designImg_">
                            {
                              authSelector?.loading ?
                                <>
                                  <div className="row">
                                    <div className="col-sm-12 text-center pt-5 pb-5">
                                      <div className="customLoaderProfile">
                                        <div className="spinner-grow text-success" role="status"></div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                                :
                                <>
                                  {
                                    userDeatil?.company_logo === "" ?
                                      <img
                                        className="imgproduct_"
                                        src={
                                          Images.puzzleProfile
                                        }
                                      />
                                      :
                                      userDeatil?.company_logo !== "" ?
                                        <img
                                          className="imgproduct_"
                                          src={
                                            userDeatil?.company_logo ?? Images.puzzleProfile

                                          }
                                        />
                                        : ""
                                  }

                                  {
                                    hideEditBtn === true ? "" : <>

                                      <input
                                        type="file"
                                        id="fileAttach"
                                        accept="image/jpeg, image/jpg, image/png"
                                        className="form-control d-none"
                                        required=""
                                        onChange={(e) => {
                                          onChangePicture(e, 1);
                                        }}
                                      />
                                      <label className="fileupimg" htmlFor="fileAttach">
                                        <figure className="chnageProfile">
                                          <img
                                            src={Images.attach}
                                            alt="Attach"
                                            className="uploadIcon_"
                                          />
                                        </figure>
                                      </label>
                                    </>
                                  }
                                </>
                            }
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-9 col-md-9">
                        <div className="designContent_">
                          <div className="text-md-start text-sm-center">
                            <div className="designLeft_">
                              <h4 className="boldmainHeading_">
                                {
                                  userDeatil?.company_name
                                }
                              </h4>
                              <h5 className="abouText_ mt-2">
                                {
                                  userDeatil?.industries_group_details?.name

                                }
                              </h5>
                              {userDeatil?.country_details?.name ?
                                <p className='motionLoc'>
                                  <img
                                    src={Images.locationpin}
                                    alt="Location"
                                    className="me-1"
                                  />
                                  <span className="textInnerOuter">
                                    {userDeatil?.province_details?.name
                                    } / {userDeatil?.country_details?.name}
                                  </span>
                                </p> : ""

                              }

                            </div>
                            <div className="designLeft_">
                            </div>
                          </div>
                          <div className="designbtnRight">

                          </div>
                        </div>
                      </div>

                    </div>
                    <hr className="bottomBorder_"></hr>
                    <div className="aboutprofile_ mt-4 ">
                      <h5 className="headingTitle_">
                        <b>
                          About {
                            userDeatil?.company_name
                          }
                        </b>
                      </h5>
                      <p className="innerSubtextSmall companyInfo mt-2 about15 ">
                        {
                          userDeatil?.company_service_description
                        }
                      </p>
                    </div>
                  </div>

                  <div className="containerBox mt-4 ">
                    <h4 className="headingTitle_ mb-3">
                      <b>Featured Posts</b>
                    </h4>
                    {
                      authSelector?.loading ?
                        <>
                          <div className="row ">
                            <div className="col-sm-12 text-center pt-5 pb-5">
                              <div className="spinner-grow text-success" role="status"></div>
                            </div>
                          </div>
                        </>
                        :
                        <>
                          <InfiniteScroll
                            dataLength={allPost?.length} // Total length of the data
                            // next={() => fetchMoreData()} 
                            hasMore={true} // Set to false when there is no more data
                            // loader={<h4>Loading...</h4>} // Loading indicator
                            // endMessage={<p>No more data to load.</p>}
                            className="overflow-hidden"
                          >
                            {allPost && allPost?.length > 0 ?
                              <div className="featurePostCon">
                                {allPost?.map((post, index) => {
                                  return (
                                    <div
                                      className="mb-4" key={index}  >
                                      <div className='posts justify-content-between'>

                                        <div className="posts">
                                          {userDeatil?.user_id == post?.user_id ?
                                            <img onClick={() => handlePageRedirectMyProfile(post?.post_details?.user_details?.user_company_information?.company_name)} src={post?.post_details?.user_details?.user_profile?.profile_photo ? post?.post_details?.user_details?.user_profile?.profile_photo : Images.puzzleProfile} className='postUser me-3' alt='UserImage' />
                                            :
                                            <img onClick={() => handlePageRedirect(post?.post_details?.user_details?.user_company_information?.company_name, post?.post_details?.user_details?.user_company_information?.id)} src={post?.post_details?.user_details?.user_profile?.profile_photo ? post?.post_details?.user_details?.user_profile?.profile_photo : Images.puzzleProfile} className='postUser me-3' alt='UserImage' />
                                          }
                                          <div className='postsDetails'>
                                            <p className='innerSubtext m-0'><strong>{post?.post_details?.user_details?.company_name}</strong></p>
                                            {userDeatil?.user_id == post?.user_id ?
                                              <h5 onClick={() => handlePageRedirectMyProfile(post?.post_details?.user_details?.user_company_information?.company_name)} className='headingTitleSmall_ d-flex align-items-center justify-content-between'>
                                                <span className="memberName">{post?.post_details?.user_details?.user_profile?.first_name} {post?.post_details?.user_details?.user_profile?.last_name}</span>
                                              </h5> :
                                              <h5 onClick={() => handlePageRedirect(post?.post_details?.user_details?.user_company_information?.company_name, post?.post_details?.user_details?.user_company_information?.id)} className='headingTitleSmall_ d-flex align-items-center justify-content-between mb-2'>
                                                <span className="memberName">{post?.post_details?.user_details?.user_profile?.first_name} {post?.post_details?.user_details?.user_profile?.last_name}</span>
                                              </h5>
                                            }
                                            {post?.post_details?.post?.startsWith('http') || post?.post_details?.post?.startsWith('https') ?
                                              <a className="linkIndex_" href={post?.post_details?.post} target="_blank" rel="noopener noreferrer" key={key}>
                                                {post?.post_details?.post}
                                              </a>
                                              : <p className='innerSubtextSmall text-break text-start'>{post?.post_details?.post}</p>
                                            }
                                            <span className='weeksTxt'>{moment(post?.created_at).fromNow()}</span>
                                          </div>
                                        </div>

                                        <div className="postOptionBox">
                                          <div className="dropdown">
                                            <p className="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                              <i className="las la-ellipsis-h moreOptionIcon"></i>
                                            </p>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                              <li onClick={() => copyLinkToClipboard(post?.post_details?.id)}><a className="dropdown-item">Copy link to post</a></li>
                                              {userDeatil?.user_id == post?.post_details?.user_id &&
                                                <>
                                                  <li onClick={() => {
                                                    setEditId(post?.post_details?.id)
                                                    editPostAction(post?.post_details?.id)
                                                    setModalDetail({ show: true, flag: "AddANewPost" });
                                                    setKey(Math.random());
                                                  }}

                                                  ><a className="dropdown-item">Edit post</a></li>

                                                  <li onClick={(e) => { deletePostAction(post?.post_id, index) }} >
                                                    <a className="dropdown-item">Delete post</a>
                                                  </li>

                                                  <li onClick={(e) => { disableEnableCommentAction(post?.post_details?.id, post?.post_details?.disable_comment == 1 ? 2 : 1, index) }} >
                                                    <a className="dropdown-item">
                                                      {post?.post_details?.disable_comment == 1 ?
                                                        "Enable comments on this post"
                                                        :
                                                        "Disable comments on this post"
                                                      }
                                                    </a>
                                                  </li>

                                                  <li><Link to="/settings" className="dropdown-item">who can see this  post ?</Link></li>
                                                </>
                                              }
                                            </ul>
                                          </div>

                                        </div>
                                      </div>
                                      {
                                        post?.post_details?.attachment_type == 2 ?
                                          <>
                                            <div class="uploadProfile_ uploadProfile__">
                                              <i class="fas fa-file-pdf ms-start me-3"></i>
                                              <div class="profileDetail_">
                                                <h5 class="profileboldHead">{post?.post_details?.attachment_name}</h5>
                                              </div>
                                            </div>
                                          </>
                                          : ""
                                      }
                                      {post?.post_details?.attachment_type == 3 ?
                                        <>
                                          {post?.isvideo == true ?
                                            <video controls autoPlay>
                                              <source src={post?.post_details?.attachment} type="video/mp4" className='soundbiteImg' />
                                            </video>
                                            :
                                            <div className="postBox mb-3">
                                              <img className="imagepost_" src={post?.post_details?.thumbnail_url} />
                                              <img onClick={() => handleVideoPlay(post?.post_details, index)} className="videoIcon" src={Images.VideoIcon} />
                                            </div>
                                          }
                                        </>
                                        : post?.attachment == null ?
                                          ""
                                          : ""
                                      }
                                      {
                                        post?.post_details?.attachment_type == 1 ?
                                          <div className="postImage_ mt-3 mb-3" >
                                            <img className="imagepost_" src={post?.post_details?.attachment} />
                                          </div> : ""
                                      }
                                      <div className='bottomPostSec d-flex align-items-center justify-content-end my-3'>
                                        <p className="postrate_">
                                          <span onClick={() => totalUserLikeList(post?.post_details?.id,)} >{post?.post_details?.likeCount}</span>
                                          {post?.post_details?.is_like?.like_status && post?.post_details?.is_like?.like_status == 1 ? <img className='likepost_ me-2' src={Images.thumbsup} onClick={(e) => { postLike(post?.post_details?.id, 2, index) }} />
                                            : <img className='likepost_ me-2' src={Images.greyLike} onClick={(e) => { postLike(post?.post_details?.id, 1, index) }} />
                                          }
                                        </p>
                                        {post?.post_details?.disable_comment === 2 && post?.post_details?.total_comments > 0 ?
                                          <p className="postrate_"><span >{post?.post_details?.total_comments}</span>
                                            <img
                                              src={Images.message}
                                              onClick={() => handleLoadMoreComments(post?.post_id, index)}
                                              className='messageiconn me-2' alt='Message' />
                                          </p>
                                          : <p className="postrate_"><span >{post?.post_details?.total_comments}</span>
                                            <img
                                              src={Images.commentGrey}
                                              className='messageiconn me-2' alt='Message' />
                                          </p>
                                        }
                                        <p className="postrate_">
                                          <img
                                            src={Images.star}
                                            onClick={(e) => {
                                              postFetures(
                                                post?.post_details?.id,
                                                2,
                                                index
                                              );
                                            }}
                                            className="messageiconn"
                                            alt="Message"
                                          />

                                        </p>
                                      </div>
                                      {/* {post?.post_details && post?.post_details?.post_comments[0] ?
                                        <>
                                          <div className="row">
                                            <div className="col-lg-1 col-md-1 p-0">
                                              <img src={Images.commentReply} className='newChatReplyIcon img-fluid me-1' alt='UserImage' />
                                            </div>
                                            <div className="col-lg-11 col-md-11 ps-0">
                                              <div className="listComments commentreply">
                                                <div className='comments mt-2 '>

                                                  <div className='commentReply newCommentReply'>
                                                    <figure>
                                                      {
                                                        userDeatil?.user_id == post?.post_details?.post_comments[0]?.user_id ?
                                                          <img onClick={() => handlePageRedirectMyProfile(post?.post_details?.post_comments[0]?.user_details?.user_company_information?.company_name)} src={post?.post_details?.post_comments[0]?.user_details?.user_profile?.profile_photo ? post?.post_details?.post_comments[0]?.user_details?.user_profile?.profile_photo : Images.puzzleProfile} className='chatPosttedUser me-3' alt='UserImage' />
                                                          :
                                                          <img onClick={() => handlePageRedirect(post?.post_details?.post_comments[0]?.user_details?.user_company_information?.company_name, post?.post_details?.post_comments[0]?.user_details?.user_company_information?.id)} src={post?.post_comments[0]?.user_details?.user_profile?.profile_photo ? post?.post_details?.post_comments[0]?.user_details?.user_profile?.profile_photo : Images.puzzleProfile} className='chatPosttedUser me-3' alt='UserImage' />
                                                      }

                                                    </figure>
                                                    <div>
                                                      {
                                                        userDeatil?.user_id == post?.post_details?.post_comments[0]?.user_id ?
                                                          <p className="innerSubtextSmall replyPerson" onClick={() => handlePageRedirectMyProfile(post?.post_details?.post_comments[0]?.user_details?.user_company_information?.company_name)}><b>{post?.post_details?.post_comments[0]?.user_details?.user_profile?.first_name} {post?.post_details?.post_comments[0]?.user_details?.user_profile?.last_name}</b></p>
                                                          :
                                                          <p className="innerSubtextSmall replyPerson" onClick={() => handlePageRedirect(post?.post_details?.post_comments[0]?.user_details?.user_company_information?.company_name, post?.post_details?.post_comments[0]?.user_details?.user_company_information?.id)}><b>{post?.post_details?.post_comments[0]?.user_details?.user_profile?.first_name} {post?.post_details?.post_comments[0]?.user_details?.user_profile?.last_name}</b></p>
                                                      }

                                                      <h5 className='headingTitleSmall_ '>
                                                        <span className='commentText mb-2 replyTime'>{moment(post?.post_details?.post_comments[0]?.created_at).fromNow()}</span>
                                                      </h5>
                                                    </div>
                                                  </div>
                                                  <p className="innerSubtextSmall mt-2">{post?.post_details?.post_details?.post_comments[0]?.comment}</p>
                                                </div>
                                                <div className='commentReply justify-content-between my-3'>
                                                  <figure>
                                                    <p className="postrate_ justify-content-end">
                                                      {post?.post_details?.post_comments[0]?.is_comment_liked?.status && post?.post_details?.post_comments[0]?.is_comment_liked?.status == 1 ?
                                                        <img className='likepost_ me-2' src={Images.thumbsup} onClick={(e) => commentLikeAction(post?.post_details?.post_comments[0].id, 2, index, "", "postindexcomment")} />
                                                        : <img className='likepost_ me-2' src={Images.greyLike} onClick={(e) => commentLikeAction(post?.post_details?.post_comments[0].id, 1, index, "", "postindexcomment")} />
                                                      }
                                                      <span>{post?.post_details?.post_comments[0].CommentLikeCount ?? 0}</span>
                                                    </p>
                                                  </figure>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                        </> : ""
                                      } */}
                                      <div className="row">
                                        {postIndexMoreComments === index ?
                                          <>
                                            <div className="dashboardComments" id="dashboardComments">
                                              {allCommentList && allCommentList?.length > 0 ?
                                                <>
                                                  {allCommentList.map((allComment, commentindex) => {
                                                    return (
                                                      <>
                                                        {/* {commentindex === 0 ? ""
                                                          : */}
                                                        <div className="row">

                                                          <div className="col-lg-1 col-md-1 p-0">
                                                            <img src={Images.commentReply} className='newChatReplyIcon img-fluid me-1' alt='UserImage' />
                                                          </div>
                                                          <div className="col-lg-11 col-md-11 ps-0">
                                                            <div className="listComments commentreply">
                                                              <div className='comments mt-2 '>

                                                                <div className='commentReply newCommentReply'>
                                                                  <figure>
                                                                    {

                                                                      userDeatil?.user_id == allComment?.user_id ?
                                                                        <img
                                                                          onClick={() => handlePageRedirectMyProfile(allComment?.user_details?.user_company_information?.company_name)}
                                                                          src={allComment?.user_details?.user_profile?.profile_photo ? allComment?.user_details?.user_profile?.profile_photo : Images.puzzleProfile} className='chatPosttedUser me-3' alt='UserImage'
                                                                        /> :
                                                                        <img
                                                                          onClick={() => handlePageRedirect(allComment?.user_details?.user_company_information?.company_name, allComment?.user_details?.user_company_information?.id)}
                                                                          src={allComment?.user_details?.user_profile?.profile_photo ? allComment?.user_details?.user_profile?.profile_photo : Images.puzzleProfile} className='chatPosttedUser me-3' alt='UserImage'
                                                                        />
                                                                    }
                                                                  </figure>
                                                                  <div>
                                                                    {
                                                                      userDeatil?.user_id == allComment?.user_id ?
                                                                        <p className="innerSubtextSmall replyPerson" onClick={() => handlePageRedirectMyProfile(allComment?.user_details?.user_company_information?.company_name)}><b>{allComment?.user_details?.user_profile?.first_name} {allComment?.user_details?.user_profile?.last_name}</b></p>
                                                                        : <p className="innerSubtextSmall replyPerson" onClick={() => handlePageRedirect(allComment?.user_details?.user_company_information?.company_name, allComment?.user_details?.user_company_information?.id)}><b>{allComment?.user_details?.user_profile?.first_name} {allComment?.user_details?.user_profile?.last_name}</b></p>}
                                                                    <h5 className='headingTitleSmall_ '>
                                                                      <span className='commentText mb-2 replyTime'>{moment(allComment?.created_at).fromNow()}</span>
                                                                    </h5>
                                                                  </div>
                                                                </div>

                                                                <p className="innerSubtextSmall mt-2">{allComment?.comment}</p>


                                                              </div>
                                                              <div className='commentReply justify-content-between my-3'>

                                                                <figure>
                                                                  <p className="postrate_ justify-content-end">
                                                                    {allComment?.is_comment_liked ?
                                                                      <img className='likepost_ me-2' src={Images.thumbsup} onClick={(e) => { commentLikeAction(allComment?.id, 2, commentindex, "", "") }} />
                                                                      : <img className='likepost_ me-2' src={Images.greyLike} onClick={(e) => { commentLikeAction(allComment?.id, 1, commentindex, "", "") }} />
                                                                    }
                                                                    <span>{allComment?.total_like_count ? allComment?.total_like_count : allComment?.CommentLikeCount ? allComment?.CommentLikeCount : 0}</span>
                                                                  </p>
                                                                </figure>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>

                                                        {/* } */}
                                                      </>
                                                    )
                                                  })}
                                                </>
                                                : ""
                                              }
                                              {allCommentList?.length > 1 && allCommentData.total > allCommentList?.length &&
                                                <div className="text-center" onClick={() => { handleLoadMoreComments(post?.post_id, postIndexMoreComments, page + 1) }} >
                                                  <i class="las la-angle-double-down loadMore"></i>
                                                </div>
                                              }
                                            </div>
                                          </> : ""}

                                        {
                                          post?.post_details?.disable_comment === 1 ? <hr /> :
                                            <div className="footerComment">
                                              <div className="commentBox_ px-0" >
                                                <div className="messageTextarea">
                                                  <img className="img-fluid postUser" src={userInfo?.user_profile?.profile_photo ? userInfo?.user_profile?.profile_photo : Images.puzzleProfile} />
                                                  <div className="form-group">
                                                    {replyFlag &&
                                                      <div className="d-flex justify-content-between">
                                                        <h6>
                                                          Reply to {replyName}
                                                        </h6>

                                                        <i className="fa fa-times mt-0 cursor-pointer" onClick={() => {
                                                          setReplyId(null)
                                                          setReplyName('')
                                                          setReplyFlag(false)
                                                          setSelectedIndex(null)
                                                        }}></i>
                                                      </div>
                                                    }

                                                    <textarea type="text" className="customFormControl" value={post.post_id === postID ? comment : ""} placeholder="Have something to say..." id="inputName" name="post" key={index}
                                                      onChange={(e) => { setComment(e.target.value); setPostID(post?.post_id) }} />
                                                    <img className="sendIcon_" src={Images.sendIcon_} onClick={() => { postComment(post?.post_id, index) }} />
                                                  </div>
                                                  <img className="sendIcon_" src={Images.sendIcon_} onClick={() => { postComment(post?.post_id, index, "comment") }} />
                                                </div>
                                              </div>
                                            </div>
                                        }
                                      </div>
                                    </div>
                                  )
                                })
                                }
                              </div>
                              : "No Post Found"
                            }
                          </InfiniteScroll>
                        </>
                    }

                  </div>

                  <div className="containerBox mt-4 pb-5">
                    <div className="currentOpportunity_">
                      <div className="currentTop">
                        <h4 className="headingTitle_">
                          <b>Current Opportunities</b>
                        </h4>
                        {
                          userCurrentOpportunity?.length > 3 ?
                            <button className="subsmallBtnblue_" onClick={() => handleActiveOpp()}>
                              View All
                            </button>
                            : ""
                        }

                      </div>
                      {
                        authSelector?.loading ?
                          <>
                            <div className="row">
                              <div className="col-sm-12 text-center pt-5 pb-5">
                                <div className="spinner-grow text-success" role="status"></div>
                              </div>
                            </div>
                          </>
                          :
                          <>
                            <div className="row mt-5 ctive">
                              {userCurrentOpportunity?.length > 0 ? (
                                <>
                                  {userCurrentOpportunity.slice(0, 3).map((data, index) => {
                                    return (
                                      <>
                                        <div className="col-lg-4" key={index}>
                                          <div className={`opportunityComtent_ ${index == 0 ? 'noOpportunity' : ''}`}>
                                            <img
                                              className="todoList_"
                                              // src={
                                              //   data?.image_mime_type === "image/png" || data?.image_mime_type === "image/jpeg" ? data?.opportunity_media : data?.image_mime_type === "application/pdf" ? Images.iconPdf : data?.image_mime_type === "application/msword" || data?.image_mime_type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? Images.iconDoc : Images.todoList
                                              // }

                                              src={
                                                Images.todoList
                                              }
                                            />
                                            <h5 className="opportunityHead_ mt-3">
                                              {data?.posting_title}
                                            </h5>
                                            <h6 className="headingTitleSmall_ mb-2">
                                              {/* <option value="">Select</option>
                                              <option value="1">Yearly</option>
                                              <option value="2">Monthly</option>
                                              <option value="3">Daily</option>
                                              <option value="4">Hourly</option>
                                              <option value="0">N/A</option> */}
                                              {data?.contract_length === 1 ? "Yearly Term Contract" : data?.contract_length === 2 ? "Monthly  Term Contract" :
                                                data?.contract_length === 3 ? "Weekly  Term Contract" : data?.contract_length === 4 ? "Daily  Term Contract" : data?.contract_length === 5 ? "Hourly  Term Contract" : ""}
                                              {/* Fixed Term Contract */}
                                            </h6>
                                            <p className="lightgreyHead_">
                                              <i>Additional Contract Details</i>
                                            </p>
                                            {data?.hq_location ?
                                              <p className="postHours_ mb-3">
                                                {data?.hq_location}
                                              </p> : ""
                                            }
                                            <p className="innerSubtextSmall wordLimit text-start">
                                              {data?.description}
                                            </p>
                                            <p className="lightgreyHead_ mb-0">
                                              <b>Posted Date</b> : 12-12-2023
                                            </p>
                                            {/* <button className="subsmallBtngreen_ mt-3">
                                              View Opportunity
                                            </button> */}
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                                </>
                              ) : (
                                <p className="mb-0">No Current Opportunity Found</p>
                              )}
                            </div>
                          </>
                      }
                    </div>
                  </div>

                  {choosePlan == "Bronze" && chooseBusinessType === 1 || choosePlan == "Bronze" && chooseBusinessType === 2 ? "" :
                    <div className="containerBox mt-4">
                      <div className="newsBox_ endorsement_ ">
                        <Tabs defaultIndex={1}>
                          <div className="endorTabs">
                            <h4 className="headingTitle_">
                              <b>Acclamations</b>
                            </h4>
                            <TabList>
                              <Tab>Given</Tab>
                              <Tab>Received</Tab>
                            </TabList>
                          </div>

                          {
                            authSelector?.loading ?
                              <>
                                <div className="row">
                                  <div className="col-sm-12 text-center pt-5 pb-5">
                                    <div className="spinner-grow text-success" role="status"></div>
                                  </div>
                                </div>
                              </>
                              :
                              <div className="row mt-4">
                                <TabPanel>
                                  {userEndorsementGiven?.length > 0 ? (
                                    <>
                                      {userEndorsementGiven?.map((data, index) => {
                                        return (
                                          <>
                                            <div className="messageDetail d-flex   mt-4" key={index}>
                                              <div className="imageDiv">
                                                <img
                                                  onClick={() => handlePageRedirect(data?.receiver_details?.user_company_information?.company_name, data?.receiver_details?.user_company_information?.id)}
                                                  src={data?.receiver_details?.user_company_information?.company_logo ? data?.receiver_details?.user_company_information?.company_logo : Images.puzzleProfile}
                                                  className="userProfile me-2"
                                                  alt="UserImage"
                                                />
                                              </div>
                                              <div className="postsDetails">
                                                <h5 className="innerSubtextSmall d-flex align-items-center mb-2">
                                                  <span
                                                    onClick={() => handlePageRedirect(data?.receiver_details?.user_company_information?.company_name, data?.receiver_details?.user_company_information?.id)}
                                                  >
                                                    <strong>
                                                      {data?.receiver_details?.user_company_information?.company_name}{" "}
                                                    </strong>
                                                  </span>
                                                </h5>
                                                <p className="discription_">
                                                  {data?.endorsement}
                                                </p>
                                              </div>
                                            </div>
                                          </>
                                        );
                                      })}
                                    </>
                                  ) : (
                                    <p>No Acclamations Given</p>
                                  )}
                                </TabPanel>

                                <TabPanel>
                                  {userEndorsementReceived?.length > 0 ? (
                                    <>
                                      {userEndorsementReceived?.map((data, index) => {

                                        return (
                                          <>
                                            <div className="messageDetail d-flex   mt-4" key={index}>
                                              <div className="imageDiv">
                                                <img
                                                  onClick={() => handlePageRedirect(data?.sender_details?.user_company_information?.company_name, data?.sender_details?.user_company_information?.id)}
                                                  src={data?.sender_details?.user_company_information?.company_logo ? data?.sender_details?.user_company_information?.company_logo : Images.companyProfile2}
                                                  className="userProfile me-2"
                                                  alt="UserImage"
                                                />
                                              </div>
                                              <div className="postsDetails">
                                                <h5 className="innerSubtextSmall d-flex align-items-center mb-2">
                                                  <span
                                                    onClick={() => handlePageRedirect(data?.sender_details?.user_company_information?.company_name, data?.sender_details?.user_company_information?.id)}
                                                  >
                                                    <strong>
                                                      {data?.sender_details?.user_company_information?.company_name}{" "}
                                                    </strong>
                                                  </span>
                                                </h5>
                                                <p className="discription_">
                                                  {data?.endorsement}
                                                </p>
                                              </div>
                                            </div>
                                          </>
                                        );
                                      })}
                                    </>
                                  ) : (
                                    <p>No Acclamations Received</p>
                                  )}
                                </TabPanel>
                              </div>
                          }
                        </Tabs>
                      </div>
                    </div>
                  }




                </div>
              </div>
              <div className="col-lg-4">
                <div className="containerBoxright mt-5">
                  <div className="companyDetailsRight">
                    <h5 className="headingTitle_ mb-2">
                      <b>Company Details</b>
                    </h5>
                    {
                      authSelector?.loading ?
                        <>
                          <div className="row">
                            <div className="col-sm-12 text-center pt-5 pb-5">
                              <div className="spinner-grow text-success" role="status"></div>
                            </div>
                          </div>
                        </>
                        :
                        <ul className="companyDetailsList">
                          {
                            userDeatil?.company_website === "" ? " " :
                              <li>
                                <span className="blueinnerSubtext pe-2">Website</span>
                                <a className="listHead_" href={userDeatil?.company_website} target="_blank">
                                  {
                                    userDeatil?.company_website
                                  }
                                </a>
                              </li>
                          }
                          {
                            userDeatil?.industries_group_details === null ? "" :
                              <li>
                                <span className="blueinnerSubtext">Industry</span>
                                <span className="listHead_">
                                  {
                                    userDeatil?.industries_group_details?.name
                                  }
                                </span>
                              </li>
                          }

                          <li>
                            <span className="blueinnerSubtext">Company Size</span>

                            <span className="listHead_">
                              {userDeatil?.number_of_employees == 0
                                ? userDeatil?.number_of_employees
                                : userDeatil?.number_of_employees + `+ employees`}
                            </span>
                          </li>

                          {userDeatil?.main_company_country_location ?
                            <li>
                              <span className="blueinnerSubtext">Locations</span>
                              <span className="listHead_">
                                {
                                  userDeatil?.main_company_country_location
                                }
                              </span>
                            </li>
                            : ""

                          }
                          {
                            userDeatil?.company_diverse_supplier_description.length === 0 ? "" :
                              <li>
                                <ul className='recentlyDataList mt-0'>
                                  <li >
                                    {
                                      userDeatil?.company_diverse_supplier_description?.map((val, index) => (
                                        <div className="recentlyBtn d-inline-block me-1" key={index}>
                                          {val.diverse_supplier_description_details.name}
                                        </div>
                                      ))
                                    }
                                  </li>
                                </ul>
                              </li>
                          }
                        </ul>
                    }

                  </div>
                </div>
                {userDeatil?.company_existing_certificate_details?.length > 0 ?
                  <div className="containerBoxright mt-3">
                    <div className="deviserCompany_">

                      <div className="currentTop">
                        <h4 className="headingTitle_">
                          <b>Certifications</b>
                        </h4>
                      </div>
                      <div className="certificationContainer_">
                        {userDeatil?.company_existing_certificate_details?.length > 0 ? (
                          <>
                            {userDeatil?.company_existing_certificate_details
                              ?.filter((data) => data?.existing_certificates_details?.name !== 'Other')
                              ?.map((filteredData, index) => {
                                return (
                                  <>
                                    <div className="posts justify-content-between mt-3" key={index}>
                                      <img
                                        src={Images.certificateIcon_}
                                        className="certifyBadge me-3"
                                        alt="UserImage"
                                      />
                                      <div className="postsDetails">
                                        <h5 className="headingTitleSmall_ d-flex     justify-content-between mb-1">
                                          <span className="listHead_">
                                            {
                                              filteredData?.existing_certificates_details?.name
                                            }
                                            <span className="docText">
                                            </span>
                                          </span>
                                        </h5>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                  : ""
                }

                {userDeatil?.company_trade_membership_details?.length > 0 ?
                  <div className="containerBoxright mt-3">
                    <div className="deviserCompany_">

                      <div className="currentTop">
                        <h4 className="headingTitle_">
                          <b>Trade Membership</b>
                        </h4>
                      </div>
                      <div className="certificationContainer_">
                        {userDeatil?.company_trade_membership_details?.length > 0 ? (
                          <>
                            {userDeatil?.company_trade_membership_details
                              ?.filter((data) => data?.company_trade_membership_details?.name !== 'Other')
                              ?.map((filteredData, index) => {
                                return (
                                  <>
                                    <div className="posts justify-content-between mt-3" key={index}>
                                      <img
                                        src={Images.certificateIcon_}
                                        className="certifyBadge me-3"
                                        alt="UserImage"
                                      />
                                      <div className="postsDetails">
                                        <h5 className="headingTitleSmall_ d-flex     justify-content-between mb-1">
                                          <span className="listHead_">
                                            {
                                              filteredData?.trade_membership_details?.name
                                            }
                                            <span className="docText">
                                            </span>
                                          </span>
                                        </h5>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                  : ""
                }
                <div className="lookSuplier mt-4">
                  <div className="cardBox">
                    <div className="textBox">
                      <h6>
                        Business <b>Resources</b>
                      </h6>
                      <p>
                        Building, sustaining and growing a business is a
                        never-ending journey that involves continuos infusions
                        of knowledge and wisdom.
                      </p>
                      <button
                        onClick={() => navigate("/resources")}
                        className="primaryBtnSmall"
                      >
                        Start Learning
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={
          modalDetail.flag === "CommentBox"
            ? "commentBox"
            : modalDetail.flag === "CommentBox"
              ? "commonWidth customContent"
              : ""
        }
        ids={modalDetail.flag === "addTagToPost" ? "addTagToPost" : ""}
        child={
          modalDetail.flag === "CommentBox" ? (
            <CommmentBox
              id={modalDetail.id}
              close={() => handleOnCloseModal()}
            />
          ) : modalDetail.flag == "allPostLikeUser" ? (
            <AllPostLikeUser
              id={modalDetail.id}
              close={() => handleOnCloseModal()}
            />
          )
            : modalDetail.flag == "AddANewPost" ?
              <AddNewPost
                // closeAllCommentsMore={setAllCommentsLoad(true)}
                close={() => handleOnCloseModal()}
                selectedCompanies={mainTags.companies?.length > 0 ? mainTags.companies : []}
                selectedUsers={mainTags.users?.length > 0 ? mainTags.users : []}
                updateTagData={(data, ids) => updateTagData(data, ids)}
                setAllPostList={setAllPostList}
                selectedIds={selectedIds}
                allPost={allPost}
                editId={editId}
                setEditId={setEditId}
                userDeatil={userDeatil}
                editFlag={editFlag}
                setEditFlag={setEditFlag}
              /> :
              ""
        }
        header={
          <div className="modalHeader_">
            <div className="common_">
              {modalDetail.flag === "CommentBox" ? (
                <h2 className="headingTitle_">Comment Box</h2>
              ) : modalDetail.flag == "allPostLikeUser" ? (
                <h2 className="headingTitle_">Post Like List</h2>
              ) : (
                ""
              )}
            </div>
          </div>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default MyCompanyProfile;
