import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import * as Images from "../../../utilities/images";
import moment from 'moment';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { getAllResources } from '../../../redux/slices/support';
import { useDispatch } from 'react-redux';
import ArticleSideBar from '../articleSidebar';

const CategorySearchData = (props) => {

  const getArticleInfo = (e,id) => {
    e.preventDefault()
    props?.getArticleInfo(id)
  }

  return (
    <>
      {
        props?.searchedData?.length > 0 ? <>
          {
            props?.searchedData && props?.searchedData?.map((data, index) => (
              <>
                {/* <h1 className='heading42 fw-normal' onClick={() => navigate(`/article-info/${data?.id}`)}>{data?.title}</h1> */}
                <h1 className='heading42 fw-normal' onClick={(e) => getArticleInfo(e,data?.id)}>{data?.title}</h1>

                {/* <p className='headingBlack21 fw-normal mb-1'>Help Desk {'>'} {data?.help_category_details?.name}</p>
                <p className='lastUpdateQue'>Last Updated - {moment(data?.help_category_details?.updated_at).format('MMM D, YYYY')}</p> */}
                <div className="suggestedArticleInner mb-5" key={index}>
                  <p className="suggestedDes wordLimit" dangerouslySetInnerHTML={{ __html: data?.description }}>
                  </p>
                  {
                    props?.searchedData.length > 1 ? <hr /> : ""
                  }

                </div>
              </>
            ))
          }
        </>
          : "No data found"
      }
    </>

  )
}

export default CategorySearchData