import React from 'react'
import BaseUrl from '../../../../constants/baseUrl'

const Soundbyterbank = (props) => {
  return (
    <div className=''>
        <div className='bitesCrad'>
          <video controls>
            <source src={`${BaseUrl.API_URL}uploads/videos/bankrupt.mp4`} className='soundbiteImg' />
          </video>
          <p>{'<'} 5-minute sessions for those running short on time but craving a quick dose of knowledge.</p>
        </div>
      </div>
  )
}

export default Soundbyterbank