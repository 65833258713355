import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import * as Images from "../../../utilities/images";
import { geocodeByAddress, getLatLng, } from 'react-places-autocomplete';
import "react-datepicker/dist/react-datepicker.css";
import { businessOwnershipType, getAllCountryList, getAllIndustryGroups, getAllIndustrySubGroups, getAllProvinceList, getCompanyProfileInfo, stepThreeCompanyLogoUplaod } from '../../../redux/slices/auth';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useOpportunitySelector } from "../../../redux/selector/opportunity";
import { addOpportunity, addPostOpportunityUploadPhotoOrPdf, getAllCurriencies } from "../../../redux/slices/opportunity";
import Multiselect from 'multiselect-react-dropdown';
import { diverseSupplierDescription, existingCertification, tradeMemberShip } from '../../../redux/slices/advanceSearch';
import PlacesAutocomplete, {
} from 'react-places-autocomplete';
import { useAuthSelector } from '../../../redux/selector/auth';

const AddPostOpportunity = (props) => {
    const opportunitySelector = useOpportunitySelector();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [postingtitle, setPostingtitle] = useState("");
    const [description, setDescription] = useState("");
    const [certificationOtherDescription, setCertificationOtherDescription] = useState("")

    const [currency, setCurrency] = useState('');
    const [allCurriencies, setAllCurriencies] = useState([]);

    const [contractAmount, setContractAmount] = useState("");
    const [contractLength, setContractLength] = useState("");

    const [industryGroup, setIndustryGroup] = useState([])
    const [industryGroupId, setIndustryGroupId] = useState("")
    const [industrySubGroupId, setIndustrySubGroupId] = useState("")
    const [subIndustryGroup, setSubIndustryGroup] = useState([])
    const [awardDate, setAwardDate] = useState(new Date());
    const [responseDeadline, setResponseDeadline] = useState(new Date());
    const [serviceLocation, setServiceLocation] = useState("");
    const [ownershipTypeId, setOwnershipTypeId] = useState("")
    const [ownershipType, setOwnershipType] = useState("")
    const [companyDescription, setCompanyDescription] = useState("")
    const [companyOtherDescription, setCompanyOtherDescription] = useState("")
    const [driversSupplierDescriptionId, setDriversSupplierDescriptionId] = useState();
    const [specificServiceLocationRequired, setSpecificServiceLocationRequired] = useState(true);
    const [existingCertificationData, setExistingCertificationData] = useState("")
    const [existingCertificationsId, setExistingCertificationsId] = useState();
    const [selected, setSelected] = useState([]);
    const [selectedCertifications, setSelectedCertifications] = useState([]);
    const [serviceLicensesRequires, setServiceLicensesRequires] = useState(true);

    const [photo, setPhotoUpload] = useState("");
    const [photoInfo, setPhotoInfo] = useState();

    const [countryId, setCountryId] = useState("");
    const [provinceId, setProvinceId] = useState("");

    const [getAllcountry, setGetAllcountry] = useState("");
    const [getAllProvince, setAllProvince] = useState("");
    const [workPreference, setWorkPreference] = useState(1);
    const [checkboxValuesJV, setCheckboxValuesJV] = useState("");
    const [checkboxValuesSecondTier, setCheckboxValuesSecondTier] = useState("");
    const [tradeMemberShipData, setTradeMemberShipData] = useState("");
    const [tradeMemberShipId, setTradeMemberShipId] = useState([]);

    const [otherTradeMemberships, setOtherTradeMemberships] = useState("")
    const [selecteTrade, setSelectedTrade] = useState("");


    const exceptThisSymbols = ["e", "E", "+", "-", "."];

    const [postOpportunity, setPostOpportunity] = useState('');


    const authSelector = useAuthSelector();


    const chooseBusinessType = authSelector?.userStep?.user?.business_type ?? authSelector?.userStep?.business_type;

    const choosePlan = authSelector?.userStep?.user?.membership_details?.name ?? authSelector?.userStep?.membership_details?.name;

    const post_opportunity_count = postOpportunity?.post_opportunity_count;

    //for COMPANY PROFILE INFORMATION.......
    const companyProfileInfo = () => {
        dispatch(
            getCompanyProfileInfo({
                cb(res) {
                    if (res.status) {
                        if (res?.data?.payload?.userDetails?.user_company_information !== null) {
                            setPostOpportunity(res?.data?.payload?.userDetails?.user_data[0])
                        }
                        else {
                            setPostOpportunity(res?.data?.payload?.userDetails?.user_data[0])
                        }

                    } else {
                    }
                },
            })
        );
    };


    //for add drivers Supplier Description........
    const SupplierDescription = () => {
        dispatch(diverseSupplierDescription({
            cb(res) {
                if (res.status) {
                    setCompanyDescription(res?.data?.payload)
                }
            }
        }))
    }

    // Existing certification
    const ExistingCertification = () => {
        dispatch(existingCertification({
            cb(res) {
                if (res.status) {
                    setExistingCertificationData(res.data.payload)
                }
                else {

                }
            }
        }))
    }

    const getAllCurrienciesAction = () => {
        dispatch(getAllCurriencies({
            cb(res) {
                if (res.status == 200) {
                    setAllCurriencies(res.data.payload)
                }
            }
        }))
    }


    //remove file
    const removeFile = file => () => {
        const newFiles = [photo]
        newFiles.splice(newFiles.indexOf(file), 1)
        setPhotoUpload(newFiles)
        setPhotoInfo("")
    }

    const industry = (e) => {
        dispatch(getAllIndustryGroups({
            cb(res) {
                if (res.status) {
                    setIndustryGroup(res?.data?.payload)
                }
                else {

                }
            }
        }))
    }

    // onchange input 
    const handleChange = (id, flag) => {
        if (flag == "industry") {
            setIndustryGroupId(id)
            subIndustry(id)
        }
        else {
            setIndustrySubGroupId(id)
        }
    }

    const subIndustry = (id) => {
        if (id) {
            setIndustryGroupId(id)
            let params = {
                id: id,
            }
            dispatch(getAllIndustrySubGroups({
                ...params, cb(res) {
                    if (res.status) {
                        setSubIndustryGroup(res?.data?.payload)
                        setIndustrySubGroupId("")
                    }
                    else {

                    }
                }
            }))

        }

    }

    // handle change address
    const handleSelect = async value => {
        const result = await geocodeByAddress(value);
        const ll = await getLatLng(result[0]);
        setServiceLocation(value)
    }

    const businessOwnership = (id) => {
        setOwnershipTypeId(id)
        dispatch(businessOwnershipType({
            cb(res) {
                if (res.status) {
                    setOwnershipType(res?.data?.payload)
                }
                else {

                }
            }
        }))
    }

    // onchange date set date in the state
    const onChangeDateAward = (date) => {
        setAwardDate(date)
    }

    // onchange date set date in the state
    const onChangeDateResponse = (date) => {
        setResponseDeadline(date)
    }

    // driver supplier description
    const handleChanges = (data) => {
        setSelected(data)
        let arr = [];
        for (let i = 0; i < data.length; i++) { arr.push(data[i].id) }
        setDriversSupplierDescriptionId(arr)
    }

    // Existing Certifications
    const handleChangesCertifications = (data) => {
        setSelectedCertifications(data)
        let arr = [];
        for (let i = 0; i < data.length; i++) { arr.push(data[i].id) }
        setExistingCertificationsId(arr)
    }

    const handleChangesTradeMembership = (data) => {
        setSelectedTrade(data);

        let arr = [];
        for (let i = 0; i < data.length; i++) {
            arr.push(data[i].id);
        }

        setTradeMemberShipId(arr);
    };

    // Trade Membership
    const TradeMembership = () => {
        dispatch(
            tradeMemberShip({
                cb(res) {
                    if (res.status) {
                        setTradeMemberShipData(res.data.payload);
                    } else {
                    }
                },
            })
        );
    };

    //Open to JV supplier opportunities
    const handleCheckboxChangeJV = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            // If checkbox is checked, add the value to the array
            // setCheckboxValuesJV([...checkboxValuesJV, value]);
            // If checkbox is checked, update the checkbox values accordingly
            const updatedValues = value;
            setCheckboxValuesJV(updatedValues);
        } else {
            // If checkbox is unchecked, remove the value from the array
            // setCheckboxValuesJV(checkboxValuesJV.filter((item) => item !== value));
            // If checkbox is unchecked, remove the value from the array
            setCheckboxValuesJV("");

        }
    };


    const handleCheckboxChangeSecondTier = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            // If checkbox is checked, add the value to the array
            // setCheckboxValuesSecondTier([...checkboxValuesSecondTier, value]);
            const updatedValues = value;
            setCheckboxValuesSecondTier(updatedValues);
        } else {
            // If checkbox is unchecked, remove the value from the array
            // setCheckboxValuesSecondTier(checkboxValuesSecondTier.filter((item) => item !== value));
            setCheckboxValuesSecondTier("")
        }
    };

    //for get all countrys .......
    const allCountry = (id) => {
        setCountryId(id)
        dispatch(
            getAllCountryList({
                cb(res) {
                    if (res.status) {
                        setGetAllcountry(res?.data?.payload);
                        if (id) {
                            provinceList(id);
                            setProvinceId("")
                        }
                        else {
                            setAllProvince("")
                        }
                    }

                },
            })
        );
    };


    //for get all provinceList .......
    const provinceList = (id) => {
        if (id) {
            let params = {
                id: id,
            };
            dispatch(
                getAllProvinceList({
                    ...params,
                    cb(res) {
                        if (res.status) {
                            setAllProvince(res?.data?.payload?.data);
                        }
                    },
                })
            );
        }
    };



    // onchange input
    const handleChangedCountry = (id, flag) => {
        if (flag == "country") {
            setCountryId(id);
            // setDiableProvince(id);
            provinceList(id);
        } else {
            setProvinceId(id);
        }
    };



    const toastId = useRef(null);

    // show only one toast at one time
    const showToast = (msg) => {
        if (!toast.isActive(toastId.current)) {
            toastId.current = toast.error(msg);
        }
    };


    //for add Opportunity
    const handleSubmit = (e) => {
        e.preventDefault();
        const numbers = /^[0-9]+$/;
        //  const numbers = /^[0-9]+$/;
        const regex = /[a-z|A-Z]+(?: [a-z|A-Z]+)*$/

        if (!postingtitle) {
            toast.error("Please enter posting title");
            return;
        }
        // else if (postingtitle.length <= 2) {
        //     toast.error("Posting title should be maximum 3 character")
        //     return;
        // }
        // else if (!postingtitle.match(regex)) {
        //     toast.error("Please enter only character and single space in posting title");
        //     return;
        // }
        // else if (!responseDeadline) {
        //     toast.error("Please select response deadline");
        //     return;
        // }
        // else if (!awardDate) {
        //     toast.error("Please select award date");
        //     return;
        // }
        else if (!description) {
            toast.error("Please enter description of contract opportunity");
            return;
        }
        else if (description.trim() === "") {
            toast.error("Description can't be empty");
            return;
        }
        else if (description.length <= 2) {
            toast.error("description should be maximum 3 character")
            return;
        }
        // else if (!currency) {
        //     toast.error("Please enter currency");
        //     return;
        // }
        // else if (!contractAmount) {
        //     toast.error("Please enter opportunity amount");
        //     return;
        // }
        // else if (!contractLength) {
        //     toast.error("Please enter opportunity length");
        //     return;
        // }
        else if (!industryGroupId) {
            toast.error("Please select Industry");
            return;
        }
        // else if (!industrySubGroupId) {
        //     toast.error("Please select Sub-industry");
        //     return;
        // }
        else if (!countryId) {
            toast.error("Please select Business Location");
            return;
        }
        // else if (!provinceId) {
        //     toast.error("Please select Business Area");
        //     return;
        // }
        // else if (!workPreference) {
        //     toast.error("Please select Work Preference");
        //     return;
        // }
        // else if (!driversSupplierDescriptionId) {
        //     toast.error("Please select Diverse Status");
        //     return;
        // }
        // else if (!checkboxValuesJV) {
        //     toast.error("Please select Open to JV Opportunities");
        //     return;
        // }

        // else if (!contractLength.match(numbers)){
        //     toast.error("Please enter only numbers");
        //     return;
        // }
        // else if (!photo || !photoInfo) {
        //     toast.error("Please select opportunity media");
        //     return;
        // }
        // else if (!preferencesSupplier) {
        //     toast.error("Please select preferences for supplier");
        //     return; 
        // }
        // else if (!serviceCategories) {
        //     toast.error("Please select product and service categories");
        //     return;
        // }
        // else if (!address) {
        //     toast.error("Please select service Location");
        //     return;
        // }
        // else if (!annualRevenue) {
        //     toast.error("Please select annual revenue");
        //     return;
        // }

        // else if (!numberOfEmployees) {
        //     toast.error("Please select number of Employees");
        //     return;
        // }
        // else if (!yearsInBusiness) {
        //     toast.error("Please select years in Business");
        //     return;
        // }
        // else if (!industriesServed) {
        //     toast.error("Please enter industries Served");
        //     return;
        // }
        // else if (industriesServed.length <= 2) {
        //     toast.error("Industries Served should be maximum 3 character")
        //     return;
        // }
        // else if (!industriesServed.match(regex)) {
        //     toast.error("Please enter only character and single space in Industries Served");
        //     return;
        // }
        // else if (!businessTypesServed) {
        //     toast.error("Please enter business types Served");
        //     return;
        // }
        // else if (businessTypesServed.length <= 2) {
        //     toast.error("Business Types Served should be maximum 3 character")
        //     return;
        // }
        // else if (!businessTypesServed.match(regex)) {
        //     toast.error("Please enter only character and single space in business Types Served");
        //     return;
        // }
        // else if (!certifications) {
        //     toast.error("Please enter certifications");
        //     return;
        // }
        // else if (certifications.length <= 2) {
        //     toast.error("Certifications should be maximum 3 character")
        //     return;
        // }
        // else if (!certifications.match(regex)) {
        //     toast.error("Please enter only character and single space in Certifications");
        //     return;
        // }
        if (existingCertificationsId?.includes(11) && !certificationOtherDescription) {
            toast.error("please enter other certificate description");
            return;
        }
        let params = {
            posting_title: postingtitle.trim(),
            response_deadline_date: responseDeadline,
            award_date: awardDate ? awardDate : "",
            description: description.trim(),
            currency: currency ? currency : "",
            opportunity_amount: contractAmount ? contractAmount : 0,
            contract_length: contractLength ? contractLength : null,
            industry_group: industryGroupId ? industryGroupId : 0,
            industry_sub_group: industrySubGroupId ? industrySubGroupId : 0,
            specific_service_locations_required: specificServiceLocationRequired,
            hq_location: serviceLocation,
            business_ownership_type: ownershipTypeId,
            diversity_status: driversSupplierDescriptionId,
            existing_certificates: existingCertificationsId,
            service_licenses_required: serviceLicensesRequires,
            opportunity_media: photoInfo?.url ? photoInfo?.url : "",
            image_name: photoInfo?.name ? photoInfo?.name : "",
            image_size: photoInfo?.size ? photoInfo?.size : 0,
            image_mime_type: photoInfo?.format ? photoInfo?.format : "",
            jv_opportunities: checkboxValuesJV ? checkboxValuesJV : "0",
            second_tier_jv_opportunities: checkboxValuesSecondTier ? checkboxValuesSecondTier : "0",
            country_id: countryId ? countryId : null,
            province_id: provinceId ? provinceId : null,
            work_preference: parseInt(workPreference) ? parseInt(workPreference) : 1,
            opportunity_trade_membership: tradeMemberShipId ? tradeMemberShipId : "",
        }

        if (companyOtherDescription) {
            params = {
                ...params,
                diverse_supplier_description_other: companyOtherDescription,
            }
        }
        if (certificationOtherDescription) {
            params = {
                ...params,
                existing_certificates_other: certificationOtherDescription,
            }
        }

        if (post_opportunity_count >= 20 && choosePlan === "Bronze" && chooseBusinessType === 2) {
            showToast("Current plan bronze. Please upgrade your membership plan ")
        }
        else if (post_opportunity_count >= 25 && choosePlan === "Gold" && chooseBusinessType === 2) {
            showToast("Current plan gold. Please upgrade your membership plan")
        }
        else if (chooseBusinessType === 1 && post_opportunity_count >= 20 && choosePlan === "Bronze") {
            showToast("Current plan bronze. Please upgrade your membership plan ")
        }
        else if (chooseBusinessType === 1 && post_opportunity_count >= 25 && choosePlan === "Gold") {
            showToast("Current plan gold. Please upgrade your membership plan")
        }


        else {
            dispatch(addOpportunity({
                ...params, cb(res) {
                    if (res.status) {
                        props.setActiveTab("activeOpportunity");
                    }
                    else {
                    }
                }
            }), [post_opportunity_count])
        }
    }


    //onDrop
    const onDrop = useCallback(acceptedFiles => {
        const imageFile = acceptedFiles[0];
        if (acceptedFiles[0] === undefined) {
            toast.error("Please select a valid image.")
            return false;
        }
        else if (!imageFile.name.match(/\.(jpg|jpeg|png|gif|svg|pdf|doc|docx)$/)) {
            toast.error("Please select a valid image.");
            return false;
        }

        let params = {
            photo: imageFile,
        }

        dispatch(addPostOpportunityUploadPhotoOrPdf({
            ...params, cb(res) {
                if (res.status) {
                    setPhotoUpload(res?.data?.payload?.url);
                    setPhotoInfo(res?.data?.payload)
                }
                else {
                }
            }
        }))

    }, [])

    const { getRootProps, getInputProps } =

        useDropzone({
            onDrop,
            accept: {
                'image/jpeg': [],
                'image/jpg': [],
                'image/png': [],
                'application/pdf': [],
                'application/msword': [], // .doc files
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': []
            }
        });


    useEffect(() => {
        TradeMembership();
        allCountry();
        provinceList();
        industry();
        businessOwnership();
        SupplierDescription();
        ExistingCertification();
        getAllCurrienciesAction();
        companyProfileInfo();

    }, []);

    return (
        <>
            <div className='newPostOpportunity'>
                <form onSubmit={(e) => handleSubmit(e)} className='editprofileForm_ row'>
                    <h6 className='postHeading mb-4'><b>Post Opportunity</b> </h6>
                    <div className='row'>
                        <div className='form-group col-lg-6 col-md-12 mb-5'>
                            <label className='postopportunity_label'> Opportunity Title<span className='ColoredText'>*</span></label>
                            <input type='text' name="postingTitle" className='customFormControl' placeholder='Enter Title' value={postingtitle} onChange={e => setPostingtitle(e.target.value.slice(0, 30))} />
                        </div>
                        <div className='form-group col-lg-6 col-md-12 mb-5'>
                            <label className='postopportunity_label'>Response Deadline </label>
                            <div className='position-relative opportunityDatePicker'>
                                <DatePicker
                                    className='customFormControl'
                                    selected={responseDeadline}
                                    onChange={onChangeDateResponse}
                                    // maxDate={moment().toDate()}
                                    dateFormat="MMMM d, yyyy" // Format as "Month Day, Year"
                                    placeholderText="Month Day, Year" // Example placeholder text
                                />
                                <img src={Images.calanderIcon} alt='calendar' className='img-fluid inputIcon newCalander' />
                            </div>
                        </div>

                    </div>
                    <div className='row'>
                        <div className='form-group col-md-12 mb-3'>
                            <label className='postopportunity_label'>Description of Opportunity <span className='ColoredText'>*</span></label>
                            <textarea value={description} onChange={e => setDescription(e.target.value)} name="description" type='text' className='customFormControl' placeholder='' rows='7'></textarea>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='form-group col-md-2 mb-5'>
                            <label className='postopportunity_label'>Currency</label>
                            <select className="cateSelectbox" required="" value={currency} onChange={(e) => { setCurrency(e.target.value) }} >
                                <option value="" >Select</option>
                                {allCurriencies.length > 0 ?
                                    <>
                                        {
                                            allCurriencies.map((data, index) => {
                                                return <option value={data.code} key={index}>{data.code}</option>
                                            })
                                        }
                                    </>
                                    :
                                    null
                                }
                            </select>
                        </div>
                        <div className='form-group col-lg-5 col-md-6 mb-5'>
                            <label className='postopportunity_label'>Opportunity Amount</label>
                            <input onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} value={contractAmount} onChange={e => setContractAmount(e.target.value)} type="number" className='customFormControl' pattern="[0-9]+" />
                        </div>
                        <div className='form-group col-lg-5 col-md-6 mb-5'>
                            <label className='postopportunity_label'>Opportunity Length</label>

                            <select className="cateSelectbox" required="" value={contractLength} onChange={(e) => { setContractLength(e.target.value) }}>
                                <option value="">Select</option>
                                <option value="1">Yearly</option>
                                <option value="2">Monthly</option>
                                <option value="3">Weekly</option>
                                <option value="4">Daily</option>
                                <option value="5">Hourly</option>
                                <option value="0">N/A</option>
                            </select>
                        </div>
                    </div>
                    <hr />
                    <h6 className='postHeading pt-3 mb-3'><b>Company Details</b> </h6>
                    <div className='row'>
                        <div className='form-group col-md-6 mb-5'>
                            <label className='postopportunity_label'>Industry Group <span className='ColoredText'>*</span></label>
                            <select className="cateSelectbox" required="" value={industryGroupId} onChange={(e) => { handleChange(e.target.value, "industry") }}
                            >
                                <option value="" >Select Industry Groups</option>
                                {industryGroup?.length > 0 ?
                                    <>
                                        {industryGroup.map((data, index) => {
                                            return <option value={data.id} key={index}>{data.name}</option>
                                        })}
                                    </>
                                    : ""}
                            </select>
                        </div>
                        <div className='form-group col-md-6 mb-5'>
                            <label className='postopportunity_label'>Sub Industry Group </label>
                            <select className="cateSelectbox" required="" value={industrySubGroupId}
                                onChange={(e) => { handleChange(e.target.value, "subindustry"); }}
                            >
                                <option value="">Select Industry Sub Groups</option>
                                {subIndustryGroup?.length > 0 ?
                                    <>
                                        {subIndustryGroup.map((data, index) => {
                                            return <option value={data.id} key={index}>{data.name}</option>
                                        })}
                                    </>
                                    : ""}
                            </select>
                        </div>


                        <div className='form-group col-md-6 mb-5'>
                            <label className='postopportunity_label'>Business Location <span className='ColoredText'>*</span></label>
                            {/* <input onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} value={contractAmount} onChange={e => setContractAmount(e.target.value)} type="number" className='customFormControl' pattern="[0-9]+" placeholder='Country, State and City' /> */}
                            <select
                                className="cateSelectbox"
                                required=""
                                value={countryId}
                                onChange={(e) => {
                                    allCountry(e.target.value, "country");
                                }}
                            >
                                <option value="" selected>Select Business Location</option>
                                {getAllcountry?.length > 0 ? (
                                    <>
                                        {getAllcountry.map((option, index) => (
                                            <option value={option?.id} key={index}>{option?.name}</option>
                                        ))}
                                    </>
                                ) : (
                                    ""
                                )}
                            </select>
                        </div>

                        <div className='form-group col-md-6 mb-5'>
                            {/* {countryId ? (
                                <> */}
                            <div className="location_ mt-2 mb-2 ">
                                <h5 className="postopportunity_label mb-2">
                                    Business Area
                                </h5>
                                <select
                                    className="cateSelectbox"
                                    required=""
                                    value={provinceId}
                                    onChange={(e) => {
                                        handleChangedCountry(e.target.value, "province");
                                    }}
                                >
                                    <option value="">Select Business Area</option>
                                    {getAllProvince?.length > 0 ? (
                                        <>
                                            {getAllProvince.map((option, index) => (
                                                <option value={option?.id} key={index}>
                                                    {option?.name}
                                                </option>
                                            ))}
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </select>
                            </div>
                            {/* </>
                            ) : (
                                <></>
                            )} */}
                        </div>

                        {/* <div className='form-group col-md-6 mb-4'>
                        <label className='postopportunity_label'>Business Area </label>
                        <select className="cateSelectbox" required="" value={industrySubGroupId}
                            onChange={(e) => { handleChange(e.target.value, "subindustry"); }}
                        >
                            <option value="">Select Industry Sub Groups</option>
                            {subIndustryGroup?.length > 0 ?
                                <>
                                    {subIndustryGroup.map((data) => {
                                        return <option value={data.id}>{data.name}</option>
                                    })}
                                </>
                                : ""}
                        </select>
                    </div> */}
                        <div className='form-group col-md-6 mb-4'>
                            <label className='postopportunity_label'>Work Preference </label>
                            <select className="cateSelectbox" required="" value={workPreference} onChange={(e) => { setWorkPreference(e.target.value) }}>

                                <option value="1">Onsite</option>
                                <option value="2">Remote</option>
                                <option value="3">Hybrid</option>
                            </select>
                        </div>
                    </div>
                    {/* <div className='row'>
                    <div className='form-group col-md-6 mb-5'>
                        <label className='postopportunity_label'>Service Location</label>
                        <PlacesAutocomplete
                            value={serviceLocation}
                            onChange={setServiceLocation}
                            onSelect={handleSelect}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                    <input
                                        {...getInputProps({
                                            placeholder: 'Search Places ...',
                                            className: ' customFormControl location-search-input form-control textArea',
                                        })}
                                    />
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map(suggestion => {
                                            const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                            return (
                                                <div
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </div>
                    <div className='form-group col-md-6 mb-5'>
                        <label className='postopportunity_label'>Specific Service Location Required?</label>
                        <div className="form-group col-md-12 mt-3 d-flex">
                            <div className="checkmark_ radioCheck_" >
                                <input type="radio" id="Location" className="businessType_" defaultChecked="{true}" value="true" name="radio-group" onChange={() => { setSpecificServiceLocationRequired(true) }} />
                                <label className="labelTxt_" for="Location">
                                    <span className='innerSubtextSmall'>Yes</span>
                                </label>
                            </div>
                            <div className="checkmark_ radioCheck_" >
                                <input type="radio" id="useriD" className="businessType_" value="false" name="radio-group" onChange={() => { setSpecificServiceLocationRequired(false) }} />
                                <label className="labelTxt_" for="useriD">
                                    <span className='innerSubtextSmall'>No</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div> */}
                    <div className='row'>
                        <div className='form-group col-md-6 mb-5 multiselect__ '>
                            <label className="headingSmall_ mb-2" htmlFor="">Diverse Status</label>
                            {companyDescription &&
                                <Multiselect
                                    options={companyDescription} // Options to display in the dropdown
                                    selectedValues={selected} // Preselected value to persist in dropdown
                                    onSelect={handleChanges} // Function will trigger on select event
                                    onRemove={handleChanges} // Function will trigger on remove event
                                    displayValue="name" // Property name to display in the dropdown options
                                />
                            }
                        </div>
                        {driversSupplierDescriptionId?.includes(16) ?

                            <div className='form-group col-md-6 mb-5'>
                                <label className="headingSmall_ mb-2" htmlFor="">Diverse Status Other</label>
                                <input name="companyDescription" type='text' className='customFormControl' value={companyOtherDescription} placeholder='Other Description' disabled="" onChange={e => setCompanyOtherDescription(e.target.value)} />
                            </div> : ""

                        }
                        <div className='form-group col-md-6 mb-5'>
                            <label className="headingSmall_ mb-2" htmlFor="">Business Ownership type</label>
                            <select className="cateSelectbox" required="" value={ownershipTypeId} onChange={(e) => { businessOwnership(e.target.value) }}>
                                <option value="">Select Business Ownership type</option>
                                {ownershipType?.length > 0 ?
                                    <>
                                        {ownershipType?.map((data, index) => {
                                            return <option value={data?.id} key={index}>{data.name}</option>
                                        })}

                                    </>
                                    : ""}
                            </select>
                        </div>
                    </div>
                    <div className='row mb-4'>
                        {/* <div className='form-group col-md-6 mb-3'>
                        <label className='postopportunity_label'>Existing Certifications</label>
                        {existingCertificationData &&
                            <Multiselect
                                options={existingCertificationData} // Options to display in the dropdown
                                selectedValues={selectedCertifications} // Preselected value to persist in dropdown
                                onSelect={handleChangesCertifications} // Function will trigger on select event
                                onRemove={handleChangesCertifications} // Function will trigger on remove event
                                displayValue="name" // Property name to display in the dropdown options
                            />
                        }
                    </div> */}
                        {/* <div className='form-group col-md-6 mb-3'>
                        <label className='postopportunity_label'>Service Licenses Requires?</label>
                        <div className="form-group col-md-12 mt-3 d-flex">
                            <div className="checkmark_ radioCheck_" >
                                <input type="radio" id="Service" className="businessType_" defaultChecked="{true}" value="true" name="radio-group2" onChange={() => { setServiceLicensesRequires(true) }} />
                                <label className="labelTxt_" for="Service">
                                    <span className='innerSubtextSmall'>Yes</span>
                                </label>
                            </div>
                            <div className="checkmark_ radioCheck_" >
                                <input type="radio" id="ServiceLicenses" className="businessType_" value="false" name="radio-group2" onChange={() => { setServiceLicensesRequires(false) }} />
                                <label className="labelTxt_" for="ServiceLicenses">
                                    <span className='innerSubtextSmall'>No</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div> */}
                        <div className='form-group col-md-6 mb-3'>
                            <label className='postopportunity_label'>Open to Joint Venture Opportunities </label>
                            <div className="form-group col-md-12 mt-3 d-flex">
                                <div className="checkmark_ radioCheck_">
                                    <input
                                        type="checkbox"
                                        id="Yes"
                                        value="1"
                                        checked={checkboxValuesJV.includes("1")}
                                        onChange={handleCheckboxChangeJV}
                                        className="checkBox me-2"
                                    />
                                    <label
                                        htmlFor="Yes"
                                        className="checkBoxTxt align-items-start align-items-md-center"
                                    >
                                        <span>Yes</span>
                                    </label>
                                </div>
                                <div className="checkmark_ radioCheck_">
                                    <input
                                        type="checkbox"
                                        id="No"
                                        value="2"
                                        checked={checkboxValuesJV.includes("2")}
                                        onChange={handleCheckboxChangeJV}
                                        className="checkBox me-2"
                                    />
                                    <label
                                        htmlFor="No"
                                        className="checkBoxTxt align-items-start align-items-md-center"
                                    >
                                        <span>No</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='form-group col-md-6 mb-3'>
                            <label className='postopportunity_label'>Open to Second Tier Opportunities</label>
                            <div className="form-group col-md-12 mt-3 d-flex">
                                <div className="checkmark_ radioCheck_">
                                    <input
                                        type="checkbox"
                                        id="Yes1"
                                        value="1"
                                        checked={checkboxValuesSecondTier.includes("1")}
                                        onChange={handleCheckboxChangeSecondTier}
                                        className="checkBox me-2"
                                    />
                                    <label
                                        htmlFor="Yes1"
                                        className="checkBoxTxt align-items-start align-items-md-center"
                                    >
                                        <span>Yes</span>
                                    </label>
                                </div>
                                <div className="checkmark_ radioCheck_">
                                    <input
                                        type="checkbox"
                                        id="No1"
                                        value="2"
                                        checked={checkboxValuesSecondTier.includes("2")}
                                        onChange={handleCheckboxChangeSecondTier}
                                        className="checkBox me-2"
                                    />
                                    <label
                                        htmlFor="No1"
                                        className="checkBoxTxt align-items-start align-items-md-center"
                                    >
                                        <span>No</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='form-group col-md-6 mb-3 multiselect__ selectIndex'>
                            <label className='postopportunity_label'>Diversity Certifications</label>

                            {existingCertificationData &&
                                <Multiselect
                                    options={existingCertificationData} // Options to display in the dropdown
                                    selectedValues={selectedCertifications} // Preselected value to persist in dropdown
                                    onSelect={handleChangesCertifications} // Function will trigger on select event
                                    onRemove={handleChangesCertifications} // Function will trigger on remove event
                                    displayValue="name" // Property name to display in the dropdown options
                                />
                            }

                        </div>
                        {existingCertificationsId?.includes(11) ?
                            <div className='form-group col-md-12 mb-3'>
                                <input name="companyDescription" type='text' className='customFormControl' value={certificationOtherDescription} placeholder='Other Description' disabled="" onChange={e => setCertificationOtherDescription(e.target.value)} />
                            </div> : ""

                        }
                        <div className='form-group col-md-6 mb-3 multiselect__'>
                            <label className='postopportunity_label'>Trade Memberships</label>
                            {tradeMemberShipData && (
                                <>
                                    <Multiselect
                                        options={tradeMemberShipData} // Options to display in the dropdown
                                        selectedValues={selecteTrade} // Preselected value to persist in dropdown
                                        onSelect={handleChangesTradeMembership} // Function will trigger on select event
                                        onRemove={handleChangesTradeMembership} // Function will trigger on remove event
                                        displayValue="name" // Property name to display in the dropdown options
                                        className="selectIndex"
                                    />
                                    <i class="las la-plus inputIcon"></i>

                                </>
                            )}
                            {tradeMemberShipId?.includes(11) ?
                                <div className='form-group col-md-12 mb-3'>
                                    <label className="headingSmall_ mt-3 mb-2" htmlFor="">Trade Memberships Other</label>
                                    <input name="companyDescription" type='text' className='customFormControl' value={otherTradeMemberships} placeholder='Other Trade Memberships' disabled="" onChange={e => setOtherTradeMemberships(e.target.value)} />
                                </div> : ""
                            }
                        </div>
                    </div>
                    <div className='row'>
                        <h6 class="postHeading"><b>Opportunity Media</b> </h6>
                        <div className='form-group col-md-12 mb-3'>
                            {photo?.length > 0 ?
                                "" :
                                <div className="uploadImgeboxNew">

                                    <div {...getRootProps({ className: "dropzone" })}>
                                        <input {...getInputProps()} />
                                        <img src={Images.uploadIcon} alt="uploadIcon" className='img-fluid uploadfileIcon hjgfhjfhj' />
                                        <p className='uploadbtn'>Drag & Drop or <span className='ColoredText'>browse</span></p>
                                        <p className='uploadText mt-2'>Supports: JPEG, JPG, PNG, PDF, DOC or DOCX</p>
                                    </div>
                                </div>
                            }
                            {photo?.length > 0 ?
                                <aside >
                                    <h4 className='headingSmall_'> Uploaded Files</h4>
                                    <ul className='uploadProfile_'>
                                        {photoInfo.format == "application/pdf" ?
                                            <img className="w3-round" src={Images.PDFImage} />
                                            : photoInfo.format == "application/msword" || photoInfo.format == "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ?
                                                <img className="w3-round" src={Images.DocImage} />
                                                : <img className="w3-round" src={photo} />
                                        }
                                        <div className='profileDetail_'>
                                            <h5 className='profileboldHead'>{photoInfo.name}</h5>
                                            <p className='profileboldgraph'> {(photoInfo?.size / (1024 * 1024)).toFixed(2)} MB</p>
                                        </div>
                                        <i className="fa fa-trash text-red" onClick={removeFile(photo)}></i>
                                    </ul>
                                </aside>
                                : ""
                            }
                        </div>
                    </div>
                    <button className='submitopportunity opportunityButton mt-2'>
                        {opportunitySelector?.opportunity_loader && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}  Submit Opportunity</button>
                </form>
            </div>
        </>
    )
}
export default AddPostOpportunity

