import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { requestEnterpriseAccount } from "../../../redux/slices/auth";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { useAuthSelector } from "../../../redux/selector/auth";
import 'react-phone-input-2/lib/style.css'

function EnterpriseSignUp() {
  const auth = useAuthSelector();
  const [phone_no , setPhone_no] = useState("")
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const handleSubmit = (e) => {
    e.preventDefault();
    const params = new FormData(e.target);

    // Use get method to retrieve values based on field names
    const name = params.get("name");
    const email = params.get("email");
    const company_description = params.get("company_description");
    const pattern = /^ *$/;
    const namePattern =  /^[A-Za-z]+$/;
    toast.dismiss();

    if (!name.match(namePattern) || name.length < 3) {
      toast.error("Enter a valid name ");
      return;
    }
    else if (
      email &&
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      toast.error(" Enter A Valid Email");
      return;
    }else if (email.trim() === "") {
      toast.error(" Enter A Valid Email");
      return;
    } else if (phone_no.phone === undefined) {
      toast.error("Plese enter phone number");
      return;
    }  
    else if (phone_no.phone.length < 11) {
      toast.error("Plese enter valid phone number");
      return;
      
    }  
    else if (!company_description || company_description.length < 4 || pattern.test(company_description)) {
      toast.error("Enter A Valid Company Description");
      return;
    } 
    const dataToSend = {
      name,
      email,
      company_description,
      phone_no: "+"+phone_no.phone,
    };

    dispatch(
      requestEnterpriseAccount({
        ...dataToSend,
        cb(res) {
          navigate('/');
        },
      })
    );
    // Now you can dispatch the values or perform any other actions with them
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <div className="enterPriceSignUp">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="containerBox">
              <h6 class="memberHead_ mb-4">Enterprise Business <b>Members</b> </h6>
              <div className="row">
                <div class="form-group col-lg-4 col-md-12 mb-3">
                  <label class="postopportunity_label">Name</label>
                  <input type="text" name="name" class="customFormControl" placeholder="Name" />
                </div>
                <div class="form-group col-lg-4 col-md-12 mb-3">
                  <label class="postopportunity_label">Email</label>
                  <input type="email" name="email" class="customFormControl" placeholder="Email" />
                </div>
                <div class="form-group col-lg-4 col-md-12 mb-3">
                  <label class="postopportunity_label">Phone</label>
                  <PhoneInput onChange={(phone) => { setPhone_no({ phone }) }} country='us'  className="inputTel"/>
                </div>
                <div class="form-group col-lg-12 mb-3">
                  <label class="postopportunity_label">Company Description</label>
                  <textarea rows="7" name="company_description" className="customFormControl"></textarea>
                </div>
                <div class="form-group col-lg-12 mb-5 text-center">
                  <button className="primaryBtnSmall" type="submit" disabled={auth.loading}>
                    {auth?.loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>Submit</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        {/* <label>Name</label>
        <input type="text" name="name" placeholder=""></input>
        <label>Email</label>
        <input type="text" name="email" placeholder=""></input>
        <label>Company Description</label>
        <input type="text" name="company_description" placeholder=""></input>
        <label>Phone Number</label>
        <input type="number" name="phone_no" placeholder=""></input>
        <button className="primaryBtnSmall" type="submit" disabled={loading}>
          {loading && (
            <span className="spinner-border spinner-border-sm"></span>
          )}
          <span>Submit</span>
        </button> */}
      </form>
     
    </>
  );
}

export default EnterpriseSignUp;
