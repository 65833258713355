import React, { useEffect, useState } from "react";
import * as Images from "../../../utilities/images";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { addContactUs } from "../../../redux/slices/contactus";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuthSelector } from "../../../redux/selector/auth";
import { addSuggestion } from "../../../redux/slices/resource";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import { useResourcesSelector } from "../../../redux/selector/resources";
import { useContactUsSelector } from "../../../redux/selector/contactus";

const ContactUs = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location?.state?.path;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone_no, setPhone_no] = useState("")
  const [message, setMessage] = useState("");
  const [company, setCompany] = useState("");
  const [queryType, setQueryType] = useState("")

  const resouceSelector = useResourcesSelector()
  const contactSelector = useContactUsSelector()

  const auth = useAuthSelector();
  const companyName =
    auth?.userStep?.user?.user_company_information?.company_name;

  //contact us form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name) {
      toast.error("Please enter name");
      return;
    } else if (name.trim() === "") {
      toast.error("Please enter name ");
      return;
    } else if (!email) {
      toast.error("Please enter email");
      return;
    } else if (
      email &&
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      toast.error("Please enter valid email address");
      return;
    } else if (!company) {
      toast.error("Please enter company name");
      return;
    } else if (company.trim() === "") {
      toast.error("Please enter company");
      return;
    } else if (!queryType) {
      toast.error("Please choose query type");
      return;
    }
    else if (!message) {
      toast.error("Please enter message");
      return;
  } else if (message.trim() === "") {
      toast.error("Please enter message");
      return;
    }
    else if(message.length <3){
      toast.error("Minimum 3 characters are required");
      return;
    }
    let params = {
      name: name,
      email: email,
      message: message,
      type: queryType,
      company: company
    }
    if (path === "suggestion-box") {

      params.phone_no = "+" + phone_no.phone;
      dispatch(
        addSuggestion({
          ...params,
          cb(res) {
            if (res?.data) {
              setName("");
              setEmail("");
              setMessage("");
              setCompany("")
              setPhone_no("")
              setQueryType("")
              window.scrollTo(0, 0);
            }
          },
        })
      );
    } else {
      dispatch(
        addContactUs({
          ...params,
          cb(res) {
            if (res?.data) {
              setName("");
              setEmail("");
              setMessage("");
              setCompany("")
              setQueryType("")
              window.scrollTo(0, 0);
            }
          },
        })
      );
    }
  };


  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "ContactUs";
  }, []);
  return (
    <>
      <div className="helpDesk">
        <section className="homeBanner">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-12">
                <div className="aboutContainer">
                  <div className="">
                    {path === "suggestion-box" ? (
                      <h3 className="bannerHead mb-0">
                        Suggestion<strong> Box!</strong>
                      </h3>
                    ) : (
                      <h3 className="bannerHead mb-0">
                        Contact<strong> Us!</strong>
                      </h3>
                    )}
                    <p className="bannerTxt mb-0">
                      {path === "suggestion-box"
                        ? "Successful ventures are always on an evolutionary journey.  OPPSWell wants to hear how to better serve its Members"
                        : "Open communication and building a connected community is paramount to OPPSWell"}
                      {/* <b>GetOPP$</b> is a matchmaking service for all <br />companies looking for contracting opportunities. */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="aboutMenus">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul className="aboutMenusContainer">
                  <li className="aboutItem">
                    <button>
                      <Link to="/privacy-policy" className="aboutLinks">
                        PRIVACY POLICY
                      </Link>
                    </button>
                  </li>
                  <li className="aboutItem">
                    <button>
                      <Link to="/terms-of-service" className="aboutLinks">
                        TERMS OF SERVICE
                      </Link>
                    </button>
                  </li>

                  <li className="aboutItem">
                    <button>
                      <Link to="/help-desk" className="aboutLinks">
                        {" "}
                        HELP Desk
                      </Link>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="bgWhite pt-5 pb-5">
        <section className="">
          <div className="container">
            {/* <div className="row justify-content-center">
                            <div className='memeberHead_ col-md-10 '>
                                <h3 className='memberHead_'>Contact<strong> Us!</strong></h3>
                                
                                    <h5 className='discription_'>Leveraging Our Contact Channels for Inquiries</h5>
                            </div>
                        </div> */}
            <div className="memberHomeImage_ mt-5 mb-4">
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="row">
                  <div className="col-md-6 col-lg-5">
                    <div className="contactLeft">
                      <h3 className="contactHead_">
                        {path === "suggestion-box"
                          ? "Make a Suggestion"
                          : "General Inquires"}
                      </h3>
                      <p className="paragraph_">
                        {path === "suggestion-box"
                          ? "So what's on your mind?  OPPSWell is a great listener and we cannot wait for our Members to suggest away!"
                          : "Thanks for reaching out to us! We are here for all things OPPSWell. Tell us your thoughts and inquiries - they matter!"}
                      </p>
                      <div className="contactInfo_">
                        <h5 className="innerSubtext mb-3">
                          <img
                            className="contactIcon"
                            src={Images.messageImg}
                          />{" "}
                          <span>
                            {path === "suggestion-box"
                              ? "suggestions@oppswell.com"
                              : "info@oppswell.com"}
                          </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 offset-lg-2 col-lg-5">
                    <div className="contactRight">
                      <div className="row">
                        {/* <div className=" mb-4 form-group">
                          <h2>{companyName}</h2>
                        </div> */}
                        <div className=" mb-4 form-group">
                          <input
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            type="text"
                            className="form-control "
                            name="text"
                            placeholder="Name"
                          />
                        </div>
                        <div className="mb-4 form-group">
                          <input
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            type="email"
                            className="form-control"
                            name="email"
                            placeholder="Email"
                          />
                        </div>
                        {/* <div class="form-group col-lg-12 col-md-12 mb-4">
                          <label class="postopportunity_label">Phone</label>
                          <PhoneInput onChange={(phone)=>{setPhone_no({phone})}} enableSearch className="inputTel" placeholder="Phone"/>
                        </div> */}
                        <div className="mb-4 form-group">
                          <input
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                            type="text"
                            className="form-control "
                            name="company"
                            placeholder="Company"
                          />
                        </div>
                        {
                          path === "suggestion-box" ?
                            <div class="mb-4 form-group">
                              {/* <label class="postopportunity_label">Phone</label> */}
                              <PhoneInput onChange={(phone) => { setPhone_no({ phone }) }} country='us' className="inputTel newinputTel" placeholder="Phone" />
                            </div>
                            : ""
                        }

                        <div className="mb-4 form-group">
                          <select

                            className="form-control generalQue"
                            // required=""
                            onChange={(e) => {
                              setQueryType(e.target.value);
                            }}
                            value={queryType}
                          >
                            {path === "suggestion-box" ? <>
                              <option value="" disabled>Select Suggestion type</option>
                              <option value="1">General Suggestion</option>
                              <option value="2">New Website Feature</option>
                              <option value="3">Search</option>
                              <option value="4">Business Resources</option>
                              <option value="5">Other</option>
                            </> :
                              <>
                                <option value="" disabled>Select Query type</option>
                                <option value="General Question">General Question</option>
                                <option value="Membership">Membership</option>
                                <option value="Account Support">Account Support</option>
                                <option value="Other">Other</option>
                              </>
                            }
                          </select>
                        </div>
                        <div className="mb-4 form-group">
                          <textarea
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            className="form-control "
                            cols="50"
                            rows="3"
                            placeholder="Message"
                          ></textarea>
                        </div>
                        <div className="form-group">
                          <button type="submit" className="primaryBtn">
                            {contactSelector.loading || resouceSelector.loading ? (
                              <span className="spinner-border spinner-border-sm"></span>
                            ) : ""}
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ContactUs;
