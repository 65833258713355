import React, { useEffect, useState } from 'react'
import * as Images from "../../../utilities/images";
import { getLabels, getAllCompanyInfo, createDraft, messageRequest, getChatDraft } from "../../../redux/slices/messages";
import { uploadAllDocuments } from "../../../redux/slices/documents";
// import { useSocketSelector } from "../../../redux/selector/socket";
import { useDispatch } from 'react-redux';
// import { socket } from "../../../app/components/socket";
import { useAuthSelector } from "../../../redux/selector/auth";
import { toast } from 'react-toastify';
import Drafts from '../messageDrafts';
import { useNavigate } from 'react-router-dom';
import { testFn } from '../../../redux/slices/messages';
import { closeSocket, getSocket } from '../../components/socketNew';

const ComposeMessage = (props) => {
    // const socketSelector = useSocketSelector();
    // const socketInstance = socket();
    const authSelector = useAuthSelector()
    const userInfo = authSelector?.userInfo?.user
    const dispatch = useDispatch();
    const [searchInput, setSearchInput] = useState(props?.chatDraft?.company_details?.company_name ? props?.chatDraft?.company_details?.company_name : props?.chatConnectionCompanyName?.user_company_information?.company_name ? props?.chatConnectionCompanyName?.user_company_information?.company_name : props?.companyinfo?.companyDetails?.company_name ? props?.companyinfo?.companyDetails?.company_name : props?.opportunityCompanyName ? props?.opportunityCompanyName : "");
    const [getAllLabel, setGetAllLabel] = useState("");
    const [selectedLabel, setSelectedLabel] = useState(props?.chatDraft?.label ? props?.chatDraft?.label : "");
    const [messages, setMessages] = useState(props?.chatDraft?.message ? props?.chatDraft?.message : "");
    const navigate = useNavigate()

    const [customLabel, setCustomLabel] = useState("");
    const [loading, setLoading] = useState(false);
    const [allCompanyList, setAllCompanyList] = useState("");
    const [subject, setSubject] = useState(props?.chatDraft?.subject ? props?.chatDraft?.subject : "")
    // const [selectedCompaniesId, setSelectedCompaniesId] = useState(props?.companyIdDraft ? props?.companyIdDraft : props?.data?.company_id ? props?.data?.company_id : props?.companyinfo?.companyDetails?.id ? props?.companyinfo?.companyDetails?.id : props?.opportunityCompanyName ? props?.opportunityCompanyName : props?.data?.company_id ? props?.data?.company_id  : props?.chatDraft?.company_id ? props?.chatDraft?.company_id:"");

    const [saveDraftToggle, setSaveDraftToggle] = useState(false)
    const [selectedCompaniesId, setSelectedCompaniesId] = useState(props?.chatDraft?.company_id ? props?.chatDraft?.company_id : props?.chatConnectionCompanyName?.user_company_information?.id ? props?.chatConnectionCompanyName?.user_company_information?.id : "");

    // Company id and Sender id came out of Redux

    const sender_id = userInfo?.id
    const receiver_id = props?.chatDraft?.company_details?.user_id ? props?.chatDraft?.company_details?.user_id : props?.chatConnectionCompanyName?.id ?
        props?.chatConnectionCompanyName?.id : ""
    // useEffect(()=>{
    //     return ()=>{closeSocket()}
    // },[])
    const firstMesage = (e) => {
        const socketInstance = getSocket();
        e.preventDefault();
        const selectedId = props.chatHeadListing?.data?.find(item => item?.company_id == selectedCompaniesId)

        if (!selectedCompaniesId) {
            toast.error("Please select company")
            return;
        }
        else if (!messages) {
            toast.error("Please enter message");
            return;
        }
        else if (!subject) {
            toast.error("Please enter subject");
            return;
        }
        if (props?.chatType === 2) {
            let params = {
                receiver_id: receiver_id,
                company_id: selectedCompaniesId,
                message_type: 1,
                subject: subject,
                message: messages,
                chat_type: props?.chatType
            }
            if (props?.chatId) {
                params = {
                    ...params,
                    chat_draft_id: props?.chatId
                }
            }
            dispatch(messageRequest({
                ...params,
                cb(res) {
                    if (res?.data) {
                        props?.close()
                        props?.chatDraftsRefresh()
                        toast.success("Message sent successfully")
                        if (res?.msg == "Chat exists already, please take it to the inbox") {
                            navigate(`account-message/${res.data.payload[0].company_id}`)
                        }
                    }
                }
            }))
        }
        else if (props?.chatType === 1) {
            let params = {
                sender_id: sender_id,
                company_id: selectedCompaniesId,
                last_message: messages,
                message_type: 1,
                subject: subject,
                chat_type: props?.chatType
            }
            if (selectedId?.id) {
                params = {
                    ...params,
                    temp_string: selectedId?.temp_string.toString()
                }
            }
            if (props?.chatId) {
                params = {
                    ...params,
                    chat_draft_id: props?.chatId
                }
            }
            socketInstance.emit('first_message', params)
            if (props?.draftChatHead) {
                props?.chatDraftsRefresh()
                // props?.draftChatHead();
                // props?.draftDataList();
                toast.success("Message Sent Sucessfully");
                props?.close()
            }
            else {
                toast.error("Message not sent");
                props.close()
            }
            // socketInstance.on('first_message', (response) => {
            //     if (response) {
            //         props?.chatDraftsRefresh()
            //         // props?.draftFirstMessage(response?.data[0])
            //         // props?.draftDataList()
            //         // props?.chatDraftsRefresh()
            //     }
            // })
        }
        else {
            let params = {
                sender_id: sender_id,
                company_id: selectedCompaniesId,
                last_message: messages,
                message_type: 1,
                subject: subject,
                chat_type: 1
            }
            // if (selectedId?.id) {
            //     params = {
            //         ...params,
            //         temp_string: selectedId?.temp_string.toString()
            //     }
            // }
            socketInstance.emit('first_message', params)
            if (props?.chatHeadSearchRefresh) {
                props?.chatHeadSearchRefresh();
                props?.dataList();
                toast.success("Message Sent Sucessfully");
                props.close()
            }
            else {
                toast.success("Message sent Sucessfully");
                props.close()
            }
            // socketInstance.on('first_message', (response) => {
            //     if (response) {
            //         props?.firstMessage(response?.data[0])
            //         props?.dataList()
            //     }
            // })
        }

    }

    const getDrafts = () => {
        dispatch(getChatDraft({
            cb(res) {
                if (res?.data) {
                    // setChatDraft(res?.data?.payload)
                }
            }
        }))

    };

    // socketInstance.on('first_message', (response) => {
    //     if (response) {
    //         props?.firstMessage(response?.data[0])
    //         props?.dataList()
    //     }
    // })
    //    end work on socket ///////////////

    // apply API for find out all companies to start communication
    const searchAllCompanyList = () => {
        let params = {
            search: searchInput ? searchInput : ""
        }
        dispatch(getAllCompanyInfo({
            ...params, cb(res) {
                if (res?.data) {
                    setAllCompanyList(res?.data?.payload?.data)
                }
            }
        }))
    };

    // apply API for find out all Lebels
    // const getLebelsList = (data) => {
    //     setSelectedLabel(data)
    //     dispatch(getLabels({
    //         cb(res) {
    //             if (res?.data) {
    //                 setGetAllLabel(res?.data?.payload)
    //             }
    //         }
    //     }))
    // };

    // apply API for save data in draft
    const createDrafts = () => {
        const activeTab = localStorage.getItem("messageTab")
        setSaveDraftToggle(true)
        if (!selectedCompaniesId) {
            toast.error("Please select company")
            return;
        }
        if (!subject) {
            toast.error("Please enter subject")
            return;
        }
        let params = {
            company_id: selectedCompaniesId,
            subject: subject,
            chat_type: 1
        }
        if (messages) {
            params = {
                ...params,
                message: messages,
                message_type: 1,
            }
        }
        if (props?.chatId) {
            params = {
                ...params,
                chat_draft_id: props?.chatId,
                chat_type: props?.chatType
            }
        }
        dispatch(createDraft({
            ...params, cb(res) {
                if (res?.data) {
                    if (props?.chatId) {
                        props?.chatDraftsRefresh()
                        props?.close()
                    }
                    else{
                        props?.close()
                    }
                }
            }
        }))
    };

    // selectedCompany function for save company data in state 
    const selectedCompany = (name, id) => {
        setSearchInput(name)
        setSelectedCompaniesId(id)
        setAllCompanyList("")
    }

    // handleChnage function for change company to communication
    const handleChnage = (e) => {
        if (!e.target.value) {
            setSelectedCompaniesId("")
        }
        setSearchInput(e.target.value)
    }


    // useEffect(() => {
    //     getLebelsList(props?.chatDraft?.label ? props?.chatDraft?.label : "");
    //     document.title = "messages";
    //     dispatch(testFn())
    // }, []);

    useEffect(() => {
        if (props?.opportunityCompanyName) {
            setSelectedCompaniesId(props?.opportunityCompanyId)
        }
    })

    useEffect(() => {
        searchAllCompanyList();
    }, [searchInput]);

    return (
        <>
            <form onSubmit={(e) => firstMesage(e)}>
                <div className="dropdown">
                    <div className="business_search mb-4" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        <img className="searchIcon_" src={Images.search_} alt="Search" />
                        <input value={searchInput} onChange={(e) => handleChnage(e)} type="text" className="custoInput" placeholder="Search Company" aria-label="discount code" aria-describedby="basic-addon2" />
                        {searchInput && <button className='inputClose' type="reset" onClick={() => { setSearchInput("") }}> </button>}
                    </div>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        {allCompanyList?.length > 0 ?
                            <>
                                {allCompanyList?.map((data, index) => {
                                    return <li className='composeProfileBox' onClick={() => { selectedCompany(data?.company_name, data?.id) }} key={index}>
                                        <img src={data.company_logo ? data.company_logo : Images.puzzleProfile} alt='companyProfile' className='img-fluid composeProfile' />
                                        <a className="dropdown-item" href="#">
                                            {data?.company_name}
                                        </a>
                                    </li>
                                })}
                            </>
                            : ""}
                    </ul>
                </div>
                <div className='form-group col-md-12 mb-3'>
                    <input name="labelcustom" type='text' className='customFormControl' placeholder='Subject' value={subject} onChange={e => setSubject(e.target.value)}></input>
                </div>
                <div className='form-group col-md-12 mb-3'>
                    <textarea name="email" type='text' className='customFormControl' placeholder='Message' value={messages} onChange={e => setMessages(e.target.value)}></textarea>
                </div>

                {selectedLabel == "Custom Label" ?
                    <div className='form-group col-md-12 mb-3'>
                        <input name="labelcustom" type='text' className='customFormControl' placeholder='Type in a Topic Title for Label' value={customLabel} onChange={e => setCustomLabel(e.target.value)}></input>
                    </div> : ""
                }
                <div className='sendMessage text-center'>
                    <button type="button" className='subsmallBtnblue_ me-2' onClick={() => createDrafts()}
                        disabled={saveDraftToggle ? true : false}
                    >Save in Draft</button>
                    <button className='primaryBtnSmall' type="submit">
                        {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        <span >Send</span>
                    </button>
                </div>
            </form>

        </>
    )
}

export default ComposeMessage