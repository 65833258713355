import React, { useState, useEffect } from "react";
import * as Images from "../../../utilities/images";
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import swal from "sweetalert";
import { createEndrosement, getCompanyInformation } from '../../../redux/slices/companyInformation';
import { Link, useNavigate, useLocation, useParams, useNavigation } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import DatePicker from "react-datepicker";
import { updateRequestConnection, connectionFollow, connectionUnfollow, addConnection, connectionRequsetCancel, connectionRequsetUnfriend } from "../../../redux/slices/connection";
import CustomModal from "../../components/common/shared/CustomModal";
import SubmitEndrosment from "./submitEndrosment";
import moment from "moment";
import { useAuthSelector } from "../../../redux/selector/auth";
import SendMessage from "./sendMessage";
import InfiniteScroll from 'react-infinite-scroll-component';
import { allPostComment, commentLike, deletePost, disableEnableComment, getPostSingleDetails, postFeature, userPostComment, userPostLike } from "../../../redux/slices/dashboard";

const CompanyfrontProfile = () => {
    const { id } = useParams();
    const authSelector = useAuthSelector();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [companyInfo, setCompanyInfo] = useState('');
    const [companyCurrentOpportunity, setCompanyCurrentOpportunity] = useState();
    const [leaveEndorsements, setLeaveEndorsements] = useState(false);
    const [editId, setEditId] = useState("")
    const [leaveEndorsementsCompanyName, setleaveEndorsementsCompanyName] = useState('');
    const [leaveEndorsementsDate, setLeaveEndorsementsDate] = useState(new Date());
    const [leaveEndorsementsDescription, setLeaveEndorsementsDescription] = useState('');
    const [leaveEndorsementsReceiverId, setleaveEndorsementsReceiverId] = useState('');
    const [leaveEndorsementsInfo, setLeaveEndorsementsInfo] = useState('');
    const [key, setKey] = useState(Math.random());
    const [showNestedReply, setShowNestedReply] = useState(false)
    const [nestedReply, setNestedReply] = useState("")
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });

    const chooseBusinessType = authSelector?.userStep?.user?.business_type ?? authSelector?.userStep?.business_type;

    const choosePlan = authSelector?.userStep?.user?.membership_details?.name ?? authSelector?.userStep?.membership_details?.name;


    //featured post data
    const userInfo = authSelector?.userInfo?.user;
    const [allPost, setAllPostList] = useState([]);
    const [userDeatil, setUserDeatil] = useState();
    const [editFlag, setEditFlag] = useState(false);
    const [editPostID, setEditPostID] = useState(false);
    const [post, setPost] = useState("");
    const [allCommentsLoad, setAllCommentsLoad] = useState(false);
    const [mainTags, setMainTags] = useState({
        companies: [],
        users: [],
    })
    const [selectedIds, setSelectedIds] = useState({
        companies: [],
        users: [],
    })
    const [photo, setPhotoUpload] = useState({
        uploadPhoto: "",
        flag: ""
    });
    const [videoUrlOriginal, setVideoUrlOriginal] = useState();
    const [photoInfo, setPhotoInfo] = useState();
    const [page, setPages] = useState(1);
    const [postIndexMoreComments, setPostIndexMoreComments] = useState("");
    const [allCommentList, setAllCommentList] = useState([]);
    const [allCommentData, setAllCommentData] = useState({});
    const [replyFlag, setReplyFlag] = useState(false)
    const [replyName, setReplyName] = useState('')
    const [replyId, setReplyId] = useState(null)
    const [selectedIndex, setSelectedIndex] = useState(null)
    const [postID, setPostID] = useState("");
    const [comment, setComment] = useState("");

    // // API for post Feature ..............
    // const postFetures = (id, flag, index) => {
    //     let params = {
    //         post_id: id,
    //         feature_status: flag,
    //     };
    //     dispatch(
    //         postFeature({
    //             ...params,
    //             cb(res) {
    //                 if (res.status) {
    //                     CompanyInformation(id);
    //                 }
    //             },
    //         })
    //     );
    // };

    // for close Modal
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };

    // company information 
    const CompanyInformation = (id) => {
        let params = {
            id: id,
        }
        dispatch(getCompanyInformation({
            ...params, cb(res) {
                if (res.status) {
                    setUserDeatil(res?.data?.payload?.userDetails?.user_company_information)
                    setCompanyInfo(res?.data?.payload)
                    setAllPostList(res.data?.payload?.feature_post)
                    setCompanyCurrentOpportunity(res?.data?.payload?.companyPostOpportunity?.data)
                    setleaveEndorsementsReceiverId(res?.data?.payload?.companyDetails?.user_details?.user_profile?.user_id)
                }
            }
        }))
    }

    const SubmitEndrosement = (e) => {
        // e.preventDefault();

        if (!leaveEndorsementsDate) {
            toast.error("Please select acclamation Date");
            
            return false;
        }
        else if (!leaveEndorsementsDescription) {
            toast.error("Please enter Description");
            return false;
        }
        let params = {
            company_name: companyInfo?.companyDetails?.company_name,
            project_date: leaveEndorsementsDate,
            receiver_id: leaveEndorsementsReceiverId,
            endorsement: leaveEndorsementsDescription

        }
        dispatch(createEndrosement({
            ...params, cb(res) {
                if (res.status) {
                    setLeaveEndorsementsInfo(res?.data?.payload)
                    setleaveEndorsementsCompanyName("")
                    setLeaveEndorsementsDate("")
                    setLeaveEndorsementsDescription("")
                    setLeaveEndorsements(false)
                    setModalDetail({ show: true, flag: "submitendorsement", });
                    setKey(Math.random());
                }
            }
        }))
    }

    // onchange date set date in the state
    const onChangeDateLeaveEndorsement = (date) => {
        setLeaveEndorsementsDate(date)
    }

    //update request
    const updateRequest = (flag) => {
        let params = {
            id: companyInfo?.connecetionRequestReceived?.id,
            status: flag
        }
        dispatch(updateRequestConnection({
            ...params, cb(res) {
                if (res.status) {
                    CompanyInformation(id)
                }
                else {
                }
            }
        }))
    }

    // API for follow connection
    const handleFollowing = () => {
        let params = {
            receiver_id: companyInfo?.companyDetails?.user_id,
            company_id: companyInfo?.companyDetails?.id
        }
        dispatch(connectionFollow({
            ...params, cb(res) {
                if (res.status) {
                    CompanyInformation(id)
                }
                else {
                }
            }
        }))

    }

    // API for unfollow connection
    const handleUnfollowing = () => {
        swal({
            text: `Are you sure want unfollow?`,
            dangerMode: false,
            buttons: true,
        })
            .then(result => {
                if (result) {
                    let params = {
                        id: companyInfo?.companyDetails?.is_following?.id ? companyInfo?.companyDetails?.is_following?.id : ""
                    }
                    dispatch(connectionUnfollow({
                        ...params, cb(res) {
                            if (res.status) {
                                CompanyInformation(id)
                            }
                            else {
                            }
                        }
                    }))
                }
            });

    }

    const CancleEndrosement = () => {
        setleaveEndorsementsCompanyName("")
        setLeaveEndorsementsDate("")
        setLeaveEndorsementsDescription("")
        setLeaveEndorsements(false)
    }

    //for add connection.........
    const handleSubmit = () => {
        let params = {
            receiver_id: companyInfo?.companyDetails?.user_id,
            company_id: companyInfo?.companyDetails?.id,
            message: "hello"
        }
        dispatch(addConnection({
            ...params, cb(res) {
                if (res.status) {
                    CompanyInformation(id)
                }
            }
        }))
    }


    // handle  page change 
    const handlePageRedirect = (name, id) => {
        if (id) {
            navigate(`/${name}/${id}`)
        }
    }

    //handle view all opp
    const handleActiveOpp = () => {
        navigate("/opportunities")
    }

    // handle redirect page 
    const hanleRequestApplicatio = (data) => {
        navigate(`/apply/${data?.id}`);
    }


    // API for Cancel connect request
    const updatePendingRequest = () => {
        swal({
            text: `Are you sure want cancel request?`,
            dangerMode: false,
            buttons: true,
        })
            .then(result => {
                if (result) {
                    let params = {
                        id: companyInfo?.companyDetails?.company_connection?.id ? companyInfo?.companyDetails?.company_connection?.id : ""
                    }
                    dispatch(connectionRequsetCancel({
                        ...params, cb(res) {
                            if (res.status) {
                                CompanyInformation(id)
                            }
                            else {
                            }
                        }
                    }))
                }
            });

    }

    // API for unfriend connect request
    const updateAcceptRequest = () => {
        swal({
            text: `Are you sure want disconnect connection?`,
            dangerMode: false,
            buttons: true,
        })
            .then(result => {
                if (result) {
                    let params = {
                        id: companyInfo?.companyDetails?.company_connection?.id ? companyInfo?.companyDetails?.company_connection?.id : ""
                    }
                    dispatch(connectionRequsetUnfriend({
                        ...params, cb(res) {
                            if (res.status) {
                                CompanyInformation(id)
                            }
                            else {
                            }
                        }
                    }))
                }
            });
    }

    const handleSubmited = (e, id) => {
        //navigate(`/view-profile`, { state: { id: id } });
        e.preventDefault()
        navigate(`/${"view-profile"}/${id}`);
    };

    const handleMessage = () => {
        if (companyInfo?.companyDetails?.company_connection) {
            
            if (companyInfo?.companyDetails?.company_connection?.status === 2) {
                if (companyInfo?.chat_head_details) {
                    navigate(`/account-message/${companyInfo?.chat_head_details?.id}`)
                } else {
                    setModalDetail({ show: true, flag: "ComposeMessage", });
                    setKey(Math.random());
                }
            } else {
                if (companyInfo?.chatRequest) {
                    if (companyInfo?.chatRequest?.status === 1) {
                        navigate(`/account-message/${companyInfo?.chat_head_details?.id}`)
                    } else if (companyInfo?.chatRequest?.status === 0) {
                        toast.warning("Message request already sent to the user")
                    } else {
                        setModalDetail({ show: true, flag: "ComposeMessage", });
                        setKey(Math.random());
                    }
                } else {
                    setModalDetail({ show: true, flag: "ComposeMessage", });
                    setKey(Math.random());
                }
            }
        } else {
            if (companyInfo?.chatRequest) {
                if (companyInfo?.chatRequest?.status === 1) {
                    navigate(`/account-message/${companyInfo?.chat_head_details?.id}`)
                }
                else if (companyInfo.chatRequest?.status === 0) {
                    toast.warning(" Message request already sent to the user")
                }
            } else {
                setModalDetail({ show: true, flag: "ComposeMessage", });
                setKey(Math.random());
            }
        }

    }

    const copyLinkToClipboard = (postId) => {

        const currentBaseUrl = window.location.origin;
        const dynamicUrl = `${currentBaseUrl}/dashboard/post/${postId}`;

        if (postId) {
            navigator.clipboard.writeText(dynamicUrl).then(() => {
                toast.dismiss()
                toast.success('Link copied to clipboard')
            });
        }
        else {
            toast.error('Somenthing went wrong')
        }

    }

    // edit featured post
    const editPostAction = (id) => {
        let params = {
            id: id,
        }
        dispatch(getPostSingleDetails({
            ...params, cb(res) {
                if (res.status) {

                    setEditFlag(true)
                    setEditPostID(res.data.payload?.id)
                    setPost(res.data.payload?.post ?? '')

                    const companyTagData = res.data.payload?.tag_company_details?.map((item, i) => {
                        return {
                            id: item.company_id,
                            name: item?.company_information?.company_name
                        }
                    })

                    const userTagData = res.data.payload?.tag_user_details?.map((item, i) => {
                        return {
                            id: item.user_id,
                            name: item?.user_details?.user_profile?.first_name + " " + item?.user_details?.user_profile?.last_name
                        }
                    })

                    setMainTags({
                        companies: companyTagData,
                        users: userTagData
                    })

                    setSelectedIds({
                        companies: companyTagData.map((item) => item.id),
                        users: userTagData.map((item) => item.id)
                    })

                    if (res?.data?.payload?.attachment) {

                        setPhotoUpload({
                            uploadPhoto: {

                                name: res?.data?.payload?.attachment_name ?? 'File',
                                size: res?.data?.payload?.attachment_size ?? 100000,
                                url: res?.data?.payload?.thumbnail_url

                            }, flag: res?.data?.payload?.attachment_type
                        });

                        setVideoUrlOriginal(res?.data?.payload?.attachment)

                        setPhotoInfo([res?.data?.payload?.thumbnail_url])

                    }

                    if (window)
                        window.scrollTo(0, 0);

                }
            }
        }))
    }

    // // API for post delete ..............
    const deletePostAction = (id, index) => {
        swal({
            text: `Are you sure want to remove this post?`,
            dangerMode: false,
            buttons: true,
        })
            .then(result => {
                if (result) {
                    let params = {
                        id: id,
                    }
                    dispatch(deletePost({
                        ...params, cb(res) {
                            if (res.status) {

                                setAllPostList((prevData) => {
                                    const data = prevData.filter((item, i) => i !== index);
                                    return data;
                                })

                                toast.success("Post deleted successfully")

                            }
                        }
                    }))
                }
            });

    }

    // // API for disabling comments ..............
    const disableEnableCommentAction = (id, flag, index) => {
        swal({
            text: `Are you sure want to ${flag == 1 ? 'disable' : 'enable'} comments on this post?`,
            dangerMode: false,
            buttons: true,
        })
            .then(result => {
                if (result) {
                    let params = {
                        id: id,
                        disable_comment: flag
                    }
                    dispatch(disableEnableComment({
                        ...params, cb(res) {
                            if (res.status) {

                                setAllPostList((prevData) => {

                                    let data = [...prevData]
                                    data[index] = { ...data[index], disable_comment: flag }
                                    return data;

                                })
                                toast.success(res?.data?.msg)
                            }
                        }
                    }))
                }
            });

    }

    //handle play button icon
    const handleVideoPlay = (post, index) => {
        setAllPostList((prevData) => {
            let data = [...prevData];
            // If you want to update the like status
            const updatedPost = {
                ...data[index],
                isvideo: true,
            };
            data[index] = updatedPost;

            return data;
        });
    };

    // total user like post
    const totalUserLikeList = (id) => {
        setModalDetail({ show: true, flag: "allPostLikeUser", id: id });
        setKey(Math.random());
    };

    //for post like...............
    const postLike = (id, flag, index) => {
        const params = {
            post_id: id,
            like_status: flag,
        };
        dispatch(
            userPostLike({
                ...params,
                cb(res) {
                    if (res.status) {
                        setAllPostList((prevData) => {
                            let data = [...prevData]
                            if (flag === 1) {
                                // If you want to update the like status
                                const updatedPost = {
                                    ...data[index],
                                    post_details: {
                                        ...data[index].post_details,
                                        is_like: res?.data?.payload?.is_like,
                                        likeCount: res?.data?.payload?.likeCount
                                    },
                                    is_like: res?.data?.payload?.is_like,
                                    likeCount: res?.data?.payload?.likeCount
                                };

                                data[index] = updatedPost;
                            } else {
                                // If you want to reset the like status to null
                                const updatedPost = {
                                    ...data[index],
                                    post_details: {
                                        ...data[index].post_details,
                                        is_like: res?.data?.payload?.is_like,
                                        likeCount: res?.data?.payload?.likeCount
                                    },
                                    is_like: res?.data?.payload?.is_like,
                                    likeCount: res?.data?.payload?.likeCount
                                };
                                data[index] = updatedPost;
                            }
                            return data;
                        })
                    } else {
                    }
                },
            })
        );
    };

    // //post all comments
    const handleLoadMoreComments = (id, index, page = 1, limit = 10) => {
        let params = {
            id: id,
            page: page,
            limit: limit
        };
        dispatch(allPostComment({
            ...params, cb(res) {
                if (res.status) {
                    setPages(page);
                    setAllCommentsLoad(true);
                    setPostIndexMoreComments(index)
                    if (page === 1) {
                        // For the first page
                        if (allCommentList.length === 0) {
                            setAllCommentList(res.data.payload?.comments || []);
                        } else {
                            setAllCommentList((prevData) => {
                                // Replace existing comments with new comments
                                const updatedComments = res.data.payload?.comments || [];
                                return updatedComments;
                            });
                        }
                    } else {
                        // For pages other than the first page (e.g., page 2)
                        setAllCommentList((prevData) => {
                            // Concatenate existing comments with new comments
                            const updatedComments = prevData.concat(res.data.payload?.comments || []);
                            return updatedComments;
                        });
                    }

                    setAllCommentData(res.data.payload);
                }

            }
        }))
    };

    //like post
    const commentLikeAction = (id, flag, index, reIndex = null, postindexcomment) => {
        const params = {
            comment_id: id,
            status: flag
        }
        dispatch(commentLike({
            ...params, cb(res) {
                if (res.status) {


                    if (reIndex == null) {

                        setAllCommentList((prevData) => {
                            let data = [...prevData]
                            const updatedComment = {
                                ...data[index], is_comment_liked: res?.data?.payload?.commentLikeDetails ?? null, total_like_count: res?.data?.payload?.commentDetails?.likeCount ?? 0
                            };
                            data[index] = updatedComment;

                            return data;
                        })

                    }

                    else if (postindexcomment === "postindexcomment") {
                        if (flag === 1) {
                            // If you want to update the like status
                            setAllPostList((prevData) => {
                                let data = [...prevData]


                                data[index] = res?.data?.payload?.post_details;
                                return data;
                            })


                        } else {
                            // If you want to reset the like status to null
                            setAllPostList((prevData) => {
                                let data = [...prevData]

                                data[index] = res?.data?.payload?.post_details;
                                return data;
                            })
                        }

                    }
                    else {
                        if (flag === 1) {
                            // If you want to update the like status
                            setAllCommentList((prevData) => {
                                let data = [...prevData]

                                data[index] = res?.data?.payload?.post_details?.post_comments[index];
                                return data;
                            })


                        } else {
                            // If you want to reset the like status to null
                            setAllCommentList((prevData) => {
                                let data = [...prevData]

                                data[index] = res?.data?.payload?.post_details?.post_comments[index];
                                return data;
                            })

                        }

                    }
                }
                else {

                }


            }
        }))
    }

    //for post comment...............
    const postComment = (id, index, flag) => {
        if (comment !== "") {
            if (!comment.length === "") {
                toast.error("Please enter text to comment");
                return;
            }
        }
        const params = {
            post_id: id,
            // comment: comment ? comment : ""
        }
        if (flag === "comment") {
            params.comment = comment
        }

        if (flag === "replyFlag") {
            params.comment_parent_id = replyId
            params.comment = nestedReply
        }
        dispatch(userPostComment({
            ...params, cb(res) {
                if (res.status) {
                    if (flag === "replyFlag") {
                        setShowNestedReply(false)
                        // if (postDivRefs.current[index]) {
                        //   postDivRefs.current[index].scrollIntoView({ behavior: 'smooth', block: 'start' });
                        // }
                        setReplyId("")

                        setComment("");
                        setPostID("")
                        setAllPostList((prevData) => {

                            let data = [...prevData];

                            if (index >= 0 && index < data?.length) {
                                const postToUpdate = data[index];
                                // Assuming res?.data?.payload contains the new comment data
                                const updatedCommentReplies = [...postToUpdate.post_details.post_comments[index].comment_replies, res?.data?.payload?.comment];

                                const updatedPost = {
                                    ...postToUpdate,
                                    post_comments: [
                                        ...postToUpdate.post_comments.slice(0, index), // Keep the comments before the index
                                        {
                                            ...postToUpdate.post_comments[index],
                                            comment_replies: updatedCommentReplies // Update comment_replies at the specified index
                                        },
                                        ...postToUpdate.post_comments.slice(index + 1) // Keep the comments after the index
                                    ]
                                };

                                data[index] = updatedPost;
                            }

                            return data;
                        });
                        setAllCommentList((prevData) => {
                            let data = [...prevData]
                            data.concat(res?.data?.payload)
                            return data;
                        })
                    }
                    else {
                        setShowNestedReply(false)
                        // if (postDivRefs.current[index]) {
                        //   postDivRefs.current[index].scrollIntoView({ behavior: 'smooth', block: 'start' });
                        // }
                        setReplyId("")

                        setComment("");
                        setPostID("")
                        setAllCommentList((prevData) => ([

                            res?.data?.payload,
                            ...prevData
                        ]))
                        setAllPostList((prevData) => {
                            let data = [...prevData]

                            const updatedPost = {
                                ...data[index],
                                post_details: {
                                    ...data[index].post_details,
                                    total_comments: res?.data?.payload?.total_comments,
                                },
                            };
                            data[index] = updatedPost;
                            return data;
                        })

                    }
                }
            }
        }))
    }

    const handlePageRedirectMyProfile = (name) => {
        const formattedName = name?.replace(/\s+/g, '-'); // Replace spaces with hyphens
        navigate(`/${formattedName}`)
    }

    const btnDisable = companyInfo?.chatRequest === false ? false : companyInfo?.chatRequest?.status === 2 ? true : companyInfo?.chatRequest?.status === 0 ? true : false

    useEffect(() => {
        window.scrollTo(0, 0)
        CompanyInformation(id)
        document.title = "companyfrontProfile";
    }, [id]);

    useEffect(() => {
        setShowNestedReply(false)
        setPostIndexMoreComments("")
    }, []);

    return (
        <>
            <section className='companyProfile_ overflow-hidden'>
                <div className='topprofileBanner'>
                    <div className='container-fluid noPadding'>
                        <div className='row'>
                            {
                                authSelector?.loading ?
                                    <>
                                        <div className="row">
                                            <div className="col-sm-12 text-center pt-5 pb-5">
                                                <div className="customLoader">
                                                    <div className="spinner-grow text-success" role="status"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    : companyInfo?.companyDetails?.banner !== null ?
                                        <img className='profile-img companyprofilebanner' src={companyInfo?.companyDetails?.banner} />
                                        : companyInfo?.companyDetails?.banner === null ?
                                            <img className='profile-img companyprofilebanner' src={Images.companyProfile2} />
                                            : ""
                            }
                        </div>
                    </div>
                </div>
                <div className='companyDetailspro_ mb-5'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-8'>
                                <div className='companyDetailsLeft'>
                                    <div className='containerBox'>
                                        <div className='row'>
                                            <div className='col-lg-2 col-md-3 p-0'>
                                                <div className='designImg_'>
                                                    {
                                                        authSelector?.loading ?
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-sm-12 text-center pt-5 pb-5">
                                                                        <div className="spinner-grow text-success" role="status"></div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            : companyInfo?.companyDetails?.company_logo != "" ?
                                                                <img className='imgproduct_' src={companyInfo?.companyDetails?.company_logo ?? Images.puzzleProfile} />
                                                                : companyInfo?.companyDetails?.company_logo === "" ?

                                                                    companyInfo?.companyDetails?.company_logo == null ? <img className='imgproduct_' src={Images.puzzleProfile} /> :
                                                                        <img className='imgproduct_' src={Images.puzzleProfile} />
                                                                    : ""
                                                    }
                                                </div>
                                            </div>
                                            <div className='col-lg-9 col-md-9'>
                                                <div className='designContent_'>
                                                    <div className='designcontentTop_'>
                                                        <div className='designLeft_'>

                                                            <h4 className='boldmainHeading_'>{companyInfo?.companyDetails?.company_name}</h4>
                                                            <h5 className='abouText_ mt-2'>{companyInfo?.companyDetails?.industries_group_details?.name}</h5>
                                                            {/* <p className='motionLoc mt-3 mb-3'>
                                                                {companyInfo?.companyDetails?.domestic_service_areas &&
                                                                    <>
                                                                        <img src={Images.location_} alt='Location' className='me-1' />
                                                                        <span className='textInnerOuter'>{companyInfo?.companyDetails?.domestic_service_areas}
                                                                        </span>
                                                                    </>
                                                                }
                                                            </p> */}

                                                            {companyInfo?.companyDetails?.country_details?.name ?
                                                                <p className='motionLoc mt-3 mb-3'>
                                                                    <img
                                                                        src={Images.location_}
                                                                        alt="Location"
                                                                        className="me-1"
                                                                    />
                                                                    <span className="textInnerOuter">
                                                                        {companyInfo?.companyDetails?.province_details?.name
                                                                        } / {companyInfo?.companyDetails?.country_details?.name}
                                                                    </span>
                                                                </p> : ""

                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='designbtnRight'>
                                                        {companyInfo?.companyDetails?.is_following ?
                                                            companyInfo?.companyDetails?.is_following?.id ?
                                                                <button className='subsmallBtngreen_ me-2 mb-2' onClick={() => handleUnfollowing()}>Following -</button>
                                                                : <button className='subsmallBtngreen_ me-2 mb-2' onClick={() => handleFollowing()}>Follow +</button>
                                                            : <>
                                                                <button className='subsmallBtngreen_ me-2 mb-2' onClick={() => handleFollowing()}>Follow +</button>
                                                            </>
                                                        }
                                                        {companyInfo?.companyDetails?.company_connection ?
                                                            companyInfo?.companyDetails?.company_connection?.status == 1 ?
                                                                <button className='subsmallBtnblue_ me-2' onClick={() => { updatePendingRequest() }}>Pending</button>
                                                                : companyInfo?.companyDetails?.company_connection?.status == 2 ?
                                                                    <button className='subsmallBtnblue_ me-2 connectedBtn' onClick={() => { updateAcceptRequest() }}>Connected</button> :
                                                                    companyInfo?.companyDetails?.company_connection?.status == 3 ?
                                                                        <button className='subsmallBtnblue_ me-2' onClick={() => { handleSubmit() }} >Request Connection</button>
                                                                        : ""
                                                            : <>
                                                                {companyInfo?.connecetionRequestReceived ?
                                                                    companyInfo?.connecetionRequestReceived?.status == 1 ?
                                                                        <>
                                                                            <button className='subsmallBtnblue_ me-2' onClick={() => { updateRequest(2) }}>accept</button>
                                                                            <button className='subsmallBtnblue_' onClick={() => { updateRequest(3) }}>Reject</button>
                                                                        </>
                                                                        : companyInfo?.connecetionRequestReceived?.status == 2 ?
                                                                            "" :
                                                                            companyInfo?.connecetionRequestReceived?.status == 3 ?
                                                                                <button className='subsmallBtnblue_ me-2 mb-2' onClick={() => { handleSubmit() }}>Request Connection</button>
                                                                                : ''


                                                                    : <button className='subsmallBtnblue_ me-2 mb-2' onClick={() => { handleSubmit() }}>Request Connection</button>
                                                                }

                                                            </>
                                                        }
                                                        {/* {
                                                            companyInfo?.chatRequest?.status === 1 ? <button className="subsmallBtnblue_" onClick={() => {
                                                                navigate(`/account-message/${companyInfo?.chat_head_details?.id}`)
                                                            }}
                                                            >Message   </button>
                                                                :
                                                                <button className={`subsmallBtnblue_ ${btnDisable === true ? "disabled__" : ""}`} onClick={() => {
                                                                    setModalDetail({ show: true, flag: "ComposeMessage", });
                                                                    setKey(Math.random());
                                                                }}
                                                                    disabled={btnDisable}
                                                                >Message</button>
                                                        } */}

                                                        {/* {chooseBusinessType == 2 && choosePlan === "Bronze" ?
                                                            // companyInfo?.companyDetails?.company_connection == false || companyInfo?.companyDetails?.company_connection?.status == 1 || companyInfo?.companyDetails?.company_connection?.status == 3 ?
                                                            "" : <button className={`subsmallBtnblue_ ${btnDisable === true ? "disabled__" : ""}`}
                                                                onClick={() => handleMessage()}
                                                                disabled={btnDisable}
                                                            >Message</button>

                                                            // : ""
                                                        } */}
                                                        {/* {chooseBusinessType === 2 && choosePlan == "Bronze" && companyInfo?.companyDetails?.company_connection == false ? "" : ""}
                                                        {chooseBusinessType === 2 && choosePlan != "Bronze" && companyInfo?.companyDetails?.company_connection == false || chooseBusinessType === 2 && choosePlan != "Bronze" || companyInfo?.companyDetails?.company_connection?.status == 2 || chooseBusinessType === 2 && choosePlan != "Bronze" && companyInfo?.companyDetails?.company_connection?.status == 1 || chooseBusinessType === 2 && choosePlan != "Bronze" && companyInfo?.companyDetails?.company_connection?.status == 3 ?
                                                            <button
                                                                className={`subsmallBtnblue_ ${btnDisable === true ? "disabled__" : ""}`}
                                                                onClick={() => handleMessage()}
                                                                disabled={btnDisable}
                                                            >
                                                                Message
                                                            </button> : ""

                                                        } */}
                                                        {authSelector?.companyProfileInfo?.userDetails?.membership_details?.business_type === 2 && 
                                                            authSelector?.companyProfileInfo?.userDetails?.membership_details?.name === "Bronze" ? "" :
                                                                <button
                                                                    className={`subsmallBtnblue_ ${btnDisable === true ? "disabled__" : ""}`}
                                                                    onClick={() => handleMessage()}
                                                                    disabled={btnDisable}
                                                                >
                                                                    Message
                                                                </button>
                                                        }

                                                        {/* { companyInfo?.companyDetails?.company_connection == false || companyInfo?.companyDetails?.company_connection?.status == 1 || companyInfo?.companyDetails?.company_connection?.status == 3 ?
                                                            "" : <button className={`subsmallBtnblue_ ${btnDisable === true ? "disabled__" : ""}`}
                                                                onClick={() => handleMessage()}
                                                                // onClick={() => {
                                                                //     setModalDetail({ show: true, flag: "ComposeMessage", });
                                                                //     setKey(Math.random());
                                                                // }}
                                                                disabled={btnDisable}
                                                            >Message</button>
                                                        } */}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <hr className="bottomBorder_"></hr>
                                        <div className='aboutprofile_ mt-4'>
                                            <h5 className='headingTitle_'><b>About </b>{companyInfo?.companyDetails?.company_name}</h5>
                                            <p className='innerSubtextSmall companyInfo mt-2 about15'>{companyInfo?.companyDetails?.company_service_description}</p>
                                        </div>
                                    </div>
                                    <div className='containerBox mt-3 pb-5'>
                                        <div className='currentOpportunity_'>
                                            <div className='currentTop'>

                                                <h4 className='headingTitle_'><b>Current Opportunities</b></h4>
                                                {companyCurrentOpportunity?.length > 3 ? <button className="subsmallBtngreen_ mt-3" onClick={() => {
                                                    navigate("/opportunity-list", { state: { oppId: companyInfo?.companyDetails?.user_id } })
                                                }}>View all</button> : ""}
                                            </div>
                                            {
                                                authSelector?.loading ?
                                                    <>
                                                        <div className="row">
                                                            <div className="col-sm-12 text-center pt-5 pb-5">
                                                                <div className="spinner-grow text-success" role="status"></div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="row mt-5 ctive">
                                                            {companyCurrentOpportunity?.length > 0 ? (
                                                                <>
                                                                    {companyCurrentOpportunity.slice(0, 3).map((data, index) => {
                                                                        return (
                                                                            <>
                                                                                <div className="col-lg-4">
                                                                                    <div className={`opportunityComtent_ ${index == 0 ? 'noOpportunity' : ''}`}>
                                                                                        <img
                                                                                            className="todoList_"
                                                                                            src={
                                                                                                data?.image_mime_type === "image/png" || data?.image_mime_type === "image/jpeg" ? data?.opportunity_media : data?.image_mime_type === "application/pdf" ? Images.iconPdf : data?.image_mime_type === "application/msword" || data?.image_mime_type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? Images.iconDoc : Images.todoList
                                                                                            }
                                                                                        />
                                                                                        <h5 className="opportunityHead_ mt-3">
                                                                                            {data?.posting_title}
                                                                                        </h5>
                                                                                        {data?.hq_location ?
                                                                                            <p className="postHours_ mb-3">
                                                                                                {data?.hq_location}
                                                                                            </p> : ""
                                                                                        }
                                                                                        <p className="innerSubtextSmall wordLimit text-start">
                                                                                            {data?.description}
                                                                                        </p>
                                                                                        {data.is_applied == null ?
                                                                                            // <button onClick={() => hanleRequestApplicatio(data)} className="subsmallBtngreen_ mt-3">
                                                                                            <button
                                                                                                onClick={(e) => handleSubmited(
                                                                                                    e,
                                                                                                    data?.id ?
                                                                                                        data?.id : ""
                                                                                                )}

                                                                                                className="subsmallBtngreen_ mt-3">
                                                                                                View Opportunity
                                                                                            </button> : <button className="subsmallBtnblue_ mt-3">
                                                                                                Application Applied
                                                                                            </button>
                                                                                        }

                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        );
                                                                    })}
                                                                </>
                                                            ) : (
                                                                <p className="mb-0"> No Current Opportunity Found</p>
                                                            )}
                                                        </div>
                                                    </>
                                            }
                                        </div>
                                    </div>
                                    <div className='containerBox mt-4'>
                                        <div className='newsBox_'>
                                            <div className='currentTop'>
                                                <h4 className='headingTitle_'><b>Featured Posts</b></h4>
                                            </div>
                                            {
                                                authSelector?.loading ?
                                                    <>
                                                        <div className="row ">
                                                            <div className="col-sm-12 text-center pt-5 pb-5">
                                                                <div className="spinner-grow text-success" role="status"></div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <InfiniteScroll
                                                            dataLength={allPost?.length} // Total length of the data
                                                            // next={() => fetchMoreData()} 
                                                            hasMore={true} // Set to false when there is no more data
                                                            // loader={<h4>Loading...</h4>} // Loading indicator
                                                            // endMessage={<p>No more data to load.</p>}
                                                            className="overflow-hidden"
                                                        >
                                                            {allPost && allPost?.length > 0 ?
                                                                <div className="featurePostCon">
                                                                    {allPost?.map((post, index) => {
                                                                        return (
                                                                            <div
                                                                                className="mb-4" key={index}  >
                                                                                <div className='posts justify-content-between'>

                                                                                    <div className="posts">
                                                                                        {userDeatil?.user_id == post?.user_id ?
                                                                                            <img onClick={() => handlePageRedirectMyProfile(post?.post_details?.user_details?.user_company_information?.company_name)} src={post?.post_details?.user_details?.user_profile?.profile_photo ? post?.post_details?.user_details?.user_profile?.profile_photo : Images.puzzleProfile} className='postUser me-3' alt='UserImage' />
                                                                                            :
                                                                                            <img onClick={() => handlePageRedirect(post?.post_details?.user_details?.user_company_information?.company_name, post?.post_details?.user_details?.user_company_information?.id)} src={post?.post_details?.user_details?.user_profile?.profile_photo ? post?.post_details?.user_details?.user_profile?.profile_photo : Images.puzzleProfile} className='postUser me-3' alt='UserImage' />
                                                                                        }
                                                                                        <div className='postsDetails'>
                                                                                            <p className='innerSubtext m-0'><strong>{post?.post_details?.user_details?.company_name}</strong></p>
                                                                                            {userDeatil?.user_id == post?.user_id ?
                                                                                                <h5 onClick={() => handlePageRedirectMyProfile(post?.post_details?.user_details?.user_company_information?.company_name)} className='headingTitleSmall_ d-flex align-items-center justify-content-between'>
                                                                                                    <span className="memberName">{post?.post_details?.user_details?.user_profile?.first_name} {post?.post_details?.user_details?.user_profile?.last_name}</span>
                                                                                                </h5> :
                                                                                                <h5 onClick={() => handlePageRedirect(post?.post_details?.user_details?.user_company_information?.company_name, post?.post_details?.user_details?.user_company_information?.id)} className='headingTitleSmall_ d-flex align-items-center justify-content-between mb-2'>
                                                                                                    <span className="memberName">{post?.post_details?.user_details?.user_profile?.first_name} {post?.post_details?.user_details?.user_profile?.last_name}</span>
                                                                                                </h5>
                                                                                            }
                                                                                            {post?.post_details?.post?.startsWith('http') || post?.post_details?.post?.startsWith('https') ?
                                                                                                <a className="linkIndex_" href={post?.post_details?.post} target="_blank" rel="noopener noreferrer" key={key}>
                                                                                                    {post?.post_details?.post}
                                                                                                </a>
                                                                                                : <p className='innerSubtextSmall text-break text-start'>{post?.post_details?.post}</p>
                                                                                            }
                                                                                            <span className='weeksTxt'>{moment(post?.created_at).fromNow()}</span>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="postOptionBox">
                                                                                        <div className="dropdown">
                                                                                            <p className="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                                <i className="las la-ellipsis-h moreOptionIcon"></i>
                                                                                            </p>
                                                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                                                <li onClick={() => copyLinkToClipboard(post?.post_details?.id)}><a className="dropdown-item">Copy link to post</a></li>
                                                                                                {userDeatil?.user_id == post?.post_details?.user_id &&
                                                                                                    <>
                                                                                                        <li onClick={() => {
                                                                                                            setEditId(post?.post_details?.id)
                                                                                                            editPostAction(post?.post_details?.id)
                                                                                                            setModalDetail({ show: true, flag: "AddANewPost" });
                                                                                                            setKey(Math.random());
                                                                                                        }}

                                                                                                        ><a className="dropdown-item">Edit post</a></li>

                                                                                                        <li onClick={(e) => { deletePostAction(post?.post_id, index) }} >
                                                                                                            <a className="dropdown-item">Delete post</a>
                                                                                                        </li>

                                                                                                        <li onClick={(e) => { disableEnableCommentAction(post?.post_details?.id, post?.disable_comment == 1 ? 2 : 1, index) }} >
                                                                                                            <a className="dropdown-item">
                                                                                                                {post?.disable_comment == 1 ?
                                                                                                                    "Enable comments on this post"
                                                                                                                    :
                                                                                                                    "Disable comments on this post"
                                                                                                                }
                                                                                                            </a>
                                                                                                        </li>

                                                                                                        <li><Link to="/settings" className="dropdown-item">who can see this  post ?</Link></li>
                                                                                                    </>
                                                                                                }
                                                                                            </ul>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    post?.post_details?.attachment_type == 2 ?
                                                                                        <>
                                                                                            <div class="uploadProfile_ uploadProfile__">
                                                                                                <i class="fas fa-file-pdf ms-start me-3"></i>
                                                                                                <div class="profileDetail_">
                                                                                                    <h5 class="profileboldHead">{post?.post_details?.attachment_name}</h5>
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                        : ""
                                                                                }
                                                                                {post?.post_details?.attachment_type == 3 ?
                                                                                    <>
                                                                                        {post?.isvideo == true ?
                                                                                            <video controls autoPlay>
                                                                                                <source src={post?.post_details?.attachment} type="video/mp4" className='soundbiteImg' />
                                                                                            </video>
                                                                                            :
                                                                                            <div className="postBox mb-3">
                                                                                                <img className="imagepost_" src={post?.post_details?.thumbnail_url} />
                                                                                                <img onClick={() => handleVideoPlay(post?.post_details, index)} className="videoIcon" src={Images.VideoIcon} />
                                                                                            </div>
                                                                                        }
                                                                                    </>
                                                                                    : post?.attachment == null ?
                                                                                        ""
                                                                                        : ""
                                                                                }
                                                                                {
                                                                                    post?.post_details?.attachment_type == 1 ?
                                                                                        <div className="postImage_ mt-3 mb-3" >
                                                                                            <img className="imagepost_" src={post?.post_details?.attachment} />
                                                                                        </div> : ""
                                                                                }

                                                                                <div className='bottomPostSec d-flex align-items-center justify-content-end my-3'>

                                                                                    <p className="postrate_">
                                                                                        <span onClick={() => totalUserLikeList(post?.post_details?.id,)} >{post?.post_details?.likeCount}</span>
                                                                                        {post?.post_details?.is_like?.like_status && post?.post_details?.is_like?.like_status == 1 ? <img className='likepost_ me-2' src={Images.thumbsup} onClick={(e) => { postLike(post?.post_details?.id, 2, index) }} />
                                                                                            : <img className='likepost_ me-2' src={Images.greyLike} onClick={(e) => { postLike(post?.post_details?.id, 1, index) }} />
                                                                                        }
                                                                                    </p>
                                                                                    {post?.post_details?.disable_comment == 2 && post?.post_details?.total_comments > 0 ?
                                                                                        <p className="postrate_"><span >{post?.post_details?.total_comments}</span>

                                                                                            <img
                                                                                                src={Images.message}
                                                                                                onClick={() => handleLoadMoreComments(post?.post_id, index)}
                                                                                                className='messageiconn me-2' alt='Message' />
                                                                                        </p>
                                                                                        : <p className="postrate_"><span >{post?.post_details?.total_comments}</span>
                                                                                            <img
                                                                                                src={Images.commentGrey}
                                                                                                className='messageiconn me-2' alt='Message' />
                                                                                        </p>
                                                                                    }
                                                                                    {/* <p className="postrate_">
                                                                                        <img
                                                                                            src={Images.star}
                                                                                            onClick={(e) => {
                                                                                                postFetures(
                                                                                                    post?.post_details?.id,
                                                                                                    2,
                                                                                                    index
                                                                                                );
                                                                                            }}
                                                                                            className="messageiconn"
                                                                                            alt="Message"
                                                                                        />

                                                                                    </p> */}
                                                                                </div>
                                                                                <div className="row">
                                                                                    {postIndexMoreComments === index ?
                                                                                        <>
                                                                                            <div className="dashboardComments" id="dashboardComments">
                                                                                                {allCommentList && allCommentList?.length > 0 ?
                                                                                                    <>
                                                                                                        {allCommentList.map((allComment, commentindex) => {
                                                                                                            return (
                                                                                                                <>

                                                                                                                    <div className="row">

                                                                                                                        <div className="col-lg-1 col-md-1 p-0">
                                                                                                                            <img src={Images.commentReply} className='newChatReplyIcon img-fluid me-1' alt='UserImage' />
                                                                                                                        </div>
                                                                                                                        <div className="col-lg-11 col-md-11 ps-0">
                                                                                                                            <div className="listComments commentreply">
                                                                                                                                <div className='comments mt-2 '>

                                                                                                                                    <div className='commentReply newCommentReply'>
                                                                                                                                        <figure>
                                                                                                                                            {

                                                                                                                                                userDeatil?.user_id == allComment?.user_id ?
                                                                                                                                                    <img
                                                                                                                                                        onClick={() => handlePageRedirectMyProfile(allComment?.user_details?.user_company_information?.company_name)}
                                                                                                                                                        src={allComment?.user_details?.user_profile?.profile_photo ? allComment?.user_details?.user_profile?.profile_photo : Images.puzzleProfile} className='chatPosttedUser me-3' alt='UserImage'
                                                                                                                                                    /> :
                                                                                                                                                    <img
                                                                                                                                                        onClick={() => handlePageRedirect(allComment?.user_details?.user_company_information?.company_name, allComment?.user_details?.user_company_information?.id)}
                                                                                                                                                        src={allComment?.user_details?.user_profile?.profile_photo ? allComment?.user_details?.user_profile?.profile_photo : Images.puzzleProfile} className='chatPosttedUser me-3' alt='UserImage'
                                                                                                                                                    />
                                                                                                                                            }
                                                                                                                                        </figure>
                                                                                                                                        <div>
                                                                                                                                            {
                                                                                                                                                userDeatil?.user_id == allComment?.user_id ?
                                                                                                                                                    <p className="innerSubtextSmall replyPerson" onClick={() => handlePageRedirectMyProfile(allComment?.user_details?.user_company_information?.company_name)}><b>{allComment?.user_details?.user_profile?.first_name} {allComment?.user_details?.user_profile?.last_name}</b></p>
                                                                                                                                                    : <p className="innerSubtextSmall replyPerson" onClick={() => handlePageRedirect(allComment?.user_details?.user_company_information?.company_name, allComment?.user_details?.user_company_information?.id)}><b>{allComment?.user_details?.user_profile?.first_name} {allComment?.user_details?.user_profile?.last_name}</b></p>}
                                                                                                                                            <h5 className='headingTitleSmall_ '>
                                                                                                                                                <span className='commentText mb-2 replyTime'>{moment(allComment?.created_at).fromNow()}</span>
                                                                                                                                            </h5>
                                                                                                                                        </div>
                                                                                                                                    </div>

                                                                                                                                    <p className="innerSubtextSmall mt-2">{allComment?.comment}</p>


                                                                                                                                </div>
                                                                                                                                <div className='commentReply justify-content-between my-3'>

                                                                                                                                    <figure>
                                                                                                                                        <p className="postrate_ justify-content-end">
                                                                                                                                            {allComment?.is_comment_liked ?
                                                                                                                                                <img className='likepost_ me-2' src={Images.thumbsup} onClick={(e) => { commentLikeAction(allComment?.id, 2, commentindex, "", "") }} />
                                                                                                                                                : <img className='likepost_ me-2' src={Images.greyLike} onClick={(e) => { commentLikeAction(allComment?.id, 1, commentindex, "", "") }} />
                                                                                                                                            }
                                                                                                                                            <span>{allComment?.total_like_count ? allComment?.total_like_count : allComment?.CommentLikeCount ? allComment?.CommentLikeCount : 0}</span>
                                                                                                                                        </p>
                                                                                                                                    </figure>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </>
                                                                                                            )
                                                                                                        })}
                                                                                                    </>
                                                                                                    : ""
                                                                                                }
                                                                                                {allCommentList?.length > 1 && allCommentData.total > allCommentList?.length &&
                                                                                                    <div className="text-center" onClick={() => { handleLoadMoreComments(post?.post_id, postIndexMoreComments, page + 1) }} >
                                                                                                        <i class="las la-angle-double-down loadMore"></i>
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        </> : ""}


                                                                                    <div className="footerComment">
                                                                                        <div className="commentBox_ px-0" >
                                                                                            <div className="messageTextarea">
                                                                                                <img className="img-fluid postUser" src={userInfo?.user_profile?.profile_photo ? userInfo?.user_profile?.profile_photo : Images.puzzleProfile} />
                                                                                                <div className="form-group">
                                                                                                    {replyFlag &&
                                                                                                        <div className="d-flex justify-content-between">
                                                                                                            <h6>
                                                                                                                Reply to {replyName}
                                                                                                            </h6>

                                                                                                            <i className="fa fa-times mt-0 cursor-pointer" onClick={() => {
                                                                                                                setReplyId(null)
                                                                                                                setReplyName('')
                                                                                                                setReplyFlag(false)
                                                                                                                setSelectedIndex(null)
                                                                                                            }}></i>
                                                                                                        </div>
                                                                                                    }
                                                                                                    <textarea type="text" className="customFormControl" value={post.post_id === postID ? comment : ""} placeholder="Have something to say..." id="inputName" name="post" key={index}
                                                                                                        onChange={(e) => { setComment(e.target.value); setPostID(post?.post_id) }} />
                                                                                                    <img className="sendIcon_" src={Images.sendIcon_} onClick={() => { postComment(post?.post_id, index) }} />
                                                                                                </div>
                                                                                                <img className="sendIcon_" src={Images.sendIcon_} onClick={() => { postComment(post?.post_id, index, "comment") }} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                    }
                                                                </div>
                                                                : "No Post Found"
                                                            }
                                                        </InfiniteScroll>
                                                    </>
                                            }
                                        </div>
                                    </div>

                                    {choosePlan == "Bronze" && chooseBusinessType === 1 || choosePlan == "Bronze" && chooseBusinessType === 2 ? ""
                                        :
                                        <div className='containerBox mt-4'>
                                            {leaveEndorsements == true ?

                                                <div className='newsBox_ endorsement_' >
                                                    <h4 className='headingTitle_'><b>Leave Acclamations</b></h4>
                                                    <form className='signupForm row justify-content-center mt-3' >
                                                        <div className="form-group col-md-6 mb-3">
                                                            <input type="text" className="customFormControl" placeholder='Company Name' disabled value={companyInfo?.companyDetails?.company_name}
                                                            />
                                                        </div>
                                                        <div className="form-group col-md-5 offset-1 mb-3">

                                                            <DatePicker
                                                                className='customFormControl'
                                                                selected={leaveEndorsementsDate}
                                                                onChange={onChangeDateLeaveEndorsement}
                                                                dateFormat="MMMM d, yyyy" // Format as "Month Day, Year"
                                                                placeholderText="Month Day, Year" // Example placeholder text
                                                            />
                                                        </div>
                                                        <div className="form-group col-md-12 mb-3">
                                                            <textarea name="description" type='text' className='customFormControl' placeholder='Type here...' rows='4' value={leaveEndorsementsDescription} onChange={e => setLeaveEndorsementsDescription(e.target.value)}></textarea>

                                                        </div>
                                                        <div className="endorsementsButton text-end">
                                                            <button type="button" className='subsmallBtnblue_' onClick={() => CancleEndrosement()}>Cancel Acclamations</button>
                                                            <button type="button" className='subsmallBtngreen_' onClick={() => SubmitEndrosement()}>Submit Acclamations</button>
                                                        </div>


                                                    </form>
                                                </div> : <>
                                                    <div className='newsBox_ endorsement_ '>

                                                        <Tabs defaultIndex={1}>
                                                            <div className="endorTabs">
                                                                <h4 className='headingTitle_'><b>Acclamations</b></h4>
                                                                <TabList>
                                                                    <Tab>Given</Tab>
                                                                    <Tab>Received</Tab>
                                                                </TabList>
                                                            </div>
                                                            <div className='row mt-4'>
                                                                <TabPanel>
                                                                    {companyInfo?.endorsement_given?.length > 0 ?
                                                                        <>
                                                                            {companyInfo?.endorsement_given?.map((data, index) => {

                                                                                return (
                                                                                    <>
                                                                                        <div className="messageDetail d-flex   mt-4">
                                                                                            <div className="imageDiv">
                                                                                                <img
                                                                                                    onClick={() => handlePageRedirect(data?.receiver_details?.user_company_information?.company_name, data?.receiver_details?.user_company_information?.id)}
                                                                                                    src={data?.receiver_details?.user_company_information?.company_logo ? data?.receiver_details?.user_company_information?.company_logo : Images.companyProfile2} className="userProfile me-2" alt="UserImage" />
                                                                                            </div>
                                                                                            <div className="postsDetails">
                                                                                                <h5 className="innerSubtextSmall d-flex align-items-center mb-2">
                                                                                                    <span
                                                                                                        onClick={() => handlePageRedirect(data?.receiver_details?.user_company_information?.company_name, data?.receiver_details?.user_company_information?.id)}
                                                                                                    ><strong>{data?.receiver_details?.user_company_information?.company_name} </strong></span>
                                                                                                </h5>
                                                                                                <p className="discription_">{data?.endorsement}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                )
                                                                            })
                                                                            }
                                                                        </> : <p>No Acclamations Given</p>
                                                                    }
                                                                </TabPanel>
                                                                <TabPanel>
                                                                    {companyInfo?.endorsement_received?.length > 0 ?
                                                                        <>
                                                                            {companyInfo?.endorsement_received?.map((data, index) => {
                                                                                return (
                                                                                    <>
                                                                                        <div className="messageDetail d-flex   mt-4">
                                                                                            <div className="imageDiv">
                                                                                                <img
                                                                                                    onClick={() => handlePageRedirect(data?.sender_details?.user_company_information?.company_name, data?.sender_details?.user_company_information?.id)}
                                                                                                    src={data?.sender_details?.user_company_information?.company_logo ? data?.sender_details?.user_company_information?.company_logo : Images.companyProfile2} className="userProfile me-2" alt="UserImage" />
                                                                                            </div>
                                                                                            <div className="postsDetails">
                                                                                                <h5 className="innerSubtextSmall d-flex align-items-center mb-2">
                                                                                                    <span
                                                                                                        onClick={() => handlePageRedirect(data?.sender_details?.user_company_information?.company_name, data?.sender_details?.user_company_information?.id)}
                                                                                                    ><strong>{data?.sender_details?.user_company_information?.company_name} </strong></span>
                                                                                                </h5>
                                                                                                <p className="discription_">{data?.endorsement}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>

                                                                                )
                                                                            })
                                                                            }
                                                                        </> : <p>No Acclamations Received</p>
                                                                    }
                                                                </TabPanel>
                                                            </div>
                                                        </Tabs>

                                                    </div>

                                                    <p className="text-center mt-3">Have you worked with this company
                                                        <span className="docText mx-2" onClick={(id) => setLeaveEndorsements(true, id)} >
                                                            <u>Click here to leave acclamations</u>
                                                        </span>
                                                    </p>

                                                </>}

                                        </div>


                                    }

                                </div>
                            </div>
                            <div className='col-lg-4 mt-5'>
                                <div className={`containerBoxright ${companyInfo?.companyDetails ? '' : 'd-none'}`}>
                                    <div className='companyDetailsRight'>
                                        <h5 className='headingTitle_ mb-2'><b>Company Details</b></h5>
                                        <ul className="companyDetailsList">
                                            <li className="">
                                                <span className="blueinnerSubtext pe-2">Website</span>
                                                <a className="listHead_" href={companyInfo?.companyDetails?.company_website} target="_blank"> {
                                                    companyInfo?.companyDetails?.company_website
                                                }</a>
                                            </li>
                                            <li className="flex-wrap">
                                                <span className="blueinnerSubtext">Industry</span>
                                                <span className="listHead_">{companyInfo?.companyDetails?.industries_group_details?.name}</span>
                                            </li>
                                            <li className="flex-wrap">
                                                <span className="blueinnerSubtext">Company Size</span>
                                                <span className="listHead_">{companyInfo?.companyDetails?.number_of_employees == 0 ? companyInfo?.companyDetails?.number_of_employees : companyInfo?.companyDetails?.number_of_employees + `+ employees`} </span>
                                            </li>

                                            {companyInfo.companyDetails?.main_company_country_location ?
                                                <li className="flex-wrap">
                                                    <span className="blueinnerSubtext">Locations</span>
                                                    <span className="listHead_">{companyInfo?.companyDetails?.main_company_country_location}</span>
                                                </li> : ""
                                            }
                                            {/* <li>
                                                <span className="blueinnerSubtext">Diversity Status</span>
                                                <span className="listHead_">

                                                    {
                                                        companyInfo?.companyDetails?.company_diverse_supplier_description.map((val, index) => (
                                                            <span className="listHead_" key={index}>
                                                                {val?.diverse_supplier_description_details?.name}
                                                            </span>
                                                        ))
                                                    }
                                                </span>
                                            </li> */}
                                            {
                                                companyInfo?.companyDetails?.company_diverse_supplier_description?.length === 0 ? "" :
                                                    <li>
                                                        <ul className='recentlyDataList mt-0'>
                                                            <li >
                                                                {
                                                                    companyInfo?.companyDetails?.company_diverse_supplier_description?.map((val, index) => (
                                                                        <div className="recentlyBtn d-inline-block me-1" key={index}>
                                                                            {val?.diverse_supplier_description_details?.name}
                                                                        </div>
                                                                    ))
                                                                }
                                                            </li>
                                                        </ul>
                                                    </li>
                                            }

                                        </ul>
                                    </div>
                                </div>
                                {companyInfo?.companyDetails?.company_existing_certificate_details?.length > 0 ?
                                    <div className='containerBoxright mt-3'>
                                        <div className='deviserCompany_'>

                                            <div className='currentTop'>
                                                <h4 className='headingTitle_'><b>Certifications</b></h4>
                                            </div>

                                            <div className='certificationContainer_'>
                                                {companyInfo?.companyDetails?.company_existing_certificate_details?.length > 0 ? (
                                                    <>
                                                        {companyInfo?.companyDetails?.company_existing_certificate_details
                                                            ?.filter((data) => data?.existing_certificates_details?.name !== 'Other')
                                                            ?.map((filteredData, index) => {
                                                                return (
                                                                    <>
                                                                        <div className="posts justify-content-between mt-3">
                                                                            <img
                                                                                src={Images.certificateIcon_}
                                                                                className="certifyBadge me-3"
                                                                                alt="UserImage"
                                                                            />
                                                                            <div className="postsDetails">
                                                                                <h5 className="headingTitleSmall_ d-flex     justify-content-between mb-1">
                                                                                    <span className="listHead_">
                                                                                        {
                                                                                            filteredData?.existing_certificates_details?.name
                                                                                        }
                                                                                        <span className="docText">
                                                                                        </span>
                                                                                    </span>
                                                                                </h5>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                );
                                                            })}
                                                    </>
                                                ) : (
                                                    <></>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                    : ""}

                                {companyInfo?.companyDetails?.company_trade_membership_details?.length > 0 ?
                                    <div className='containerBoxright mt-3'>
                                        <div className='deviserCompany_'>

                                            <div className='currentTop'>
                                                <h4 className='headingTitle_'><b>Trade Membership</b></h4>
                                            </div>

                                            <div className='certificationContainer_'>
                                                {companyInfo?.companyDetails?.company_trade_membership_details?.length > 0 ? (
                                                    <>
                                                        {companyInfo?.companyDetails?.company_trade_membership_details
                                                            ?.filter((data) => data?.trade_membership_details?.name !== 'Other')
                                                            ?.map((filteredData, index) => {
                                                                return (
                                                                    <>
                                                                        <div className="posts justify-content-between mt-3">
                                                                            <img
                                                                                src={Images.certificateIcon_}
                                                                                className="certifyBadge me-3"
                                                                                alt="UserImage"
                                                                            />
                                                                            <div className="postsDetails">
                                                                                <h5 className="headingTitleSmall_ d-flex     justify-content-between mb-1">
                                                                                    <span className="listHead_">
                                                                                        {
                                                                                            filteredData?.trade_membership_details?.name
                                                                                        }
                                                                                        <span className="docText">
                                                                                        </span>
                                                                                    </span>
                                                                                </h5>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                );
                                                            })}
                                                    </>
                                                ) : (
                                                    <></>
                                                )}

                                            </div>
                                        </div>
                                    </div>

                                    : ""}
                                <div className='lookSuplier mt-4'>
                                    <div className='cardBox'>
                                        <div className="textBox">
                                            <h6>Business <b>Resources</b></h6>
                                            <p>Building, sustaining and growing a business is a never-ending journey that involves continuos infusions of knowledge and wisdom.</p>
                                            <button onClick={() => navigate("/resources")} className='primaryBtnSmall' >Start Learning</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >

            </section >

            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                className={modalDetail.flag === "submitendorsement" ? "submitendorsement" : ""}
                ids={modalDetail.flag === "submitendorsement" ? "submitendorsement" : ""}
                child={
                    modalDetail.flag === "submitendorsement" ? (
                        <SubmitEndrosment
                            close={() => handleOnCloseModal()}
                        />
                    ) : modalDetail.flag === "ComposeMessage" ? (
                        <SendMessage
                            close={() => handleOnCloseModal()}
                            companyInfo={companyInfo}
                            handeCompany={() => CompanyInformation(id)}
                        />
                    ) :
                        ""
                }
                header={
                    <div className='modalHeader_'>
                        <div className="common_">
                            {modalDetail.flag === "submitendorsement" ?
                                <h2 className="addLabels_heading">
                                    Acclamations
                                </h2> :
                                modalDetail.flag === "ComposeMessage" ?
                                    <h2 className="addLabels_heading">
                                        Compose Message
                                    </h2>
                                    : ""
                            }
                        </div>
                    </div>
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

export default CompanyfrontProfile