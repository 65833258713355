import './App.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, useParams } from "react-router-dom";
import Router from "./routes";
import './public/css/custom.css';
import './public/css/customNew.css';
import './public/css/customNew2.css';
import './public/css/login.css';
import './public/css/responsive.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux'
import store from './redux';
import { persistor } from './redux';
import { PersistGate } from 'redux-persist/integration/react';
import HomeScreenPassword from './app/components/common/homeScreenForm';
import OneSignal from 'react-onesignal';
import NetworkService from "./utilities/api"
import runOneSignal from './utilities/oneSignal';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";


NetworkService.setupInterceptors(store);

const stripePromise = loadStripe("pk_test_51OOWyEKuOVhkftG29KiQ1NHv4XzTKV6toPMQFc9smgaUMnYMIayQfaMhaldPMEFHRm1skIrmEGX06orv9E1L5tjh005RBkwtZd");

function App() {

  useEffect(() => {

    runOneSignal();
  })

  return (
    <>
      <Elements stripe={stripePromise}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </PersistGate>
        <HomeScreenPassword />
      </Provider>
      <ToastContainer
      />
      </Elements>
    </>
  );
}

export default App;
