import React, { useEffect, useState } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import { diverseSupplierDescription } from '../../../redux/slices/advanceSearch';
import { businessOwnershipType, getAllCountryList, getAllIndustryGroups, getAllIndustrySubGroups, getAllProvinceList, getCompanyProfileInfo, userCompanyProfileUpdate } from '../../../redux/slices/auth';
import { useAuthSelector } from '../../../redux/selector/auth';
import { toast } from "react-toastify"

const EditCompanyInformationDetails = (props) => {
    const dispatch = useDispatch();
    const authSelector = useAuthSelector()
    const [companyStatus, setCompanyStatus] = useState()
    const [companyJVOpportunities, setCompanyJVOpportunities] = useState("")
    const [companySecondTierOpportunities, setCompanySecondTierOpportunities] = useState("")
    const [countryId, setCountryId] = useState("");
    const [provinceId, setProvinceId] = useState("");
    const [getAllcountry, setGetAllcountry] = useState("");
    const [getAllProvince, setAllProvince] = useState("");

    const [ownershipType, setOwnershipType] = useState("")
    const [ownershipTypeId, setOwnershipTypeId] = useState("")
    const [totalNoEmployee, setTotalNoEmployee] = useState("")
    const [industryGroupId, setIndustryGroupId] = useState("")
    const [subIndustryGroupId, setSubIndustryGroupId] = useState("")
    const [industryGroup, setIndustryGroup] = useState([])
    const [industrySubGroup, setindustrySubGroup] = useState([]);
    const [address, setAddress] = useState("");
    const [addresHqLocation, setAddresHqLocation] = useState("");
    const [companyDetails, setCompanyDetails] = useState("");
    const [cordinates, setCordinates] = useState({
        lat: null,
        lng: null
    });
    const [cordinatesHqLocation, setCordinatesHqLocation] = useState({
        lat: null,
        lng: null
    });



    const options = [
        { id: 1, name: 'Enterprise business' },
        { id: 2, name: 'Small business' },
    ];


    //for get all countrys .......
    const allCountry = (id) => {

        setCountryId(id)
        dispatch(
            getAllCountryList({
                cb(res) {
                    if (res.status) {
                        setGetAllcountry(res?.data?.payload);
                        if (id) {
                            provinceList(id);
                            setProvinceId("")
                        }
                        else {
                            setAllProvince("")
                        }
                    }
                },
            })
        );
    };


    //for get all provinceList .......
    const provinceList = (id) => {
        if (id) {
            let params = {
                id: id,
            };
            dispatch(
                getAllProvinceList({
                    ...params,
                    cb(res) {
                        if (res.status) {
                            setAllProvince(res?.data?.payload?.data);
                        }
                    },
                })
            );
        }
    };

    const IndustryGroups = (id) => {
        setIndustryGroupId(id)
        dispatch(getAllIndustryGroups({
            cb(res) {
                if (res.status) {
                    setIndustryGroup(res?.data?.payload)
                    if (id) {
                        industrySubGroups(id);
                        setSubIndustryGroupId("")
                    }

                }
            }
        }))
    }


    //for add industry Sub Groups .......
    const industrySubGroups = (id) => {
        if (id) {
            let params = {
                id: id,
            }
            dispatch(getAllIndustrySubGroups({
                ...params, cb(res) {
                    if (res.status) {
                        setindustrySubGroup(res?.data?.payload)
                    }
                }
            }))
        }
    }




    const [selectedId, setSelectedId] = useState(1); // Default selected ID

    // Find the selected option based on selectedId
    const selectedOption = options.find(option => option.id === selectedId);

    const handleSelectChange = (event) => {
        const newSelectedId = parseInt(event.target.value, 10); // Parse the selected value as an integer
        setSelectedId(newSelectedId);
    };

    // handle change address
    const handleSelect = async value => {
        const result = await geocodeByAddress(value);
        const ll = await getLatLng(result[0]);
        setAddress(value)
        setCordinates(ll)
    }

    // handle change hq location
    const handleSelectHqLocation = async value => {
        const result = await geocodeByAddress(value);
        const ll = await getLatLng(result[0]);
        setAddresHqLocation(value)
        setCordinatesHqLocation(ll)
    }
    const businessOwnership = (id) => {
        setOwnershipTypeId(id)
        dispatch(businessOwnershipType({
            cb(res) {
                if (res.status) {
                    setOwnershipType(res?.data?.payload)
                }
                else {

                }
            }
        }))
    }

    const handleChange = (e, flag) => {
        const { value } = e.target;
        if (flag == "employees") {
            setTotalNoEmployee(value);
        }
    }

    // onchange input 
    const handleChangedProvince = (id) => {
        setProvinceId(id)
    }

    // onchange input 
    const handleChangeIndustrySubGroup = (id) => {
        setSubIndustryGroupId(id)
    }


    // company edit profile
    const handleSubmit = (e) => {
        // props?.save()
        e.preventDefault()
        if (totalNoEmployee === "-0") {
            toast.error("Please  enter number between 0 and 500");
            return
        }

        let params = {
            id: companyDetails?.id,
            business_ownership_type: ownershipTypeId ? ownershipTypeId : 0,
            company_status: parseInt(companyStatus) ? parseInt(companyStatus) : null,
            number_of_employees: totalNoEmployee ? totalNoEmployee : 0,
            // domestic_service_areas: address ? address : "",
            // global_service_areas: addresHqLocation ? addresHqLocation : "",
            industry_groups: industryGroupId ? industryGroupId : null,
            industry_sub_groups: subIndustryGroupId ? subIndustryGroupId : null,
            company_website: companyDetails?.company_website ? companyDetails?.company_website : "",
            open_to_jv_supplier_opportunities: companyJVOpportunities ? companyJVOpportunities : "0",
            open_to_jv_and_2nd_tier_suppliers: companySecondTierOpportunities ? companySecondTierOpportunities : "0",
            country_id: countryId ? countryId : null,
            province_id: provinceId ? provinceId : null,
        };

        dispatch(userCompanyProfileUpdate({
            ...params, cb(res) {
                if (res.status) {
                    props.refreshCompanyDetail();
                    props.close();
                }
            }
        }))
    };

    //for COMPANY PROFILE INFORMATION.......
    const companyProfileInfo = () => {
        dispatch(getCompanyProfileInfo({
            cb(res) {
                if (res.status) {
                    if (res?.data?.payload?.userDetails?.user_company_information !== null) {
                        setCompanyJVOpportunities(res?.data?.payload?.userDetails?.user_company_information?.open_to_jv_supplier_opportunities)
                        setCompanySecondTierOpportunities(res?.data?.payload?.userDetails?.user_company_information?.open_to_jv_and_2nd_tier_suppliers)
                        setCompanyStatus(res?.data?.payload?.userDetails?.user_company_information?.company_status)
                        setCompanyDetails(res?.data?.payload?.userDetails?.user_company_information)
                        setIndustryGroupId(res?.data?.payload?.userDetails?.user_company_information?.industry_groups)
                        setSubIndustryGroupId(res?.data?.payload?.userDetails?.user_company_information?.industry_sub_groups)
                        setAddresHqLocation(res?.data?.payload?.userDetails?.user_company_information?.global_service_areas)
                        setAddress(res?.data?.payload?.userDetails?.user_company_information?.domestic_service_areas)
                        setTotalNoEmployee(res?.data?.payload?.userDetails?.user_company_information?.number_of_employees)
                        setOwnershipTypeId(res?.data?.payload?.userDetails?.user_company_information?.business_ownership_type)
                        setCountryId(res?.data?.payload?.userDetails?.user_company_information?.country_id)
                        setProvinceId(res?.data?.payload?.userDetails?.user_company_information?.province_id)
                    }
                    else {
                        setCompanyJVOpportunities(res?.data?.payload?.userDetails?.user_selected_company_information?.user_company_information?.open_to_jv_supplier_opportunities)
                        setCompanySecondTierOpportunities(res?.data?.payload?.userDetails?.user_company_information?.open_to_jv_and_2nd_tier_suppliers)
                        setCompanyStatus(res?.data?.payload?.userDetails?.user_selected_company_information?.user_company_information?.company_status)
                        setCompanyDetails(res?.data?.payload?.userDetails?.user_selected_company_information?.user_company_information)
                        setIndustryGroupId(res?.data?.payload?.userDetails?.user_selected_company_information?.user_company_information?.industry_groups)
                        setSubIndustryGroupId(res?.data?.payload?.userDetails?.user_selected_company_information?.user_company_information?.industry_sub_groups)
                        setAddresHqLocation(res?.data?.payload?.userDetails?.user_selected_company_information?.user_company_information?.global_service_areas)
                        setAddress(res?.data?.payload?.userDetails?.user_selected_company_information?.user_company_information?.domestic_service_areas)
                        setTotalNoEmployee(res?.data?.payload?.userDetails?.user_selected_company_information?.user_company_information?.number_of_employees)
                        setOwnershipTypeId(res?.data?.payload?.userDetails?.user_selected_company_information?.user_company_information?.business_ownership_type)
                        setCountryId(res?.data?.payload?.userDetails?.user_company_information?.country_id)
                        setProvinceId(res?.data?.payload?.userDetails?.user_company_information?.province_id)
                    }

                }
                else {

                }
            }
        }))
    }


    useEffect(() => {
        companyProfileInfo();
        businessOwnership();
        IndustryGroups();
        allCountry();
        provinceList();
        industrySubGroups()
    }, []);


    useEffect(() => {
        if (industryGroupId) {
            industrySubGroups(industryGroupId)
        }
    }, [industryGroupId])


    useEffect(() => {
        if (countryId) {
            provinceList(countryId)
        }
    }, [countryId])

    return (
        <>
            {authSelector.userDeatil ?
                <>
                    <div className="row">
                        <div className="col-sm-12 text-center pt-5 pb-5">
                            <div className="spinner-grow text-success" role="status"></div>
                        </div>
                    </div>
                </>
                :

                <form onSubmit={(e) => handleSubmit(e)} id="edit-company-info-details" className='signupForm row justify-content-center mt-5'>
                    <div className='row'>
                        <div className='form-group col-md-6 mb-3'>
                            <label className='postopportunity_label'>Company Status</label>
                            <select className="cateSelectbox" value={companyStatus} onChange={(e) => setCompanyStatus(e.target.value)}>
                                <option value="" >Select Company Status</option>
                                <option value="1">Private</option>
                                <option value="2">Public</option>
                                {/* {options.map(option => (
                                    <option key={option.id} value={option.id}>
                                        {option.name}
                                    </option>
                                ))} */}
                            </select>
                        </div>

                        <div className='form-group col-md-6 mb-3'>
                            <label className='postopportunity_label'>Business Ownership type</label>
                            <select className="cateSelectbox" required="" value={ownershipTypeId} onChange={(e) => { businessOwnership(e.target.value) }}>
                                <option value="">Business Ownership type</option>
                                {ownershipType?.length > 0 ?
                                    <>
                                        {ownershipType?.map((data) => {
                                            return <option value={data?.id}>{data.name}</option>
                                        })}

                                    </>
                                    : ""}
                            </select>
                        </div>

                        <div className='form-group col-md-6 mb-3'>
                            <label className='postopportunity_label'>Open to Joint Venture supplier opportunities</label>
                            <select className="cateSelectbox" value={companyJVOpportunities} onChange={(e) => setCompanyJVOpportunities(e.target.value)}>
                                <option value="" >Select Joint Venture Opportunities</option>
                                <option value="1">Yes</option>
                                <option value="2">No</option>
                            </select>
                        </div>
                        <div className='form-group col-md-6 mb-3'>
                            <label className='postopportunity_label'>Open to Second Tier Opportunities</label>
                            <select className="cateSelectbox" value={companySecondTierOpportunities} onChange={(e) => setCompanySecondTierOpportunities(e.target.value)}>
                                <option value="" >Select Second Tier Opportunities</option>
                                <option value="1">Yes</option>
                                <option value="2">No</option>
                            </select>
                        </div>
                        <div className='form-group col-md-6 mb-3'>
                            <div className="location_ mt-3 mb-2 ">
                                <h5 className="postopportunity_label mb-2">
                                    Business Location
                                </h5>
                                <select
                                    className="cateSelectbox"
                                    required=""
                                    value={countryId}
                                    onChange={(e) => {
                                        allCountry(e.target.value, "country");
                                    }}
                                >
                                    <option value="" selected>Select Business Location</option>
                                    {getAllcountry?.length > 0 ? (
                                        <>
                                            {getAllcountry.map((option, id) => (
                                                <option value={option?.id}>{option?.name}</option>
                                            ))}
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className='form-group col-md-6 mb-3'>
                            {/* {countryId ? (
                                <> */}
                            <div className="location_ mt-3 mb-2 ">
                                <h5 className="postopportunity_label mb-2">
                                    Business Area
                                </h5>
                                <select
                                    className="cateSelectbox"
                                    required=""
                                    value={provinceId}
                                    onChange={(e) => {
                                        handleChangedProvince(e.target.value, "province");
                                    }}
                                >
                                    <option value="" >State/Province</option>
                                    {getAllProvince?.length > 0 ? (
                                        <>
                                            {getAllProvince.map((option, id) => (
                                                <option value={option?.id}>
                                                    {option?.name}
                                                </option>
                                            ))}
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </select>
                            </div>
                            {/* </>
                            ) : (
                                <></>
                            )} */}
                        </div>
                        <div className='form-group col-md-6 mb-3'>
                            <label className='postopportunity_label'>Employees</label>
                            <input type="Number" min="0" max="500" value={totalNoEmployee} onChange={(e) => handleChange(e, "employees")} className='customFormControl' placeholder='' />
                        </div>
                        <div className='form-group col-md-6 mb-3'>
                            <label className='postopportunity_label'>Industry</label>
                            <select className="cateSelectbox" required="" value={industryGroupId} onChange={(e) => {
                                e.stopPropagation()
                                IndustryGroups(e.target.value)
                            }}
                            >
                                <option value="" >Select Industry Groups</option>
                                {industryGroup?.length > 0 ?
                                    <>
                                        {industryGroup?.map((data) => {
                                            return <option value={data.id}>{data.name}</option>
                                        })}

                                    </>
                                    : ""}
                            </select>
                        </div>
                        <div className='form-group col-md-6 mb-3'>
                            <label className='postopportunity_label'>Sub Industry</label>
                            <select className="cateSelectbox" required="" value={subIndustryGroupId} onChange={(e) => { handleChangeIndustrySubGroup(e.target.value) }}
                            >
                                <option value="" >Select Sub Industry Groups</option>
                                {industrySubGroup?.length > 0 ?
                                    <>
                                        {industrySubGroup?.map((data) => {
                                            return <option value={data.id}>{data.name}</option>
                                        })}

                                    </>
                                    : ""}
                            </select>
                        </div>
                    </div>
                </form>
            }
        </>
    )
}

export default EditCompanyInformationDetails